import React from 'react';
import moblrn_logo from '../images/svg/moblrn_logo.svg';
import Fade from '@material-ui/core/Fade';
import './SplashScreen.css';


/**
* SplashScreen is the page that is shown when it's unknown whether the user
* is logged in or not
*/
const SplashScreen = () => {
  return(
    <div className="moblrnSplashScreen">
      <Fade
        in={true}
        style={{ transformOrigin: '0 0 0' }}
        {...({ timeout: 1200 })}>
          <img className="moblrnSplashScreenIconDiv" src={moblrn_logo} alt="moblrn logo"/>
      </Fade>
    </div>
  );
};


export default SplashScreen;
