import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../actions';
import toast_success_icon from '../../images/svg/toast_success_icon.svg';
import './ImageUploadToast.css';


/**
* ImageUploadToast is a standard toast component that takes text, time to close
* and creates a toast coming from the top of the screen, specifically used for the
* Image Upload components within the Course core (Welcome, About, Lessons and Library pages)
*/
const ImageUploadToast = (props) => {
  const dispatch = useDispatch();
  const { text, timeToClose } = props;
  const currentPath = window.location.pathname.split('/');
  const doubleMenuPage = currentPath[3] === "lessons" || currentPath[3] === "library";
  const toastOrigin = useSelector(state => state.componentStates.imageUploadToastOrigin);
  const [toastClassname, setToastClassname] = useState(
    doubleMenuPage ?
    "imageUploadToastDoubleMenu imageUploadToastHidden" :
    "imageUploadToast imageUploadToastHidden"
  );
  const [componentMounted, setComponentMounted] = useState(true);

  useEffect(() => {
    setToastClassname(
      doubleMenuPage ?
      "imageUploadToastDoubleMenu imageUploadToastVisible" :
      "imageUploadToast imageUploadToastVisible"
    );
    setTimeout(() => {
      if (componentMounted) {
        setToastClassname(
          doubleMenuPage ?
          "imageUploadToastDoubleMenu imageUploadToastHidden" :
          "imageUploadToast imageUploadToastHidden"
        );
        setTimeout(
          () => dispatch(actions.closeImageUploadToast.request()),
          500
        );
      }
    },
    timeToClose);
    return (() => {
      setComponentMounted(false);
    });
  }, []);

   if (toastOrigin === currentPath[3]) {
    return (
      <div className={toastClassname}>
        <div className="imageUploadToastContent">
          <img className="imageUploadToastIcon" src={toast_success_icon} alt="success icon"/>
          <div>{text}</div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default ImageUploadToast;
