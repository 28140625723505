import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import DatePicker from 'react-date-picker/dist/entry.nostyle';
import black_arrow from '../../images/svg/black_arrow.svg';
import purple_arrow from '../../images/svg/purple_arrow.svg';
import white_arrow from '../../images/svg/white_arrow.svg';
import plus_white from '../../images/svg/plus_white.svg';
import change_image_icon from '../../images/svg/change_image_icon.svg';
import '../dateTimePicker/DatePicker.css';
import './commonFormComponents.css';


/**
* renderNumberField() renders the material UI TextField component
*/
export const renderNumberField = ({ input, meta, ...custom }) => {
  return (
    <div className="numberFieldWrapper">
      <input
        {...input}
        type="number"
        className="numberField"
        placeholder={custom.defaultText}
      />
      {meta.error && meta.touched && <div className="errorText absolutelyPositionedErrorText">{meta.error}</div>}
    </div>
  );
};

/**
* renderResizableTextField() renders the material UI TextField component with
* the width according to the width of the inner text
*/
export const renderResizableTextField = ({ input, meta, ...custom }) => {
  return (
    <div className="resizableTextFieldWrapper">
      <input
        {...input}
        className="resizableTextField"
        placeholder={custom.defaultText}
        ref={custom.inputref}
        onKeyDown={() => resizeInputField(custom.inputref.current, custom.spanref.current)}
      />
      {meta.error && meta.touched && <div className="errorText">{meta.error}</div>}
      <span className="resizableTextFieldMeasuringSpan" ref={custom.spanref}>{custom.inputref.current.value}</span>
    </div>
  );
};

const resizeInputField = (inputRef, spanRef) => {
  inputRef.style.width = spanRef.clientWidth + 10 + 'px';
};

export const renderDatePicker = ({ input, ...custom }) => (
  <DatePicker
    {...input}
    name={custom.name}
    clearIcon={null}
    required
  />
);

export const Dropdown = (props) => {
  return (
    <select
      name={props.name}
      onChange={props.onChange}
      defaultValue={"default"}
      className="createCourseSelect"
    >
      <option value="default" disabled hidden>{props.placeholder}</option>
      {props.options.map((client) => {
        return (
          <option key={client.id} value={client.id}>{client.name}</option>
        );
      })}
    </select>
  );
};

/**
* renderDropzone renders the empty dropzone component that can be used in forms
* It can be customized to be square or rectangular by setting dropzonetype
* to 'square'
*/
export const renderDropzone = ({ input, ...custom }) => {
  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles[0]) {
      input.onChange(acceptedFiles[0]);
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png, image/jpg, image/svg',
    maxfiles: 1
  });
  return input.value || custom.initialImage ?
    renderImage(input, custom.initialImage, isDragActive, isDragReject, getRootProps, getInputProps, custom.dropzonetype)
    : renderEmptyDropzone(input, isDragActive, isDragReject, getRootProps, getInputProps, custom.dropzonetype);
};

/**
* renderEmptyDropzone renders the empty dropzone before the user uploads
* an image
*/
const renderEmptyDropzone = (input, isDragActive, isDragReject, getRootProps, getInputProps, dropzonetype) => {
  const currentPath = window.location.pathname;
  const { courseId } = useParams();
  const dimensionClassName = dropzonetype === 'square' ? 'dropzoneSquareDimensions' : 'dropzoneRectangleDimensions';
  const textWidthClassName = dropzonetype === 'square' ? 'minSizeTextSquareWidth' : 'minSizeTextRectangleWidth';
  const recommendedSizeHelpText = dropzonetype === 'square' ? 'Recommended image size 550 x 550 px, maximum file size 200kb' : 'Recommended image width 550 px, maximum file size 200kb';
  return (
    <div className="emptyDropzoneAndTextWrapper">
      <div className={"emptyDropzone " + dimensionClassName} {...getRootProps()}>
        <input
          type="file"
          {...getInputProps()}
        />
        {isDragActive ?
          renderDropzoneOverlay(isDragReject, dropzonetype)
          :
          <>
            <img className="emptyDropzoneIcon" src={black_arrow} alt="downward pointing arrow"/>
            <img className="emptyDropzoneIcon emptyDropzonePurpleIcon" src={purple_arrow} alt="downward pointing arrow"/>
            <div className="emptyDropzoneText">
              Drop image here to upload
            </div>
            <div className="emptyDropzoneHelperText">
              or <u>browse</u> to choose a file
            </div>
          </>
        }
      </div>
      <div className={textWidthClassName}>
        <div className="minSizeText">Supported file formats: .jpg, .jpgz, .png, .svg</div>
        <div className="minSizeText">
          {
            currentPath === "/newcourse" || currentPath === `/course/${courseId}/settings` ?
            'Recommended image size 1280 x 800 px, maximum file size 200kb'
            :
            recommendedSizeHelpText
          }
        </div>
      </div>
    </div>
  );
};

/**
* renderDropzoneOverlay renders the dropzone overlay that is visible when
* the user drags an image to the dropzone
*/
const renderDropzoneOverlay = (isDragReject, dropzonetype) => {
  const dimensionClassName = dropzonetype === 'square' ? 'dropzoneSquareDimensions' : 'dropzoneRectangleDimensions';
  return (
    <div className={"dropzoneImageOverlay " + dimensionClassName}>
      { isDragReject ?
        <>
          <img className="emptyDropzoneIcon emptyDropzoneCross" src={plus_white} alt="white cross"/>
          <div className="dropzoneOverlayText">
            Moblrn only supports .jpg, .jpgz, .png, .svg
          </div>
        </>
        :
        <>
          <img className="emptyDropzoneIcon" src={white_arrow} alt="downward pointing arrow"/>
          <div className="dropzoneOverlayText">
            Drop image here to upload
          </div>
        </>
      }
    </div>
  );
};

/**
* renderImage renders the image uploaded to the dropzone
*/
const renderImage = (input, initialImage, isDragActive, isDragReject, getRootProps, getInputProps, dropzonetype) => {
  const currentPath = window.location.pathname;
  const { courseId } = useParams();
  const dimensionClassName = dropzonetype === 'square' ? 'dropzoneSquareDimensions' : 'dropzoneRectangleDimensions';
  const imageDimensionClassName = dropzonetype === 'square' ? 'dropzoneSquareImageDimensions' : 'dropzoneRectangularImageDimensions';
  const textWidthClassName = dropzonetype === 'square' ? 'minSizeTextSquareWidth' : 'minSizeTextRectangleWidth';
  const recommendedSizeHelpText = dropzonetype === 'square' ? 'Recommended image size 550 x 550 px, maximum file size 200kb' : 'Recommended image width 550 px, maximum file size 200kb';
  const imageHoverOverlayDimensionClassName = dropzonetype === 'square' ? 'dropzoneImageHoverOverlaySquareDimensions' : 'dropzoneImageHoverOverlayRectangularDimensions';
  const imagePath = input.value ? URL.createObjectURL(input.value) : null;
  return (
    <div className="emptyDropzoneAndTextWrapper">
      <div className={"renderDropzoneImageAndOverlayWrapper " + imageHoverOverlayDimensionClassName}>
        <div className={"dropzoneImageContainer " + dimensionClassName} {...getRootProps()}>
          <input
            type="file"
            {...getInputProps()}
          />
          <img className={"dropzoneImage " + imageDimensionClassName} src={imagePath || initialImage} />
          {isDragActive && renderDropzoneOverlay(isDragReject, dropzonetype)}
          <div className={"renderDropzoneImageHoverOverlay " + imageHoverOverlayDimensionClassName}>
            <img className="renderDropzoneImageHoverOverlayIcon" src={change_image_icon} alt="change images icon"/>
            <div className="dropzoneOverlayText">Click to change image</div>
          </div>
        </div>
      </div>
      <div className={textWidthClassName}>
        <div className="minSizeText">Supported file formats: .jpg, .jpgz, .png, .svg</div>
        <div className="minSizeText">
          {
            currentPath === "/newcourse" || currentPath === `/course/${courseId}/settings` ?
            'Recommended image size 1280 x 800 px, maximum file size 200kb'
            :
            recommendedSizeHelpText
          }
        </div>
      </div>
    </div>
  );
};
