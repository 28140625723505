import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import downward_arrow from '../images/svg/downward_arrow.svg';
import * as actions from '../actions';
import './PaginationBar.css';


/**
* PaginationBar is the component used for choosing the page for all lists
* that require pagination
*/
const PaginationBar = () => {
  const dispatch = useDispatch();
  const numberOfPages = useSelector(state => state.componentStates.numberOfPages);
  const currentPageNumber = useSelector(state => state.componentStates.currentPageNumber);
  const leftArrowClassnames = currentPageNumber === 1 ?
    "leftPaginationBarArrow disabledPaginationBarArrow"
    : "leftPaginationBarArrow";
  const rightArrowClassnames = currentPageNumber === numberOfPages ?
    "rightPaginationBarArrow disabledPaginationBarArrow"
    : "rightPaginationBarArrow";
  return (
    <div className="paginationBar">
      <img
        className={leftArrowClassnames}
        src={downward_arrow}
        alt="arrow left"
        onClick={() => dispatch(actions.changePageNumber.request(currentPageNumber - 1))}
      />
      {renderPaginationBarNumbers(numberOfPages, currentPageNumber, dispatch)}
      <img
        className={rightArrowClassnames}
        src={downward_arrow}
        alt="arrow right"
        onClick={() => dispatch(actions.changePageNumber.request(currentPageNumber + 1))}
      />
    </div>
  );
};

const renderPaginationBarNumbers = (numberOfPages, currentPageNumber, dispatch) => {
  const numberOfPreviousPages = currentPageNumber - 1;
  const numberOfNextPages = numberOfPages - currentPageNumber;
  let startPage = 1;
  let endPage = numberOfPages;
  if (numberOfPages > 9) {
    if (numberOfPreviousPages >= 4 && numberOfNextPages >= 4) {
      startPage = currentPageNumber - 4;
      endPage = currentPageNumber + 4;
    }
    if (numberOfPreviousPages < 4) {
      endPage = 9;
    }
    if (numberOfNextPages < 4) {
      startPage = currentPageNumber - 8 + numberOfNextPages;
      endPage = numberOfPages;
    }
  }
  let numbers = [];
  for (let i = startPage; i <= endPage; i++) {
    numbers.push(
      <div
        className="paginationBarNumber"
        key={i}
        onClick={() => dispatch(actions.changePageNumber.request(i))}
      >
        {i}
      </div>
    );
  }
  numbers[currentPageNumber - startPage] =
    <div className="paginationBarNumber paginationBarCurrentPageNumber" key={currentPageNumber}>
      {currentPageNumber}
    </div>;
  return numbers;
};

export default PaginationBar;
