import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { Link, useParams } from 'react-router-dom';
import * as actions from '../../actions';
import { renderDropzone, renderNumberField } from './commonFormComponents';
import DateTimePicker from 'react-datetime-picker/dist/entry.nostyle';
import WarningModal from '../WarningModal';
import {
  required,
  title,
  hasNumberInRange,
  composeValidators
} from '../../utils/validationUtils';
import './CourseSettingsForm.css';
import '../dateTimePicker/Clock.css';
import '../dateTimePicker/Calendar.css';
import '../dateTimePicker/DateTimePicker.css';

/**
* CourseSettingsForm
*/
const CourseSettingsForm = () => {
  const dispatch = useDispatch();
  const programNameRef = useRef('programNameRef');
  const isSuperAdmin = useSelector(state => state.user.isSuperAdmin);
  const clientList = useSelector(state => state.clients.clientNames);
  const [openDeleteCourseModal, setOpenDeleteCourseModal] = useState(false);
  const [courseToDelete, setCourseToDelete] = useState(null);

  if (isSuperAdmin) {
    useEffect(() => dispatch(actions.getClientNames.request()), []);
  }

  const { courseId } = useParams();
  const courseToEdit = useSelector(state => state.programs.currentProgram);
  const currentCourseImage = courseToEdit.programCardImageURL ? process.env.REACT_APP_MEDIA_URL + courseToEdit.programCardImageURL : null;

  const handleSubmit = (data, form) => {
    const updatedCourse = {
      _id: courseToEdit._id,
      clientId: data.courseSettingsClient,
      name: data.courseSettingsCourseTitle,
      programCardImageURL: data.courseSettingsImage,
      interactionDefaultScore: courseToEdit.interactionDefaultScore,
      dynamicPushTime: { from: data.openCourseFromTime, to: data.openCourseToTime },
      startDate: data.specificTimelineDate
    };
    dispatch(actions.editCourseSettings.request(updatedCourse));
    dispatch(actions.setToastOrigin.request(`/course/${courseId}/settings`));
    form.change('courseSettingsImage', undefined);
  };

  return (
    <>
      <div className="courseSettingsFormTitle">SETTINGS</div>
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          courseSettingsClient: courseToEdit.clientId,
          courseSettingsCourseTitle: courseToEdit.name,
          openCourseFromTime: courseToEdit.type === 1 ? courseToEdit.dynamicPushTime.from : null,
          openCourseToTime: courseToEdit.type === 1 ? courseToEdit.dynamicPushTime.to : null,
          specificTimelineDate: courseToEdit.type === 2 ? courseToEdit.startDate : null
        }}
      >
        {({ handleSubmit, values, errors }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            {
              isSuperAdmin &&
              <>
                <div className="courseSettingsFormSubTitle">Client</div>
                <div className="courseSettingsDropdownContainer">
                  <Field name="courseSettingsClient" options={clientList}>
                    {({input, options}) => {
                      return (
                        <Dropdown
                          options={options}
                          name={input.name}
                          currentClient={values.courseSettingsClient}
                          onChange={(value) => input.onChange(value)}
                        />
                      );
                    }}
                  </Field>
                </div>
              </>
            }
            <div className="courseSettingsFormSubTitle">Program title</div>
            <div className="courseSettingsHelperText">Give your program a good name that describes it in an easy way</div>
            <Field
              name="courseSettingsCourseTitle"
              validate={composeValidators(required, title)}
            >
              {({ input, meta }) => (
                <div className="courseSettingsQuestionWithErrorContainer">
                  <div className="courseSettingsInputAndErrorContainer">
                    <div className="courseSettingsNameInputAndCounter">
                      <input
                        {...input}
                        type="text"
                        className="courseSettingsInputField"
                        minLength= "1"
                        maxLength= "30"
                        ref={programNameRef}
                        required
                      />
                      <div className="courseSettingsNameTextCounter">{calculateNameLength(programNameRef) + '/30'}</div>
                    </div>
                    {meta.error && meta.touched && <div className="courseSettingsErrorMessage">{meta.error}</div>}
                  </div>
                </div>
              )}
            </Field>

            <div className="courseSettingsFormSubTitle">Program image</div>
            <div className="courseSettingsHelperText courseSettingsImageHelperText">The program image will be used as the program card in the app</div>
            <div className="courseSettingsDropzoneContainer">
              <Field
                name="courseSettingsImage"
                dropzonetype="square"
                initialImage={currentCourseImage}
                component={renderDropzone}
              />
            </div>
            <div className="courseSettingsNonEditableField">
              <div className="courseSettingsFormSubTitle">Timeline type</div>
              <div className="courseSettingsHelperText courseSettingsTypeHelperText">Choose what type of timeline your program should have</div>
              <div className="courseSettingsTypeButtonsContainer">
                <label className={courseToEdit.type === 1 ? "courseSettingsTypeButtonChosen courseSettingsTypeLeftButton" : "courseSettingsTypeButton courseSettingsTypeLeftButton"}>
                  <input
                    name="courseType"
                    type="radio"
                    value= "1"
                  />
                  INDIVIDUAL TIMELINE
                </label>
                <label className={courseToEdit.type === 2 ? "courseSettingsTypeButtonChosen" : "courseSettingsTypeButton"}>
                  <input
                    name="courseType"
                    type="radio"
                    value= "2"
                  />
                  FIXED DATE TIMELINE
                </label>
              </div>
            </div>
            {courseToEdit.type === 1 &&
              <>
                <div className="courseSettingsFormSubTitle">Push notification settings</div>
                <div className="courseSettingsHelperText">Between what hours should the user get notifications? (24 hour clock &amp; timezone is in CET)</div>
                <div className="courseSettingsTimelineMiniText">From</div>
                <div className="courseSettingsTimePickerContainer">
                  <Field
                    name="openCourseFromTime"
                    validate={composeValidators(required, hasNumberInRange(0, 23))}
                    type="number"
                    min="0"
                    max="23"
                    required
                    component={renderNumberField}
                  />
                </div>
                <div className="courseSettingsTimelineMiniText courseSettingsTimelineMiniTextTo">To</div>
                <div className="courseSettingsTimePickerContainer">
                  <Field
                    name="openCourseToTime"
                    validate={composeValidators(required, hasNumberInRange(0, 23))}
                    type="number"
                    min="0"
                    max="23"
                    required
                    component={renderNumberField}
                  />
                </div>
              </>
            }
            {courseToEdit.type === 2 &&
              <>
                <div className="courseSettingsFormSubTitle">Start date</div>
                <div className="courseSettingsHelperText">Choose time and date when your program should start (24 hour clock &amp; timezone is in CET)</div>
                <div className="courseSettingsTimelineDatePickerContainer">
                  <Field name="specificTimelineDate" validate={composeValidators(required)}>
                    {({input}) => {
                      return (
                        <DateTimePicker
                          name={input.name}
                          onChange={(value) => input.onChange(value)}
                          value={setDate(values.specificTimelineDate)}
                          clearIcon={null}
                          disableClock={true}
                          locale="en-en"
                          format="dd/MM/y H:mm"
                          required
                        />
                      );
                    }}
                  </Field>
                </div>
              </>
            }
            <div className="courseSettingsSubmitButtonsContainer">
              <Link to="/" exact="true" className="courseSettingsRouterLink">
                <button type="button" className="courseSettingsCancelButton">
                  CANCEL
                </button>
              </Link>
              <button
                type="submit"
                onSubmit={handleSubmit}
                disabled={disableSubmitButton(values, courseToEdit, errors)}
                className="courseSettingsSubmitButton courseSettingsButtonRightMargin"
              >
                SAVE CHANGES
              </button>
              <button
                type="button"
                className="courseSettingsDeleteButton"
                onClick={() => handleOpenDeleteCourseModal(setOpenDeleteCourseModal, setCourseToDelete, courseId)}
              >
                DELETE PROGRAM
              </button>
            </div>
            <WarningModal
              open={openDeleteCourseModal}
              onClose={() => setOpenDeleteCourseModal(false)}
              title='Delete program'
              text1={`Are you sure you want to delete the program "${courseToEdit.name}"?`}
              text2='All content, users and other data will be DELETED from this program and CAN NOT be recovered.'
              leftButtonText='NO, CANCEL'
              rightButtonText='YES, DELETE'
              onLeftButtonClick={() => handleCloseDeleteCourseModal(setOpenDeleteCourseModal, setCourseToDelete)}
              onRightButtonClick={() => handleDeleteCourse(setOpenDeleteCourseModal, dispatch, courseToDelete, setCourseToDelete)}
            />
          </form>
        )}
      </Form>
    </>
  );
};

const calculateNameLength = (programNameRef) => {
  if (!programNameRef.current.value) {
    return 0;
  } else {
    return programNameRef.current.value.length;
  }
};

const Dropdown = (props) => {
  return (
    <select
      name={props.name}
      onChange={props.onChange}
      value={props.currentClient}
      className="courseSettingsSelect"
    >
      {props.options.map((client) => {
        return (
          <option key={client._id} value={client._id}>{client.name}</option>
        );
      })}
    </select>
  );
};

const setDate = (date) => {
  if (!date) {
    return null;
  } else {
    return new Date(date);
  }
};

const handleOpenDeleteCourseModal = (setOpenDeleteCourseModal, setCourseToDelete, courseId) => {
  setOpenDeleteCourseModal(true);
  setCourseToDelete(courseId);
};

const handleCloseDeleteCourseModal = (setOpenDeleteCourseModal, setCourseToDelete) => {
  setOpenDeleteCourseModal(false);
  setCourseToDelete(null);
};

const handleDeleteCourse = (setOpenDeleteCourseModal, dispatch, courseToDelete, setCourseToDelete) => {
  setOpenDeleteCourseModal(false);
  dispatch(actions.deleteCourse.request(courseToDelete));
  setCourseToDelete(null);
};

const disableSubmitButton = (values, courseToEdit, errors) => {
  const originalDate = new Date(courseToEdit.startDate);
  const newDate = new Date(values.specificTimelineDate);
  return (
    Object.keys(errors).length > 0
    || !(courseToEdit.clientId !== values.courseSettingsClient
    || courseToEdit.name !== values.courseSettingsCourseTitle
    || values.courseSettingsImage
    || (courseToEdit.type === 1 && courseToEdit.dynamicPushTime.from !== parseInt(values.openCourseFromTime))
    || (courseToEdit.type === 1 && courseToEdit.dynamicPushTime.to !== parseInt(values.openCourseToTime))
    || originalDate.getTime() !== newDate.getTime())
  );
};

export default CourseSettingsForm;
