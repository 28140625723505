import React from 'react';
import { useDashboardGraphs } from './use-dashboard-graphs';
import './ProgramProgressPiechart.css';
import LoadingText from '../components/LoadingText';

/**
* ProgramProgressPiechart shows how many students in a program have completed,
* started and not started a program
*/
const ProgramProgressPiechart = () => {
  const {
    filteredCount,
    finishedStudentsPercent,
    isFetchingNextPage,
    isLoading,
    notLoggedInStudentsPercent,
    pieChartData,
    studentsInProgressPercent,
    total,
    totalPercent,
  } = useDashboardGraphs();

  const finishedStudentsDashlimits = finishedStudentsPercent + " " + (100 - finishedStudentsPercent).toString();
  const studentsInProgressDashlimits = studentsInProgressPercent + " " + (100 - studentsInProgressPercent).toString();
  const notLoggedInStudentsDashlimits = notLoggedInStudentsPercent + " " + (100 - notLoggedInStudentsPercent).toString();
  const studentsInProgressDashoffset = 100 - finishedStudentsPercent + 25 || 0;
  const notLoggedInStudentsDashoffset = 100 - finishedStudentsPercent - studentsInProgressPercent + 25 || 0;
  
  return (
    <div className="programProgressPiechartContainer">
      <div className="progressPiechartTitle">Summary</div>
      <div className="piechartWithLegend">
        <svg width="188px" height="188px" viewBox="0 0 42 42">
          <circle cx="21" cy="21" r="15.91549430918954" fill="transparent"></circle>
          <circle cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#9976ba" strokeWidth="6" strokeDasharray={finishedStudentsDashlimits} strokeDashoffset="25"></circle>
          <circle cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#e4d7ff" strokeWidth="6" strokeDasharray={studentsInProgressDashlimits} strokeDashoffset={studentsInProgressDashoffset}></circle>
          <circle cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#f4efff" strokeWidth="6" strokeDasharray={notLoggedInStudentsDashlimits} strokeDashoffset={notLoggedInStudentsDashoffset}></circle>
          <g>
            {
              filteredCount > 0 ?
              <>
                <text x="50%" y="57%" className="chartNumber" fill="#414141">
                  {filteredCount}
                </text>
                <text x="50%" y="43%" className="chartLabel" fill="#414141">
                  {filteredCount === 1 ? "user" : "users"}
                </text>
              </>
              :
              <text x={isLoading ? '37%' :'23%'} y="53%" className="chartEmptyText" fill="#414141">
                {isLoading ? 'Loading...' : 'No data available'}
              </text>
            }
          </g>
        </svg>
        <div className="legendRowContainer">
          <div className="legendRow">
            <div className="legendSquare" style={{"backgroundColor": "#f4efff"}}/>
            <div className="legendRowTextWrapper">
              <div className="legendRowText">Not started</div>
              <div className="legendRowPercentage">{`${pieChartData['Not started']} (${notLoggedInStudentsPercent ? notLoggedInStudentsPercent.toFixed(1) : "0.0"}%)`}</div>
            </div>
          </div>
          <div className="legendRow">
            <div className="legendSquare" style={{"backgroundColor": "#e4d7ff"}}/>
            <div className="legendRowTextWrapper">
              <div className="legendRowText">In progress</div>
              <div className="legendRowPercentage">{`${pieChartData['In progress']} (${studentsInProgressPercent ? studentsInProgressPercent.toFixed(1) : "0.0"}%)`}</div>
            </div>
          </div>
          <div className="legendRow">
            <div className="legendSquare" style={{"backgroundColor": "#9976ba"}}/>
            <div className="legendRowTextWrapper">
              <div className="legendRowText">Finished</div>
              <div className="legendRowPercentage">{`${pieChartData.Finished} (${finishedStudentsPercent ? finishedStudentsPercent.toFixed(1) : "0.0"}%)`}</div>
            </div>
          </div>
          <div className="pieChartHorizontalLine"/>
          <div className="legendRow">
            <div className="legendRowTextWrapper legendRowTextTotal">
              <div className="legendRowText">Total</div>
              <div className="legendRowPercentage">{`${total} (${totalPercent === '100.0' ? '100' : totalPercent}%)`}</div>
            </div>
          </div>
        </div>
      </div>
      <div className={`chart-loading-overlay ${isLoading || isFetchingNextPage ? 'shown' : ''}`}>
        <LoadingText label={`Loading (${totalPercent}%)`} />
      </div>
    </div>
  ); 
};


export default ProgramProgressPiechart;
