import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Const from '../../store/Const';
import { ModuleTitleField } from './commonCourseCoreComponents';
import { AddContentDropdown } from './AddContentDropdown';
import { renderCourseComponent, onDragEnd, onDragUpdate } from '../../utils/componentUtils';
import './Common.css';

/**
* CourseAboutPageContent contains all input fields that make up the Course's
* About Page
*/

const CourseAboutPageContent = () => {
  const dispatch = useDispatch();
  const aboutPageContent = useSelector(state => state.programs.currentProgram.moduleContent.about);
  const aboutPageName = useSelector(state => state.programs.currentProgram.modules.aboutModule.name || 'About page');
  const moduleId = useSelector(state => state.programs.currentProgram.modules.aboutModule._id) || '';
  const [placeholderProps, setPlaceholderProps] = useState({});
  const initialData = {
    components: {},
    columns: {
      "about-column": {
        id: "about-column",
        componentIds: []
      }
    },
    columnOrder: ["about-column"]
  };
  const [data, setData] = useState(initialData);

  useEffect(() => {
    const itemIds = aboutPageContent.map(item => item._id);
    let aboutContentObject = {};
    aboutPageContent.forEach(item => {
      const component = {
        id: item._id,
        content: item
      };
      aboutContentObject[item._id] = component;
    });

    const itemData = {
      components: aboutContentObject,
      columns: {
        "about-column": {
          id: "about-column",
          componentIds: itemIds
        }
      },
      columnOrder: ["about-column"]
    };
    setData(itemData);
  }, [aboutPageContent]);

  return (
    <>
      <ModuleTitleField
        titleText={aboutPageName}
        placeholder="About page"
        moduleId={moduleId}
      />
      {aboutPageContent.length !== 0 &&
        <>
          {data.columnOrder.map((columnId) => {
            const column = data.columns[columnId];
            const components = column.componentIds.map((componentId) => data.components[componentId]);
            return (
              <DragDropContext
                onDragEnd={(result) => onDragEnd(result, data, setPlaceholderProps, setData, moduleId, Const.moduleType.About, dispatch)}
                onDragUpdate={(update) => onDragUpdate(update, setPlaceholderProps)}
                key={columnId}
              >
                <Droppable droppableId={column.id} key={column.id}>
                  {(provided, snapshot) => (
                    <div
                      className="courseContentWrapper"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {components.map((component, index) => {
                        return (
                          <Draggable
                            draggableId={component.id}
                            index={index}
                            key={component.id}
                            isDragDisabled={aboutPageContent.length === 1}
                          >
                            {(provided, snapshot) => (
                              <div
                                className="courseComponentWrapper"
                                key={component.id}
                                {...provided.draggableProps}
                                ref={provided.innerRef}
                              >
                                <div className={snapshot.isDragging ? "courseComponentWrapperDragging" : ""}>
                                  {renderCourseComponent(component.content, aboutPageContent.length, Const.moduleType.About, provided, null)}
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      <div>{provided.placeholder}</div>
                      {Object.keys(placeholderProps).length > 0 && snapshot.isDraggingOver && (
                        <div
                          className="dragItemCustomPlacholder"
                          style={{
                            top: placeholderProps.clientY,
                            left: placeholderProps.clientX,
                            height: placeholderProps.clientHeight - 12,
                            width: placeholderProps.clientWidth
                          }}
                        />
                      )}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            );
          })}
        </>
      }
      <AddContentDropdown
        moduleId={moduleId}
        parentId={null}
        moduleType={Const.moduleType.About}
        sourceAboutPage={true}
      />
    </>
  );
};

export default CourseAboutPageContent;
