const initialState = {
  currentClient: {
    clientId: "",
    clientName: "",
    logo: "",
    clientLogo: null,
    activeAdmins: 0,
    maxActiveAdmins: null,
    activePrograms: 0,
    maxActivePrograms: null,
    activeStudents: 0,
    maxActiveStudents: null,
    stripePlan: "",
    billingDate: null,
    billingEmail: "",
    last4: "",
    subscriptionIsCancelled: null,
    publicApiEnabled: false
  },
  clientNames: [],
  clients: [],
  allAPIKeys: [],
  currentClientAPIKeys: [],
};

/**
* client is the reducer responsible for the client data including
* subscription data
*/
const client = (state = initialState, action) => {
  switch (action.type) {
    case 'GETCLIENTSUBSCRIPTIONDATA_SUCCESS': {
      let newCurrentClient = {
        ...state.currentClient,
        ...action.response
      };
      return {
        ...state,
        currentClient: newCurrentClient
      };
    }
    case 'GETCLIENTSTRIPESUBSCRIPTIONDATA_SUCCESS': {
      let newCurrentClient = {
        ...state.currentClient,
        ...action.response
      };
      return {
        ...state,
        currentClient: newCurrentClient
      };
    }
    case 'TOGGLECANCELSUBSCRIPTION_SUCCESS': {
      let newCurrentClient = {
        ...state.currentClient,
        ...action.response
      };
      return {
        ...state,
        currentClient: newCurrentClient
      };
    }
    case 'GETCLIENTNAMES_SUCCESS':
      return {
        ...state,
        clientNames: action.response,
      };
    case 'GETCLIENTLOGO_SUCCESS':
    case 'SETCLIENTLOGO_SUCCESS': {
      let newCurrentClient = {
        ...state.currentClient,
        clientLogo: action.response.key
      };
      return {
        ...state,
        currentClient: newCurrentClient
      };
    }
    case 'GETCLIENTS_SUCCESS':
      return {
        ...state,
        clients: action.response.clients
      };
    case 'GETCLIENT_SUCCESS':
      return {
        ...state,
        currentClient: action.response
      };
    case 'GETALLAPIKEYS_SUCCESS':
      return {
        ...state,
        allAPIKeys: action.response
      };
    case 'GETCLIENTAPIKEYS_SUCCESS':
      return {
        ...state,
        currentClientAPIKeys: action.response
      };
    case 'ENABLEAPIFORCLIENT_SUCCESS':
      return {
        ...state,
        currentClient: {
          ...state.currentClient,
          publicApiEnabled: !state.currentClient.publicApiEnabled
        }
      };
    case 'DISABLEAPIFORCLIENT_SUCCESS':
      return {
        ...state,
        currentClient: {
          ...state.currentClient,
          publicApiEnabled: false
        }
      };
    case 'DELETECLIENTAPIKEY_SUCCESS': {
      const updatedClientAPIKeys = state.currentClientAPIKeys.filter(key => {
        return key.keyId !== action.keyId;
      });
      return {
        ...state,
        currentClientAPIKeys: updatedClientAPIKeys
      };
    }
    case 'LOGOUT_REQUEST':
      return initialState;
    default:
      return state;
  }
};

export default client;
