import React from 'react';
import { useSelector } from 'react-redux';
import Menu from '../components/Menu.js';
import CreateCourseForm from '../components/forms/CreateCourseForm';
import ErrorToast from '../components/ErrorToast.js';
import LoadingToast from '../components/LoadingToast.js';
import './NewCoursePage.css';

/**
* NewCoursePage is the page where admins can set the parameters
* for a new course
*/
const NewCoursePage = () => {
  const errorToastActive = useSelector(state => state.componentStates.errorToastActive);
  const loadingToastActive = useSelector(state => state.componentStates.loadingToastActive);
  return (
    <div className="createCoursePageContainer">
      <Menu/>
      <div className="createCourseFormWrapper">
        <CreateCourseForm/>
      </div>
      {errorToastActive &&
        <ErrorToast
          text="Could not create program, please try again"
          timeToClose={10000}
        />
      }
      {loadingToastActive &&
        <LoadingToast
          text="Creating program"
        />
      }
    </div>
  );
};

export default NewCoursePage;
