import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../actions';
import toast_success_icon from '../images/svg/toast_success_icon.svg';
import './Toast.css';


/**
* Toast is a standard toast component that takes text, time to close
* and creates a toast coming from the top of the screen
*/
const Toast = (props) => {
  const dispatch = useDispatch();
  const { text, timeToClose } = props;
  const [toastClassname, setToastClassname] = useState("toast toastHidden");
  const [componentMounted, setComponentMounted] = useState(true);
  const toastOrigin = useSelector(state => state.componentStates.toastOrigin);
  const currentPath = window.location.pathname;

  useEffect(() => {
    setToastClassname("toast toastVisible");
    setTimeout(() => {
      if (componentMounted) {
        setToastClassname("toast toastHidden");
        setTimeout(
          () => dispatch(actions.closeToast.request()),
          500
        );
      }
    },
    timeToClose);
    return (() => {
      setComponentMounted(false);
    });
  }, []);

  if (toastOrigin === currentPath || props.shown) {
    return (
      <div className={toastClassname}>
        <div className="toastContent">
          <img className="toastIcon" src={toast_success_icon} alt="warning icon"/>
          <div>{text}</div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default Toast;
