import React from 'react';
import sorted_ascending_arrows from '../../images/svg/sorted_ascending_arrows.svg';
import sorted_descending_arrows from '../../images/svg/sorted_descending_arrows.svg';
import unsorted_arrows from '../../images/svg/unsorted_arrows2.svg';
import './commonTableComponents.css';


export const renderSortIcon = (ownAttribute, sortAttribute, sortOrder) => {
  if (ownAttribute !== sortAttribute) {
    return <img src={unsorted_arrows} className="sortIcon" alt="unsorted icon"/>;
  }
  return sortOrder === -1 ?
    <img src={sorted_descending_arrows} className="sortIcon descendingSortIcon" alt="descending sort icon"/>
    : <img src={sorted_ascending_arrows} className="sortIcon" alt="ascending sort icon"/>;
};
