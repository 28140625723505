import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import * as actions from '../../actions';
import { useDispatch } from 'react-redux';
import './AnswerChoiceTextarea.css';

/**
* AnswerChoiceTextarea: reusable textarea that can be displayed next to radio buttons
* or checkboxes. It will display the answer text and it is editable.
*/

const AnswerChoiceTextarea = ({ option, isCorrectOption, autoFocus }) => {
  const dispatch = useDispatch();
  const [localAnswerText, setLocalAnswerText] = useState(option.name);
  const [hasLoaded, setHasLoaded] = useState(false);
  const minTextAreaHeight = 16;
  const textareaRef = useRef(null);

  useLayoutEffect(() => {
    textareaRef.current.style.height = "16px";
    textareaRef.current.style.height = `${Math.max(textareaRef.current.scrollHeight, minTextAreaHeight)}px`;
  }, [localAnswerText]);

  useEffect(() => {
    let debouncedSaveFunction = setTimeout(
      () => {
        if (hasLoaded) {
          dispatch(actions.editOption.request({ ...option, name: localAnswerText }));
        }
        setHasLoaded(true);
      },
      500
    );
    return () => clearTimeout(debouncedSaveFunction);
  }, [localAnswerText]);

  return (
    <textarea
      className={isCorrectOption ? "answerChoiceTextarea answerChoiceTextareaCorrect" : "answerChoiceTextarea"}
      placeholder={'Option text'}
      value={localAnswerText}
      ref={textareaRef}
      onChange={(event) => setLocalAnswerText(event.target.value)}
      autoFocus={autoFocus}
    />
  );
};

export default AnswerChoiceTextarea;
