import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '../../actions';
import CourseCoreMoveTooltip from './CourseCoreMoveTooltip';
import CourseCoreDeleteTooltip from './CourseCoreDeleteTooltip';
import draggable_handle from '../../images/svg/draggable_handle.svg';
import draggable_handle_onhover from '../../images/svg/draggable_handle_onhover.svg';
import disclaimer_icon from '../../images/svg/disclaimer_icon.svg';
import empty_checkbox from '../../images/svg/empty_checkbox.svg';
import red_x_mark from '../../images/svg/red_x_mark.svg';
import red_x_mark_onhover from '../../images/svg/red_x_mark_onhover.svg';
import './DisclaimerComponent.css';


/**
* DisclaimerComponent: Checkbox with a descriptive text. Can only be added
* in the welcome module
*/
const DisclaimerComponent = (props) => {
  const dispatch = useDispatch();
  const { moduleId, moduleItemId, text, moduleType, provided, numberOfComponents, parentId, autoFocus } = props;
  const [localText, setLocalText] = useState(text);
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    let debouncedSaveFunction = setTimeout(
      () => {
        if (hasLoaded) {
          dispatch(actions.updateComponent.request(moduleId, moduleItemId, { text: localText }, moduleType));
        }
        setHasLoaded(true);
      },
      500
    );
    return () => clearTimeout(debouncedSaveFunction);
  }, [localText]);

  return (
    <div className="disclaimerComponentContainer">
      <img
        className={numberOfComponents === 1 ? "disclaimerComponentIcon disclaimerComponentIconAlone" : "disclaimerComponentIcon"}
        src={disclaimer_icon}
      />
      {numberOfComponents > 1 &&
        <div className="disclaimerComponentTooltipParent" {...provided.dragHandleProps}>
          <img className="disclaimerComponentDraggableHandle" src={draggable_handle}/>
          <img className="disclaimerComponentDraggableHandleOnHover" src={draggable_handle_onhover}/>
          <CourseCoreMoveTooltip x={"-140%"} y={"-195%"}/>
        </div>
      }
      <div className="disclaimerInputWrapper">
        <img src={empty_checkbox}/>
        <input
          type="text"
          className="disclaimerComponentInput"
          placeholder={'e.g. "I agree with the terms"'}
          value={localText}
          onChange={(event) => setLocalText(event.target.value)}
          autoFocus={autoFocus}
        />
      </div>
      <div className="disclaimerComponentXMarkContainer">
        <img className="disclaimerComponentXMark" src={red_x_mark}/>
        <img
          className="disclaimerComponentXMarkOnHover"
          src={red_x_mark_onhover}
          onClick={() => dispatch(actions.deleteComponent.request(moduleId, moduleItemId, moduleType, parentId))}
        />
        <CourseCoreDeleteTooltip x={"30%"} y={"-195%"}/>
      </div>
    </div>
  );
};

export default DisclaimerComponent;
