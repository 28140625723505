import './StatisticsPage.css';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CourseLeftMenu from '../components/CourseLeftMenu.js';
import ErrorToast from '../components/ErrorToast.js';
import InteractionStatisticsGraph from './InteractionStatisticsGraph';
import InteractionStatisticsModal from '../components/InteractionStatisticsModal';
import LoadingOverlay from '../components/LoadingOverlay';
import LoadingToast from '../components/LoadingToast.js';
import ProgramProgressPiechart from './ProgramProgressPiechart';
import React, { useState } from 'react';
import StudentTable from './StudentTable';
import StudentTableSelect from './StudentTableSelect';
import Toast from '../components/Toast.js';
import { useDashboardTableData } from './use-dashboard-table-data';
import { useDashboardFiltersStore } from './dashboard-filters.store';
import UpdateUserStatus from '../components/updateStatusCacheModals/UpdateUserStatus';

/**
 * Course StatisticsPage - path="/course/:courseId/dashboard"
 */

const StatisticsPage = () => {
  const [interactionIndex, setInteractionIndex] = useState(-1);
  const { filteredCount, program, totalCount } = useDashboardTableData();
  const {
    searchString,
    selectedCompanies,
    selectedDepartments,
    selectedStatuses,
  } = useDashboardFiltersStore();

  const { courseId } = useParams();
  const clientName = useSelector(
    (state) => state.programs.currentProgram.clientName
  );
  const toastActive = useSelector((state) => state.componentStates.toastActive);
  const errorToastActive = useSelector(
    (state) => state.componentStates.errorToastActive
  );
  const loadingToastActive = useSelector(
    (state) => state.componentStates.loadingToastActive
  );
  const loadingResetUserProgress = useSelector(
    (state) => state.componentStates.loadingResetUserProgress
  );

  const areThereFiltersSelected =
    selectedCompanies.length > 0 ||
    selectedDepartments.length > 0 ||
    selectedStatuses.length > 0;

  return (
    <div className="statisticsPageWrap">
      {loadingResetUserProgress && <LoadingOverlay />}
      <div>
        <CourseLeftMenu />
        <div className="statisticsContentWrapper">
          <div className="statisticsTitleAndStatusWrapper">
            <div className="statisticsTitle">
              {areThereFiltersSelected || searchString
                ? `Statistics (${filteredCount}/${totalCount} Users)`
                : 'Statistics'}
            </div>
            <UpdateUserStatus programId={courseId} />
          </div>
          <div className="statisticsProgramName">
            {clientName} - {program.name}
          </div>
          <div className="statisticsChartWrap">
            <InteractionStatisticsGraph
              onInteractionClick={setInteractionIndex}
            />
            <ProgramProgressPiechart />
          </div>
          <div className="statisticsFiltersWrapper">
            <StudentTableSelect />
          </div>
          <div className="statisticsTableWrapper">
            <StudentTable />
          </div>
          <InteractionStatisticsModal
            interactionIndex={interactionIndex}
            onClose={() => setInteractionIndex(-1)}
          />
        </div>
      </div>
      {toastActive && <Toast text="Export downloaded" timeToClose={5000} />}
      {errorToastActive && (
        <ErrorToast
          text={'Could not download export, please try again'}
          timeToClose={10000}
        />
      )}
      {loadingToastActive && <LoadingToast text="Downloading export" />}
    </div>
  );
};

export default StatisticsPage;
