import * as actions from '../actions';
import axios from 'axios';
import history from '../store/history';
import { call, put, takeLatest, all, spawn, delay } from 'redux-saga/effects';
import API from '../api/api';
import Const from '../store/Const';

export function* watchLogin() {
  yield takeLatest(actions.LOGIN.REQUEST, login);
}

export function* watchLogout() {
  yield takeLatest(actions.LOGOUT.REQUEST, logout);
}

export function* watchGetClientSubscriptionData() {
  yield takeLatest(
    actions.GETCLIENTSUBSCRIPTIONDATA.REQUEST,
    getClientSubscriptionData
  );
}

export function* watchGetClientStripeSubscriptionData() {
  yield takeLatest(
    actions.GETCLIENTSTRIPESUBSCRIPTIONDATA.REQUEST,
    getClientStripeSubscriptionData
  );
}

export function* watchToggleCancelSubscription() {
  yield takeLatest(
    actions.TOGGLECANCELSUBSCRIPTION.REQUEST,
    toggleCancelSubscription
  );
}

export function* watchPerformPurchase() {
  yield takeLatest(actions.PERFORMPURCHASE.REQUEST, performPurchase);
}

export function* watchChangeBillingDetails() {
  yield takeLatest(actions.CHANGEBILLINGDETAILS.REQUEST, changeBillingDetails);
}

export function* watchDownloadUserAnswerXls() {
  yield takeLatest(
    actions.DOWNLOADUSERANSWERXLS.REQUEST,
    downloadUserAnswerXls
  );
}

export function* watchDownloadBatchUploadCsvExample() {
  yield takeLatest(
    actions.DOWNLOADBATCHUPLOADCSVEXAMPLE.REQUEST,
    downloadBatchUploadCsvExample
  );
}

export function* watchDownloadBatchUploadXlsxExample() {
  yield takeLatest(
    actions.DOWNLOADBATCHUPLOADXLSXEXAMPLE.REQUEST,
    downloadBatchUploadXlsxExample
  );
}

export function* watchDownloadCourseUserList() {
  yield takeLatest(
    actions.DOWNLOADCOURSEUSERLIST.REQUEST,
    downloadCourseUserList
  );
}

export function* watchPasswordReset() {
  yield takeLatest(actions.RESETPASSWORD.REQUEST, resetPassword);
}

export function* watchForgotPasswordSubmit() {
  yield takeLatest(actions.FORGOTPASSWORDSUBMIT.REQUEST, forgotPasswordSubmit);
}

export function* watchNewPasswordSubmit() {
  yield takeLatest(actions.NEWPASSWORDSUBMIT.REQUEST, newPasswordSubmit);
}

export function* watchGetCourses() {
  yield takeLatest(actions.GETCOURSES.REQUEST, getCourses);
}

export function* watchGetClientNames() {
  yield takeLatest(actions.GETCLIENTNAMES.REQUEST, getClientNames);
}

export function* watchSetClientLogo() {
  yield takeLatest(actions.SETCLIENTLOGO.REQUEST, setClientLogo);
}

export function* watchGetClientLogo() {
  yield takeLatest(actions.GETCLIENTLOGO.REQUEST, getClientLogo);
}

export function* watchGetClients() {
  yield takeLatest(actions.GETCLIENTS.REQUEST, getClients);
}

export function* watchGetAdmins() {
  yield takeLatest(actions.GETADMINS.REQUEST, getAdmins);
}

export function* watchGetUsers() {
  yield takeLatest(actions.GETUSERS.REQUEST, getUsers);
}

export function* watchGetAdmin() {
  yield takeLatest(actions.GETADMIN.REQUEST, getAdmin);
}

export function* watchDeleteClient() {
  yield takeLatest(actions.DELETECLIENT.REQUEST, deleteClient);
}

export function* watchGetClient() {
  yield takeLatest(actions.GETCLIENT.REQUEST, getClient);
}

export function* watchCreateCourse() {
  yield takeLatest(actions.CREATECOURSE.REQUEST, createCourse);
}

export function* watchCopyCourse() {
  yield takeLatest(actions.COPYCOURSE.REQUEST, copyCourse);
}

export function* watchCreateClient() {
  yield takeLatest(actions.CREATECLIENT.REQUEST, createClient);
}

export function* watchUpdateClient() {
  yield takeLatest(actions.UPDATECLIENT.REQUEST, updateClient);
}

export function* watchCreateAdmin() {
  yield takeLatest(actions.CREATEADMIN.REQUEST, createAdmin);
}

export function* watchEditAdmin() {
  yield takeLatest(actions.EDITADMIN.REQUEST, editAdmin);
}

export function* watchGetCourse() {
  yield takeLatest(actions.GETCOURSE.REQUEST, getCourse);
}

export function* watchDeleteCourse() {
  yield takeLatest(actions.DELETECOURSE.REQUEST, deleteCourse);
}

export function* watchEditCourseSettings() {
  yield takeLatest(actions.EDITCOURSESETTINGS.REQUEST, editCourseSettings);
}

export function* watchPublishCourse() {
  yield takeLatest(actions.PUBLISHCOURSE.REQUEST, publishCourse);
}

export function* watchDeleteUser() {
  yield takeLatest(actions.DELETEUSER.REQUEST, deleteUser);
}

export function* watchDeleteSelectedUsers() {
  yield takeLatest(actions.DELETESELECTEDUSERS.REQUEST, deleteSelectedUsers);
}

export function* watchAddComponent() {
  yield takeLatest(actions.ADDCOMPONENT.REQUEST, addComponent);
}

export function* watchUpdateComponent() {
  yield takeLatest(actions.UPDATECOMPONENT.REQUEST, updateComponent);
}

export function* watchDeleteComponent() {
  yield takeLatest(actions.DELETECOMPONENT.REQUEST, deleteComponent);
}

export function* watchUpdateImageComponent() {
  yield takeLatest(actions.UPDATEIMAGECOMPONENT.REQUEST, updateImageComponent);
}

export function* watchAddImageCarouselImage() {
  yield takeLatest(
    actions.ADDIMAGECAROUSELIMAGE.REQUEST,
    addImageCarouselImage
  );
}

export function* watchRemoveImageCarouselImage() {
  yield takeLatest(
    actions.REMOVEIMAGECAROUSELIMAGE.REQUEST,
    removeImageCarouselImage
  );
}

export function* watchGetModuleContent() {
  yield takeLatest(actions.GETMODULECONTENT.REQUEST, getModuleContent);
}

export function* watchUpdateModule() {
  yield takeLatest(actions.UPDATEMODULE.REQUEST, updateModule);
}

export function* watchUpdateComponentOrder() {
  yield takeLatest(actions.UPDATECOMPONENTORDER.REQUEST, updateComponentOrder);
}

export function* watchToggleEnableModule() {
  yield takeLatest(actions.TOGGLEENABLEMODULE.REQUEST, toggleEnableModule);
}

export function* watchAddOption() {
  yield takeLatest(actions.ADDOPTION.REQUEST, addOption);
}

export function* watchDeleteOption() {
  yield takeLatest(actions.DELETEOPTION.REQUEST, deleteOption);
}

export function* watchEditOption() {
  yield takeLatest(actions.EDITOPTION.REQUEST, editOption);
}

export function* watchGetUsersInCourse() {
  yield takeLatest(actions.GETUSERSINCOURSE.REQUEST, getUsersInCourse);
}

export function* watchAddUserToCourse() {
  yield takeLatest(actions.ADDUSERTOCOURSE.REQUEST, addUserToCourse);
}

export function* watchGetCourseUser() {
  yield takeLatest(actions.GETCOURSEUSER.REQUEST, getCourseUser);
}

export function* watchEditCourseUser() {
  yield takeLatest(actions.EDITCOURSEUSER.REQUEST, editCourseUser);
}

export function* watchRemoveUserFromCourse() {
  yield takeLatest(actions.REMOVEUSERFROMCOURSE.REQUEST, removeUserFromCourse);
}

export function* watchRemoveUserFromCourseUserOverview() {
  yield takeLatest(
    actions.REMOVEUSERFROMCOURSEUSEROVERVIEW.REQUEST,
    removeUserFromCourseUserOverview
  );
}

export function* watchRemoveUserFromCourseInStatisticsTable() {
  yield takeLatest(
    actions.REMOVEUSERFROMCOURSEINSTATISTICSTABLE.REQUEST,
    removeUserFromCourseInStatisticsTable
  );
}

export function* watchRemoveAllUsersFromCourse() {
  yield takeLatest(
    actions.REMOVEALLUSERSFROMCOURSE.REQUEST,
    removeAllUsersFromCourse
  );
}

export function* watchResendInvite() {
  yield takeLatest(actions.RESENDINVITE.REQUEST, resendInviteSelectedUsers);
}

export function* watchResendInviteToAllInactiveUsers() {
  yield takeLatest(
    actions.RESENDINVITETOALLINACTIVEUSERS.REQUEST,
    resendInviteToAllInactiveUsers
  );
}

export function* watchResendInviteSelectedUsers() {
  yield takeLatest(
    actions.RESENDINVITESELECTEDUSERS.REQUEST,
    resendInviteSelectedUsers
  );
}

export function* watchGetUserCourseScores() {
  yield takeLatest(actions.GETUSERCOURSESCORES.REQUEST, getUserCourseScores);
}

export function* watchGetUserCourseAnswers() {
  yield takeLatest(actions.GETUSERCOURSEANSWERS.REQUEST, getUserCourseAnswers);
}

export function* watchLoadPushes() {
  yield takeLatest(actions.LOADPUSHES.REQUEST, loadPushes);
}

export function* watchEditOneChoiceOption() {
  yield takeLatest(actions.EDITONECHOICEOPTION.REQUEST, editOneChoiceOption);
}

export function* watchGetAllAPIKeys() {
  yield takeLatest(actions.GETALLAPIKEYS.REQUEST, getAllAPIKeys);
}

export function* watchGetClientAPIKeys() {
  yield takeLatest(actions.GETCLIENTAPIKEYS.REQUEST, getClientAPIKeys);
}

export function* watchEnableAPIForClient() {
  yield takeLatest(actions.ENABLEAPIFORCLIENT.REQUEST, enableAPIForClient);
}

export function* watchDisableAPIForClient() {
  yield takeLatest(actions.DISABLEAPIFORCLIENT.REQUEST, disableAPIForClient);
}

export function* watchCreateAPIKeyForClient() {
  yield takeLatest(
    actions.CREATEAPIKEYFORCLIENT.REQUEST,
    createAPIKeyForClient
  );
}

export function* watchDeleteClientAPIKey() {
  yield takeLatest(actions.DELETECLIENTAPIKEY.REQUEST, deleteClientAPIKey);
}

export function* watchGetUsersFiltersList() {
  yield takeLatest(actions.GETUSERSFILTERSLIST.REQUEST, getUsersFiltersList);
}

export function* watchResetUserProgress() {
  yield takeLatest(actions.RESETUSERPROGRESS.REQUEST, resetUserProgress);
}

export function* watchResetUserProgressUserTable() {
  yield takeLatest(
    actions.RESETUSERPROGRESS_USERTABLE.REQUEST,
    resetUserProgressUserTable
  );
}

export function* watchMultiaddUsersToPrograms() {
  yield takeLatest(
    actions.MULTIADD_USERS_TO_PROGRAMS.REQUEST,
    multiaddUsersToPrograms
  );
}

/**
 * login() requests user login and if successful loads basic program data
 */
export function* login(payload) {
  const { email, password } = payload;
  try {
    const response = yield call(API.login, { email, password });
    if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
      localStorage.setItem('passwordResetEmail', payload.email);
      history.push('changepasswordnocode');
      yield put(actions.login.success({ cognitoUserObject: response }));
    } else {
      axios.defaults.headers.common['Authorization'] =
        response.authenticationResult
          ? response.authenticationResult.idToken
          : response.signInUserSession.idToken.jwtToken;
      const userDetails = JSON.parse(
        response.signInUserSession.idToken.payload.userDetails
      );
      yield put(
        actions.login.success({
          isSuperAdmin: userDetails.isSuperAdmin,
          permissions: userDetails.permissions,
        })
      );
      if (userDetails.permissions[0]) {
        const response = yield call(
          API.getClientLogo,
          userDetails.permissions[0].clientId
        );
        yield put(actions.getClientLogo.success(response.data));
      }
    }
  } catch (error) {
    yield put(actions.login.failure(error));
    localStorage.setItem('passwordResetEmail', payload.email);
    if (error.code === 'PasswordResetRequiredException') {
      try {
        yield call(API.triggerForgotPassword, payload.email);
        yield call(history.push, '/changepassword');
      } catch (error) {
        console.log(error);
      }
    }
  }
}

/**
 * loginWithToken() logs in the user on entering the app if the user has
 * a valid token
 */
export function* loginWithToken() {
  try {
    const currentAuthenticatedUser = yield call(
      API.getCurrentAuthenticatedUser,
      {}
    );
    axios.defaults.headers.common['Authorization'] =
      currentAuthenticatedUser.signInUserSession.idToken.jwtToken;
    const userDetails = JSON.parse(
      currentAuthenticatedUser.signInUserSession.idToken.payload.userDetails
    );
    yield put(
      actions.login.success({
        isSuperAdmin: userDetails.isSuperAdmin,
        permissions: userDetails.permissions,
      })
    );
    if (userDetails.permissions[0]) {
      const response = yield call(
        API.getClientLogo,
        userDetails.permissions[0].clientId
      );
      yield put(actions.getClientLogo.success(response.data));
    }
  } catch (error) {
    yield put(actions.login.failure(error));
  }
}

/**
 * logout()
 */
export function* logout() {
  try {
    yield call(API.logout, {});
  } catch (error) {
    yield put(actions.logout.failure(error));
  } finally {
    localStorage.clear();
    history.push('/login');
  }
}

/**
 * getClientSubscriptionData() gets the client data needed in the subscription
 * view from the database
 */
export function* getClientSubscriptionData() {
  try {
    //TODO: User role will now be taken direcly from the cookie and this endpoint will not be obsolete
    // When this part will be redone this endpoint should be removed
    const userRole = yield call(API.getUserRole, {});
    yield put(actions.getUserRole.success(userRole.data.role));
    //Don't get data if not Client Admin
    const response = yield call(API.getClientSubscriptionData, {});
    yield put(actions.getClientSubscriptionData.success(response.data));
  } catch (error) {
    yield put(actions.getClientSubscriptionData.failure(error));
  }
}

/**
 * getClientStripeSubscriptionData() gets the client data needed in
 * the subscription view from Stripe
 */
export function* getClientStripeSubscriptionData() {
  try {
    //TODO: User role will now be taken direcly from the cookie and this endpoint will not be obsolete
    // When this part will be redone this endpoint should be removed
    const userRole = yield call(API.getUserRole, {});
    yield put(actions.getUserRole.success(userRole.data.role));
    const response = yield call(API.getClientStripeSubscriptionData, {});
    yield put(actions.getClientStripeSubscriptionData.success(response.data));
  } catch (error) {
    yield put(actions.getClientStripeSubscriptionData.failure(error));
  }
}

/**
 * toggleCancelSubscription()
 */
export function* toggleCancelSubscription(payload) {
  const { clientId } = payload;
  try {
    const response = yield call(API.toggleCancelSubscription, clientId);
    yield put(actions.toggleCancelSubscription.success(response.data));
  } catch (error) {
    yield put(actions.toggleCancelSubscription.failure(error));
  }
}

/**
 * changeBillingDetails() initiates a Stripe Checkout session where the customer
 * can change their billing details
 */
export function* changeBillingDetails(payload) {
  const { clientId } = payload;
  try {
    const stripe = window.Stripe(process.env.REACT_APP_STRIPE_API_PUBLIC_KEY);
    const checkoutSessionData = yield call(
      API.initiatePaymentMethodChange,
      clientId
    );
    stripe.redirectToCheckout({
      sessionId: checkoutSessionData.data.sessionId,
    });
  } catch (error) {
    yield put(actions.changeBillingDetails.failure(error));
  }
}

/**
 *
 */
export function* performPurchase(payload) {
  const { clientId } = payload;
  try {
    const stripe = window.Stripe(process.env.REACT_APP_STRIPE_API_PUBLIC_KEY);
    const createSessionResult = yield call(API.createCheckoutSession, clientId);
    stripe.redirectToCheckout({
      sessionId: createSessionResult.data.sessionId,
    });
  } catch (error) {
    yield put(actions.performPurchase.failure(error));
  }
}

/**
 * downloadUserAnswerXls
 */
export function* downloadUserAnswerXls({ programId }) {
  try {
    const { data, headers } = yield call(API.downloadUserAnswerXls, programId);

    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const fileName = headers['content-disposition'].match(
      /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/
    )[2];

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    yield put(actions.downloadUserAnswerXls.success());
  } catch (error) {
    yield put(actions.downloadUserAnswerXls.failure(error));
  }
}

/**
 * downloadBatchUploadCsvExample
 */
export function* downloadBatchUploadCsvExample() {
  try {
    const response = yield call(API.downloadBatchUploadCsvExample, {});
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'users_sample.csv');
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    yield put(actions.downloadBatchUploadCsvExample.failure(error));
  }
}

/**
 * downloadBatchUploadXlsxExample
 */
export function* downloadBatchUploadXlsxExample() {
  try {
    const response = yield call(API.downloadBatchUploadXlsxExample, {});
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: 'application/vnd.ms-excel' })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'xlsx_example.xlsx');
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    yield put(actions.downloadBatchUploadXlsxExample.failure(error));
  }
}

/**
 * downloadCourseUserList
 */
export function* downloadCourseUserList(payload) {
  const { programId } = payload;
  try {
    const response = yield call(API.downloadCourseUserList, programId);
    let contentDisposition = response.request.getResponseHeader(
      'Content-Disposition'
    );
    const filename = contentDisposition.slice(
      contentDisposition.indexOf('filename=') + 9,
      contentDisposition.length
    );
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(
      new Blob([response.data], { type: 'application/vnd.ms-excel' })
    );
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    yield put(actions.downloadCourseUserList.failure(error));
  }
}

/**
 * forgotPasswordSubmit() sends a new password chosen by the user to the
 * cognito server
 */
export function* forgotPasswordSubmit(payload) {
  const { verificationCode, newPassword } = payload;
  const email = localStorage.getItem('passwordResetEmail');
  try {
    yield call(API.forgotPasswordSubmit, {
      email,
      verificationCode,
      newPassword,
    });
    yield put(actions.forgotPasswordSubmit.success({}));
    yield call(history.push, '/');
  } catch (error) {
    yield put(actions.forgotPasswordSubmit.failure(error));
  }
}

/**
 * resetPassword() sends a password reset request to the server
 */
export function* resetPassword(payload) {
  const { username } = payload;
  try {
    localStorage.setItem('passwordResetEmail', username);
    yield call(API.triggerForgotPassword, username);
    yield call(history.push, '/changepassword');
  } catch (error) {
    yield put(actions.resetPassword.failure(error));
  }
}

/**
 * newPasswordSubmit() sends a new password chosen by the user to the
 * cognito server
 */
export function* newPasswordSubmit(payload) {
  const { cognitoUser, newPassword } = payload;
  try {
    const response = yield call(API.completeNewPassword, {
      cognitoUser,
      newPassword,
      requiredAttributes: {},
    });
    axios.defaults.headers.common['Authorization'] =
      response.authenticationResult
        ? response.authenticationResult.idToken
        : response.signInUserSession.idToken.jwtToken;
    const userDetails = JSON.parse(
      response.signInUserSession.idToken.payload.userDetails
    );
    yield put(
      actions.login.success({
        isSuperAdmin: userDetails.isSuperAdmin,
        permissions: userDetails.permissions,
      })
    );
    if (userDetails.permissions[0]) {
      const response = yield call(
        API.getClientLogo,
        userDetails.permissions[0].clientId
      );
      yield put(actions.getClientLogo.success(response.data));
    }
    yield call(history.push, '/');
  } catch (error) {
    yield put(actions.newPasswordSubmit.failure(error));
  }
}

/**
 * getCourses() gets a list of all courses that fit the parameters
 */
export function* getCourses(payload) {
  const {
    clientId,
    pageNumber,
    coursesPerPage,
    sortAttribute,
    sortOrder,
    clientType,
    searchString,
  } = payload;
  try {
    const response = yield call(API.getCourses, {
      clientId,
      pageNumber,
      coursesPerPage,
      sortAttribute,
      sortOrder,
      clientType,
      searchString,
    });
    yield put(actions.getCourses.success(response.data));
  } catch (error) {
    yield put(actions.getCourses.failure(error));
  }
}

/**
 * getClientNames() fetches an array containing all client ids with the
 * corresponding client name
 */
export function* getClientNames() {
  try {
    const response = yield call(API.getClientNames, {});
    yield put(actions.getClientNames.success(response.data));
  } catch (error) {
    yield put(actions.getClientNames.failure(error));
  }
}

/**
 * setClientLogo()
 */
export function* setClientLogo(payload) {
  const { formData, clientId } = payload;
  try {
    const response = yield call(API.setClientLogo, {
      file: formData,
      clientId,
    });
    yield put(actions.setClientLogo.success(response.data));
  } catch (error) {
    yield put(actions.setClientLogo.failure(error));
  }
}

/**
 * getClientLogo()
 */
export function* getClientLogo(payload) {
  const { clientId } = payload;
  try {
    const response = yield call(API.getClientLogo, clientId);
    yield put(actions.getClientLogo.success(response.data));
  } catch (error) {
    yield put(actions.getClientLogo.failure(error));
  }
}

/**
 * getClients()
 */
export function* getClients(payload) {
  const {
    itemsPerPage,
    currentPageNumber,
    searchString,
    sortOrder,
    sortAttribute,
  } = payload;
  try {
    const response = yield call(API.getClients, {
      itemsPerPage,
      currentPageNumber,
      searchString,
      sortOrder,
      sortAttribute,
    });
    yield put(actions.getClients.success(response.data));
  } catch (error) {
    yield put(actions.getClients.failure(error));
  }
}

/**
 * getAdmins()
 */
export function* getAdmins(payload) {
  const {
    clientId,
    itemsPerPage,
    currentPageNumber,
    searchString,
    sortOrder,
    sortAttribute,
  } = payload;
  try {
    const response = yield call(API.getAdmins, {
      clientId,
      itemsPerPage,
      currentPageNumber,
      searchString,
      sortOrder,
      sortAttribute,
    });
    yield put(actions.getAdmins.success(response.data));
  } catch (error) {
    yield put(actions.getAdmins.failure(error));
  }
}

/**
 * getUsers()
 */
export function* getUsers(payload) {
  const {
    itemsPerPage,
    currentPageNumber,
    searchString,
    sortOrder,
    sortAttribute,
  } = payload;
  try {
    const response = yield call(API.getUsers, {
      itemsPerPage,
      currentPageNumber,
      searchString,
      sortOrder,
      sortAttribute,
    });
    yield put(actions.getUsers.success(response.data));
  } catch (error) {
    yield put(actions.getUsers.failure(error));
  }
}

/**
 * getAdmin()
 */
export function* getAdmin(payload) {
  const { id } = payload;
  try {
    const response = yield call(API.getAdmin, id);
    yield put(actions.getAdmin.success(response.data));
  } catch (error) {
    yield put(actions.getAdmin.failure(error));
  }
}

/**
 * deleteClient()
 */
export function* deleteClient(payload) {
  const { clientId, originClientOverview } = payload;
  try {
    const response = yield call(API.deleteClient, clientId);
    yield put(actions.deleteClient.success(response.data));
    if (originClientOverview) {
      yield call(history.push, '/clients');
    }
  } catch (error) {
    yield put(actions.deleteClient.failure(error));
  }
}

/**
 * getClient()
 */
export function* getClient(payload) {
  const { id } = payload;
  try {
    const response = yield call(API.getClient, id);
    yield put(actions.getClient.success(response.data));
  } catch (error) {
    yield put(actions.getClient.failure(error));
  }
}

/**
 * createCourse()
 */
export function* createCourse(payload) {
  const {
    name,
    clientId,
    timelineType,
    interactionDefaultScore,
    from,
    to,
    startDate,
    programCardImage,
  } = payload;
  try {
    let uploadResponse;
    if (programCardImage) {
      uploadResponse = yield call(API.uploadFileToS3, programCardImage);
    }
    const response = yield call(API.createCourse, {
      name,
      clientId,
      type: timelineType,
      interactionDefaultScore,
      dynamicPushTime: { from, to },
      startDate,
      programCardImageURL: uploadResponse?.key,
    });
    yield put(actions.createCourse.success(response.data));
    const courseId = response.data._id;
    yield call(history.push, `/course/${courseId}/lessons`);
  } catch (error) {
    yield put(actions.createCourse.failure(error));
  }
}

/**
 * copyCourse()
 */
export function* copyCourse(payload) {
  const {
    courseId,
    clientId,
    selectedClientId,
    pageNumber,
    coursesPerPage,
    sortAttribute,
    sortOrder,
    clientType,
    searchString,
  } = payload;
  try {
    const response = yield call(API.copyCourse, { courseId, clientId });
    yield put(actions.copyCourse.success(response.data));
    const courseResponse = yield call(API.getCourses, {
      clientId: selectedClientId,
      pageNumber,
      coursesPerPage,
      sortAttribute,
      sortOrder,
      clientType,
      searchString,
    });
    yield put(actions.getCourses.success(courseResponse.data));
  } catch (error) {
    yield put(actions.copyCourse.failure(error));
  }
}

/**
 * createClient()
 */
export function* createClient(payload) {
  const {
    name,
    logo,
    maxActivePrograms,
    maxActiveUsers,
    maxAdmins,
    clientType,
  } = payload;
  try {
    let clientParameters = {
      name,
      maxActivePrograms,
      maxActiveUsers,
      maxAdmins,
      clientType,
    };
    if (logo) {
      const uploadResponse = yield call(API.uploadFileToS3, logo);
      clientParameters.logo = uploadResponse.key;
      const response = yield call(API.createClient, clientParameters);
      yield put(actions.createClient.success(response.data));
      yield call(
        history.push,
        `/clients/${response.data.clientId}/clientoverview`
      );
    } else {
      const response = yield call(API.createClient, clientParameters);
      yield put(actions.createClient.success(response.data));
      yield call(
        history.push,
        `/clients/${response.data.clientId}/clientoverview`
      );
    }
  } catch (error) {
    yield put(actions.createClient.failure(error));
  }
}

/**
 * updateClient()
 */
export function* updateClient(payload) {
  const {
    _id,
    name,
    logo,
    maxActivePrograms,
    maxActiveUsers,
    maxAdmins,
    clientType,
  } = payload;
  try {
    let clientParameters = {
      _id,
      name,
      maxActivePrograms,
      maxActiveUsers,
      maxAdmins,
      clientType,
    };
    if (logo) {
      const uploadResponse = yield call(API.uploadFileToS3, logo);
      clientParameters.logo = uploadResponse.key;
      const response = yield call(API.updateClient, clientParameters);
      yield put(actions.updateClient.success(response.data));
    } else {
      const response = yield call(API.updateClient, clientParameters);
      yield put(actions.updateClient.success(response.data));
    }
  } catch (error) {
    yield put(actions.updateClient.failure(error));
  }
}

/**
 * createAdmin()
 */
export function* createAdmin(payload) {
  const {
    username,
    email,
    isSuperAdmin,
    clientId,
    redirectPath,
    permissionsObject,
  } = payload;
  try {
    if (isSuperAdmin) {
      const response = yield call(API.createAdmin, {
        username,
        email,
        isSuperAdmin,
      });
      yield put(actions.createAdmin.success(response.data));
    } else {
      const response = yield call(API.createAdmin, {
        username,
        email,
        clientId,
        permissionsObject,
      });
      yield put(actions.createAdmin.success(response.data));
      if (redirectPath) {
        yield call(history.push, redirectPath);
      }
    }
  } catch (error) {
    yield put(actions.createAdmin.failure(error.response.data.msg));
  }
}

/**
 * editAdmin(): only the Admin's name and admin settings (permissions) are editable
 */
export function* editAdmin(payload) {
  const { userId, username, email, permissionsObject, isSuperAdmin } = payload;
  try {
    const response = yield call(API.editAdmin, {
      _id: userId,
      username,
      email,
      permissionsObject,
      isSuperAdmin,
    });
    yield put(actions.editAdmin.success(response.data));
  } catch (error) {
    yield put(actions.editAdmin.failure(error));
  }
}

/**
 * getCourse()
 */
export function* getCourse(payload) {
  const { id } = payload;
  try {
    const response = yield call(API.getCourse, id);
    yield put(actions.getCourse.success(response.data));
  } catch (error) {
    yield put(actions.getCourse.failure(error));
  }
}

/**
 * deleteCourse()
 */
export function* deleteCourse(payload) {
  const { id } = payload;
  try {
    yield call(API.deleteCourse, id);
    yield put(actions.deleteCourse.success({ id }));
    yield call(history.push, '/');
  } catch (error) {
    yield put(actions.deleteCourse.failure(error));
  }
}

/**
 * getModuleContent() gets the modules and then the content of the chosen module
 */
export function* getModuleContent(payload) {
  const { courseId, moduleType } = payload;
  try {
    const courseModulesResponse = yield call(API.getCourseModules, courseId);
    yield put(actions.getCourseModules.success(courseModulesResponse.data));
    const moduleToGet = Object.values(courseModulesResponse.data).find(
      (module) => module.type === moduleType
    );
    const moduleContentResponse = yield call(
      API.getModuleContent,
      moduleToGet._id
    );
    // Lessons contain an obsolete module object that previously was the top level item, now we only use the first one
    if (
      moduleType === Const.moduleType.Lessons &&
      moduleContentResponse.data[0].type === Const.moduleItemType.ContentTab
    ) {
      moduleContentResponse.data = moduleContentResponse.data[0];
    }
    yield put(
      actions.getModuleContent.success(moduleContentResponse.data, moduleType)
    );
  } catch (error) {
    yield put(actions.getModuleContent.failure(error));
  }
}

/**
 * editCourseSettings()
 */
export function* editCourseSettings(payload) {
  const { updatedCourse } = payload;
  const image = updatedCourse.programCardImageURL;
  try {
    if (image) {
      const uploadResponse = yield call(API.uploadFileToS3, image);
      const updatedCourseWithImage = {
        ...updatedCourse,
        programCardImageURL: uploadResponse.key,
      };
      const response = yield call(API.editCourseSettings, {
        updatedCourse: updatedCourseWithImage,
      });
      yield put(actions.editCourseSettings.success(response.data));
    } else {
      const response = yield call(API.editCourseSettings, { updatedCourse });
      yield put(actions.editCourseSettings.success(response.data));
    }
  } catch (error) {
    yield put(actions.editCourseSettings.failure(error));
  }
}

/**
 * publishCourse()
 */
export function* publishCourse(payload) {
  const { courseId, publish } = payload;
  try {
    const response = yield call(API.publishCourse, { courseId, publish });
    yield put(actions.publishCourse.success(response.data));
  } catch (error) {
    yield put(actions.publishCourse.failure(error));
  }
}

/**
 * deleteUser()
 */
export function* deleteUser(payload) {
  const {
    userId,
    itemsPerPage,
    currentPageNumber,
    searchString,
    sortOrder,
    sortAttribute,
    isAdmin,
    clientId,
    source,
  } = payload;
  try {
    yield call(API.deleteUser, userId);
    yield put(actions.deleteUser.success());
    if (source === 'editAdminPage') {
      history.push('/admins');
    }
    if (source !== 'editAdminPage') {
      if (isAdmin) {
        const response = yield call(API.getAdmins, {
          itemsPerPage,
          currentPageNumber,
          searchString,
          sortOrder,
          sortAttribute,
          clientId,
        });
        yield put(actions.getAdmins.success(response.data));
      } else {
        const response = yield call(API.getUsers, {
          itemsPerPage,
          currentPageNumber,
          searchString,
          sortOrder,
          sortAttribute,
          clientId,
        });
        yield put(actions.getUsers.success(response.data));
      }
    }
  } catch (error) {
    yield put(actions.deleteUser.failure(error));
  }
}

/**
 * deleteSelectedUsers()
 */
export function* deleteSelectedUsers(payload) {
  const {
    userIds,
    itemsPerPage,
    currentPageNumber,
    searchString,
    sortOrder,
    sortAttribute,
  } = payload;
  try {
    yield all(
      userIds.map((userId) => {
        return call(API.deleteUser, userId);
      })
    );
    const response = yield call(API.getUsers, {
      itemsPerPage,
      currentPageNumber,
      searchString,
      sortOrder,
      sortAttribute,
    });
    yield put(actions.getUsers.success(response.data));
  } catch (error) {
    yield put(actions.deleteUser.failure(error));
  }
}

/**
 *
 */
export function* addComponent(payload) {
  const { componentType, parentId, moduleId, moduleType, data, courseId } =
    payload;
  try {
    const response = yield call(API.addComponent, {
      type: componentType,
      parentId,
      moduleId,
      data,
    });
    yield put(
      actions.addComponent.success(response.data, moduleType, parentId)
    );
    if (componentType === Const.moduleItemType.Lesson) {
      const lessonId = response.data._id;
      const correctFeedbackResponse = yield call(API.addComponent, {
        type: Const.moduleItemType.FeedbackCorrect,
        parentId: lessonId,
        moduleId,
      });
      yield put(
        actions.addComponent.success(
          correctFeedbackResponse.data,
          moduleType,
          lessonId
        )
      );
      const wrongFeedbackResponse = yield call(API.addComponent, {
        type: Const.moduleItemType.FeedbackWrong,
        parentId: lessonId,
        moduleId,
      });
      yield put(
        actions.addComponent.success(
          wrongFeedbackResponse.data,
          moduleType,
          lessonId
        )
      );
      yield call(history.push, `/course/${courseId}/lessons/${lessonId}`);
    }
    if (componentType === Const.moduleItemType.ContentPage) {
      const libraryPageId = response.data._id;
      yield call(history.push, `/course/${courseId}/library/${libraryPageId}`);
    }
  } catch (error) {
    yield put(actions.addComponent.failure(error));
  }
}

/**
 * updateComponent
 */
export function* updateComponent(payload) {
  const { moduleId, moduleItemId, data, moduleType, other } = payload;
  try {
    let dataWithUnsetValues = data;
    if (other && other.data) {
      dataWithUnsetValues = { ...other.data, ...data };
    }
    const response = yield call(API.updateComponent, {
      ...other,
      moduleId,
      _id: moduleItemId,
      data: dataWithUnsetValues,
    });
    yield put(actions.updateComponent.success(response.data, moduleType));
  } catch (error) {
    yield put(actions.updateComponent.failure(error));
  }
}

/**
 * deleteComponent
 */
export function* deleteComponent(payload) {
  const { moduleId, moduleItemId, moduleType, parentId } = payload;
  try {
    yield call(API.deleteComponent, { moduleId, _id: moduleItemId });
    yield put(
      actions.deleteComponent.success(
        { _id: moduleItemId },
        moduleType,
        parentId
      )
    );
  } catch (error) {
    yield put(actions.deleteComponent.failure(error));
  }
}

/**
 * updateImageComponent
 */
export function* updateImageComponent(payload) {
  const { moduleId, moduleItemId, image, moduleType } = payload;
  try {
    const uploadResponse = yield call(API.uploadFileToS3, image);
    const response = yield call(API.updateImageComponent, {
      moduleId,
      _id: moduleItemId,
      key: uploadResponse.key,
    });
    yield put(actions.updateImageComponent.success(response.data, moduleType));
  } catch (error) {
    yield put(actions.updateImageComponent.failure(error));
  }
}

/**
 * addImageCarouselImage
 */
export function* addImageCarouselImage(payload) {
  const { moduleId, moduleItemId, image, moduleType } = payload;
  try {
    const uploadResponse = yield call(API.uploadFileToS3, image);
    let response = yield call(API.addImageCarouselImage, {
      moduleId,
      _id: moduleItemId,
      key: uploadResponse.key,
    });
    response.data.data.keys = response.data.data.keys.map((key) => {
      return { name: key };
    });
    yield put(actions.addImageCarouselImage.success(response.data, moduleType));
  } catch (error) {
    yield put(actions.addImageCarouselImage.failure(error));
  }
}

/**
 * removeImageCarouselImage
 */
export function* removeImageCarouselImage(payload) {
  const { moduleItemId, image, moduleType } = payload;
  try {
    let response = yield call(API.removeImageCarouselImage, {
      moduleItemId,
      key: image,
    });
    response.data.data.keys = response.data.data.keys.map((key) => {
      return { name: key };
    });
    yield put(
      actions.removeImageCarouselImage.success(response.data, moduleType)
    );
  } catch (error) {
    yield put(actions.removeImageCarouselImage.failure(error));
  }
}

/**
 * updateModule
 */
export function* updateModule(payload) {
  const { moduleId, data } = payload;
  try {
    let response = yield call(API.updateModule, {
      moduleId,
      ...data,
      _id: moduleId,
    });
    yield put(actions.updateModule.success(response.data));
  } catch (error) {
    yield put(actions.updateModule.failure(error));
  }
}

/**
 * updateComponentOrder
 */
export function* updateComponentOrder(payload) {
  const { moduleId, moduleType, items } = payload;
  try {
    yield call(API.updateComponentOrder, { moduleId, items });
    yield put(actions.updateComponentOrder.success(items, moduleType));
  } catch (error) {
    yield put(actions.updateComponentOrder.failure(error));
  }
}

/**
 * toggleEnableModule
 */
export function* toggleEnableModule(payload) {
  const { moduleId, isEnabled } = payload;
  try {
    const response = yield call(API.toggleEnableModule, {
      moduleId,
      isEnabled,
    });
    yield put(actions.toggleEnableModule.success(response));
  } catch (error) {
    yield put(actions.toggleEnableModule.failure(error));
  }
}

/**
 * addOption
 */
export function* addOption(payload) {
  const { moduleId, moduleItemId } = payload;
  try {
    const response = yield call(API.addOption, { moduleId, moduleItemId });
    yield put(actions.addOption.success(response.data));
  } catch (error) {
    yield put(actions.addOption.failure(error));
  }
}

/**
 * deleteOption
 */
export function* deleteOption(payload) {
  const { moduleId, moduleItemId, optionId } = payload;
  try {
    const response = yield call(API.deleteOption, {
      moduleId,
      moduleItemId,
      optionId,
    });
    yield put(
      actions.deleteOption.success(response.data, moduleItemId, optionId)
    );
  } catch (error) {
    yield put(actions.deleteOption.failure(error));
  }
}

/**
 * editOption
 */
export function* editOption(payload) {
  const { optionData } = payload;
  try {
    const response = yield call(API.editOption, {
      ...optionData,
      optionId: optionData._id,
    });
    yield put(
      actions.editOption.success(
        response.data,
        optionData.moduleItemId,
        optionData._id
      )
    );
  } catch (error) {
    yield put(actions.editOption.failure(error));
  }
}

/**
 * getUsersInCourse
 */
export function* getUsersInCourse(payload) {
  const {
    courseId,
    currentPageNumber,
    itemsPerPage,
    searchString,
    sortAttribute,
    sortOrder,
    companiesFilter,
    departmentsFilter,
  } = payload;
  try {
    const response = yield call(API.getUsersInCourse, {
      id: courseId,
      currentPageNumber,
      itemsPerPage,
      searchString,
      sortAttribute,
      sortOrder,
      companies: companiesFilter,
      departments: departmentsFilter,
    });
    yield put(actions.getUsersInCourse.success(response.data));
  } catch (error) {
    yield put(actions.getUsersInCourse.failure(error));
  }
}

/**
 * addUserToCourse
 */
export function* addUserToCourse(payload) {
  const { courseId, username, email, company, department } = payload;
  try {
    const response = yield call(API.addUserToCourse, {
      programId: courseId,
      username,
      email,
      company,
      department,
    });
    yield put(actions.addUserToCourse.success(response.data));
  } catch (error) {
    yield put(actions.addUserToCourse.failure(error.response.data.msg));
  }
}

/**
 * getCourseUser
*/
export function* getCourseUser(payload) {
  const { userId } = payload;
  try {
    const response = yield call(API.getCourseUser, { userId });
    yield put(actions.getCourseUser.success(response.data));
  } catch (error) {
    yield put(actions.getCourseUser.failure(error));
  }
}

/**
 * editCourseUser
 */
export function* editCourseUser(payload) {
  const { updatedUser } = payload;
  try {
    const response = yield call(API.editCourseUser, { updatedUser });
    yield put(actions.editCourseUser.success(response.data));
  } catch (error) {
    yield put(actions.editCourseUser.failure(error));
  }
}

/**
 * removeUserFromCourse
*/
export function* removeUserFromCourse(payload) {
  const {
    courseId,
    userId,
    currentPageNumber,
    itemsPerPage,
    searchString,
    sortAttribute,
    sortOrder,
    source,
  } = payload;
  try {
    yield call(API.removeUserFromCourse, { courseId, userId });
    yield put(actions.removeUserFromCourse.success());
    let response = {};
    if (source === 'usersDataTable') {
      response = yield call(API.getUsersInCourse, { id: courseId });
    } else {
      response = yield call(API.getUsersInCourse, {
        id: courseId,
        currentPageNumber,
        itemsPerPage,
        searchString,
        sortAttribute,
        sortOrder,
      });
    }
    yield put(actions.getUsersInCourse.success(response.data));
  } catch (error) {
    yield put(actions.removeUserFromCourse.failure(error));
  }
}

/**
 * removeUserFromCourseUserOverview
 */
export function* removeUserFromCourseUserOverview(payload) {
  const { courseId, userId } = payload;
  try {
    yield call(API.removeUserFromCourse, { courseId, userId });
    yield put(actions.removeUserFromCourse.success());
    yield call(history.push, `/course/${courseId}/dashboard`);
  } catch (error) {
    yield put(actions.removeUserFromCourse.failure(error));
  }
}

/**
 * removeUserFromCourseInStatisticsTable - Same as removeUserFromCourse but
 * after removing the user the statistics page data is loaded
 */
export function* removeUserFromCourseInStatisticsTable(payload) {
  const { courseId, userId } = payload;
  try {
    yield call(API.removeUserFromCourse, {
      courseId,
      excludedUsersIds: [],
      selectionInverted: false,
      selectedUsersIds: [userId],
    });
    yield put(actions.removeUserFromCourseInStatisticsTable.success(userId));
  } catch (error) {
    yield put(actions.removeUserFromCourseInStatisticsTable.failure(error));
  }
}

/**
 * removeAllUsersFromCourse
 */
export function* removeAllUsersFromCourse(payload) {
  const { courseId } = payload;
  try {
    yield call(API.removeAllUsersFromCourse, courseId);
    yield put(actions.removeAllUsersFromCourse.success());
  } catch (error) {
    yield put(actions.removeAllUsersFromCourse.failure(error));
  }
}

/**
 * resendInviteToAllInactiveUsers sends another welcome email to all users
 * who have not started the current course
 */
export function* resendInviteToAllInactiveUsers(payload) {
  const { courseId } = payload;
  try {
    yield call(API.resendInviteToAllInactiveUsers, courseId);
    yield put(actions.resendInviteToAllInactiveUsers.success());
  } catch (error) {
    yield put(actions.resendInviteToAllInactiveUsers.failure(error));
  }
}

/**
 * resendInviteSelectedUsers: Will resend the invitation email to all Selected students
 * from the User list within a Program, only if the selected student has a status of
 * Not started or In progress (has not finished the program yet)
 */
export function* resendInviteSelectedUsers(payload) {
  try {
    yield call(API.resendInvite, payload);
    yield put(actions.resendInviteSelectedUsers.success());
  } catch (error) {
    yield put(actions.resendInviteSelectedUsers.failure(error));
  }
}

/**
 * getUserCourseScores gets an object with precalculated scores for the user
 * in all granularities (course, chapter, lesson etc.)
 */
export function* getUserCourseScores(payload) {
  const { courseId, userId } = payload;
  try {
    const response = yield call(API.getUserCourseScores, { courseId, userId });
    yield put(actions.getUserCourseScores.success(response.data[0]));
  } catch (error) {
    yield put(actions.getUserCourseScores.failure(error));
  }
}

/**
 * Load Pushes
 */
export function* loadPushes(payload) {
  const { courseId, userId } = payload;
  try {
    const response = yield call(API.loadPushes, { courseId, userId });
    yield put(actions.loadPushes.success(response.data));
  } catch (error) {
    yield put(actions.loadPushes.failure(error));
  }
}

/**
 * getUserCourseAnswers gets the user's answers to all questions for the given
 * course
 */
export function* getUserCourseAnswers(payload) {
  const { courseId, userId } = payload;
  try {
    const response = yield call(API.getUserCourseAnswers, { courseId, userId });
    yield put(actions.getUserCourseAnswers.success(response.data));
  } catch (error) {
    yield put(actions.getUserCourseAnswers.failure(error));
  }
}

/**
 * editOneChoiceOption: special case for the Lesson content component "One Choice", in which we set
 * a new answer option to the correct one and update the previous one to be incorrect now
 */
export function* editOneChoiceOption(payload) {
  const { oldOptionData, newOptionData } = payload;
  const oldOptionDataLength = Object.keys(oldOptionData).length;
  try {
    let oldOptionResponse = {};
    if (oldOptionDataLength > 1) {
      oldOptionResponse = yield call(API.editOption, {
        ...oldOptionData,
        optionId: oldOptionData._id,
      });
    }
    const newOptionResponse = yield call(API.editOption, {
      ...newOptionData,
      optionId: newOptionData._id,
    });
    yield put(
      actions.editOneChoiceOption.success(
        oldOptionResponse.data,
        newOptionResponse.data,
        newOptionData.moduleItemId
      )
    );
  } catch (error) {
    yield put(actions.editOneChoiceOption.failure(error));
  }
}

/**
 * getAllAPIKeys() will get the data on all existing API keys from all Clients
 */
export function* getAllAPIKeys() {
  try {
    const response = yield call(API.getAllAPIKeys, {});
    yield put(actions.getAllAPIKeys.success(response.data));
  } catch (error) {
    yield put(actions.getAllAPIKeys.failure(error));
  }
}

/**
 * getClientAPIKeys() will get the data on the API Keys for a specific Client
 */
export function* getClientAPIKeys(payload) {
  const { clientId } = payload;
  try {
    const response = yield call(API.getClientAPIKeys, { clientId });
    yield put(actions.getClientAPIKeys.success(response.data));
  } catch (error) {
    yield put(actions.getClientAPIKeys.failure(error));
  }
}

/**
 * enableAPIForClient() will enable the public API feature for a specific Client
 */
export function* enableAPIForClient(payload) {
  const { clientId } = payload;
  try {
    yield call(API.enableAPIForClient, { clientId });
    yield put(actions.enableAPIForClient.success());
  } catch (error) {
    yield put(actions.enableAPIForClient.failure(error));
  }
}

/**
 * disableAPIForClient() will disable the public API feature for a specific Client
 */
export function* disableAPIForClient(payload) {
  const { clientId } = payload;
  try {
    yield call(API.disableAPIForClient, { clientId });
    yield put(actions.disableAPIForClient.success());
  } catch (error) {
    yield put(actions.disableAPIForClient.failure(error));
  }
}

/**
 * createAPIKeyForClient() will create an API Key for a specific Client
 */
export function* createAPIKeyForClient(payload) {
  const { clientId } = payload;
  try {
    yield call(API.createAPIKeyForClient, { clientId });
    // The API key takes some seconds to be created in AWS, so this delay is added
    // in order to be able to show the key immediately after it has been created
    yield delay(5000);
    const response = yield call(API.getClientAPIKeys, { clientId });
    yield put(actions.getClientAPIKeys.success(response.data));
    yield put(actions.createAPIKeyForClient.success());
  } catch (error) {
    yield put(actions.createAPIKeyForClient.failure(error));
  }
}

/**
 * deleteClientAPIKey() will delete a specific single API Key from a Client
 */
export function* deleteClientAPIKey(payload) {
  const { clientId, keyId } = payload;
  try {
    yield call(API.deleteClientAPIKey, { clientId, keyId });
    yield put(actions.deleteClientAPIKey.success(keyId));
  } catch (error) {
    yield put(actions.deleteClientAPIKey.failure(error));
  }
}

/**
 * getUsersFiltersList() will return a list of all the companies and departments to which
 * all the students in a specific program belong to
 */
export function* getUsersFiltersList(payload) {
  const { courseId } = payload;
  try {
    const response = yield call(API.getUsersFiltersList, {
      programId: courseId,
    });
    yield put(actions.getUsersFiltersList.success(response.data));
  } catch (error) {
    yield put(actions.getUsersFiltersList.failure(error));
  }
}

/**
 * resetUserProgress: will reset a specific User's progress within a program back to zero
 * This is done by removing the user from the program and adding the user again
 * This version is used from the Statistics (Dashboard page) Students table within a Program
 */
export function* resetUserProgress(payload) {
  const { userToReset, courseId } = payload;
  const userId = userToReset.id;
  const username = userToReset.name;
  const email = userToReset.email;
  const company = userToReset.company || '';
  const department = userToReset.department || '';

  try {
    const removeUserResponse = yield call(API.removeUserFromCourse, {
      courseId,
      excludedUsersIds: [],
      selectionInverted: false,
      selectedUsersIds: [userId],
    });
    if (removeUserResponse.data.success) {
      yield put(actions.removeUserFromCourseInStatisticsTable.success(userId));
      const addUserToProgramResponse = yield call(API.addUserToCourse, {
        programId: courseId,
        username,
        email,
        company,
        department,
      });
      if (addUserToProgramResponse.data.success) {
        yield put(actions.resetUserProgress.success(userToReset));
      }
    }
  } catch (error) {
    yield put(actions.resetUserProgress.failure(error));
  }
}

/**
 * resetUserProgressUserTable: will reset a specific User's progress within a program back to zero
 * This version is used from the User Table within a Program
 */
export function* resetUserProgressUserTable(payload) {
  const {
    userToReset,
    courseId,
    currentPageNumber,
    itemsPerPage,
    searchString,
    sortAttribute,
    sortOrder,
    source,
  } = payload;
  const username = userToReset.username;
  const email = userToReset.email;
  const company = userToReset.company || '';
  const department = userToReset.department || '';

  try {
    const removeUserResponse = yield call(API.removeUserFromCourse, {
      courseId,
      excludedUsersIds: [],
      selectionInverted: false,
      selectedUsersIds: [userToReset._id],
    });
    if (removeUserResponse.data.success) {
      yield put(actions.removeUserFromUserTable.request(userToReset._id));
      const addUserToProgramResponse = yield call(API.addUserToCourse, {
        programId: courseId,
        username,
        email,
        company,
        department,
      });
      if (addUserToProgramResponse.data.success) {
        let response = {};
        if (source === 'usersDataTable') {
          response = yield call(API.getUsersInCourse, { id: courseId });
        } else {
          response = yield call(API.getUsersInCourse, {
            id: courseId,
            currentPageNumber,
            itemsPerPage,
            searchString,
            sortAttribute,
            sortOrder,
          });
        }
        yield put(actions.getUsersInCourse.success(response.data));
        yield put(actions.resetUserProgressUserTable.success());
      }
    }
  } catch (error) {
    yield put(actions.resetUserProgressUserTable.failure(error));
  }
}

/**
 * multiaddUsersToPrograms: Feature on the Users data table within a program in which several Users can
 * simmultaneously be added to several chosen Programs from the same Client
 */
export function* multiaddUsersToPrograms(payload) {
  const { params } = payload;
  try {
    const response = yield call(API.multiaddUsersToPrograms, params);
    yield put(actions.multiaddUsersToPrograms.success(response.data));
  } catch (error) {
    yield put(actions.multiaddUsersToPrograms.failure(error));
  }
}

/**
 * rootSaga will run initial request to load data and start daemons to
 * handle all incoming requests for the application.
 */
const makeRestartable = (saga) =>
  function* () {
    yield spawn(function* () {
      let delayTimer = 1000;
      while (delayTimer < 300000) {
        try {
          yield call(saga);
          console.error(
            'unexpected root saga termination. The root sagas are supposed to be sagas that live during the whole app lifetime!',
            saga
          );
        } catch (e) {
          delayTimer += 5000;
          console.error('Saga error, the saga will be restarted', e);
        }
        yield delay(delayTimer); // Avoid infinite failures blocking app TODO use backoff retry policy...
      }
    });
  };

const rootSagas = [
  watchGetClientSubscriptionData,
  watchGetClientStripeSubscriptionData,
  watchToggleCancelSubscription,
  watchPerformPurchase,
  watchChangeBillingDetails,
  watchDownloadUserAnswerXls,
  watchDownloadBatchUploadCsvExample,
  watchDownloadBatchUploadXlsxExample,
  watchDownloadCourseUserList,
  watchForgotPasswordSubmit,
  watchNewPasswordSubmit,
  watchLogin,
  watchLogout,
  watchPasswordReset,
  watchGetCourses,
  watchGetClientNames,
  watchSetClientLogo,
  watchGetClientLogo,
  watchGetClients,
  watchGetAdmins,
  watchGetUsers,
  watchGetAdmin,
  watchDeleteClient,
  watchGetClient,
  watchCreateCourse,
  watchCopyCourse,
  watchCreateClient,
  watchUpdateClient,
  watchCreateAdmin,
  watchEditAdmin,
  watchGetCourse,
  watchDeleteCourse,
  watchEditCourseSettings,
  watchPublishCourse,
  watchDeleteUser,
  watchDeleteSelectedUsers,
  watchUpdateComponent,
  watchDeleteComponent,
  watchUpdateImageComponent,
  watchAddImageCarouselImage,
  watchRemoveImageCarouselImage,
  watchGetModuleContent,
  watchAddComponent,
  watchUpdateModule,
  watchUpdateComponentOrder,
  watchToggleEnableModule,
  watchAddOption,
  watchDeleteOption,
  watchEditOption,
  watchGetUsersInCourse,
  watchAddUserToCourse,
  watchGetCourseUser,
  watchEditCourseUser,
  watchRemoveUserFromCourse,
  watchRemoveUserFromCourseUserOverview,
  watchRemoveUserFromCourseInStatisticsTable,
  watchRemoveAllUsersFromCourse,
  watchResendInvite,
  watchResendInviteToAllInactiveUsers,
  watchResendInviteSelectedUsers,
  watchGetUserCourseScores,
  watchGetUserCourseAnswers,
  watchLoadPushes,
  watchEditOneChoiceOption,
  watchGetAllAPIKeys,
  watchGetClientAPIKeys,
  watchEnableAPIForClient,
  watchDisableAPIForClient,
  watchCreateAPIKeyForClient,
  watchDeleteClientAPIKey,
  watchGetUsersFiltersList,
  watchResetUserProgress,
  watchResetUserProgressUserTable,
  watchMultiaddUsersToPrograms,
].map(makeRestartable);

export default function* rootSaga() {
  yield all(rootSagas.map((saga) => call(saga)));
  yield call(loginWithToken);
}
