import React from 'react';
import './CourseCoreDeleteTooltip.css';

/**
* CourseCoreDeleteTooltip: Reusable tooltip displayed when hovering on the X Mark
* (delete icon) of all Course Core Content Items
*/
const CourseCoreDeleteTooltip = (props) => {
  const { x, y } = props;
  return (
    <div
      className="courseCoreDeleteTooltip"
      style={{'transform': `translate(${x}, ${y})`}}
    >
      Delete
    </div>
  );
};

export default CourseCoreDeleteTooltip;
