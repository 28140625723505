import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useOutsideClick } from '../utils/componentUtils';
import * as actions from '../actions';
import downward_arrow_smooth from '../images/svg/downward_arrow_smooth.svg';
import './DropdownComponent.css';


/**
* DropdownComponent is a reusable dropdown component
*/
const DropdownComponent = ({ name, defaultText, optionTexts, optionValues }) => {
  const dropdownRef = useRef();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
  useOutsideClick(dropdownRef, () => {
    setOpen(false);
  });
  useEffect(() => {
    dispatch(actions.setSelectedDropdownValue.request(name, optionValues[selectedItemIndex] || null));
  }, [selectedItemIndex]);
  const dropdownMenuClasses = open ? "dropdownComponentMenu" : "dropdownComponentMenu dropdownComponentMenuClosed";
  return (
    <div
      className={selectedItemIndex > -1 ? "dropdownComponentTextBold dropdownComponent" : "dropdownComponent dropdownComponentTextLight"}
      ref={dropdownRef}
      onClick={() => setOpen(!open)}
    >
      <div className="dropdownComponentText">
        {selectedItemIndex > -1 ? optionTexts[selectedItemIndex] : defaultText}
      </div>
      <img className="dropdownComponentIcon" src={downward_arrow_smooth} alt="downward arror"/>
      <div className={dropdownMenuClasses} style={{maxHeight: getDropdownMenuMaxheight(dropdownRef)}}>
        {selectedItemIndex !== -1 &&
          <div className="dropdownComponentMenuItem" onClick={() => setSelectedItemIndex(-1)}>
            <i>
              clear filter
            </i>
          </div>
        }
        {optionTexts.map((option, index) => {
          return (
            <div
              className={
                selectedItemIndex === index ?
                "dropdownComponentSelectedMenuItem dropdownComponentMenuItem"
                : "dropdownComponentMenuItem"
              }
              key={index}
              onClick={() => setSelectedItemIndex(index)}
            >
              {option}
            </div>
          );
        })}
      </div>
    </div>
  );
};

/**
* getDropdownMenuMaxheight calculates the maxHeight of the dropdown menu.
* The requirements are: The menu should fill out the whole page except
* the bottom 70px. The menu should also be at least 200px long if the content
* has at least that height, otherwise the menu should have the height of
* it's content.
*/
const getDropdownMenuMaxheight = (ref) => {
  const { top, height } = ref.current ?
    ref.current.getBoundingClientRect()
    : { top: 0, height: 0 };
  const bottomMargin = 70;
  const maxHeight = window.innerHeight - top - height - bottomMargin;
  return maxHeight >= 200 ? maxHeight + 'px' : 'min(200px, min-content)';
};


export default DropdownComponent;
