import React from 'react';
import CourseFilterAndSearchBar from './CourseFilterAndSearchBar';
import CourseTable from './tables/CourseTable';
import PaginationBar from './PaginationBar';
import './CourseList.css';


/**
* CourseList is the wrapper for the table with courses,
* the search and filter component and the pagination
*/
const CourseList = () => {
  return (
    <div className="courseListContainer">
      <div className="courseListTitle">PROGRAM LIST</div>
      <CourseFilterAndSearchBar/>
      <CourseTable/>
      <div className="courseListPaginationContainer">
        <PaginationBar/>
      </div>
    </div>
  );
};

export default CourseList;
