import React, { useEffect, useRef } from "react";
import * as actions from '../actions';
import Const from '../store/Const';
import TitleComponent from '../components/courseComponents/TitleComponent';
import ParagraphComponent from '../components/courseComponents/ParagraphComponent';
import VideoComponent from '../components/courseComponents/VideoComponent';
import LinkComponent from '../components/courseComponents/LinkComponent';
import ImageComponent from '../components/courseComponents/ImageComponent';
import MultipleImagesComponent from '../components/courseComponents/MultipleImagesComponent';
import DisclaimerComponent from '../components/courseComponents/DisclaimerComponent';
import OneChoiceComponent from '../components/courseComponents/OneChoiceComponent';
import MultiChoiceComponent from '../components/courseComponents/MultiChoiceComponent';
import ScaleComponent from '../components/courseComponents/ScaleComponent';
import CorrectSpellingComponent from '../components/courseComponents/CorrectSpellingComponent';
import OpenAnswerComponent from '../components/courseComponents/OpenAnswerComponent';
import sorted_ascending_arrows from '../images/svg/sorted_ascending_arrows.svg';
import sorted_descending_arrows from '../images/svg/sorted_descending_arrows.svg';
import unsorted_arrows from '../images/svg/unsorted_arrows2.svg';
import sorting_arrow_inactive from '../images/styleguideComponentsImages/sorting_arrow_inactive.svg';
import sorting_arrow_descending from '../images/styleguideComponentsImages/sorting_arrow_descending.svg';
import '../components/courseCore/Common.css';


/**
* useOutsideClick detects if a click targets the element referenced in ref.
* If it doesn't the callback function handler is called
*/
export const useOutsideClick = (ref, handler) => {
  useEffect(
    () => {
      const listener = event => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    [ref, handler]
  );
};

/**
 * usePrevious is a custom hook used for comparing the current state to
 * a previous state
 */
export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

/**
* changeSortOrder handles changes in sort order in a table
*/
export const changeSortOrder = (ownAttribute, sortAttribute, setSortAttribute, sortOrder, setSortOrder) => {
  if (ownAttribute === sortAttribute) {
    setSortOrder(-sortOrder);
  }
  else {
    setSortAttribute(ownAttribute);
    setSortOrder(1);
  }
};

/**
* handleSortRequest will set sorting order on a table as "asc" or "desc"
* And also set the property to sort by
*/
export const handleSortRequest = (property, order, setOrder, orderBy, setOrderBy) => {
  const isDesc = orderBy === property && order === 'desc';
  setOrder(isDesc ? 'asc' : 'desc');
  setOrderBy(property);
};

/**
* renderArrow will generate the sorting arrow for a table's column header
*/
export const renderArrow = (property, order, orderBy) => {
  if (property !== orderBy) {
    return <img className="studentTableSortArrow" src={unsorted_arrows} alt="unsorted icon"/>;
  }
  return order === 'desc' ?
    <img className="studentTableSortArrow" src={sorted_descending_arrows} alt="descending sort icon"/>
    : <img className="studentTableSortArrow" src={sorted_ascending_arrows} alt="ascending sort icon"/>;
};

/**
* renderArrow will generate the sorting arrow for a table's column header.
* This one will generate the arrows for the tables with the new design according
* to the new styleguide.
*/
export const renderStyleguideArrow = (property, order, orderBy) => {
  if (property !== orderBy) {
    return <img className="userDataSortArrow" src={sorting_arrow_inactive} alt="unsorted icon"/>;
  }
  return order === 'desc' ?
    <img className="userDataSortArrow" src={sorting_arrow_descending} alt="descending sort icon"/>
    : <img className="userDataSortArrowAscending" src={sorting_arrow_descending} alt="ascending sort icon"/>;
};

/**
* clientAdminPermissionsRole will render a string which shows a list of the permissions
* a Client Admin has
*/
export const clientAdminPermissionsRole = admin => {
  let permissionsArray = [];
  if (admin.permissions[0].fullRights) {
    return 'Full rights';
  }
  if (admin.permissions[0].read) {
    permissionsArray.push('Read');
  }
  if (admin.permissions[0].edit) {
    permissionsArray.push('Edit');
  }
  if (admin.permissions[0].invite) {
    permissionsArray.push('Invite');
  }
  return permissionsArray.join(', ');
};

/**
* renderCourseComponent renders the right course component according to the
* component type
*/
export const renderCourseComponent = (component, numberOfComponents, moduleType, provided, parentId) => {
  switch (component.type) {
    case Const.moduleItemType.Title:
      return renderTitleComponent(component, numberOfComponents, moduleType, provided, parentId);
    case Const.moduleItemType.Paragraph:
      return renderParagraphComponent(component, numberOfComponents, moduleType, provided, parentId);
    case Const.moduleItemType.Image:
      return renderImageComponent(component, numberOfComponents, moduleType, provided, parentId);
    case Const.moduleItemType.Video:
      return renderVideoComponent(component, numberOfComponents, moduleType, provided, parentId);
    case Const.moduleItemType.Link:
      return renderLinkComponent(component, numberOfComponents, moduleType, provided, parentId);
    case Const.moduleItemType.ImageCarousel:
      return renderImageCarouselComponent(component, numberOfComponents, moduleType, provided, parentId);
    case Const.moduleItemType.DisclaimerCheckbox:
      return renderDisclaimerComponent(component, numberOfComponents, moduleType, provided, parentId);
    // QuestionDropdown is being discontinued, but needs to be supported for old course that still have it.
    // It will render same component as QuestionOneChoice
    case Const.moduleItemType.QuestionOneChoice:
    case Const.moduleItemType.QuestionDropdown:
      return renderOneChoiceComponent(component, numberOfComponents, moduleType, provided, parentId);
    case Const.moduleItemType.QuestionCheckboxes:
      return renderMultiChoiceComponent(component, numberOfComponents, moduleType, provided, parentId);
    case Const.moduleItemType.QuestionScale:
      return renderScaleComponent(component, numberOfComponents, moduleType, provided, parentId);
    case Const.moduleItemType.QuestionText:
      return renderCorrectSpellingComponent(component, numberOfComponents, moduleType, provided, parentId);
    case Const.moduleItemType.QuestionParagraph:
      return renderOpenAnswerComponent(component, numberOfComponents, moduleType, provided, parentId);
    default:
      return null;
  }
};

/**
*
*/
const renderTitleComponent = (component, numberOfComponents, moduleType, provided, parentId) => {
  return (
    <div key={component._id}>
      <TitleComponent
        moduleId={component.moduleId}
        moduleItemId={component._id}
        text={component.data.text || ''}
        moduleType={moduleType}
        provided={provided}
        numberOfComponents={numberOfComponents}
        parentId={parentId}
        autoFocus={component.autoFocus}
      />
    </div>
  );
};

/**
*
*/
const renderParagraphComponent = (component, numberOfComponents, moduleType, provided, parentId) => {
  return (
    <div key={component._id}>
      <ParagraphComponent
        moduleId={component.moduleId}
        moduleItemId={component._id}
        text={component.data.text || ''}
        moduleType={moduleType}
        provided={provided}
        numberOfComponents={numberOfComponents}
        parentId={parentId}
        autoFocus={component.autoFocus}
      />
    </div>
  );
};

/**
*
*/
const renderImageComponent = (component, numberOfComponents, moduleType, provided, parentId) => {
  return (
    <div key={component._id}>
      <ImageComponent
        moduleId={component.moduleId}
        moduleItemId={component._id}
        imageUrl={component.data.key || ''}
        moduleType={moduleType}
        provided={provided}
        numberOfComponents={numberOfComponents}
        parentId={parentId}
      />
    </div>
  );
};

/**
*
*/
const renderVideoComponent = (component, numberOfComponents, moduleType, provided, parentId) => {
  return (
    <div key={component._id}>
      <VideoComponent
        moduleId={component.moduleId}
        moduleItemId={component._id}
        videoUrl={component.data.url || ''}
        moduleType={moduleType}
        provided={provided}
        numberOfComponents={numberOfComponents}
        parentId={parentId}
        autoFocus={component.autoFocus}
      />
    </div>
  );
};

/**
*
*/
const renderLinkComponent = (component, numberOfComponents, moduleType, provided, parentId) => {
  return (
    <div key={component._id}>
      <LinkComponent
        moduleId={component.moduleId}
        moduleItemId={component._id}
        text={component.data.text || ''}
        url={component.data.url || ''}
        moduleType={moduleType}
        provided={provided}
        numberOfComponents={numberOfComponents}
        parentId={parentId}
        autoFocus={component.autoFocus}
      />
    </div>
  );
};

/**
*
*/
const renderImageCarouselComponent = (component, numberOfComponents, moduleType, provided, parentId) => {
  return (
    <div key={component._id}>
      <MultipleImagesComponent
        moduleId={component.moduleId}
        moduleItemId={component._id}
        images={component.data.keys || []}
        moduleType={moduleType}
        provided={provided}
        numberOfComponents={numberOfComponents}
        parentId={parentId}
      />
    </div>
  );
};

/**
*
*/
const renderDisclaimerComponent = (component, numberOfComponents, moduleType, provided, parentId) => {
  return (
    <div key={component._id}>
      <DisclaimerComponent
        moduleId={component.moduleId}
        moduleItemId={component._id}
        text={component.data && component.data.text || ''}
        moduleType={moduleType}
        provided={provided}
        numberOfComponents={numberOfComponents}
        parentId={parentId}
        autoFocus={component.autoFocus}
      />
    </div>
  );
};

/**
*
*/
const renderOneChoiceComponent = (component, numberOfComponents, moduleType, provided, parentId) => {
  return (
    <div key={component._id}>
      <OneChoiceComponent
        moduleId={component.moduleId}
        moduleItemId={component._id}
        moduleType={moduleType}
        provided={provided}
        numberOfComponents={numberOfComponents}
        parentId={parentId}
        autoFocus={component.autoFocus}
        options={component.data && component.data.options || []}
        score={component.score}
      />
    </div>
  );
};

/**
*
*/
const renderMultiChoiceComponent = (component, numberOfComponents, moduleType, provided, parentId) => {
  return (
    <div key={component._id}>
      <MultiChoiceComponent
        moduleId={component.moduleId}
        moduleItemId={component._id}
        moduleType={moduleType}
        provided={provided}
        numberOfComponents={numberOfComponents}
        parentId={parentId}
        autoFocus={component.autoFocus}
        options={component.data && component.data.options || []}
        score={component.score}
      />
    </div>
  );
};

/**
*
*/
const renderScaleComponent = (component, numberOfComponents, moduleType, provided, parentId) => {
  return (
    <div key={component._id}>
      <ScaleComponent
        moduleId={component.moduleId}
        moduleItemId={component._id}
        moduleType={moduleType}
        provided={provided}
        numberOfComponents={numberOfComponents}
        parentId={parentId}
        autoFocus={component.autoFocus}
        minValue={component.data.min}
        correctValue={component.data.numCorrect}
        maxValue={component.data.max}
        stepIncrement={component.data.step}
        score={component.score}
      />
    </div>
  );
};

/**
*
*/
const renderCorrectSpellingComponent = (component, numberOfComponents, moduleType, provided, parentId) => {
  return (
    <div key={component._id}>
      <CorrectSpellingComponent
        moduleId={component.moduleId}
        moduleItemId={component._id}
        moduleType={moduleType}
        provided={provided}
        numberOfComponents={numberOfComponents}
        parentId={parentId}
        autoFocus={component.autoFocus}
        questionText={component.data && component.data.text || ''}
        answerText={component.data && component.data.textCorrect || ''}
        score={component.score}
      />
    </div>
  );
};

/**
*
*/
const renderOpenAnswerComponent = (component, numberOfComponents, moduleType, provided, parentId) => {
  return (
    <div key={component._id}>
      <OpenAnswerComponent
        moduleId={component.moduleId}
        moduleItemId={component._id}
        moduleType={moduleType}
        provided={provided}
        numberOfComponents={numberOfComponents}
        parentId={parentId}
        autoFocus={component.autoFocus}
        questionText={component.data && component.data.text || ''}
      />
    </div>
  );
};

/**
* Function related to Drag and Drop: this will store and set the new order of components
* after they have been dragged and reordered
*/
export const onDragEnd = (result, data, setPlaceholderProps, setData, moduleId, moduleType, dispatch) => {
  const { destination, source, draggableId } = result;
  if (!destination) {
    return;
  }
  if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  ) {
    return;
  }

  const column = data.columns[source.droppableId];
  const newComponentIds = Array.from(column.componentIds);
  newComponentIds.splice(source.index, 1);
  newComponentIds.splice(destination.index, 0, draggableId);

  const newColumn = {
    ...column,
    componentIds: newComponentIds
  };

  const newData = {
    ...data,
    columns: {
      ...data.columns,
      [newColumn.id] : newColumn
    }
  };
  setPlaceholderProps({});
  setData(newData);

  const sortedComponents = newComponentIds.map((id, index) => {
    let component = data.components[id].content;
    component.sortOrder = index;
    return component;
  });
  dispatch(actions.updateComponentOrder.request(moduleId, moduleType, sortedComponents));
};

/**
* Function related to Drag and Drop: this one helps generate the placeholder
* that indicates where the draggable component will be dropped
*/
export const onDragUpdate = (update, setPlaceholderProps) => {
  const queryAttr = "data-rbd-draggable-id";
  if(!update.destination){
    return;
  }
  const draggableId = update.draggableId;
  const destinationIndex = update.destination.index;

  const domQuery = `[${queryAttr}='${draggableId}']`;
  const draggedDOM = document.querySelector(domQuery);

  if (!draggedDOM) {
    return;
  }
  const { clientHeight, clientWidth } = draggedDOM;
  const sourceIndex = update.source.index;

  const childrenArray = [...draggedDOM.parentNode.children];
  const movedItem = childrenArray[sourceIndex];
  childrenArray.splice(sourceIndex, 1);

  const updatedArray = [
    ...childrenArray.slice(0, destinationIndex),
    movedItem,
    ...childrenArray.slice(destinationIndex + 1)
  ];

  const clientY =
    parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
    updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
      const style = curr.currentStyle || window.getComputedStyle(curr);
      const marginBottom = parseFloat(style.marginBottom);
      return total + curr.clientHeight + marginBottom;
    }, 0);

  setPlaceholderProps({
    clientHeight,
    clientWidth,
    clientY,
    clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft)
  });
};

/**
* youtubeUrlFixer() cleans up a youtube url to work in the iframe
*/
export const youtubeUrlFixer = (url) => {
  let appendOptions = "?rel=0&amp;showinfo=1&amp&modestbranding=1";
  url = url.replace("youtu.be", "youtube.com/watch?v=");
  if (url.includes("?time_continue=")) {
    let videoId = url.split("v=")[1];
    let startTime = url.split("time_continue=")[1].split('&')[0];
    appendOptions = appendOptions + "&start=" + startTime;
    return "https://www.youtube.com/embed/" + videoId + appendOptions;
  }
  if (url.includes("youtube.com/embed")) {
    return url + appendOptions;
  }
  let newUrl = url.replace("youtube.com/watch?v=", "youtube.com/embed/");
  let superNewUrl = newUrl.split('&')[0];
  return superNewUrl + appendOptions;
};

/**
* vimeoUrlFixer() cleans up a vimeo url to work in the iframe
*/
export const vimeoUrlFixer = (url) => {
  let appendOptions = "?color=ffffff&badge=0&byline=0&portrait=0&title=0";
  if (!url.includes("player")) {
    url = url.replace("vimeo.com", "player.vimeo.com/video");
  }
  if (!url.includes("?")) {
    url = url.split('?')[0];
  }
  let videoId = url.split('video/')[1].split('/')[0];
  return "https://player.vimeo.com/video/" + videoId + appendOptions;
};

/**
* findEarliestViewDate finds the earliest view date (date when a user first
* saw a lesson) from an array of answers: usually used to show a Start Date
* for a student within a program, when the student first viewed or started
* a program
*/
export const findEarliestViewDate = (answers) => {
  const answersWithViewDate = answers.filter(answer => {
    return answer.viewDate;
  });
  const viewDates = answersWithViewDate.map(answer => {
    return new Date(answer.viewDate);
  });
  const sortedDates = viewDates.sort((a, b) => b - a);
  return sortedDates[sortedDates.length - 1];
};

/**
* findLatestActiveDate finds the latest active date (date when a student last
* viewed or answered an interaction in a lesson) from an array of answers
* This one is used to calculate the Finished Date: moment when a student
* completed a program
*/
export const findLatestActiveDate = (answers) => {
  const answersWithDates = answers.filter(answer => {
    return answer.viewDate || answer.answerDate;
  });
  const answersWithViewDate = answersWithDates.filter(answer => {
    return answer.viewDate;
  });
  const viewDates = answersWithViewDate.map(answer => {
    return new Date(answer.viewDate);
  });
  const answersWithAnswerDate = answersWithDates.filter(answer => {
    return answer.answerDate;
  });
  const answerDates = answersWithAnswerDate.map(answer => {
    return new Date(answer.answerDate);
  });

  const resultDates = [...viewDates, ...answerDates];
  const sortedDates = resultDates.sort((a, b) => b - a);

  return sortedDates[0];
};
