import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Tooltip from '../Tooltip';
import PaginationBar from '../PaginationBar';
import WarningModal from '../WarningModal';
import * as actions from '../../actions';
import { useOutsideClick, changeSortOrder } from '../../utils/componentUtils';
import { getYearMonthDayFromDate } from '../../utils/dateUtils';
import { renderSortIcon } from './commonTableComponents';
import Const from '../../store/Const';
import three_dots from '../../images/svg/three_dots.svg';
import three_dots_black_bg from '../../images/svg/three_dots_black_bg.svg';
import red_x_mark from '../../images/svg/red_x_mark.svg';
import white_x_mark from '../../images/svg/white_x_mark.svg';
import './UserTable.css';

/**
* UserTable is the table showing all users registered or specified
* by search
*/
const UserTable = () => {
  const dispatch = useDispatch();
  const users = useSelector(state => state.users.users);
  const itemsPerPage = useSelector(state => state.componentStates.itemsPerPage);
  const currentPageNumber = useSelector(state => state.componentStates.currentPageNumber);
  const searchString = useSelector(state => state.componentStates.searchValues['userNameOrEmail']) || null;
  const [openDropdownMenuIndex, setOpenDropdownMenuIndex] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [openDropdownCourseIndex, setOpenDropdownCourseIndex] = useState(null);
  const [openDeleteUserModalId, setOpenDeleteUserModalId] = useState(null);
  const [openDeleteSelectedUserModal, setOpenDeleteSelectedUserModal] = useState(false);
  const [sortOrder, setSortOrder] = useState(1);
  const [sortAttribute, setSortAttribute] = useState('username');
  const dropdownRef = useRef();
  const courseDropdownRef = useRef();
  useEffect(() => {
    dispatch(actions.getUsers.request(
      itemsPerPage,
      currentPageNumber,
      searchString,
      sortOrder,
      sortAttribute
    ));
  }, [currentPageNumber, searchString, sortOrder, sortAttribute]);
  useOutsideClick(dropdownRef, () => {
    setTimeout(() => setOpenDropdownMenuIndex(null), 100);
  });
  useOutsideClick(courseDropdownRef, () => {
    setTimeout(() => setOpenDropdownCourseIndex(null), 100);
  });
  const onTypeChange = (item) => {
    selectedItems.includes(item)
      ? setSelectedItems(selectedItems.filter((index) => index !== item))
      : setSelectedItems([...selectedItems, item]);
  };
  return (
    <>
      <table className="userTable">
        <tbody>
          <tr>
            <th>
              <div
                className="userTableNameAndIconContainer"
                onClick={() => changeSortOrder('username', sortAttribute, setSortAttribute, sortOrder, setSortOrder)}
              >
                <div className="userTableTitle">Name</div>
                {renderSortIcon('username', sortAttribute, sortOrder)}
              </div>
            </th>
            <th>
              <div
                className="userTableTitleAndIconContainer"
                onClick={() => changeSortOrder('email', sortAttribute, setSortAttribute, sortOrder, setSortOrder)}
              >
                <div className="userTableTitle">E-mail</div>
                {renderSortIcon('email', sortAttribute, sortOrder)}
              </div>
            </th>
            <th>
              <div className="userTableTitleAndIconContainer">
                <div className="userTableTitle">Company</div>
              </div>
            </th>
            <th>
              <div className="userTableTitleAndIconContainer">
                <div className="userTableTitle">Programs</div>
              </div>
            </th>
            <th>
              <div
                className="userTableTitleAndIconContainer"
                onClick={() => changeSortOrder('lastLogin', sortAttribute, setSortAttribute, sortOrder, setSortOrder)}
              >
                <div className="userTableTitle">Last login to Moblrn</div>
                {renderSortIcon('lastLogin', sortAttribute, sortOrder)}
              </div>
            </th>
            <th>
              <div
                className="userTableTitleAndIconContainer"
                onClick={() => changeSortOrder('devicePlatformType', sortAttribute, setSortAttribute, sortOrder, setSortOrder)}
              >
                <div className="userTableTitle">Last Device</div>
                {renderSortIcon('devicePlatformType', sortAttribute, sortOrder)}
              </div>
            </th>
            <th className="userTableTitle"></th>
          </tr>
          {users.map((user, index) => (
            <tr className="userTableRow" key={index}>
              <td className="userNameValue">
                <label className="userTableText userTableCheckboxContainer">
                  <input
                    type="checkbox"
                    checked={selectedItems.includes(user._id)}
                    onChange={() => onTypeChange(user._id)}
                  />
                    {user.username}
                  <span className="userTableCustomCheckbox"></span>
                </label>
              </td>
              <td className="userTableText userEmailValue">{user.email}</td>
              <td className="userTableText userCompanyValue">{user.company}</td>
              <td className="userTableText userProgramsValue">
                <div>
                  <div
                    className="userTableCoursesNumber"
                    onClick={() => setOpenDropdownCourseIndex(openDropdownCourseIndex === index ? null : index)}
                  >
                    {user.programs.length}
                  </div>
                </div>
                {index === openDropdownCourseIndex && renderCourseDropdownMenu(courseDropdownRef, user.programs, user._id)}
              </td>
              <td className="userTableText userLastLoginValue">{getYearMonthDayFromDate(user.lastLogin)}</td>
              <td className="userTableText userDeviceValue">{Const.deviceType[user.devicePlatformType]}</td>
              <td
                className="userTableMenuCell"
                onClick={() => setOpenDropdownMenuIndex(openDropdownMenuIndex === index ? null : index)}
              >
                <div className="userTableMenuIconsContainer">
                  <img className="userTableMenuIcon userTableMenuIconGrey" src={three_dots} alt="three dots"/>
                  <img className="userTableMenuIconBlackBG" src={three_dots_black_bg} alt="three dots"/>
                  {openDropdownMenuIndex === null && <Tooltip text="User menu" width="65"/>}
                  {index === openDropdownMenuIndex && renderDropdownMenu(dropdownRef, setOpenDeleteUserModalId, user)}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="userTablePaginationAndDeleteButtonsContainer">
        <div className="userTableEmptyComponent"></div>
        <PaginationBar/>
        <div className="userTableDeleteButtonsContainer">
          <button
            className={selectedItems.length === 0 ? "userTableDeleteSelectedButton" : "userTableDeleteSelectedButton userTableDeleteSelectedButtonEnabled"}
            onClick={() => setOpenDeleteSelectedUserModal(true)}
            disabled={selectedItems.length === 0}
          >
            DELETE SELECTED
          </button>
        </div>
      </div>
      <WarningModal
        open={openDeleteUserModalId !== null}
        onClose={() => setOpenDeleteUserModalId(null)}
        title='Delete user'
        text1={`Are you sure you want to delete user "${getUserNameFromId(users, openDeleteUserModalId)}"?`}
        text2={`“${getUserNameFromId(users, openDeleteUserModalId)}” will be deleted and CAN NOT be recovered`}
        leftButtonText='NO, CANCEL'
        rightButtonText='YES, DELETE'
        onLeftButtonClick={() => setOpenDeleteUserModalId(null)}
        onRightButtonClick={() => {
          dispatch(actions.deleteUser.request(openDeleteUserModalId, itemsPerPage, currentPageNumber, searchString, sortOrder, sortAttribute, false));
          setOpenDeleteUserModalId(null);
        }}
      />
      <WarningModal
        open={openDeleteSelectedUserModal}
        onClose={() => setOpenDeleteSelectedUserModal(false)}
        title='Delete selected user'
        text1={`Are you sure you want to delete the ${selectedItems.length} selected users?`}
        text2='The users will be deleted and CAN NOT be recovered'
        leftButtonText='NO, CANCEL'
        rightButtonText='YES, DELETE'
        onLeftButtonClick={() => setOpenDeleteSelectedUserModal(false)}
        onRightButtonClick={() => {
          dispatch(actions.deleteSelectedUsers.request(selectedItems, itemsPerPage, currentPageNumber, searchString, sortOrder, sortAttribute));
          setOpenDeleteSelectedUserModal(false);
          setSelectedItems([]);
        }}
      />
    </>
  );
};


const renderDropdownMenu = (dropdownRef, setOpenDeleteUserModalId, user) => {
  return (
    <div className="userTableDropdownMenu" ref={dropdownRef}>
      <div className="userTableDropdownMenuItem">
        <div className="userDropdownDeleteContainer" onClick={() => setOpenDeleteUserModalId(user._id)}>
          <div className="userTableDropdownMenuText userDropdownDeleteText">Delete user</div>
          <img className="userTableDropdownMenuX" src={red_x_mark} alt="crossmark"/>
          <img className="userTableDropdownMenuX userTableXWhite" src={white_x_mark} alt="crossmark"/>
        </div>
      </div>
    </div>
  );
};

const renderCourseDropdownMenu = (courseDropdownRef, userPrograms, userId) => {
  return (
    <div className="userTableCourseDropdown" ref={courseDropdownRef}>
      {userPrograms.map((program) => (
        <Link className="userTableCourseDropdownItem" to={`/course/${program.programId}/useroverview/${userId}`} key={program.programId}>
          <div className="userCourseDropdownItemContainer">
            <div className="userTableDropdownMenuText userTableCourseDropdownText">{program.program.name}</div>
          </div>
        </Link>
      ))}
    </div>
  );
};

const getUserNameFromId = (users, id) => {
  if (!users || !id) {
    return null;
  }
  const user = users.find(user => { return user._id === id; });
  return user.username;
};

export default UserTable;
