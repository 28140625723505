import React from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '../../actions';
import CourseCoreMoveTooltip from './CourseCoreMoveTooltip';
import CourseCoreDeleteTooltip from './CourseCoreDeleteTooltip';
import ImageCarouselComponent from './ImageCarouselComponent';
import MultipleImagesDropzone from './MultipleImagesDropzone';
import multiple_images_icon from '../../images/svg/multiple_images_icon.svg';
import draggable_handle from '../../images/svg/draggable_handle.svg';
import draggable_handle_onhover from '../../images/svg/draggable_handle_onhover.svg';
import red_x_mark from '../../images/svg/red_x_mark.svg';
import red_x_mark_onhover from '../../images/svg/red_x_mark_onhover.svg';
import './MultipleImagesComponent.css';

/**
* MultipleImagesComponent: this will add an Image Carousel to the Module. This component
* contains the image carousel to display the images and also a dropzone which uploads the
* images which are shown in the carousel
*/

const MultipleImagesComponent = (props) => {
  const dispatch = useDispatch();
  const { moduleId, moduleItemId, images, moduleType, provided, numberOfComponents, parentId } = props;
  return (
    <div className="multipleImagesComponentContainer">
      <img
        className={numberOfComponents === 1 ? "multipleImagesComponentIcon multipleImagesComponentIconAlone" : "multipleImagesComponentIcon"}
        src={multiple_images_icon}
      />
      {numberOfComponents > 1 &&
        <div className="multipleImagesComponentTooltipParent" {...provided.dragHandleProps}>
          <img className="multipleImagesComponentDraggableHandle" src={draggable_handle}/>
          <img className="multipleImagesComponentDraggableHandleOnHover" src={draggable_handle_onhover}/>
          <CourseCoreMoveTooltip x={"-140%"} y={"-195%"}/>
        </div>
      }
      <div>
        {images.length > 0 && <ImageCarouselComponent images={images} moduleItemId={moduleItemId} moduleType={moduleType}/>}
        <MultipleImagesDropzone
          moduleId={moduleId}
          moduleItemId={moduleItemId}
          moduleType={moduleType}
        />
        <div className="multipleImagesComponentSupportText">
          <div>Supported file formats: .jpg, .jpgz, .png, .svg</div>
          <div>Recommended image width 550 px, maximum file size 200kb</div>
        </div>
      </div>
      <div className="multipleImagesComponentXMarkContainer">
        <img className="multipleImagesComponentXMark" src={red_x_mark}/>
        <img
          className="multipleImagesComponentXMarkOnHover"
          src={red_x_mark_onhover}
          onClick={() => dispatch(actions.deleteComponent.request(moduleId, moduleItemId, moduleType, parentId))}
        />
        <CourseCoreDeleteTooltip x={"30%"} y={"-195%"}/>
      </div>
    </div>
  );
};

export default MultipleImagesComponent;
