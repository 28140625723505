import axios from 'axios';
import { Auth, Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { getQueryFromParameters } from '../utils/apiUtils.js';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
//axios.defaults.baseURL = "/api";

export default class API {
  static login(data) {
    return Auth.signIn(data.email, data.password);
  }

  static logout() {
    return Auth.signOut();
  }

  static getCurrenctSession() {
    return Auth.currentSession();
  }

  static getCurrentAuthenticatedUser() {
    return Auth.currentAuthenticatedUser();
  }

  static triggerForgotPassword(username) {
    return Auth.forgotPassword(username);
  }

  static forgotPasswordSubmit(data) {
    return Auth.forgotPasswordSubmit(data.email, data.verificationCode, data.newPassword);
  }

  static completeNewPassword(data) {
    return Auth.completeNewPassword(
      data.cognitoUser,
      data.newPassword,
      data.requiredAttributes
    );
  }

  static uploadFileToS3(file) {
    return Storage.put(uuidv4(), file, { contentType: file.type, acl: 'public-read' });
  }


  static getUsersInCourse(parameters) {
    let query = getQueryFromParameters(parameters);
    return axios.get('/api/programs/' + parameters.id + '/users' + query, { params: { company: parameters.companies, department: parameters.departments, status: parameters.status } });
  }

  static getUsersInCourseCount({ courseId }) {
    return axios.get('/api/programs/' + courseId + '/usersCount');
  }

  static getAllUserAnswersPerProgram(data) {
    return axios.post('/api/programs/' + data.programId + '/answers', {});
  }

  static getUserAnswersPerProgram(data) {
    return axios.get('/api/programs/' + data.programId + '/users/' + data.userId + '/answers', {});
  }

  static getClientSubscriptionData() {
    return axios.get('/api/clientSubscriptionData', {});
  }

  //Works with cognito but should be tested on a real go client
  static getClientStripeSubscriptionData() {
    return axios.get('/api/clientStripeSubscriptionData', {});
  }

  //Works with cognito but should be tested on a real go client
  static toggleCancelSubscription(clientId) {
    return axios.post('/api/stripe/toggleStripeSubscriptionCancelled', {clientId});
  }

  //TODO: Remove after rewriting self-service page
  static getUserRole() {
    return axios.get('/api/role', {});
  }

  //Works with cognito but should be tested on a real basic client
  static createCheckoutSession(clientId) {
    return axios.post('/api/stripe/createCheckoutSession', {clientId});
  }

  //Works with cognito but should be tested on a real go client
  static initiatePaymentMethodChange(clientId) {
    return axios.post('/api/stripe/initiatePaymentMethodChange', {clientId});
  }

  static downloadUserAnswerXls(programId) {
    return axios({
      method: 'get',
      url: '/api/programs/' + programId + '/xls',
      responseType: 'arraybuffer'
    });
  }

  static downloadCourseUserList(id) {
    return axios({
      method: 'get',
      url: '/api/programs/' + id + '/users/xls',
      responseType: 'arraybuffer'
    });
  }

  static downloadBatchUploadCsvExample() {
    return axios.get('/users_sample.csv', {});
  }

  static downloadBatchUploadXlsxExample() {
    return axios({
      method: 'get',
      url: '/xlsx_example.xlsx',
      responseType: 'arraybuffer'
    });
  }

  static getStudentScoreAndCertificate(programIdStudentIds) {
    return axios.post('/api/programs/studentScoreAndCertificate', programIdStudentIds);
  }

  static getCourses(parameters) {
    return axios.post('/api/getcourses', parameters);
  }

  static getClientNames() {
    return axios.get('/api/clientnames', {});
  }

  static setClientLogo(parameters) {
    return axios.post('/api/clients/' + parameters.clientId + '/setLogo', parameters.file);
  }

  static getClientLogo(clientId) {
    return axios.get('/api/clients/' + clientId + '/logo', {});
  }

  static getClients(parameters) {
    let query = getQueryFromParameters(parameters);
    return axios.get('/api/clients' + query, {});
  }

  static getAdmins(parameters) {
    let query = getQueryFromParameters(parameters);
    return axios.get('/api/users' + query, { params: { clientId: parameters.clientId }});
  }

  static getUsers(parameters) {
    let query = getQueryFromParameters(parameters);
    return axios.get('/api/programs/users' + query, {});
  }

  static getAdmin(id) {
    return axios.get('/api/users/' + id, {});
  }

  static deleteClient(clientId) {
    return axios.delete('/api/clients/' + clientId, {});
  }

  static getClient(id) {
    return axios.get('/api/clients/' + id, {});
  }

  static createCourse(parameters) {
    return axios.post('/api/programs', parameters);
  }

  static copyCourse(parameters) {
    return axios.post('/api/programs/' + parameters.courseId, parameters);
  }

  static createClient(parameters) {
    return axios.post('/api/clients/', parameters);
  }

  static createAdmin(parameters) {
    return axios.post('/api/users', parameters);
  }

  static updateClient(client) {
    return axios.put('/api/clients/' + client._id, client);
  }

  static editAdmin(parameters) {
    return axios.put('/api/users', parameters);
  }

  static getCourse(id) {
    return axios.get('/api/programs/' + id , {});
  }

  static getCourseModules(id) {
    return axios.get('/api/programs/' + id + '/modules' , {});
  }

  static getModuleContent(id) {
    return axios.get('/api/modules/' + id + '/items/allItemsParsed', {});
  }

  static deleteCourse(id) {
    return axios.delete('/api/programs/' + id , {});
  }

  static editCourseSettings(parameters) {
    return axios.put('/api/programs/' + parameters.updatedCourse._id, parameters.updatedCourse);
  }

  static publishCourse(parameters) {
    return axios.post('/api/programs/' + parameters.courseId + '/publish', { publish: parameters.publish });
  }

  static deleteUser(adminId) {
    return axios.delete('/api/users/' + adminId, {});
  }

  static addComponent(parameters) {
    return axios.post('/api/modules/' + parameters.moduleId + '/items/', parameters);
  }

  static updateComponent(parameters) {
    return axios.put('/api/modules/' + parameters.moduleId + '/items/' + parameters._id, parameters);
  }

  static deleteComponent(parameters) {
    return axios.delete('/api/modules/' + parameters.moduleId + '/items/' + parameters._id, {});
  }

  static updateImageComponent(parameters) {
    return axios.post('/api/modules/' + parameters.moduleId + '/items/' + parameters._id + '/setImage', { key: parameters.key });
  }

  static addImageCarouselImage(parameters) {
    return axios.post('/api/modules/' + parameters.moduleId + '/items/' + parameters._id + '/addImage', { key: parameters.key });
  }

  static removeImageCarouselImage(parameters) {
    return axios.post('/api/modules/items/deleteImage', parameters);
  }

  static updateModule(parameters) {
    return axios.put('/api/modules/' + parameters.moduleId, parameters);
  }

  static updateComponentOrder(parameters) {
    return axios.put('/api/modules/' + parameters.moduleId + '/updateSort', parameters.items);
  }

  static toggleEnableModule(parameters) {
    return axios.put('/api/modules/' + parameters.moduleId + '/setEnabled', { isEnabled: parameters.isEnabled });
  }

  static addOption(parameters) {
    return axios.post('api/modules/' + parameters.moduleId + '/items/' + parameters.moduleItemId + '/options', parameters);
  }

  static deleteOption(parameters) {
    return axios.delete('api/modules/' + parameters.moduleId + '/items/' + parameters.moduleItemId + '/options/' + parameters.optionId, {});
  }

  static editOption(parameters) {
    return axios.put('api/modules/' + parameters.moduleId + '/items/' + parameters.moduleItemId + '/options/' + parameters.optionId, parameters);
  }

  static addUserToCourse(parameters) {
    return axios.post('api/programs/users', parameters);
  }

  static removeUserFromCourse(parameters) {
    return axios.put(
      'api/programs/' + parameters.courseId + '/users',
      parameters
    );
  }

  static removeAllUsersFromCourse(courseId) {
    return axios.delete('api/programs/' + courseId + '/users/all', {});
  }

  static async batchAddUsersToCourse({ programId, formData }) {
    try {
      const { data } = await axios.post(
        'api/programs/' + programId + '/importusers',
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      return data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { error: 'Please verify your connection and try again' };
    }
  }

  static async getBatchAddStatus(parameters) {
    const { data } = await axios.get(
      'api/programs/' + parameters.courseId + '/importusersstatus'
    );
    return data;
  }

  static getCourseUser(parameters) {
    return axios.get('/api/programs/users/' + parameters.userId, {});
  }

  static editCourseUser(parameters) {
    return axios.put('/api/programs/users/' + parameters.updatedUser._id, parameters.updatedUser);
  }

  static downloadBatchAddReport(batchId) {
    return axios({
      method: 'get',
      url: '/api/skippedusers/' + batchId,
      responseType: 'arraybuffer'
    });
  }

  static resendInvite(parameters) {
    return axios.post(
      '/api/programs/' + parameters.courseId + '/userInvitations',
      parameters
    );
  }

  static resendInviteToAllInactiveUsers(courseId) {
    return axios.get('/api/programs/' + courseId + '/users/resendinvite', {});
  }

  static getUserCourseScores(parameters) {
    return axios.get('/api/programs/' + parameters.courseId + '/users/' + parameters.userId + '/scoreObject', {});
  }

  static getUserCourseAnswers(parameters) {
    return axios.get('/api/programs/' + parameters.courseId + '/users/' + parameters.userId + '/answers', {});
  }

  static loadPushes(parameters) {
    return axios.get('/api/programs/' + parameters.courseId + '/users/' + parameters.userId + '/pushes', {});
  }

  static getAllAPIKeys() {
    return axios.get('/api/publicapi/keys', {});
  }

  static getClientAPIKeys(parameters) {
    return axios.get('/api/publicapi/keys/' + parameters.clientId, {});
  }

  static enableAPIForClient(parameters) {
    return axios.post('/api/publicapi/enable/' + parameters.clientId, {});
  }

  static disableAPIForClient(parameters) {
    return axios.post('/api/publicapi/disable/' + parameters.clientId, {});
  }

  static createAPIKeyForClient(parameters) {
    return axios.post('/api/publicapi/keys/' + parameters.clientId + '/key', {});
  }

  static deleteClientAPIKey(parameters) {
    return axios.delete('/api/publicapi/keys/' + parameters.clientId + '/key/' + parameters.keyId , {});
  }

  static getUsersFiltersList(parameters) {
    return axios.post('/api/programs/' + parameters.programId + '/users/filters' , {});
  }

  static getClientProgressOverview(parameters) {
    let query = getQueryFromParameters(parameters);
    return axios.get(
      "/api/clients/" + parameters.clientId + "/overview" + query,
      {
        params: {
          company: parameters.companies,
          department: parameters.departments,
          status: parameters.statuses,
          programId: parameters.programId
        }
      }
    );
  }

  static getClientProgressGraph(parameters) {
    const instance = axios.create({
      baseURL: `https://${process.env.REACT_APP_CACHE_URL_ID}.execute-api.eu-west-1.amazonaws.com`,
    });
    let query = getQueryFromParameters(parameters);
    return instance.get(
      "/prod/graph" + query,
      {
        params: {
          company: parameters.companies,
          department: parameters.departments,
          status: parameters.statuses,
          programId: parameters.programId
        }
      }
    );
  }

  static async multiaddUsersToPrograms(parameters) {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_NEW_API_URL,
    });
    return instance.post(
      '/.netlify/functions/programs-api/multiProgramEnrollment',
      parameters
    );
  }
}

/**
* Request interceptor that ensures that the IdToken is refreshed if it's expired
*/
axios.interceptors.request.use(function (config) {
  return new Promise(resolve => {
    Auth.currentSession().then((session) => {
      let idTokenExpire = session.getIdToken().getExpiration();
      let refreshToken = session.getRefreshToken();
      let currentTimeSeconds = Math.round(+new Date() / 1000);
      if (idTokenExpire < currentTimeSeconds) {
        Auth.currentAuthenticatedUser().then((res) => {
          res.refreshSession(refreshToken, (err, data) => {
            if (err) {
              Auth.signOut();
            } else {
              axios.defaults.headers.common['Authorization'] = data.getIdToken().getJwtToken();
              resolve(config);
            }
          });
        });
      } else {
        config.headers.Authorization = session.getIdToken().getJwtToken();
        resolve(config);
      }
    }).catch(() => {
      resolve(config);
    });
  });
});
