import React, { useState, useRef } from 'react';
import { useOutsideClick } from '../utils/componentUtils';
import downward_arrow_smooth from '../images/svg/downward_arrow_smooth.svg';
import filter_dropdown_checkmark from '../images/svg/filter_dropdown_checkmark.svg';
import './StudentsProgressTableDropdown.css';

/**
* StudentsProgressTableDropdown
*/
const StudentsProgressTableDropdown = ({ defaultText, optionTexts, selectedClientIndex, setSelectedClientIndex }) => {
  const dropdownRef = useRef();
  const [open, setOpen] = useState(false);

  useOutsideClick(dropdownRef, () => {
    setOpen(false);
  });

  const dropdownMenuClasses = open ? "stProgressDropdownComponentMenu" : "stProgressDropdownComponentMenu stProgressDropdownComponentMenuClosed";

  const dropdownTextClassname = () => {
    if (selectedClientIndex && selectedClientIndex >= 0) {
      return "studentTableSelectDropdownComponent stProgressDropdownComponentTextBold";
    } else {
      return "studentTableSelectDropdownComponent stProgressDropdownComponentTextLight";
    }
  };

  const setSelection = (index) => {
    setSelectedClientIndex(index);
    setOpen(false);
  };

  const clearSelection = () => {
    setSelectedClientIndex(-1);
  };

  return (
    <div ref={dropdownRef} className="studentProgressTableDropdown">
      <div className={dropdownTextClassname()} onClick={() => setOpen(!open)}>
        <div className="studentTableSelectDropdownComponentText">
          {selectedClientIndex > -1 ? optionTexts[selectedClientIndex] : defaultText}
        </div>
        <img className="studentTableSelectDropdownComponentIcon" src={downward_arrow_smooth} alt="downward arror"/>
      </div>
      <div className={dropdownMenuClasses} style={{maxHeight: getDropdownMenuMaxheight(dropdownRef)}}>
        <div
          className={
            selectedClientIndex >= 0 ?
            "studentTableSelectDropdownComponentClearItem"
            :
            "studentTableSelectDropdownComponentClearItem studentTableSelectDropdownComponentClearItemDisabled"
          }
          onClick={() => clearSelection()}
        >
          Clear selection
        </div>
        {optionTexts.map((option, index) => {
          return (
            <div
              className={
                index === selectedClientIndex ?
                "studentTableSelectDropdownComponentMenuItem studentTableSelectDropdownComponentSelectedMenuItem"
                :
                "studentTableSelectDropdownComponentMenuItem"
              }
              key={index}
              onClick={index !== selectedClientIndex ? () => setSelection(index) : () => clearSelection()}
            >
              {
                index === selectedClientIndex ?
                <img className="studentTableSelectDropdownItemIcon" src={filter_dropdown_checkmark} alt="checkmark"/>
                :
                <div className="studentTableSelectDropdownItemEmptyIcon"/>
              }
              <div className="stProgressDropdownComponentOptionText">{option}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

/**
* getDropdownMenuMaxheight calculates the maxHeight of the dropdown menu.
* The requirements are: The menu should fill out the whole page except
* the bottom 70px. The menu should also be at least 200px long if the content
* has at least that height, otherwise the menu should have the height of
* it's content.
*/
const getDropdownMenuMaxheight = (ref) => {
  const { top, height } = ref.current ?
    ref.current.getBoundingClientRect()
    : { top: 0, height: 0 };
  const bottomMargin = 70;
  const maxHeight = window.innerHeight - top - height - bottomMargin;
  return maxHeight >= 200 ? maxHeight + 'px' : 'min(200px, min-content)';
};


export default StudentsProgressTableDropdown;
