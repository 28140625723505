import React from 'react';
import spinner_orange from '../images/svg/spinner_orange.svg';
import './LoadingOverlay.css';

/**
* LoadingOverlay
*/
const LoadingOverlay = () => {
  return (
    <div className="fullPageOverlay">
      <img className="fullPageSpinner" src={spinner_orange} alt="spinner"/>
    </div>
  );
};

export default LoadingOverlay;
