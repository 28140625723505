import React from 'react';
import Const from '../../store/Const';
import Modal from '@material-ui/core/Modal';
import './PushesModal.css';

/**
* PushesModal
*/
const PushesModal = (props) => {
  const { open, onClose, pushes } = props;
  const formattedDate = (plannedDate) => {
    if (!plannedDate) {
      return "-";
    }
    const date = new Date(plannedDate);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const fullDate = `${year}-${month < 10 ? `0${month}` : `${month}`}-${day}`;
    return fullDate;
  };

  return (
    <Modal
      id="pushesModal"
      className="pushesModal"
      open={open}
      onClose={onClose}
    >
      <div className="pushesModalBackground">
        <div className="pushesModalTitle">Notification history</div>
        <table className="pushesTable">
          <tbody>
            <tr>
              <th className="pushesTableHeader">Message</th>
              <th className="pushesTableHeader">Planned date</th>
              <th className="pushesTableHeader">Sent date</th>
              <th className="pushesTableHeader">Type</th>
              <th className="pushesTableHeader">Status</th>
            </tr>
            {pushes.length > 0 &&
              <>
                {pushes.map((push, index) => (
                  <tr className="pushesTableRow" key={index}>
                    <td className="pushesTableText pushMessageValue">{push.message}</td>
                    <td className="pushesTableText">{formattedDate(push.toBeSentAt)}</td>
                    <td className="pushesTableText">{formattedDate(push.sendDate)}</td>
                    <td className="pushesTableText">{Const.pushNotificationType[push.type]}</td>
                    <td className="pushesTableText">{Const.pushStatusType[push.status]}</td>
                  </tr>
                ))}
              </>
            }
          </tbody>
        </table>
        {pushes.length === 0 && <div className="pushesModalEmptyText">No push notifications has been sent.</div>}
        <div onClick={onClose} className="pushesModalCloseButton">CLOSE</div>
      </div>
    </Modal>
  );
};

export default PushesModal;
