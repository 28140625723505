import React from 'react';
import SearchComponent from './SearchComponent';
import UserTable from './tables/UserTable';
import './UserList.css';

/**
* UserList is the wrapper for the table with users,
* the search for user component and the pagination
*/
const UserList = () => {
  return (
    <div className="userListContainer">
      <div className="userListTitle">USER LIST</div>
      <div className="userListSearchContainer">
      <SearchComponent
        name='userNameOrEmail'
        defaultText='Search users'
      />
      </div>
      <UserTable/>
    </div>
  );
};

export default UserList;
