import React from 'react';
import Menu from '../Menu.js';
import styles from './MainLayout.module.css';

/**
 * Menu to the left and responsive container to the right
 */
const MainLayout = ({ children }) => {
  return (
    <div className={styles.mainLayout}>
      <div className={styles.menuWrapper}>
        <Menu />
      </div>
      <div className={styles.contentWrapper}>{children}</div>
    </div>
  );
};

export default MainLayout;
