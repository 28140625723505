import React from 'react';
import { Field } from 'react-final-form';
import './FinalFormRadioButton.css';

/**
 * FinalFormRadioButton: reusable radio button built specifically for forms
 * using Final Form in order to be able to use all of the built-in funcitonality
 * of Final Form and its built in component "Field"
 */
const FinalFormRadioButton = ({
  label,
  name,
  value,
  onClick,
  disabled,
  required,
  checked,
}) => {
  return (
    <div
      className={
        !disabled
          ? 'finalFormRadioButtonContainer'
          : 'finalFormRadioButtonContainer finalFormRadioButtonDisabled'
      }
      onClick={onClick}
    >
      <div>
        <Field
          id={value}
          name={name}
          component="input"
          type="radio"
          value={value}
          disabled={disabled}
          required={required}
          checked={checked}
        />
        <span
          className={
            !disabled
              ? 'finalFormCustomRadioButton'
              : 'finalFormCustomRadioButton finalFormCustomRadioButtonDisabledIcon'
          }
        ></span>
      </div>
      <label htmlFor={value}>{label}</label>
    </div>
  );
};

export default FinalFormRadioButton;
