const initialState = {
  admins: [],
  adminToEdit: {}
};

/**
* admins is the reducer responsible for the admin data
*/
const admins = (state = initialState, action) => {
  switch (action.type) {
    case 'GETADMINS_SUCCESS':
      return {
        ...state,
        admins: action.response.admins
      };
    case 'GETADMIN_SUCCESS':
      return {
        ...state,
        adminToEdit: action.response
      };
    case 'LOGOUT_REQUEST':
      return initialState;
    default:
      return state;
  }
};

export default admins;
