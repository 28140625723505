import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '../../actions';
import search_icon from '../../images/styleguideComponentsImages/search_icon.svg';
import './SearchField.css';

/**
 * SearchField: reusable search field component
 */
const SearchField = (props) => {
  const dispatch = useDispatch();
  const { name, onChangeDebounced, defaultText, clientId, disabled, value } =
    props;
  const [text, setText] = useState('');
  useEffect(() => {
    let debouncedSearchFunction = setTimeout(() => {
      dispatch(actions.setSearchValue.request(name, text));
      if (typeof onChangeDebounced === 'function') onChangeDebounced(text);
    }, 500);
    return () => clearTimeout(debouncedSearchFunction);
  }, [text]);
  useEffect(() => {
    setText('');
  }, [clientId]);
  return (
    <div
      className={
        disabled
          ? 'searchFieldContainer searchFieldDisabled'
          : 'searchFieldContainer'
      }
    >
      <img className="searchFieldIcon" src={search_icon} />
      <input
        type="text"
        className="searchFieldInput"
        placeholder={defaultText}
        value={value ?? text}
        onChange={(event) => setText(event.target.value)}
        disabled={disabled}
      />
    </div>
  );
};

export default SearchField;
