import React from 'react';
import { vimeoUrlFixer, youtubeUrlFixer } from '../../utils/componentUtils';
import Slider from "react-slick";
import carousel_next_arrow from '../../images/svg/carousel_next_arrow.svg';
import carousel_prev_arrow from '../../images/svg/carousel_prev_arrow.svg';
import light_green_checkmark from '../../images/svg/light_green_checkmark.svg';
import red_x from '../../images/svg/red_x.svg';
import headline_icon from '../../images/svg/headline_icon.svg';
import one_choice_icon from '../../images/svg/one_choice_icon.svg';
import radio_button from '../../images/svg/radio_button.svg';
import one_choice_icon_right from '../../images/svg/one_choice_radio_button_checked.svg';
import one_choice_icon_wrong from '../../images/svg/one_choice_radio_button_wrong.svg';
import multi_choice_icon from '../../images/svg/multi_choice_icon.svg';
import multi_choice_icon_right from '../../images/svg/multichoice_checked_checkbox.svg';
import multi_choice_icon_wrong from '../../images/svg/multichoice_wrong_checkbox.svg';
import user_unchecked_checkbox from '../../images/svg/user_unchecked_checkbox.svg';
import slider_icon from '../../images/svg/slider_icon.svg';
import './UserOverviewPageContentComponent.css';

/**
* UserOverviewPageContentComponent
*/
const UserOverviewPageContentComponent = ({ pageItem, answers }) => {
  switch (pageItem.type) {
    case 1:
      return renderTitleComponent(pageItem);
    case 2:
      return renderParagraphComponent(pageItem);
    case 3:
      return renderImageComponent(pageItem);
    case 4:
      return renderVideoComponent(pageItem);
    case 5:
      return renderLinkComponent(pageItem);
    case 11:
      return renderCorrectSpellingComponent(pageItem, answers || {});
    case 12:
      return renderOpenAnswerComponent(pageItem, answers || {});
    case 13:
      return renderOneChoiceComponent(pageItem, answers || {});
    case 14:
      return renderMultiChoiceComponent(pageItem, answers || []);
    case 15:
      return renderOneChoiceComponent(pageItem, answers || {});
    case 16:
      return renderSliderComponent(pageItem, answers || {});
    case 20:
      return renderImageSliderComponent(pageItem);
    case 21:
    case 30:
      return <div className="overviewPageContentComponentWrapper"/>;
    default:
      return null;
  }
};

const renderTitleComponent = (pageItem) => {
  return (
    <div key={pageItem._id} className="overviewPageContentComponentWrapper">
      <div className="overviewPageTitleComponentText">{pageItem.data.text}</div>
    </div>
  );
};

const renderParagraphComponent = (pageItem) => {
  return (
    <div key={pageItem._id} className="overviewPageContentComponentWrapper">
      <div className="overviewPageParagraphComponentText">{pageItem.data.text}</div>
    </div>
  );
};

const renderImageComponent = (pageItem) => {
  const imageKey = pageItem.data.key;
  const imageSource = process.env.REACT_APP_MEDIA_URL + imageKey;
  return (
    <div key={pageItem._id} className="overviewPageContentComponentWrapper">
      <img
        className="overviewPageImageComponent"
        alt="Image not available"
        src={imageSource}
      />
    </div>
  );
};

const renderVideoComponent = (pageItem) => {
  if (pageItem.data.url) {
    const videoUrl = pageItem.data.url.includes("vimeo") ? vimeoUrlFixer(pageItem.data.url) : youtubeUrlFixer(pageItem.data.url);
    return (
      <div className="overviewPageContentComponentWrapper" key={pageItem._id}>
        <iframe
          className="overviewPageVideoComponent"
          src={videoUrl}
          allowFullScreen="allowFullScreen"
          mozallowfullscreen="mozallowfullscreen"
          msallowfullscreen="msallowfullscreen"
          oallowfullscreen="oallowfullscreen"
          webkitallowfullscreen="webkitallowfullscreen"
          title="title">
        </iframe>
      </div>
    );
  } else {
    return (
      <div className="overviewPageContentComponentWrapper"/>
    );
  }
};

const renderLinkComponent = (pageItem) => {
  return (
    <div className="overviewPageContentComponentWrapper" key={pageItem._id}>
      <a className="overviewPageLinkComponent" target="_blank" rel="noopener noreferrer" href={pageItem.data.url}>
        {pageItem.data.text}
      </a>
    </div>
  );
};

const renderImageSliderComponent = (pageItem) => {
  let imageList = pageItem.data.keys.map((image) => {
    return {
      imageUrl: process.env.REACT_APP_MEDIA_URL + image,
    };
  });
  const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <img className={className} onClick={onClick} src={carousel_next_arrow}/>
    );
  };
  const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <img className={className} onClick={onClick} src={carousel_prev_arrow}/>
    );
  };
  const settings = {
    nextArrow: <NextArrow/>,
    prevArrow: <PrevArrow/>
  };
  return (
    <div className="overviewPageContentComponentWrapper" key={pageItem._id}>
      <Slider {...settings}>
        {imageList.map((image, index) => {
          return (
            <div key={index}>
              <img className="overviewPageImageSliderComponent" src={image.imageUrl}/>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

const renderCorrectSpellingComponent = (pageItem, answer) => {
  const optionBorderColor = () => {
    if (answer.value === pageItem.data.textCorrect) {
      return "overviewCorrectSpellingValues overviewCorrectSpellingCorrectAnswer";
    } else if (answer.value && answer.value !== pageItem.data.textCorrect) {
      return "overviewCorrectSpellingValues overviewCorrectSpellingWrongAnswer";
    } else {
      return "overviewCorrectSpellingValues";
    }
  };
  const renderResponseIcon = () => {
    if (pageItem.data.textCorrect && answer.value === pageItem.data.textCorrect) {
      return <img className="overviewCorrectSpellingResponseIcon" src={light_green_checkmark}/>;
    } else if (pageItem.data.textCorrect && answer.value && answer.value !== pageItem.data.textCorrect) {
      return <img className="overviewCorrectSpellingResponseIcon" src={red_x}/>;
    } else if (!answer.value) {
      return <div className="overviewNoResponseIcon"/>;
    }
  };
  const isAnswerCorrect = pageItem.data.textCorrect && answer.value === pageItem.data.textCorrect;

  return (
    <div className="overviewPageContentComponentWrapper" key={pageItem._id}>
      <div className="overviewCorrectSpellingComponentHeader">
        <div className="overviewCorrectSpellingIconAndTitle">
          <img className="overviewCorrectSpellingIcon" src={headline_icon}/>
          <div className="overviewCorrectSpellingTitle">Correct spelling</div>
        </div>
        <div className="overviewCorrectSpellingPoints">
          {`${isAnswerCorrect ? pageItem.score && pageItem.score > 0 ? pageItem.score : '0' : '0'}/${pageItem.score || '0'} ${pageItem.score === 1 ? "point" : "points"}`}
        </div>
      </div>
      <div className="overviewCorrectSpellingSubTitle">QUESTION</div>
      <div className="overviewCorrectSpellingValues">{pageItem.data.text}</div>
      <div className="overviewCorrectSpellingSubTitle">USER ANSWER</div>
      <div className={optionBorderColor()}>{answer.value}</div>
      <div className="overviewCorrectSpellingSubTitle">CORRECT ANSWER</div>
      <div
        className="overviewCorrectSpellingValues overviewCorrectSpellingCorrectAnswer overviewCorrectSpellingLastValue"
      >
        {pageItem.data.textCorrect}
      </div>
      <div className="overviewCorrectSpellingResponseIconWrapper">
        {renderResponseIcon()}
      </div>
    </div>
  );
};

const renderOpenAnswerComponent = (pageItem, answer) => {
  return (
    <div className="overviewPageContentComponentWrapper" key={pageItem._id}>
      <div className="overviewOpenAnswerHeader">
        <img className="overviewCorrectSpellingIcon" src={headline_icon}/>
        <div className="overviewCorrectSpellingTitle">Open answer</div>
      </div>
      <div className="overviewCorrectSpellingSubTitle">QUESTION</div>
      <div className="overviewCorrectSpellingValues">{pageItem.data.text}</div>
      <div className="overviewCorrectSpellingSubTitle">USER ANSWER</div>
      <div className="overviewCorrectSpellingValues overviewOpenAnswerText">{answer.value}</div>
    </div>
  );
};

const renderOneChoiceComponent = (pageItem, answer) => {
  const correctOption = pageItem.data.options.filter(option => {
    return option.isCorrect;
  });
  const correctOptionId = correctOption.length > 0 ? correctOption[0]._id : "";
  const userIsCorrect = correctOptionId === answer.moduleItemOptionId;

  const selectOptionIcon = (option, correctOptionId, answer) => {
    if ((correctOption.length === 0 && option._id === answer.moduleItemOptionId) || (option._id === correctOptionId && option._id === answer.moduleItemOptionId)) {
      return one_choice_icon_right;
    } else if (option._id !== correctOptionId && option._id === answer.moduleItemOptionId) {
      return one_choice_icon_wrong;
    } else {
      return radio_button;
    }
  };

  const optionBorderColor = (option) => {
    if (option.isCorrect || (correctOption.length === 0 && option._id === answer.moduleItemOptionId)) {
      return "overviewOneChoiceValues overviewCorrectSpellingCorrectAnswer";
    } else if (!option.isCorrect && option._id === answer.moduleItemOptionId) {
      return "overviewOneChoiceValues overviewCorrectSpellingWrongAnswer";
    } else {
      return "overviewOneChoiceValues";
    }
  };

  const renderResponseIcon = () => {
    if ((correctOption.length === 0 && answer.moduleItemOptionId) || (correctOption.length > 0 && userIsCorrect)) {
      return <img className="overviewCorrectSpellingResponseIcon" src={light_green_checkmark}/>;
    } else if (correctOption.length > 0 && answer.moduleItemOptionId && !userIsCorrect) {
      return <img className="overviewCorrectSpellingResponseIcon" src={red_x}/>;
    } else if (!answer.moduleItemOptionId) {
      return <div className="overviewNoResponseIcon"/>;
    }
  };

  return (
    <div className="overviewPageContentComponentWrapper" key={pageItem._id}>
      <div className="overviewCorrectSpellingComponentHeader">
        <div className="overviewCorrectSpellingIconAndTitle">
          <img className="overviewCorrectSpellingIcon" src={one_choice_icon}/>
          <div className="overviewCorrectSpellingTitle">One choice</div>
        </div>
        <div className="overviewCorrectSpellingPoints">
          {
            `${(answer.moduleItemOptionId && correctOption.length === 0) || (correctOption.length > 0 && userIsCorrect) ?
              pageItem.score && pageItem.score > 0 ? pageItem.score : '0' : '0'}/${pageItem.score || '0'} ${pageItem.score === 1 ? "point" : "points"}`
          }
        </div>
      </div>
      {pageItem.data.options.map(option => {
        return (
          <div key={option._id} className="overviewOneChoiceIconAndText">
            <img className="overviewOneChoiceIcon" src={selectOptionIcon(option, correctOptionId, answer)}/>
            <div className={optionBorderColor(option)}>
              {option.name}
            </div>
          </div>
        );
      })}
      <div className="overviewCorrectSpellingResponseIconWrapper">
        {renderResponseIcon()}
      </div>
    </div>
  );
};

const renderMultiChoiceComponent = (pageItem, answers) => {
  const correctOptions = pageItem.data.options.filter(option => {
    return option.isCorrect;
  });
  const correctOptionIds = correctOptions.map(option => {
    return option._id;
  });
  const answerOptionIds = answers.map(answer => {
    return answer.moduleItemOptionId;
  });
  // allAnswersCorrect checks if the user picked all the correct answers
  let allAnswersCorrect = (userAnswers, correctAnswers) => correctAnswers.every(item => userAnswers.includes(item));

  const selectOptionIcon = (option, correctOptionIds, answerOptionIds) => {
    if ((correctOptionIds.includes(option._id) && answerOptionIds.includes(option._id)) || (answerOptionIds.includes(option._id) && correctOptionIds.length === 0)) {
      return multi_choice_icon_right;
    } else if (!correctOptionIds.includes(option._id) && answerOptionIds.includes(option._id)) {
      return multi_choice_icon_wrong;
    } else {
      return user_unchecked_checkbox;
    }
  };

  const optionBorderColor = (option) => {
    if (correctOptionIds.includes(option._id) || (answerOptionIds.includes(option._id) && correctOptionIds.length === 0)) {
      return "overviewOneChoiceValues overviewCorrectSpellingCorrectAnswer";
    } else if (!correctOptionIds.includes(option._id) && answerOptionIds.includes(option._id)) {
      return "overviewOneChoiceValues overviewCorrectSpellingWrongAnswer";
    } else {
      return "overviewOneChoiceValues";
    }
  };

  const renderResponseIcon = () => {
    if ((correctOptionIds.length === 0 && answerOptionIds.length > 0) || (correctOptionIds.length > 0 && allAnswersCorrect(answerOptionIds, correctOptionIds))) {
      return <img className="overviewCorrectSpellingResponseIcon" src={light_green_checkmark}/>;
    } else if (correctOptionIds.length > 0 && answerOptionIds.length > 0 && !allAnswersCorrect(answerOptionIds, correctOptionIds)) {
      return <img className="overviewCorrectSpellingResponseIcon" src={red_x}/>;
    } else if (answerOptionIds.length <= 0) {
      return <div className="overviewNoResponseIcon"/>;
    } else {
      return <div className="overviewNoResponseIcon"/>;
    }
  };

  return (
    <div className="overviewPageContentComponentWrapper" key={pageItem._id}>
      <div className="overviewCorrectSpellingComponentHeader">
        <div className="overviewCorrectSpellingIconAndTitle">
          <img className="overviewCorrectSpellingIcon" src={multi_choice_icon}/>
          <div className="overviewCorrectSpellingTitle">Multiple choices</div>
        </div>
        <div className="overviewCorrectSpellingPoints">
          {
            `${(answerOptionIds.length > 0 && correctOptionIds.length === 0) || (correctOptionIds.length > 0 && allAnswersCorrect(answerOptionIds, correctOptionIds)) ?
              pageItem.score && pageItem.score > 0 ? pageItem.score : '0' : '0'}/${pageItem.score || '0'} ${pageItem.score === 1 ? "point" : "points"}`
          }
        </div>
      </div>
      {pageItem.data.options.map(option => {
        return (
          <div key={option._id} className="overviewOneChoiceIconAndText">
            <img className="overviewOneChoiceIcon" src={selectOptionIcon(option, correctOptionIds, answerOptionIds)}/>
            <div className={optionBorderColor(option)}>
              {option.name}
            </div>
          </div>
        );
      })}
      <div className="overviewCorrectSpellingResponseIconWrapper">
        {renderResponseIcon()}
      </div>
    </div>
  );
};

const renderSliderComponent = (pageItem, answer) => {
  const answerBorderColor = () => {
    if ((!pageItem.data.numCorrect && answer.value) || parseInt(answer.value) === parseInt(pageItem.data.numCorrect)) {
      return "overviewSliderValue overviewCorrectSpellingCorrectAnswer";
    } else if (parseInt(answer.value) && parseInt(answer.value) !== parseInt(pageItem.data.numCorrect)) {
      return "overviewSliderValue overviewCorrectSpellingWrongAnswer";
    } else {
      return "overviewSliderValue overviewSliderValueNoAnswer";
    }
  };

  const renderResponseIcon = () => {
    if ((!pageItem.data.numCorrect && answer.value) || (parseInt(answer.value) === parseInt(pageItem.data.numCorrect))) {
      return <img className="overviewCorrectSpellingResponseIcon" src={light_green_checkmark}/>;
    } else if (parseInt(answer.value) && parseInt(answer.value) !== parseInt(pageItem.data.numCorrect)) {
      return <img className="overviewCorrectSpellingResponseIcon" src={red_x}/>;
    } else if (!answer.value) {
      return <div className="overviewNoResponseIcon"/>;
    }
  };

  return (
    <div className="overviewPageContentComponentWrapper" key={pageItem._id}>
      <div className="overviewCorrectSpellingComponentHeader">
        <div className="overviewCorrectSpellingIconAndTitle">
          <img className="overviewCorrectSpellingIcon" src={slider_icon}/>
          <div className="overviewCorrectSpellingTitle">Slider</div>
        </div>
        <div className="overviewCorrectSpellingPoints">
          {
            `${(parseInt(answer.value) && !parseInt(pageItem.data.numCorrect)) || (parseInt(answer.value) === parseInt(pageItem.data.numCorrect)) ?
              pageItem.score && pageItem.score > 0 ? pageItem.score : '0' : '0'}/${pageItem.score || '0'} ${pageItem.score === 1 ? "point" : "points"}`
          }
        </div>
      </div>
      <div>
        <div className="overviewSliderValuesWrapper">
          <div className="overviewSliderValueWrapper">
            <div className="overviewSliderSubTitle">USER ANSWER</div>
            <div className={answerBorderColor()}>{answer.value}</div>
          </div>
          <div className="overviewSliderValueWrapper">
            <div className="overviewSliderSubTitle">CORRECT ANSWER</div>
            <div className={"overviewSliderValue overviewCorrectSpellingCorrectAnswer"}>
              {pageItem.data.numCorrect}
            </div>
          </div>
        </div>
      </div>
      <div className="overviewCorrectSpellingResponseIconWrapper">
        {renderResponseIcon()}
      </div>
    </div>
  );
};

export default UserOverviewPageContentComponent;
