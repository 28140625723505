import React from 'react';
import styles from './LoadingText.module.css';

const LoadingText = ({ label }) => {
  return (
    <div className={styles.loadingText}>
      {label}
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </div>
  );
};

export default LoadingText;
