import React from 'react';
import './Tooltip.css';


/**
* Tooltip is a standard tooltip component that takes text, width and height
* and creates a tooltip centered above the parent element
*/
const Tooltip = (props) => {
  const { text, width, height } = props;
  return (
    <div
      className="tooltip"
      style={{'width': width + 'px', 'height': height + 'px'}}
    >
      {text}
    </div>
  );
};

export default Tooltip;
