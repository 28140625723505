import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../actions';
import toast_error_icon from '../images/svg/toast_error_icon.svg';
import './ErrorToast.css';


/**
* ErrorToast is a standard toast component to show something has gone wrong
* It will come from the top of the screen and close down automatically after
* a short time or when clicking on the x icon
*/
const ErrorToast = (props) => {
  const dispatch = useDispatch();
  const { text, timeToClose } = props;
  const [errorToastClassname, setErrorToastClassname] = useState("errorToast errorToastHidden");
  const [componentMounted, setComponentMounted] = useState(true);
  const toastOrigin = useSelector(state => state.componentStates.toastOrigin);
  const currentPath = window.location.pathname;

  useEffect(() => {
    setErrorToastClassname("errorToast errorToastVisible");
    setTimeout(() => {
      if (componentMounted) {
        setErrorToastClassname("errorToast errorToastHidden");
        setTimeout(
          () => dispatch(actions.closeErrorToast.request()),
          500
        );
      }
    },
    timeToClose);
    return (() => {
      setComponentMounted(false);
    });
  }, []);

  if (toastOrigin === currentPath) {
    return (
      <div className={errorToastClassname}>
        <div className="errorToastContent" onClick={() => dispatch(actions.closeErrorToast.request())}>
          <img className="errorToastIcon" src={toast_error_icon} alt="warning icon"/>
          <div>{text}</div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default ErrorToast;
