export const initialFeedbackCorrectData = {
  components: {},
  columns: {
    "feedback-correct-column": {
      id: "feedback-correct-column",
      componentIds: []
    }
  },
  columnOrder: ["feedback-correct-column"]
};

export const initialFeedbackWrongData = {
  components: {},
  columns: {
    "feedback-wrong-column": {
      id: "feedback-wrong-column",
      componentIds: []
    }
  },
  columnOrder: ["feedback-wrong-column"]
};

export const structureFeedbackCorrectData = (feedbackCorrectComponents, setFeedbackCorrectObject) => {
  const itemIds = feedbackCorrectComponents.map(item => item._id);
    let feedbackCorrectContentObject = {};
    feedbackCorrectComponents.forEach(item => {
      const component = {
        id: item._id,
        content: item
      };
      feedbackCorrectContentObject[item._id] = component;
    });

    const itemData = {
      components: feedbackCorrectContentObject,
      columns: {
        "feedback-correct-column": {
          id: "feedback-correct-column",
          componentIds: itemIds
        }
      },
      columnOrder: ["feedback-correct-column"]
    };
    setFeedbackCorrectObject(itemData);
};

export const structureFeedbackWrongData = (feedbackWrongComponents, setFeedbackWrongObject) => {
  const itemIds = feedbackWrongComponents.map(item => item._id);
    let feedbackWrongContentObject = {};
    feedbackWrongComponents.forEach(item => {
      const component = {
        id: item._id,
        content: item
      };
      feedbackWrongContentObject[item._id] = component;
    });

    const itemData = {
      components: feedbackWrongContentObject,
      columns: {
        "feedback-wrong-column": {
          id: "feedback-wrong-column",
          componentIds: itemIds
        }
      },
      columnOrder: ["feedback-wrong-column"]
    };
    setFeedbackWrongObject(itemData);
};
