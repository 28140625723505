const initialState = {
  isLoggedIn: undefined,
  interactionIndex: -1,
  formSubmitted: false,
  showSubmitNewPasswordErrorMessage: false,
  wrongPasswordOrUsername: false,
  currentPageNumber: 1,
  numberOfPages: 1,
  itemsPerPage: 19,
  selectedDropdownValues: {},
  searchValues: {},
  showSettingsDropdown: false,
  toastOrigin: '',
  toastActive: false,
  errorToastActive: false,
  loadingToastActive: false,
  toastMessage: '',
  reRenderClientTable: false,
  submitButtonDisabled: false,
  imageUploadToastOrigin: '',
  imageUploadToastActive: false,
  imageUploadErrorToastActive: false,
  imageUploadLoadingToastActive: false,
  imageLoadingAnimationOrigin: '',
  savingStatus: 'default',
  offlineStatusActive: false,
  connectionStatus: '',
  pageTitleEditedInMenu: false,
  openChaptersInLessonsMenu: {},
  newlyCreatedCourse: false,
  disableCreateAPIKey: false,
  loadingAPITableOverlayActive: false,
  loadingUserlistFilterOptions: false,
  moduleContentSuccess: false,
  loadingResetUserProgress: false,
  multiaddToProgramsLoading: false
};



const componentStates = (state = initialState, action) => {
  switch (action.type) {
    case 'SETSHOWNINTERACTION_REQUEST':
      return {
        ...state,
        interactionIndex: action.interactionIndex,
      };
    case 'FORGOTPASSWORDSUBMIT_REQUEST':
      return {
        ...state,
        formSubmitted: true
      };
    case 'FORGOTPASSWORDSUBMIT_SUCCESS':
      return {
        ...state,
        formSubmitted: false
      };
    case 'LOGINFORMVALUECHANGED_REQUEST':
      return {
        ...state,
        formSubmitted: false,
        wrongPasswordOrUsername: false
      };
    case 'FORGOTPASSWORDSUBMIT_FAILURE':
      return {
        ...state,
        showSubmitNewPasswordErrorMessage: true,
        formSubmitted: false
      };
    case 'CLOSESUBMITNEWPASSWORDERRORMODAL_REQUEST':
      return {
        ...state,
        showSubmitNewPasswordErrorMessage: false
      };
    case 'LOGIN_REQUEST':
      return {
        ...state,
        formSubmitted: true
      };
    case 'LOGIN_SUCCESS':
      if (action.response.cognitoUserObject) {
        return {
          ...state,
          isLoggedIn: true,
          formSubmitted: false
        };
      }
      return {
        ...state,
        isLoggedIn: true,
        formSubmitted: false
      };
    case 'LOGIN_FAILURE':
      return {
        ...state,
        isLoggedIn: false,
        formSubmitted: false,
        wrongPasswordOrUsername: action.error.code === 'UserNotFoundException' || action.error.code === 'NotAuthorizedException'
      };
    case 'LOGOUT_REQUEST':
      return initialState;
    case 'GETCOURSES_SUCCESS':
      return {
        ...state,
        numberOfPages: action.response.numberOfPages
      };
    case 'CHANGEPAGENUMBER_REQUEST':
      return {
        ...state,
        currentPageNumber: action.number
      };
    case 'SETSELECTEDDROPDOWNVALUE_REQUEST': {
      let newSelectedDropdownValues = {
        ...state.selectedDropdownValues
      };
      newSelectedDropdownValues[action.name] = action.value;
      return {
        ...state,
        selectedDropdownValues: newSelectedDropdownValues,
        currentPageNumber: 1
      };
    }
    case 'SETSEARCHVALUE_REQUEST': {
      let newSearchValues = {
        ...state.searchValues
      };
      newSearchValues[action.name] = action.value;
      return {
        ...state,
        searchValues: newSearchValues,
        currentPageNumber: 1
      };
    }
    case 'SHOWSETTINGSDROPDOWN_REQUEST':
      return {
        ...state,
        showSettingsDropdown: action.value
      };
    case 'GETUSERS_SUCCESS':
    case 'GETADMINS_SUCCESS':
    case 'GETCLIENTS_SUCCESS':
    case 'GETUSERSINCOURSE_SUCCESS':
      return {
        ...state,
        numberOfPages: action.response.numberOfPages
      };
    case 'CREATEADMIN_SUCCESS':
    case 'EDITCOURSESETTINGS_SUCCESS':
      return {
        ...state,
        errorToastActive: false,
        loadingToastActive: false,
        toastActive: true,
        toastMessage: '',
        submitButtonDisabled: false
      };
    case 'CREATECOURSE_SUCCESS':
      return {
        ...state,
        errorToastActive: false,
        loadingToastActive: false,
        newlyCreatedCourse: true
      };
    case 'REMOVEALLUSERSFROMCOURSE_SUCCESS':
      return {
        ...state,
        errorToastActive: false,
        loadingToastActive: false,
        toastActive: true,
        toastMessage: 'Users removed from program',
        currentPageNumber: 1,
        numberOfPages: 1,
        searchValues: {}
      };
    case 'CREATECLIENT_SUCCESS':
    case 'ADDUSERTOCOURSE_SUCCESS':
      return {
        ...state,
        errorToastActive: false,
        loadingToastActive: false,
        toastActive: true,
        submitButtonDisabled: false
      };
    case 'CLOSETOAST_REQUEST':
      return {
        ...state,
        toastActive: false,
        toastMessage: '',
        toastOrigin: ''
      };
    case 'DELETECLIENT_FAILURE':
    case 'CREATECLIENT_FAILURE':
    case 'UPDATECLIENT_FAILURE':
    case 'EDITADMIN_FAILURE':
    case 'CREATECOURSE_FAILURE':
    case 'EDITCOURSEUSER_FAILURE':
      return {
        ...state,
        errorToastActive: true,
        loadingToastActive: false,
        submitButtonDisabled: false
      };
    case 'ADDUSERTOCOURSE_FAILURE':
      return {
        ...state,
        errorToastActive: true,
        loadingToastActive: false,
        submitButtonDisabled: false,
        toastMessage: `Could not add user - ${action.error}`
      };
    case 'CREATEADMIN_FAILURE':
      return {
        ...state,
        errorToastActive: true,
        loadingToastActive: false,
        submitButtonDisabled: false,
        toastMessage: `Could not add admin - ${action.error}`
      };
    case 'DELETECOURSE_FAILURE':
      return {
        ...state,
        errorToastActive: true,
        loadingToastActive: false,
        toastMessage: 'Could not delete program, please try again'
      };
    case 'COPYCOURSE_FAILURE':
      return {
        ...state,
        errorToastActive: true,
        loadingToastActive: false,
        toastMessage: 'Could not copy program, please try again'
      };
    case 'REMOVEALLUSERSFROMCOURSE_FAILURE':
      return {
        ...state,
        errorToastActive: true,
        loadingToastActive: false,
        toastMessage: 'Could not remove users, please try again'
      };
    case 'EDITCOURSESETTINGS_FAILURE':
      return {
        ...state,
        errorToastActive: true,
        loadingToastActive: false,
        toastMessage: 'Could not save settings, please try again'
      };
    case 'RESENDINVITETOALLINACTIVEUSERS_FAILURE':
    case 'RESENDINVITESELECTEDUSERS_FAILURE':
      return {
        ...state,
        errorToastActive: true,
        loadingToastActive: false,
        toastMessage: 'Could not send invites, please try again'
      };
    case 'RESENDINVITE_FAILURE':
      return {
        ...state,
        errorToastActive: true,
        loadingToastActive: false,
        toastMessage: 'Could not send invite, please try again'
      };
    case 'CLOSEERRORTOAST_REQUEST':
      return {
        ...state,
        errorToastActive: false,
        toastMessage: '',
        toastOrigin: ''
      };
    case 'DELETECLIENT_REQUEST':
      return {
        ...state,
        loadingToastActive: true,
        reRenderClientTable: true
      };
    case 'RESENDINVITETOALLINACTIVEUSERS_REQUEST':
    case 'RESENDINVITESELECTEDUSERS_REQUEST':
      return {
        ...state,
        loadingToastActive: true,
        toastMessage: 'Sending invites'
      };
    case 'RESENDINVITE_REQUEST':
      return {
        ...state,
        loadingToastActive: true,
        toastMessage: 'Sending invite'
      };
    case 'DELETECLIENT_SUCCESS':
      return {
        ...state,
        errorToastActive: false,
        loadingToastActive: false,
        toastActive: true,
        reRenderClientTable: false
      };
    case 'CREATECLIENT_REQUEST':
    case 'CREATEADMIN_REQUEST':
    case 'CREATECOURSE_REQUEST':
    case 'ADDUSERTOCOURSE_REQUEST':
      return {
        ...state,
        loadingToastActive: true,
        submitButtonDisabled: true
      };
    case 'UPDATECLIENT_REQUEST':
    case 'EDITADMIN_REQUEST':
    case 'EDITCOURSEUSER_REQUEST':
      return {
        ...state,
        loadingToastActive: true,
        submitButtonDisabled: true
      };
    case 'REMOVEALLUSERSFROMCOURSE_REQUEST':
      return {
        ...state,
        loadingToastActive: true,
        toastMessage: 'Removing users'
      };
    case 'ENABLESUBMITBUTTON_REQUEST':
      return {
        ...state,
        submitButtonDisabled: false
      };
    case 'UPDATECLIENT_SUCCESS':
    case 'EDITADMIN_SUCCESS':
    case 'EDITCOURSEUSER_SUCCESS':
      return {
        ...state,
        errorToastActive: false,
        loadingToastActive: false,
        toastActive: true,
        submitButtonDisabled: false,
        toastMessage: ''
      };
    case 'COPYCOURSE_SUCCESS':
      return {
        ...state,
        errorToastActive: false,
        loadingToastActive: false,
        toastActive: true,
        toastMessage: 'Program duplicated'
      };
    case 'DELETECOURSE_SUCCESS':
      return {
        ...state,
        errorToastActive: false,
        loadingToastActive: false,
        toastActive: true,
        submitButtonDisabled: false,
        toastMessage: 'Program deleted'
      };
    case 'DELETECOURSE_REQUEST':
      return {
        ...state,
        loadingToastActive: true,
        submitButtonDisabled: true,
        toastMessage: 'Deleting program'
      };
    case 'COPYCOURSE_REQUEST':
      return {
        ...state,
        loadingToastActive: true,
        toastMessage: 'Duplicating program, this may take several minutes'
      };
    case 'EDITCOURSESETTINGS_REQUEST':
      return {
        ...state,
        loadingToastActive: true,
        submitButtonDisabled: true,
        toastMessage: 'Saving settings'
      };
    case 'TOGGLECANCELSUBSCRIPTION_REQUEST':
    case 'DOWNLOADUSERANSWERXLS_REQUEST':
      return {
        ...state,
        loadingToastActive: true
      };
    case 'DELETEUSER_REQUEST':
      return {
        ...state,
        loadingToastActive: true,
        toastMessage: 'Deleting admin'
      };
    case 'TOGGLECANCELSUBSCRIPTION_SUCCESS':
    case 'DOWNLOADUSERANSWERXLS_SUCCESS':
      return {
        ...state,
        errorToastActive: false,
        loadingToastActive: false,
        toastActive: true
      };
    case 'DELETEUSER_SUCCESS':
      return {
        ...state,
        errorToastActive: false,
        loadingToastActive: false,
        toastActive: true,
        toastMessage: 'Admin deleted'
      };
    case 'RESENDINVITETOALLINACTIVEUSERS_SUCCESS':
    case 'RESENDINVITESELECTEDUSERS_SUCCESS':
      return {
        ...state,
        errorToastActive: false,
        loadingToastActive: false,
        toastActive: true,
        toastMessage: 'Invites sent'
      };
    case 'RESENDINVITE_SUCCESS':
      return {
        ...state,
        errorToastActive: false,
        loadingToastActive: false,
        toastActive: true,
        toastMessage: 'Invite sent'
      };
    case 'TOGGLECANCELSUBSCRIPTION_FAILURE':
    case 'DOWNLOADUSERANSWERXLS_FAILURE':
      return {
        ...state,
        loadingToastActive: false,
        errorToastActive: true
      };
    case 'DELETEUSER_FAILURE':
      return {
        ...state,
        loadingToastActive: false,
        errorToastActive: true,
        toastMessage: "Couldn't delete admin, please try again"
      };
    case 'SETTOASTORIGIN_REQUEST':
      return {
        ...state,
        toastOrigin: action.payload
      };
    case 'UPDATEIMAGECOMPONENT_REQUEST':
    case 'ADDIMAGECAROUSELIMAGE_REQUEST':
      return {
        ...state,
        imageUploadLoadingToastActive: true,
        savingStatus: 'saving'
      };
    case 'UPDATEIMAGECOMPONENT_SUCCESS':
    case 'ADDIMAGECAROUSELIMAGE_SUCCESS':
      return {
        ...state,
        imageUploadErrorToastActive: false,
        imageUploadLoadingToastActive: false,
        imageUploadToastActive: true,
        imageLoadingAnimationOrigin: '',
        savingStatus: 'saved'
      };
    case 'UPDATEIMAGECOMPONENT_FAILURE':
    case 'ADDIMAGECAROUSELIMAGE_FAILURE':
      return {
        ...state,
        imageUploadLoadingToastActive: false,
        imageUploadErrorToastActive: true,
        imageLoadingAnimationOrigin: ''
      };
    case 'CLOSEIMAGEUPLOADTOAST_REQUEST':
      return {
        ...state,
        imageUploadToastActive: false,
        imageUploadToastOrigin: ''
      };
    case 'CLOSEIMAGEUPLOADERRORTOAST_REQUEST':
      return {
        ...state,
        imageUploadErrorToastActive: false,
        imageUploadToastOrigin: ''
      };
    case 'SETIMAGEUPLOADTOASTORIGIN_REQUEST':
      return {
        ...state,
        imageUploadToastOrigin: action.payload
      };
    case 'SETIMAGELOADINGANIMATIONORIGIN_REQUEST':
      return {
        ...state,
        imageLoadingAnimationOrigin: action.payload
      };
    case 'UPDATECOMPONENT_REQUEST':
      return {
        ...state,
        savingStatus: 'saving'
      };
    case 'UPDATECOMPONENT_SUCCESS':
      return {
        ...state,
        savingStatus: 'saved'
      };
    case 'CLOSEAUTOSAVESAVEDICON_REQUEST':
      return {
        ...state,
        savingStatus: 'default'
      };
    case 'SETOFFLINESTATUS_REQUEST':
      return {
        ...state,
        offlineStatusActive: action.payload
      };
    case 'SETCONNECTIONSTATUS_REQUEST':
      return {
        ...state,
        connectionStatus: action.payload
      };
    case 'TOGGLEEDITMENUPAGETITLE_REQUEST':
      return {
        ...state,
        pageTitleEditedInMenu: action.isEdited
      };
    case 'SETOPENCHAPTERSINLESSONSMENU_REQUEST':
      return {
        ...state,
        openChaptersInLessonsMenu: action.chapterData
      };
    case 'CREATEAPIKEYFORCLIENT_REQUEST':
      return {
        ...state,
        loadingToastActive: true,
        toastMessage: 'Creating key, this might take a few seconds...',
        disableCreateAPIKey: true
      };
    case 'CREATEAPIKEYFORCLIENT_SUCCESS':
      return {
        ...state,
        errorToastActive: false,
        loadingToastActive: false,
        toastActive: true,
        toastMessage: 'Key created',
        disableCreateAPIKey: false
      };
    case 'CREATEAPIKEYFORCLIENT_FAILURE':
      return {
        ...state,
        loadingToastActive: false,
        errorToastActive: true,
        toastMessage: "Couldn't create key, please try again",
        disableCreateAPIKey: false
      };
    case 'GETALLAPIKEYS_REQUEST':
      return {
        ...state,
        loadingAPITableOverlayActive: true
      };
    case 'GETALLAPIKEYS_SUCCESS':
    case 'GETALLAPIKEYS_FAILURE':
      return {
        ...state,
        loadingAPITableOverlayActive: false
      };
    case 'GETUSERSFILTERSLIST_REQUEST':
      return {
        ...state,
        loadingUserlistFilterOptions: true
      };
    case 'GETUSERSFILTERSLIST_SUCCESS':
    case 'GETUSERSFILTERSLIST_FAILURE':
      return {
        ...state,
        loadingUserlistFilterOptions: false
      };
    case 'GETMODULECONTENT_REQUEST':
      return {
        ...state,
        moduleContentSuccess: false
      };
    case 'GETMODULECONTENT_SUCCESS':
    case 'GETMODULECONTENT_FAILURE':
      return {
        ...state,
        moduleContentSuccess: true
      };
    case 'RESETUSERPROGRESS_REQUEST':
    case 'RESETUSERPROGRESS_USERTABLE_REQUEST':
      return {
        ...state,
        loadingResetUserProgress: true
      };
    case 'RESETUSERPROGRESS_SUCCESS':
    case 'RESETUSERPROGRESS_FAILURE':
    case 'RESETUSERPROGRESS_USERTABLE_SUCCESS':
    case 'RESETUSERPROGRESS_USERTABLE_FAILURE':
      return {
        ...state,
        loadingResetUserProgress: false
      };
    case 'MULTIADD_USERS_TO_PROGRAMS_REQUEST':
      return {
        ...state,
        multiaddToProgramsLoading: true
      };
    case 'MULTIADD_USERS_TO_PROGRAMS_SUCCESS':
      return {
        ...state,
        multiaddToProgramsLoading: false,
        toastActive: true,
        toastMessage: 'Users added to Programs'
      };
    case 'MULTIADD_USERS_TO_PROGRAMS_FAILURE':
      return {
        ...state,
        multiaddToProgramsLoading: false,
        errorToastActive: true,
        toastMessage: 'Error: Please check your connection and try again in a few minutes'
      };
    default:
      return state;
  }
};

export default componentStates;
