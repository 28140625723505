import React from 'react';
import update_status_in_progress_icon from '../../images/svg/update_status_in_progress_icon.svg';
import './UpdateUserStatusCacheInProgressStage.css';

const UpdateUserStatusCacheInProgressStage = ({
  total,
  current,
  title,
  progressLabel,
}) => {
  const progressPercentage = (current * 100) / total;
  const progressText =
    total > 0
      ? `${current} out of ${total} ${progressLabel}`
      : 'Getting progress...';

  return (
    <div className="updateStatusInProgressWrapper">
      <div className="updateStatusInProgressImageAndTitleWrapper">
        <img
          className="updateStatusInProgressImage inProgressRotatingIcon"
          src={update_status_in_progress_icon}
          alt="update in progress icon"
        />
        <div className="updateStatusInProgressText">{title}</div>
      </div>
      <div className="updateStatusInProgressBarBackground">
        <div className="updateStatusInProgressBarText">
          <div className="updateStatusInProgressBarDarkText">
            {progressText}
          </div>
        </div>
        <div
          className="updateStatusInProgressBarValue"
          style={{ width: `${progressPercentage}%` }}
        >
          <div className="updateStatusInProgressBarText">
            <div className="updateStatusInProgressBarLightText">
              {progressText}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateUserStatusCacheInProgressStage;
