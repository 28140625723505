import './AddUsersPage.css';
import { LOCAL_STORAGE_KEY } from './UploadStatus';
import { useBatchAddProgressStore } from './use-batch-add-progress.store';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import * as actions from '../actions';
import API from '../api/api';
import BatchUploadHistoryModal from './BatchUploadHistoryModal.js';
import BatchUploadModal from './BatchUploadModal.js';
import ButtonPrimaryBlue from '../components/styleguideComponents/ButtonPrimaryBlue';
import ButtonSecondaryOutlined from '../components/styleguideComponents/ButtonSecondaryOutlined';
import CourseAddUserForm from './CourseAddUserForm';
import download_icon from '../images/styleguideComponentsImages/download_arrow_icon.svg';
import ErrorToast from '../components/ErrorToast';
import history_icon from '../images/styleguideComponentsImages/time_back_icon.svg';
import page_icon from '../images/styleguideComponentsImages/page_icon.svg';
import PageBreadCrumbs from '../components/styleguideComponents/PageBreadCrumbs';
import ProgramLayout from '../components/layouts/ProgramLayout';
import React, { useEffect, useState } from 'react';
import Toast from '../components/Toast';

const AddUsersPage = () => {
  const dispatch = useDispatch();
  const { courseId } = useParams();
  const [uploading, setUploading] = useState(false);
  const [uploadResult, setUploadResult] = useState();
  const { setBatchAddTracking } = useBatchAddProgressStore();
  const [fileInputKey, setFileInputKey] = useState(Date.now());

  const onUpload = async (file) => {
    const formData = new FormData();
    formData.append('userList', file, file.name);
    setBatchAddTracking({ batchId: '', courseId: '' });
    setUploading(true);
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    const result = await API.batchAddUsersToCourse({
      programId: courseId,
      formData,
    });
    setBatchAddTracking({ batchId: result.batchId, courseId });
    if (result.error || result.queuedCount === 0) {
      setUploadResult(result);
    }
    setUploading(false);
    setFileInputKey(Date.now());
  };

  const toastActive = useSelector((state) => state.componentStates.toastActive);
  const errorToastActive = useSelector(
    (state) => state.componentStates.errorToastActive
  );
  const toastMessage = useSelector(
    (state) => state.componentStates.toastMessage
  );

  const [historyModalShown, setHistoryModalShown] = useState(false);

  const queryId = ['programBatchHistory', courseId];
  const { data, isLoading } = useQuery(queryId, () =>
    API.getBatchAddStatus({ courseId })
  );
  const batches = Object.values(data?.batches ?? {}).sort(
    (a, b) => b.batchId - a.batchId
  );

  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.invalidateQueries(queryId);
  }, [historyModalShown, uploading]);

  return (
    <ProgramLayout
      mainContent={
        <>
          <div className="courseAddUsersContent">
            <PageBreadCrumbs
              labelsArray={[
                { text: 'Users', link: `/course/${courseId}/users` },
                { text: 'Add user' },
              ]}
            />
            <div className="addUserFormTitle">Add user</div>
            <div className="courseAddUsersWrapper">
              <CourseAddUserForm />
              <div className="courseBatchAddUsersWrapper">
                <div className="batchAddUsersTitle">Batch add users</div>
                <div className="batchAddUsersSubTitle">
                  How to batch upload users
                </div>
                <div className="batchAddUsersHelperText">
                  1. Download the template file
                  <br />
                  2. Edit the file with the user&apos;s information
                  <br />
                  3. Save the file as .xlsx (Excel)
                  <br />
                  4. Upload the file here
                </div>
                {batches.length > 0 && (
                  <div
                    className={`uploadHistoryButton ${
                      uploading ? 'loading' : ''
                    }`}
                  >
                    <ButtonSecondaryOutlined
                      type="button"
                      label="Upload history"
                      disabled={uploading}
                      iconSource={history_icon}
                      onClick={() => setHistoryModalShown(true)}
                    />
                  </div>
                )}
                <div className="batchAddUsersDownloadButtonWrapper">
                  <div
                    className={`downloadTemplateButtonWrapper ${
                      uploading ? 'loading' : ''
                    }`}
                  >
                    <ButtonSecondaryOutlined
                      type="button"
                      label="Download template"
                      disabled={uploading}
                      iconSource={download_icon}
                      onClick={() =>
                        dispatch(
                          actions.downloadBatchUploadXlsxExample.request()
                        )
                      }
                    />
                  </div>
                  <div
                    className="batchAddUsersSubmitButtonWrapper"
                    key={fileInputKey}
                  >
                    <ButtonPrimaryBlue
                      type="button"
                      label={uploading ? 'Uploading' : 'Upload file'}
                      iconSource={page_icon}
                      loading={uploading}
                      disabled={uploading}
                    />
                    <input
                      type="file"
                      disabled={uploading}
                      onChange={(event) => onUpload(event.target.files[0])}
                      className="batchAddUsersFileInput"
                    />
                  </div>
                </div>
                <div className="batchAddUsersHelperText support">
                  Contact{' '}
                  <a
                    className="batchAddUsersSupportEmail"
                    href="mailto:support@moblrn.com"
                  >
                    support@moblrn.com
                  </a>{' '}
                  for assistance
                </div>
              </div>
            </div>
          </div>
          <BatchUploadHistoryModal
            shown={historyModalShown}
            courseId={courseId}
            loading={isLoading}
            batches={batches}
            onClose={() => setHistoryModalShown(false)}
          />
          <BatchUploadModal
            open={!!uploadResult}
            result={uploadResult}
            onClose={() => setUploadResult()}
          />
          {toastActive && <Toast text="User added" timeToClose={5000} />}
          {errorToastActive && (
            <ErrorToast
              text={toastMessage || 'Could not add user, please try again'}
              timeToClose={10000}
            />
          )}
        </>
      }
    />
  );
};

export default AddUsersPage;
