import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actions from '../../actions';
import Const from '../../store/Const';
import history from '../../store/history';
import { useOutsideClick, changeSortOrder } from '../../utils/componentUtils';
import { renderSortIcon } from './commonTableComponents';
import Tooltip from '../Tooltip';
import WarningModal from '../WarningModal';
import three_dots from '../../images/svg/three_dots.svg';
import three_dots_black_bg from '../../images/svg/three_dots_black_bg.svg';
import pencil_icon_grey from '../../images/svg/pencil_icon_grey.svg';
import red_x_mark from '../../images/svg/red_x_mark.svg';
import white_x_mark from '../../images/svg/white_x_mark.svg';
import client_overview_icon from '../../images/svg/client_overview_icon.svg';
import './ClientTable.css';

/**
* ClientTable is the table showing all clients available or specified
* by search
*/
const ClientTable = () => {
  const dispatch = useDispatch();
  const [openDropdownMenuIndex, setOpenDropdownMenuIndex] = useState(null);
  const [sortOrder, setSortOrder] = useState(1);
  const [sortAttribute, setSortAttribute] = useState('name');
  const [openDeleteClientModal, setOpenDeleteClientModal] = useState(false);
  const [clientToDelete, setClientToDelete] = useState(null);
  const reRenderClientTable = useSelector(state => state.componentStates.reRenderClientTable);
  const itemsPerPage = useSelector(state => state.componentStates.itemsPerPage);
  const currentPageNumber = useSelector(state => state.componentStates.currentPageNumber);
  const searchString = useSelector(state => state.componentStates.searchValues['clientName']) || null;
  const listedClients = useSelector(state => state.clients.clients) || [];
  const clientToDeleteObject = listedClients.find(client => client._id === clientToDelete);
  const clientToDeleteName = clientToDeleteObject ? clientToDeleteObject.name : "";

  useEffect(() => {
    dispatch(actions.getClients.request(
      itemsPerPage,
      currentPageNumber,
      searchString,
      sortOrder,
      sortAttribute
    ));
  }, [currentPageNumber, searchString, sortOrder, sortAttribute, reRenderClientTable]);
  const clientTypes = Object.keys(Const.clientTypes);
  const dropdownRef = useRef();
  useOutsideClick(dropdownRef, () => {
    setTimeout(() => setOpenDropdownMenuIndex(null), 100);
  });
  return (
    <table className="clientTable">
      <tbody>
        <tr>
          <th>
            <div
              className="clientTableClientAndIconContainer"
            >
              <div className="clientTableTitle">Client</div>
            </div>
          </th>
          <th>
            <div
              className="clientTableTitleAndIconContainer clientTableClickableTitle"
              onClick={() => changeSortOrder('clientType', sortAttribute, setSortAttribute, sortOrder, setSortOrder)}
            >
              <div className="clientTableTitle">Client Type</div>
              {renderSortIcon('clientType', sortAttribute, sortOrder)}
            </div>
          </th>
          <th>
            <div
              className="clientTableTitleAndIconContainer"
            >
              <div className="clientTableTitle">Programs</div>
            </div>
          </th>
          <th>
            <div
              className="clientTableTitleAndIconContainer clientTableClickableTitle"
              onClick={() => changeSortOrder('maxActivePrograms', sortAttribute, setSortAttribute, sortOrder, setSortOrder)}
            >
              <div className="clientTableTitle">Program Limit</div>
              {renderSortIcon('maxActivePrograms', sortAttribute, sortOrder)}
            </div>
          </th>
          <th className="clientTableTitle"></th>
        </tr>
        {listedClients.map((client, index) => (
          <tr className="clientTableRow" key={index}>
            <td className="clientTableText clientNameValue" onClick={() => history.push(`/clients/${client._id}/clientoverview`)}>{client.name}</td>
            <td className="clientTableText clientTypeValue" onClick={() => history.push(`/clients/${client._id}/clientoverview`)}>{clientTypes[client.clientType]}</td>
            <td className="clientTableText clientCoursesValue" onClick={() => history.push(`/clients/${client._id}/clientoverview`)}>{client.programsCount}</td>
            <td className="clientTableText clientCourseLimitValue" onClick={() => history.push(`/clients/${client._id}/clientoverview`)}>{client.maxActivePrograms}</td>
            <td
              className="clientTableMenuCell"
              onClick={() => setOpenDropdownMenuIndex(openDropdownMenuIndex === index ? null : index)}
            >
              <div className="clientTableMenuIconsContainer">
                <img className="clientTableMenuIcon clientTableMenuIconGrey" src={three_dots} alt="three dots"/>
                <img className="clientTableMenuIconBlackBG" src={three_dots_black_bg} alt="three dots"/>
                {openDropdownMenuIndex === null && <Tooltip text="Client menu" width="70"/>}
                {index === openDropdownMenuIndex && renderDropdownMenu(dropdownRef, setOpenDeleteClientModal, setClientToDelete, listedClients[index]._id)}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
      <WarningModal
        open={openDeleteClientModal}
        onClose={() => setOpenDeleteClientModal(false)}
        title='Delete client'
        text1={`Are you sure you want to delete the client "${clientToDeleteName}"?`}
        text2={`The client "${clientToDeleteName}" will be deleted and CAN NOT be recovered`}
        leftButtonText='NO, CANCEL'
        rightButtonText='YES, DELETE'
        onLeftButtonClick={() => handleCloseDeleteClientModal(setOpenDeleteClientModal, setClientToDelete)}
        onRightButtonClick={() => handleDeleteClient(setOpenDeleteClientModal, dispatch, clientToDelete, setClientToDelete)}
      />
    </table>
  );
};


const renderDropdownMenu = (dropdownRef, setOpenDeleteClientModal, setClientToDelete, clientId) => {
  return (
    <div className="clientTableDropdownMenu" ref={dropdownRef}>
      <Link className="clientTableDropdownMenuItem" to={`/clients/${clientId}/clientoverview`}>
        <div className="clientDropdownItemContainer">
          <div className="clientTableDropdownMenuText">Client overview</div>
          <img className="clientTableDropdownMenuIcon" src={client_overview_icon} alt="pencil icon"/>
        </div>
      </Link>
      <Link className="clientTableDropdownMenuItem" to={`/client/${clientId}`}>
        <div className="clientDropdownItemContainer">
          <div className="clientTableDropdownMenuText">Edit client</div>
          <img className="clientTableDropdownMenuIcon" src={pencil_icon_grey} alt="pencil icon"/>
        </div>
      </Link>
      <div className="clientDropdownDeleteContainer" onClick={() => handleOpenDeleteClientModal(setOpenDeleteClientModal, setClientToDelete, clientId)}>
        <div className="clientTableDropdownMenuText clientDropdownDeleteText">Delete client</div>
        <img className="clientTableDropdownMenuX" src={red_x_mark} alt="crossmark"/>
        <img className="clientTableDropdownMenuX clientTableXWhite" src={white_x_mark} alt="crossmark"/>
      </div>
    </div>
  );
};

const handleOpenDeleteClientModal = (setOpenDeleteClientModal, setClientToDelete, clientId) => {
  setOpenDeleteClientModal(true);
  setClientToDelete(clientId);
};

const handleCloseDeleteClientModal = (setOpenDeleteClientModal, setClientToDelete) => {
  setOpenDeleteClientModal(false);
  setClientToDelete(null);
};

const handleDeleteClient = (setOpenDeleteClientModal, dispatch, clientToDelete, setClientToDelete) => {
  setOpenDeleteClientModal(false);
  dispatch(actions.deleteClient.request(clientToDelete, null));
  dispatch(actions.setToastOrigin.request('/clients'));
  setClientToDelete(null);
};

export default ClientTable;
