import React from 'react';
import spinner_grey from '../images/svg/spinner_grey.svg';

/**
* SmallSpinner is used as a secondary loading icon i.e. in tables.
* Should be removed when the CMS will be standalone react and is a
* component only because images cannot be built properly
*/
const SmallSpinner = () => {
  return (
    <span style={{marginLeft: "5px"}}>
      <img src={spinner_grey} alt="spinner"/>
    </span>
  );
};

export default SmallSpinner;
