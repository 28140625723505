import React from 'react';
import {
  calculateAnswerPercentage,
  calculateSliderAnswerPercentage,
  calculateNonOptionAnswerPercentage,
  sortAnswersByAnswerPercentage
} from '../utils/statisticsUtils';
import { vimeoUrlFixer, youtubeUrlFixer } from '../utils/componentUtils';
import Slider from "react-slick";
import Fade from '@material-ui/core/Fade';
import Collapse from '@material-ui/core/Collapse';
import noImage from '../images/no-image.png';
import headline_icon from '../images/svg/headline_icon.svg';
import one_choice_icon from '../images/svg/one_choice_icon.svg';
import one_choice_icon_right from '../images/svg/one_choice_radio_button_checked.svg';
import one_choice_icon_wrong from '../images/svg/one_choice_radio_button_wrong.svg';
import multi_choice_icon from '../images/svg/multi_choice_icon.svg';
import multi_choice_icon_right from '../images/svg/multichoice_checked_checkbox.svg';
import multi_choice_icon_wrong from '../images/svg/multichoice_wrong_checkbox.svg';
import slider_icon from '../images/svg/slider_icon.svg';
import carousel_next_arrow from '../images/svg/carousel_next_arrow.svg';
import carousel_prev_arrow from '../images/svg/carousel_prev_arrow.svg';
import './image-gallery.css';
import './AnsweredLessonComponents.css';


/**
* renderAnsweredLessonComponents() creates a component based on the type of the
* interactionObject.
*/
export const renderAnsweredLessonComponents = (
  interactionObject,
  i = 0,
  componentAnswers,
  componentOptions,
  numberOfAnswerees,
  showTextAnswers,
  setShowTextAnswers,
  showParagpraghAnswers,
  setShowParagpraghAnswers,
  showMultiChoiceAnswers,
  setShowMultiChoiceAnswers,
  showCheckboxAnswers,
  setShowCheckboxAnswers,
  showSliderAnswers,
  setShowSliderAnswers,
  showComments,
  setShowComments
) => {
  interactionObject.data = interactionObject.data ? interactionObject.data : {};
  interactionObject.data.text = interactionObject.data.text ? interactionObject.data.text : "";
  interactionObject.data.url = interactionObject.data.url ? interactionObject.data.url : "";

  switch (interactionObject.type) {
    case 1:
      return (
        <div className="answeredComponent" key={i}>
          <div className="answeredTitleComponent">{interactionObject.data ? interactionObject.data.text : ""}</div>
        </div>
      );
    case 2:
      return (
        <div className="answeredComponent" key={i}>
          <div className="answeredParagraphComponent">{interactionObject.data ? interactionObject.data.text : ""}</div>
        </div>
      );
    case 3: {
      let imageHasSource = interactionObject.data && interactionObject.data.key;
      let imageSource = imageHasSource ? process.env.REACT_APP_MEDIA_URL + interactionObject.data.key : noImage;
      return (
        <Fade
          key={i}
          in={true}
          style={{ transformOrigin: '0 0 0' }}
          {...({ timeout: 400 + 200*i })}
        >
          {ImageComponent(imageHasSource, imageSource, i)}
        </Fade>
      );
    }
    case 4:
      return (
        <Fade
          key={i}
          in={true}
          style={{ transformOrigin: '0 0 0' }}
          {...({ timeout: 1000 + 200*i })}
        >
          {YoutubeComponent(interactionObject.data.url, i)}
        </Fade>
      );
    case 5:
      return (
        <div className="answeredComponent" key={i}>
          <a className="answeredLinkComponent" target="_blank" rel="noopener noreferrer" href={interactionObject.data.url}>{interactionObject.data.text}</a>
        </div>
      );
    case 11:
      return QuestionTextComponent(interactionObject.data.text, interactionObject.data.textCorrect, i, componentAnswers, interactionObject._id, showTextAnswers, setShowTextAnswers);
    case 12:
      return QuestionParagraphComponent(interactionObject.data.text, i, componentAnswers, interactionObject._id, showParagpraghAnswers, setShowParagpraghAnswers);
    case 13:
      return MultipleChoiceComponent(componentOptions, i, componentAnswers, interactionObject._id, showMultiChoiceAnswers, setShowMultiChoiceAnswers);
    case 14:
      return CheckboxComponent(componentOptions, i, componentAnswers, numberOfAnswerees, interactionObject._id, showCheckboxAnswers, setShowCheckboxAnswers);
    case 15:
      return MultipleChoiceComponent(componentOptions, i, componentAnswers, interactionObject._id, showMultiChoiceAnswers, setShowMultiChoiceAnswers);
    case 16:
      return SliderComponent(componentAnswers, i, interactionObject.data, interactionObject._id, showSliderAnswers, setShowSliderAnswers);
    case 20:
      return ImageSliderComponent(interactionObject.data.keys, i);
    case 21:
      return <div className="answeredComponent"/>;
    case 30:
      return CommentComponent(componentAnswers, i, showComments, setShowComments);
    default:
      return null;
  }
};

/**
* ImageComponent() renders the image component. If no image is provided, it will
* render a white space.
*/
const ImageComponent = (imageHasSource, imageSource, i) => {
  if (imageHasSource) {
    return (
      <div className="answeredComponent" key={i}>
        <img
          className="answeredImage"
          alt="Image not available"
          src={imageSource}
        />
      </div>
    );
  } else {
    return <div className="answeredComponent" key={i}/>;
  }
};

/**
* YoutubeComponent() renders a youtube or vimeo video
*/
export const YoutubeComponent = (url, i) => {
  if (url) {
    url = url.includes("vimeo") ? vimeoUrlFixer(url) : youtubeUrlFixer(url);
    return (
      <div className="answeredComponent" key={i}>
        <iframe
          className="answeredVideoComponent"
          src={url}
          allowFullScreen="allowFullScreen"
          mozallowfullscreen="mozallowfullscreen"
          msallowfullscreen="msallowfullscreen"
          oallowfullscreen="oallowfullscreen"
          webkitallowfullscreen="webkitallowfullscreen"
          title="title">
        </iframe>
      </div>
    );
  } else {
    return (
      <div className="answeredComponent"/>
    );
  }
};

/**
* QuestionTextComponent() renders a text field component for
* the interaction form. Requires a specific text to be correct
*/
export const QuestionTextComponent = (questionText, correctAnswer, i, componentAnswers, componentId, showTextAnswers, setShowTextAnswers) => {
  const uniqueAnswers = [...new Set(componentAnswers)];
  let answerPercentageArray = calculateNonOptionAnswerPercentage(correctAnswer, componentAnswers, componentAnswers.length);
  const { sortedUniqueAnswers, sortedAnswerRatios } = sortAnswersByAnswerPercentage(uniqueAnswers, answerPercentageArray);
  const topFiveComponentAnswers = sortedUniqueAnswers.slice(0, 5);
  const restOfComponentAnswers = sortedUniqueAnswers.slice(5, sortedUniqueAnswers.length);

  return (
    <div className="answeredComponent" key={i}>
      <div className="answeredComponentTitle">
        <div className="answeredComponentTitleIconAndText">
          <img className="answeredComponentTitleIcon" src={headline_icon}/>
          <div className="answeredComponentTitleText">Correct spelling</div>
        </div>
        <div className="answeredComponentTitleText">
          {`${componentAnswers.length > 0 ? componentAnswers.length : 0} ${componentAnswers.length === 1 ? "answer" : "answers"}`}
        </div>
      </div>
      <div className="answeredComponentSubTitle">QUESTION</div>
      <div className="answeredTextQuestionText">{questionText || "Question text missing..."}</div>
      <div className="answeredComponentSubTitle">CORRECT ANSWER</div>
      <div className="answeredTextQuestionText">{correctAnswer || "Correct answer missing..."}</div>
      <div className="answeredComponentSubTitle">RESULT</div>
      <div>
        {topFiveComponentAnswers.map((answer, i) => {
          const currentAnswers = componentAnswers.filter(currentAnswer => {return currentAnswer === answer;});
          return (
            <div key={i} className="questionTextAnswer">
              <div className="questionTextAnswerIconAndText">
                <div
                  className={answer === correctAnswer ? "questionTextAnswerIcon questionTextAnswerIconRight" : "questionTextAnswerIcon questionTextAnswerIconWrong"}
                />
                <div className="questionTextAnswerText">{answer}</div>
              </div>
              <div className="choiceQuestionPercentageComponent">
                <div className="choiceQuestionPercentageText">{`${sortedAnswerRatios[i]}%`} <span className="choiceQuestionPercentageTotal">{`(${currentAnswers.length})`}</span></div>
                {
                  answer === correctAnswer ?
                  <div className="choiceQuestionPercentageSlider">
                    <input type="range" min="0" max="100" value={sortedAnswerRatios[i]} readOnly/>
                  </div>
                  :
                  <div className="choiceQuestionPercentageSlider choiceQuestionPercentageSliderWrong">
                    <input type="range" min="0" max="100" value={sortedAnswerRatios[i]} readOnly/>
                  </div>
                }
              </div>
            </div>
          );}
        )}
        <Collapse in={showTextAnswers === componentId}>
          <div>
            {restOfComponentAnswers.map((answer, i) => {
              const currentAnswers = componentAnswers.filter(currentAnswer => {return currentAnswer === answer;});
              return (
                <div key={i} className="questionTextAnswer">
                  <div className="questionTextAnswerIconAndText">
                    <div
                      className={answer === correctAnswer ? "questionTextAnswerIcon questionTextAnswerIconRight" : "questionTextAnswerIcon questionTextAnswerIconWrong"}
                    />
                    <div className="questionTextAnswerText">{answer}</div>
                  </div>
                  <div className="choiceQuestionPercentageComponent">
                    <div className="choiceQuestionPercentageText">{`${sortedAnswerRatios[i]}%`} <span className="choiceQuestionPercentageTotal">{`(${currentAnswers.length})`}</span></div>
                    {
                      answer === correctAnswer ?
                      <div className="choiceQuestionPercentageSlider">
                        <input type="range" min="0" max="100" value={sortedAnswerRatios[i]} readOnly/>
                      </div>
                      :
                      <div className="choiceQuestionPercentageSlider choiceQuestionPercentageSliderWrong">
                        <input type="range" min="0" max="100" value={sortedAnswerRatios[i]} readOnly/>
                      </div>
                    }
                  </div>
                </div>
              );}
            )}
          </div>
        </Collapse>
      </div>
      {uniqueAnswers.length > 5 &&
        <div className="showAnswersButton" onClick={() => setShowTextAnswers(showTextAnswers === componentId ? null : componentId)}>
          {showTextAnswers === componentId ? "Hide all answers" : "Show all answers"}
        </div>
      }
    </div>
  );
};

/**
* QuestionParagraphComponent() renders a long free text field component
* for the interaction form. Expects free text answer
*/
export const QuestionParagraphComponent = (questionText, i, componentAnswers, componentId, showParagpraghAnswers, setShowParagpraghAnswers) => {
  const topFiveComponentAnswers = componentAnswers.slice(0, 5);
  const restOfComponentAnswers = componentAnswers.slice(5, componentAnswers.length);

  return (
    <div className="answeredComponent" key={i}>
      <div className="answeredComponentTitle">
        <div className="answeredComponentTitleIconAndText">
          <img className="answeredComponentTitleIcon" src={headline_icon}/>
          <div className="answeredComponentTitleText">Open answer</div>
        </div>
        <div className="answeredComponentTitleText">
          {`${componentAnswers.length > 0 ? componentAnswers.length : 0} ${componentAnswers.length === 1 ? "answer" : "answers"}`}
        </div>
      </div>
      <div className="answeredComponentSubTitle">QUESTION</div>
      <div className="answeredTextQuestionText">{questionText || "Question text missing..."}</div>
      <div className="answeredComponentSubTitle">RESULT</div>
      <div>
        {topFiveComponentAnswers.map((answer, i) => {
          return (
            <div key={i} className="answeredParagraphComponent questionParagraphAnswers">
              <div className="questionParagraphAnswerIcon"/>
              <div className="questionParagraphAnswerText">{answer}</div>
            </div>
          );
        })}
        <Collapse in={showParagpraghAnswers === componentId}>
          {restOfComponentAnswers.map((answer, i) => {
            return (
              <div key={i} className="answeredParagraphComponent questionParagraphAnswers">
                <div className="questionParagraphAnswerIcon"/>
                <div className="questionParagraphAnswerText">{answer}</div>
              </div>
            );
          })}
        </Collapse>
      </div>
      {componentAnswers.length > 5 &&
        <div className="showAnswersButton" onClick={() => setShowParagpraghAnswers(showParagpraghAnswers === componentId ? null : componentId)}>
          {showParagpraghAnswers === componentId ? "Hide all answers" : "Show all answers"}
        </div>
      }
    </div>
  );
};

/**
* MultipleChoiceComponent() renders the answers for the radio group component
* and the dropdown component
*/
export const MultipleChoiceComponent = (options, i, answers, componentId, showMultiChoiceAnswers, setShowMultiChoiceAnswers) => {
  let answerPercentageArray = calculateAnswerPercentage(options, answers, answers.length);
  let hasRightOption = questionHasCorrectAnwer(options);
  const { sortedUniqueAnswers, sortedAnswerRatios } = sortAnswersByAnswerPercentage(options, answerPercentageArray);
  const topFiveOptions = sortedUniqueAnswers.slice(0, 5);
  const restOfOptions = sortedUniqueAnswers.slice(5, sortedUniqueAnswers.length);

  return (
    <div className="answeredComponent" key={i}>
      <div className="answeredComponentTitle">
        <div className="answeredComponentTitleIconAndText">
          <img className="answeredComponentTitleIcon" src={one_choice_icon}/>
          <div className="answeredComponentTitleText">One choice</div>
        </div>
        <div className="answeredComponentTitleText">
          {`${answers.length > 0 ? answers.length : 0} ${answers.length === 1 ? "answer" : "answers"}`}
        </div>
      </div>
      <div>
        {topFiveOptions.map((option, index) => {
          let questionIconColor = option.isCorrect || !hasRightOption ? one_choice_icon_right : one_choice_icon_wrong;
          const currentAnswers = answers.filter(currentAnswer => {return currentAnswer === option._id;});
          return (
            <div className="choiceQuestionRow" key={index}>
              <div className="choiceQuestionIconAndText">
                <img className="choiceQuestionIcon" src={questionIconColor}/>
                <div className="choiceQuestionText">{option.name}</div>
              </div>
              <div className="choiceQuestionPercentageComponent">
                <div className="choiceQuestionPercentageText">{`${sortedAnswerRatios[index]}%`} <span className="choiceQuestionPercentageTotal">{`(${currentAnswers.length})`}</span></div>
                {
                  option.isCorrect || !hasRightOption ?
                  <div className="choiceQuestionPercentageSlider">
                    <input type="range" min="0" max="100" value={sortedAnswerRatios[index]} readOnly/>
                  </div>
                  :
                  <div className="choiceQuestionPercentageSlider choiceQuestionPercentageSliderWrong">
                    <input type="range" min="0" max="100" value={sortedAnswerRatios[index]} readOnly/>
                  </div>
                }
              </div>
            </div>
          );
        })}
        <Collapse in={showMultiChoiceAnswers === componentId}>
          {restOfOptions.map((option, index) => {
            let questionIconColor = option.isCorrect || !hasRightOption ? one_choice_icon_right : one_choice_icon_wrong;
            const currentAnswers = answers.filter(currentAnswer => {return currentAnswer === option._id;});
            return (
              <div className="choiceQuestionRow" key={index}>
                <div className="choiceQuestionIconAndText">
                  <img className="choiceQuestionIcon" src={questionIconColor}/>
                  <div className="choiceQuestionText">{option.name}</div>
                </div>
                <div className="choiceQuestionPercentageComponent">
                  <div className="choiceQuestionPercentageText">{`${sortedAnswerRatios[index]}%`} <span className="choiceQuestionPercentageTotal">{`(${currentAnswers.length})`}</span></div>
                  {
                    option.isCorrect || !hasRightOption ?
                    <div className="choiceQuestionPercentageSlider">
                      <input type="range" min="0" max="100" value={sortedAnswerRatios[index]} readOnly/>
                    </div>
                    :
                    <div className="choiceQuestionPercentageSlider choiceQuestionPercentageSliderWrong">
                      <input type="range" min="0" max="100" value={sortedAnswerRatios[index]} readOnly/>
                    </div>
                  }
                </div>
              </div>
            );
          })}
        </Collapse>
      </div>
      {options.length > 5 &&
        <div className="showAnswersButton" onClick={() => setShowMultiChoiceAnswers(showMultiChoiceAnswers === componentId ? null : componentId)}>
          {showMultiChoiceAnswers === componentId ? "Hide all answers" : "Show all answers"}
        </div>
      }
    </div>
  );
};

/**
* CheckboxComponent() renders the answers for the checkbox group component
*/
export const CheckboxComponent = (options, i, answers, numberOfAnswerees, componentId, showCheckboxAnswers, setShowCheckboxAnswers) => {
  let answerPercentageArray = calculateAnswerPercentage(options, answers, answers.length);
  let hasRightOption = questionHasCorrectAnwer(options);
  const { sortedUniqueAnswers, sortedAnswerRatios } = sortAnswersByAnswerPercentage(options, answerPercentageArray);
  const topFiveOptions = sortedUniqueAnswers.slice(0, 5);
  const restOfOptions = sortedUniqueAnswers.slice(5, sortedUniqueAnswers.length);

  return (
    <div className="answeredComponent" key={i}>
      <div className="answeredComponentTitle">
        <div className="answeredComponentTitleIconAndText">
          <img className="answeredComponentTitleIcon" src={multi_choice_icon}/>
          <div className="answeredComponentTitleText">Multiple choices</div>
        </div>
        <div className="answeredComponentTitleText">
          {`${answers.length > 0 ? answers.length : 0} ${answers.length === 1 ? "answer" : "answers"}`}
        </div>
      </div>
      <div>
        {topFiveOptions.map((option, index) => {
          let questionIconColor = option.isCorrect || !hasRightOption ? multi_choice_icon_right : multi_choice_icon_wrong;
          const currentAnswers = answers.filter(currentAnswer => {return currentAnswer === option._id;});
          return (
            <div className="choiceQuestionRow" key={index}>
              <div className="choiceQuestionIconAndText">
                <img className="choiceQuestionIcon" src={questionIconColor}/>
                <div className="choiceQuestionText">{option.name}</div>
              </div>
              <div className="choiceQuestionPercentageComponent">
                <div className="choiceQuestionPercentageText">{`${sortedAnswerRatios[index]}%`} <span className="choiceQuestionPercentageTotal">{`(${currentAnswers.length})`}</span></div>
                {
                  option.isCorrect || !hasRightOption ?
                  <div className="choiceQuestionPercentageSlider">
                    <input type="range" min="0" max="100" value={sortedAnswerRatios[index]} readOnly/>
                  </div>
                  :
                  <div className="choiceQuestionPercentageSlider choiceQuestionPercentageSliderWrong">
                    <input type="range" min="0" max="100" value={sortedAnswerRatios[index]} readOnly/>
                  </div>
                }
              </div>
            </div>
          );
        })}
        <Collapse in={showCheckboxAnswers === componentId}>
          {restOfOptions.map((option, index) => {
            let questionIconColor = option.isCorrect || !hasRightOption ? multi_choice_icon_right : multi_choice_icon_wrong;
            const currentAnswers = answers.filter(currentAnswer => {return currentAnswer === option._id;});
            return (
              <div className="choiceQuestionRow" key={index}>
                <div className="choiceQuestionIconAndText">
                  <img className="choiceQuestionIcon" src={questionIconColor}/>
                  <div className="choiceQuestionText">{option.name}</div>
                </div>
                <div className="choiceQuestionPercentageComponent">
                  <div className="choiceQuestionPercentageText">{`${sortedAnswerRatios[index]}%`} <span className="choiceQuestionPercentageTotal">{`(${currentAnswers.length})`}</span></div>
                  {
                    option.isCorrect || !hasRightOption ?
                    <div className="choiceQuestionPercentageSlider">
                      <input type="range" min="0" max="100" value={sortedAnswerRatios[index]} readOnly/>
                    </div>
                    :
                    <div className="choiceQuestionPercentageSlider choiceQuestionPercentageSliderWrong">
                      <input type="range" min="0" max="100" value={sortedAnswerRatios[index]} readOnly/>
                    </div>
                  }
                </div>
              </div>
            );
          })}
        </Collapse>
      </div>
      {options.length > 5 &&
        <div className="showAnswersButton" onClick={() => setShowCheckboxAnswers(showCheckboxAnswers === componentId ? null : componentId)}>
          {showCheckboxAnswers === componentId ? "Hide all answers" : "Show all answers"}
        </div>
      }
    </div>
  );
};

/**
* SliderComponent() renders the student answers of the Slider component along
* with the correct answer
*/
export const SliderComponent = (answers, i, data, componentId, showSliderAnswers, setShowSliderAnswers) => {
  let options = [...new Set(answers)];
  options.sort();
  let answerPercentageArray = calculateSliderAnswerPercentage(options, answers);
  const { sortedUniqueAnswers, sortedAnswerRatios } = sortAnswersByAnswerPercentage(options, answerPercentageArray);
  const topFiveOptions = sortedUniqueAnswers.slice(0, 5);
  const restOfOptions = sortedUniqueAnswers.slice(5, sortedUniqueAnswers.length);

  return (
    <div className="answeredComponent" key={i}>
      <div className="answeredComponentTitle">
        <div className="answeredComponentTitleIconAndText">
          <img className="answeredComponentTitleIcon" src={slider_icon}/>
          <div className="answeredComponentTitleText">Slider</div>
        </div>
        <div className="answeredComponentTitleText">
          {`${answers.length > 0 ? answers.length : 0} ${answers.length === 1 ? "answer" : "answers"}`}
        </div>
      </div>
      <div>
        {topFiveOptions.map((option, index) => {
          const currentAnswers = answers.filter(currentAnswer => {return currentAnswer === option;});
          return (
            <div className="choiceQuestionRow" key={index}>
              <div className="questionTextAnswerIconAndText">
                <div
                  className={data.numCorrect && option === data.numCorrect.toString() ?
                    "questionTextAnswerIcon questionTextAnswerIconRight" : "questionTextAnswerIcon questionTextAnswerIconWrong"
                  }
                />
                <div className="questionTextAnswerText">{option}</div>
              </div>
              <div className="choiceQuestionPercentageComponent">
                <div className="choiceQuestionPercentageText">{`${sortedAnswerRatios[index]}%`} <span className="choiceQuestionPercentageTotal">{`(${currentAnswers.length})`}</span></div>
                {
                  data.numCorrect && option === data.numCorrect.toString() ?
                  <div className="choiceQuestionPercentageSlider">
                    <input type="range" min="0" max="100" value={sortedAnswerRatios[index]} readOnly/>
                  </div>
                  :
                  <div className="choiceQuestionPercentageSlider choiceQuestionPercentageSliderWrong">
                    <input type="range" min="0" max="100" value={sortedAnswerRatios[index]} readOnly/>
                  </div>
                }
              </div>
            </div>
          );
        })}
        <Collapse in={showSliderAnswers === componentId}>
          {restOfOptions.map((option, index) => {
            const currentAnswers = answers.filter(currentAnswer => {return currentAnswer === option;});
            return (
              <div className="choiceQuestionRow" key={index}>
                <div className="questionTextAnswerIconAndText">
                  <div
                    className={data.numCorrect && option === data.numCorrect.toString() ?
                      "questionTextAnswerIcon questionTextAnswerIconRight" : "questionTextAnswerIcon questionTextAnswerIconWrong"
                    }
                  />
                  <div className="questionTextAnswerText">{option}</div>
                </div>
                <div className="choiceQuestionPercentageComponent">
                  <div className="choiceQuestionPercentageText">{`${sortedAnswerRatios[index]}%`} <span className="choiceQuestionPercentageTotal">{`(${currentAnswers.length})`}</span></div>
                  {
                    option === data.numCorrect.toString() ?
                    <div className="choiceQuestionPercentageSlider">
                      <input type="range" min="0" max="100" value={sortedAnswerRatios[index]} readOnly/>
                    </div>
                    :
                    <div className="choiceQuestionPercentageSlider choiceQuestionPercentageSliderWrong">
                      <input type="range" min="0" max="100" value={sortedAnswerRatios[index]} readOnly/>
                    </div>
                  }
                </div>
              </div>
            );
          })}
        </Collapse>
      </div>
      {options.length > 5 &&
        <div className="showAnswersButton" onClick={() => setShowSliderAnswers(showSliderAnswers === componentId ? null : componentId)}>
          {showSliderAnswers === componentId ? "Hide all answers" : "Show all answers"}
        </div>
      }
    </div>
  );
};

/**
* ImageSliderComponent() renders an image slider component
*/
export const ImageSliderComponent = (images, i) => {
  let imageList = images.map((image) => {
    return {
      original: process.env.REACT_APP_MEDIA_URL + image.name,
    };
  });
  const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <img className={className} onClick={onClick} src={carousel_next_arrow}/>
    );
  };
  const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <img className={className} onClick={onClick} src={carousel_prev_arrow}/>
    );
  };
  const settings = {
    nextArrow: <NextArrow/>,
    prevArrow: <PrevArrow/>
  };
  return (
    <div className="answeredComponent" key={i}>
      <Slider {...settings}>
        {imageList.map((image, index) => {
          return (
            <div key={index}>
              <img className="answeredImageSliderImage" src={image.original}/>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

/**
* CommentComponent() renders the comments from a comment component
*/
export const CommentComponent = (comments, index, showComments, setShowComments) => {
  return (
    <div className="questionTextComponent answeredLessonComponent" key={index}>
      {comments.length > 0 ?
        <div className="showAnswersButton" onClick={() => setShowComments(!showComments)}>
          {showComments ? "Hide comments" : "Show comments"}
        </div>
        :
        <div className="showAnswersButton">
          No comments yet
        </div>
      }
      <Collapse in={showComments}>
        <ul className="textAnswerList">
          {comments.map((comment, i) => <li key={i}>{comment}</li>)}
        </ul>
      </Collapse>
    </div>
  );
};

/**
* questionHasCorrectAnwer checks if the the question has any correct answer
*/
const questionHasCorrectAnwer = (options) => {
  let hasRightOption = false;
  for (let i = 0; i < options.length; i++) {
    if (options[i].isCorrect === true) {
      hasRightOption = true;
    }
  }
  return hasRightOption;
};
