import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useOutsideClick } from '../utils/componentUtils';
import Const from '../store/Const';
import * as actions from '../actions';
import Menu from '../components/Menu.js';
import ClientAPITable from '../components/tables/ClientAPITable.js';
import Toast from '../components/Toast.js';
import ErrorToast from '../components/ErrorToast.js';
import LoadingToast from '../components/LoadingToast.js';
import ClientOverviewAdminList from '../components/tables/ClientOverviewAdminList';
import WarningModal from '../components/WarningModal';
import three_dots from '../images/svg/three_dots.svg';
import three_dots_black_bg from '../images/svg/three_dots_black_bg.svg';
import pencil_icon_grey from '../images/svg/pencil_icon_grey.svg';
import red_x_mark from '../images/svg/red_x_mark.svg';
import white_x_mark from '../images/svg/white_x_mark.svg';
import gears_api from '../images/svg/gears_api.svg';
import disable_api_gears from '../images/svg/disable_api_gears.svg';
import './ClientOverviewPage.css';

/**
* ClientOverviewPage
*/
const ClientOverviewPage = () => {
  const dispatch = useDispatch();
  const admins = useSelector(state => state.admins.admins);
  const toastActive = useSelector(state => state.componentStates.toastActive);
  const errorToastActive = useSelector(state => state.componentStates.errorToastActive);
  const loadingToastActive = useSelector(state => state.componentStates.loadingToastActive);
  const toastMessage = useSelector(state => state.componentStates.toastMessage);
  const { clientId } = useParams();
  const [openDropdownMenu, setOpenDropdownMenu] = useState(false);
  const [openDeleteClientModal, setOpenDeleteClientModal] = useState(false);
  const [clientToDelete, setClientToDelete] = useState(null);
  const [openDeleteAPIModal, setOpenDeleteAPIModal] = useState(false);
  const client = useSelector(state => state.clients.currentClient);
  const clientHasAPI = useSelector(state => state.clients.currentClient.publicApiEnabled);

  const dropdownRef = useRef();
  useOutsideClick(dropdownRef, () => {
    setTimeout(() => setOpenDropdownMenu(false), 100);
  });

  useEffect(() => {
    dispatch(actions.getClient.request(clientId));
    return (() => {
      dispatch(actions.getClient.success({}));
    });
  }, [clientId]);

  const getClientType = (object, value) => {
    return Object.keys(object).find(key => object[key] === `${value}`);
  };

  const renderEnableDisableAPIButton = () => {
    if (clientHasAPI) {
      return (
        <div className="clientOverviewDropdownItem" onClick={() => handleOpenDeleteAPIModal(setOpenDeleteAPIModal, setClientToDelete, clientId)}>
          <div>Disable API</div>
          <img className="clientOverviewDropdownItemIcon" src={disable_api_gears}/>
        </div>
      );
    } else {
      return (
        <div className="clientOverviewDropdownItem" onClick={() => dispatch(actions.enableAPIForClient.request(clientId))}>
          <div>Enable API</div>
          <img className="clientOverviewDropdownItemIcon" src={gears_api}/>
        </div>
      );
    }
  };

  return (
    <div className="clientOverviewPageContainer">
      <Menu/>
      <div className="clientOverviewContentWrapper">
        <div className="clientOverviewTitle">CLIENT OVERVIEW</div>
        <div className="clientOverviewDetailsWrapper">
          <div className="clientOverviewNameAndMenuWrapper">
            <div className="clientOverviewName">{client.name}</div>
            <div className="clientOverviewMenuIconsWrapper" onClick={() => setOpenDropdownMenu(!openDropdownMenu)}>
              <img className="clientOverviewMenuIcon" src={three_dots}/>
              <img className="clientOverviewMenuIconOnHover" src={three_dots_black_bg}/>
              {openDropdownMenu &&
                <div className="clientOverviewMenuDropdown" ref={dropdownRef}>
                  {renderEnableDisableAPIButton()}
                  <Link className="clientOverviewDropdownItem" to={`/client/${clientId}`}>
                    <div>Edit client</div>
                    <img className="clientOverviewDropdownItemIcon" src={pencil_icon_grey}/>
                  </Link>
                  <div className="clientOverviewDropdownDelete" onClick={() => handleOpenDeleteClientModal(setOpenDeleteClientModal, setClientToDelete, clientId)}>
                    <div>Delete client</div>
                    <img className="clientOverviewDropdownMenuX" src={red_x_mark} alt="crossmark"/>
                    <img className="clientOverviewDropdownMenuX clientOverviewXWhite" src={white_x_mark} alt="crossmark"/>
                  </div>
                </div>
              }
            </div>
          </div>
          <div className="clientOverviewDetailsTextsWrapper">
            <div className="clientOverviewProperty">Client type: <span className="clientOverviewValue">{getClientType(Const.clientTypes, client.clientType) || "-"}</span></div>
            <div className="clientOverviewProperty">Admins: <span className="clientOverviewValue">{`${admins && admins.length || 0}/${client.maxAdmins || 0}`}</span></div>
            <div className="clientOverviewProperty">Unique users: <span className="clientOverviewValue">{`${client.activeStudents || 0}/${client.maxActiveUsers || 0}`}</span></div>
            <div className="clientOverviewProperty">Programs: <span className="clientOverviewValue">{`${client.activePrograms || 0}/${client.maxActivePrograms || 0}`}</span></div>
          </div>
        </div>
        {clientHasAPI && <ClientAPITable/>}
        <ClientOverviewAdminList/>
      </div>
      <WarningModal
        open={openDeleteClientModal}
        onClose={() => setOpenDeleteClientModal(false)}
        title='Delete client'
        text1={`Are you sure you want to delete the client "${client.name}"?`}
        text2={`The client "${client.name}" will be deleted and CAN NOT be recovered`}
        leftButtonText='NO, CANCEL'
        rightButtonText='YES, DELETE'
        onLeftButtonClick={() => handleCloseDeleteClientModal(setOpenDeleteClientModal, setClientToDelete)}
        onRightButtonClick={() => handleDeleteClient(setOpenDeleteClientModal, dispatch, clientToDelete, setClientToDelete)}
      />
      <WarningModal
        open={openDeleteAPIModal}
        onClose={() => setOpenDeleteAPIModal(false)}
        title='Disable API'
        text1={`Are you sure that you want to disable API for ${client.name}?`}
        text2={`The current API keys will NOT be removed but disabled from further use.`}
        leftButtonText='NO, CANCEL'
        rightButtonText='YES, DISABLE'
        onLeftButtonClick={() => handleCloseDeleteAPIModal(setOpenDeleteAPIModal, setClientToDelete)}
        onRightButtonClick={() => handleDeleteAPI(setOpenDeleteAPIModal, dispatch, clientToDelete, setClientToDelete)}
      />
      {toastActive &&
        <Toast
          text={toastMessage}
          timeToClose={5000}
        />
      }
      {errorToastActive &&
        <ErrorToast
          text={toastMessage}
          timeToClose={10000}
        />
      }
      {loadingToastActive &&
        <LoadingToast
          text={toastMessage}
        />
      }
    </div>
  );
};

const handleOpenDeleteClientModal = (setOpenDeleteClientModal, setClientToDelete, clientId) => {
  setOpenDeleteClientModal(true);
  setClientToDelete(clientId);
};

const handleCloseDeleteClientModal = (setOpenDeleteClientModal, setClientToDelete) => {
  setOpenDeleteClientModal(false);
  setClientToDelete(null);
};

const handleDeleteClient = (setOpenDeleteClientModal, dispatch, clientToDelete, setClientToDelete) => {
  setOpenDeleteClientModal(false);
  dispatch(actions.deleteClient.request(clientToDelete, 'originClientOverview'));
  setClientToDelete(null);
};

const handleOpenDeleteAPIModal = (setOpenDeleteAPIModal, setClientToDelete, clientId) => {
  setOpenDeleteAPIModal(true);
  setClientToDelete(clientId);
};

const handleCloseDeleteAPIModal = (setOpenDeleteAPIModal, setClientToDelete) => {
  setOpenDeleteAPIModal(false);
  setClientToDelete(null);
};

const handleDeleteAPI = (setOpenDeleteAPIModal, dispatch, clientToDelete, setClientToDelete) => {
  setOpenDeleteAPIModal(false);
  dispatch(actions.disableAPIForClient.request(clientToDelete));
  setClientToDelete(null);
};

export default ClientOverviewPage;
