import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams  } from 'react-router-dom';
import * as actions from '../actions';
import Const from '../store/Const';
import CourseLeftMenu from '../components/CourseLeftMenu.js';
import CourseWelcomeMailForm from '../components/forms/CourseWelcomeMailForm';
import './CourseWelcomeMailPage.css';

/**
* CourseWelcomeMailPage - path="/course/:id/welcomemail"
*/

const CourseWelcomeMailPage = () => {
  const dispatch = useDispatch();
  const { courseId } = useParams();
  useEffect(() => {
    dispatch(actions.getCourse.request(courseId));
    dispatch(actions.getModuleContent.request(courseId, Const.moduleType.Welcome));
  }, []);

  return (
    <div className="courseWelcomeMailComponentsWrapper">
      <CourseLeftMenu/>
      <div className="courseWelcomeMailFormWrapper">
        <CourseWelcomeMailForm/>
      </div>
    </div>
  );
};

export default CourseWelcomeMailPage;
