import React from 'react';
import './ButtonSecondaryOutlinedRed.css';

/**
 * ButtonSecondaryOutlinedRed
 */
const ButtonSecondaryOutlinedRed = ({
  type,
  label,
  disabled,
  onClick,
  iconSource,
  iconSourceOnHover,
  iconSourceDisabled,
}) => {
  return (
    <button
      type={type}
      className={
        disabled
          ? 'buttonSecondaryOutlinedRedDisabled'
          : 'buttonSecondaryOutlinedRed'
      }
      disabled={disabled}
      onClick={onClick}
    >
      {disabled ? (
        <img
          className="buttonSecondaryOutlinedRedIcon"
          src={iconSourceDisabled}
        />
      ) : (
        <>
          <img className="buttonSecondaryOutlinedRedIcon" src={iconSource} />
          <img
            className="buttonSecondaryOutlinedRedIconOnHover"
            src={iconSourceOnHover}
          />
        </>
      )}
      {label}
    </button>
  );
};

export default ButtonSecondaryOutlinedRed;
