import './UsersData.css';
import { getYearMonthDayFromDate } from '../utils/dateUtils';
import { Link, useParams } from 'react-router-dom';
import {
  useOutsideClick,
  renderStyleguideArrow,
} from '../utils/componentUtils';
import { useDispatch, useSelector } from 'react-redux';
import { useUsersDataStore } from './users-data.store';
import * as actions from '../actions';
import email_envelope_icon from '../images/svg/email_envelope_icon.svg';
import LazyLoadingTableFooter from '../components/LazyLoadingTableFooter';
import NoResultsComponent from '../components/NoResultsComponent';
import pencil_icon_grey from '../images/svg/pencil_icon_grey.svg';
import React, { useState, useEffect, useRef } from 'react';
import red_x_mark from '../images/svg/red_x_mark.svg';
import ResendEmailModal from '../components/ResendEmailModal';
import three_dots from '../images/svg/three_dots.svg';
import three_dots_black_bg from '../images/svg/three_dots_black_bg.svg';
import Tooltip from '../components/Tooltip';
import user from '../images/svg/user.svg';
import WarningModal from '../components/WarningModal';
import white_x_mark from '../images/svg/white_x_mark.svg';
import add_user_icon from '../images/styleguideComponentsImages/add_user_icon.svg';
import checkbox_checked from '../images/styleguideComponentsImages/checkbox_checked.svg';
import checkbox_unchecked from '../images/styleguideComponentsImages/checkbox_unchecked.svg';
import checkbox_checked_disabled from '../images/styleguideComponentsImages/checkbox_checked_disabled.svg';
import checkbox_partial_selection from '../images/styleguideComponentsImages/checkbox_partial_selection.svg';
import TableSelectDropdown from '../components/styleguideComponents/TableSelectDropdown';
import SearchField from '../components/styleguideComponents/SearchField';
import WarningPopup from '../components/styleguideComponents/WarningPopup';

/**
 * UsersDataTable
 */
const UsersDataTable = ({
  onDeleteSelectedUsers,
  openDeleteUserModalSelected,
  openResendEmailModalSelected,
  selectedNonFinishedCount,
  selectedItemsCount,
  setOpenDeleteUserModalSelected,
  setOpenResendEmailModalSelected,
}) => {
  const { courseId } = useParams();
  const dispatch = useDispatch();

  const searchString = useSelector(
    (state) => state.componentStates.searchValues.courseUsersDataTable
  );
  const programName = useSelector(
    (state) => state.programs.currentProgram.name
  );

  const {
    clearStore,
    companies,
    currentPageNumber,
    departments,
    deselectAllItems,
    excludedUsersIds,
    fetchFilters,
    fetchUsers,
    filteredCount,
    filters,
    itemsPerPage,
    loading,
    resetUserProgressLocally,
    selectedUsersIds,
    selectionInverted,
    setCourseId,
    setExcludedUsersIds,
    setFilters,
    setSelectedUsersIds,
    setSortAttribute,
    setUserField,
    sortAttribute,
    sortOrder,
    toggleInvertedSelection,
    totalCount,
    users,
  } = useUsersDataStore();

  useEffect(() => {
    setCourseId(courseId);
    fetchFilters();
    fetchUsers();
    return clearStore;
  }, []);

  const [openDropdownMenuIndex, setOpenDropdownMenuIndex] = useState(null);
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [openResetUserModal, setOpenResetUserModal] = useState(false);
  const [userToReset, setUserToReset] = useState({});
  const dropdownRef = useRef();

  useOutsideClick(dropdownRef, () => {
    setTimeout(() => setOpenDropdownMenuIndex(null), 100);
  });

  const handleRemoveOption = (name, value) => {
    setFilters({ [name]: filters[name].filter((i) => i !== value) });
  };

  const handleChange = (name, value) => {
    setFilters({ [name]: [...filters[name], value] });
  };

  const handleClear = (name) => {
    setFilters({ [name]: [] });
  };

  useEffect(() => {
    setFilters({ searchString: searchString ?? '' });
  }, [searchString]);

  const areThereFiltersSelected =
    filters.company.length > 0 ||
    filters.department.length > 0 ||
    filters.status.length > 0;

  const shouldShowLazyRow =
    totalCount === -1 ||
    (users.length !== filteredCount && users.length !== totalCount);

  const onSelectChange = (item) => {
    if (selectionInverted) {
      if (excludedUsersIds.includes(item._id)) {
        setExcludedUsersIds(
          excludedUsersIds.filter((index) => index !== item._id)
        );
      } else {
        const newExcluded = [...excludedUsersIds, item._id];
        setExcludedUsersIds(newExcluded);
        if (newExcluded.length === users.length) {
          deselectAllItems();
        }
      }
    } else {
      if (selectedUsersIds.includes(item._id)) {
        setSelectedUsersIds(
          selectedUsersIds.filter((index) => index !== item._id)
        );
      } else {
        const newSelected = [...selectedUsersIds, item._id];
        setSelectedUsersIds(newSelected);
        if (newSelected.length === totalCount) {
          toggleInvertedSelection();
        }
      }
    }
  };

  useEffect(() => {
    setSelectedUsersIds(selectedUsersIds);
  }, [selectedUsersIds]);

  const [intersectionObserverEnabled, setIntersectionObserverEnabled] =
    useState(!loading);

  useEffect(() => {
    setIntersectionObserverEnabled(!loading);
  }, [loading]);

  const isRowSelected = (row) =>
    selectionInverted
      ? !excludedUsersIds.includes(row._id)
      : selectedUsersIds.includes(row._id);
  const someItemsSelected =
    selectedUsersIds.length > 0 || excludedUsersIds.length > 0;

  const headerCheckboxIcon = () => {
    if (someItemsSelected && !(areThereFiltersSelected || searchString)) {
      return checkbox_partial_selection;
    } else if (selectionInverted) {
      return checkbox_checked;
    } else if (areThereFiltersSelected || searchString) {
      return checkbox_checked_disabled;
    } else {
      return checkbox_unchecked;
    }
  };

  const handleDeleteUser = async () => {
    onDeleteSelectedUsers(userToDelete);
    setOpenDeleteUserModal(false);
    setUserToDelete(null);
  };
  const handleDeleteSelectedUsers = async () => {
    onDeleteSelectedUsers();
    setOpenDeleteUserModalSelected(false);
  };

  const handleResendEmailSelected = () => {
    dispatch(
      actions.resendInviteSelectedUsers.request({
        courseId,
        selectedUsersIds,
        excludedUsersIds,
        selectionInverted,
      })
    );
    dispatch(actions.setToastOrigin.request(`/course/${courseId}/users`));

    const modifiedUsers = selectionInverted
      ? users.filter(({ _id }) => !excludedUsersIds.includes(_id))
      : selectedUsersIds.map((id) => getUserFromUserId(id, users));

    modifiedUsers
      .filter(({ remoteStatus }) =>
        ['Not started', 'In progress'].includes(remoteStatus)
      )
      .forEach(({ _id }) => {
        setUserField(
          _id,
          'lastInvitation',
          getYearMonthDayFromDate(new Date())
        );
      });

    setOpenResendEmailModalSelected(false);
    deselectAllItems();
  };

  return (
    <div className="usersData">
      <div className="userDataTopMenuSection">
        <div className="courseUserDataTitleWrapper">
          <div>
            <div className="courseUserDataTitle">Users</div>
            <div className="courseUserDataTitleCounter">
              {(areThereFiltersSelected || searchString) && filteredCount
                ? `${filteredCount}/${totalCount} users`
                : `${totalCount} users`}
            </div>
          </div>
          <div className="courseUserDataTitleButtonsWrapper">
            <Link
              to={`/course/${courseId}/adduser`}
              exact="true"
              className="courseUserDataAddUserButton"
            >
              <img
                src={add_user_icon}
                className="courseUserDataAddUserButtonIcon"
              />
            </Link>
            <SearchField
              name="courseUsersDataTable"
              defaultText="Search user"
            />
          </div>
        </div>
        <div className="courseUserTableFiltersWrapper">
          <div className="courseUserTableFilter">
            <TableSelectDropdown
              name="status"
              defaultText="Status"
              optionTexts={['Not started', 'Active']}
              selectedItems={filters.status}
              handleRemoveOption={handleRemoveOption}
              handleChange={handleChange}
              handleClear={handleClear}
              source="courseUsersDataTable"
            />
          </div>
          <div className="courseUserTableFilter">
            <TableSelectDropdown
              name="company"
              defaultText="Company"
              optionTexts={companies}
              selectedItems={filters.company}
              handleRemoveOption={handleRemoveOption}
              handleChange={handleChange}
              handleClear={handleClear}
              source="courseUsersDataTable"
            />
          </div>
          <div className="courseUserTableFilter">
            <TableSelectDropdown
              name="department"
              defaultText="Department"
              optionTexts={departments}
              selectedItems={filters.department}
              handleRemoveOption={handleRemoveOption}
              handleChange={handleChange}
              handleClear={handleClear}
              source="courseUsersDataTable"
            />
          </div>
        </div>
      </div>
      <table className="courseUserDataTable">
        <tbody>
          <tr>
            <th className="userDataTableHeaderRow">
              <div className="courseUserTableNameAndSelectHeader">
                <div className="courseUserTableSelectIconWrapper">
                  <img
                    className="courseUserTableSelectAllIcon"
                    src={headerCheckboxIcon()}
                    alt="checkbox icon"
                    onClick={
                      areThereFiltersSelected || searchString
                        ? null
                        : () => toggleInvertedSelection()
                    }
                  />
                  {(areThereFiltersSelected || searchString) && (
                    <div className="courseUserTableSelectAllTooltip">
                      Select all disabled when filtering or searching
                    </div>
                  )}
                </div>
                <div
                  className="userDataTitleAndIconContainer"
                  onClick={() => setSortAttribute('username')}
                >
                  <div className="userDataTableTitle">Name</div>
                  {renderStyleguideArrow(
                    'username',
                    sortOrder === 1 ? 'asc' : 'desc',
                    sortAttribute
                  )}
                </div>
              </div>
            </th>
            <th className="userDataTableHeaderRow">
              <div
                className="userDataTitleAndIconContainer"
                onClick={() => setSortAttribute('email')}
              >
                <div className="userDataTableTitle">E-mail</div>
                {renderStyleguideArrow(
                  'email',
                  sortOrder === 1 ? 'asc' : 'desc',
                  sortAttribute
                )}
              </div>
            </th>
            <th className="userDataTableHeaderRow">
              <div
                className="userDataTitleAndIconContainer"
                onClick={() => setSortAttribute('company')}
              >
                <div className="userDataTableTitle">Company</div>
                {renderStyleguideArrow(
                  'company',
                  sortOrder === 1 ? 'asc' : 'desc',
                  sortAttribute
                )}
              </div>
            </th>
            <th className="userDataTableHeaderRow">
              <div
                className="userDataTitleAndIconContainer"
                onClick={() => setSortAttribute('department')}
              >
                <div className="userDataTableTitle">Department</div>
                {renderStyleguideArrow(
                  'department',
                  sortOrder === 1 ? 'asc' : 'desc',
                  sortAttribute
                )}
              </div>
            </th>
            <th className="userDataTableHeaderRow">
              <div
                className="userDataTitleAndIconContainer"
                onClick={() => setSortAttribute('status')}
              >
                <div className="userDataTableTitle">Status</div>
                {renderStyleguideArrow(
                  'status',
                  sortOrder === 1 ? 'asc' : 'desc',
                  sortAttribute
                )}
              </div>
            </th>
            <th className="userDataTableHeaderRow">
              <div
                className="userDataTitleAndIconContainer"
                onClick={() => setSortAttribute('lastInvitationResentAt')}
              >
                <div className="userDataTableTitle">Last invitation</div>
                {renderStyleguideArrow(
                  'lastInvitationResentAt',
                  sortOrder === 1 ? 'asc' : 'desc',
                  sortAttribute
                )}
              </div>
            </th>
            <th className="userDataTableHeaderRow">
              <div
                className="userDataTitleAndIconContainer"
                onClick={() => setSortAttribute('firstInteractionAt')}
              >
                <div className="userDataTableTitle">Start date</div>
                {renderStyleguideArrow(
                  'firstInteractionAt',
                  sortOrder === 1 ? 'asc' : 'desc',
                  sortAttribute
                )}
              </div>
            </th>
            <th className="userDataTableHeaderRow">
              <div
                className="userDataTitleAndIconContainer"
                onClick={() => setSortAttribute('lastInteractionAt')}
              >
                <div className="userDataTableTitle">Last login</div>
                {renderStyleguideArrow(
                  'lastInteractionAt',
                  sortOrder === 1 ? 'asc' : 'desc',
                  sortAttribute
                )}
              </div>
            </th>
          </tr>
          {users.map((row, index) => (
            <tr
              className={
                isRowSelected(row)
                  ? 'userDataTableRow courseUserTableRowSelected'
                  : 'userDataTableRow'
              }
              key={index}
            >
              <td className="courseUserNameValue">
                <label className="userDataTableText courseUserTableCheckboxContainer">
                  <input
                    type="checkbox"
                    checked={isRowSelected(row)}
                    onChange={() => onSelectChange(row)}
                  />
                  {row.username}
                  <span className="userDataCustomCheckbox"></span>
                </label>
              </td>
              <td className="userDataTableText courseUserEmailValue">
                {row.email}
              </td>
              <td className="userDataTableText courseUserCompanyValue">
                {row.company}
              </td>
              <td className="userDataTableText courseUserDepartmentValue">
                {row.department}
              </td>
              <td className="userDataTableText courseUserStatusValue">
                {row.status}
              </td>
              <td className="userDataTableText courseUserLastInvitationValue">
                {row.lastInvitation}
              </td>
              <td className="userDataTableText courseUserStartDateValue">
                {row.startDate}
              </td>
              <td className="userDataTableText courseUserLastInteractionValue">
                {row.lastLogin}
              </td>
              <td
                className="courseUserTableMenuCell"
                onClick={() =>
                  setOpenDropdownMenuIndex(
                    openDropdownMenuIndex === index ? null : index
                  )
                }
              >
                <div className="courseUserTableMenuIconsContainer">
                  <img
                    className="courseUserTableMenuIcon courseUserTableMenuIconGrey"
                    src={three_dots}
                    alt="three dots"
                  />
                  <img
                    className="courseUserTableMenuIconBlackBG"
                    src={three_dots_black_bg}
                    alt="three dots"
                  />
                  {openDropdownMenuIndex === null && (
                    <Tooltip text="User menu" width="65" />
                  )}
                  {index === openDropdownMenuIndex &&
                    renderDropdownMenu(
                      dropdownRef,
                      courseId,
                      row._id,
                      setOpenDeleteUserModal,
                      setUserToDelete,
                      dispatch,
                      setOpenResetUserModal,
                      setUserToReset,
                      row,
                      setUserField
                    )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
        {shouldShowLazyRow && (
          <LazyLoadingTableFooter
            callback={fetchUsers}
            colSpan={9}
            observerEnabled={intersectionObserverEnabled}
          />
        )}
      </table>
      {users.length === 0 && !loading && (
        <div className="courseUserTableNoResultsWrapper">
          <NoResultsComponent
            text1="No users here…"
            text2="invite your first one below!"
            buttonText="ADD USER"
            path={`/course/${courseId}/adduser`}
          />
        </div>
      )}
      <WarningPopup
        open={openDeleteUserModal}
        onClose={() => setOpenDeleteUserModal(false)}
        title="Remove user from program"
        text1={`Are you sure you want to remove ${getUserNameFromUserId(
          userToDelete,
          users
        )} from ${programName}?`}
        leftButtonText="No, cancel"
        rightButtonText="Yes, remove"
        onLeftButtonClick={() =>
          handleCloseDeleteUserModal(setOpenDeleteUserModal, setUserToDelete)
        }
        onRightButtonClick={handleDeleteUser}
      />
      <ResendEmailModal
        open={openResendEmailModalSelected}
        onClose={() => setOpenResendEmailModalSelected(false)}
        title={`Resend invitation to ${selectedNonFinishedCount} ${
          selectedNonFinishedCount > 1 ? 'users' : 'user'
        }?`}
        text1="You are about to resend the invitation email to all selected users"
        text2={`${selectedNonFinishedCount} ${
          selectedNonFinishedCount > 1 ? 'users' : 'user'
        } will receive the invitation email.`}
        leftButtonText="NO, CANCEL"
        rightButtonText="YES, RESEND INVITATION"
        onLeftButtonClick={() => setOpenResendEmailModalSelected(false)}
        onRightButtonClick={handleResendEmailSelected}
      />
      <WarningPopup
        open={openDeleteUserModalSelected}
        onClose={() => setOpenDeleteUserModalSelected(false)}
        title={`Remove ${
          selectedItemsCount > 1 ? 'users' : 'user'
        } from program`}
        text1={`Are you sure you want to remove ${selectedItemsCount} ${
          selectedItemsCount > 1 ? 'users' : 'user'
        } from ${programName}?`}
        leftButtonText="No, cancel"
        rightButtonText="Yes, remove"
        onLeftButtonClick={() => setOpenDeleteUserModalSelected(false)}
        onRightButtonClick={handleDeleteSelectedUsers}
      />
      <WarningModal
        open={openResetUserModal}
        onClose={() => setOpenResetUserModal(false)}
        title="Reset user progress"
        text1={`Are you sure you want to reset "${userToReset.username}'s" progress from ${programName}?`}
        text2={`"${userToReset.username}'s" progress will be reset and CAN NOT be recovered`}
        leftButtonText="NO, CANCEL"
        rightButtonText="YES, RESET"
        onLeftButtonClick={() =>
          handleCloseResetUserModal(setOpenResetUserModal, setUserToReset)
        }
        onRightButtonClick={() =>
          handleResetUserProgress(
            setOpenResetUserModal,
            dispatch,
            userToReset,
            setUserToReset,
            courseId,
            currentPageNumber,
            itemsPerPage,
            searchString,
            sortAttribute,
            sortOrder,
            resetUserProgressLocally
          )
        }
      />
    </div>
  );
};

const renderDropdownMenu = (
  dropdownRef,
  courseId,
  userId,
  setOpenDeleteUserModal,
  setUserToDelete,
  dispatch,
  setOpenResetUserModal,
  setUserToReset,
  userDataObject,
  setUserField
) => {
  return (
    <div className="courseUserTableDropdownMenu" ref={dropdownRef}>
      <Link
        className="courseUserTableDropdownMenuItem"
        to={`/course/${courseId}/user/${userId}`}
      >
        <div className="courseUserDropdownItemContainer">
          <div className="courseUserTableDropdownMenuText">Edit user</div>
          <img
            className="courseUserTableDropdownMenuIcon"
            src={pencil_icon_grey}
            alt="pencil icon"
          />
        </div>
      </Link>
      {userDataObject.status === 'Active' && (
        <div
          className="courseUserTableDropdownMenuItem"
          onClick={() =>
            handleOpenResetUserModal(
              setOpenResetUserModal,
              setUserToReset,
              userDataObject
            )
          }
        >
          <div className="courseUserDropdownItemContainer">
            <div className="courseUserTableDropdownMenuText">
              Reset progress
            </div>
            <img
              className="courseUserTableDropdownMenuIcon"
              src={user}
              alt="user icon"
            />
          </div>
        </div>
      )}
      {userDataObject.status === 'Not started' && (
        <div className="courseUserTableDropdownMenuItem">
          <div
            className="courseUserDropdownItemContainer"
            onClick={() => {
              dispatch(
                actions.resendInvite.request({
                  courseId,
                  excludedUsersIds: [],
                  selectedUsersIds: [userId],
                  selectionInverted: false,
                })
              );
              dispatch(
                actions.setToastOrigin.request(`/course/${courseId}/users`)
              );
              setUserField(
                userId,
                'lastInvitation',
                getYearMonthDayFromDate(new Date())
              );
            }}
          >
            <div className="courseUserTableDropdownMenuText">Resend email</div>
            <img
              className="courseUserTableDropdownMenuIcon"
              src={email_envelope_icon}
              alt="envelope icon"
            />
          </div>
        </div>
      )}
      <div className="courseUserTableDropdownMenuItem">
        <div
          className="courseUserDropdownDeleteContainer"
          onClick={() =>
            handleOpenDeleteUserModal(
              setOpenDeleteUserModal,
              setUserToDelete,
              userId
            )
          }
        >
          <div className="courseUserTableDropdownMenuText courseUserDropdownDeleteText">
            Remove user
          </div>
          <img
            className="courseUserTableDropdownMenuX"
            src={red_x_mark}
            alt="crossmark"
          />
          <img
            className="courseUserTableDropdownMenuX courseUserTableXWhite"
            src={white_x_mark}
            alt="crossmark"
          />
        </div>
      </div>
    </div>
  );
};

const handleOpenDeleteUserModal = (
  setOpenDeleteUserModal,
  setUserToDelete,
  userId
) => {
  setOpenDeleteUserModal(true);
  setUserToDelete(userId);
};

const handleCloseDeleteUserModal = (
  setOpenDeleteUserModal,
  setUserToDelete
) => {
  setOpenDeleteUserModal(false);
  setUserToDelete(null);
};

const handleOpenResetUserModal = (
  setOpenResetUserModal,
  setUserToReset,
  userDataObject
) => {
  setOpenResetUserModal(true);
  setUserToReset(userDataObject);
};

const handleCloseResetUserModal = (setOpenResetUserModal, setUserToReset) => {
  setOpenResetUserModal(false);
  setUserToReset({});
};

const handleResetUserProgress = (
  setOpenResetUserModal,
  dispatch,
  userToReset,
  setUserToReset,
  courseId,
  currentPageNumber,
  itemsPerPage,
  searchString,
  sortAttribute,
  sortOrder,
  resetUserProgressLocally
) => {
  const source = 'usersDataTable';
  dispatch(
    actions.resetUserProgressUserTable.request(
      { ...userToReset, id: userToReset._id },
      courseId,
      currentPageNumber - 1,
      itemsPerPage,
      searchString,
      sortAttribute,
      sortOrder,
      source
    )
  );
  resetUserProgressLocally(userToReset._id);
  setOpenResetUserModal(false);
  setUserToReset({});
};

const getUserFromUserId = (userId, users) => {
  return users.find((user) => {
    return user._id === userId;
  });
};

const getUserNameFromUserId = (userId, users) => {
  const user = getUserFromUserId(userId, users);
  if (user) {
    return user.username;
  }
  return '';
};

export default UsersDataTable;
