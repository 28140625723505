import { useDashboardFiltersStore } from './dashboard-filters.store';
import { useInfiniteQuery } from 'react-query';
import { useParams } from 'react-router';
import API from '../api/api';

const ITEMS_PER_PAGE = 25;
export const DASHBOARD_TABLE_QUERY_KEY = 'dashboardTableData';

export const useDashboardTableData = () => {
  const { courseId } = useParams();
  const dasboardFiltersStore = useDashboardFiltersStore();

  const query = useInfiniteQuery(
    [
      DASHBOARD_TABLE_QUERY_KEY,
      courseId,
      dasboardFiltersStore.selectedCompanies,
      dasboardFiltersStore.selectedDepartments,
      dasboardFiltersStore.selectedStatuses,
      dasboardFiltersStore.sortAttribute,
      dasboardFiltersStore.sortOrder,
      dasboardFiltersStore.searchString,
    ],
    ({ pageParam = 0 }) =>
      fetchProgressOverview({
        ...dasboardFiltersStore,
        pageParam,
        programId: courseId,
      }),
    { getNextPageParam: (lastPage) => lastPage.pageParam + 1 }
  );

  // "Computed" values
  const { data } = query;
  const firstPage = data?.pages[0] ?? {};
  const {
    clientStudentsFilteredCount: filteredCount = 0,
    clientUsersTotalCount: totalCount = 0,
    currentClientPrograms: programs = [],
  } = firstPage;
  const program = programs[0] ?? {};
  const allPages = data?.pages ?? [];
  const students = allPages.reduce(
    (acc, page) => [...acc, ...page.currentClientStudents],
    []
  );

  return {
    ...query,
    ...dasboardFiltersStore,
    firstPage,
    filteredCount,
    students,
    totalCount,
    program,
  };
};

async function fetchProgressOverview({
  programId,
  pageParam,
  searchString,
  sortAttribute,
  sortOrder,
  selectedCompanies,
  selectedDepartments,
  selectedStatuses,
}) {
  const res = await API.getClientProgressOverview({
    programId,
    currentPageNumber: pageParam,
    itemsPerPage: ITEMS_PER_PAGE,
    searchString,
    sortAttribute,
    sortOrder,
    companies: selectedCompanies,
    departments: selectedDepartments,
    statuses: selectedStatuses,
  });
  return { ...res.data, pageParam };
}
