import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '../../actions';
import autosave_checkmark_icon from '../../images/svg/autosave_checkmark_icon.svg';
import autosave_cloud_icon from '../../images/svg/autosave_cloud_icon.svg';
import './AutosaveSavedComponent.css';

/**
* AutosaveSavedComponent: Autosave icon used in the course core to show progress on
* the autosave.
*/
const AutosaveSavedComponent = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => dispatch(actions.closeAutosaveSavedIcon.request()), 1000);
  }, []);

  return (
    <div className="autoSaveSavedWrapper">
      <div className="autoSaveSavedIconsWrapper">
        <img className="autoSaveSavedCheckmark" src={autosave_checkmark_icon}/>
        <img className="autoSaveSavedCloud" src={autosave_cloud_icon}/>
      </div>
      <div className="autoSaveSavedText">Saved</div>
    </div>
  );
};

export default AutosaveSavedComponent;
