import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../actions';
import SearchComponent from './SearchComponent';
import DropdownComponent from './DropdownComponent';
import AdminTable from './tables/AdminTable';
import './AdminList.css';

/**
* AdminList is the wrapper for the table with admins,
* the filter by client, search for admin component and the pagination
*/
const AdminList = () => {
  const dispatch = useDispatch();
  const isSuperAdmin = useSelector(state => state.user.isSuperAdmin);
  const clientIdsAndNames = useSelector(state => state.clients.clientNames);
  if (isSuperAdmin) {
    useEffect(() => dispatch(actions.getClientNames.request()), []);
  }
  const clientIds = clientIdsAndNames.map(client => {
    return client._id;
  });
  const clientNames = clientIdsAndNames.map(client => {
    return client.name;
  });
  return (
    <div className="adminListContainer">
      <div className="adminListTitle">ADMIN LIST</div>
      <div className="adminListFilterAndSearchContainer">
        {isSuperAdmin &&
          <DropdownComponent
            name='adminListClientFilter'
            defaultText='Filter by client'
            optionTexts={clientNames}
            optionValues={clientIds}
          />
        }
        <SearchComponent
          name='adminNameOrEmail'
          defaultText='Search admin'
        />
      </div>
      <AdminTable/>
    </div>
  );
};

export default AdminList;
