import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams  } from 'react-router-dom';
import * as actions from '../../actions';
import './CourseSignupPageForm.css';

/**
* CourseSignupPageForm
*/

const CourseSignupPageForm = () => {
  const dispatch = useDispatch();
  const currentClient = useSelector(state => state.clients.currentClient);
  const currentProgram = useSelector(state => state.programs.currentProgram);
  const [uniqueUsers, setUniqueUsers] = useState(0);
  const [courseDescription, setCourseDescription] = useState('');
  const { courseId } = useParams();

  useEffect(() => {
    setCourseDescription(currentProgram.signupPageDescription || '');
    setUniqueUsers(currentProgram.signupPageMaxRegistrants || 0);
  }, [currentProgram]);

  useEffect(() => {
    if (uniqueUsers < 0 || !uniqueUsers) {
      setUniqueUsers(0);
    }
    if (currentClient.maxActiveUsers && uniqueUsers > currentClient.maxActiveUsers) {
      setUniqueUsers(currentClient.maxActiveUsers);
    }
  }, [uniqueUsers]);

  const minTextAreaHeight = 16;
  const textareaRef = useRef(null);

  useLayoutEffect(() => {
    textareaRef.current.style.height = "16px";
    textareaRef.current.style.height = `${Math.max(textareaRef.current.scrollHeight, minTextAreaHeight)}px`;
  }, [courseDescription]);

  const disableSubmitButton = () => {
    return (
      currentProgram.signupPageDescription === courseDescription &&
      currentProgram.signupPageMaxRegistrants === uniqueUsers
    );
  };

  const handleSubmit = () => {
    const updatedProgram = {
      _id: currentProgram._id,
      clientId: currentProgram.clientId,
      signupPageDescription: courseDescription,
      signupPageMaxRegistrants: uniqueUsers || 0
    };
    dispatch(actions.editCourseSettings.request(updatedProgram));
    return false;
  };

  return (
    <>
      <div className="signupPageFormTitle">SIGN-UP PAGE</div>
      <div className="signupPageFormSubTitle">Maximum number of sign-ups</div>
      <div className="signupPageFormHelperText">Enter how many unique users that should be able to join this program</div>
      <div className="signupPageUsersFieldWrapper">
        <input
          className="signupPageUsersField"
          type="number"
          min={0}
          max={currentClient.maxActiveUsers || 20}
          value={uniqueUsers || ''}
          placeholder="e.g. 10"
          onChange={event => setUniqueUsers(event.target.value)}
          required
        />
        <div className="signupPageUsersFieldText">out of {currentClient.maxActiveUsers || 20}</div>
      </div>
      <div className="signupPageFormSubTitle">Program description</div>
      <div className="signupPageFormHelperText">Give a short description of the program, the user will see this when they sign up for the program.</div>
      <div className="signupPageDescriptionFieldWrapper">
        <textarea
          className="signupPageTextarea"
          placeholder='e.g. "In this program, you will learn about our company values."'
          maxLength="250"
          value={courseDescription}
          onChange={(event) => setCourseDescription(event.target.value)}
          ref={textareaRef}
        />
        <div className="signupPageCounterText">{`${courseDescription.length}/250`}</div>
      </div>
      <div className="signupPageFormSubTitle">Program link</div>
      <div className="signupPageFormHelperText">Share this link to the users you want to invite</div>
      <div className="signupPageLink">{`www.moblrn.com/signupPage/${courseId}`}</div>
      <button
        className="signupPageSubmitButton"
        type="button"
        disabled={disableSubmitButton()}
        onClick={() => handleSubmit()}
      >
        SAVE CHANGES
      </button>
    </>
  );
};

export default CourseSignupPageForm;
