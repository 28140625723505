import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useOutsideClick, renderArrow } from '../utils/componentUtils';
import { getDayMonthYearFromDate, getTimeFromDate } from '../utils/dateUtils';
import * as actions from '../actions';
import history from '../store/history';
import SmallSpinner from '../components/SmallSpinner';
import Tooltip from '../components/Tooltip';
import WarningModal from '../components/WarningModal';
import SearchComponent from '../components/SearchComponent';
import champion_cup from '../images/svg/champion_cup.svg';
import three_dots from '../images/svg/three_dots.svg';
import three_dots_black_bg from '../images/svg/three_dots_black_bg.svg';
import pencil_icon_grey from '../images/svg/pencil_icon_grey.svg';
import red_x_mark from '../images/svg/red_x_mark.svg';
import white_x_mark from '../images/svg/white_x_mark.svg';
import logout_arrow from '../images/svg/logout_arrow.svg';
import user from '../images/svg/user.svg';
import './StudentTable.css';
import { useDashboardTableData } from './use-dashboard-table-data';
import { useDashboardFiltersStore } from './dashboard-filters.store';
import LazyLoadingTableFooter from '../components/LazyLoadingTableFooter';
import LoadingText from '../components/LoadingText';
import ErrorToast from '../components/ErrorToast';
import LoadingToast from '../components/LoadingToast';
import Toast from '../components/Toast';
import API from '../api/api';

/**
* StudentTable
*/

const StudentTable = () => {
  const loadingResetUserProgress = useSelector(
    (state) => state.componentStates.loadingResetUserProgress
  );

  const {
    fetchNextPage,
    filteredCount,
    isLoading,
    isFetchingNextPage,
    program,
    sortAttribute,
    sortOrder,
    students,
    totalCount,
    status,
    refetch: refetchStudents,
  } = useDashboardTableData();
  const {
    clearFilters,
    selectedCompanies,
    selectedDepartments,
    selectedStatuses,
    setAttrSortOrder,
    setDashboardFilters,
  } = useDashboardFiltersStore();

  const [waitingForUserReset, setWaitingForUserReset] = useState(false);
  useEffect(()=> {
    if(!waitingForUserReset && loadingResetUserProgress) {
      setWaitingForUserReset(true)
    } else if(waitingForUserReset && !loadingResetUserProgress) {
      refetchStudents()
    }
  }, [loadingResetUserProgress])

  const dispatch = useDispatch();
  const { courseId } = useParams();
  const user = useSelector(state => state.user);
  const isSuperAdmin = user.isSuperAdmin;
  const userPermissions = user.permissions[0] ?? {};
  const [openDropdownMenuIndex, setOpenDropdownMenuIndex] = useState(null);
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [openResetUserModal, setOpenResetUserModal] = useState(false);
  const [userToReset, setUserToReset] = useState({});
  const dropdownRef = useRef();
  useOutsideClick(dropdownRef, () => {
    setTimeout(() => setOpenDropdownMenuIndex(null), 100);
  });

  const downloadUserAnswerXls = (courseId) => {
    dispatch(actions.setToastOrigin.request(`/course/${courseId}/dashboard`));
    dispatch(actions.downloadUserAnswerXls.request(courseId));
  };

  const numberOfFilters =
    selectedCompanies.length +
    selectedDepartments.length +
    selectedStatuses.length;

  const [intersectionObserverEnabled, setIntersectionObserverEnabled] =
    useState(!isLoading);

  useEffect(() => {
    setIntersectionObserverEnabled(!(isLoading || isFetchingNextPage));
  }, [isLoading, isFetchingNextPage]);
  const shouldShowLazyRow =
    status === 'idle' ||
    (students.length !== filteredCount && students.length !== totalCount);
 
  const [removingState, setRemovingState] = useState({
    error: false,
    loading: false,
    succeed: false,
  });
  const deleteUsers = async (userId) => {
    try {
      console.log({userId})
      setOpenDeleteUserModal(false);
      setUserToDelete(null);
      setRemovingState({
        error: false,
        loading: true,
        succeed: false,
      });
      await API.removeUserFromCourse({
        courseId,
        excludedUsersIds: [],
        selectionInverted: false,
        selectedUsersIds: [userId],
      });
      refetchStudents();
      setRemovingState({
        loading: false,
        succeed: true,
      });
    } catch (error) {
      setRemovingState({
        loading: false,
        error: true,
      });
    }
  };

  return (
    <>
      <div className="studentTableTitle">User list</div>
      <div className="studentTableSearchDownloadWrapper">
        <SearchComponent
          name='dashboardCourseUserNameOrEmail'
          defaultText='Filter users'
          disabled={totalCount === 0}
          onChangeDebounced={(value) => setDashboardFilters({ searchString: value })}
        />
        <div className="studentTableDownloadButtonWrapper" onClick={() => downloadUserAnswerXls(courseId)}>
          <div className="studentTableDownloadButtonText">Download statistics</div>
          <img className="studentTableDownloadButtonIcon" src={logout_arrow} alt="arrow icon"/>
        </div>
      </div>
      <table className="studentTable">
        <tbody>
          <tr>
            <th>
              <div className="studentTableHead studentTableHeadNameTitle" onClick={() => setAttrSortOrder('username')}>
                <div>Name</div>
                {renderArrow("username", sortOrder === -1 ? 'desc' : 'asc', sortAttribute)}
              </div>
            </th>
            <th>
              <div className="studentTableHead" onClick={() => setAttrSortOrder('email')}>
                <div>Email</div>
                {renderArrow("email", sortOrder === -1 ? 'desc' : 'asc', sortAttribute)}
              </div>
            </th>
            <th>
              <div className="studentTableHead" onClick={() => setAttrSortOrder('company')}>
                <div>Company</div>
                {renderArrow("company", sortOrder === -1 ? 'desc' : 'asc', sortAttribute)}
              </div>
            </th>
            <th>
              <div className="studentTableHead" onClick={() => setAttrSortOrder('department')}>
                <div>Department</div>
                {renderArrow("department", sortOrder === -1 ? 'desc' : 'asc', sortAttribute)}
              </div>
            </th>
            <th>
              <div className="studentTableHead" onClick={() => setAttrSortOrder('status')}>
                <div>Status</div>
                {renderArrow("status", sortOrder === -1 ? 'desc' : 'asc', sortAttribute)}
              </div>
            </th>
            <th>
              <div className="studentTableHead" onClick={() => setAttrSortOrder('score')}>
                <div>Points</div>
                {(students.length > 0 && !Number.isInteger(students[0]?.statuses[courseId].score)) ? <SmallSpinner/> : renderArrow("score", sortOrder === -1 ? 'desc' : 'asc', sortAttribute)}
              </div>
            </th>
            <th>
              <div className="studentTableHead" onClick={() => setAttrSortOrder('lastInteractionAt')}>
                <div>Finished date</div>
                {renderArrow("lastInteractionAt", sortOrder === -1 ? 'desc' : 'asc', sortAttribute)}
              </div>
            </th>
          </tr>
          { isLoading && 
            <tr>
              <td>
                <LoadingText label="Loading users" /> 
              </td>
            </tr>
          }
          {students.map((student, index) => (
            <tr className="studentTableRow" key={student.email}>
              <td className="studentTableCell studentTableNameCell" onClick={() => history.push(`/course/${courseId}/useroverview/${student._id}`)}>{student.username}</td>
              <td className="studentTableCell emailCell" onClick={() => history.push(`/course/${courseId}/useroverview/${student._id}`)}>{student.email}</td>
              <td className="studentTableCell companyCell" onClick={() => history.push(`/course/${courseId}/useroverview/${student._id}`)}>{student.company}</td>
              <td className="studentTableCell departmentCell" onClick={() => history.push(`/course/${courseId}/useroverview/${student._id}`)}>{student.department}</td>
              <td className="studentTableCell statusCell" onClick={() => history.push(`/course/${courseId}/useroverview/${student._id}`)}>
                { student.statuses[courseId]?.status === 'Certified' && (
                  <img src={champion_cup} alt="champion cup" />
                )}
                { student.statuses[courseId]?.status }
              </td>
              <td className="studentTableCell scoreCell" onClick={() => history.push(`/course/${courseId}/useroverview/${student._id}`)}>{student.statuses[courseId]?.score}</td>
              <td className="studentTableCell" onClick={() => history.push(`/course/${courseId}/useroverview/${student._id}`)}>
                {getStudentFinishedDate(student, courseId)}
              </td>
              {
                (isSuperAdmin || userPermissions.fullRights || userPermissions.invite) ?
                <td className="studentTableMenuCell" onClick={() => setOpenDropdownMenuIndex(openDropdownMenuIndex === index ? null : index)}>
                  <div className="studentTableMenuCellIconsContainer">
                    <img className="studentTableMenuCellIcon studentTableMenuCellIconGrey" src={three_dots} alt="three dots"/>
                    <img className="studentTableMenuCellIconBlackBG" src={three_dots_black_bg} alt="three dots"/>
                    {openDropdownMenuIndex === null && <Tooltip text="User menu" width="65"/>}
                    {index === openDropdownMenuIndex && renderDropdownMenu(dropdownRef, courseId, student._id, setOpenDeleteUserModal, setUserToDelete, setOpenResetUserModal, setUserToReset, student)}
                  </div>
                </td>
                :
                <td></td>
              }
            </tr>
          ))}
        </tbody>
        {shouldShowLazyRow && (
          <LazyLoadingTableFooter
            callback={() => fetchNextPage()}
            colSpan={9}
            observerEnabled={intersectionObserverEnabled}
          />
        )}
      </table>
      {(numberOfFilters > 0 && students.length === 0) && renderNoMatchesComponent(clearFilters)}
      <WarningModal
        open={openDeleteUserModal}
        onClose={() => setOpenDeleteUserModal(false)}
        title='Remove user from course'
        text1={`Are you sure you want to remove "${getUserNameFromUserId(userToDelete, students)}" from ${program.name}?`}
        text2={`"${getUserNameFromUserId(userToDelete, students)}" will be deleted and CAN NOT be recovered`}
        leftButtonText='NO, CANCEL'
        rightButtonText='YES, REMOVE'
        onLeftButtonClick={() => handleCloseDeleteUserModal(setOpenDeleteUserModal, setUserToDelete)}
        onRightButtonClick={() => deleteUsers(userToDelete)}
      />
      <WarningModal
        open={openResetUserModal}
        onClose={() => setOpenResetUserModal(false)}
        title='Reset user progress'
        text1={`Are you sure you want to reset "${userToReset.username}'s" progress from ${program.name}?`}
        text2={`"${userToReset.username}'s" progress will be reset and CAN NOT be recovered`}
        leftButtonText='NO, CANCEL'
        rightButtonText='YES, RESET'
        onLeftButtonClick={() => handleCloseResetUserModal(setOpenResetUserModal, setUserToReset)}
        onRightButtonClick={() => handleResetUserProgress(setOpenResetUserModal, dispatch, userToReset, setUserToReset, courseId)}
      />
      { removingState.succeed && (
        <Toast
          shown={removingState.succeed}
          text="User removed"
          timeToClose={5000}
        />
        )}
      { removingState.error && (
        <ErrorToast
          shown={removingState.error}
          text="Error removing user"
          timeToClose={10000}
        />
      )}
      { removingState.loading && (
        <LoadingToast
          shown={removingState.loading}
          text="Removing user"
        />
      )}
    </>
  );
};

const getStudentFinishedDate = (student, courseId) => {
  const { status, lastInteractionAt } = student.statuses[courseId];
  return ["Finished","Certified"].includes(status) ? getDayMonthYearFromDate(lastInteractionAt) + ' ' + getTimeFromDate(lastInteractionAt) : "";
};

const renderDropdownMenu = (dropdownRef, courseId, userId, setOpenDeleteUserModal, setUserToDelete, setOpenResetUserModal, setUserToReset, userDataObject) => {
  return (
    <div className="studentTableDropdownMenu" ref={dropdownRef}>
      <Link className="studentTableDropdownMenuItem" to={`/course/${courseId}/user/${userId}`}>
        <div className="studentTableDropdownItemContainer">
          <div className="studentTableDropdownMenuText">Edit user</div>
          <img className="studentTableDropdownMenuIcon" src={pencil_icon_grey} alt="pencil icon"/>
        </div>
      </Link>
      {
        userDataObject.statuses[courseId].status !== "Not started"
        &&
        <div className="studentTableDropdownMenuItem">
          <div className="studentTableDropdownItemContainer" onClick={() => handleOpenResetUserModal(setOpenResetUserModal, setUserToReset, userDataObject)}>
            <div className="studentTableDropdownMenuText">Reset progress</div>
            <img className="studentTableDropdownMenuIcon" src={user} alt="user"/>
          </div>
        </div>
      }
      <div className="studentTableDropdownMenuItem">
        <div className="studentTableDropdownDeleteContainer" onClick={() => handleOpenDeleteUserModal(setOpenDeleteUserModal, setUserToDelete, userId)}>
          <div className="studentTableDropdownMenuText studentTableDropdownDeleteText">Remove user</div>
          <img className="studentTableDropdownMenuX" src={red_x_mark} alt="crossmark"/>
          <img className="studentTableDropdownMenuX studentTableXWhite" src={white_x_mark} alt="crossmark"/>
        </div>
      </div>
    </div>
  );
};

const handleOpenDeleteUserModal = (setOpenDeleteUserModal, setUserToDelete, userId) => {
  setOpenDeleteUserModal(true);
  setUserToDelete(userId);
};

const handleCloseDeleteUserModal = (setOpenDeleteUserModal, setUserToDelete) => {
  setOpenDeleteUserModal(false);
  setUserToDelete(null);
};

const handleOpenResetUserModal = (setOpenResetUserModal, setUserToReset, userDataObject) => {
  setOpenResetUserModal(true);
  setUserToReset({...userDataObject, id: userDataObject._id});
};

const handleCloseResetUserModal = (setOpenResetUserModal, setUserToReset) => {
  setOpenResetUserModal(false);
  setUserToReset({});
};

const handleResetUserProgress = (setOpenResetUserModal, dispatch, userToReset, setUserToReset, courseId) => {
  dispatch(actions.resetUserProgress.request(userToReset, courseId));
  setOpenResetUserModal(false);
  setUserToReset({});
};

const getUserNameFromUserId = (userId, users) => {
  const correctUser = users.find(user => {
    return user._id === userId;
  });
  if (correctUser) {
    return correctUser.username;
  }
  return '';
};

const renderNoMatchesComponent = (clearFilters) => {
  return (
    <div className="studentTableNoMatchesWrapper">
      <div className="studentTableNoMatchesText">No matches found.</div>
      <div className="studentTableNoMatchesButton" onClick={clearFilters}>CLEAR FILTERS</div>
    </div>
  );
};

export default StudentTable;
