import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import * as actions from '../../actions';
import CourseCoreMoveTooltip from './CourseCoreMoveTooltip';
import CourseCoreDeleteTooltip from './CourseCoreDeleteTooltip';
import ImageUploadLoadingAnimation from './ImageUploadLoadingAnimation';
import draggable_handle from '../../images/svg/draggable_handle.svg';
import draggable_handle_onhover from '../../images/svg/draggable_handle_onhover.svg';
import image_icon from '../../images/svg/image_icon.svg';
import red_x_mark from '../../images/svg/red_x_mark.svg';
import red_x_mark_onhover from '../../images/svg/red_x_mark_onhover.svg';
import './ImageComponent.css';

/**
* ImageComponent: Field which adds a single Image to the Module, chosen from the Add Content dropdown
*/
const ImageComponent = (props) => {
  const dispatch = useDispatch();
  const { moduleId, moduleItemId, imageUrl, moduleType, provided, numberOfComponents, parentId } = props;
  const imageLoadingAnimationOrigin = useSelector(state => state.componentStates.imageLoadingAnimationOrigin);
  const currentPath = window.location.pathname.split('/');

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles[0]) {
      const reader = new FileReader();
      reader.onload = () => {
        const binaryStr = reader.result;
        dispatch(actions.updateImageComponent.request(moduleId, moduleItemId, binaryStr, moduleType));
      };
      reader.readAsArrayBuffer(acceptedFiles[0]);
    }
    dispatch(actions.setImageUploadToastOrigin.request(currentPath[3]));
    dispatch(actions.setImageLoadingAnimationOrigin.request(moduleItemId));
  }, []);
  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png, image/jpg, image/svg',
    maxfiles: 1
  });

  return (
    <>
      <div className="imageComponentContainer">
        <img
          className={numberOfComponents === 1 ? "imageComponentIcon imageComponentIconAlone" : "imageComponentIcon"}
          src={image_icon}
        />
        {numberOfComponents > 1 &&
          <div className="imageComponentTooltipParent" {...provided.dragHandleProps}>
            <img className="imageComponentDraggableHandle" src={draggable_handle}/>
            <img className="imageComponentDraggableHandleOnHover" src={draggable_handle_onhover}/>
            <CourseCoreMoveTooltip x={"-140%"} y={"-195%"}/>
          </div>
        }
        {imageUrl ?
          renderImage(imageUrl, isDragActive, isDragReject, getRootProps, getInputProps, imageLoadingAnimationOrigin, moduleItemId)
          : renderEmptyDropzone(isDragActive, isDragReject, getRootProps, getInputProps, imageLoadingAnimationOrigin, moduleItemId)
        }
        <div className="imageComponentXMarkContainer">
          <img className="imageComponentXMark" src={red_x_mark}/>
          <img
            className="imageComponentXMarkOnHover"
            src={red_x_mark_onhover}
            onClick={() => dispatch(actions.deleteComponent.request(moduleId, moduleItemId, moduleType, parentId))}
          />
          <CourseCoreDeleteTooltip x={"30%"} y={"-195%"}/>
        </div>
      </div>
      <div className="imageComponentSupportText">
        <div>Supported file formats: .jpg, .jpgz, .png, .svg</div>
        <div>Recommended image width 550 px, maximum file size 200kb</div>
      </div>
    </>
  );
};

const renderImage = (imageUrl, isDragActive, isDragReject, getRootProps, getInputProps, imageLoadingAnimationOrigin, moduleItemId) => {
  return (
    <div className="imageComponentImageAndOverlayWrapper">
      <div className="imageComponentImageContainer" {...getRootProps()}>
        <input {...getInputProps()} />
        <img className="imageComponentImage" src={process.env.REACT_APP_MEDIA_URL + imageUrl} />
        {isDragActive && renderDropzoneOverlay(isDragReject)}
        {imageLoadingAnimationOrigin === moduleItemId && <ImageUploadLoadingAnimation/>}
        <div className="imageComponentImageHoverOverlay">
          <div className="imageComponentOverlayText">Click to change image</div>
        </div>
      </div>
    </div>
  );
};

const renderEmptyDropzone = (isDragActive, isDragReject, getRootProps, getInputProps, imageLoadingAnimationOrigin, moduleItemId) => {
  const emptyDropzone = document.getElementById("emptyDropzone");
  if(emptyDropzone) {
    emptyDropzone.removeAttribute("multiple");
  }

  return (
    <div className="imageComponentEmptyDropzone" {...getRootProps()}>
      <input id="emptyDropzone" {...getInputProps()} />
      {isDragActive ?
        renderDropzoneOverlay(isDragReject)
        :
        <>
          <div className="imageComponentEmptyDropzoneText">
            Drop image here to upload
          </div>
          <div className="imageComponentEmptyDropzoneHelperText">
            or <span className="imageComponentEmptyDropzoneTextUnderline">browse</span> to choose a file
          </div>
        </>
      }
      {imageLoadingAnimationOrigin === moduleItemId && <ImageUploadLoadingAnimation/>}
    </div>
  );
};

const renderDropzoneOverlay = (isDragReject) => {
  return (
    <div className="imageComponentImageOverlay">
      { isDragReject ?
        <>
          <div className="imageComponentOverlayText">
            Moblrn only supports .jpg, .jpgz, .png, .svg
          </div>
        </>
        :
        <>
          <div className="imageComponentOverlayText">
            Drop image here to upload
          </div>
        </>
      }
    </div>
  );
};

export default ImageComponent;
