import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../actions';
import Const from '../../store/Const';
import { useOutsideClick } from '../../utils/componentUtils';
import { getPreviousPageStartDate, getNextPageStartDate } from '../../reducers/programs';
import DateTimePicker from 'react-datetime-picker/dist/entry.nostyle';
import radio_button from '../../images/svg/radio_button.svg';
import radio_button_active from '../../images/svg/radio_button_active.svg';
import './PageSettingsModal.css';
import '../dateTimePicker/TimePicker.css';
import '../dateTimePicker/Clock.css';
import '../dateTimePicker/DatePicker.css';
import '../dateTimePicker/Calendar.css';
import '../dateTimePicker/DateTimePicker.css';


/**
* PageSettingsModal
*/
const PageSettingsModal = (props) => {
  const { open, onClose, page, isFirstPage } = props;
  const dispatch = useDispatch();
  const courseId = useSelector(state => state.programs.currentProgram._id);
  const moduleId = useSelector(state => state.programs.currentProgram.modules.lessonModule._id) || '';
  const courseType = useSelector(state => state.programs.currentProgram.type) || 0;
  const previousStartDate = useSelector(getPreviousPageStartDate);
  const nextStartDate = useSelector(getNextPageStartDate);
  const courseStartDate = useSelector(state => state.programs.currentProgram.startDate) || null;
  const [useTimeDelay, setUseTimeDelay] = useState(false);
  const [timeDelayInHours, setTimeDelayInHours] = useState(0);
  const [usePush, setUsePush] = useState(false);
  const [pushText, setPushText] = useState('');
  const [useReminder, setUseReminder] = useState(false);
  const [reminderDelay, setReminderDelay] = useState(0);
  const [reminderText, setReminderText] = useState('');
  const [startDate, setStartDate] = useState('');
  useEffect(() => {
    setUseTimeDelay(initiateUseTimeDelay(
      isFirstPage,
      courseType === Const.courseType.Dynamic,
      page.data.startOffsetMinutes,
      getEarliestStartDate(courseStartDate, previousStartDate),
      page.data.startStaticDate
    ));
    setTimeDelayInHours(Math.floor(page.data.startOffsetMinutes/60) || 24);
    setUsePush(page.data.pushWhenNew || false);
    setPushText(page.data.pushWhenNewText || '');
    setUseReminder(page.data.pushReminder || false);
    setReminderDelay(page.data.pushReminderTimeout || 24);
    setReminderText(page.data.pushReminderText || '');
    setStartDate(page.data.startStaticDate || getEarliestStartDate(courseStartDate, previousStartDate));
  }, [page]);
  const handleLeftButtonClick = () => {
    setUseTimeDelay(false);
    setUsePush(false);
    setUseReminder(false);
    dispatch(actions.setCurrentlyOpenPageSettingsId.request(''));
  };
  const handleRightButtonClick = () => {
    if (page._id) {
      dispatch(actions.updateComponent.request(
        moduleId,
        page._id,
        calculatePageData(
          page,
          usePush,
          pushText,
          timeDelayInHours,
          useReminder,
          reminderDelay,
          reminderText,
          useTimeDelay,
          courseType,
          isFirstPage,
          startDate,
          getEarliestStartDate(courseStartDate, previousStartDate)
        ),
        Const.moduleType.Lessons
      ));
    }
    else {
      dispatch(actions.addComponent.request(
        Const.moduleItemType.Lesson,
        page.parentId,
        moduleId,
        Const.moduleType.Lessons,
        calculatePageData(
          page,
          usePush,
          pushText,
          timeDelayInHours,
          useReminder,
          reminderDelay,
          reminderText,
          useTimeDelay,
          courseType,
          isFirstPage,
          startDate,
          getEarliestStartDate(courseStartDate, previousStartDate)
        ),
        courseId
      ));
    }
    setUseTimeDelay(false);
    setUsePush(false);
    setUseReminder(false);
    dispatch(actions.setCurrentlyOpenPageSettingsId.request(''));
  };
  const handleChooseDateOnChange = (value) => {
    if (value < new Date(getEarliestStartDate(courseStartDate, previousStartDate))) {
      setStartDate(getEarliestStartDate(courseStartDate, previousStartDate));
    }
    else if (nextStartDate && value > new Date(nextStartDate)) {
      setStartDate(nextStartDate);
    }
    else {
      setStartDate(value);
    }
    setUseTimeDelay(true);
  };

  const disableSubmitButton = () => {
    if (usePush && pushText.length <= 0) {
      return true;
    }
    if (useReminder && reminderText.length <= 0) {
      return true;
    }
  };

  return (
    <Modal
      id="modal"
      className="pageSettingsModal"
      open={open}
      onClose={onClose}
    >
      <div className="pageSettingsModalBackground">
        <div className="pageSettingsModalTitle">{page._id ? 'Page settings' : 'New page'}</div>
        {renderTimeDelaySettings(
            useTimeDelay,
            setUseTimeDelay,
            setUsePush,
            setUseReminder,
            timeDelayInHours,
            setTimeDelayInHours,
            courseType,
            startDate,
            setStartDate,
            getEarliestStartDate(courseStartDate, previousStartDate),
            nextStartDate,
            isFirstPage,
            handleChooseDateOnChange
          )
        }
        {renderPushSettings(usePush, setUsePush, useTimeDelay, pushText, setPushText, courseType, isFirstPage, timeDelayInHours)}
        {renderReminderSettings(
          usePush,
          useTimeDelay,
          useReminder,
          setUseReminder,
          reminderDelay,
          reminderText,
          setReminderText,
          setReminderDelay,
          courseType,
          isFirstPage
        )}
        <div className="pageSettingsModalButtonsContainer">
          <button className="pageSettingsModalCancelButton" onClick={() => handleLeftButtonClick()}>CANCEL</button>
          <button
            className="pageSettingsModalSaveButton"
            onClick={() => handleRightButtonClick()}
            disabled={disableSubmitButton()}
          >
            {page._id ? 'SAVE CHANGES' : 'CREATE PAGE'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

const renderTimeDelaySettings = (
  useTimeDelay,
  setUseTimeDelay,
  setUsePush,
  setUseReminder,
  timeDelayInHours,
  setTimeDelayInHours,
  courseType,
  startDate,
  setStartDate,
  minStartDate,
  maxStartDate,
  isFirstPage,
  handleChooseDateOnChange
) => {
  const handleTimeDelayButton = () => {
    setUseTimeDelay(false);
    setUsePush(false);
    setUseReminder(false);
  };
  return (
    <>
      <div className="pageSettingsModalSubtitle">
        THIS PAGE SHOULD BE RELEASED:
      </div>
      <div className="pageSettingsModalRadioButtonWrapper">
        <div onClick={handleTimeDelayButton}>
          {
            useTimeDelay ?
            <img className="pageSettingsModalRadioButtonIcon" src={radio_button}/>
            :
            <img className="pageSettingsModalRadioButtonIcon" src={radio_button_active}/>
          }
        </div>
        <div className="pageSettingsModalRadioButtonText">
          {
            isFirstPage ?
            "When the program is released (Recommended)"
            :
            "With previous page (no push notification)"
          }
        </div>
      </div>
      <div className="pageSettingsModalRadioButtonWrapper">
        <div onClick={() => setUseTimeDelay(true)}>
          {
            !useTimeDelay ?
            <img className="pageSettingsModalRadioButtonIcon" src={radio_button}/>
            :
            <img className="pageSettingsModalRadioButtonIcon" src={radio_button_active}/>
          }
        </div>
        {courseType === Const.courseType.Dynamic &&
          <>
            <DelayComponent
              hoursDelayed={timeDelayInHours}
              setTimeDelay={setTimeDelayInHours}
              setUseDelayOrReminder={setUseTimeDelay}
            />
            <div className="pageSettingsModalRadioButtonText pageSettingsDelayComponentText">
              {
                isFirstPage ?
                "after the program is released"
                :
                "after completing previous page"
              }
            </div>
          </>
        }
        {courseType === Const.courseType.Static &&
          <DateTimePicker
            onChange={(value) => handleChooseDateOnChange(value)}
            value={new Date(startDate)}
            clearIcon={null}
            disableClock={true}
            locale="en-en"
            format="dd/MM/y H:mm"
            minDate={minStartDate ? new Date(minStartDate) : null}
            maxDate={maxStartDate ? new Date(maxStartDate) : null}
          />
        }
      </div>
      <div className="pageSettingsModalHelperText">
        (You can always change this later)
      </div>
    </>
  );
};

const renderPushSettings = (usePush, setUsePush, useTimeDelay, pushText, setPushText, courseType, isFirstPage, timeDelayInHours) => {
  const minTextAreaHeight = 16;
  const textareaRef = useRef(null);

  const generateOptionText = () => {
    if (isFirstPage && courseType === Const.courseType.Static) {
      return "Add push notification (Recommended)";
    } else if (isFirstPage && courseType === Const.courseType.Dynamic) {
      return <span className="pageSettingsFirstPagePushText">Add push notification (The first page can&apos;t have push notification, changing this will not send a push notification)</span>;
    } else {
      return "Add push notification";
    }
  };

  const enablePushSettingsTextarea = () => {
    if (!usePush) {
      return true;
    } else if (isFirstPage && courseType === Const.courseType.Static) {
      return false;
    } else if (!useTimeDelay) {
      return true;
    }
  };

  useLayoutEffect(() => {
    if (usePush) {
      textareaRef.current.style.height = "16px";
      textareaRef.current.style.height = `${Math.max(textareaRef.current.scrollHeight, minTextAreaHeight)}px`;
    }
  }, [pushText]);

  return (
    <div className={timeDelayInHours > 0 && (useTimeDelay || (isFirstPage && courseType === Const.courseType.Static)) ?
      "pageSettingsModalSection"
      :
      "pageSettingsModalSection pageSettingsModalSectionDisabled"
      }
    >
      <div className="pageSettingsModalSubtitle">
        PUSH NOTIFICATION:
      </div>
      <div className="pageSettingsModalRadioButtonWrapper">
        <div onClick={() => setUsePush(false)} className={useTimeDelay || (isFirstPage && courseType === Const.courseType.Static) ? "" : "pageSettingsModalRadioButtonDisabled"}>
          {
            usePush ?
            <img className="pageSettingsModalRadioButtonIcon" src={radio_button}/>
            :
            <img className="pageSettingsModalRadioButtonIcon" src={radio_button_active}/>
          }
        </div>
        <div className="pageSettingsModalRadioButtonText">No push notification</div>
      </div>
      <div className={(isFirstPage && courseType === Const.courseType.Dynamic) ?
        "pageSettingsModalRadioButtonWrapper pageSettingsModalFirstPageRadioButton"
        :
        "pageSettingsModalRadioButtonWrapper"
        }
      >
        <div onClick={() => setUsePush(true)} className={useTimeDelay || (isFirstPage && courseType === Const.courseType.Static) ? "" : "pageSettingsModalRadioButtonDisabled"}>
          {
            !usePush ?
            <img className="pageSettingsModalRadioButtonIcon" src={radio_button}/>
            :
            <img className="pageSettingsModalRadioButtonIcon" src={radio_button_active}/>
          }
        </div>
        <div className="pageSettingsModalRadioButtonText">{generateOptionText()}</div>
      </div>
      <>
        <textarea
          className="pageSettingsModalTextArea"
          placeholder={'e.g. "You have new content available!"'}
          value={pushText}
          onChange={(event) => setPushText(event.target.value)}
          ref={textareaRef}
          maxLength="100"
          disabled={enablePushSettingsTextarea()}
        />
        <div className="pageSettingsModalInputHelperTexts">
          <div className={(usePush && pushText.length === 0) ? "pageSettingsModalCounterWarningText" : "pageSettingsModalCounterWarningTextHidden"}>
            Needs at least one character
          </div>
          <div className="pageSettingsModalCounterText">{`${pushText.length}/100`}</div>
        </div>
      </>
    </div>
  );
};

const renderReminderSettings = (
  usePush,
  useTimeDelay,
  useReminder,
  setUseReminder,
  reminderDelay,
  reminderText,
  setReminderText,
  setReminderDelay,
  courseType,
  isFirstPage
) => {
  const minTextAreaHeight = 16;
  const textareaRef = useRef(null);

  useLayoutEffect(() => {
    if (useReminder) {
      textareaRef.current.style.height = "16px";
      textareaRef.current.style.height = `${Math.max(textareaRef.current.scrollHeight, minTextAreaHeight)}px`;
    }
  }, [reminderText]);

  const classNameReminderSettingsSection = () => {
    if (isFirstPage && courseType === Const.courseType.Static && usePush) {
      return "pageSettingsModalSection";
    } else if (usePush && useTimeDelay) {
      return "pageSettingsModalSection";
    } else {
      return "pageSettingsModalSection pageSettingsModalSectionDisabled";
    }
  };

  const enableReminderSettingsSectionTextarea = () => {
    if (isFirstPage && courseType === Const.courseType.Static && (usePush && useReminder)) {
      return false;
    } else if (!useTimeDelay || !usePush || !useReminder) {
      return true;
    }
  };

  return (
    <div className={classNameReminderSettingsSection()}>
      <div className="pageSettingsModalSubtitle">
        PUSH NOTIFICATION REMINDER:
      </div>
      <div className="pageSettingsModalRadioButtonWrapper">
        <div
          onClick={() => setUseReminder(false)}
          className={(isFirstPage && courseType === Const.courseType.Static && usePush) || (usePush && useTimeDelay) ? "" : "pageSettingsModalRadioButtonDisabled"}
        >
          {
            useReminder ?
            <img className="pageSettingsModalRadioButtonIcon" src={radio_button}/>
            :
            <img className="pageSettingsModalRadioButtonIcon" src={radio_button_active}/>
          }
        </div>
        <div className="pageSettingsModalRadioButtonText">
          No reminder
        </div>
      </div>
      <div className="pageSettingsModalRadioButtonWrapper">
        <div
          onClick={() => setUseReminder(true)}
          className={(isFirstPage && courseType === Const.courseType.Static && usePush) || (usePush && useTimeDelay) ? "" : "pageSettingsModalRadioButtonDisabled"}
        >
          {
            !useReminder ?
            <img className="pageSettingsModalRadioButtonIcon" src={radio_button}/>
            :
            <img className="pageSettingsModalRadioButtonIcon" src={radio_button_active}/>
          }
        </div>
        <div className="pageSettingsModalRadioButtonText">
          Send reminder
        </div>
        <DelayComponent
          hoursDelayed={reminderDelay}
          setTimeDelay={setReminderDelay}
          setUseDelayOrReminder={setUseReminder}
        />
        <div className="pageSettingsModalRadioButtonText pageSettingsDelayComponentText">
          after the first push message
        </div>
      </div>
      <>
        <textarea
          className="pageSettingsModalTextArea pushNotificationReminderTextarea"
          placeholder={'e.g. "Reminder - new content"'}
          value={reminderText}
          onChange={(event) => setReminderText(event.target.value)}
          ref={textareaRef}
          maxLength="100"
          disabled={enableReminderSettingsSectionTextarea()}
        />
        <div className="pageSettingsModalInputHelperTexts">
          <div className={(useReminder && reminderText.length === 0) ? "pageSettingsModalCounterWarningText" : "pageSettingsModalCounterWarningTextHidden"}>
            Needs at least one character
          </div>
          <div className="pageSettingsModalCounterText">{`${reminderText.length}/100`}</div>
        </div>
      </>
    </div>
  );
};

const DelayComponent = ({ hoursDelayed, setTimeDelay, setUseDelayOrReminder }) => {
  const [timePickerOpen, setTimePickerOpen] = useState(false);
  const weeks = Math.floor(hoursDelayed/168);
  const days = Math.floor((hoursDelayed - weeks*168)/24);
  const hours = Math.floor(hoursDelayed - weeks*168 - days*24);
  const [localWeeks, setLocalWeeks] = useState(weeks);
  const [localDays, setLocalDays] = useState(days);
  const [localHours, setLocalHours] = useState(hours);
  const delayComponentRef = useRef();
  useEffect(() => {
    setTimeDelay(localWeeks*7*24 + localDays*24 + parseInt(localHours || 0));
  }, [localWeeks, localDays, localHours]);
  useOutsideClick(delayComponentRef, () => {
    setTimeout(() => setTimePickerOpen(false), 100);
  });
  let timeDelayString = '';
  if (hoursDelayed <= 0) {
    timeDelayString = '-';
  }
  else {
    let timeDelayList = [];
    const timePeriods = ['w', 'd', 'h'];
    [weeks, days, hours].forEach((timePeriod, index) => {
      if (timePeriod === 1) {
        timeDelayList.push(timePeriod + ' ' + timePeriods[index]);
      }
      else if (timePeriod > 1) {
        timeDelayList.push(timePeriod + ' ' + timePeriods[index]);
      }
    });
    if (timeDelayList.length === 3) {
      timeDelayList.splice(2, 0, ' & ');
      timeDelayList.splice(1, 0, ', ');
    }
    if (timeDelayList.length === 2) {
      timeDelayList.splice(1, 0, ' & ');
    }
    timeDelayList.forEach(item => {
      timeDelayString += item;
    });
  }
  return (
    <div
      className="pageSettingsModalTimeDelayDisplay"
      onClick={() => {
        setTimePickerOpen(true);
        setLocalWeeks(weeks);
        setLocalDays(days);
        setLocalHours(hours);
      }}
    >
      {timeDelayString}
      {
        timePickerOpen &&
        <div className="pageSettingsModalTimePicker" ref={delayComponentRef}>
          <div className="pageSettingsModalTimePickerInputRow">
            <input
              type="number"
              value={localWeeks === 0 ? '' : localWeeks}
              className="pageSettingsModalTimePickerInput"
              placeholder={0}
              onChange={(event) => {
                setUseDelayOrReminder(true);
                if (event.target.value < 0) {
                  setLocalWeeks(0);
                }
                else if (event.target.value > 52) {
                  setLocalWeeks(52);
                }
                else {
                  setLocalWeeks(event.target.value);
                }
              }}
            />
            <div className="pageSettingsModalTimePickerInputText">
              weeks
            </div>
          </div>
          <div className="pageSettingsModalTimePickerInputRow">
            <input
              type="number"
              value={localDays}
              className="pageSettingsModalTimePickerInput"
              placeholder={0}
              onChange={(event) => {
                setUseDelayOrReminder(true);
                if (event.target.value < 0) {
                  setLocalDays(0);
                } else {
                  setLocalDays(event.target.value);
                }
              }}
            />
            <div className="pageSettingsModalTimePickerInputText">
              days
            </div>
          </div>
          <div className="pageSettingsModalTimePickerInputRow">
            <input
              type="number"
              value={localHours === 0 ? '' : localHours}
              className="pageSettingsModalTimePickerInput"
              placeholder={0}
              onChange={(event) => {
                setUseDelayOrReminder(true);
                if (event.target.value < 0) {
                  setLocalHours(0);
                } else {
                  setLocalHours(event.target.value);
                }
              }}
            />
            <div className="pageSettingsModalTimePickerInputText">
              hours
            </div>
          </div>
        </div>
      }
    </div>
  );
};

/**
* initiateUseTimeDelay checks if the edited page is already using time delay
*/
const initiateUseTimeDelay = (isFirstPage, isDynamic, startOffsetMinutes, earliestStartDate, pageStartDate) => {
  if (isFirstPage) {
    return false;
  }
  if (isDynamic && startOffsetMinutes) {
    return true;
  }
  if (!isDynamic && pageStartDate && earliestStartDate !== pageStartDate) {
    return true;
  }
  return false;
};

/**
* calculatePageData calculates what data to send to the backend given the
* data in the component
*/
const calculatePageData = (
  page,
  usePush,
  pushText,
  timeDelayInHours,
  useReminder,
  reminderDelay,
  reminderText,
  useTimeDelay,
  courseType,
  isFirstPage,
  startDate,
  earliestStartDate
) => {
  if (courseType === Const.courseType.Dynamic) {
    return (
      {
        text: page._id ? page.data.text : '',
        pushWhenNew: usePush && timeDelayInHours > 0,
        pushWhenNewText: pushText,
        pushReminder: useReminder && reminderDelay > 0,
        pushReminderText: reminderText,
        startOffsetHours: useTimeDelay ? timeDelayInHours : 0,
        startOffsetMinutes: useTimeDelay ? timeDelayInHours*60 : 0,
        pushReminderTimeout: useReminder ? reminderDelay : 0,
        pushReminderTimeoutMinutes: useReminder ? reminderDelay*60 : 0,
      }
    );
  }
  else if (courseType === Const.courseType.Static) {
    if (isFirstPage) {
      return (
        {
          text: page._id ? page.data.text : '',
          pushWhenNew: usePush,
          pushWhenNewText: pushText,
          pushReminder: useReminder && reminderDelay > 0,
          pushReminderText: reminderText,
          pushReminderTimeout: useReminder ? reminderDelay : 0,
          pushReminderTimeoutMinutes: useReminder ? reminderDelay*60 : 0,
        }
      );
    }
    else {
      return (
        {
          text: page._id ? page.data.text : '',
          pushWhenNew: usePush && earliestStartDate !== startDate,
          pushWhenNewText: pushText,
          pushReminder: useReminder && reminderDelay > 0,
          pushReminderText: reminderText,
          pushReminderTimeout: useReminder ? reminderDelay : 0,
          pushReminderTimeoutMinutes: useReminder ? reminderDelay*60 : 0,
          startStaticDate: useTimeDelay ? startDate : earliestStartDate
        }
      );
    }
  }
};

/**
* getEarliestStartDate gets the earliest start date for the static course page.
* The earliest start date has to be no earlier than the previous pages start
* date and the course start date
*/
const getEarliestStartDate = (courseStartDate, previousStartDate) => {
  if (previousStartDate && new Date(previousStartDate) > new Date(courseStartDate)) {
    return previousStartDate;
  }
  return courseStartDate;
};


export default PageSettingsModal;
