import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../actions';
import { useOutsideClick } from '../../utils/componentUtils';
import chevron_downward_icon from '../../images/styleguideComponentsImages/chevron_downward_icon.svg';
import checkbox_checked from '../../images/styleguideComponentsImages/checkbox_checked.svg';
import checkbox_unchecked from '../../images/styleguideComponentsImages/checkbox_unchecked.svg';
import './TableSelectDropdown.css';

/**
 * TableSelectDropdown: reusable custom select/ dropdown component, mainly
 * used for filtering on tables
 */
const TableSelectDropdown = ({
  name,
  defaultText,
  optionTexts,
  selectedItems,
  handleRemoveOption,
  handleChange,
  handleClear,
  source,
  disabled,
}) => {
  const dispatch = useDispatch();
  const userTableFilter = useSelector((state) => state.users.userTableFilter);
  const isUserlistFilterOptionsLoading = useSelector(
    (state) => state.componentStates.loadingUserlistFilterOptions
  );
  const dropdownRef = useRef();
  const [open, setOpen] = useState(false);

  useOutsideClick(dropdownRef, () => {
    setOpen(false);
  });

  const dropdownTextClassname = () => {
    if (selectedItems.length === 1) {
      return 'tableSelect tableSelectSingleSelectedItem';
    } else if (selectedItems.length > 0) {
      return 'tableSelect tableSelectSeveralSelectedItems';
    } else {
      return 'tableSelect tableSelectDropdownTextLight';
    }
  };

  const dropdownText = () => {
    if (selectedItems.length === 1) {
      return selectedItems[0];
    } else if (selectedItems.length > 0) {
      return `${selectedItems.length} filters selected`;
    } else {
      return defaultText;
    }
  };

  const handleClearOption = (name) => {
    if (source === 'studentTableSelect') {
      handleClear(name);
    } else if (source === 'courseUserTable') {
      let newFilter = { ...userTableFilter };
      newFilter[name] = [];
      dispatch(actions.updateUserTableFilter.request(newFilter));
    } else if (source === 'userProgressTable') {
      handleClear(name);
    } else if (source === 'courseUsersDataTable') {
      handleClear(name);
    }
  };

  return (
    <div
      ref={dropdownRef}
      className={
        disabled
          ? 'tableSelectWrapper tableSelectWrapperDisabled'
          : 'tableSelectWrapper'
      }
    >
      <div className="tableSelectTitle">{defaultText}</div>
      <div className={dropdownTextClassname()} onClick={() => setOpen(!open)}>
        <div className="tableSelectText">{dropdownText()}</div>
        <img
          className="tableSelectIcon"
          src={chevron_downward_icon}
          alt="downward arrow"
        />
      </div>
      <div
        className={
          open
            ? 'tableSelectDropdown'
            : 'tableSelectDropdown tableSelectDropdownClosed'
        }
        style={{ maxHeight: getDropdownMenuMaxheight(dropdownRef) }}
      >
        {isUserlistFilterOptionsLoading ? (
          <div className="tableSelectLoadingText">
            Loading<span>.</span>
            <span>.</span>
            <span>.</span>
          </div>
        ) : (
          <>
            <div
              className={
                selectedItems.length === 0
                  ? 'tableSelectDropdownItemClearItem tableSelectDropdownItemClearItemDisabled'
                  : 'tableSelectDropdownItemClearItem'
              }
              onClick={() =>
                selectedItems.length === 0 ? null : handleClearOption(name)
              }
            >
              Clear filters
            </div>
            {optionTexts.map((option) => {
              return (
                <div
                  className="tableSelectDropdownItem"
                  key={option}
                  onClick={
                    selectedItems.includes(option)
                      ? () => handleRemoveOption(name, option)
                      : () => handleChange(name, option)
                  }
                >
                  {selectedItems.includes(option) ? (
                    <img
                      className="tableSelectDropdownItemIcon"
                      src={checkbox_checked}
                    />
                  ) : (
                    <img
                      className="tableSelectDropdownItemIcon"
                      src={checkbox_unchecked}
                    />
                  )}
                  {option}
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

/**
 * getDropdownMenuMaxheight calculates the maxHeight of the dropdown menu.
 * The requirements are: The menu should fill out the whole page except
 * the bottom 70px. The menu should also be at least 200px long if the content
 * has at least that height, otherwise the menu should have the height of
 * it's content.
 */
const getDropdownMenuMaxheight = (ref) => {
  const { top, height } = ref.current
    ? ref.current.getBoundingClientRect()
    : { top: 0, height: 0 };
  const bottomMargin = 70;
  const maxHeight = window.innerHeight - top - height - bottomMargin;
  return maxHeight >= 200 ? maxHeight + 'px' : 'min(200px, min-content)';
};

export default TableSelectDropdown;
