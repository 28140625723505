import React, { useEffect } from 'react';
import { makeLinspaceArray } from '../utils/statisticsUtils';
import { useDashboardGraphs } from './use-dashboard-graphs';
import './InteractionStatisticsGraph.css';
import LoadingText from '../components/LoadingText';

/**
 * InteractionStatisticsGraph shows how many students received, saw
 * and completed each interaction
 */

const InteractionStatisticsGraph = ({ onInteractionClick }) => {
  const {
    fetchNextPage,
    filteredCount,
    interactionCount,
    interactionViewsToAnswers,
    isLoading,
    isFetchingNextPage,
    studentsWithAnswers,
    totalPercent,
  } = useDashboardGraphs();
  useEffect(() => {
    if (studentsWithAnswers.length < filteredCount) {
      fetchNextPage();
    }
  }, [studentsWithAnswers.length]);

  let points = calculatePolygonPoints(
    interactionViewsToAnswers,
    filteredCount,
    interactionCount
  );
  let graphWidth = Math.max(679, 25 * (interactionCount - 1));
  const pageWidth = graphWidth / (interactionCount - 1);

  return (
    <div className="interactionStatisticsGraphContainer">
      <div className="interactionStatisticsTitle">Progress</div>
      <div className="interactionGraphLegend">
        <div className="interactionGraphLegendWrapper">
          <div
            className="interactionGraphLegendColor"
            style={{ backgroundColor: '#e4d7ff' }}
          />
          <div className="interactionGraphLegendText">Opened page</div>
        </div>
        <div className="interactionGraphLegendWrapper">
          <div
            className="interactionGraphLegendColor"
            style={{ backgroundColor: '#9976ba' }}
          />
          <div className="interactionGraphLegendText">Completed page</div>
        </div>
      </div>
      <div className="interactionGraphYLabel">Users</div>
      <div className="interactionGraphXLabel">Page</div>
      <div className="interactionGraphyYMax">{filteredCount}</div>
      {filteredCount >= 10 && (
        <div className="interactionGraphyYHalf">{filteredCount / 2}</div>
      )}
      <div className="interactionStatisticsGraphWrapper">
        {interactionCount === 0 ||
          (filteredCount === 0 && (
            <div className="interactionGraphEmptyText">No data available</div>
          ))}
        <div className="interactionStatisticsGraphAndOverlay">
          <svg width={graphWidth} height="270">
            <defs>
              <pattern
                id="grid"
                width={pageWidth}
                height="67.5"
                patternUnits="userSpaceOnUse"
              >
                <path
                  d={'M ' + pageWidth + ' 0 L 0 0 0 67.5'}
                  fill="none"
                  stroke="silver"
                  strokeWidth="0.5"
                />
              </pattern>
            </defs>
            <rect
              width="calc(100% + 1px)"
              height="100%"
              transform="translate(-0.5,0)"
              fill="url(#grid)"
            />
            <polygon
              points={points.seenPositionPolygonString}
              fill="#e4d7ff"
              fillOpacity="0.2"
            />
            <polygon
              points={points.completedPositionPolygonString}
              fill="#9976ba"
              fillOpacity="0.2"
            />
            <polyline
              points={points.seenPositionString}
              stroke="#e4d7ff"
              fill="none"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <polyline
              points={points.completedPositionString}
              stroke="#9976ba"
              fill="none"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <polyline
              points={'0,270 ' + graphWidth + ',270'}
              stroke="#cbcbcb"
              fill="none"
              strokeWidth="2"
            />
          </svg>
          {interactionCount > 0 &&
            renderGraphOverlays(
              interactionCount,
              filteredCount,
              interactionViewsToAnswers,
              onInteractionClick
            )}
        </div>
        <div className="interationStatisticsBottomWrapper">
          <div className="interactionNumbers">
            {createInteractionLabels(interactionCount)}
          </div>
        </div>
      </div>
      <div className={`chart-loading-overlay ${isLoading || isFetchingNextPage ? 'shown' : ''}`}>
        <LoadingText label={`Loading (${totalPercent}%)`} />
      </div>
    </div>
  );
};

const calculatePolygonPoints = (
  interactionStatistics = [],
  numberOfStudents,
  numberOfInteractions
) => {
  let height = 270;
  let width = Math.max(679, 25 * (numberOfInteractions - 1));
  let x_positions = makeLinspaceArray(0, width, numberOfInteractions);
  let seenPositionString = '';
  let completedPositionString = '';
  interactionStatistics.forEach((interactionStat, index) => {
    let x_position = x_positions[index];
    let seen_y_position =
      height - Math.ceil((height * interactionStat[0]) / numberOfStudents);
    let completed_y_position =
      height - Math.ceil((height * interactionStat[1]) / numberOfStudents);
    seenPositionString += x_position + ',' + seen_y_position + ' ';
    completedPositionString += x_position + ',' + completed_y_position + ' ';
  });
  let seenPositionPolygonString = seenPositionString + width + ',270 0,270';
  let completedPositionPolygonString =
    completedPositionString + width + ',270 0,270';
  return {
    seenPositionString,
    completedPositionString,
    seenPositionPolygonString,
    completedPositionPolygonString,
  };
};

const renderGraphOverlays = (
  numberOfInteractions,
  numberOfStudents,
  interactionStatistics,
  setShownInteraction
) => {
  let height = 270;
  let graphWidth = Math.max(679, 25 * (numberOfInteractions - 1));
  let x_positions = makeLinspaceArray(0, graphWidth - 1, numberOfInteractions);
  let overlayBorders = [-10];
  let overlayDivs = [];
  for (let i = 0; i < numberOfInteractions - 1; i++) {
    overlayBorders.push(Math.ceil((x_positions[i] + x_positions[i + 1]) / 2));
  }
  overlayBorders.push(graphWidth + 10);
  for (let i = 0; i < numberOfInteractions; i++) {
    let overlayStyle = {
      left: overlayBorders[i] + 'px',
      width: overlayBorders[i + 1] - overlayBorders[i] + 'px',
    };
    let leftMargin = (overlayBorders[i + 1] - overlayBorders[i]) / 2 - 3;
    if (i === 0) {
      leftMargin = 7;
    }
    if (i === numberOfInteractions - 1) {
      leftMargin = overlayBorders[i + 1] - overlayBorders[i] - 10 - 3;
    }
    let seen_y_position =
      height - (height * interactionStatistics[i][0]) / numberOfStudents - 2;
    let completed_y_position =
      height - (height * interactionStatistics[i][1]) / numberOfStudents - 2;
    let completedNumberTopPosition = Math.min(completed_y_position - 6, 255);
    let seenNumberTopPosition =
      interactionStatistics[i][1] === interactionStatistics[i][0]
        ? completedNumberTopPosition
        : Math.min(seen_y_position - 6, completedNumberTopPosition - 13);
    overlayDivs.push(
      <div
        className="interactionGraphOverlay"
        key={i}
        style={overlayStyle}
        onClick={() => setShownInteraction(i)}
      >
        <div className="interactionGraphOverlayRelativeWrapper">
          <div
            className="interactionGraphOverlayDot"
            style={{ left: leftMargin, top: seen_y_position }}
          />
          <div
            style={{
              position: 'absolute',
              left: leftMargin + 10,
              top: seenNumberTopPosition,
            }}
          >
            {interactionStatistics[i][0]}
          </div>
          <div
            className="interactionGraphOverlayDot"
            style={{ left: leftMargin, top: completed_y_position }}
          />
          <div
            style={{
              position: 'absolute',
              left: leftMargin + 10,
              top: completedNumberTopPosition,
            }}
          >
            {interactionStatistics[i][1]}
          </div>
        </div>
      </div>
    );
  }
  return overlayDivs;
};

const createInteractionLabels = (numberOfInteractions) => {
  let interactionLabels = [];
  for (let i = 0; i < numberOfInteractions; i++) {
    interactionLabels.push(
      <div className="interactionNumber" key={i}>
        {i + 1}
      </div>
    );
  }
  return interactionLabels;
};

export default InteractionStatisticsGraph;
