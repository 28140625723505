import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '../../actions';
import CourseCoreMoveTooltip from './CourseCoreMoveTooltip';
import CourseCoreDeleteTooltip from './CourseCoreDeleteTooltip';
import AnswerChoiceTextarea from './AnswerChoiceTextarea';
import multi_choice_icon from '../../images/svg/multi_choice_icon.svg';
import draggable_handle from '../../images/svg/draggable_handle.svg';
import draggable_handle_onhover from '../../images/svg/draggable_handle_onhover.svg';
import red_x_mark from '../../images/svg/red_x_mark.svg';
import red_x_mark_onhover from '../../images/svg/red_x_mark_onhover.svg';
import './MultiChoiceComponent.css';

/**
* MultiChoiceComponent: Lesson question component which has checkboxes for the
* different answer choices and several anwers can be chosen
*/

const MultiChoiceComponent = ({
  moduleId,
  moduleItemId,
  moduleType,
  provided,
  numberOfComponents,
  parentId,
  autoFocus,
  options,
  score
}) => {
  const dispatch = useDispatch();
  const [questionPoints, setQuestionPoints] = useState(score >= 0 ? score : 0);
  const [hasLoaded, setHasLoaded] = useState(false);
  useEffect(() => {
    let debouncedSaveFunction = setTimeout(
      () => {
        if (hasLoaded) {
          dispatch(actions.updateComponent.request(moduleId, moduleItemId, {}, moduleType, { score: questionPoints }));
        }
        setHasLoaded(true);
      },
      500
    );
    return () => clearTimeout(debouncedSaveFunction);
  }, [questionPoints]);
  const handleSetQuestionPoints = (newQuestionPoints) => {
    if (newQuestionPoints < 0) {
      setQuestionPoints(0);
    }
    else if (newQuestionPoints > 1000) {
      setQuestionPoints(1000);
    }
    else {
      setQuestionPoints(newQuestionPoints);
    }
  };
  return (
    <div className="multiChoiceComponentContainer">
      <img
        className={numberOfComponents === 1 ? "multiChoiceComponentIcon multiChoiceComponentIconAlone" : "multiChoiceComponentIcon"}
        src={multi_choice_icon}
      />
      {numberOfComponents > 1 &&
        <div className="multiChoiceComponentTooltipParent" {...provided.dragHandleProps}>
          <img className="multiChoiceComponentDraggableHandle" src={draggable_handle}/>
          <img className="multiChoiceComponentDraggableHandleOnHover" src={draggable_handle_onhover}/>
          <CourseCoreMoveTooltip x={"-126%"} y={"-195%"}/>
        </div>
      }
      <div className="multiChoiceContentWrapper">
        <div className="multiChoiceTitleWrapper">
          <img className="multiChoiceTitleIcon" src={multi_choice_icon}/>
          <div className="multiChoiceTitleText">Multiple choices</div>
        </div>
        <div className="multiChoiceAnswersTitleWrapper">
          <div className="multiChoiceAnswersTitleText">OPTIONS</div>
          <div className="multiChoiceAnswersTitleText">CORRECT</div>
        </div>
        <div>
          {options.map((option, index) => {
            return (
              <div className="multiChoiceAnswerAndDelete" key={option._id}>
                <div className="multiChoiceTextAndCheckbox">
                  <AnswerChoiceTextarea
                    option={option}
                    isCorrectOption={option.isCorrect}
                    autoFocus={autoFocus && index === options.length - 1 || option.autoFocus}
                  />
                  <div className="multiChoiceCheckboxesContainer">
                    <label>
                      <input
                        type="checkbox"
                        checked={option.isCorrect}
                        onChange={() => dispatch(actions.editOption.request({ ...option, isCorrect: !option.isCorrect }))}
                      />
                      <span className="multiChoiceCustomCheckbox"></span>
                    </label>
                  </div>
                </div>
                <div className="multiChoiceCheckboxXMarkWrapper">
                  <img className="multiChoiceCheckboxXMark" src={red_x_mark}/>
                  <img
                    className="multiChoiceCheckboxXMarkOnHover"
                    src={red_x_mark_onhover}
                    onClick={() => dispatch(actions.deleteOption.request(moduleId, moduleItemId, option._id))}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <button
          className="multiChoiceAddAnswerButton"
          type="button"
          onClick={() => dispatch(actions.addOption.request(moduleId, moduleItemId))}
        >
          + Add option
        </button>
        <div className="multiChoicePointsText">POINTS FOR CORRECT ANSWER (MAX 1000)</div>
        <input
          type="number"
          className="multiChoicePointsInput"
          min="0"
          max="1000"
          value={questionPoints}
          onChange={(event) => handleSetQuestionPoints(event.target.value)}
        />
      </div>
      <div className="multiChoiceComponentXMarkContainer">
        <img className="multiChoiceComponentXMark" src={red_x_mark}/>
        <img
          className="multiChoiceComponentXMarkOnHover"
          src={red_x_mark_onhover}
          onClick={() => dispatch(actions.deleteComponent.request(moduleId, moduleItemId, moduleType, parentId))}
        />
        <CourseCoreDeleteTooltip x={"18%"} y={"-195%"}/>
      </div>
    </div>
  );
};

export default MultiChoiceComponent;
