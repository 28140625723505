import React, { useEffect, useRef } from 'react';

const IntersectionObserverWrapper = ({ callback, children, options }) => {
  if (!callback || !options) {
    console.error('No callback or options passed');
    return null;
  }

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) callback();
    });
  };
  const observer = useRef(
    new IntersectionObserver(handleIntersection, options)
  );
  const targetRef = useRef();

  useEffect(() => {
    if (observer.current && targetRef.current)
      observer.current.observe(targetRef.current);
  }, [targetRef]);

  return <div ref={targetRef}>{children}</div>;
};

export default IntersectionObserverWrapper;
