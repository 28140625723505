import React from 'react';
import Modal from '@material-ui/core/Modal';
import './ResendEmailModal.css';

/**
* ResendEmailModal
*/
const ResendEmailModal = (props) => {
  const { open, onClose, title, text1, text2, leftButtonText, rightButtonText, onLeftButtonClick, onRightButtonClick } = props;
  return (
    <Modal
      id="modal"
      className="resendEmailModal"
      open={open}
      onClose={onClose}
    >
      <div className="resendEmailModalBackground">
        <div className="resendEmailModalExclamation">!</div>
        <div className="resendEmailMainText">{title}</div>
        <div className="resendEmailHelperText">
          {text1}
        </div>
        <div className="resendEmailHelperText">
          {text2}
        </div>
        <div className="resendEmailButtonsContainer">
          <button className="resendEmailModalButton" onClick={onLeftButtonClick}>{leftButtonText}</button>
          <button className="resendEmailModalYesButton" onClick={onRightButtonClick}>{rightButtonText}</button>
        </div>
      </div>
    </Modal>
  );
};

export default ResendEmailModal;
