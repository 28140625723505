import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '../actions';
import magnifying_glass_icon from '../images/svg/magnifying_glass_icon.svg';
import './SearchComponent.css';


/**
* SearchComponent
*/
const SearchComponent = (props) => {
  const dispatch = useDispatch();
  const { name, onChangeDebounced, defaultText, clientId, disabled, value } = props;
  const [text, setText] = useState('');
  useEffect(() => {
    let debouncedSearchFunction = setTimeout(() => {
      dispatch(actions.setSearchValue.request(name, text));
      if (typeof onChangeDebounced === 'function') onChangeDebounced(text);
    }, 500);
    return () => clearTimeout(debouncedSearchFunction);
  }, [text]);
  useEffect(() => {
    setText('');
  }, [clientId]);
  return (
    <div className={disabled ? "searchBarContainer searchBarContainerDisabled" : "searchBarContainer"}>
      <img className="searchIconMagnifyingGlass" src={magnifying_glass_icon} alt="magnifying glass"/>
      <input
        type="text"
        className="searchComponentInput"
        placeholder={defaultText}
        value={value ?? text}
        onChange={(event) => setText(event.target.value)}
        disabled={disabled}
      />
    </div>
  );
};

export default SearchComponent;
