import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import loading_toast_icon from '../images/svg/loading_toast_icon.svg';
import './LoadingToast.css';


/**
* LoadingToast is a standard toast component to show an action is in progress
*/
const LoadingToast = (props) => {
  const { text } = props;
  const [loadingToastClassname, setLoadingToastClassname] = useState("loadingToast loadingToastHidden");
  const toastOrigin = useSelector(state => state.componentStates.toastOrigin);
  const currentPath = window.location.pathname;

  useEffect(() => {
    setLoadingToastClassname("loadingToast loadingToastVisible");
  }, []);

  if (toastOrigin === currentPath || props.shown) {
    return (
      <div className={loadingToastClassname}>
        <div className="loadingToastContent">
          <div className="loadingRotatingIcon">
            <img className="loadingToastIcon" src={loading_toast_icon} alt="loading icon"/>
          </div>
          <div className="loadingToastText">{text}</div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default LoadingToast;
