/**
* sortStudentsByType() sorts the student results according to how far each
* student has come in the program. All categories are exclusive.
* notLoggedInStudents have not viewed a single interaction
* studentsInProgress have done at least one interaction, but not all
* finishedStudents have answered all interactions
*/

export const sortStudentsByType = (students) => {
  let sortedStudents = {
    notLoggedInStudents: [],
    studentsInProgress: [],
    finishedStudents: []
  };

  students.forEach(student => {
    const studentAnswersWithViewDate = student.answers.filter(answer => {
      return answer.viewDate;
    });

    if (studentAnswersWithViewDate.length === 0) {
      sortedStudents.notLoggedInStudents.push(student.answers);
      return;
    }
    if (student.isCourseCompleted) {
      sortedStudents.finishedStudents.push(student.answers);
    }
    else {
      sortedStudents.studentsInProgress.push(student.answers);
    }
  });
  return sortedStudents;
};

/**
* getStudentProgressStatus gives the status of a students progress. The progress
* can be 'Not started', 'In progress', 'Finished'
*/
export const getStudentProgressStatus = (studentAnswers, isCourseCompleted, getClientDataFailure) => {
  const answersWithViewDate = studentAnswers.filter(answer => {
    return answer.viewDate;
  });

  if (answersWithViewDate.length === 0) {
    return 'Not started';
  }
  else if (isCourseCompleted) {
    return 'Finished';
  }
  else if (getClientDataFailure) {
    return 'Error';
  }
  return 'In progress';
};

/**
* getUsersDataUserStatus gives the status of a student's progress. The progress
* can be 'Not started' or 'Active'. Used on the Users Data table within a Program.
*/
export const getUsersDataUserStatus = (user, courseId) => {
  const currentCourse = user.programs.find(program => { return program.programId === courseId; }) || {};
  const userStatus = currentCourse.startDate ? 'Active' : 'Not started';
  return userStatus;
};

/**
* makeLinspaceArray creates an array of specified size with integers
* of equal steps in between
*/
export const makeLinspaceArray = (startValue, stopValue, cardinality) => {
  let array = [];
  let step = (stopValue - startValue) / (cardinality - 1);
  for (let i = 0; i < cardinality; i++) {
    array.push(Math.ceil(startValue + (step * i)));
  }
  return array;
};

/**
* filterStudents filters the students based on company,
* department and status
*/
export const filterStudents = (studentFilter, students, searchString) => {
  let filteredStudents = [...students];
  if (studentFilter.company.length > 0) {
    let takeEmpty = studentFilter.company.includes("No Company");
    filteredStudents = filteredStudents.filter(student => {
      return (!student.company && takeEmpty) || studentFilter.company.includes(student.company);
    });
  }
  if (studentFilter.department.length > 0) {
    let takeEmpty = studentFilter.department.includes("No Department");
    filteredStudents = filteredStudents.filter(student => {
      return (!student.department && takeEmpty) || studentFilter.department.includes(student.department);
    });
  }
  if (studentFilter.status.length > 0) {
    filteredStudents = filteredStudents.filter(student => {
      return studentFilter.status.includes(getStudentProgressStatus(student.answers, student.isCourseCompleted));
    });
  }
  if (studentFilter.certificateStatus.length === 1) {
    let filterQualifiedStudents = studentFilter.certificateStatus[0] === 'Achieved';
    filteredStudents = filteredStudents.filter(student => {
      return student.isCertificateQualified === filterQualifiedStudents;
    });
  }
  if (searchString && searchString.dashboardCourseUserNameOrEmail) {
    filteredStudents = filteredStudents.filter(student => {
      return (
        student.name.toLowerCase().includes(searchString.dashboardCourseUserNameOrEmail.toLowerCase()) ||
        student.email.toLowerCase().includes(searchString.dashboardCourseUserNameOrEmail.toLowerCase())
      );
    });
  }
  return filteredStudents;
};

/**
* filterUsersDataTableStudents filters the students based on company,
* department - used for the Users Data Table
*/
export const filterUsersDataTableStudents = (studentFilter, students, searchString, courseId) => {
  let filteredStudents = [...students];
  if (studentFilter.company.length > 0) {
    let takeEmpty = studentFilter.company.includes("No Company");
    filteredStudents = filteredStudents.filter(student => {
      return (!student.company && takeEmpty) || studentFilter.company.includes(student.company);
    });
  }
  if (studentFilter.department.length > 0) {
    let takeEmpty = studentFilter.department.includes("No Department");
    filteredStudents = filteredStudents.filter(student => {
      return (!student.department && takeEmpty) || studentFilter.department.includes(student.department);
    });
  }
  if (studentFilter.status.length > 0) {
    filteredStudents = filteredStudents.filter(student => {
      return studentFilter.status.includes(getUsersDataUserStatus(student, courseId));
    });
  }
  if (searchString && searchString.courseUsersDataTable) {
    filteredStudents = filteredStudents.filter(student => {
      return (
        student.username.toLowerCase().includes(searchString.courseUsersDataTable.toLowerCase()) ||
        student.email.toLowerCase().includes(searchString.courseUsersDataTable.toLowerCase())
      );
    });
  }
  return filteredStudents;
};

/**
* getInteractionByIndex finds the nth interaction from the chapterlist where
* n is the index
*/
export const getInteractionByIndex = (index, chapters) => {
  if (index === -1) {
    return {};
  }
  let interactionCount = 0;
  for (let i = 0; i < chapters.length; i++) {
    if (interactionCount + chapters[i].children.length <= index) {
      interactionCount += chapters[i].children.length;
    }
    else {
      return chapters[i].children[index - interactionCount];
    }
  }
  return {};
};

/**
* filterStudentAnswersByInteractionId returns all answers with the given
* interaction id
*/
export const filterStudentAnswersByInteractionId = (studentsWithAnswers, programId, interactionId) => {
  let filteredAnswers = [];
  studentsWithAnswers.forEach(({ statuses = {} }) => {
    statuses[programId]?.answers.forEach(answer => {
      if (answer.moduleItemId === interactionId && answer.answers) {
        let answersFromRightInteraction = [...answer.answers];
        filteredAnswers = filteredAnswers.concat(answersFromRightInteraction);
      }
    });
  });
  return filteredAnswers;
};

/**
* filterStudentAnswersByModuleItemId returns all the answers that match with
* the given moduleItemId and removes all unneccessary information
*/
export const filterStudentAnswersByModuleItemId = (component, studentAnswers) => {
  let filteredAnswers = studentAnswers.filter(answer => {
    return answer.moduleItemId === component._id;
  });
  switch (component.type) {
    case 11:
    case 12:
    case 16:
    case 30:
      return filteredAnswers.map(answer => {
        return answer.value;
      });
    case 13:
    case 14:
    case 15:
      return filteredAnswers.map(answer => {
        return answer.moduleItemOptionId;
      });
    default:
      return filteredAnswers;
  }
};


/**
* getListOfInteractionsById returns a list of all interaction id in order
*/
export const getListOfInteractionsById = (chapters) => {
  let listOfInteractions = [];
  chapters.forEach(chapter => {
    chapter.children.forEach(interaction => {
      listOfInteractions.push(interaction._id);
    });
  });
  return listOfInteractions;
};

/**
* calculateAnswerPercentage calculates the answer ratio for each answer
* for components where each option contains an id
*/
export const calculateAnswerPercentage = (options, answers, numberOfAnswerees) => {
  let answerCountArray = new Array(options.length).fill(0);
  answers.forEach(answer => {
    let answerIndex = options.findIndex(option => {
      return option._id === answer;
    });
    answerCountArray[answerIndex] += 1;
  });
  return answerCountArray.map(answerCount => {
    let percentage = Math.round(answerCount / numberOfAnswerees * 100);
    if (isNaN(percentage)) {
      percentage = 0;
    }
    return percentage;
  });
};

/**
* calculateNonOptionAnswerPercentage: calculates percentages per answer on questions that
* do not have options.
*/
export const calculateNonOptionAnswerPercentage = (correctAnswer, answers, numberOfAnswerees) => {
  const uniqueAnswers = [...new Set(answers)];
  let answerCountArray = new Array(uniqueAnswers.length).fill(0);
  answers.forEach(answer => {
    let answerIndex = uniqueAnswers.findIndex(uniqueAnswer => {
      return uniqueAnswer === answer;
    });
    answerCountArray[answerIndex] += 1;
  });
  return answerCountArray.map(answerCount => {
    let percentage = Math.round(answerCount / numberOfAnswerees * 100);
    if (isNaN(percentage)) {
      percentage = 0;
    }
    return percentage;
  });
};

/**
* calculateSliderAnswerPercentage calculates the answer ratio for each answer
* for the slider component
*/
export const calculateSliderAnswerPercentage = (options, answers) => {
  let answerCountArray = new Array(options.length).fill(0);
  answers.forEach(answer => {
    let answerIndex = options.findIndex(option => {
      return option === answer;
    });
    answerCountArray[answerIndex] += 1;
  });
  return answerCountArray.map(answerCount => {
    let percentage = Math.round(answerCount / answers.length * 100);
    if (isNaN(percentage)) {
      percentage = 0;
    }
    return percentage;
  });
};

/**
* sortAnswersByAnswerPercentage
*/
export const sortAnswersByAnswerPercentage = (uniqueAnswers, answerRatios) => {
  let sortingArray = uniqueAnswers.map((answer, index) => {
    return {
      answer: answer,
      ratio: answerRatios[index]
    };
  });
  sortingArray.sort((a, b) => {
    if (a.ratio < b.ratio) {
      return 1;
    }
    else {
      return -1;
    }
  });
  const sortedUniqueAnswers = sortingArray.map(sortObject => {
    return sortObject.answer;
  });
  const sortedAnswerRatios = sortingArray.map(sortObject => {
    return sortObject.ratio;
  });
  return { sortedUniqueAnswers, sortedAnswerRatios };
};


/**
* stableSort, getSorting and desc are functions used to sort tables without
* having to make a new call to the Backend, used for tables without
* pagination
*/
export const stableSort = (array, cmp) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

export const getSorting = (order, orderBy) => {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
};

const desc = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

/**
* sortList takes an array and one element: it will sort the array elements as usual, but the element passed on
* will always remain as the first element of the array
*/
export const sortList = (array, makeFirst) => {
  array.sort((a, b) => {
    if(a === b) return 0;
    if (a === makeFirst) return -1;
    if (b === makeFirst) return 1;
  
    if (a < b)
        return -1;
    if (a > b )
        return 1;
    return 0;
  });
  return array;
};
