export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(value)
    ? 'Invalid email address'
    : undefined;

export const password = value =>
  !value
    ? 'Please enter your password'
    : undefined;

export const verificationCode = value =>
  !value
    ? 'Please enter your verification code'
    : undefined;

export const hasMinLength = value =>
  !(value && value.length >= 8) ?
    "The password must have at least 8 characters"
    : undefined;

export const containsNumber = value =>
  !(value && /(.*\d.*)/.test(value)) ?
    "The password must contain a number"
    : undefined;

export const containsUppercaseLetter = value =>
  !(value && /(.*[A-Z].*)/.test(value)) ?
    "The password must must contain at least one upper case letter"
    : undefined;

export const containsLowercaseLetter = value =>
  !(value && /(.*[a-z].*)/.test(value)) ?
    "The password must contain at least one lower case letter"
    : undefined;

export const name = value =>
  !value
    ? 'Please enter a name'
    : undefined;

export const title = value =>
  !value
    ? 'Please enter a title'
    : undefined;

export const hasMaxClientNameLength = value =>
  !(value && value.length <= 50)
    ? 'Name cannot be longer than 50 characters'
    : undefined;

export const hasNumberInRange = (minNumber, maxNumber) => value =>
  !(value && value >= minNumber && value <= maxNumber)
    ? 'Please enter a value between ' + minNumber + ' and ' + maxNumber
    : undefined;

export const required = value =>
  !value
    ? 'Required'
    : undefined;

/**
* composeValidators is a helper function for react-final-form to give
* validation functions to Field components
*/
export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);
