import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '../../actions';
import CourseCoreMoveTooltip from './CourseCoreMoveTooltip';
import CourseCoreDeleteTooltip from './CourseCoreDeleteTooltip';
import AnswerWideTextarea from './AnswerWideTextarea';
import headline_icon from '../../images/svg/headline_icon.svg';
import draggable_handle from '../../images/svg/draggable_handle.svg';
import draggable_handle_onhover from '../../images/svg/draggable_handle_onhover.svg';
import red_x_mark from '../../images/svg/red_x_mark.svg';
import red_x_mark_onhover from '../../images/svg/red_x_mark_onhover.svg';
import './CorrectSpellingComponent.css';

/**
* CorrectSpellingComponent
*/

const CorrectSpellingComponent = ({
  moduleId,
  moduleItemId,
  moduleType,
  provided,
  numberOfComponents,
  parentId,
  autoFocus,
  questionText,
  answerText,
  score
}) => {
  const dispatch = useDispatch();
  const [localQuestionText, setLocalQuestionText] = useState(questionText);
  const [localAnswer, setLocalAnswer] = useState(answerText);
  const [questionPoints, setQuestionPoints] = useState(score >= 0 ? score : 0);
  const [hasLoaded, setHasLoaded] = useState(false);
  useEffect(() => {
    let debouncedSaveFunction = setTimeout(
      () => {
        if (hasLoaded) {
          dispatch(actions.updateComponent.request(
            moduleId,
            moduleItemId,
            {
              text: localQuestionText,
              textCorrect: localAnswer
            },
            moduleType,
            { score: questionPoints }
          ));
        }
        setHasLoaded(true);
      },
      500
    );
    return () => clearTimeout(debouncedSaveFunction);
  }, [questionPoints, localQuestionText, localAnswer]);

  const handleSetQuestionPoints = (newQuestionPoints) => {
    if (newQuestionPoints < 0) {
      setQuestionPoints(0);
    }
    else if (newQuestionPoints > 1000) {
      setQuestionPoints(1000);
    }
    else {
      setQuestionPoints(newQuestionPoints);
    }
  };
  return (
    <div className="correctSpellingContainer">
      <img
        className={numberOfComponents === 1 ? "correctSpellingIcon correctSpellingIconAlone" : "correctSpellingIcon"}
        src={headline_icon}
      />
      {numberOfComponents > 1 &&
        <div className="correctSpellingTooltipParent" {...provided.dragHandleProps}>
          <img className="correctSpellingDraggableHandle" src={draggable_handle}/>
          <img className="correctSpellingDraggableHandleOnHover" src={draggable_handle_onhover}/>
          <CourseCoreMoveTooltip x={"-126%"} y={"-195%"}/>
        </div>
      }
      <div className="correctSpellingWrapper">
        <div className="correctSpellingTitleWrapper">
          <img className="correctSpellingTitleIcon" src={headline_icon}/>
          <div className="correctSpellingTitleText">Correct spelling</div>
        </div>
        <div className="correctSpellingSubtitleText">QUESTION</div>
        <AnswerWideTextarea
          text={localQuestionText}
          setNewText={setLocalQuestionText}
          autoFocus={autoFocus}
        />
        <div className="correctSpellingSubtitleText">CORRECT ANSWER</div>
        <AnswerWideTextarea
          text={localAnswer}
          setNewText={setLocalAnswer}
          correctAnswer="true"
        />
        <div className="correctSpellingPointsText">POINTS FOR CORRECT ANSWER (MAX 1000)</div>
        <input
          type="number"
          className="correctSpellingPointsInput"
          min="0"
          max="1000"
          value={questionPoints}
          onChange={(event) => handleSetQuestionPoints(event.target.value)}
        />
      </div>
      <div className="correctSpellingXMarkContainer">
        <img className="correctSpellingXMark" src={red_x_mark}/>
        <img
          className="correctSpellingXMarkOnHover"
          src={red_x_mark_onhover}
          onClick={() => dispatch(actions.deleteComponent.request(moduleId, moduleItemId, moduleType, parentId))}
        />
        <CourseCoreDeleteTooltip x={"18%"} y={"-195%"}/>
      </div>
    </div>
  );
};

export default CorrectSpellingComponent;
