import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import * as actions from '../../actions';
import ImageUploadLoadingAnimation from './ImageUploadLoadingAnimation';
import './MultipleImagesDropzone.css';

/**
* MultipleImagesDropzone: Image Dropzone component where the images can be uploaded which will
* be added to the Image Carousel
*/

const MultipleImagesDropzone = (props) => {
  const dispatch = useDispatch();
  const { moduleId, moduleItemId, moduleType } = props;
  const imageLoadingAnimationOrigin = useSelector(state => state.componentStates.imageLoadingAnimationOrigin);
  const currentPath = window.location.pathname.split('/');

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles[0]) {
      const reader = new FileReader();
      reader.onload = () => {
        const binaryStr = reader.result;
        dispatch(actions.addImageCarouselImage.request(moduleId, moduleItemId, binaryStr, moduleType));
      };
      reader.readAsArrayBuffer(acceptedFiles[0]);
    }
    dispatch(actions.setImageUploadToastOrigin.request(currentPath[3]));
    dispatch(actions.setImageLoadingAnimationOrigin.request(moduleItemId));
  }, []);
  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png, image/jpg, image/svg',
    maxfiles: 1
  });

  return (
    <div className="multipleImagesDropzoneContainer">
      {renderEmptyDropzone(isDragActive, isDragReject, getRootProps, getInputProps, imageLoadingAnimationOrigin, moduleItemId)}
    </div>
  );
};

const renderEmptyDropzone = (isDragActive, isDragReject, getRootProps, getInputProps, imageLoadingAnimationOrigin, moduleItemId) => {
  const multiDropzone = document.getElementById("multiDropzone");
  if(multiDropzone) {
    multiDropzone.removeAttribute("multiple");
  }
  
  return (
    <div className="multipleImagesEmptyDropzone" {...getRootProps()}>
      <input id="multiDropzone" {...getInputProps()}/>
      {isDragActive ?
        renderDropzoneOverlay(isDragReject)
        :
        <>
          <div className="multipleImagesEmptyDropzoneText">
            Drop image here to upload
          </div>
          <div className="multipleImagesEmptyDropzoneHelperText">
            or <span className="multipleImagesEmptyDropzoneTextUnderline">browse</span> to choose a file
          </div>
        </>
      }
      {imageLoadingAnimationOrigin === moduleItemId && <ImageUploadLoadingAnimation/>}
    </div>
  );
};

const renderDropzoneOverlay = (isDragReject) => {
  return (
    <div className="multipleImagesImageOverlay">
      { isDragReject ?
        <>
          <div className="multipleImagesOverlayText">
            Moblrn only supports .jpg, .jpgz, .png, .svg
          </div>
        </>
        :
        <>
          <div className="multipleImagesOverlayText">
            Drop image here to upload
          </div>
        </>
      }
    </div>
  );
};

export default MultipleImagesDropzone;
