import React from 'react';
import { useSelector } from 'react-redux';
import AutosaveComponent from './AutosaveComponent';
import AutosaveSavedComponent from './AutosaveSavedComponent';
import AutosaveSavingComponent from './AutosaveSavingComponent';

/**
* SavingProgressComponent
*/

const SavingProgressComponent = () => {
  const savingStatus = useSelector(state => state.componentStates.savingStatus);

  return (
    <>
      {savingStatus === 'default' && <AutosaveComponent/>}
      {savingStatus === 'saving' && <AutosaveSavingComponent/>}
      {savingStatus === 'saved' && <AutosaveSavedComponent/>}
    </>
  );
};

export default SavingProgressComponent;
