import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '../../actions';
import CourseCoreMoveTooltip from './CourseCoreMoveTooltip';
import CourseCoreDeleteTooltip from './CourseCoreDeleteTooltip';
import AnswerChoiceTextarea from './AnswerChoiceTextarea';
import one_choice_icon from '../../images/svg/one_choice_icon.svg';
import draggable_handle from '../../images/svg/draggable_handle.svg';
import draggable_handle_onhover from '../../images/svg/draggable_handle_onhover.svg';
import red_x_mark from '../../images/svg/red_x_mark.svg';
import red_x_mark_onhover from '../../images/svg/red_x_mark_onhover.svg';
import './OneChoiceComponent.css';

/**
* OneChoiceComponent: Lesson question component which has radio buttons for the
* different answer choices and only one can be chosen
*/

const OneChoiceComponent = ({
  moduleId,
  moduleItemId,
  moduleType,
  provided,
  numberOfComponents,
  parentId,
  autoFocus,
  options,
  score
}) => {
  const dispatch = useDispatch();
  const [correctOptionId, setCorrectOptionId] = useState('');
  const [hasLoaded, setHasLoaded] = useState(false);
  const [questionPoints, setQuestionPoints] = useState(score >= 0 ? score : 0);
  useEffect(() => {
    let correctOptionId = '';
    options.forEach(option => {
      if (option.isCorrect) {
        correctOptionId = option._id;
      }
    });
    setCorrectOptionId(correctOptionId);
  }, [options]);
  useEffect(() => {
    let debouncedSaveFunction = setTimeout(
      () => {
        if (hasLoaded) {
          dispatch(actions.updateComponent.request(moduleId, moduleItemId, {}, moduleType, { score: questionPoints }));
        }
        setHasLoaded(true);
      },
      500
    );
    return () => clearTimeout(debouncedSaveFunction);
  }, [questionPoints]);
  const handleChangeCorrectAnswer = (newCorrectOptionId) => {
    if (correctOptionId !== newCorrectOptionId) {
      const oldCorrectOption = options.find(option => { return option._id === correctOptionId; });
      const newCorrectOption = options.find(option => { return option._id === newCorrectOptionId; });
      dispatch(actions.editOneChoiceOption.request({ ...oldCorrectOption, isCorrect: false }, { ...newCorrectOption, isCorrect: true }));
      setCorrectOptionId(newCorrectOptionId);
    }
  };
  const handleSetQuestionPoints = (newQuestionPoints) => {
    if (newQuestionPoints < 0) {
      setQuestionPoints(0);
    }
    else if (newQuestionPoints > 1000) {
      setQuestionPoints(1000);
    }
    else {
      setQuestionPoints(newQuestionPoints);
    }
  };
  return (
    <div className="oneChoiceComponentContainer">
      <img
        className={numberOfComponents === 1 ? "oneChoiceComponentIcon oneChoiceComponentIconAlone" : "oneChoiceComponentIcon"}
        src={one_choice_icon}
      />
      {numberOfComponents > 1 &&
        <div className="oneChoiceComponentTooltipParent" {...provided.dragHandleProps}>
          <img className="oneChoiceComponentDraggableHandle" src={draggable_handle}/>
          <img className="oneChoiceComponentDraggableHandleOnHover" src={draggable_handle_onhover}/>
          <CourseCoreMoveTooltip x={"-126%"} y={"-195%"}/>
        </div>
      }
      <div className="oneChoiceWrapper">
        <div className="oneChoiceTitleWrapper">
          <img className="oneChoiceTitleIcon" src={one_choice_icon}/>
          <div className="oneChoiceTitleText">One choice</div>
        </div>
        <div className="oneChoiceAnswersTitleWrapper">
          <div className="oneChoiceAnswersTitleText">OPTIONS</div>
          <div className="oneChoiceAnswersTitleText">CORRECT</div>
        </div>
        <div>
          {options.map((option, index) => {
            return (
              <div className="oneChoiceAnswerAndDelete" key={option._id}>
                <div className="oneChoiceTextAndRadio">
                  <AnswerChoiceTextarea
                    option={option}
                    isCorrectOption={correctOptionId === option._id}
                    autoFocus={autoFocus && index === options.length - 1 || option.autoFocus}
                  />
                  <div className="oneChoiceRadioButtonContainer">
                    <label htmlFor={option._id}>
                      <input
                        type="radio"
                        name={moduleItemId}
                        id={option._id}
                        value={option._id}
                        onChange={(event) => handleChangeCorrectAnswer(event.target.value)}
                        checked={correctOptionId === option._id}
                      />
                      <span className="oneChoiceCustomRadioButton"></span>
                    </label>
                  </div>
                </div>
                <div className="oneChoiceRadioButtonXMarkWrapper">
                  <img className="oneChoiceRadioButtonXMark" src={red_x_mark}/>
                  <img
                    className="oneChoiceRadioButtonXMarkOnHover"
                    src={red_x_mark_onhover}
                    onClick={() => dispatch(actions.deleteOption.request(moduleId, moduleItemId, option._id))}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <button
          className="oneChoiceAddAnswerButton"
          type="button"
          onClick={() => dispatch(actions.addOption.request(moduleId, moduleItemId))}
        >
          + Add option
        </button>
        <div className="oneChoicePointsText">POINTS FOR CORRECT ANSWER (MAX 1000)</div>
        <input
          type="number"
          className="oneChoicePointsInput"
          min="0"
          max="1000"
          value={questionPoints}
          onChange={(event) => handleSetQuestionPoints(event.target.value)}
        />
      </div>

      <div className="oneChoiceComponentXMarkContainer">
        <img className="oneChoiceComponentXMark" src={red_x_mark}/>
        <img
          className="oneChoiceComponentXMarkOnHover"
          src={red_x_mark_onhover}
          onClick={() => dispatch(actions.deleteComponent.request(moduleId, moduleItemId, moduleType, parentId))}
        />
        <CourseCoreDeleteTooltip x={"18%"} y={"-195%"}/>
      </div>
    </div>
  );
};

export default OneChoiceComponent;
