import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import * as actions from '../../actions';
import { Form, Field } from 'react-final-form';
import {
  name,
  composeValidators
} from '../../utils/validationUtils';
import PageBreadCrumbs from '../styleguideComponents/PageBreadCrumbs';
import TextInputField from '../styleguideComponents/TextInputField';
import FinalFormRadioButton from '../styleguideComponents/FinalFormRadioButton';
import MultiselectCheckbox from '../styleguideComponents/MultiselectCheckbox';
import ButtonSecondaryOutlined from '../styleguideComponents/ButtonSecondaryOutlined';
import ButtonPrimaryBlue from '../styleguideComponents/ButtonPrimaryBlue';
import ButtonSecondaryOutlinedRed from '../styleguideComponents/ButtonSecondaryOutlinedRed';
import WarningModal from '../WarningModal';
import save_changes_icon_white from '../../images/styleguideComponentsImages/save_changes_icon_white.svg';
import trashbin_icon_white from '../../images/styleguideComponentsImages/trashbin_icon_white.svg';
import trashbin_icon_red from '../../images/styleguideComponentsImages/trashbin_icon_red.svg';
import trashbin_icon_disabled from '../../images/styleguideComponentsImages/trashbin_icon_disabled.svg';
import './EditAdminForm.css';

/**
* EditAdminForm: only the Admin's name and admin settings (permissions) are editable
*/
const EditAdminForm = () => {
  const dispatch = useDispatch();
  const toastActive = useSelector(state => state.componentStates.toastActive);
  const loadingState = useSelector(state => state.componentStates.loadingToastActive);
  const submitButtonDisabled = useSelector(state => state.componentStates.submitButtonDisabled);
  const [deletingAdmin, setDeletingAdmin] = useState(false);

  const { id } = useParams();
  const adminToEdit = useSelector(state => state.admins.adminToEdit);
  const isSuperAdmin = adminToEdit.isSuperAdmin;

  useEffect(() => {
    if (!deletingAdmin) {
      dispatch(actions.getAdmin.request(id));
    }
  }, [id, toastActive]);

  const [permissionRead, setPermissionRead] = useState();
  const [permissionEdit, setPermissionEdit] = useState();
  const [permissionInvite, setPermissionInvite] = useState();
  const [permissionFullRights, setPermissionFullRights] = useState();
  const [openDeleteAdminModal, setOpenDeleteAdminModal] = useState(false);
  const adminToDeleteName = adminToEdit ? adminToEdit.username : "";

  useEffect(() => {
    if (adminToEdit.permissions && adminToEdit.permissions[0]) {
      setPermissionFullRights(adminToEdit.permissions[0].fullRights);
      setPermissionRead(adminToEdit.permissions[0].read);
      setPermissionEdit(adminToEdit.permissions[0].edit);
      setPermissionInvite(adminToEdit.permissions[0].invite);
    }
  }, [adminToEdit]);

  const permissionsObject = {
    read: permissionRead,
    edit: permissionEdit,
    invite: permissionInvite,
    fullRights: permissionFullRights
  };

  useEffect(() => {
    if (permissionFullRights) {
      setPermissionRead(false);
      setPermissionEdit(false);
      setPermissionInvite(false);
    }
  }, [permissionFullRights]);

  const handleToggleFullRights = () => setPermissionFullRights(!permissionFullRights);

  const handleTogglePermissionRead = () => {
    setPermissionRead(!permissionRead);
    setPermissionFullRights(false);
  };

  const handleTogglePermissionEdit = () => {
    setPermissionEdit(!permissionEdit);
    setPermissionFullRights(false);
  };

  const handleTogglePermissionInvite = () => {
    setPermissionInvite(!permissionInvite);
    setPermissionFullRights(false);
  };

  const isPermissionSelected = permissionRead || permissionEdit || permissionInvite || permissionFullRights;
  const havePermissionsChanged = () => {
    if (adminToEdit.permissions && adminToEdit.permissions[0]) {
      return (
        adminToEdit.permissions[0].fullRights !== permissionFullRights ||
        adminToEdit.permissions[0].read !== permissionRead ||
        adminToEdit.permissions[0].edit !== permissionEdit ||
        adminToEdit.permissions[0].invite !== permissionInvite
      );
    }
  };

  const handleSubmit = (data) => {
    dispatch(actions.editAdmin.request(
      id,
      data.editAdminName,
      adminToEdit.email,
      permissionsObject,
      isSuperAdmin
    ));
    dispatch(actions.setToastOrigin.request(`/admin/${id}`));
  };

  return (
    <>
      <PageBreadCrumbs labelsArray={[{text: "Admins", link: "/admins"}, {text: "Edit admin"}]} />
      <div className="editAdminFormTitle">EDIT ADMIN</div>
      <Form
        onSubmit={(data) => handleSubmit(data)}
          initialValues={{
          editAdminName: adminToEdit.username
        }}
      >
        {({ handleSubmit, values }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Field
              name="editAdminName"
              validate={composeValidators(name)}
            >
              {({ input, meta }) => (
                <TextInputField
                  label="Name"
                  input={input}
                  meta={meta}
                  prefilledValue={values.editAdminName || ''}
                  calculateTextLength={calculateNameLength}
                  mustHaveCounter={true}
                  type="text"
                  minLength="1"
                  maxLength="30"
                  required={true}
                />
              )}
            </Field>
            <div className="editAdminEmailFieldContainer">
              <TextInputField
                label="Email"
                prefilledValue={adminToEdit.email || ''}
                mustHaveCounter={false}
                type="email"
                readOnly={true}
                disabled={true}
              />
            </div>
            <div className="editAdminFieldContainer">
              <div className="editAdminSubTitle editAdminSubTitleDisabled">Admin type</div>
              <div className="editAdminTypeButtonsContainer">
                <div className="editAdminTypeTopButton">
                  <FinalFormRadioButton
                    label="Client admin"
                    name="adminType"
                    value="clientAdmin"
                    disabled={true}
                    checked={!adminToEdit.isSuperAdmin}
                  />
                </div>
                <FinalFormRadioButton
                  label="Super admin"
                  name="adminType"
                  value="clientAdmin"
                  disabled={true}
                  checked={!!adminToEdit.isSuperAdmin}
                />
              </div>
            </div>
            {!adminToEdit.isSuperAdmin &&
              <>
                <div className="editAdminFieldContainer">
                  <div className="editAdminSubTitle editAdminSubTitleDisabled">Client</div>
                  <div className="editAdminClientDropdownContainer">
                    <Dropdown
                      placeholder={adminToEdit.clientName}
                    />
                  </div>
                </div>
                <div className="editAdminSubTitle">Client admin settings</div>
                <div className="editAdminSettingsCheckboxesWrapper">
                  <MultiselectCheckbox
                    label='Full rights'
                    onClick={handleToggleFullRights}
                    checked={permissionFullRights}
                  />
                  <MultiselectCheckbox
                    label='Read'
                    onClick={handleTogglePermissionRead}
                    checked={permissionRead}
                  />
                  <MultiselectCheckbox
                    label='Edit'
                    onClick={handleTogglePermissionEdit}
                    checked={permissionEdit}
                  />
                  <MultiselectCheckbox
                    label='Invite'
                    onClick={handleTogglePermissionInvite}
                    checked={permissionInvite}
                  />
                </div>
              </>
            }
            <div className="editAdminActionButtonsWrapper">
              <div className="editAdminButtonSpacing">
                <Link className="editAdminCancelButton" to="/admins" exact="true">
                  <ButtonSecondaryOutlined type="button" label="Cancel" />
                </Link>
              </div>
              <div className="editAdminButtonSpacing">
                <ButtonSecondaryOutlinedRed
                  type="button"
                  label="Delete admin"
                  disabled={loadingState}
                  onClick={() => setOpenDeleteAdminModal(true)}
                  iconSource={trashbin_icon_red}
                  iconSourceOnHover={trashbin_icon_white}
                  iconSourceDisabled={trashbin_icon_disabled}
                />
              </div>
              <ButtonPrimaryBlue
                type="submit"
                label={loadingState ? "Saving changes" : "Save changes"}
                disabled={
                  isSuperAdmin ?
                  (!values.editAdminName || !isPermissionSelected) &&
                  adminToEdit.username === values.editAdminName ||
                  submitButtonDisabled
                  :
                  (!values.editAdminName || !isPermissionSelected) ||
                  !havePermissionsChanged() &&
                  adminToEdit.username === values.editAdminName ||
                  submitButtonDisabled
                }
                iconSource={save_changes_icon_white}
                loading={loadingState}
              />
            </div>
          </form>
        )}
      </Form>
      <WarningModal
        open={openDeleteAdminModal}
        onClose={() => setOpenDeleteAdminModal(false)}
        title='Delete admin'
        text1={`Are you sure you want to delete the admin "${adminToDeleteName}"?`}
        text2='The admin will be deleted and CAN NOT be recovered'
        leftButtonText='NO, CANCEL'
        rightButtonText='YES, DELETE'
        onLeftButtonClick={() => setOpenDeleteAdminModal(false)}
        onRightButtonClick={() => handleDeleteAdmin(setOpenDeleteAdminModal, setDeletingAdmin, dispatch, id)}
      />
    </>
  );
};

const calculateNameLength = (name) => {
  if (!name) {
    return 0;
  } else {
    return name.length;
  }
};

const Dropdown = (props) => {
  return (
    <select
      defaultValue={"default"}
      className="editAdminSelectDisabled"
      disabled
    >
      <option value="default" disabled hidden>{props.placeholder}</option>
    </select>
  );
};

const handleDeleteAdmin = (setOpenDeleteAdminModal, setDeletingAdmin, dispatch, adminId) => {
  const source = "editAdminPage";
  setOpenDeleteAdminModal(false);
  setDeletingAdmin(true);
  dispatch(actions.deleteUser.request(adminId, null, null, null, null, null, null, null, source));
};

export default EditAdminForm;
