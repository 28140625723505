import React from 'react';
import autosave_checkmark_icon from '../../images/svg/autosave_checkmark_icon.svg';
import autosave_cloud_icon from '../../images/svg/autosave_cloud_icon.svg';
import './AutosaveComponent.css';

/**
* AutosaveComponent: Progress icon that will show on the course core pages
* showing the status on the Autosave progress. This one is the default state.
*/
const AutosaveComponent = () => {
  return (
    <div className="autoSaveWrapper">
      <div className="autoSaveIconsWrapper">
        <img className="autoSaveCheckmark" src={autosave_checkmark_icon}/>
        <img className="autoSaveCloud" src={autosave_cloud_icon}/>
      </div>
    </div>
  );
};

export default AutosaveComponent;
