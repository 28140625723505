import React from 'react';
import update_status_icon from '../../images/svg/update_status_icon.svg';
import './UpdateUserStatusCacheButton.css';

/**
 * UpdateUserStatusCacheButton: button used within the update user
 * status cache flow in order to trigger a manual update
 */
const UpdateUserStatusCacheButton = ({ onClick }) => {
  return (
    <div className="updateStatusButtonWrapper" onClick={onClick}>
      <img
        className="updateStatusButtonImage"
        src={update_status_icon}
        alt="update icon"
      />
      <div className="updateStatusButtonText">Force update status</div>
    </div>
  );
};

export default UpdateUserStatusCacheButton;
