const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';

/**
* action() is a helper function to create action objects
*/
export function action(type, payload = {}) {
  return {
    type,
    ...payload
  };
}

/**
* createRequestTypes() creates a request type for each action
*/
export function createRequestTypes(base) {
  return [REQUEST, SUCCESS, FAILURE].reduce((acc, type) => {
    acc[type] = `${base}_${type}`;
    return acc;
  }, {});
}
