import React from 'react';
import autosave_saving_arrows from '../../images/svg/autosave_saving_arrows.svg';
import './AutosaveReconnectingComponent.css';

/**
* AutosaveReconnectingComponent: icon used on the course core to show the connection status
*/
const AutosaveReconnectingComponent = () => {
  return (
    <div className="autoSaveReconnectingWrapper">
      <img className="autoSaveReconnectingArrows" src={autosave_saving_arrows}/>
      <div className="autoSaveReconnectingText">Reconnecting...</div>
    </div>
  );
};

export default AutosaveReconnectingComponent;
