import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useOutsideClick } from '../../utils/componentUtils';
import * as actions from '../../actions';
import './commonCourseCoreComponents.css';

/**
* ModuleTitleField is a reusable input type text component with a
* customizable default text and placeholder text for a module title
*/
export const ModuleTitleField = ({ titleText, placeholder, moduleId }) => {
  const dispatch = useDispatch();
  const [localText, setLocalText] = useState(titleText);
  const [hasLoaded, setHasLoaded] = useState(false);
  useEffect(() => {
    if (!hasLoaded) {
      setLocalText(titleText);
    }
    let debouncedSaveFunction = setTimeout(
      () => {
        if (hasLoaded) {
          dispatch(actions.updateModule.request(moduleId, { name: localText }));
        }
        setHasLoaded(true);
      },
      500
    );
    return () => clearTimeout(debouncedSaveFunction);
  }, [localText, titleText]);
  return (
    <input
      className="pageTitleInput"
      type="text"
      minLength="1"
      maxLength="30"
      placeholder={placeholder}
      value={localText}
      onChange={(event) => setLocalText(event.target.value)}
    />
  );
};

/**
* MenuTimelineTitleField is a reusable input type text component for the side menu with a
* customizable default text and placeholder text, specifically used for the Timeline module
*/
export const MenuTimelineTitleField = React.forwardRef(function MenuTitleField(props, ref) {
  const { titleText, placeholder, moduleId, moduleContentLoaded } = props;
  const dispatch = useDispatch();
  const [localText, setLocalText] = useState('');

  useEffect(() => {
    if (moduleContentLoaded) {
      setLocalText(titleText);
    }
  }, [moduleContentLoaded, titleText]);

  useEffect(() => {
    if (localText.length > 0 && moduleContentLoaded && localText !== titleText) {
      const thread = setTimeout(() => {
        dispatch(actions.updateModule.request(moduleId, { name: localText }));
      }, 1000);
      return () => clearTimeout(thread);
    }
  }, [localText, titleText, moduleContentLoaded]);

  return (
    <input
      className="menuTitleInput"
      type="text"
      minLength="1"
      maxLength="30"
      placeholder={placeholder}
      value={localText}
      onChange={(event) => setLocalText(event.target.value)}
      ref={ref}
    />
  );
});

/**
* MenuTitleField is a reusable input type text component for the side menu with a
* customizable default text and placeholder text
*/
export const MenuTitleField = React.forwardRef((props, ref) => {
  const { titleText, placeholder, moduleId } = Object.assign({}, props);
  const dispatch = useDispatch();
  const [localText, setLocalText] = useState(titleText);
  const [hasLoaded, setHasLoaded] = useState(false);
  useEffect(() => {
    if (!hasLoaded) {
      setLocalText(titleText);
    }
    let debouncedSaveFunction = setTimeout(
      () => {
        if (hasLoaded) {
          dispatch(actions.updateModule.request(moduleId, { name: localText }));
        }
        setHasLoaded(true);
      },
      500
    );
    return () => clearTimeout(debouncedSaveFunction);
  }, [localText, titleText]);
  return (
    <input
      className="menuTitleInput"
      type="text"
      minLength="1"
      maxLength="30"
      placeholder={placeholder}
      value={localText}
      onChange={(event) => setLocalText(event.target.value)}
      ref={ref}
    />
  );
});

/**
* MenuItemTitleField is a reusable input type text component for the side menu with a
* customizable default text and placeholder text
*/
export const MenuItemTitleField = ({ item, placeholder, moduleId, moduleType, setCurrentlyEditedMenuItemId }) => {
  const dispatch = useDispatch();
  const pageMenuTitleRef = useRef();
  const [localText, setLocalText] = useState(item.data.text || '');
  useEffect(() => {
    let debouncedSaveFunction = setTimeout(
      () => {
        if (localText !== item.data.text) {
          dispatch(actions.updateComponent.request(moduleId, item._id, { text: localText }, moduleType, item));
        }
      },
      300
    );
    return () => clearTimeout(debouncedSaveFunction);
  }, [localText, item]);
  useOutsideClick(pageMenuTitleRef, () => {
    dispatch(actions.toggleEditMenuPageTitle.request(false));
    setTimeout(() => setCurrentlyEditedMenuItemId(''), 0);
  });
  const exitOnEnter = (event) => {
    if(event.keyCode === 13) {
      setCurrentlyEditedMenuItemId('');
    }
  };
  return (
    <input
      className={placeholder === "New chapter" ? "courseModuleChapterTitleInput" : "courseModuleMenuItemInput"}
      type="text"
      minLength="1"
      maxLength="30"
      placeholder={placeholder}
      value={localText}
      onChange={(event) => setLocalText(event.target.value)}
      onKeyDown={(event) => exitOnEnter(event)}
      ref={pageMenuTitleRef}
      autoFocus
    />
  );
};

/**
* PageTitleField is a reusable input type text component with a
* customizable default text and placeholder text for a page component
*/
export const PageTitleField = ({ titleText, placeholder, moduleId, pageId, moduleType, item }) => {
  const dispatch = useDispatch();
  const [localText, setLocalText] = useState(titleText);
  const [previousPageId, setPreviousPageId] = useState(titleText);
  const [hasLoaded, setHasLoaded] = useState(false);
  useEffect(() => {
    setLocalText(titleText);
  }, [titleText]);
  useEffect(() => {
    let debouncedSaveFunction = setTimeout(
      () => {
        if (hasLoaded && previousPageId === pageId) {
          dispatch(actions.updateComponent.request(moduleId, pageId, { text: localText }, moduleType, item));
        }
        setPreviousPageId(pageId);
        setHasLoaded(true);
      },
      500
    );
    return () => clearTimeout(debouncedSaveFunction);
  }, [localText]);
  return (
    <input
      className="pageTitleInput"
      type="text"
      minLength="1"
      maxLength="30"
      placeholder={placeholder}
      value={localText}
      onChange={(event) => setLocalText(event.target.value)}
    />
  );
};
