import { create } from 'zustand';
import { useMutation } from 'react-query';
import API from '../api/api';

export const useStore = create((set) => ({
  setState: set,
  statusById: {},
  errorMessageById: {},
}));

export const useAddUserToProgram = () => {
  const { setState, statusById, errorMessageById } = useStore();
  const getMutationId = ({ programId, email }) => programId + email;

  const { mutate } = useMutation(addUserToCourse, {
    onMutate: (variables) => {
      const id = getMutationId(variables);
      setState((state) => ({
        statusById: { ...state.statusById, [id]: 'loading' },
      }));
    },
    onError: (error, variables) => {
      const id = getMutationId(variables);
      setState((state) => ({
        statusById: { ...state.statusById, [id]: 'error' },
        errorMessageById: {
          ...state.errorMessageById,
          [id]: error.response.data.msg,
        },
      }));
    },
    onSuccess: (data, variables) => {
      const id = getMutationId(variables);
      setState((state) => ({
        statusById: { ...state.statusById, [id]: 'success' },
      }));
    },
  });

  return {
    mutate,
    statusById,
    getMutationId,
    errorMessageById,
  };
};

async function addUserToCourse({
  programId,
  username,
  email,
  company,
  department,
}) {
  const res = await API.addUserToCourse({
    programId,
    username,
    email,
    company,
    department,
  });
  return res.data;
}
