import React, { useEffect, useState } from 'react';
import { getYearMonthDayFromDate, getTimeFromDate } from '../utils/dateUtils';
import Modal from '@material-ui/core/Modal';
import unpublish_icon from '../images/svg/unpublish_icon.svg';
import publish_icon from '../images/svg/publish_icon.svg';
import './PublishModal.css';
import { useParams } from 'react-router';
import API from '../api/api';

/**
* PublishModal is a modal informing the user what happens if they publish or
* unpublish the course and asks them if they want to continue
*/
const PublishModal = (props) => {
  const { open, isPublished, courseName, onClose, onLeftButtonClick, onRightButtonClick, courseType, startDate } = props;
  
  const { courseId } = useParams();
  const [usersCount, setUsersCount] = useState('...');
  const fetchUserCount = async () => {
    try {
      const { data } = await API.getUsersInCourseCount({ courseId });
      setUsersCount(data.usersTotalCount);
    } catch(e) {
      setUsersCount(0);
    }
  };
  
  useEffect(() => {
    if (open) fetchUserCount();
  }, [open]);

  return (
    <Modal
      id="modal"
      className="publishModal"
      open={open}
      onClose={onClose}
    >
      <div className="publishModalBackground">
        <img className="publishModalIcon" src={isPublished ? unpublish_icon : publish_icon}/>
        <div className={isPublished ? "publishModalMainText publishModalUnpublishText" : "publishModalMainText publishModalPublishText"}>
          {isPublished ? 'Unpublish program' : 'Publish program'}
        </div>
        <div className="publishModalHelperText">
          {`You are about to ${isPublished ? 'unpublish' : 'publish'} '${courseName}'`}
        </div>
        {
          courseType === 1 ?
          <div className="publishModalHelperText">
            {
              isPublished ?
              'The program will not be available for any users anymore.' :
              `The program will be available for ${usersCount} users and they will receive the invitation message.`
            }
          </div>
          :
          <div className="publishModalHelperText">
            {
              isPublished ?
              'The program will not be available for any users anymore.' :
              `The program will be available for ${usersCount} users and they will receive the invitation message at ${getYearMonthDayFromDate(startDate)} ${getTimeFromDate(startDate)}.`
            }
          </div>
        }
        <div className="publishModalButtonsContainer">
          <button className="publishModalButton" onClick={onLeftButtonClick}>NO, CANCEL</button>
          <button
            className={isPublished ? "publishModalYesButton publishModalUnpublishBackground" : "publishModalYesButton publishModalPublishBackground"}
            onClick={onRightButtonClick}>
            {isPublished ? 'UNPUBLISH PROGRAM' : 'PUBLISH PROGRAM'}
          </button>
        </div>
      </div>
    </Modal>
  );
};


export default PublishModal;
