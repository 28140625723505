import React from 'react';
import { Link } from 'react-router-dom';
import './NoResultsComponent.css';

/**
* NoResultsComponent is a component that will show when the Search or Filter function does not
* yield any results. Text, path where to redirect to and button text are customizable
*/

const NoResultsComponent = ({ text1, text2, path, buttonText }) => {
  return (
    <div className="noResultsWrapper">
      <div className="noResultsText">{text1}</div>
      <div className="noResultsSecondText">{text2}</div>
      <Link to={path} className="noResultsRedirectButton">{buttonText}</Link>
    </div>
  );
};

export default NoResultsComponent;
