import React from 'react';
import ReactDOM from 'react-dom';
import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from './store';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import sagas from './sagas/sagas';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { QueryClient, QueryClientProvider } from 'react-query';

const logger = createLogger({});
const sagaMiddleware = createSagaMiddleware();
const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});
const store = createStore(
  rootReducer(),
  applyMiddleware(logger, sagaMiddleware)
);
sagaMiddleware.run(sagas);
ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
