import { create } from 'zustand';
import { getYearMonthDayFromDate } from '../utils/dateUtils';
import API from '../api/api';

const ITEMS_PER_PAGE = 20;

export const useUsersDataStore = create((set, get) => ({
  ...getInitialState(),
  setSortAttribute(sortAttribute) {
    const { sortAttribute: prevAttribute, sortOrder } = get();

    if (sortAttribute === prevAttribute) {
      set({ sortOrder: sortOrder * -1, currentPageNumber: 0 });
    } else {
      set({ sortAttribute, sortOrder: 1, currentPageNumber: 0 });
    }

    get().fetchUsers();
  },
  setCourseId(courseId) {
    set({ courseId });
  },
  async fetchFilters() {
    const { courseId: programId } = get();
    const { data } = await API.getUsersFiltersList({ programId });
    set({
      companies: data.studentsCompanies,
      departments: data.studentsDepartments,
    });
  },
  async fetchUsers() {
    if (get().loading) return;
    set({ loading: true });

    const {
      courseId,
      currentPageNumber,
      filters,
      itemsPerPage,
      sortAttribute,
      sortOrder,
    } = get();

    const { data } = await API.getUsersInCourse({
      id: courseId,
      currentPageNumber,
      itemsPerPage,
      searchString: filters.searchString,
      sortAttribute,
      sortOrder,
      companies: filters.company,
      departments: filters.department,
      status: filters.status,
    });

    data.users.forEach((user) => {
      const program = user.programs[0];

      user.lastInvitation = getYearMonthDayFromDate(
        program.lastInvitationResentAt
      );
      user.startDate = getYearMonthDayFromDate(program.firstInteractionAt);
      user.lastLogin = getYearMonthDayFromDate(program.lastInteractionAt);
    });

    set((state) => ({
      filteredCount: data.userCountFiltered ?? data.usersTotalCount,
      totalCount: data.usersTotalCount,
      totalNonStartedUsers: data.totalNonStartedUsers,
      totalNonFinishedUsers: data.totalNonFinishedUsers,
      users:
        state.currentPageNumber > 0
          ? [...state.users, ...data.users]
          : data.users,
      currentPageNumber: state.currentPageNumber + 1,
      loading: false,
    }));
  },
  setFilters(newFilters) {
    const filtersChanged = Object.keys(newFilters).some(
      (key) =>
        JSON.stringify(get().filters[key]) !== JSON.stringify(newFilters[key])
    );
    if (!filtersChanged) return;

    set((state) => ({
      filters: {
        ...state.filters,
        ...newFilters,
      },
      currentPageNumber: 0,
      users: [],
    }));
    get().fetchUsers();
  },
  clearStore() {
    set(getInitialState());
  },
  setUserField(userId, field, value) {
    set((state) => {
      const user = state.users.find(({ _id }) => userId === _id);
      user[field] = value;
      return { users: [...state.users] };
    });
  },
  resetUserProgressLocally(userId) {
    const { setUserField } = get();
    setUserField(userId, 'status', 'Not started');
    setUserField(userId, 'lastInvitation', getYearMonthDayFromDate(new Date()));
    setUserField(userId, 'startDate', '');
    setUserField(userId, 'lastLogin', '');
  },
  removeUser(userId) {
    set(({ filteredCount, totalCount, users }) => ({
      users: users.filter(({ _id }) => userId !== _id),
      filteredCount: filteredCount - 1,
      totalCount: totalCount - 1,
    }));
  },
  toggleInvertedSelection() {
    const { selectionInverted, excludedUsersIds } = get();
    set({
      selectionInverted: !(selectionInverted && excludedUsersIds.length === 0),
      excludedUsersIds: [],
      selectedUsersIds: [],
    });
  },
  setSelectedUsersIds(selectedUsersIds) {
    set({ selectedUsersIds });
  },
  setExcludedUsersIds(excludedUsersIds) {
    set({ excludedUsersIds });
  },
  deselectAllItems() {
    set({
      selectionInverted: false,
      excludedUsersIds: [],
      selectedUsersIds: [],
    });
  },
}));

function getInitialState() {
  return {
    courseId: null,
    companies: [],
    currentPageNumber: 0,
    departments: [],
    error: null,
    filteredCount: -1,
    filters: {
      company: [],
      department: [],
      status: [],
      searchString: '',
    },
    itemsPerPage: ITEMS_PER_PAGE,
    loading: false,
    selectionInverted: false,
    selectedUsersIds: [],
    excludedUsersIds: [],
    sortAttribute: 'username',
    sortOrder: 1,
    totalCount: -1,
    users: [],
    totalNonStartedUsers: 0,
    totalNonFinishedUsers: 0,
  };
}
