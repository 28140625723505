import React from 'react';
import Modal from '@material-ui/core/Modal';
import warning_icon from '../../images/styleguideComponentsImages/warning_icon.svg';
import './WarningPopup.css';

/**
 * WarningPopup is a reusable modal used for warning the user of an action
 * they are about to do
 */
const WarningPopup = (props) => {
  const {
    open,
    onClose,
    title,
    text1,
    leftButtonText,
    rightButtonText,
    onLeftButtonClick,
    onRightButtonClick,
  } = props;
  return (
    <Modal id="modal" className="warningPopup" open={open} onClose={onClose}>
      <div className="warningPopupWrapper">
        <img src={warning_icon} className="warningPopupIcon" />
        <div className="warningPopupMainText">{title}</div>
        <div className="warningPopupHelperText">{text1}</div>
        <div className="warningPopupButtonsContainer">
          <button
            type="button"
            onClick={onLeftButtonClick}
            className="warningPopupCancelButton"
          >
            {leftButtonText}
          </button>
          <button
            type="button"
            onClick={onRightButtonClick}
            className="warningPopupActionButton"
          >
            {rightButtonText}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default WarningPopup;
