import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams  } from 'react-router-dom';
import * as actions from '../actions';
import Const from '../store/Const';
import CourseLeftMenu from '../components/CourseLeftMenu.js';
import CourseLessonsMenu from '../components/courseCore/CourseLessonsMenu';
import CourseLessonsContent from '../components/courseCore/CourseLessonsContent.js';
import ImageUploadToast from '../components/courseComponents/ImageUploadToast';
import ImageUploadErrorToast from '../components/courseComponents/ImageUploadErrorToast';
import ImageUploadLoadingToast from '../components/courseComponents/ImageUploadLoadingToast';
import AutosaveIconsTrackerComponent from '../components/courseComponents/AutosaveIconsTrackerComponent';
import ContentLoadingOverlay from '../components/ContentLoadingOverlay';
import './CourseLessonsPage.css';

/**
* CourseLessonsPage: user is redirected to this page as soon as they create
* a new course. It will show: CourseLeftMenu left sidebar menu, CourseLessonsMenu
* and the Lessons page Content
*/
const CourseLessonsPage = () => {
  const dispatch = useDispatch();
  const { courseId, pageid } = useParams();

  useEffect(() => {
    dispatch(actions.getCourse.request(courseId));
    dispatch(actions.getModuleContent.request(courseId, Const.moduleType.Lessons));

    return (() => {
      dispatch(actions.getCourse.success({
        studentResults: [],
        programData: {},
        students: [],
        isCertificateActive: false,
        dynamicPushTime: {},
        startDate: new Date(),
        modules: {
          aboutModule: {},
          libraryModule: {},
          lessonModule: {},
          welcomeModule: {},
        },
        moduleContent: {
          about: [],
          library: [],
          lessons: [],
          welcome: []
        },
        currentLibraryPageId: "",
        currentLessonId: "",
        currentlyOpenPageSettingsId: "",
        currentUserPushes: []
      }));
    });
  }, []);

  useEffect(() => {
    if (pageid) {
      dispatch(actions.setLessonContent.request(pageid));
    }
  }, [pageid]);

  const imageUploadToastActive = useSelector(state => state.componentStates.imageUploadToastActive);
  const imageUploadErrorToastActive = useSelector(state => state.componentStates.imageUploadErrorToastActive);
  const imageUploadLoadingToastActive = useSelector(state => state.componentStates.imageUploadLoadingToastActive);
  const lessonsModuleContent = useSelector(state => state.programs.currentProgram.moduleContent.lessons);

  return (
    <>
      {(Object.keys(lessonsModuleContent).length <= 0) && <ContentLoadingOverlay/>}
      <div className="courseComponentsWrapper">
        <CourseLeftMenu/>
        <CourseLessonsMenu/>
        <div className="courseLessonsContentWrapper">
          <CourseLessonsContent/>
        </div>
        {imageUploadToastActive &&
          <ImageUploadToast
            text="Image uploaded"
            timeToClose={5000}
          />
        }
        {imageUploadErrorToastActive &&
          <ImageUploadErrorToast
            text="Couldn't upload image, please try again"
            timeToClose={10000}
          />
        }
        {imageUploadLoadingToastActive &&
          <ImageUploadLoadingToast
            text="Uploading image"
          />
        }
        <AutosaveIconsTrackerComponent/>
      </div>
    </>
  );
};

export default CourseLessonsPage;
