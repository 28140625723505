import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams  } from 'react-router-dom';
import * as actions from '../actions';
import Const from '../store/Const';
import CourseLeftMenu from '../components/CourseLeftMenu.js';
import certificate_mandatory_icon from '../images/svg/certificate_mandatory_icon.svg';
import user_unchecked_checkbox from '../images/svg/user_unchecked_checkbox.svg';
import certificate_no_icon from '../images/svg/certificate_no_icon.svg';
import certificate_yes_icon from '../images/svg/certificate_yes_icon.svg';
import './CourseCertificatePage.css';

/**
* CourseCertificatePage - path="/course/:id/certificate"
*/

const CourseCertificatePage = () => {
  const dispatch = useDispatch();
  const { courseId } = useParams();
  const currentProgram = useSelector(state => state.programs.currentProgram);
  const languages = Const.certificateLanguages;

  const [language, setLanguage] = useState('Choose language');
  const [activatePercentage, setActivatePercentage] = useState(false);
  const [percentage, setPercentage] = useState(100);
  const [showStudentScore, setShowStudentScore] = useState(false);

  useEffect(() => {
    dispatch(actions.getCourse.request(courseId));
    dispatch(actions.getModuleContent.request(courseId, Const.moduleType.Welcome));
  }, []);

  useEffect(() => {
    setLanguage(currentProgram.certificateLanguage);
    setActivatePercentage(currentProgram.isCertificateWithScore);
    setPercentage(currentProgram.certificateQualificationRate ? currentProgram.certificateQualificationRate : 100);
    setShowStudentScore(currentProgram.certificateShowStudentScore);
  }, [currentProgram]);

  const submitForm = () => {
    const updatedProgram = {
      _id: currentProgram._id,
      clientId: currentProgram.clientId,
      certificateLanguage: language,
      isCertificateWithScore: activatePercentage,
      certificateQualificationRate: percentage,
      certificateShowStudentScore: showStudentScore
    };
    dispatch(actions.editCourseSettings.request(updatedProgram));
  };

  return (
    <div className="certificateComponentsWrapper">
      <CourseLeftMenu/>
      <div className="certificateContentWrapper">
        <div className="certificatePageTitle">CERTIFICATE</div>
        <div className="certificatePageSubtitle">Certificate language</div>
        <div className="certificatePageHelperText">Choose certificate language</div>
        {renderLanguagesDropdown(languages, language, setLanguage)}
        <div className="certificatePageSubtitle">Certificate requirements</div>
        <div className="certificatePageHelperText">What does the user need to fulfill to recieve the certificate?</div>
        <div className="certificateMandatoryTextWrapper">
          <img className="certificateMandatoryIcon" src={certificate_mandatory_icon}/>
          <div className="certificateMandatoryText">Complete all pages (Mandatory)</div>
        </div>
        <div className="certificateRequirementOptionsWrapper">
          <div className="certificatePercentageTextWrapper">
            <div className="certificateCheckboxes">
              {activatePercentage ?
                <img
                  className="certificateCheckbox"
                  src={certificate_mandatory_icon}
                  onClick={() => setActivatePercentage(!activatePercentage)}
                />
                :
                <img
                  className="certificateCheckbox"
                  src={user_unchecked_checkbox}
                  onClick={() => setActivatePercentage(!activatePercentage)}
                />
              }
            </div>
            <input
              type="number"
              className="certificatePercentageInput"
              min="1"
              max ="100"
              value={percentage}
              onChange={(event) => setPercentage(event.target.value)}
              disabled={!activatePercentage}
              required
            />
            <div className="certificateOptionText">% of the answers needs to be correct</div>
          </div>
          {percentage > 100 &&
            <div className="certificateErrorText">Percentage should not be higher than 100</div>
          }
        </div>
        <div className="certificatePageSubtitle">Show total score on certificate</div>
        <div className="certificatePageHelperText">Should the user be able to see their total score on the certificate?</div>
        <div className="certificateScoreOptionWrapper certificateFirstScoreOption">
          {showStudentScore ?
            <img
              className="certificateScoreOptionIcon"
              src={certificate_yes_icon}
              onClick={() => setShowStudentScore(!showStudentScore)}
            />
            :
            <img
              className="certificateScoreOptionIcon"
              src={certificate_no_icon}
              onClick={() => setShowStudentScore(!showStudentScore)}
            />
          }
          <div className="certificateOptionText">Yes, show total score</div>
        </div>
        <div className="certificateScoreOptionWrapper">
          {showStudentScore ?
            <img
              className="certificateScoreOptionIcon"
              src={certificate_no_icon}
              onClick={() => setShowStudentScore(!showStudentScore)}
            />
            :
            <img
              className="certificateScoreOptionIcon"
              src={certificate_yes_icon}
              onClick={() => setShowStudentScore(!showStudentScore)}
            />
          }
          <div className="certificateOptionText">No, don’t show total score</div>
        </div>
        <button
          type="button"
          className="certificateSubmitButton"
          disabled={disableSubmitButton(
            language,
            currentProgram,
            activatePercentage,
            percentage,
            showStudentScore
          )}
          onClick={() => submitForm()}
        >
          SAVE CHANGES
        </button>
      </div>
    </div>
  );
};

const renderLanguagesDropdown = (languages, language, setLanguage) => {
  return (
    <select
      className="certificateLanguagesSelect"
      value={language || 'Choose language'}
      onChange={(event) => setLanguage(event.target.value)}
    >
      <option
        className="certificateLanguagesSelectPlaceholderOption"
        disabled
      >
        Choose language
      </option>
      {languages.map((language) => {
        return (
          <option key={language} value={language}>{language}</option>
        );
      })}
    </select>
  );
};

const disableSubmitButton = (
  language,
  currentProgram,
  activatePercentage,
  percentage,
  showStudentScore
) => {
  return (
    !language ||
    language === currentProgram.certificateLanguage &&
    activatePercentage === currentProgram.isCertificateWithScore &&
    parseInt(percentage) === currentProgram.certificateQualificationRate &&
    showStudentScore === currentProgram.certificateShowStudentScore
  );
};

export default CourseCertificatePage;
