
const initialState = {
  role: "",
  cognitoUser: null,
  isSuperAdmin: false,
  permissions: []
};

/**
* user is the reducer responsible for handling the user data, for example roles
* This reducer will potentially become obsolete as the new user handling will
* be implemented
*
* TODO: Remove role and all associated action handling
*/
const user = (state = initialState, action) => {
  switch (action.type) {
    case 'GETUSERROLE_SUCCESS':
      return {
        ...state,
        role: action.response
      };
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        cognitoUser: action.response.cognitoUserObject,
        isSuperAdmin: action.response.isSuperAdmin,
        permissions: action.response.permissions
      };
    case 'LOGOUT_REQUEST':
      return initialState;
    default:
      return state;
  }
};

export default user;
