import React from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import * as actions from '../../actions';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import {
  email,
  composeValidators
} from '../../utils/validationUtils';
import './LoginForm.css';
import '../Common.css';


const mapStateToProps = (state) => {
  return {
    formsubmitted: state.componentStates.formSubmitted,
    wrongPasswordOrUsername: state.componentStates.wrongPasswordOrUsername
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (username, password) => {
      dispatch(actions.login.request(username, password));
    },
    loginFormValueChanged: () => {
      dispatch(actions.loginFormValueChanged.request());
    }
  };
};

/**
* LoginForm handles login
*/
const LoginForm = ({ login, formsubmitted, wrongPasswordOrUsername, loginFormValueChanged }) => {
  return (
    <Form
      onSubmit={(data) => login(data.username, data.password)}
    >
      {({ handleSubmit, values, errors }) => (
        <form className="loginForm" autoComplete="off" onSubmit={handleSubmit}>
          <Field
            name="username"
            className="loginField"
            type="email"
            label="E-mail"
            validate={composeValidators(email)}
            formsubmitted={formsubmitted.toString()}
            component={renderTextField}
          />
          <Field
            name="password"
            className="loginField"
            type="password"
            label="Password"
            formsubmitted={formsubmitted.toString()}
            wrongpasswordorusername={wrongPasswordOrUsername ? 1 : 0}
            component={renderTextField}
          />
          <OnChange name="username">
            {() => formsubmitted || wrongPasswordOrUsername && loginFormValueChanged()}
          </OnChange>
          <OnChange name="password">
            {() => formsubmitted || wrongPasswordOrUsername && loginFormValueChanged()}
          </OnChange>
          {Object.keys(errors).length === 0 && formsubmitted ?
            <CircularProgress className="loginLoadingIcon" thickness={3}/>
            :
            <button
              className="button purpleButton loginButton"
              disabled={!bothFieldsNotEmpty(values) || Object.keys(errors).length > 0}
              onSubmit={handleSubmit}
            >
              Sign in
            </button>
          }
        </form>
      )}
    </Form>
  );
};


/**
* bothFieldsNotEmpty checks if both fiels are not empty
*/
const bothFieldsNotEmpty = (values) => {
  return values && values.username && values.password;
};

/* Custom theme for Material UI's TextField component */
const theme = createMuiTheme({
  typography: {
    fontSize: 13,
    fontFamily: "RobotoRegular"
  },
  overrides: {
    MuiFormLabel: {
      "root": {
        "color": "#CCCCCC",
        "marginLeft": 12
      }
    }
  }
});

/**
* renderTextField() renders the material UI TextField component
*/
const renderTextField = ({
  input,
  meta: {touched, error},
  ...custom
}) => (
  <div>
    <ThemeProvider theme={theme}>
      <TextField
        {...input}
        {...custom}
        error={touched && (error || custom.wrongpasswordorusername) && custom.formsubmitted ? true : false}
        helperText={
          touched && error && custom.formsubmitted ?
          error :
          custom.wrongpasswordorusername ?
          'Wrong username or password' :
          null
        }
        label={custom.label}
      />
    </ThemeProvider>
  </div>
);

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
