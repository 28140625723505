import './CourseAddUserForm.css';
import { email, name, composeValidators } from '../utils/validationUtils';
import { Form, Field } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import * as actions from '../actions';
import ButtonPrimaryBlue from '../components/styleguideComponents/ButtonPrimaryBlue';
import ButtonSecondaryOutlined from '../components/styleguideComponents/ButtonSecondaryOutlined';
import plus_icon_white from '../images/styleguideComponentsImages/plus_icon_white.svg';
import React, { useRef } from 'react';
import TextInputField from '../components/styleguideComponents/TextInputField';

const CourseAddUserForm = () => {
  const dispatch = useDispatch();
  const addUserFormNameRef = useRef('addUserFormNameRef');
  const addUserFormCompanyRef = useRef('addUserFormCompanyRef');
  const addUserFormDepartmentRef = useRef('addUserFormDepartmentRef');
  const { courseId } = useParams();
  const submitButtonDisabled = useSelector(
    (state) => state.componentStates.submitButtonDisabled
  );
  const loadingState = useSelector(
    (state) => state.componentStates.loadingToastActive
  );

  const handleSubmit = (data) => {
    dispatch(
      actions.addUserToCourse.request(
        courseId,
        data.addUserFormName,
        data.addUserFormEmail,
        data.addUserFormCompany || '',
        data.addUserFormDepartment || ''
      )
    );
    dispatch(actions.setToastOrigin.request(`/course/${courseId}/adduser`));
  };

  const handleClearForm = (form) => {
    form.restart();
    dispatch(actions.enableSubmitButton.request());
  };

  const mandatoryFieldsFilledIn = (values, errors) => {
    return (
      values &&
      values.addUserFormName &&
      values.addUserFormEmail &&
      Object.keys(errors).length === 0
    );
  };

  const calculateNameLength = (addUserFormNameRef) => {
    if (!addUserFormNameRef.current.value) {
      return 0;
    } else {
      return addUserFormNameRef.current.value.length;
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      {({ handleSubmit, values, errors, form, pristine }) => (
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Field name="addUserFormName" validate={composeValidators(name)}>
            {({ input, meta }) => (
              <TextInputField
                label="Name"
                input={input}
                meta={meta}
                fieldRef={addUserFormNameRef}
                calculateTextLength={calculateNameLength}
                type="text"
                placeholder="John Doe"
                minLength="1"
                required={true}
              />
            )}
          </Field>
          <div className="addUserFormFieldContainer">
            <Field name="addUserFormEmail" validate={composeValidators(email)}>
              {({ input, meta }) => (
                <TextInputField
                  label="Email"
                  input={input}
                  meta={meta}
                  mustHaveCounter={false}
                  type="email"
                  placeholder="john.doe@email.com"
                  required={true}
                />
              )}
            </Field>
          </div>
          <div className="addUserFormFieldContainer">
            <Field name="addUserFormCompany">
              {({ input, meta }) => (
                <TextInputField
                  label="Company"
                  input={input}
                  meta={meta}
                  fieldRef={addUserFormCompanyRef}
                  calculateTextLength={calculateNameLength}
                  type="text"
                  placeholder="Company"
                />
              )}
            </Field>
          </div>
          <div className="addUserFormFieldContainer">
            <Field name="addUserFormDepartment">
              {({ input, meta }) => (
                <TextInputField
                  label="Department"
                  input={input}
                  meta={meta}
                  fieldRef={addUserFormDepartmentRef}
                  calculateTextLength={calculateNameLength}
                  type="text"
                  placeholder="Department"
                />
              )}
            </Field>
          </div>
          <div className="addAdminActioButtonsWrapper">
            <div className="addAdminActioButtonsSpacing">
              <Link to={`/course/${courseId}/users`} exact="true" style={{textDecoration:'none'}}>
                <ButtonSecondaryOutlined type="button" label="Cancel" />
              </Link>
            </div>
            <div className="addAdminActioButtonsSpacing">
              <ButtonSecondaryOutlined
                type="button"
                label="Clear form"
                disabled={pristine}
                onClick={() => handleClearForm(form)}
              />
            </div>
            <ButtonPrimaryBlue
              type="submit"
              label={loadingState ? 'Creating user' : 'Create user'}
              disabled={
                !mandatoryFieldsFilledIn(values, errors) || submitButtonDisabled
              }
              iconSource={plus_icon_white}
              loading={loadingState}
            />
          </div>
        </form>
      )}
    </Form>
  );
};

export default CourseAddUserForm;
