import { create } from 'zustand';

export const useDashboardFiltersStore = create((set) => ({
  companiesNames: [],
  departmentsNames: [],
  searchString: '',
  selectedCompanies: [],
  selectedDepartments: [],
  selectedStatuses: [],
  sortAttribute: 'username',
  sortOrder: 1,
  clearFilters() {
    set({
      selectedCompanies: [],
      selectedDepartments: [],
      selectedStatuses: [],
    });
  },
  setAttrSortOrder(attr) {
    set((state) => ({
      sortAttribute: attr,
      sortOrder: state.sortAttribute === attr ? -state.sortOrder : 1,
    }));
  },
  setDashboardFilters: set,
}));
