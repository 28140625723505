import React from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import * as actions from '../../actions';
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import {
  email,
  composeValidators
} from '../../utils/validationUtils';
import './ResetPasswordForm.css';
import '../Common.css';


const mapStateToProps = (state) => {
  return {
    formsubmitted: state.componentStates.formSubmitted,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (username) => {
      dispatch(actions.resetPassword.request(username));
    }
  };
};


/**
* ResetPasswordForm handles login
*/
const ResetPasswordForm = ({ resetPassword, formsubmitted }) => {
  return (
    <Form
      onSubmit={(data) => resetPassword(data.username)}
      mutators={{
        setPasswordFromFailedLogin: (args, state, utils) => {
          utils.changeValue(state, 'username', () => localStorage.getItem("passwordResetEmail"));
        }
      }}
      render = {({ handleSubmit, values, errors, form }) => {
        if (localStorage.getItem("passwordResetEmail") && !values.username) {
          form.mutators.setPasswordFromFailedLogin();
        }
        return (
          <form className="ResetPasswordForm" autoComplete="off" onSubmit={handleSubmit}>
            <Field
              name="username"
              className="resetPasswordField"
              type="email"
              label="E-mail"
              validate={composeValidators(email)}
              formsubmitted={formsubmitted.toString()}
              component={renderTextField}
            />
            <button
              className="button purpleButton resetPasswordButton"
              disabled={!values.username || errors.username}
              onSubmit={handleSubmit}
            >
              Reset password
            </button>
          </form>
        );}
      }
    />
  );
};

/* Custom theme for Material UI's TextField component */
const theme = createMuiTheme({
  typography: {
    fontSize: 13,
    fontFamily: "RobotoRegular"
  },
  overrides: {
    MuiFormLabel: {
      "root": {
        "color": "#414141",
        "marginLeft": 12
      }
    }
  }
});

/**
* renderTextField() renders the material UI TextField component
*/
const renderTextField = ({
  input,
  meta: {touched, error},
  ...custom
}) => (
  <div>
    <ThemeProvider theme={theme}>
      <TextField
        {...input}
        {...custom}
        error={touched && error && custom.formsubmitted ? true : false}
        helperText={touched && error && custom.formsubmitted ? error : null}
        label={custom.label}
      />
    </ThemeProvider>
  </div>
);


export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm);
