import React from 'react';
import loading_dots_white from '../../images/styleguideComponentsImages/loading_dots_white.svg';
import './ButtonPrimaryBlue.css';

/**
 * ButtonPrimaryBlue
 */
const ButtonPrimaryBlue = ({
  type,
  label,
  disabled,
  iconSource,
  loading,
  onClick,
}) => {
  return (
    <button
      type={type}
      disabled={disabled}
      className="buttonPrimaryBlue"
      onClick={onClick}
    >
      {loading ? (
        <img
          className="buttonPrimaryBlueIcon buttonPrimaryBlueLoadingIcon"
          src={loading_dots_white}
        />
      ) : (
        <img className="buttonPrimaryBlueIcon" src={iconSource} />
      )}
      {label}
    </button>
  );
};

export default ButtonPrimaryBlue;
