import React from 'react';
import autosave_saving_arrows from '../../images/svg/autosave_saving_arrows.svg';
import './AutosaveSavingComponent.css';

/**
* AutosaveSavingComponent: Autosave icon used in the course core to show progress on
* the autosave.
*/
const AutosaveSavingComponent = () => {
  return (
    <div className="autoSaveSavingWrapper">
      <img className="autoSaveSavingArrows" src={autosave_saving_arrows}/>
      <div className="autoSaveSavingText">Saving...</div>
    </div>
  );
};

export default AutosaveSavingComponent;
