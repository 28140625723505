import React from 'react';
import { Link } from 'react-router-dom';
import LoginForm from '../components/forms/LoginForm';
import moblrn_logo_old from '../images/svg/moblrn-logo-old.svg';
import './LoginPage.css';


/**
* LoginPage is the page where users who are flagged for a password
* change will be redirected to after login
*/
const LoginPage = () => {
  return (
    <div className="loginPageBackground">
      <div className="loginPageContent">
        <img className="moblrnLogo" src={moblrn_logo_old} alt="moblrn logo"/>
        <div className="loginPageTitle">Sign in to Moblrn Admin</div>
        <LoginForm/>
        <Link className="forgoPasswordLink" to="/resetPassword">Forgot password?</Link>
      </div>
    </div>
  );
};



export default LoginPage;
