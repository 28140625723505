import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '../../actions';
import CourseCoreMoveTooltip from './CourseCoreMoveTooltip';
import CourseCoreDeleteTooltip from './CourseCoreDeleteTooltip';
import draggable_handle from '../../images/svg/draggable_handle.svg';
import draggable_handle_onhover from '../../images/svg/draggable_handle_onhover.svg';
import text_icon from '../../images/svg/text_icon.svg';
import red_x_mark from '../../images/svg/red_x_mark.svg';
import red_x_mark_onhover from '../../images/svg/red_x_mark_onhover.svg';
import './ParagraphComponent.css';


/**
* ParagraphComponent: Field which adds a paragraph (text) to the Module,
* chosen from the Add Content dropdown
*/
const ParagraphComponent = (props) => {
  const dispatch = useDispatch();
  const { moduleId, moduleItemId, text, moduleType, provided, numberOfComponents, parentId, autoFocus } = props;
  const [localText, setLocalText] = useState(text);
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    let debouncedSaveFunction = setTimeout(
      () => {
        if (hasLoaded) {
          dispatch(actions.updateComponent.request(moduleId, moduleItemId, { text: localText }, moduleType));
        }
        setHasLoaded(true);
      },
      500
    );
    return () => clearTimeout(debouncedSaveFunction);
  }, [localText]);

  const minTextAreaHeight = 16;
  const textareaRef = useRef(null);

  useLayoutEffect(() => {
    textareaRef.current.style.height = "16px";
    textareaRef.current.style.height = `${Math.max(textareaRef.current.scrollHeight, minTextAreaHeight)}px`;
  }, [localText]);

  return (
    <div className="paragraphComponentContainer">
      <img
        className={numberOfComponents === 1 ? "paragraphComponentIcon paragraphComponentIconAlone" : "paragraphComponentIcon"}
        src={text_icon}
      />
      {numberOfComponents > 1 &&
        <div className="paragraphComponentTooltipParent" {...provided.dragHandleProps}>
          <img className="paragraphComponentDraggableHandle" src={draggable_handle}/>
          <img className="paragraphComponentDraggableHandleOnHover" src={draggable_handle_onhover}/>
          <CourseCoreMoveTooltip x={"-140%"} y={"-195%"}/>
        </div>
      }
      <textarea
        className="paragraphComponentTextarea"
        placeholder={'Text'}
        value={localText}
        onChange={(event) => setLocalText(event.target.value)}
        ref={textareaRef}
        autoFocus={autoFocus}
      />
      <div className="paragraphComponentXMarkContainer">
        <img className="paragraphComponentXMark" src={red_x_mark}/>
        <img
          className="paragraphComponentXMarkOnHover"
          src={red_x_mark_onhover}
          onClick={() => dispatch(actions.deleteComponent.request(moduleId, moduleItemId, moduleType, parentId))}
        />
        <CourseCoreDeleteTooltip x={"30%"} y={"-195%"}/>
      </div>
    </div>
  );
};

export default ParagraphComponent;
