import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../actions';
import toast_error_icon from '../../images/svg/toast_error_icon.svg';
import './ImageUploadErrorToast.css';

/**
* ImageUploadErrorToast is a standard toast component to show something has gone wrong
* when uploading an image. It will come from the top of the screen and close down automatically 
* after a short time or when clicking on the x icon
* Specifically used for the Image Upload components within the Course core (Welcome, About,
* Lessons and Library pages)
*/
const ImageUploadErrorToast = (props) => {
  const dispatch = useDispatch();
  const { text, timeToClose } = props;
  const currentPath = window.location.pathname.split('/');
  const doubleMenuPage = currentPath[3] === "lessons" || currentPath[3] === "library";
  const [errorToastClassname, setErrorToastClassname] = useState(
    doubleMenuPage ?
    "imageUploadErrorToastDoubleMenu imageUploadErrorToastHidden" :
    "imageUploadErrorToast imageUploadErrorToastHidden"
  );
  const [componentMounted, setComponentMounted] = useState(true);
  const toastOrigin = useSelector(state => state.componentStates.imageUploadToastOrigin);

  useEffect(() => {
    setErrorToastClassname(
      doubleMenuPage ?
      "imageUploadErrorToastDoubleMenu imageUploadErrorToastVisible" :
      "imageUploadErrorToast imageUploadErrorToastVisible"
    );
    setTimeout(() => {
      if (componentMounted) {
        setErrorToastClassname(
          doubleMenuPage ?
          "imageUploadErrorToastDoubleMenu imageUploadErrorToastHidden" :
          "imageUploadErrorToast imageUploadErrorToastHidden"
        );
        setTimeout(
          () => dispatch(actions.closeImageUploadErrorToast.request()),
          500
        );
      }
    },
    timeToClose);
    return (() => {
      setComponentMounted(false);
    });
  }, []);

  if (toastOrigin === currentPath[3]) {
    return (
      <div className={errorToastClassname}>
        <div className="imageUploadErrorToastContent" onClick={() => dispatch(actions.closeErrorToast.request())}>
          <img className="imageUploadErrorToastIcon" src={toast_error_icon} alt="warning icon"/>
          <div>{text}</div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default ImageUploadErrorToast;
