import './StudentTableSelect.css';
import React, { useEffect } from 'react';
import StudentTableSelectDropdownComponent from '../components/StudentTableSelectDropdownComponent';
import { useDashboardFiltersStore } from './dashboard-filters.store';
import { useDashboardTableData } from './use-dashboard-table-data';
import { useSelector } from 'react-redux';

/**
 * StudentTableSelect lets the admin select which data to show in the Student Table.
 * It lets the admin select several companies, departments and progress statuses
 */

const StudentTableSelect = () => {
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const { firstPage } = useDashboardTableData();
  const {
    clearFilters,
    companiesNames,
    departmentsNames,
    selectedCompanies,
    selectedDepartments,
    selectedStatuses,
    setDashboardFilters,
  } = useDashboardFiltersStore();
  useEffect(() => {
    if (!firstPage.clientCompanies || !firstPage.clientDepartments) return;
    setDashboardFilters({
      companiesNames: firstPage.clientCompanies ?? [],
      departmentsNames: firstPage.clientDepartments ?? [],
    });
  }, [firstPage]);

  const statusesNames = ['Not started', 'In progress', 'Finished'];
  if (currentProgram.certificate) statusesNames.push('Certified');

  useEffect(clearFilters, []);

  const numberOfFilters =
    selectedCompanies.length +
    selectedDepartments.length +
    selectedStatuses.length;

  return (
    <>
      <div className="studentTableSelectTitleAndClearFiltersWrapper">
        <div className="studentTableSelectTitle">Filter options</div>
        <div className="studentTableSelecFiltersNumber">
          {numberOfFilters <= 0
            ? ''
            : `${numberOfFilters} ${
                numberOfFilters > 1 ? 'filters' : 'filter'
              } applied`}
        </div>
        {numberOfFilters > 0 && (
          <div
            className="studentTableSelectResetFilterText"
            onClick={clearFilters}
          >
            Clear filters
          </div>
        )}
      </div>
      <div className="studentTableSelectDropdownsWrapper">
        <div>
          <div className="studentTableSelectDropdownTitle">Company</div>
          <StudentTableSelectDropdownComponent
            name="company"
            defaultText="Company"
            optionTexts={companiesNames}
            selectedItems={selectedCompanies}
            handleRemoveOption={(_name, index) =>
              setDashboardFilters((state) => ({
                selectedCompanies: state.selectedCompanies.filter(
                  (i) => i !== index
                ),
              }))
            }
            handleChange={(_name, value) =>
              setDashboardFilters((state) => ({
                selectedCompanies: [...state.selectedCompanies, value],
              }))
            }
            handleClear={() => setDashboardFilters({ selectedCompanies: [] })}
            source="studentTableSelect"
          />
        </div>
        <div>
          <div className="studentTableSelectDropdownTitle">Department</div>
          <StudentTableSelectDropdownComponent
            name="department"
            defaultText="Department"
            optionTexts={departmentsNames}
            selectedItems={selectedDepartments}
            handleRemoveOption={(_name, index) =>
              setDashboardFilters((state) => ({
                selectedDepartments: state.selectedDepartments.filter(
                  (i) => i !== index
                ),
              }))
            }
            handleChange={(_name, value) =>
              setDashboardFilters((state) => ({
                selectedDepartments: [...state.selectedDepartments, value],
              }))
            }
            handleClear={() => setDashboardFilters({ selectedDepartments: [] })}
            source="studentTableSelect"
          />
        </div>
        <div>
          <div className="studentTableSelectDropdownTitle">Status</div>
          <StudentTableSelectDropdownComponent
            name="status"
            defaultText="Status"
            optionTexts={statusesNames}
            selectedItems={selectedStatuses}
            handleRemoveOption={(_name, index) =>
              setDashboardFilters((state) => ({
                selectedStatuses: state.selectedStatuses.filter(
                  (i) => i !== index
                ),
              }))
            }
            handleChange={(_name, value) =>
              setDashboardFilters((state) => ({
                selectedStatuses: [...state.selectedStatuses, value],
              }))
            }
            handleClear={() => setDashboardFilters({ selectedStatuses: [] })}
            source="studentTableSelect"
          />
        </div>
      </div>
    </>
  );
};

export default StudentTableSelect;
