import React from 'react';
import { useSelector } from 'react-redux';
import Menu from '../components/Menu.js';
import AdminList from '../components/AdminList.js';
import Toast from '../components/Toast.js';
import ErrorToast from '../components/ErrorToast.js';
import LoadingToast from '../components/LoadingToast.js';
import './AdminPage.css';


/**
* AdminPage is the page where a superadmin can see an overview over all admins
*/
const AdminPage = () => {
  const toastActive = useSelector(state => state.componentStates.toastActive);
  const errorToastActive = useSelector(state => state.componentStates.errorToastActive);
  const loadingToastActive = useSelector(state => state.componentStates.loadingToastActive);
  return (
    <div className="adminPageContainer">
      <Menu/>
      <div className="adminListWrapper">
        <AdminList/>
      </div>
      {toastActive &&
        <Toast
          text="Admin deleted"
          timeToClose={5000}
        />
      }
      {errorToastActive &&
        <ErrorToast
          text="Couldn't delete admin, please try again"
          timeToClose={10000}
        />
      }
      {loadingToastActive &&
        <LoadingToast
          text="Deleting admin"
        />
      }
    </div>
  );
};

export default AdminPage;
