import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as actions from '../actions';
import Const from '../store/Const';
import CourseLeftMenu from '../components/CourseLeftMenu.js';
import CourseAboutPageContent from '../components/courseCore/CourseAboutPageContent.js';
import ImageUploadToast from '../components/courseComponents/ImageUploadToast';
import ImageUploadErrorToast from '../components/courseComponents/ImageUploadErrorToast';
import ImageUploadLoadingToast from '../components/courseComponents/ImageUploadLoadingToast';
import AutosaveIconsTrackerComponent from '../components/courseComponents/AutosaveIconsTrackerComponent';
import './CourseAboutPage.css';

/**
* CourseAboutPage is the component containing all the fields that make up the
* Course's About Page (within CourseAboutPageContent) - "/course/:id/about"
*/

const CourseAboutPage = () => {
  const dispatch = useDispatch();
  const { courseId } = useParams();
  useEffect(() => {
    dispatch(actions.getCourse.request(courseId));
    dispatch(actions.getModuleContent.request(courseId, Const.moduleType.About));
  }, []);

  const imageUploadToastActive = useSelector(state => state.componentStates.imageUploadToastActive);
  const imageUploadErrorToastActive = useSelector(state => state.componentStates.imageUploadErrorToastActive);
  const imageUploadLoadingToastActive = useSelector(state => state.componentStates.imageUploadLoadingToastActive);

  return (
    <div className="courseAboutComponentsWrapper">
      <CourseLeftMenu/>
      <div className="courseAboutFormWrapper">
        <CourseAboutPageContent/>
      </div>
      {imageUploadToastActive &&
        <ImageUploadToast
          text="Image uploaded"
          timeToClose={5000}
        />
      }
      {imageUploadErrorToastActive &&
        <ImageUploadErrorToast
          text="Couldn't upload image, please try again"
          timeToClose={10000}
        />
      }
      {imageUploadLoadingToastActive &&
        <ImageUploadLoadingToast
          text="Uploading image"
        />
      }
      <AutosaveIconsTrackerComponent/>
    </div>
  );
};

export default CourseAboutPage;
