import { useInfiniteQuery } from 'react-query';
import { useParams } from 'react-router';
import API from '../api/api';
import { useDashboardFiltersStore } from './dashboard-filters.store';

const ITEMS_PER_PAGE = 30;
export const DASHBOARD_GRAPHS_QUERY_KEY = 'dashboardGraphsData';

export const useDashboardGraphs = () => {
  const { courseId } = useParams();
  const {
    querySearchString,
    selectedCompanies,
    selectedDepartments,
    selectedStatuses,
  } = useDashboardFiltersStore();

  const graphQuery = useInfiniteQuery(
    [
      DASHBOARD_GRAPHS_QUERY_KEY,
      courseId,
      selectedCompanies,
      selectedDepartments,
      selectedStatuses,
      querySearchString,
    ],
    ({ pageParam = 0 }) =>
      fetchProgressGraph({
        querySearchString,
        selectedCompanies,
        selectedDepartments,
        selectedStatuses,
        pageParam,
        programId: courseId,
        searchString: querySearchString,
      }),
    { getNextPageParam: (lastPage) => lastPage.pageParam + 1 }
  );

  const allGraphChunks = graphQuery.data?.pages ?? [];
  const firstGraphChunk = allGraphChunks[0] ?? {};
  const {
    currentClientPrograms = [],
    clientStudentsFilteredCount: filteredCount = 0,
    clientUsersTotalCount: totalCount = 0,
  } = firstGraphChunk;
  const graphProgram = currentClientPrograms[0] ?? {};

  // Graph data
  const {
    interactionCount = 0,
    interactionOptions = [],
    chapters = [],
  } = graphProgram;
  const studentsWithAnswers = allGraphChunks.reduce(
    (acc, chunk) => [...acc, ...chunk.currentClientStudents],
    []
  );
  const viewsToAnswersByChunk = allGraphChunks.map(
    (chunk) => chunk.currentClientPrograms[0]?.interactionViewsToAnswers ?? []
  );
  const interactionViewsToAnswers =
    viewsToAnswersByChunk.length === 0
      ? []
      : viewsToAnswersByChunk.reduce(
          (totalViewsToAnswers, chunkViewsToAnswers) =>
            totalViewsToAnswers.map(([views, answers], coursePageIndex) => [
              views + chunkViewsToAnswers[coursePageIndex][0],
              answers + chunkViewsToAnswers[coursePageIndex][1],
            ])
        );

  // Pie chart data
  const pieChartData = {
    Finished: 0,
    'In progress': 0,
    'Not started': 0,
  };
  studentsWithAnswers.forEach(({ statuses = {} }) => {
    const { status } = statuses[courseId] ?? {};
    const dataStatus = status === 'Certified' ? 'Finished' : status;
    pieChartData[dataStatus] = (pieChartData[dataStatus] ?? 0) + 1;
  });

  const finishedStudentsPercent = 100 * (pieChartData.Finished / filteredCount);
  const studentsInProgressPercent =
    100 * (pieChartData['In progress'] / filteredCount);
  const notLoggedInStudentsPercent =
    100 * (pieChartData['Not started'] / filteredCount);
  const total =
    pieChartData.Finished +
    pieChartData['In progress'] +
    pieChartData['Not started'];
  const totalPercent = parseFloat(100 * (total / (filteredCount || 1))).toFixed(
    1
  );

  return {
    chapters,
    fetchNextPage: graphQuery.fetchNextPage,
    filteredCount,
    finishedStudentsPercent,
    interactionCount,
    interactionOptions,
    interactionViewsToAnswers,
    isFetchingNextPage: graphQuery.isFetchingNextPage,
    isLoading: graphQuery.isLoading,
    notLoggedInStudentsPercent,
    pieChartData,
    studentsInProgressPercent,
    studentsWithAnswers,
    total,
    totalCount,
    totalPercent,
  };
};

async function fetchProgressGraph({
  programId,
  pageParam,
  searchString,
  selectedCompanies,
  selectedDepartments,
  selectedStatuses,
}) {
  const res = await API.getClientProgressGraph({
    programId,
    currentPageNumber: pageParam,
    itemsPerPage: ITEMS_PER_PAGE,
    searchString,
    sortAttribute: 'email',
    sortOrder: 1,
    companies: selectedCompanies,
    departments: selectedDepartments,
    statuses: selectedStatuses,
  });
  return { ...res.data, pageParam };
}
