import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import * as actions from '../../actions';
import {
  email,
  name,
  composeValidators
} from '../../utils/validationUtils';
import MultiselectCheckbox from '../styleguideComponents/MultiselectCheckbox';
import PageBreadCrumbs from '../styleguideComponents/PageBreadCrumbs';
import TextInputField from '../styleguideComponents/TextInputField';
import FinalFormRadioButton from '../styleguideComponents/FinalFormRadioButton';
import ButtonSecondaryOutlined from '../styleguideComponents/ButtonSecondaryOutlined';
import ButtonPrimaryBlue from '../styleguideComponents/ButtonPrimaryBlue';
import plus_icon_white from '../../images/styleguideComponentsImages/plus_icon_white.svg';
import './AddAdminForm.css';

/**
* AddAdminForm: Component where details can be entered in order to create
* a new Admin
*/
const AddAdminForm = () => {
  const dispatch = useDispatch();
  const loadingState = useSelector(state => state.componentStates.loadingToastActive);
  const addAdminNameRef = useRef('addAdminNameRef');

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const clientId = query.get('clientId');

  const [superAdmin, setSuperAdmin] = useState(false);
  const submitButtonDisabled = useSelector(state => state.componentStates.submitButtonDisabled);
  const clientList = useSelector(state => state.clients.clientNames);

  const [permissionRead, setPermissionRead] = useState(false);
  const [permissionEdit, setPermissionEdit] = useState(false);
  const [permissionInvite, setPermissionInvite] = useState(false);
  const [permissionFullRights, setPermissionFullRights] = useState(true);

  useEffect(() => dispatch(actions.getClientNames.request()), []);

  const permissionsObject = {
    read: permissionRead,
    edit: permissionEdit,
    invite: permissionInvite,
    fullRights: permissionFullRights
  };

  const handleSubmit = (data) => {
    const isSuperAdmin = superAdmin;

    dispatch(actions.createAdmin.request(
      data.addAdminName,
      data.addAdminEmail,
      isSuperAdmin,
      data.addAdminClient,
      clientId ? `/clients/${clientId}/clientoverview` : null,
      permissionsObject
    ));
    dispatch(actions.setToastOrigin.request('/admins/addadmin'));
  };

  useEffect(() => {
    if (permissionFullRights) {
      setPermissionRead(false);
      setPermissionEdit(false);
      setPermissionInvite(false);
    }
  }, [permissionFullRights]);

  const handleToggleFullRights = () => setPermissionFullRights(!permissionFullRights);
  
  const handleTogglePermissionRead = () => {
    setPermissionRead(!permissionRead);
    setPermissionFullRights(false);
  };

  const handleTogglePermissionEdit = () => {
    setPermissionEdit(!permissionEdit);
    setPermissionFullRights(false);
  };

  const handleTogglePermissionInvite = () => {
    setPermissionInvite(!permissionInvite);
    setPermissionFullRights(false);
  };

  const isPermissionSelected = permissionRead || permissionEdit || permissionInvite || permissionFullRights;

  return (
    <div>
      <PageBreadCrumbs labelsArray={[{text: "Admins", link: "/admins"}, {text: "Create admin"}]} />
      <div className="addAdminFormTitle">Create admin</div>
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          adminType: "clientAdmin",
          addAdminClient: clientId ? clientId : "placeholder",
        }}
      >
        {({ handleSubmit, values, errors, form, pristine }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Field
              name="addAdminName"
              validate={composeValidators(name)}
            >
              {({ input, meta }) => (
                <TextInputField
                  label="Name"
                  input={input}
                  meta={meta}
                  fieldRef={addAdminNameRef}
                  calculateTextLength={calculateNameLength}
                  mustHaveCounter={true}
                  type="text"
                  placeholder="John Doe"
                  minLength="1"
                  maxLength="30"
                  required={true}
                />
              )}
            </Field>
            <div className="addAdminFieldWrapper">
              <Field
                name="addAdminEmail"
                validate={composeValidators(email)}
              >
                {({ input, meta }) => (
                  <TextInputField
                    label="Email"
                    input={input}
                    meta={meta}
                    mustHaveCounter={false}
                    type="email"
                    placeholder="john.doe@email.com"
                    required={true}
                  />
                )}
              </Field>
            </div>
            <div className="addAdminFieldWrapper">
              <div className={clientId ? "addAdminSubTitle addAdminDisabledField" : "addAdminSubTitle"}>Admin type</div>
              <div className="addAdminTypeButtonsContainer">
                <div className="addAdminTypeTopButton">
                  <FinalFormRadioButton
                    label="Client admin"
                    name="adminType"
                    value="clientAdmin"
                    onClick={() => setSuperAdmin(false)}
                    disabled={clientId}
                    required={true}
                    checked={superAdmin === false}
                  />
                </div>
                <FinalFormRadioButton
                  label="Super admin"
                  name="adminType"
                  value="superAdmin"
                  onClick={() => setSuperAdmin(true)}
                  disabled={clientId}
                  required={true}
                  checked={superAdmin === true}
                />
              </div>
            </div>
            {!superAdmin &&
              <>
                <div className={clientId ? "addAdminDisabledField addAdminFieldContainer" : "addAdminFieldContainer"}>
                  <div className="addAdminSubTitle addAdminClientDropdownLabel">Client</div>
                  <div className="addAdminClientDropdownContainer">
                    <Field
                      name="addAdminClient"
                      options={clientList}
                      placeholder="Choose client"
                      component={renderDropdown}
                      disabled={clientId}
                    />
                  </div>
                </div>
                <div className="addAdminSubTitle">Client admin settings</div>
                <div className="addAdminSettingsCheckboxesWrapper">
                  <MultiselectCheckbox
                    label='Full rights'
                    onClick={handleToggleFullRights}
                    checked={permissionFullRights}
                  />
                  <MultiselectCheckbox
                    label='Read'
                    onClick={handleTogglePermissionRead}
                    checked={permissionRead}
                  />
                  <MultiselectCheckbox
                    label='Edit'
                    onClick={handleTogglePermissionEdit}
                    checked={permissionEdit}
                  />
                  <MultiselectCheckbox
                    label='Invite'
                    onClick={handleTogglePermissionInvite}
                    checked={permissionInvite}
                  />
                </div>
              </>
            }
            <div className="addAdminActioButtonsWrapper">
              <div className="addAdminActioButtonsSpacing">
                <Link to="/admins" exact="true" className="addAdminCancelButton">
                  <ButtonSecondaryOutlined type="button" label="Cancel" />
                </Link>
              </div>
              <div className="addAdminActioButtonsSpacing">
                <ButtonSecondaryOutlined
                  type="button"
                  label="Clear form"
                  disabled={pristine}
                  onClick={() => handleClearForm(form, setSuperAdmin, setPermissionRead, setPermissionEdit, setPermissionInvite, setPermissionFullRights, dispatch)}
                />
              </div>
              <ButtonPrimaryBlue
                type="submit"
                label={loadingState ? "Creating admin" : "Create admin"}
                disabled={!allFieldsFilledIn(superAdmin, values, errors, isPermissionSelected) || submitButtonDisabled}
                iconSource={plus_icon_white}
                loading={loadingState}
              />
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};

const calculateNameLength = (addAdminNameRef) => {
  if (!addAdminNameRef.current.value) {
    return 0;
  } else {
    return addAdminNameRef.current.value.length;
  }
};

export const renderDropdown = ({ input, ...custom }) => {
  return (
    <select
      {...input}
      className="addAdminSelect"
      disabled={custom.disabled}
    >
      <option value="placeholder" disabled hidden>{custom.placeholder}</option>
      {custom.options.map((client) => {
        return (
          <option key={client._id} value={client._id}>{client.name}</option>
        );
      })}
    </select>
  );
};

const allFieldsFilledIn = (superAdmin, values, errors, isPermissionSelected) => {
  if (!superAdmin) {
    return (
      values &&
      values.addAdminName &&
      values.addAdminEmail &&
      values.adminType &&
      values.addAdminClient &&
      values.addAdminClient !== "placeholder" &&
      Object.keys(errors).length === 0 &&
      isPermissionSelected
    );
  } else {
    return values && values.addAdminName && values.addAdminEmail && values.adminType && Object.keys(errors).length === 0;
  }
};

const handleClearForm = (form, setSuperAdmin, setPermissionRead, setPermissionEdit, setPermissionInvite, setPermissionFullRights, dispatch) => {
  form.restart();
  setSuperAdmin(false);
  setPermissionRead(false);
  setPermissionEdit(false);
  setPermissionInvite(false);
  setPermissionFullRights(true);
  dispatch(actions.enableSubmitButton.request());
};

export default AddAdminForm;
