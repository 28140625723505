import React from 'react';
import ButtonSecondaryOutlined from '../components/styleguideComponents/ButtonSecondaryOutlined';
import ButtonSecondaryOutlinedRed from '../components/styleguideComponents/ButtonSecondaryOutlinedRed';
import envelope_icon from '../images/styleguideComponentsImages/envelope_icon.svg';
import plus_icon_black from '../images/styleguideComponentsImages/plus_icon_black.svg';
import trashbin_icon_white from '../images/styleguideComponentsImages/trashbin_icon_white.svg';
import trashbin_icon_red from '../images/styleguideComponentsImages/trashbin_icon_red.svg';
import trashbin_icon_disabled from '../images/styleguideComponentsImages/trashbin_icon_disabled.svg';
import './UsersSelectActions.css';

/**
 * UsersSelectActions: Appears on the Users data table within a Program
 * It will only show if any Users have been selected and will provide some
 * possibles actions to do for the selected Users
 */
const UsersSelectActions = ({
  selectedItemsCount,
  deselectAllItems,
  setOpenResendEmailModalSelected,
  setOpenDeleteUserModalSelected,
  selectedNonFinishedCount,
  openRightPanel,
  setOpenRightPanel,
}) => {
  const clearSelection = () => {
    deselectAllItems();
    setOpenRightPanel(false);
  };

  const disableButton = selectedItemsCount === 0;

  return (
    <>
      <div
        className={`userSelectActionsWrapper ${
          selectedItemsCount > 0 ? 'userSelectActionsWrapperShown' : ''
        }`}
      >
        <div className="userSelectActionsCounter">{`${selectedItemsCount} ${
          selectedItemsCount === 1 ? 'USER' : 'USERS'
        } SELECTED`}</div>
        <div className="userSelectActionsButtonsWrapper">
          <div className="userSelectActionsButtonSpacing">
            <ButtonSecondaryOutlined
              type="button"
              label="Clear selection"
              disabled={disableButton}
              onClick={() => clearSelection()}
            />
          </div>
          <div className="userSelectActionsButtonSpacing">
            <ButtonSecondaryOutlined
              type="button"
              label={`Resend invitation (${selectedNonFinishedCount})`}
              disabled={disableButton || selectedNonFinishedCount === 0}
              onClick={() => setOpenResendEmailModalSelected(true)}
              icon={envelope_icon}
            />
          </div>
          <div className="userSelectActionsButtonSpacing">
            <ButtonSecondaryOutlined
              type="button"
              label={`Invite to programs (${selectedItemsCount})`}
              disabled={disableButton}
              onClick={() => setOpenRightPanel(!openRightPanel)}
              icon={plus_icon_black}
              activeState={openRightPanel}
            />
          </div>
          <div className="userSelectActionsButtonSpacingLast">
            <ButtonSecondaryOutlinedRed
              type="button"
              label={`Remove ${
                selectedItemsCount === 1 ? 'user' : 'users'
              } (${selectedItemsCount})`}
              disabled={disableButton}
              onClick={() => setOpenDeleteUserModalSelected(true)}
              iconSource={trashbin_icon_red}
              iconSourceOnHover={trashbin_icon_white}
              iconSourceDisabled={trashbin_icon_disabled}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UsersSelectActions;
