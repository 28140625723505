import React from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import * as actions from '../../actions';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import {
  hasMinLength,
  containsNumber,
  containsUppercaseLetter,
  containsLowercaseLetter,
  composeValidators
} from '../../utils/validationUtils';
import './ChangePasswordNoCodeForm.css';
import '../Common.css';

const mapStateToProps = (state) => {
  return {
    formsubmitted: state.componentStates.formSubmitted,
    cognitoUser: state.user.cognitoUser
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    newPasswordSubmit: (cognitoUser, newPassword) => {
      dispatch(actions.newPasswordSubmit.request(cognitoUser, newPassword));
    }
  };
};


/**
* ChangePasswordNoCodeForm handles changing a logged in users password
*/
const ChangePasswordNoCodeForm = ({
    newPasswordSubmit,
    formsubmitted,
    cognitoUser
  }) => {
  return (
    <Form
      onSubmit={(data) => newPasswordSubmit(cognitoUser, data.newPassword)}
      render = {({ handleSubmit, values, errors }) => {
        let lengthRequirementClasses = !hasMinLength(values.newPassword) ? "changePasswordListItems fullfilledRequirement" : "changePasswordListItems";
        let numberRequirementClasses = !containsNumber(values.newPassword) ? "changePasswordListItems fullfilledRequirement" : "changePasswordListItems";
        let uppercaseRequirementClasses = !containsUppercaseLetter(values.newPassword) ? "changePasswordListItems fullfilledRequirement" : "changePasswordListItems";
        let lowercaseRequirementClasses = !containsLowercaseLetter(values.newPassword) ? "changePasswordListItems fullfilledRequirement" : "changePasswordListItems";
        return (
          <form className="changePasswordNoCodeForm" autoComplete="off" onSubmit={handleSubmit}>
          <Field
            name="newPassword"
            className="changePasswordNoCodeField"
            type="password"
            label="New password"
            validate={composeValidators(hasMinLength, containsNumber, containsUppercaseLetter, containsLowercaseLetter)}
            formsubmitted={formsubmitted.toString()}
            component={renderTextField}
          />
          <ul className="changePasswordNoCodeText">
            <li className={lengthRequirementClasses}>At least 8 characters</li>
            <li className={numberRequirementClasses}>At least one number</li>
            <li className={uppercaseRequirementClasses}>At least one uppercase letter</li>
            <li className={lowercaseRequirementClasses}>At least one lowercase letter</li>
          </ul>
          {Object.keys(errors).length === 0 && formsubmitted ?
            <CircularProgress className="changePasswordNoCodeLoadingIcon" thickness={3}/>
            :
            <button
              className="changePasswordNoCodeButton button purpleButton"
              disabled={!passwordFieldNotEmpty(values) || Object.keys(errors).length > 0}
              onSubmit={handleSubmit}
            >
              Save password
            </button>
          }
        </form>
        );
      }}
    />
  );
};


/**
* passwordFieldNotEmpty checks if the new password field is not empty
*/
const passwordFieldNotEmpty = (values) => {
  return values && values.newPassword;
};

/* Custom theme for Material UI's TextField component */
const theme = createMuiTheme({
  typography: {
    fontSize: 13,
    fontFamily: "RobotoRegular"
  },
  overrides: {
    MuiFormLabel: {
      "root": {
        "color": "#414141",
        "marginLeft": 12
      }
    }
  }
});

/**
* renderTextField() renders the material UI TextField component
*/
const renderTextField = ({
  input,
  meta: {touched, error},
  ...custom
}) => (
  <ThemeProvider theme={theme}>
    <TextField
      {...input}
      {...custom}
      error={touched && error && custom.formsubmitted ? true : false}
      helperText={touched && error && custom.formsubmitted ? error : null}
      label={custom.label}
    />
  </ThemeProvider>
);

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordNoCodeForm);
