import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '../../actions';
import CourseCoreMoveTooltip from './CourseCoreMoveTooltip';
import CourseCoreDeleteTooltip from './CourseCoreDeleteTooltip';
import slider_icon from '../../images/svg/slider_icon.svg';
import draggable_handle from '../../images/svg/draggable_handle.svg';
import draggable_handle_onhover from '../../images/svg/draggable_handle_onhover.svg';
import red_x_mark from '../../images/svg/red_x_mark.svg';
import red_x_mark_onhover from '../../images/svg/red_x_mark_onhover.svg';
import scale_warning_icon from '../../images/svg/scale_warning_icon.svg';
import './ScaleComponent.css';

/**
* ScaleComponent: answer component that generates a slider and gathers 4 values for min, max, correct
* and step increment
*/

const ScaleComponent = ({
  moduleId,
  moduleItemId,
  moduleType,
  provided,
  numberOfComponents,
  autoFocus,
  parentId,
  minValue,
  correctValue,
  maxValue,
  stepIncrement,
  score
}) => {
  const dispatch = useDispatch();
  const [localMinValue, setLocalMinValue] = useState(minValue);
  const [localCorrectValue, setLocalCorrectValue] = useState(correctValue);
  const [localMaxValue, setLocalMaxValue] = useState(maxValue);
  const [localStepIncrement, setLocalStepIncrement] = useState(stepIncrement);
  const [questionPoints, setQuestionPoints] = useState(score >= 0 ? score : 0);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [errorOnMinValue, setErrorOnMinValue] = useState(false);
  const [errorOnCorrectValue, setErrorOnCorrectValue] = useState(false);
  const [errorOnMaxValueFirst, setErrorOnMaxValueFirst] = useState(false);
  const [errorOnMaxValueSecond, setErrorOnMaxValueSecond] = useState(false);
  const [errorOnStepValueFirst, setErrorOnStepValueFirst] = useState(false);
  const [errorOnStepValueSecond, setErrorOnStepValueSecond] = useState(false);
  const areThereErrors = errorOnMinValue || errorOnCorrectValue || errorOnMaxValueFirst || errorOnMaxValueSecond || errorOnStepValueFirst || errorOnStepValueSecond;

  useEffect(() => {
    let debouncedSaveFunction = setTimeout(
      () => {
        if (hasLoaded) {
          dispatch(actions.updateComponent.request(
            moduleId,
            moduleItemId,
            {
              max: localMaxValue,
              min: localMinValue,
              numCorrect: localCorrectValue,
              step: localStepIncrement
            },
            moduleType,
            { score: questionPoints }
          ));
        }
        setHasLoaded(true);
      },
      500
    );
    return () => clearTimeout(debouncedSaveFunction);
  }, [localMaxValue, localMinValue, localCorrectValue, localStepIncrement, questionPoints]);

  useEffect(() => {
    let debouncedSaveFunction = setTimeout(
      () => {
        handleChangeMinValue(localMinValue);
      },
      400
    );
    return () => clearTimeout(debouncedSaveFunction);
  }, [localMinValue]);
  useEffect(() => {
    let debouncedSaveFunction = setTimeout(
      () => {
        handleChangeCorrectValue(localCorrectValue);
      },
      400
    );
    return () => clearTimeout(debouncedSaveFunction);
  }, [localCorrectValue]);
  useEffect(() => {
    let debouncedSaveFunction = setTimeout(
      () => {
        handleChangeMaxValue(localMaxValue);
      },
      400
    );
    return () => clearTimeout(debouncedSaveFunction);
  }, [localMaxValue]);
  useEffect(() => {
    let debouncedSaveFunction = setTimeout(
      () => {
        handleChangeStepValue(localStepIncrement);
      },
      400
    );
    return () => clearTimeout(debouncedSaveFunction);
  }, [localStepIncrement]);

  useEffect(() => {
    let debouncedSaveFunction = setTimeout(
      () => {
        handleValuesChange();
      },
      400
    );
    return () => clearTimeout(debouncedSaveFunction);
  }, [localMaxValue, localMinValue, localCorrectValue, localStepIncrement]);

  const handleValuesChange = () => {
    parseInt(localMinValue) > parseInt(localCorrectValue) ? setErrorOnMinValue(true) : setErrorOnMinValue(false);
    parseInt(localCorrectValue) > parseInt(localMaxValue) ? setErrorOnCorrectValue(true) : setErrorOnCorrectValue(false);
    parseInt(localMaxValue) < parseInt(localMinValue) ? setErrorOnMaxValueFirst(true) : setErrorOnMaxValueFirst(false);
    parseInt(localMaxValue) === 0 ? setErrorOnMaxValueSecond(true) : setErrorOnMaxValueSecond(false);
    parseInt(localStepIncrement) > parseInt(localMaxValue) ? setErrorOnStepValueFirst(true) : setErrorOnStepValueFirst(false);
    parseInt(localStepIncrement) === 0 ? setErrorOnStepValueSecond(true) : setErrorOnStepValueSecond(false);
  };

  const handleChangeMinValue = (value) => {
    if (value < 0) {
      setLocalMinValue(0);
    }
    else if (value > 10000) {
      setLocalMinValue(10000);
    }
    else {
      setLocalMinValue(value);
    }
  };

  const handleChangeCorrectValue = (value) => {
    if (value < 0) {
      setLocalCorrectValue(0);
    }
    else if (value > 10000) {
      setLocalCorrectValue(10000);
    }
    else {
      setLocalCorrectValue(value);
    }
  };

  const handleChangeMaxValue = (value) => {
    if (value < 0) {
      setLocalMaxValue(0);
    }
    else if (value > 10000) {
      setLocalMaxValue(10000);
    }
    else {
      setLocalMaxValue(value);
    }
  };

  const handleChangeStepValue = (value) => {
    if (value < 0) {
      setLocalStepIncrement(0);
    }
    else {
      setLocalStepIncrement(value);
    }
  };

  const handleSetQuestionPoints = (newQuestionPoints) => {
    if (newQuestionPoints < 0) {
      setQuestionPoints(0);
    }
    else if (newQuestionPoints > 1000) {
      setQuestionPoints(1000);
    }
    else {
      setQuestionPoints(newQuestionPoints);
    }
  };
  return (
    <div className="scaleComponentContainer">
      <img
        className={numberOfComponents === 1 ? "scaleComponentIcon scaleComponentIconAlone" : "scaleComponentIcon"}
        src={slider_icon}
      />
      {numberOfComponents > 1 &&
        <div className="scaleComponentTooltipParent" {...provided.dragHandleProps}>
          <img className="scaleComponentDraggableHandle" src={draggable_handle}/>
          <img className="scaleComponentDraggableHandleOnHover" src={draggable_handle_onhover}/>
          <CourseCoreMoveTooltip x={"-136%"} y={"-195%"}/>
        </div>
      }
      <div className="scaleComponentContentWrapper">
        {areThereErrors && renderErrorMessages(errorOnMinValue, errorOnCorrectValue, errorOnMaxValueFirst, errorOnMaxValueSecond, errorOnStepValueFirst, errorOnStepValueSecond)}
        <div className="scaleComponentTitleWrapper">
          <img className="scaleComponentTitleIcon" src={slider_icon}/>
          <div className="scaleComponentTitleText">Slider</div>
        </div>
        <div className="scaleComponentValuesTitleWrapper">
          <div className="scaleComponentValueTitleText scaleComponentValueLeftTitle">MIN VALUE</div>
          <div className="scaleComponentValueTitleText">CORRECT VALUE</div>
          <div className="scaleComponentValueTitleText scaleComponentValueRightTitle">MAX VALUE</div>
        </div>
        <div className="scaleComponentValuesInputsWrapper">
          <div className="scaleComponentValueAndIndicator">
            <input
              type="number"
              name="scaleMinValue"
              className={errorOnMinValue || errorOnMaxValueFirst ? "scaleComponentValuesInput scaleComponentSliderValues scaleComponentValuesInputWithError" : "scaleComponentValuesInput scaleComponentSliderValues"}
              min="0"
              max="10000"
              autoFocus={autoFocus}
              value={localMinValue}
              onChange={(event) => setLocalMinValue(event.target.value)}
            />
            <div className="scaleComponentValueInputIndicator"></div>
          </div>
          <div className="scaleComponentValueAndIndicator">
            <input
              type="number"
              name="scaleCorrectValue"
              className={errorOnCorrectValue || errorOnMinValue ? "scaleComponentValuesInput scaleComponentSliderValues scaleComponentValuesInputWithError" : "scaleComponentValuesInput scaleComponentSliderValues"}
              min="0"
              max="10000"
              value={localCorrectValue}
              onChange={(event) => setLocalCorrectValue(event.target.value)}
            />
            <div className="scaleComponentValueInputIndicator"></div>
          </div>
          <div className="scaleComponentValueAndIndicator">
            <input
              type="number"
              name="scaleMaxValue"
              className={errorOnMaxValueFirst || errorOnMaxValueSecond || errorOnCorrectValue || errorOnStepValueFirst ? "scaleComponentValuesInput scaleComponentSliderValues scaleComponentValuesInputWithError" : "scaleComponentValuesInput scaleComponentSliderValues"}
              min="0"
              max="10000"
              value={localMaxValue}
              onChange={(event) => setLocalMaxValue(event.target.value)}
            />
            <div className="scaleComponentValueInputIndicator"></div>
          </div>
        </div>
        <div className="scaleComponentCustomSliderWrapper">
          <input type="range" min="0" max="100" readOnly={true} value="50"/>
        </div>
        <div className="scaleComponentValueTitleText">STEP INCREMENT</div>
        <input
          type="number"
          name="stepIncrementValue"
          className={errorOnStepValueFirst || errorOnStepValueSecond ? "scaleComponentValuesInput scaleComponentIncrementInput scaleComponentValuesInputWithError" : "scaleComponentValuesInput scaleComponentIncrementInput"}
          value={localStepIncrement}
          onChange={(event) => setLocalStepIncrement(event.target.value)}
        />
        <div className="scaleComponentValueTitleText">POINTS FOR CORRECT ANSWER (MAX 1000)</div>
        <input
          type="number"
          name="questionPointsValue"
          className="scaleComponentValuesInput scaleComponentIncrementInput"
          min="0"
          max="1000"
          value={questionPoints}
          onChange={(event) => handleSetQuestionPoints(event.target.value)}
        />
      </div>
      <div className="scaleComponentXMarkContainer">
        <img className="scaleComponentXMark" src={red_x_mark}/>
        <img
          className="scaleComponentXMarkOnHover"
          src={red_x_mark_onhover}
          onClick={() => dispatch(actions.deleteComponent.request(moduleId, moduleItemId, moduleType, parentId))}
        />
        <CourseCoreDeleteTooltip x={"26%"} y={"-195%"}/>
      </div>
    </div>
  );
};

const renderErrorMessages = (errorOnMinValue, errorOnCorrectValue, errorOnMaxValueFirst, errorOnMaxValueSecond, errorOnStepValueFirst, errorOnStepValueSecond) => {
  return (
    <div className="scaleComponentErrorsContainer">
      <div className="scaleComponentWarningAndIconWrapper">
        <img className="scaleComponentWarningIcon" src={scale_warning_icon}/>
        <div className="scaleComponentWarningTitle">Warning</div>
      </div>
      {errorOnMinValue &&
        <div className="scaleComponentWarningTextWrapper">
          <div className="scaleComponentWarningText">
            The <span className="scaleComponentWarningTextBold">Min</span> value <span className="scaleComponentWarningTextBold">can&apos;t be bigger</span> than the <span className="scaleComponentWarningTextBold">Correct</span> value
          </div>
        </div>
      }
      {errorOnCorrectValue &&
        <div className="scaleComponentWarningTextWrapper">
          <div className="scaleComponentWarningText">
            The <span className="scaleComponentWarningTextBold">Correct</span> value <span className="scaleComponentWarningTextBold">can&apos;t be bigger</span> than the <span className="scaleComponentWarningTextBold">Max</span> value
          </div>
        </div>
      }
      {errorOnMaxValueFirst &&
        <div className="scaleComponentWarningTextWrapper">
          <div className="scaleComponentWarningText">
            The <span className="scaleComponentWarningTextBold">Max</span> value <span className="scaleComponentWarningTextBold">can&apos;t be smaller</span> than the <span className="scaleComponentWarningTextBold">Min</span> value
          </div>
        </div>
      }
      {errorOnMaxValueSecond &&
        <div className="scaleComponentWarningTextWrapper">
          <div className="scaleComponentWarningText">
            The <span className="scaleComponentWarningTextBold">Max</span> value <span className="scaleComponentWarningTextBold">can&apos;t be zero</span>
          </div>
        </div>
      }
      {errorOnStepValueFirst &&
        <div className="scaleComponentWarningTextWrapper">
          <div className="scaleComponentWarningText">
            The <span className="scaleComponentWarningTextBold">Step</span> increment <span className="scaleComponentWarningTextBold">can&apos;t be bigger</span> than the <span className="scaleComponentWarningTextBold">Max</span> value
          </div>
        </div>
      }
      {errorOnStepValueSecond &&
        <div className="scaleComponentWarningTextWrapper">
          <div className="scaleComponentWarningText">
            The <span className="scaleComponentWarningTextBold">Step</span> increment <span className="scaleComponentWarningTextBold">can&apos;t be zero</span>
          </div>
        </div>
      }
    </div>
  );
};

export default ScaleComponent;
