import { create } from 'zustand';
import { useEffect } from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import API from '../api/api';

const ITEMS_PER_PAGE = 25;

export const useStore = create((set) => ({
  clientId: null,
  prevClientId: null,
  companiesNames: [],
  currentClientIndex: -1,
  departmentsNames: [],
  searchString: '',
  selectedCompanies: [],
  selectedDepartments: [],
  sortAttribute: 'username',
  sortOrder: 1,
  setAttrSortOrder(attr) {
    set((state) => ({
      sortAttribute: attr,
      sortOrder: state.sortAttribute === attr ? -state.sortOrder : 1,
    }));
  },
  setState: set,
}));

export const useProgressOverview = () => {
  const isSuperAdmin = useSelector((state) => state.user.isSuperAdmin);
  const defaultClientId = useSelector(
    (state) => state.user.permissions[0]?.clientId || null
  );

  const { data: clients } = useQuery('clients', getClientNames, {
    enabled: isSuperAdmin,
  });

  const store = useStore();
  const clientId =
    isSuperAdmin && clients
      ? clients[store.currentClientIndex]?._id || null
      : defaultClientId;

  const query = useInfiniteQuery(
    [
      'progressOverview',
      clientId,
      store.selectedCompanies,
      store.selectedDepartments,
      store.sortAttribute,
      store.sortOrder,
      store.searchString,
    ],
    ({ pageParam = 0 }) =>
      fetchProgressOverview({
        ...store,
        pageParam,
        clientId,
      }),
    {
      enabled: !!clientId,
      getNextPageParam: (lastPage) => lastPage.pageParam + 1,
    }
  );

  // "Computed" values
  const { data } = query;
  const clientNames = clients?.map(({ name }) => name) ?? [];
  const firstPage = data?.pages[0] ?? {};
  const {
    clientCompanies = [],
    clientDepartments = [],
    currentClientPrograms: programs = [],
    clientStudentsFilteredCount: filteredCount = 0,
    clientUsersTotalCount: totalCount = 0,
  } = firstPage;

  useEffect(() => {
    // Clear all filters when clearing or changing the client as a super admin
    if (clientId !== store.prevClientId) {
      store.setState({
        selectedCompanies: [],
        selectedDepartments: [],
        searchString: '',
        prevClientId: clientId,
      });
    }

    // Avoid emptying filters options when reloading results (since companies
    // and depts come in the same response as the table results)
    if (
      clientCompanies.length === 0 &&
      clientDepartments.length === 0 &&
      clientId === store.clientId
    ) {
      return;
    }

    store.setState({
      clientId,
      companiesNames: clientCompanies,
      departmentsNames: clientDepartments,
    });
  }, [clientId, clientCompanies, clientDepartments]);

  const allPages = data?.pages ?? [];
  const students = allPages.reduce(
    (acc, page) => [...acc, ...page.currentClientStudents],
    []
  );

  return {
    ...query,
    ...store,
    clientId,
    clientNames,
    filteredCount,
    publishedPrograms: programs.filter(({ isPublished }) => isPublished),
    students,
    totalCount,
  };
};

async function getClientNames() {
  const res = await API.getClientNames();
  return res.data;
}

async function fetchProgressOverview({
  clientId,
  pageParam,
  searchString,
  sortAttribute,
  sortOrder,
  selectedCompanies,
  selectedDepartments,
}) {
  const res = await API.getClientProgressOverview({
    clientId,
    currentPageNumber: pageParam,
    itemsPerPage: ITEMS_PER_PAGE,
    searchString,
    sortAttribute,
    sortOrder,
    companies: selectedCompanies,
    departments: selectedDepartments,
  });
  return { ...res.data, pageParam };
}
