import React from 'react';
import IntersectionObserverWrapper from './intersectionObserver/IntersectionObserver';
import LoadingText from './LoadingText';

const LazyLoadingTableFooter = ({ callback, colSpan, observerEnabled }) => (
  <tfoot className="courseUserTableFooter">
    <tr>
      <td colSpan={colSpan}>
        {observerEnabled ? (
          <IntersectionObserverWrapper
            options={{ threshold: 0 }}
            callback={callback}
          >
            <LoadingText label="Loading more users" />
          </IntersectionObserverWrapper>
        ) : (
          <LoadingText label="Loading more users" />
        )}
      </td>
    </tr>
  </tfoot>
);

export default LazyLoadingTableFooter;
