import React from 'react';
import autosave_checkmark_icon from '../../images/svg/autosave_checkmark_icon.svg';
import autosave_cloud_icon from '../../images/svg/autosave_cloud_icon.svg';
import './AutosaveConnectedComponent.css';

/**
* AutosaveConnectedComponent: icon used on the course core to show the connection status
*/

const AutosaveConnectedComponent = () => {
  return (
    <div className="autoSaveConnectedWrapper">
      <div className="autoSaveConnectedIconsWrapper">
        <img className="autoSaveConnectedCheckmark" src={autosave_checkmark_icon}/>
        <img className="" src={autosave_cloud_icon}/>
      </div>
      <div className="autoSaveConnectedText">Connected</div>
    </div>
  );
};

export default AutosaveConnectedComponent;
