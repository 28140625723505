import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import * as actions from '../../actions';
import Slider from "react-slick";
import red_x_mark_onhover from '../../images/svg/red_x_mark_onhover.svg';
import carousel_next_arrow from '../../images/svg/carousel_next_arrow.svg';
import carousel_prev_arrow from '../../images/svg/carousel_prev_arrow.svg';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './ImageCarouselComponent.css';

/**
* ImageCarouselComponent: Field which adds an Image Carousel to the Module, chosen from the Add Content dropdown
*/
const ImageCarouselComponent = ({ images, moduleItemId, moduleType }) => {
  const dispatch = useDispatch();
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <img className={className} onClick={onClick} src={carousel_next_arrow}/>
    );
  };
  const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <img className={className} onClick={onClick} src={carousel_prev_arrow}/>
    );
  };
  const settings = {
    nextArrow: <NextArrow/>,
    prevArrow: <PrevArrow/>
  };

  if (images) {
    return (
      <div className="imageCarouselWrapper">
        <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)} {...settings}>
          {images.map((item, index) => {
            return (
              <div key={`image-carousel-preview-${index}`}>
                <img className="imageCarouselBigPreview" src={process.env.REACT_APP_MEDIA_URL + images[index].name} />
              </div>
            );
          })}
        </Slider>
        <Slider
          asNavFor={nav1}
          ref={(slider2) => setNav2(slider2)}
          infinite= {images.length > 4}
          slidesToShow={4}
          swipeToSlide={true}
          focusOnSelect={true}
          className="imageCarouselThumbnailSlider"
          {...settings}
        >
          {images.map((item, index) => {
            return (
              <div className="imageCarouselThumbnailWrapper" key={`image-carousel-preview-${index}`}>
                <img className="imageCarouselThumbnail" src={process.env.REACT_APP_MEDIA_URL + images[index].name} />
                <img
                  className="imageCarouselThumbnailXMark"
                  src={red_x_mark_onhover}
                  onClick={() => dispatch(actions.removeImageCarouselImage.request(moduleItemId, images[index].name, moduleType))}
                />
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }
  return null;
};

export default ImageCarouselComponent;
