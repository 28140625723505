import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDayMonthYearFromDate } from '../../utils/dateUtils';
import { stableSort, getSorting } from '../../utils/statisticsUtils';
import { handleSortRequest, renderArrow } from '../../utils/componentUtils';
import * as actions from '../../actions';
import history from '../../store/history';
import Tooltip from '../Tooltip';
import hyperlink_icon from '../../images/svg/hyperlink_icon.svg';
import hyperlink_icon_BG from '../../images/svg/hyperlink_icon_BG.svg';
import './APITable.css';

/**
* APITable is a Super Admin view which lists all the API keys and details created
* for all Clients across the platform
*/
const APITable = () => {
  const dispatch = useDispatch();
  const allAPIKeys = useSelector(state => state.clients.allAPIKeys);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('client');

  useEffect(() => {
    dispatch(actions.getAllAPIKeys.request());
  }, []);

  let rows = allAPIKeys.map(key => {
    return createData(key.clientName, key.keyId, key.createdAt, key.userName, key.clientId);
  });

  return (
    <>
      <table className="APITable">
        <tbody>
          <tr>
            <th>
              <div className="APIClientNameAndIconContainer APITableClickableTitle" onClick={() => handleSortRequest('client', order, setOrder, orderBy, setOrderBy)}>
                <div className="APITableTitle">Client</div>
                {renderArrow("client", order, orderBy)}
              </div>
            </th>
            <th>
              <div className="APITableTitleAndIconContainer APITableClickableTitle" onClick={() => handleSortRequest('keyID', order, setOrder, orderBy, setOrderBy)}>
                <div className="APITableTitle">Key ID</div>
                {renderArrow("keyID", order, orderBy)}
              </div>
            </th>
            <th>
              <div className="APITableTitleAndIconContainer APITableClickableTitle" onClick={() => handleSortRequest('createdDate', order, setOrder, orderBy, setOrderBy)}>
                <div className="APITableTitle">Created</div>
                {renderArrow("createdDate", order, orderBy)}
              </div>
            </th>
            <th>
              <div className="APITableTitleAndIconContainer APITableClickableTitle" onClick={() => handleSortRequest('creator', order, setOrder, orderBy, setOrderBy)}>
                <div className="APITableTitle">Creator</div>
                {renderArrow("creator", order, orderBy)}
              </div>
            </th>
            <th className="APITableTitle"></th>
          </tr>
          {stableSort(rows, getSorting(order, orderBy)).map((row, index) => (
            <tr className="APITableRow" key={index}>
              <td className="APITableText APINameValue">{row.client}</td>
              <td className="APITableText APIIDValue">{row.keyID.substring(0,4)}</td>
              <td className="APITableText APIDateValue">{getDayMonthYearFromDate(row.createdDate)}</td>
              <td className="APITableText APICreatorValue">{row.creator}</td>
              <td className="APITableMenuCell">
                <div className="APITableMenuIconsContainer" onClick={() => history.push(`/clients/${row.clientId}/clientoverview`)}>
                  <img className="APITableMenuIcon APITableMenuIconGrey" src={hyperlink_icon} alt="hyperlink icon"/>
                  <img className="APITableMenuIconBlackBG" src={hyperlink_icon_BG} alt="hyperlink icon"/>
                  <Tooltip text="Go to client" width="65"/>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {allAPIKeys.length === 0 && <div className="APITableEmptyTableText">No API keys to show yet...</div>}
    </>
  );
};

const createData = (client, keyID, createdDate, creator, clientId) => {
  return { client, keyID, createdDate, creator, clientId };
};

export default APITable;
