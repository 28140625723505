import { downloadBatchAddReport } from './use-download-report';
import { Modal } from '@material-ui/core';
import ButtonSecondaryOutlined from '../components/styleguideComponents/ButtonSecondaryOutlined';
import LoadingText from '../components/LoadingText.js';
import React from 'react';
import styles from './BatchAddHistoryModal.module.css';

const dateFormatter = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'medium',
  timeStyle: 'short',
});

const BatchUploadHistoryModal = ({ batches, loading, shown, onClose }) => {
  const getBatchStatus = (batch) => {
    const { pending = 0, total = 0 } = batch;
    const percentage = Math.floor(((total - pending) / total) * 100);
    return percentage === 100 ? 'Completed' : percentage + '%';
  };

  return (
    <Modal
      id="uploadHistoryModal"
      className="batchUploadModal"
      open={shown}
      onClose={onClose}
    >
      <div className={styles.container}>
        <h3>Upload history</h3>
        {loading && <LoadingText label="Loading previous uploads" />}
        {batches.length > 0 && (
          <div className={styles.tableWrapper}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Uploader</th>
                  <th>Added</th>
                  <th>Failed</th>
                  <th>Skipped</th>
                  <th>Total</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {batches.map((batch) => (
                  <tr key={batch.batchId}>
                    <td>{dateFormatter.format(new Date(batch.createdAt))}</td>
                    <td>{batch.uploader ?? 'Unknown'}</td>
                    <td>{batch.added ?? 0}</td>
                    <td>{batch.error ?? 0}</td>
                    <td>
                      {(batch.already ?? 0) +
                        (batch.quota ?? 0) +
                        (batch.duplicated ?? 0)}
                    </td>
                    <td>{batch.total ?? 0}</td>
                    <td>{getBatchStatus(batch)}</td>
                    <td
                      className={styles.downloadReportButton}
                      onClick={() => downloadBatchAddReport(batch.batchId)}
                    >
                      Download report
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div className={styles.closeButtonWrapper}>
          <ButtonSecondaryOutlined
            type="button"
            label="Close"
            onClick={onClose}
          />
        </div>
      </div>
    </Modal>
  );
};

export default BatchUploadHistoryModal;
