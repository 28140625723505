import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useParams } from 'react-router-dom';
import * as actions from '../actions';
import PublishModal from './PublishModal';
import { useOutsideClick } from '../utils/componentUtils';
import downward_arrow_smooth from '../images/svg/downward_arrow_smooth.svg';
import pencil_icon_grey from '../images/svg/pencil_icon_grey.svg';
import three_dots from '../images/svg/three_dots.svg';
import three_dots_black_bg from '../images/svg/three_dots_black_bg.svg';
import user from '../images/svg/user.svg';
import logout_arrow from '../images/svg/logout_arrow.svg';
import toggle_menu_icon from '../images/svg/toggle_menu_icon.svg';
import green_toggle_icon from '../images/svg/green_toggle_icon.svg';
import chart_icon from '../images/svg/chart_icon.svg';
import chart_icon_purple from '../images/svg/chart_icon_purple.svg';
import settings_wheel_icon from '../images/svg/settings_wheel_icon.svg';
import settings_wheel_icon_purple from '../images/svg/settings_wheel_icon_purple.svg';
import plus_grey_thin from '../images/svg/plus_grey_thin.svg';
import './CourseLeftMenu.css';
import Const from '../store/Const';

/**
* CourseLeftMenu: is the left sidebar Menu that allows the user to edit a Course and change
* settings
*/
const CourseLeftMenu = () => {
  const dispatch = useDispatch();
  const currentPath = window.location.pathname.split('/');
  const [openUsersDropdownMenu, setOpenUsersDropdownMenu] = useState(false);
  const [openPublishModal, setOpenPublishModal] = useState(false);

  const { courseId } = useParams();
  const currentUser = useSelector(state => state.user);
  const isSuperAdmin = currentUser.isSuperAdmin;
  const userPermissions = currentUser.permissions[0] ?? {};
  const currentProgram = useSelector(state => state.programs.currentProgram);
  const currentProgramName = useSelector(state => state.programs.currentProgram.name);
  const modules = useSelector(state => state.programs.currentProgram.modules);

  useEffect(() => {
    dispatch(actions.getCourse.request(courseId));
    dispatch(actions.getModuleContent.request(courseId, Const.moduleType.Welcome));
  }, []);
  
  const handleUsersDropdownMenu = (event) => {
    event.preventDefault();
    setOpenUsersDropdownMenu(!openUsersDropdownMenu);
    return false;
  };

  const usersDropdownRef = useRef();
  useOutsideClick(usersDropdownRef, () => {
    setTimeout(() => setOpenUsersDropdownMenu(false), 100);
  });

  const handleLogout = () => {
    dispatch(actions.logout.request());
  };

  const handleToggleClick = (event, moduleId, isEnabled) => {
    event.preventDefault();
    dispatch(actions.toggleEnableModule.request(moduleId, isEnabled));
    return false;
  };

  const handleCertificateToggle = (event, certificate) => {
    const updatedProgram = {
      _id: currentProgram._id,
      clientId: currentProgram.clientId,
      certificate
    };
    event.preventDefault();
    dispatch(actions.editCourseSettings.request(updatedProgram));
    return false;
  };

  const handleSignupPageToggle = (event, signupPage) => {
    const updatedProgram = {
      _id: currentProgram._id,
      clientId: currentProgram.clientId,
      signupPage
    };
    event.preventDefault();
    dispatch(actions.editCourseSettings.request(updatedProgram));
    return false;
  };

  const publishSegmentClassname = currentProgram.isPublished ?
    "courseLeftMenuBottomSegment courseLeftMenuPublishSegment courseLeftMenuPublished" :
    "courseLeftMenuBottomSegment courseLeftMenuPublishSegment courseLeftMenuUnpublished";

  return (
    <div className="courseLeftMenuContainer courseLeftMenuFlexContainer">
      <div className="courseLeftMenuTopSection">
        <div className="courseLeftMenuSegment courseLeftMenuTitleSegment">
          <NavLink to="/" exact>
            <img className="courseLeftMenuGoBackArrow" src={downward_arrow_smooth}/>
          </NavLink>
          <div className="courseLeftMenuCourseTitle">{currentProgramName}</div>
        </div>
        <div
          className={
            currentProgram.isPublished ?
            "courseLeftMenuPublishedStatusSegment courseLeftMenuPublishedStatus" :
            "courseLeftMenuPublishedStatusSegment courseLeftMenuNotPublishedStatus"
          }
        >
          <div className={currentProgram.isPublished ? "courseLeftMenuPublishedStatusText" : "courseLeftMenuNotPublishedStatusText"}>
            {currentProgram.isPublished ? "Program is published" : "Program is not published"}
          </div>
        </div>
        {
          (isSuperAdmin || userPermissions.fullRights || userPermissions.edit) &&
          <div className="courseLeftMenuWholeSegment">
            <div className="courseLeftMenuMainMenu">
              <div className="courseLeftMenuSegmentIconAndTitle">
                <img className="courseLeftMenuSegmentIcon" src={pencil_icon_grey}/>
                <div className="courseLeftMenuSegmentTitle">CREATE</div>
              </div>
            </div>
            <NavLink
              className="courseLeftMenuSubMenuLink"
              to={`/course/${courseId}/welcome`}
              exact
              activeClassName="courseLeftMenuActiveSubMenuLink"
            >
              <div className="courseLeftMenuSubMenuWrapper">
                <div className="courseLeftMenuSubMenuText">{modules.welcomeModule.name || 'Disclaimer'}</div>
                {
                  modules.welcomeModule.isEnabled ?
                  <div className="courseLeftMenuDeactivateTooltipParent">
                    <img
                      className="courseLeftMenuToggleIcon"
                      src={green_toggle_icon}
                      onClick={(event) => handleToggleClick(event, modules.welcomeModule._id, !modules.welcomeModule.isEnabled)}
                    />
                    <span className="courseLeftMenuDeactivateTooltip">Deactivate</span>
                  </div>
                  :
                  <div className="courseLeftMenuActivateTooltipParent">
                    <img
                      className="courseLeftMenuToggleIcon"
                      src={toggle_menu_icon}
                      onClick={(event) => handleToggleClick(event, modules.welcomeModule._id, !modules.welcomeModule.isEnabled)}
                    />
                    <span className="courseLeftMenuActivateTooltip">Activate</span>
                  </div>
                }
              </div>
            </NavLink>
            <NavLink
              className="courseLeftMenuSubMenuLink"
              to={`/course/${courseId}/lessons`}
              exact
              activeClassName="courseLeftMenuActiveSubMenuLink"
              isActive={() => currentPath[3] === "lessons"}
            >
              <div className="courseLeftMenuSubMenuWrapper">
                <div className="courseLeftMenuSubMenuText">{modules.lessonModule.name || 'Timeline'}</div>
                {
                  modules.lessonModule.isEnabled ?
                  <div className="courseLeftMenuDeactivateTooltipParent">
                    <img
                      className="courseLeftMenuToggleIcon"
                      src={green_toggle_icon}
                      onClick={(event) => handleToggleClick(event, modules.lessonModule._id, !modules.lessonModule.isEnabled)}
                    />
                    <span className="courseLeftMenuDeactivateTooltip">Deactivate</span>
                  </div>
                  :
                  <div className="courseLeftMenuActivateTooltipParent">
                    <img
                      className="courseLeftMenuToggleIcon"
                      src={toggle_menu_icon}
                      onClick={(event) => handleToggleClick(event, modules.lessonModule._id, !modules.lessonModule.isEnabled)}
                    />
                    <span className="courseLeftMenuActivateTooltip">Activate</span>
                  </div>
                }
              </div>
            </NavLink>
            <NavLink
              className="courseLeftMenuSubMenuLink"
              to={`/course/${courseId}/library`}
              exact
              activeClassName="courseLeftMenuActiveSubMenuLink"
              isActive={() => currentPath[3] === "library"}
            >
              <div className="courseLeftMenuSubMenuWrapper">
                <div className="courseLeftMenuSubMenuText">{modules.libraryModule.name || 'Library'}</div>
                {
                  modules.libraryModule.isEnabled ?
                  <div className="courseLeftMenuDeactivateTooltipParent">
                    <img
                      className="courseLeftMenuToggleIcon"
                      src={green_toggle_icon}
                      onClick={(event) => handleToggleClick(event, modules.libraryModule._id, !modules.libraryModule.isEnabled)}
                    />
                    <span className="courseLeftMenuDeactivateTooltip">Deactivate</span>
                  </div>
                  :
                  <div className="courseLeftMenuActivateTooltipParent">
                    <img
                      className="courseLeftMenuToggleIcon"
                      src={toggle_menu_icon}
                      onClick={(event) => handleToggleClick(event, modules.libraryModule._id, !modules.libraryModule.isEnabled)}
                    />
                    <span className="courseLeftMenuActivateTooltip">Activate</span>
                  </div>
                }
              </div>
            </NavLink>
            <NavLink
              className="courseLeftMenuSubMenuLink"
              to={`/course/${courseId}/about`}
              exact
              activeClassName="courseLeftMenuActiveSubMenuLink"
            >
              <div className="courseLeftMenuSubMenuWrapper">
                <div className="courseLeftMenuSubMenuText">{modules.aboutModule.name || 'About'}</div>
                {
                  modules.aboutModule.isEnabled ?
                  <div className="courseLeftMenuDeactivateTooltipParent">
                    <img
                      className="courseLeftMenuToggleIcon"
                      src={green_toggle_icon}
                      onClick={(event) => handleToggleClick(event, modules.aboutModule._id, !modules.aboutModule.isEnabled)}
                    />
                    <span className="courseLeftMenuDeactivateTooltip">Deactivate</span>
                  </div>
                  :
                  <div className="courseLeftMenuActivateTooltipParent">
                    <img
                      className="courseLeftMenuToggleIcon"
                      src={toggle_menu_icon}
                      onClick={(event) => handleToggleClick(event, modules.aboutModule._id, !modules.aboutModule.isEnabled)}
                    />
                    <span className="courseLeftMenuActivateTooltip">Activate</span>
                  </div>
                }
              </div>
            </NavLink>
            <NavLink
              className="courseLeftMenuSubMenuLink"
              to={`/course/${courseId}/certificate`}
              exact
              activeClassName="courseLeftMenuActiveSubMenuLink"
            >
              <div className="courseLeftMenuSubMenuWrapper">
                <div className="courseLeftMenuSubMenuText">Certificate</div>
                {currentProgram.certificate ?
                  <div className="courseLeftMenuDeactivateTooltipParent">
                    <img
                      className="courseLeftMenuToggleIcon"
                      src={green_toggle_icon}
                      onClick={(event) => handleCertificateToggle(event, !currentProgram.certificate)}
                    />
                    <span className="courseLeftMenuDeactivateTooltip">Deactivate</span>
                  </div>
                  :
                  <div className="courseLeftMenuActivateTooltipParent">
                    <img
                      className="courseLeftMenuToggleIcon"
                      src={toggle_menu_icon}
                      onClick={(event) => handleCertificateToggle(event, !currentProgram.certificate)}
                    />
                    <span className="courseLeftMenuActivateTooltip">Activate</span>
                  </div>
                }
              </div>
            </NavLink>
          </div>
        }
        {
          (isSuperAdmin || userPermissions.fullRights || userPermissions.invite) &&
          <div className="courseLeftMenuWholeSegment">
            <div className="courseLeftMenuMainMenu">
              <div className="courseLeftMenuSegmentIconAndTitle">
                <img className="courseLeftMenuSegmentIcon" src={user}/>
                <div className="courseLeftMenuSegmentTitle">INVITE</div>
              </div>
            </div>
            <NavLink
              className="courseLeftMenuSubMenuLink"
              to={`/course/${courseId}/welcomemail`}
              exact
              activeClassName="courseLeftMenuActiveSubMenuLink"
            >
              <div className="courseLeftMenuSubMenuWrapper">Invitation message</div>
            </NavLink>
            <NavLink
              className="courseLeftMenuSubMenuLink"
              to={`/course/${courseId}/users`}
              exact
              activeClassName="courseLeftMenuActiveSubMenuLink"
            >
              <div className="courseLeftMenuSubMenuWrapper courseLeftMenuSubMenuWithDotsIcon">
                <div>Users</div>
                <div className="courseLeftSubMenuDotsIconContainer" onClick={(event) => handleUsersDropdownMenu(event)}>
                  <img className="courseLeftMenuDotsIcon courseLeftMenuThreeDotsIcon" src={three_dots}/>
                  <div className="courseLeftMenuTooltipParent">
                    <img className="courseLeftMenuThreeDotsIconOnHover" src={three_dots_black_bg}/>
                    <span className="courseLeftMenuTooltip">Users menu</span>
                  </div>
                </div>
              </div>
            </NavLink>
            {openUsersDropdownMenu &&
              <div className="courseLeftSubMenuDropdown" ref={usersDropdownRef}>
                <Link className="courseLeftUserMenuLink" to={`/course/${courseId}/adduser`}>
                  <div className="courseLeftSubMenuDropdownItem">
                    <div>Add user</div>
                    <img className="courseLeftSubMenuDropdownMenuIcon" src={plus_grey_thin} alt="duplicate icon"/>
                  </div>
                </Link>
                <div
                  className="courseLeftSubMenuDropdownItem"
                  onClick={() => dispatch(actions.downloadCourseUserList.request(courseId))}
                >
                  <div>Download userlist</div>
                  <img
                    className="courseLeftSubMenuDropdownMenuIcon courseLeftSubMenuDropdownMenuIconRotate"
                    src={logout_arrow}
                    alt="arrow icon"
                  />
                </div>
              </div>
            }
            <NavLink
              className="courseLeftMenuSubMenuLink"
              to={`/course/${courseId}/signup`}
              exact
              activeClassName="courseLeftMenuActiveSubMenuLink"
            >
              <div className="courseLeftMenuSubMenuWrapper">
                <div>Sign-up page</div>
                {currentProgram.signupPage ?
                  <div className="courseLeftMenuDeactivateTooltipParent">
                    <img
                      className="courseLeftMenuToggleIcon"
                      src={green_toggle_icon}
                      onClick={(event) => handleSignupPageToggle(event, !currentProgram.signupPage)}
                    />
                    <span className="courseLeftMenuDeactivateTooltip">Deactivate</span>
                  </div>
                  :
                  <div className="courseLeftMenuActivateTooltipParent">
                    <img
                      className="courseLeftMenuToggleIcon"
                      src={toggle_menu_icon}
                      onClick={(event) => handleSignupPageToggle(event, !currentProgram.signupPage)}
                    />
                    <span className="courseLeftMenuActivateTooltip">Activate</span>
                  </div>
                }
              </div>
            </NavLink>
          </div>
        }
        {
          (isSuperAdmin || userPermissions.fullRights || userPermissions.read) &&
          <NavLink
            className="courseLeftMenuMainMenuLink"
            to={`/course/${courseId}/dashboard`}
            exact
            activeClassName="courseLeftMenuActiveMainMenuLink"
          >
            <div className="courseLeftMenuSegment courseLeftMenuDashboardSegment">
              <img className="courseLeftMenuSegmentIcon courseLeftMenuChartIconGrey" src={chart_icon}/>
              <img className="courseLeftMenuSegmentIcon courseLeftMenuChartIconPurple" src={chart_icon_purple}/>
              <div>DASHBOARD</div>
            </div>
          </NavLink>
        }
      </div>
      <div>
        {
          (isSuperAdmin || userPermissions.fullRights) &&
          <>
            <div
              className={`${publishSegmentClassname} courseLeftPublishMenu`}
              onClick={() => setOpenPublishModal(true)}
            >
              <div className="courseLeftMenuSegmentIconAndTitle">
                <img
                  className={currentProgram.isPublished ? "courseLeftMenuSegmentIcon courseLeftMenuUnpublishArrow" : "courseLeftMenuSegmentIcon courseLeftMenuPublishArrow"}
                  src={logout_arrow}
                />
                <div className="courseLeftMenuSegmentTitle">{currentProgram.isPublished ? 'UNPUBLISH PROGRAM' : 'PUBLISH PROGRAM'}</div>
              </div>
            </div>
            <NavLink
              className="courseLeftMenuMainMenuLink courseLeftMenuProgramSettings"
              to={`/course/${courseId}/settings`}
              exact
              activeClassName="courseLeftMenuActiveMainMenuLink"
            >
              <div className="courseLeftMenuBottomSegment courseLeftMenuSettingsSegment">
                <img className="courseLeftMenuSegmentIcon courseLeftMenuWheelIcon" src={settings_wheel_icon}/>
                <img className="courseLeftMenuSegmentIcon courseLeftMenuWheelIconPurple" src={settings_wheel_icon_purple}/>
                <div>PROGRAM SETTINGS</div>
              </div>
            </NavLink>
          </>
        }
        <NavLink
          className="courseLeftMenuLogoutButton"
          to="/login"
          exact
          onClick={handleLogout}
        >
          <div className="courseLeftMenuBottomSegment courseLeftMenuLogoutSegment">
            <img className="courseLeftLogoutSegmentIcon" src={logout_arrow} />
            <div className="courseLeftMenuLogoutText">LOG OUT</div>
          </div>
        </NavLink>
      </div>
      <PublishModal
        open={openPublishModal}
        onClose={() => setOpenPublishModal(false)}
        isPublished={currentProgram.isPublished}
        courseName={currentProgram.name}
        courseType={currentProgram.type}
        startDate={currentProgram.startDate || null}
        onLeftButtonClick={() => setOpenPublishModal(false)}
        onRightButtonClick={() => {
          dispatch(actions.publishCourse.request(currentProgram._id, !currentProgram.isPublished));
          setOpenPublishModal(false);
        }}
      />
    </div>
  );
};

export default CourseLeftMenu;
