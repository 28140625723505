export const initialPagesData = {
  components: {},
  columns: {
    "pages-column": {
      id: "pages-column",
      componentIds: []
    }
  },
  columnOrder: ["pages-column"]
};

export const initialChaptersData = {
  components: {},
  columns: {
    "chapters-column": {
      id: "chapters-column",
      componentIds: []
    }
  },
  columnOrder: ["chapters-column"]
};

export const initialChapterPagesData = {
  components: {},
  columns: {
    "chapterPages-column": {
      id: "chapterPages-column",
      componentIds: []
    }
  },
  columnOrder: ["chapterPages-column"]
};

export const structurePagesData = (pagesArray, setPagesData) => {
  const itemIds = pagesArray.map(item => item._id);
    let pagesContentObject = {};
    pagesArray.forEach(item => {
      const component = {
        id: item._id,
        content: item
      };
      pagesContentObject[item._id] = component;
    });

    const itemData = {
      components: pagesContentObject,
      columns: {
        "pages-column": {
          id: "pages-column",
          componentIds: itemIds
        }
      },
      columnOrder: ["pages-column"]
    };
    setPagesData(itemData);
};

export const structureChaptersData = (chaptersArray, setChaptersData) => {
  const itemIds = chaptersArray.map(item => item._id);
    let chaptersContentObject = {};
    chaptersArray.forEach(item => {
      const component = {
        id: item._id,
        content: item
      };
      chaptersContentObject[item._id] = component;
    });

    const itemData = {
      components: chaptersContentObject,
      columns: {
        "chapters-column": {
          id: "chapters-column",
          componentIds: itemIds
        }
      },
      columnOrder: ["chapters-column"]
    };
    setChaptersData(itemData);
};

export const structureChapterPagesData = (chapterPagesArray, setChapterPagesData) => {
  const itemIds = chapterPagesArray.map(item => item._id);
    let chapterPagesContentObject = {};
    chapterPagesArray.forEach(item => {
      const component = {
        id: item._id,
        content: item
      };
      chapterPagesContentObject[item._id] = component;
    });

    const itemData = {
      components: chapterPagesContentObject,
      columns: {
        "chapterPages-column": {
          id: "chapterPages-column",
          componentIds: itemIds
        }
      },
      columnOrder: ["chapterPages-column"]
    };
    setChapterPagesData(itemData);
};
