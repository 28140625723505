import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import * as actions from '../actions';
// import SettingsMenu from './SettingsMenu';
import moblrn_logo_with_text_horizontal from '../images/svg/moblrn_logo_with_text_horizontal.svg';
import three_lines_grey from '../images/svg/three_lines_grey.svg';
import three_lines_purple from '../images/svg/three_lines_purple.svg';
import user from '../images/svg/user.svg';
import user_purple from '../images/svg/user_purple.svg';
import plus_grey_thin from '../images/svg/plus_grey_thin.svg';
import plus_purple from '../images/svg/plus_purple.svg';
import plus_white_black_bg from '../images/svg/plus_white_black_bg.svg';
import logout_arrow from '../images/svg/logout_arrow.svg';
import gears_api from '../images/svg/gears_api.svg';
import gears_api_purple from '../images/svg/gears_api_purple.svg';
import './Menu.css';

/**
* Menu: Component showing the navigation links on the left
*/
const Menu = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const currentUser = useSelector(state => state.user);
  const isSuperAdmin = currentUser.isSuperAdmin;
  const userPermissions = currentUser.permissions[0] ?? {};
  const clientLogo = useSelector(state => state.clients.currentClient.clientLogo);
  
  const handleLogout = () => {
    dispatch(actions.logout.request());
  };

  return (
    <div className="menuContainer menuFlexContainer">
      <div>
        <div className={isSuperAdmin ? "menuAdminTitleSegment" : "menuSegment menuLogoSegment"}>
          {
            isSuperAdmin ?
            <div className="menuAdminTitle">SUPER ADMIN</div>
            :
            <img
              className="menuLogo"
              src={clientLogo ? process.env.REACT_APP_MEDIA_URL + clientLogo : moblrn_logo_with_text_horizontal}
            />
          }
        </div>
        <NavLink
          className="menuLink"
          to="/"
          exact
          activeClassName="dashboardActiveLink"
        >
          <div className="menuSegment">
            <img className="menuIcon menuIconLinesGrey" src={three_lines_grey}/>
            <img className="menuIcon menuIconLinesPurple" src={three_lines_purple}/>
            <div className="menuText">PROGRAMS</div>
          </div>
        </NavLink>
        {
          (isSuperAdmin || userPermissions.fullRights || userPermissions.edit) &&
          <NavLink
            className="menuLink"
            to="/newcourse"
            activeClassName="dashboardActiveLink"
          >
            <div className="menuSegment">
              <img className="menuIcon menuIconPlusGreyCreateCourse" src={plus_grey_thin}/>
              <img className="menuIcon menuIconPlusPurpleCreateCourse" src={plus_purple}/>
              <div className="menuText">CREATE PROGRAM</div>
            </div>
          </NavLink>
        }
        {isSuperAdmin && renderSuperAdminItems(pathname)}
        {/* Client Settings for Client Admins is being hidden for now */}
        {/* {!isSuperAdmin && <SettingsMenu/>} */}
        {
          (isSuperAdmin || userPermissions.fullRights || userPermissions.read) &&
          <NavLink
            className="menuLink"
            to="/usersprogress"
            activeClassName="dashboardActiveLink"
            exact
          >
            <div className="menuSegment menuLinkLastItem">
              <img className="menuIcon menuIconUserGrey" src={user}/>
              <img className="menuIcon menuIconUserPurple" src={user_purple}/>
              <div className="menuText">USER PROGRESS OVERVIEW</div>
            </div>
          </NavLink>
        }
      </div>
      <div>
        {
          isSuperAdmin &&
          <NavLink
            className="menuLink"
            to="/apioverview"
            activeClassName="dashboardActiveLink"
          >
            <div className="menuSegment logoutMenuSegment">
              <img className="menuIcon menuIconGreyAPIGears" src={gears_api}/>
              <img className="menuIcon menuIconPurpleAPIGears" src={gears_api_purple}/>
              <div className="menuText">API</div>
            </div>
          </NavLink>
        }
        <NavLink
          className="menuLink"
          to="/login"
          onClick={handleLogout}
        >
          <div className={isSuperAdmin ? "menuSegment" : "menuSegment logoutMenuSegment"}>
            <img className="menuIcon" src={logout_arrow} />
            <div className="menuText">LOG OUT</div>
          </div>
        </NavLink>
      </div>
    </div>
  );
};

/**
* renderSuperAdminItems renders the part of the menu that is only accessible
* to superadmins
*/
const renderSuperAdminItems = (pathname) => {
  const history = useHistory();

  const handleClientsClick = (event) => {
    event.preventDefault();
    history.push("/addclient");
    return false;
  };

  const handleAdminsClick = (event) => {
    event.preventDefault();
    history.push("/admins/addadmin");
    return false;
  };

  return (
    <div>
      <NavLink
        className="menuLink"
        to="/clients"
        activeClassName="dashboardActiveLink"
        exact
      >
        <div className="menuSegment">
          <img className="menuIcon menuIconUserGrey" src={user}/>
          <img className="menuIcon menuIconUserPurple" src={user_purple}/>
          <div className="menuText">CLIENTS</div>
          <div className="plusContainer" onClick={handleClientsClick}>
            <img className="menuPlus menuIconPlusGrey" src={plus_grey_thin}/>
            <div className="addClientTooltipParent">
              <img className="menuIcon menuIconPlusWhiteBlackBG" src={plus_white_black_bg}/>
              <img className="menuIcon menuIconPlusPurple" src={plus_purple}/>
              <span>Add client</span>
            </div>
          </div>
        </div>
      </NavLink>
      <NavLink
        className="menuLink"
        to="/admins"
        isActive={() => ['/admins', '/admins/addadmin'].includes(pathname)}
        activeClassName="dashboardActiveLink"
      >
        <div className="menuSegment">
          <img className="menuIcon menuIconUserGrey" src={user}/>
          <img className="menuIcon menuIconUserPurple" src={user_purple}/>
          <div className="menuText">ADMINS</div>
          <div className="plusContainer" onClick={handleAdminsClick}>
            <img className="menuPlus menuIconPlusGrey" src={plus_grey_thin}/>
            <div className="addAdminTooltipParent">
              <img className="menuIcon menuIconPlusWhiteBlackBG" src={plus_white_black_bg}/>
              <img className="menuIcon menuIconPlusPurple" src={plus_purple}/>
              <span>Add admin</span>
            </div>
          </div>
        </div>
      </NavLink>
      <NavLink
        className="menuLink"
        to="/users"
        activeClassName="dashboardActiveLink"
      >
        <div className="menuSegment">
          <img className="menuIcon menuIconUserGrey" src={user}/>
          <img className="menuIcon menuIconUserPurple" src={user_purple}/>
          <div className="menuText">USERS</div>
        </div>
      </NavLink>
    </div>
  );
};

export default Menu;
