import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Const from '../../store/Const';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { AddContentDropdown } from '../courseCore/AddContentDropdown';
import { renderCourseComponent, onDragEnd, onDragUpdate } from '../../utils/componentUtils';
import { initialFeedbackCorrectData, initialFeedbackWrongData, structureFeedbackCorrectData, structureFeedbackWrongData } from './LessonsFeedbackDragAndDropData';
import './LessonsFeedbackComponent.css';

/**
* LessonsFeedbackComponent
*/
const LessonsFeedbackComponent = ({ feedbackCorrectData, feedbackWrongData }) => {
  const dispatch = useDispatch();
  const feedbackCorrectComponents = feedbackCorrectData.children;
  const feedbackWrongComponents = feedbackWrongData.children;

  const [feedbackCorrectObject, setFeedbackCorrectObject] = useState(initialFeedbackCorrectData);
  const [feedbackWrongObject, setFeedbackWrongObject] = useState(initialFeedbackWrongData);
  const [placeholderProps, setPlaceholderProps] = useState({});

  useEffect(() => {
    structureFeedbackCorrectData(feedbackCorrectComponents, setFeedbackCorrectObject);
    structureFeedbackWrongData(feedbackWrongComponents, setFeedbackWrongObject);
  }, [feedbackCorrectComponents, feedbackWrongComponents]);

  return (
    <>
      <div className="feedbackComponentTitleWrapper">
        <div className="feedbackComponentTitle">FEEDBACK</div>
        <div className="feedbackComponentSubText">
          Feedback is shown after a user has answered a question.
          <br/><br/>
          <b>Same feedback to all users</b>
          <br/>
          Only add content below <q>Default/Correct answer</q>.
          <br/><br/>
          <b>Different feedback for correct & Incorrect answer</b>
          <br/>
          Add content below both <q>Default/Correct answer</q> and <q>Incorrect answer</q>.
        </div>
      </div>
      <div className="feedbackComponentSubtitle">DEFAULT/ CORRECT ANSWER</div>
      {feedbackCorrectComponents.length > 0 &&
        <>
          {feedbackCorrectObject.columnOrder.map(columnId => {
            const column = feedbackCorrectObject.columns[columnId];
            const components = column.componentIds.map((componentId) => feedbackCorrectObject.components[componentId]);
            return (
              <DragDropContext
                onDragEnd={(result) => onDragEnd(result, feedbackCorrectObject, setPlaceholderProps, setFeedbackCorrectObject, feedbackCorrectData.moduleId, Const.moduleType.Lessons, dispatch)}
                onDragUpdate={(update) => onDragUpdate(update, setPlaceholderProps)}
                key={columnId}
              >
                <Droppable droppableId={column.id} key={column.id}>
                  {(provided, snapshot) => (
                    <div
                      className="courseContentWrapper feedbackCorrectWrapper"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {components.map((component, index) => {
                        return (
                          <Draggable
                            draggableId={component.id}
                            index={index}
                            key={component.id}
                            isDragDisabled={feedbackCorrectComponents.length === 1}
                          >
                            {(provided, snapshot) => (
                              <div
                                className="courseComponentWrapper"
                                key={component.id}
                                {...provided.draggableProps}
                                ref={provided.innerRef}
                              >
                                <div className={snapshot.isDragging ? "courseComponentWrapperDragging" : ""}>
                                  {renderCourseComponent(component.content, feedbackCorrectComponents.length, Const.moduleType.Lessons, provided, feedbackCorrectData._id)}
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      <div>{provided.placeholder}</div>
                      {Object.keys(placeholderProps).length > 0 && snapshot.isDraggingOver && (
                        <div
                          className="dragItemCustomPlacholder"
                          style={{
                            top: placeholderProps.clientY,
                            left: placeholderProps.clientX,
                            height: placeholderProps.clientHeight - 12,
                            width: placeholderProps.clientWidth
                          }}
                        />
                      )}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            );
          })}
        </>
      }
      <AddContentDropdown
        moduleId={feedbackCorrectData.moduleId}
        parentId={feedbackCorrectData._id}
        moduleType={Const.moduleType.Lessons}
        sourceFeedbackComponent={true}
      />
      <div className="feedbackComponentSubtitle feedbackIncorrectAnswerText">INCORRECT ANSWER</div>
      {feedbackWrongComponents.length > 0 &&
        <>
          {feedbackWrongObject.columnOrder.map(columnId => {
            const column = feedbackWrongObject.columns[columnId];
            const components = column.componentIds.map((componentId) => feedbackWrongObject.components[componentId]);
            return (
              <DragDropContext
                onDragEnd={(result) => onDragEnd(result, feedbackWrongObject, setPlaceholderProps, setFeedbackWrongObject, feedbackWrongData.moduleId, Const.moduleType.Lessons, dispatch)}
                onDragUpdate={(update) => onDragUpdate(update, setPlaceholderProps)}
                key={columnId}
              >
                <Droppable droppableId={column.id} key={column.id}>
                  {(provided, snapshot) => (
                    <div
                      className="courseContentWrapper feedbackWrongWrapper"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {components.map((component, index) => {
                        return (
                          <Draggable
                            draggableId={component.id}
                            index={index}
                            key={component.id}
                            isDragDisabled={feedbackWrongComponents.length === 1}
                          >
                            {(provided, snapshot) => (
                              <div
                                className="courseComponentWrapper"
                                key={component.id}
                                {...provided.draggableProps}
                                ref={provided.innerRef}
                              >
                                <div className={snapshot.isDragging ? "courseComponentWrapperDragging" : ""}>
                                  {renderCourseComponent(component.content, feedbackWrongComponents.length, Const.moduleType.Lessons, provided, feedbackWrongData._id)}
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      <div>{provided.placeholder}</div>
                      {Object.keys(placeholderProps).length > 0 && snapshot.isDraggingOver && (
                        <div
                          className="dragItemCustomPlacholder"
                          style={{
                            top: placeholderProps.clientY,
                            left: placeholderProps.clientX,
                            height: placeholderProps.clientHeight - 12,
                            width: placeholderProps.clientWidth
                          }}
                        />
                      )}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            );
          })}
        </>
      }
      <AddContentDropdown
        moduleId={feedbackWrongData.moduleId}
        parentId={feedbackWrongData._id}
        moduleType={Const.moduleType.Lessons}
        sourceFeedbackComponent={true}
      />
    </>
  );
};

export default LessonsFeedbackComponent;
