import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../actions';
import { useOutsideClick } from '../utils/componentUtils';
import downward_arrow_smooth from '../images/svg/downward_arrow_smooth.svg';
import filter_dropdown_checkmark from '../images/svg/filter_dropdown_checkmark.svg';
import './StudentTableSelectDropdownComponent.css';

/**
* StudentTableSelectDropdownComponent
*/
const StudentTableSelectDropdownComponent = ({ name, defaultText, optionTexts, selectedItems, handleRemoveOption, handleChange, handleClear, source, disabled }) => {
  const dispatch = useDispatch();
  const userTableFilter = useSelector(state => state.users.userTableFilter);
  const isUserlistFilterOptionsLoading = useSelector(state => state.componentStates.loadingUserlistFilterOptions);
  const dropdownRef = useRef();
  const [open, setOpen] = useState(false);

  useOutsideClick(dropdownRef, () => {
    setOpen(false);
  });

  const dropdownTextClassname = () => {
    if (selectedItems.length === 1) {
      return "studentTableSelectDropdownComponent studentTableSelectDropdownComponentSingleSelectedItem";
    } else if (selectedItems.length > 0) {
      return "studentTableSelectDropdownComponent studentTableSelectDropdownComponentTextBold";
    } else {
      return "studentTableSelectDropdownComponent studentTableSelectDropdownComponentTextLight";
    }
  };

  const dropdownText = () => {
    if (selectedItems.length === 1) {
      return selectedItems[0];
    } else if (selectedItems.length > 0) {
      return `${selectedItems.length} selected`;
    } else {
      return defaultText;
    }
  };

  const handleClearOption = (name) => {
    if (source === "studentTableSelect") {
      handleClear(name);
    } else if (source === "courseUserTable") {
      let newFilter = {...userTableFilter};
      newFilter[name] = [];
      dispatch(actions.updateUserTableFilter.request(newFilter));
    } else if (source === "userProgressTable") {
      handleClear(name);
    } else if (source === 'courseUsersDataTable') {
      handleClear(name);
    }
  };

  return (
    <div ref={dropdownRef} className={disabled ? "studentTableSelectDropdownDisabled studentTableSelectDropdown" : "studentTableSelectDropdown"}>
      <div className={dropdownTextClassname()} onClick={() => setOpen(!open)}>
        <div className="studentTableSelectDropdownComponentText">
          {dropdownText()}
        </div>
        <img className="studentTableSelectDropdownComponentIcon" src={downward_arrow_smooth} alt="downward arror"/>
      </div>
      <div
        className={open ? "studentTableSelectDropdownComponentMenu" : "studentTableSelectDropdownComponentMenu studentTableSelectDropdownComponentMenuClosed"}
        style={{maxHeight: getDropdownMenuMaxheight(dropdownRef)}}
      >
        {
          isUserlistFilterOptionsLoading ?
          <div className="studentTableFilterDropdownLoadingText">Loading<span>.</span><span>.</span><span>.</span></div>
          :
          <>
            <div
              className={
                selectedItems.length === 0 ?
                "studentTableSelectDropdownComponentClearItem studentTableSelectDropdownComponentClearItemDisabled"
                :
                "studentTableSelectDropdownComponentClearItem"
              }
              onClick={() => selectedItems.length === 0 ? null : handleClearOption(name)}
            >
              Clear selection
            </div>
            {optionTexts.map((option) => {
              return (
                <div
                  className={
                    selectedItems.includes(option) ?
                    "studentTableSelectDropdownComponentMenuItem studentTableSelectDropdownComponentSelectedMenuItem"
                    :
                    "studentTableSelectDropdownComponentMenuItem"
                  }
                  key={option}
                  onClick={selectedItems.includes(option) ? () => handleRemoveOption(name, option) : () => handleChange(name, option)}
                >
                  {
                    selectedItems.includes(option) ?
                    <img className="studentTableSelectDropdownItemIcon" src={filter_dropdown_checkmark} alt="checkmark"/>
                    :
                    <div className="studentTableSelectDropdownItemEmptyIcon"/>
                  }
                  {option}
                </div>
              );
            })}
          </>
        }
      </div>
    </div>
  );
};

/**
* getDropdownMenuMaxheight calculates the maxHeight of the dropdown menu.
* The requirements are: The menu should fill out the whole page except
* the bottom 70px. The menu should also be at least 200px long if the content
* has at least that height, otherwise the menu should have the height of
* it's content.
*/
const getDropdownMenuMaxheight = (ref) => {
  const { top, height } = ref.current ?
    ref.current.getBoundingClientRect()
    : { top: 0, height: 0 };
  const bottomMargin = 70;
  const maxHeight = window.innerHeight - top - height - bottomMargin;
  return maxHeight >= 200 ? maxHeight + 'px' : 'min(200px, min-content)';
};


export default StudentTableSelectDropdownComponent;
