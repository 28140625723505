import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import Modal from '@material-ui/core/Modal';
import ChangePasswordNoCodeForm from '../components/forms/ChangePasswordNoCodeForm';
import './ChangePasswordNoCodePage.css';
import grey_cross from '../images/grey_cross.png';
import moblrn_logo_old from '../images/svg/moblrn-logo-old.svg';

const mapStateToProps = (state) => {
  return {
    showSubmitNewPasswordErrorMessage: state.componentStates.showSubmitNewPasswordErrorMessage
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeSubmitNewPasswordErrorModal: () => {
      dispatch(actions.closeSubmitNewPasswordErrorModal.request());
    },
  };
};

/**
* ChangePasswordNoCodePage is the page where users who are flagged for a password
* change will be redirected to after login
*/
const ChangePasswordNoCodePage = ({ showSubmitNewPasswordErrorMessage, closeSubmitNewPasswordErrorModal }) => {
  return (
    <div className="choosePasswordNoCodeBackground">
      <div className="choosePasswordNoCodeContent">
        <img className="moblrnLogo" src={moblrn_logo_old} alt="moblrn logo"/>
        <div className="changePasswordNoCodeTitle">Create new password</div>
        <ChangePasswordNoCodeForm/>
        <Modal
          id="modal"
          className="changePasswordNoCodeErrorModal"
          open={showSubmitNewPasswordErrorMessage}
          onClose={() => closeSubmitNewPasswordErrorModal()}>
          <div className="changePasswordNoCodeErrorModalBackground">
            <img className="changePasswordNoCodeErrorModalX" onClick={() => closeSubmitNewPasswordErrorModal()} src={grey_cross} alt="plus"/>
            <div className="changePasswordNoCodeSubmitPasswordErrorModal">
              An error occured while submitting the new password. Please try again.
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};



export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordNoCodePage);
