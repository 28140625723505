import React, { useState } from 'react';
import { getDayMonthYearFromDate, getTimeFromDate } from '../../utils/dateUtils';
import UserOverviewPageContentComponent from './UserOverviewPageContentComponent';
import downward_arrow_smooth from '../../images/svg/downward_arrow_smooth.svg';
import light_green_checkmark from '../../images/svg/light_green_checkmark.svg';
import './UserOverviewPageComponent.css';

/**
* UserOverviewPageComponent
*/

const UserOverviewPageComponent = ({ page, totalScore, studentScore, answers }) => {
  const [openPageContent, setOpenPageContent] = useState(false);
  const pageCompleted = answers && (answers.answerDate || (answers.containsQuestion === false && answers.viewDate));
  const pageStarted = answers && answers.viewDate;
  let completionDate = null;
  if (pageCompleted) {
    completionDate = answers.answerDate || answers.viewDate;
  }
  let startDate = null;
  if (pageStarted) {
    startDate = answers.viewDate;
  }
  const renderStatusDate = () => {
    if (pageCompleted) {
      return (
        <div className="overviewPageCardDate overviewPageCardFinishedDate">
          <img className="overviewPageCardDateIcon" src={light_green_checkmark}/>
          <div>{'Finished ' + getDayMonthYearFromDate(completionDate) + ' ' + getTimeFromDate(completionDate)}</div>
        </div>
      );
    } else if (!pageCompleted && pageStarted) {
      return (
        <div className="overviewPageCardDate">
          {'Started ' + getDayMonthYearFromDate(startDate) + ' ' + getTimeFromDate(startDate)}
        </div>
      );
    } else {
      return (
        <div className={totalScore > 0 ? "overviewPageCardNotStartedText" : "overviewPageCardNotStartedText overviewPageCardNotStartedStandAloneText"}>
          Not started
        </div>
      );
    }
  };

  return (
    <>
      <div className={pageCompleted ? "overviewPageCardWrapper overviewFinishedPageCardWrapper" : "overviewPageCardWrapper"}>
        <div className="overviewPageCardNameAndMenuWrapper">
          <div className="overviewPageCardName">{page.data.text || "New page"}</div>
          <img
            className={openPageContent ? "overviewPageCardArrow overviewPageCardInvertArrow" : "overviewPageCardArrow"}
            src={downward_arrow_smooth}
            onClick={() => setOpenPageContent(!openPageContent)}
          />
        </div>
        {renderStatusDate()}
        {
          totalScore > 0 &&
          <>
            <div className="overviewPageCardSliderLabel">
              <div>Points</div>
              <div className="overviewPageCardSliderValue">{totalScore}</div>
            </div>
            {renderProgressComponent(totalScore, studentScore, pageCompleted)}
          </>
        }
      </div>
      {openPageContent &&
        <div className="overviewPageContentWrapper">
          {page.children.map((pageItem) => {
            const answersMethod = () => {
              if (answers) {
                if (pageItem.type === 14) {
                  return answers.answers ? answers.answers.filter(answer => answer.moduleItemId === pageItem._id) : [];
                } else {
                  return answers.answers ? answers.answers.find(answer => answer.moduleItemId === pageItem._id) : [];
                }
              }
            };
            return (
              <UserOverviewPageContentComponent
                key={pageItem._id}
                pageItem={pageItem}
                answers={answersMethod()}
              />
            );
          })}
        </div>
      }
    </>
  );
};

const renderProgressComponent = (max, value, pageCompleted) => {
  return (
    <div className="overviewPageCardProgressComponent">
      <div className="overviewPageCardProgressComponentTrack" />
      <div
        className={pageCompleted ? "overviewPageCardProgressComponentProgress overviewPageProgressComponentFinished" : "overviewPageCardProgressComponentProgress"}
        style={{width: calculateProgressWidth(max, value)}}
      >
        <div className="overviewPageCardProgressComponentValue">{value ? value : 0}</div>
      </div>
    </div>
  );
};

const calculateProgressWidth = (max, value) => {
  if (!value || value === 0) {
    return 20;
  }
  const width = (348 * value) / max;
  if (width < 25) {
    return 30;
  }
  return width;
};

export default UserOverviewPageComponent;
