import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '../../actions';
import lost_connection_x from '../../images/svg/lost_connection_x.svg';
import lost_connection_cloud from '../../images/svg/lost_connection_cloud.svg';
import './AutosaveLostConnectionComponent.css';

/**
* AutosaveLostConnectionComponent: icon used on the course core to show the connection status
*/
const AutosaveLostConnectionComponent = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => dispatch(actions.setConnectionStatus.request('reconnecting')), 1500);
  }, []);

  return (
    <div className="lostConnectionWrapper">
      <div className="lostConnectionIconsWrapper">
        <img className="lostConnectionXMark" src={lost_connection_x}/>
        <img className="lostConnectionCloud" src={lost_connection_cloud}/>
      </div>
      <div className="lostConnectionText">Lost connection</div>
    </div>
  );
};

export default AutosaveLostConnectionComponent;
