import API from '../api/api';

export async function downloadBatchAddReport(batchId) {
  const res = await API.downloadBatchAddReport(batchId);
  let contentDisposition = res.request.getResponseHeader('Content-Disposition');
  const filename = contentDisposition.slice(
    contentDisposition.indexOf('filename=') + 9,
    contentDisposition.length
  );
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(
    new Blob([res.data], { type: 'application/vnd.ms-excel' })
  );
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
