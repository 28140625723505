export const getQueryFromParameters = (parameters) => {
  let query = '';
  if ((parameters.currentPageNumber || parameters.currentPageNumber === 0) && parameters.itemsPerPage) {
    query = '?page=' + parameters.currentPageNumber + '&count=' + parameters.itemsPerPage;
    if (parameters.searchString) {
      query += '&search=' + encodeURIComponent(parameters.searchString);
    }
    if (parameters.sortOrder === 1) {
      query += '&sortBy=' + parameters.sortAttribute;
    }
    else {
      query += '&sortBy=-' + parameters.sortAttribute;
    }
  }
  else if (parameters.sortAttribute && parameters.sortOrder) {
    if (parameters.sortOrder === 1) {
      query += '?sortBy=' + parameters.sortAttribute;
    }
    else {
      query += '?sortBy=-' + parameters.sortAttribute;
    }
  }
  return query;
};
