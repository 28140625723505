import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import {
  getInteractionByIndex,
  filterStudentAnswersByInteractionId,
  filterStudentAnswersByModuleItemId
} from '../utils/statisticsUtils';
import { renderAnsweredLessonComponents } from './AnsweredLessonComponents';
import './InteractionStatisticsModal.css';
import { useParams } from 'react-router';
import { useDashboardGraphs } from '../program-dashboard/use-dashboard-graphs';

const InteractionStatisticsModal = ({ interactionIndex, onClose }) => {
  const { courseId } = useParams();

  const {
    filteredCount,
    chapters,
    interactionOptions,
    studentsWithAnswers,
    interactionViewsToAnswers,
  } = useDashboardGraphs();

  let interaction = getInteractionByIndex(interactionIndex, chapters);
  let interactionIndexPlusOne = interactionIndex + 1;
  let interactionName = (interaction.data && interaction.data.text) || "Interaction " + interactionIndexPlusOne;

  return (
    <Modal onClose={onClose} open={interactionIndex >= 0} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <div className="interactionStatisticsModalContainer">
        <div className="interactionModalInteractionTitle">{interactionName}</div>
        {renderCompletionRate(interactionIndex, filteredCount, interactionViewsToAnswers)}
        {renderLessonContent(interactionIndex, chapters, studentsWithAnswers, interactionOptions, interactionViewsToAnswers, courseId)}
      </div>
    </Modal>
  );
};

const renderCompletionRate = (interactionIndex, filteredCount, interactionStatistics) => {
  if (filteredCount === 0 || !interactionStatistics[interactionIndex]) {
    return null;
  }
  let numberOfStudents = filteredCount;
  let numberOfCompletions = interactionStatistics[interactionIndex][1];
  const percentageCompleted = Math.ceil((numberOfCompletions * 100) / numberOfStudents);
  return (
    <div className="interactionStatisticsModalCompletionRateText">{`${percentageCompleted}% (${numberOfCompletions}/${numberOfStudents}) of the users has completed this page`}</div>
  );
};

const renderLessonContent = (interactionIndex, chapters, studentsWithAnswers, options, interactionStatistics, programId) => {
  const [showTextAnswers, setShowTextAnswers] = useState(false);
  const [showParagpraghAnswers, setShowParagpraghAnswers] = useState(false);
  const [showMultiChoiceAnswers, setShowMultiChoiceAnswers] = useState(false);
  const [showCheckboxAnswers, setShowCheckboxAnswers] = useState(false);
  const [showSliderAnswers, setShowSliderAnswers] = useState(false);
  const [showComments, setShowComments] = useState(false);
  let interaction = getInteractionByIndex(interactionIndex, chapters);
  let filteredAnswersByInteraction = filterStudentAnswersByInteractionId(studentsWithAnswers, programId, interaction._id, );
  let numberOfCompletions = interactionStatistics.length > 0 && interactionIndex > 0
        ? interactionStatistics[interactionIndex][2] : 0;
  if (interaction.children) {
    interaction.children.sort(
      function compare(a, b) {
        return a.sortOrder > b.sortOrder ? 1 : -1;
      }
    );
    return interaction.children.map((component, index) => {
      let componentAnswers = filterStudentAnswersByModuleItemId(component, filteredAnswersByInteraction);
      let componentOptions = options.filter(option => {
        return option.moduleItemId === component._id;
      });
      return renderAnsweredLessonComponents(
        component,
        index,
        componentAnswers,
        componentOptions,
        numberOfCompletions,
        showTextAnswers,
        setShowTextAnswers,
        showParagpraghAnswers,
        setShowParagpraghAnswers,
        showMultiChoiceAnswers,
        setShowMultiChoiceAnswers,
        showCheckboxAnswers,
        setShowCheckboxAnswers,
        showSliderAnswers,
        setShowSliderAnswers,
        showComments,
        setShowComments
      );
    });
  }
  return null;
};

export default InteractionStatisticsModal;
