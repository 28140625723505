import React, { useRef, useLayoutEffect } from 'react';
import './AnswerWideTextarea.css';

/**
* AnswerWideTextarea: reusable textarea that will dynamically grow
* vertically, used on the One Correct Word and Open Answer components
*/

const AnswerWideTextarea = ({ text, setNewText, correctAnswer, autoFocus, openAnswerComponent }) => {
  const minTextAreaHeight = 16;
  const textareaRef = useRef(null);

  useLayoutEffect(() => {
    textareaRef.current.style.height = "16px";
    textareaRef.current.style.height = `${Math.max(textareaRef.current.scrollHeight, minTextAreaHeight)}px`;
  }, [text]);

  return (
    <textarea
      style={openAnswerComponent ? {marginBottom: "12px"} : {}}
      className={correctAnswer ? "answerWideTextarea answerWideTextareaCorrect" : "answerWideTextarea"}
      value={text}
      onChange={(event) => setNewText(event.target.value)}
      ref={textareaRef}
      autoFocus={autoFocus}
    />
  );
};

export default AnswerWideTextarea;
