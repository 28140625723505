import React, { useEffect, useState } from 'react';
import './AutosaveReconnectingToast.css';

/**
* AutosaveReconnectingToast: toast used on the course core to show the connection status
*/
const AutosaveReconnectingToast = () => {
  const currentPath = window.location.pathname.split('/');
  const doubleMenuPage = currentPath[3] === "lessons" || currentPath[3] === "library";
  const [toastClassname, setToastClassname] = useState(
    doubleMenuPage ?
    "reconnectingToastDoubleMenu reconnectingToastHidden" :
    "reconnectingToast reconnectingToastHidden"
  );

  useEffect(() => {
    setToastClassname(
      doubleMenuPage ?
      "reconnectingToastDoubleMenu reconnectingToastVisible" :
      "reconnectingToast reconnectingToastVisible"
    );
  }, []);

  return (
    <div className={toastClassname}>
      <div className="reconnectingToastContent">
        <div>Lost connection, trying to reconnect</div>
      </div>
    </div>
  );
};

export default AutosaveReconnectingToast;
