import React from 'react';
import './ButtonSecondaryOutlined.css';

/**
 * ButtonSecondaryOutlined
 */
const ButtonSecondaryOutlined = ({
  type,
  label,
  disabled,
  onClick,
  icon,
  iconSource,
  activeState,
}) => {
  const className = () => {
    if (disabled) {
      return 'buttonSecondaryOutlinedDisabled';
    } else if (activeState) {
      return 'buttonSecondaryOutlinedActive';
    } else {
      return 'buttonSecondaryOutlined';
    }
  };

  return (
    <button
      type={type}
      className={className()}
      disabled={disabled}
      onClick={onClick}
    >
      {icon && (
        <img
          className={`buttonSecondaryOutlinedIcon ${
            disabled ? 'buttonSecondaryOutlinedIconDisabled' : ''
          }`}
          src={icon}
        />
      )}
      {iconSource && <img className="buttonPrimaryBlueIcon" src={iconSource} />}
      {label}
    </button>
  );
};

export default ButtonSecondaryOutlined;
