import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '../../actions';
import IFrameComponent from './IFrameComponent';
import CourseCoreMoveTooltip from './CourseCoreMoveTooltip';
import CourseCoreDeleteTooltip from './CourseCoreDeleteTooltip';
import draggable_handle from '../../images/svg/draggable_handle.svg';
import draggable_handle_onhover from '../../images/svg/draggable_handle_onhover.svg';
import video_icon from '../../images/svg/video_icon.svg';
import red_x_mark from '../../images/svg/red_x_mark.svg';
import red_x_mark_onhover from '../../images/svg/red_x_mark_onhover.svg';
import './VideoComponent.css';


/**
* VideoComponent: Field which adds a Video to the Module, chosen from the Add Content dropdown. We will only
* allow YouTube and Vimeo URLs which will render an iFrame, otherwise a Warning message is shown to enter a
* correct URL.
*/
const VideoComponent = (props) => {
  const dispatch = useDispatch();
  const { moduleId, moduleItemId, videoUrl, moduleType, provided, numberOfComponents, parentId, autoFocus } = props;
  const [localVideoUrl, setLocalVideoUrl] = useState(videoUrl);
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    let debouncedSaveFunction = setTimeout(
      () => {
        if (hasLoaded && checkValidURL()) {
          dispatch(actions.updateComponent.request(moduleId, moduleItemId, { url: localVideoUrl }, moduleType));
        }
        setHasLoaded(true);
      },
      500
    );
    return () => clearTimeout(debouncedSaveFunction);
  }, [localVideoUrl]);

  const vimeoRegex = /https:\/\/vimeo.com\/\d{8,}(?=\b|\/)/;
  const youTubeRegex = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11,})(?:\S+)?$/;
  const checkValidURL = () => {
    if(vimeoRegex.test(localVideoUrl) || youTubeRegex.test(localVideoUrl)) {
      return true;
    }
  };
  return (
    <div className="videoComponentContainer">
      <img
        className={numberOfComponents === 1 ? "videoComponentIcon videoComponentIconAlone" : "videoComponentIcon"}
        src={video_icon}
      />
      {numberOfComponents > 1 &&
        <div className="videoComponentTooltipParent" {...provided.dragHandleProps}>
          <img className="videoComponentDraggableHandle" src={draggable_handle}/>
          <img className="videoComponentDraggableHandleOnHover" src={draggable_handle_onhover}/>
          <CourseCoreMoveTooltip x={"-140%"} y={"-195%"}/>
        </div>
      }
      <div className="videoComponentiFrameAndURLWrapper">
        {localVideoUrl && checkValidURL() && <IFrameComponent url={localVideoUrl}/>}
        <input
          type="text"
          className="videoComponentInput"
          placeholder={'Vimeo Link or YouTube Link'}
          value={localVideoUrl || ""}
          onChange={(event) => setLocalVideoUrl(event.target.value)}
          autoFocus={autoFocus}
        />
        {localVideoUrl && !checkValidURL() &&
          <div className="videoComponentWarningMessage">The URL must be a valid Vimeo or YouTube link</div>
        }
      </div>
      <div className="videoComponentXMarkContainer">
        <img className="videoComponentXMark" src={red_x_mark}/>
        <img
          className="videoComponentXMarkOnHover"
          src={red_x_mark_onhover}
          onClick={() => dispatch(actions.deleteComponent.request(moduleId, moduleItemId, moduleType, parentId))}
        />
        <CourseCoreDeleteTooltip x={"30%"} y={"-195%"}/>
      </div>
    </div>
  );
};

export default VideoComponent;
