import React from 'react';
import unchecked_checkbox from '../../images/styleguideComponentsImages/checkbox_unchecked.svg';
import checked_checkbox from '../../images/styleguideComponentsImages/checkbox_checked.svg';
import './MultiselectCheckbox.css';

/**
 * MultiselectCheckbox: Reusable checkbox component
 */
const MultiselectCheckbox = ({ label, onClick, checked }) => {
  return (
    <div onClick={onClick} className="multiselectCheckboxOption">
      {checked ? (
        <img src={checked_checkbox} className="multiselectCheckboxOptionImg" />
      ) : (
        <img
          src={unchecked_checkbox}
          className="multiselectCheckboxOptionImg"
        />
      )}
      <div className="multiselectCheckboxOptionText">{label}</div>
    </div>
  );
};

export default MultiselectCheckbox;
