import './BatchUploadModal.css';
import ButtonSecondaryOutlined from '../components/styleguideComponents/ButtonSecondaryOutlined';
import green_checkbox from '../images/svg/green_checkbox.svg';
import Modal from '@material-ui/core/Modal';
import React from 'react';
import red_x from '../images/svg/red_x.svg';
import yellow_questionmark from '../images/svg/yellow_questionmark.svg';

const BatchUploadModal = ({ open, result, onClose }) => {
  const { error, queuedCount = 0 } = result ?? {};

  return (
    <Modal
      id="modal"
      className="batchUploadModal"
      open={open}
      onClose={onClose}
    >
      <div className="batchUploadModalBackground">
        {queuedCount > 0 && renderSuccessContent(queuedCount)}
        {!error && queuedCount === 0 && renderEmptyFileContent()}
        {error && renderErrorContent(error)}
        <div className="batchUploadModalButton">
          <ButtonSecondaryOutlined
            type="button"
            label="Close"
            onClick={onClose}
          />
        </div>
      </div>
    </Modal>
  );
};

const renderSuccessContent = (queuedCount) => {
  return (
    <>
      <img className="batchUploadModalIcon" src={green_checkbox} />
      <div className="batchUploadModalMainText batchUploadModalSuccessTextColor">
        File uploaded
      </div>
      <div className="batchUploadModalHelperText">
        {`${queuedCount} users are being added to your course`}
      </div>
    </>
  );
};

const renderEmptyFileContent = () => {
  return (
    <>
      <img className="batchUploadModalIcon" src={yellow_questionmark} />
      <div className="batchUploadModalMainText batchUploadModalEmptyTextColor">
        Empty file
      </div>
      <div className="batchUploadModalHelperText">
        The file you are trying to upload is empty
      </div>
      <div className="batchUploadModalHelperText">
        If you are unsure how to format your file please consider to download
        the templates
      </div>
    </>
  );
};

const renderErrorContent = (failureMessage) => {
  return (
    <>
      <img className="batchUploadModalIcon" src={red_x} />
      <div className="batchUploadModalMainText batchUploadModalErrorTextColor">
        No users uploaded
      </div>
      <div className="batchUploadModalHelperText">
        {failureMessage
          ? failureMessage
          : 'No users were added because of an unknown error'}
      </div>
      <div className="batchUploadModalHelperText">
        Please contact{' '}
        <a
          className="batchUploadModaSupportEmail"
          href="mailto:support@moblrn.com"
        >
          support@moblrn.com
        </a>{' '}
        for further assistance
      </div>
    </>
  );
};

export default BatchUploadModal;
