import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams  } from 'react-router-dom';
import Const from '../store/Const';
import * as actions from '../actions';
import CourseLeftMenu from '../components/CourseLeftMenu.js';
import CourseSettingsForm from '../components/forms/CourseSettingsForm.js';
import Toast from '../components/Toast.js';
import ErrorToast from '../components/ErrorToast.js';
import LoadingToast from '../components/LoadingToast.js';
import './CourseSettingsPage.css';

/**
* CourseSettingsPage
*/
const CourseSettingsPage = () => {
  const dispatch = useDispatch();
  const toastActive = useSelector(state => state.componentStates.toastActive);
  const errorToastActive = useSelector(state => state.componentStates.errorToastActive);
  const loadingToastActive = useSelector(state => state.componentStates.loadingToastActive);
  const toastMessage = useSelector(state => state.componentStates.toastMessage);
  const { courseId } = useParams();
  useEffect(() => {
    dispatch(actions.getCourse.request(courseId));
    dispatch(actions.getModuleContent.request(courseId, Const.moduleType.Welcome));
  }, []);

  return (
    <div className="courseSettingsPageContainer">
      <CourseLeftMenu/>
      <div className="courseSettingsFormWrapper">
        <CourseSettingsForm/>
      </div>
      {toastActive &&
        <Toast
          text="Settings saved"
          timeToClose={5000}
        />
      }
      {errorToastActive &&
        <ErrorToast
          text={toastMessage}
          timeToClose={10000}
        />
      }
      {loadingToastActive &&
        <LoadingToast
          text={toastMessage}
        />
      }
    </div>
  );
};

export default CourseSettingsPage;
