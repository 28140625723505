import React from 'react';
import update_status_failed_icon from '../../images/svg/update_status_failed_icon.svg';
import './UpdateUserStatusCacheFailedStage.css';

/**
 * UpdateUserStatusCacheFailedStage: this modal indicates the
 * user status cache update has failed for some reason
 */
const UpdateUserStatusCacheFailedStage = () => {
  return (
    <div className="updateStatusFailedWrapper">
      <div className="updateStatusFailedImageAndTitleWrapper">
        <img
          className="updateStatusFailedImage"
          src={update_status_failed_icon}
          alt="update failed icon"
        />
        <div className="updateStatusFailedText">UPDATE FAILED</div>
      </div>
      <div>
        <div className="updateStatusFailedHelperText">
          Something went wrong.
        </div>
        <div className="updateStatusFailedHelperText">
          We have been notified and will look into it.
        </div>
      </div>
      <a
        className="updateStatusFailedContactText"
        href="mailto:support@moblrn.com"
      >
        Contact support
      </a>
    </div>
  );
};

export default UpdateUserStatusCacheFailedStage;
