import React from 'react';
import * as actions from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import SavingProgressComponent from './SavingProgressComponent';
import ConnectionTrackerComponent from './ConnectionTrackerComponent';
import ConnectionToastsComponent from './ConnectionToastsComponent';
import lost_connection_x from '../../images/svg/lost_connection_x.svg';
import lost_connection_cloud from '../../images/svg/lost_connection_cloud.svg';
import autosave_saving_arrows from '../../images/svg/autosave_saving_arrows.svg';
import './AutosaveIconsTrackerComponent.css';

/**
* AutosaveIconsTrackerComponent: Component that will determine which Autosave icon to display
* depending on the autosave progress. Will also keep track on the network and show
* connection status icons
*/

const AutosaveIconsTrackerComponent = () => {
  const dispatch = useDispatch();
  const offline = useSelector(state => state.componentStates.offlineStatusActive);

  const hasNetwork = (online) => {
    if(!online) {
      dispatch(actions.setOfflineStatus.request(true));
      dispatch(actions.setConnectionStatus.request('connectionLost'));
    } else {
      dispatch(actions.setConnectionStatus.request('connected'));
      setTimeout(() => dispatch(actions.setConnectionStatus.request('')), 1500);
      setTimeout(() => dispatch(actions.setOfflineStatus.request(false)), 1500);
    }
  };
  window.addEventListener("load", () => {
    hasNetwork(navigator.onLine);
  
    window.addEventListener("online", () => {
      hasNetwork(true);
    });
  
    window.addEventListener("offline", () => {
      hasNetwork(false);
    });
  });

  return (
    <>      
      {!offline && <SavingProgressComponent/>}
      {offline && <ConnectionTrackerComponent/>}
      {offline && <ConnectionToastsComponent/>}
      <img className="hiddenConnectionIcons" src={lost_connection_x}/>
      <img className="hiddenConnectionIcons" src={lost_connection_cloud}/>
      <img className="hiddenConnectionIcons" src={autosave_saving_arrows}/>
    </>
  );
};

export default AutosaveIconsTrackerComponent;
