import React from 'react';
import { useSelector } from 'react-redux';
import {
  Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import StatisticsPage from './program-dashboard/StatisticsPage';
import ChangePasswordPage from './pages/ChangePasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ChangePasswordNoCodePage from './pages/ChangePasswordNoCodePage';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import NewCoursePage from './pages/NewCoursePage';
import ClientPage from './pages/ClientPage';
import ClientOverviewPage from './pages/ClientOverviewPage';
import AdminPage from './pages/AdminPage';
import UserPage from './pages/UserPage';
import AddClientPage from './pages/AddClientPage';
import CourseSettingsPage from './pages/CourseSettingsPage';
import CourseLessonsPage from './pages/CourseLessonsPage';
import CourseAboutPage from './pages/CourseAboutPage';
import CourseWelcomePage from './pages/CourseWelcomePage';
import CourseLibraryPage from './pages/CourseLibraryPage';
import CourseCertificatePage from './pages/CourseCertificatePage';
import CourseWelcomeMailPage from './pages/CourseWelcomeMailPage';
import UsersDataPage from './users-data/UsersDataPage';
import CourseEditUserPage from './pages/CourseEditUserPage';
import AddUsersPage from './program-add-user/AddUsersPage';
import CourseSignupPage from './pages/CourseSignupPage';
import EditClientPage from './pages/EditClientPage';
import SplashScreen from './pages/SplashScreen';
import EditAdminPage from './pages/EditAdminPage';
import AddAdminPage from './pages/AddAdminPage';
import APIOverviewPage from './pages/APIOverviewPage';
import CourseUserOverviewPage from './pages/CourseUserOverviewPage';
import ProgressOverviewPage from './progress-overview/ProgressOverviewPage';
import Amplify from 'aws-amplify';
import history from './store/history';
import UploadStatus from './program-add-user/UploadStatus';

function App() {
  const isLoggedIn = useSelector(state => state.componentStates.isLoggedIn);
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/login" render={ () => (
          isLoggedIn ?
          <Redirect to={getEntryUrl()}/>
          : <LoginPage/>
        )}/>
        <Route exact path="/changepasswordnocode" component={ChangePasswordNoCodePage}/>
        <Route exact path="/changepassword" component={ChangePasswordPage}/>
        <Route exact path="/resetpassword" component={ResetPasswordPage}/>
        <PrivateRoute exact path="/" component={DashboardPage} isLoggedIn={isLoggedIn}/>
        <PrivateRoute exact path="/newcourse" component={NewCoursePage} isLoggedIn={isLoggedIn}/>
        <PrivateRoute exact path="/clients" component={ClientPage} isLoggedIn={isLoggedIn}/>
        <PrivateRoute exact path="/clients/:clientId/clientoverview" component={ClientOverviewPage} isLoggedIn={isLoggedIn}/>
        <PrivateRoute exact path="/admins" component={AdminPage} isLoggedIn={isLoggedIn}/>
        <PrivateRoute exact path="/users" component={UserPage} isLoggedIn={isLoggedIn}/>
        <PrivateRoute exact path="/usersprogress" component={ProgressOverviewPage} isLoggedIn={isLoggedIn}/>
        {/* Billing page will be hidden for now, keeping route in case it needs to be shown again */}
        {/* <PrivateRoute exact path="/billing" component={SubscriptionsPage} isLoggedIn={isLoggedIn}/> */}
        <PrivateRoute exact path="/addclient" component={AddClientPage} isLoggedIn={isLoggedIn}/>
        <PrivateRoute exact path="/admins/addadmin" component={AddAdminPage} isLoggedIn={isLoggedIn}/>
        <PrivateRoute exact path="/apioverview" component={APIOverviewPage} isLoggedIn={isLoggedIn}/>
        {/* Client Settings for Client Admins will be hidden for now, keeping route in case it needs to be shown again */}
        {/* <PrivateRoute exact path="/client/:id/settings" component={ClientSettingsPage} isLoggedIn={isLoggedIn}/> */}
        <PrivateRoute exact path="/client/:id" component={EditClientPage} isLoggedIn={isLoggedIn}/>
        <PrivateRoute exact path="/admin/:id" component={EditAdminPage} isLoggedIn={isLoggedIn}/>
        <PrivateRoute exact path="/course/:courseId/welcome" component={CourseWelcomePage} isLoggedIn={isLoggedIn}/>
        <PrivateRoute exact path="/course/:courseId/lessons/:pageid?" component={CourseLessonsPage} isLoggedIn={isLoggedIn}/>
        <PrivateRoute exact path="/course/:courseId/library/:pageid?" component={CourseLibraryPage} isLoggedIn={isLoggedIn}/>
        <PrivateRoute exact path="/course/:courseId/about" component={CourseAboutPage} isLoggedIn={isLoggedIn}/>
        <PrivateRoute exact path="/course/:courseId/certificate" component={CourseCertificatePage} isLoggedIn={isLoggedIn}/>
        <PrivateRoute exact path="/course/:courseId/welcomemail" component={CourseWelcomeMailPage} isLoggedIn={isLoggedIn}/>
        <PrivateRoute exact path="/course/:courseId/users" component={UsersDataPage} isLoggedIn={isLoggedIn}/>
        <PrivateRoute exact path="/course/:courseId/user/:userId" component={CourseEditUserPage} isLoggedIn={isLoggedIn}/>
        <PrivateRoute exact path="/course/:courseId/useroverview/:userId" component={CourseUserOverviewPage} isLoggedIn={isLoggedIn}/>
        <PrivateRoute exact path="/course/:courseId/adduser" component={AddUsersPage} isLoggedIn={isLoggedIn}/>
        <PrivateRoute exact path="/course/:courseId/signup" component={CourseSignupPage} isLoggedIn={isLoggedIn}/>
        <PrivateRoute exact path="/course/:courseId/dashboard" component={StatisticsPage} isLoggedIn={isLoggedIn}/>
        <PrivateRoute exact path="/course/:courseId/settings" component={CourseSettingsPage} isLoggedIn={isLoggedIn}/>
        <PrivateRoute path="/" component={<Redirect to="/"/>}/>
      </Switch>
      <UploadStatus />
    </Router>
  );
}

//https://stackoverflow.com/questions/44666149/redirect-to-login-page-using-react-router-v4
const PrivateRoute = ({component: Component, isLoggedIn, path}) => (
  <Route
    path={path}
    render = {props => {
      localStorage.setItem("previousLocation", localStorage.getItem("currentLocation"));
      localStorage.setItem("currentLocation", props.match.url);
      if (isLoggedIn === undefined) {
        return <SplashScreen/>;
      }
      return (
        isLoggedIn ?
        <Component {...props}/>
        : createLoginRedirectComponent(props)
      );
    }
  }/>
);

/**
* getEntryUrl gets the url the user tried to enter before they were redirected
* to the login page
*/
const getEntryUrl = () => {
  let entryUrl  = localStorage.getItem("entryUrl");
  if (entryUrl && !entryUrl.includes('/login')) {
    return entryUrl;
  }
  return "/";
};

const createLoginRedirectComponent = (props) => {
  if (props.match.url !== '/settings') {
    localStorage.setItem("entryUrl", props.match.url);
  }
  return <Redirect to="/login"/>;
};

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_USERPOOL_REGION,
    userPoolId: process.env.REACT_APP_USERPOOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USERPOOL_WEBCLIENT_ID,
    cookieStorage: undefined,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    identityPoolId: process.env.REACT_APP_IDENTITYPOOL_ID
  },
  Storage: {
    bucket: process.env.REACT_APP_BUCKET_NAME,
    region: 'us-east-1',
    identityPoolId: process.env.REACT_APP_IDENTITYPOOL_ID,
    customPrefix: {
      public: ''
    }
  }
});

export default App;
