import './ProgressOverviewTable.css';
import { Link } from 'react-router-dom';
import { renderSortIcon } from '../components/tables/commonTableComponents';
import { useProgressOverview } from './progress-overview.store';
import { useAddUserToProgram } from './add-user-to-program.store';
import { useSelector } from 'react-redux';
import champion_cup_white from '../images/svg/champion_cup_white.svg';
import LazyLoadingTableFooter from '../components/LazyLoadingTableFooter';
import React, { useEffect, useState } from 'react';
import SearchComponent from '../components/SearchComponent';
import StudentsProgressTableDropdown from '../components/StudentsProgressTableDropdown';
import StudentTableSelectDropdownComponent from '../components/StudentTableSelectDropdownComponent';
import LoadingText from '../components/LoadingText';

/**
 * ProgressOverviewTable
 */
const ProgressOverviewTable = () => {
  const user = useSelector(state => state.user);
  const isSuperAdmin = user.isSuperAdmin;
  const userPermissions = user.permissions[0] ?? {};

  const {
    clientId,
    clientNames,
    companiesNames,
    currentClientIndex,
    departmentsNames,
    fetchNextPage,
    filteredCount,
    isLoading,
    isFetchingNextPage,
    publishedPrograms,
    searchString,
    selectedCompanies,
    selectedDepartments,
    setState,
    status,
    students,
    totalCount,
    setAttrSortOrder,
    sortAttribute,
    sortOrder,
  } = useProgressOverview();

  const addUser = useAddUserToProgram();

  const areThereFiltersSelected =
    selectedCompanies.length > 0 || selectedDepartments.length > 0;

  const showPromptText = () => {
    if (students.length === 0 && publishedPrograms.length === 0) {
      return (
        <div className="studentsProgressTablePromptText">
          No users or active programs found for this Client
        </div>
      );
    } else if (
      areThereFiltersSelected &&
      searchString &&
      students.length === 0
    ) {
      return (
        <div className="studentsProgressTablePromptText">
          No users found with current filters and search criteria
        </div>
      );
    } else if (areThereFiltersSelected && students.length === 0) {
      return (
        <div className="studentsProgressTablePromptText">
          No users found with current filters
        </div>
      );
    } else if (searchString && students.length === 0) {
      return (
        <div className="studentsProgressTablePromptText">
          No users found with this search criteria
        </div>
      );
    } else if (students.length === 0) {
      return (
        <div className="studentsProgressTablePromptText">
          No users found for this Client
        </div>
      );
    } else if (publishedPrograms.length === 0) {
      return (
        <div className="studentsProgressTablePromptText">
          No active Programs found for this Client
        </div>
      );
    }
  };

  const [intersectionObserverEnabled, setIntersectionObserverEnabled] =
    useState(!isLoading);

  useEffect(() => {
    setIntersectionObserverEnabled(!(isLoading || isFetchingNextPage));
  }, [isLoading, isFetchingNextPage]);

  const shouldShowLazyRow =
    status === 'idle' ||
    (students.length !== filteredCount && students.length !== totalCount);

  return (
    <div className="studentsProgressTableContainer">
      <div className="studentsProgressTableTitle">
        <div>
          USER PROGRESS OVERVIEW
          {filteredCount && filteredCount !== totalCount
            ? ` (${filteredCount}/${totalCount} Users)`
            : ''}
        </div>
      </div>
      <div className="studentsProgressTableSearchAndFiltersWrapper">
        <div className="studentsProgressTableFiltersWrapper">
          {isSuperAdmin && (
            <div>
              <div className="studentTableSelectDropdownTitle">Client</div>
              <StudentsProgressTableDropdown
                defaultText="Client"
                optionTexts={clientNames}
                selectedClientIndex={currentClientIndex}
                setSelectedClientIndex={(i) =>
                  setState({ currentClientIndex: i })
                }
              />
            </div>
          )}
          <div>
            <div className="studentTableSelectDropdownTitle">Company</div>
            <StudentTableSelectDropdownComponent
              name="company"
              defaultText="Company"
              optionTexts={companiesNames}
              selectedItems={selectedCompanies}
              handleRemoveOption={(name, index) =>
                setState((state) => ({
                  selectedCompanies: state.selectedCompanies.filter(
                    (i) => i !== index
                  ),
                }))
              }
              handleChange={(_name, value) =>
                setState((state) => ({
                  selectedCompanies: [...state.selectedCompanies, value],
                }))
              }
              handleClear={() => setState({ selectedCompanies: [] })}
              source="userProgressTable"
              disabled={companiesNames.length === 0}
            />
          </div>
          <div>
            <div className="studentTableSelectDropdownTitle">Department</div>
            <StudentTableSelectDropdownComponent
              name="department"
              defaultText="Department"
              optionTexts={departmentsNames}
              selectedItems={selectedDepartments}
              handleRemoveOption={(_name, index) =>
                setState((state) => ({
                  selectedDepartments: state.selectedDepartments.filter(
                    (i) => i !== index
                  ),
                }))
              }
              handleChange={(_name, value) =>
                setState((state) => ({
                  selectedDepartments: [...state.selectedDepartments, value],
                }))
              }
              handleClear={() => setState({ selectedDepartments: [] })}
              source="userProgressTable"
              disabled={departmentsNames.length === 0}
            />
          </div>
        </div>
        <div className="studentsProgressTablSearchWrapper">
          <SearchComponent
            defaultText="Search users"
            disabled={totalCount === 0}
            onChangeDebounced={(value) => setState({ searchString: value })}
          />
        </div>
      </div>
      {!clientId && (
        <div className="studentsProgressTablePromptText">
          Please pick a Client
        </div>
      )}
      {isLoading && (
        <div className="studentsProgressTableLoadingText">
          Loading <span>.</span>
          <span>.</span>
          <span>.</span>
        </div>
      )}
      {clientId && publishedPrograms.length > 0 && (
        <>
          <table className="studentsProgressTable">
            <tbody>
              <tr className="studentsProgressTableHeaderRow">
                <th className="studentsProgressTableNameWrapper">
                  <div
                    className={
                      students.length === 0
                        ? 'studentsProgressTableTitleAndIconContainer studentsProgressTableUsernameHeaderNoResults'
                        : 'studentsProgressTableTitleAndIconContainer studentsProgressTableUsernameHeader'
                    }
                    onClick={() => setAttrSortOrder('username')}
                  >
                    <div className="studentsProgressTableNameTitle">Name</div>
                    {renderSortIcon('username', sortAttribute, sortOrder)}
                  </div>
                </th>
                {publishedPrograms.map((program) => {
                  return (
                    <th
                      className="studentsProgressTableProgramNameWrapper"
                      key={program._id}
                    >
                      <Link
                        className="studentsProgressTableProgramNameText"
                        to={`/course/${program._id}/dashboard`}
                      >
                        {program.name}
                      </Link>
                    </th>
                  );
                })}
              </tr>
              {students.map((student, index) => {
                return (
                  <tr className="studentsProgressTableRow" key={index}>
                    <td className="progressOverviewTableText studentsProgressTableUsernameValue">
                      {student.username}
                    </td>
                    {publishedPrograms.map((program, index) => {
                      return (
                        <td
                          className="progressOverviewTableText studentsProgressTableStatusValue"
                          key={index}
                        >
                          {renderStudentStatusInProgram(
                            student,
                            program,
                            addUser, 
                            isSuperAdmin,
                            userPermissions
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
            {shouldShowLazyRow && (
              <LazyLoadingTableFooter
                callback={() => fetchNextPage()}
                colSpan={9}
                observerEnabled={intersectionObserverEnabled}
              />
            )}
          </table>
        </>
      )}
      {clientId && !(isLoading || isFetchingNextPage) && showPromptText()}
      {(areThereFiltersSelected || searchString) && (
        <div className="progressOverviewTableStickyButtonsWrapper">
          <button
            type="button"
            onClick={() =>
              setState({
                searchString: '',
                selectedCompanies: [],
                selectedDepartments: [],
              })
            }
          >
            CLEAR FILTERS
          </button>
        </div>
      )}
    </div>
  );
};

const renderStudentStatusInProgram = (student, program, addUser, isSuperAdmin, userPermissions) => {
  const statusData = student.statuses?.[program._id];
  let status = statusData?.status ?? '-';
  const studentURL = `/course/${program._id}/useroverview/${student._id}`;
  const mutationId = addUser.getMutationId({
    programId: program._id,
    email: student.email,
  });

  if (status === '-') {
    const addUserText = {
      loading: (
        <div className="progressOverviewPendingText">
          <LoadingText label="Pending" />
        </div>
      ),
      success: (
        <Link
          className="progressStatusStyled progressStatusNotStarted"
          to={studentURL}
        >
          <div>Invited</div>
        </Link>
      ),
      error: (
        <div className="progressOverviewFailStatus">
          <div>Fail</div>
          <div className="progressOverviewFailStatusTooltip">
            {addUser.errorMessageById[mutationId]}
          </div>
        </div>
      ),
    };

    const addingUserStatus = addUser.statusById[mutationId];

    return (
      (isSuperAdmin || userPermissions.fullRights) ?
      <div
        onClick={
          !addingUserStatus
            ? () =>
                addUser.mutate({
                  programId: program._id,
                  username: student.username,
                  email: student.email,
                  company: student.company,
                  department: student.department,
                })
            : null
        }
      >
        {addUserText[addingUserStatus] ?? (
          <div className="progressOverviewInviteButton">Invite</div>
        )}
      </div>
      :
      <div>-</div>
    );
  }

  let styledStatus;
  switch (status) {
    case 'Certified':
      styledStatus = (
        <Link className="progressStatusFinishedWithCertificate" to={studentURL}>
          <img src={champion_cup_white} alt="champion cup" />
          <div className="progressStatusFinishedWithCertificateText">
            Certified
          </div>
        </Link>
      );
      break;
    case 'Finished':
      styledStatus = (
        <Link
          className="progressStatusStyled progressStatusFinished"
          to={studentURL}
        >
          <div>Finished</div>
        </Link>
      );
      break;
    case 'In progress':
      styledStatus = (
        <Link
          className="progressStatusStyled progressStatusInProgress"
          to={studentURL}
        >
          <div>In progress</div>
        </Link>
      );
      break;
    case 'Not started':
      styledStatus = (
        <Link
          className="progressStatusStyled progressStatusNotStarted"
          to={studentURL}
        >
          <div>Invited</div>
        </Link>
      );
      break;
  }

  return styledStatus;
};

export default ProgressOverviewTable;
