import { combineReducers } from 'redux';
import clients from '../reducers/clients';
import admins from '../reducers/admins';
import user from '../reducers/user';
import users from '../reducers/users';
import programs from '../reducers/programs';
import componentStates from '../reducers/componentStates';



export default () => combineReducers({
  user,
  users,
  clients,
  admins,
  programs,
  componentStates
});
