import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { renderDropzone, renderNumberField } from './commonFormComponents';
import {
  title,
  required,
  hasNumberInRange,
  composeValidators
} from '../../utils/validationUtils';
import * as actions from '../../actions';
import DateTimePicker from 'react-datetime-picker/dist/entry.nostyle';
import './CreateCourseForm.css';
import '../dateTimePicker/TimePicker.css';
import '../dateTimePicker/Clock.css';
import '../dateTimePicker/DatePicker.css';
import '../dateTimePicker/Calendar.css';

/**
* CreateCourseForm is the form where admins can set the parameters
* for a new course
*/
const CreateCourseForm = () => {
  const dispatch = useDispatch();
  const programNameRef = useRef('programNameRef');
  const isSuperAdmin = useSelector(state => state.user.isSuperAdmin);

  const clientList = useSelector(state => state.clients.clientNames);
  if (isSuperAdmin) {
    useEffect(() => dispatch(actions.getClientNames.request()), []);
  }

  const [typeOpenCourse, setTypeOpenCourse] = useState(false);
  const [typeSpecificTimeline, setTypeSpecificTimeline] = useState(false);

  const handleOpenCourseClick = () => {
    setTypeOpenCourse(true);
    setTypeSpecificTimeline(false);
  };

  const handleSpecificTimelineClick = () => {
    setTypeSpecificTimeline(true);
    setTypeOpenCourse(false);
  };

  const openCourseButtonClassNames = () => {
    if(!typeOpenCourse && !typeSpecificTimeline) {
      return "createCourseTimelineButton createCourseOpenCourseButton";
    } else if(typeOpenCourse) {
      return "createCourseTimelineButtonActive createCourseOpenCourseButton";
    } else if(typeSpecificTimeline) {
      return "createCourseTimelineButton createCourseOpenCourseButton";
    }
  };

  const specificTimelineClassNames = () => {
    if(!typeSpecificTimeline && !typeOpenCourse) {
      return "createCourseTimelineButton";
    } else if(typeSpecificTimeline) {
      return "createCourseTimelineButtonActive";
    } else if(typeOpenCourse) {
      return "createCourseTimelineButton";
    }
  };

  const handleSubmit = (data) => {
    let startDate;
    if(data.timelineType === "2") {
      startDate = data.specificTimelineDate;
    }
    dispatch(actions.createCourse.request(
      data.courseTitle,
      data.createCourseClient || null,
      data.timelineType,
      0,
      data.openCourseFromTime,
      data.openCourseToTime,
      startDate,
      data.createCourseImage || ""
    ));
    dispatch(actions.setToastOrigin.request('/newcourse'));
  };

  return (
    <div className="createCourseContainer">
      <div className="createCourseTitle">CREATE PROGRAM</div>
      <Form
        onSubmit={(data) => handleSubmit(data)}
        initialValues={{
          openCourseFromTime: '9',
          openCourseToTime: '20'
        }}
      >
        {({ handleSubmit, values, errors, form }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            {isSuperAdmin &&
              <>
                <div className="createCourseSubTitle">Client</div>
                <div className="createCourseClientDropdownContainer">
                  <Field name="createCourseClient" options={clientList}>
                    {({input, options}) => {
                      return (
                        <Dropdown
                          options={options}
                          name={input.name}
                          placeholder="Choose client"
                          onChange={(value) => input.onChange(value)}
                        />
                      );
                    }}
                  </Field>
                </div>
              </>
            }
            <div className="createCourseTitleQuestionContainer">
              <div className="createCourseSubTitle">Program name</div>
              <Field
                name="courseTitle"
                validate={composeValidators(title)}
              >
                {({ input, meta }) => (
                  <div className="createCourseInputAndErrorContainer">
                    <div className="createCourseNameInputAndCounter">
                      <input
                        {...input}
                        type="text"
                        placeholder="Enter the program name here"
                        className="createCourseTitleInput"
                        minLength= "1"
                        maxLength= "30"
                        ref={programNameRef}
                        required
                      />
                      <div className="createCourseNameTextCounter">{calculateNameLength(programNameRef) + '/30'}</div>
                    </div>
                    {meta.error && meta.touched && <div className="createCourseErrorMessage">{meta.error}</div>}
                  </div>
                )}
              </Field>
            </div>
            <div className="createCourseSubTitle">Program image</div>
            <div className="createCourseHelperText">This image will represent the program in the app</div>
            <div className="createCourseDropzoneContainer">
              <Field
                name="createCourseImage"
                dropzonetype="square"
                component={renderDropzone}
              />
              {values.createCourseImage &&
                <button
                  className="createCourseRemoveImageButton"
                  type="button"
                  onClick={() => form.change('createCourseImage', null)}
                >
                  REMOVE IMAGE
                </button>
              }
            </div>
            <div className="createCourseQuestionContainer">
              <div className="createCourseSubTitle">Timeline type</div>
                <div className="createCourseHelperText createCourseHelperTextTopLine">Choose timeline type for your program.</div>
                <div className="createCourseHelperText">You CAN NOT change the timeline type later.</div>
                <div className="createCourseTimelineButtonsContainer">
                  <div className="createCourseOpenCourseTooltipParent">
                    <label className={openCourseButtonClassNames()}>
                      <Field
                        name="timelineType"
                        component="input"
                        type="radio"
                        value="1"
                        onClick={handleOpenCourseClick}
                      />
                      INDIVIDUAL TIMELINE
                    </label>
                    <span>
                      Select Individual Timeline when users can join the training at any time. You can add a timespan between lessons.
                    </span>
                  </div>
                  <div className="createCourseTimelineTooltipParent">
                    <label className={specificTimelineClassNames()}>
                      <Field
                        name="timelineType"
                        component="input"
                        type="radio"
                        value="2"
                        onClick={handleSpecificTimelineClick}
                      />
                      FIXED DATE TIMELINE
                    </label>
                    <span>
                      Select Fixed Date Timeline when every user should receive content on a fixed date or time. For example, 
                      a group gets new lessons every Friday at 8.15 AM for six weeks.
                    </span>
                  </div>
                </div>
              {typeOpenCourse &&
                <>
                  <div className="createCourseSubTitle">Notification settings</div>
                  <div className="createCourseHelperText">During what hours should users receive notifications? Timezone: GMT+1, 24-hour clock.</div>
                  <div className="createCourseTimelineMiniText">From</div>
                  <div className="createCourseTimePickerContainer">
                    <Field
                      name="openCourseFromTime"
                      validate={composeValidators(hasNumberInRange(0, 23))}
                      type="number"
                      min="0"
                      max="23"
                      required
                      component={renderNumberField}
                    />
                  </div>
                  <div className="createCourseTimelineMiniText createCourseTimelineMiniTextTo">To</div>
                  <div className="createCourseTimePickerContainer">
                    <Field
                      name="openCourseToTime"
                      validate={composeValidators(hasNumberInRange(0, 23))}
                      type="number"
                      min="0"
                      max="23"
                      required
                      component={renderNumberField}
                    />
                  </div>
                </>
              }
              {typeSpecificTimeline &&
                <>
                  <div className="createCourseSubTitle">Program release date</div>
                  <div className="createCourseHelperText createCourseHelperTextTopLine">At what time and date should the program be released?</div>
                  <div className="createCourseHelperText">Once the program is published, the date and time can not be changed. Timezone: GMT+1, 24-hour clock.</div>
                  <div className="createCourseTimelineDatePickerContainer">
                    <Field name="specificTimelineDate" validate={composeValidators(required)}>
                      {({input}) => {
                        return (
                          <DateTimePicker
                            name={input.name}
                            onChange={(value) => input.onChange(value)}
                            value={setDate(values.specificTimelineDate)}
                            clearIcon={null}
                            disableClock={true}
                            locale="en-en"
                            format="dd/MM/y H:mm"
                            required
                          />
                        );
                      }}
                    </Field>
                  </div>
                </>
              }
            </div>
            <button
              type="submit"
              onSubmit={handleSubmit}
              className="createCourseSubmitButton"
              disabled={!allFieldsFilledIn(isSuperAdmin, values, errors)}
            >
              CREATE PROGRAM
            </button>
          </form>
        )}
      </Form>
    </div>
  );
};

const calculateNameLength = (programNameRef) => {
  if (!programNameRef.current.value) {
    return 0;
  } else {
    return programNameRef.current.value.length;
  }
};

const Dropdown = (props) => {
  return (
    <select
      name={props.name}
      onChange={props.onChange}
      defaultValue={"default"}
      className="createCourseSelect"
    >
      <option value="default" disabled hidden>{props.placeholder}</option>
      {props.options.map((client) => {
        return (
          <option key={client._id} value={client._id}>{client.name}</option>
        );
      })}
    </select>
  );
};

const setDate = (date) => {
  if (!date) {
    return new Date();
  } else {
    return new Date(date);
  }
};

const allFieldsFilledIn = (isSuperAdmin, values, errors) => {
  if(isSuperAdmin){
    if(values.timelineType === "1"){
      return values && values.createCourseClient && values.courseTitle && values.openCourseFromTime && values.openCourseToTime && Object.keys(errors).length === 0;
    } else if(values.timelineType === "2"){
      return values && values.createCourseClient && values.courseTitle && values.specificTimelineDate && Object.keys(errors).length === 0;
    }
  } else {
    if(values.timelineType === "1"){
      return values && values.courseTitle && values.openCourseFromTime && values.openCourseToTime && Object.keys(errors).length === 0;
    } else if(values.timelineType === "2"){
      return values && values.courseTitle && values.specificTimelineDate && Object.keys(errors).length === 0;
    }
  }
};

export default CreateCourseForm;
