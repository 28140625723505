import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import loading_toast_icon from '../../images/svg/loading_toast_icon.svg';
import './ImageUploadLoadingToast.css';


/**
* ImageUploadLoadingToast is a standard toast component to show an Image is being uploaded
* Specifically used for the Image Upload components within the Course core (Welcome, About,
* Lessons and Library pages)
*/
const ImageUploadLoadingToast = (props) => {
  const { text } = props;
  const currentPath = window.location.pathname.split('/');
  const doubleMenuPage = currentPath[3] === "lessons" || currentPath[3] === "library";
  const [loadingToastClassname, setLoadingToastClassname] = useState(
    doubleMenuPage ?
    "imageUploadLoadingToastDoubleMenu imageUploadLoadingToastHidden" :
    "imageUploadLoadingToast imageUploadLoadingToastHidden"
  );
  const toastOrigin = useSelector(state => state.componentStates.imageUploadToastOrigin);

  useEffect(() => {
    setLoadingToastClassname(
      doubleMenuPage ?
      "imageUploadLoadingToastDoubleMenu imageUploadLoadingToastVisible" :
      "imageUploadLoadingToast imageUploadLoadingToastVisible"
    );
  }, []);

  if (toastOrigin === currentPath[3]) {
    return (
      <div className={loadingToastClassname}>
        <div className="imageUploadLoadingToastContent">
          <div className="imageUploadLoadingRotatingIcon">
            <img className="imageUploadLoadingToastIcon" src={loading_toast_icon} alt="loading icon"/>
          </div>
          <div className="imageUploadLoadingToastText">{text}</div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default ImageUploadLoadingToast;
