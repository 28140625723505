import React from 'react';
import { useSelector } from 'react-redux';
import AutosaveLostConnectionComponent from './AutosaveLostConnectionComponent';
import AutosaveReconnectingComponent from './AutosaveReconnectingComponent';
import AutosaveConnectedComponent from './AutosaveConnectedComponent';

/**
* ConnectionTrackerComponent
*/

const ConnectionTrackerComponent = () => {
  const connectionStatus = useSelector(state => state.componentStates.connectionStatus);

  return (
    <>
      {connectionStatus === 'connectionLost' && <AutosaveLostConnectionComponent/>}
      {connectionStatus === 'reconnecting' && <AutosaveReconnectingComponent/>}
      {connectionStatus === 'connected' && <AutosaveConnectedComponent/>}
    </>
  );
};

export default ConnectionTrackerComponent;
