import React from 'react';
import './ImageUploadLoadingAnimation.css';

/**
* ImageUploadLoadingAnimation: Loading animation that will show in the image upload components
* on the duration a new image is being uploaded
*/

const ImageUploadLoadingAnimation = () => {
  return (
    <div className="imageUploadLoadingOverlay">
      <div className="imageUploadLoadingOverlayText">
        Loading image<span>.</span><span>.</span><span>.</span>
      </div>
    </div>
  );
};

export default ImageUploadLoadingAnimation;
