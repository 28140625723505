import React from 'react';
import { useSelector } from 'react-redux';
import AutosaveReconnectingToast from './AutosaveReconnectingToast';
import AutosaveConnectedToast from './AutosaveConnectedToast';

/**
* ConnectionToastsComponent
*/

const ConnectionToastsComponent = () => {
  const connectionStatus = useSelector(state => state.componentStates.connectionStatus);

  return (
    <>
      {connectionStatus !== 'connected' && <AutosaveReconnectingToast/>}
      {connectionStatus === 'connected' && <AutosaveConnectedToast/>}
    </>
  );
};

export default ConnectionToastsComponent;
