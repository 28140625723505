import React from 'react';
import { useSelector } from 'react-redux';
import Menu from '../components/Menu.js';
import CourseList from '../components/CourseList.js';
import Toast from '../components/Toast.js';
import ErrorToast from '../components/ErrorToast.js';
import LoadingToast from '../components/LoadingToast.js';
import './DashboardPage.css';


/**
* DashboardPage is the first page the user lands on and lists all the courses
* that the admin manages
*/
const DashboardPage = () => {
  const toastActive = useSelector(state => state.componentStates.toastActive);
  const errorToastActive = useSelector(state => state.componentStates.errorToastActive);
  const loadingToastActive = useSelector(state => state.componentStates.loadingToastActive);
  const toastMessage = useSelector(state => state.componentStates.toastMessage);
  return (
    <div className="dashboardPageContainer">
      <Menu/>
      <div className="courseListWrapper">
        <CourseList/>
      </div>
      {toastActive &&
        <Toast
          text={toastMessage}
          timeToClose={5000}
        />
      }
      {errorToastActive &&
        <ErrorToast
          text={toastMessage}
          timeToClose={10000}
        />
      }
      {loadingToastActive &&
        <LoadingToast
          text={toastMessage}
        />
      }
    </div>
  );
};

export default DashboardPage;
