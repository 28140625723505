import React from 'react';
import './CourseCoreMoveTooltip.css';

/**
* CourseCoreMoveTooltip: Reusable tooltip displayed when hovering on the Draggable
* handle of all Course Core Content Items
*/
const CourseCoreMoveTooltip = (props) => {
  const { x, y } = props;
  return (
    <div
      className="courseCoreMoveTooltip"
      style={{'transform': `translate(${x}, ${y})`}}
    >
      Move
    </div>
  );
};

export default CourseCoreMoveTooltip;
