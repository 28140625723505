import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import Const from '../../store/Const';
import * as actions from '../../actions';
import { useOutsideClick } from '../../utils/componentUtils';
import plus_white from '../../images/svg/plus_white.svg';
import headline_icon from '../../images/svg/headline_icon.svg';
import text_icon from '../../images/svg/text_icon.svg';
import image_icon from '../../images/svg/image_icon.svg';
import multiple_images_icon from '../../images/svg/multiple_images_icon.svg';
import video_icon from '../../images/svg/video_icon.svg';
import link_icon from '../../images/svg/link_icon.svg';
import './AddContentDropdown.css';

/**
* AddContentDropdown: Reusable dropdown component that includes all the options of
* fields (content) that can be added to a Module
*/
export const AddContentDropdown = ({
  moduleId,
  parentId,
  moduleType,
  sourceAboutPage,
  sourceFeedbackComponent
}) => {
  const dispatch = useDispatch();
  const [openContentDropdownMenu, setOpenContentDropdownMenu] = useState(false);
  const contentDropdownRef = useRef();
  useOutsideClick(contentDropdownRef, () => {
    setTimeout(() => setOpenContentDropdownMenu(false), 100);
  });
  const handleAddComponentClick = (componentType) => {
    dispatch(actions.addComponent.request(componentType, parentId, moduleId, moduleType));
    setOpenContentDropdownMenu(false);
  };

  const addContentButtonRef = useRef();
  const { top, height } = addContentButtonRef.current ? addContentButtonRef.current.getBoundingClientRect() : { top: 0, height: 0 };
  const distanceToBottom = window.innerHeight - top - height;

  return (
    <div className="addContentDropdownButtonParent">
      <div
        className={openContentDropdownMenu ? "addContentDropdownButton addContentDropdownButtonActive" : "addContentDropdownButton"}
        ref={addContentButtonRef}
        onClick={() => setOpenContentDropdownMenu(!openContentDropdownMenu)}
      >
        <img className="addContentDropdownButtonPlusIcon" src={plus_white}/>
        <div>ADD CONTENT</div>
      </div>
      {openContentDropdownMenu &&
        renderDropdownMenu(
          contentDropdownRef,
          handleAddComponentClick,
          sourceAboutPage,
          sourceFeedbackComponent,
          distanceToBottom
        )}
    </div>
  );
};

const renderDropdownMenu = (
  contentDropdownRef,
  handleAddComponentClick,
  sourceAboutPage,
  sourceFeedbackComponent,
  distanceToBottom
  ) => {
    const dropdownWrapperClassName = () => {
      if (sourceAboutPage) {
        if (distanceToBottom < 280) {
          return 'addContentDropdownMenuWrapperDropUp aboutPageDropdownLeftMargin'; 
        } else {
          return 'addContentDropdownMenuWrapper aboutPageDropdownLeftMargin';
        }
      } else if (sourceFeedbackComponent) {
        if (distanceToBottom < 280) {
          return 'addContentDropdownMenuWrapperDropUp feedbackComponentDropdownLeftMargin';
        } else {
          return 'addContentDropdownMenuWrapper feedbackComponentDropdownLeftMargin';
        }
      } else {
        if (distanceToBottom < 280) {
          return 'addContentDropdownMenuWrapperDropUp libraryPageDropdownLeftMargin';
        } else {
          return 'addContentDropdownMenuWrapper libraryPageDropdownLeftMargin';
        }
      }
    };

  return (
    <div
      className={dropdownWrapperClassName()}
      ref={contentDropdownRef}
    >
      <div className="addContentDropdownMenuItem" onClick={() => handleAddComponentClick(Const.moduleItemType.Title)}>
        <div>Headline</div>
        <img className="addContentDropdownMenuItemIcon" src={headline_icon}/>
      </div>
      <div className="addContentDropdownMenuItem" onClick={() => handleAddComponentClick(Const.moduleItemType.Paragraph)}>
        <div>Text</div>
        <img className="addContentDropdownMenuItemIcon" src={text_icon}/>
      </div>
      <div className="addContentDropdownMenuItem" onClick={() => handleAddComponentClick(Const.moduleItemType.Image)}>
        <div>Image</div>
        <img className="addContentDropdownMenuItemIcon" src={image_icon}/>
      </div>
      <div className="addContentDropdownMenuItem" onClick={() => handleAddComponentClick(Const.moduleItemType.ImageCarousel)}>
        <div>Multiple Images</div>
        <img className="addContentDropdownMenuItemIcon" src={multiple_images_icon}/>
      </div>
      <div className="addContentDropdownMenuItem" onClick={() => handleAddComponentClick(Const.moduleItemType.Video)}>
        <div>Video link (URL)</div>
        <img className="addContentDropdownMenuItemIcon" src={video_icon}/>
      </div>
      <div className="addContentDropdownMenuItem" onClick={() => handleAddComponentClick(Const.moduleItemType.Link)}>
        <div>Link (URL)</div>
        <img className="addContentDropdownMenuItemIcon" src={link_icon}/>
      </div>
    </div>
  );
};
