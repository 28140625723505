import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import * as actions from '../../actions';
import { Form, Field } from 'react-final-form';
import { required, name, composeValidators } from '../../utils/validationUtils';
import './CourseEditUserForm.css';

/**
* CourseEditUserForm
*/
const CourseEditUserForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { courseId, userId } = useParams();
  const toastActive = useSelector(state => state.componentStates.toastActive);
  const userToEdit = useSelector(state => state.users.currentCourseUser);
  const submitButtonDisabled = useSelector(state => state.componentStates.submitButtonDisabled);

  useEffect(() => {
    dispatch(actions.getCourseUser.request(userId));
  }, [courseId, toastActive]);

  const handleSubmit = (data) => {
    const updatedUser =  {
      _id: userToEdit._id,
      username: data.userName,
      department: data.userDepartment,
      company: data.userCompany,
      subscribedToMailPush: userToEdit.subscribedToMailPush,
      subscribedToMobilePush: userToEdit.subscribedToMobilePush
    };
    dispatch(actions.editCourseUser.request(updatedUser));
    dispatch(actions.setToastOrigin.request(`/course/${courseId}/user/${userId}`));
  };

  return (
    <>
      <div className="editUserFormTitle">EDIT USER</div>
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          userName: userToEdit.username,
          userEmail: userToEdit.email,
          userCompany: userToEdit.company,
          userDepartment: userToEdit.department
        }}
      >
        {({ handleSubmit, values, errors }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div className="editUserFormSubTitle">Name*</div>
            <div className="editUserHelperText">Enter the user&apos;s full name here, this will be visible in lists and statistics</div>
            <Field
              name="userName"
              validate={composeValidators(required, name)}
            >
              {({ input, meta }) => (
                <div className="editUserInputAndErrorContainer">
                  <div className="editUserNameInputAndCounter">
                    <input
                      {...input}
                      type="text"
                      className="editUserInputField"
                      minLength= "1"
                      maxLength= "30"
                      required
                    />
                    <div className="editUserNameTextCounter">{calculateNameLength(values.userName) + '/30'}</div>
                  </div>
                  {meta.error && meta.touched && <div className="editUserErrorMessage">{meta.error}</div>}
                </div>
              )}
            </Field>
            <div className="editUserFieldContainer editUserNonEditableField">
              <div className="editUserFormSubTitle">Email*</div>
              <div className="editUserHelperText">The email will be used for login</div>
              <Field name="userEmail">
                {({ input }) => (
                  <div className="editUserInputAndErrorContainer">
                    <input
                      {...input}
                      type="email"
                      className="editUserEmailField"
                      disabled
                    />
                  </div>
                )}
              </Field>
            </div>
            <div className="editUserFieldContainer">
              <div className="editUserFormSubTitle">Company</div>
              <div className="editUserHelperText">Assign a company to the user to get an easier overview in the statistics</div>
              <Field name="userCompany">
                {({ input }) => (
                  <div className="editUserInputAndErrorContainer">
                    <div className="editUserNameInputAndCounter">
                      <input
                        {...input}
                        type="text"
                        className="editUserInputField"
                        minLength= "1"
                        maxLength= "30"
                      />
                      <div className="editUserNameTextCounter">{calculateNameLength(values.userCompany) + '/30'}</div>
                    </div>
                  </div>
                )}
              </Field>
            </div>
            <div className="editUserFieldContainer">
              <div className="editUserFormSubTitle">Department</div>
              <div className="editUserHelperText">Department is a subcategory to company and the users result will be sorted after this</div>
              <Field name="userDepartment">
                {({ input }) => (
                  <div className="editUserInputAndErrorContainer">
                    <div className="editUserNameInputAndCounter">
                      <input
                        {...input}
                        type="text"
                        className="editUserInputField"
                        minLength= "1"
                        maxLength= "30"
                      />
                      <div className="editUserNameTextCounter">{calculateNameLength(values.userDepartment) + '/30'}</div>
                    </div>
                  </div>
                )}
              </Field>
            </div>
            <div className="editUserSubmitButtonsContainer">
              <button
                type="button"
                className="editUserCancelButton editUserButtonRightMargin"
                onClick={() => history.goBack()}
              >
                CANCEL
              </button>
              <button
                type="submit"
                onSubmit={handleSubmit}
                disabled={disableSubmitButton(values, userToEdit, errors) || submitButtonDisabled || !values.userName}
                className="editUserSubmitButton editUserButtonRightMargin"
              >
                SAVE CHANGES
              </button>
            </div>
          </form>
        )}
      </Form>
    </>
  );
};

const calculateNameLength = (value) => {
  if (!value) {
    return 0;
  } else {
    return value.length;
  }
};

const disableSubmitButton = (values, userToEdit, errors) => {
  return (
    Object.keys(errors).length > 0
    || !(values.userName !== userToEdit.username
    || values.userCompany !== userToEdit.company
    || values.userDepartment !== userToEdit.department)
  );
};

export default CourseEditUserForm;
