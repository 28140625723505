import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Menu from '../components/Menu.js';
import AddClientForm from '../components/forms/AddClientForm.js';
import Toast from '../components/Toast.js';
import ErrorToast from '../components/ErrorToast.js';
import LoadingToast from '../components/LoadingToast.js';
import './ClientPage.css';
import './Common.css';

/**
* AddClientPage is the page containing the form for adding new clients
*/
const AddClientPage = () => {
  const isSuperAdmin = useSelector(state => state.user.isSuperAdmin);
  const toastActive = useSelector(state => state.componentStates.toastActive);
  const errorToastActive = useSelector(state => state.componentStates.errorToastActive);
  const loadingToastActive = useSelector(state => state.componentStates.loadingToastActive);
  return (
    <div className="pageContainer">
      <Menu/>
      <div className="pageContentWrapper">
        {isSuperAdmin ?
          <div>
            <div className="pageTitle">ADD CLIENT</div>
            <AddClientForm/>
          </div>
          : <Redirect to="/"/>
        }
      </div>
      {toastActive &&
        <Toast
          text="Client created"
          timeToClose={5000}
        />
      }
      {errorToastActive &&
        <ErrorToast
          text="Couldn't add client, please try again"
          timeToClose={10000}
        />
      }
      {loadingToastActive &&
        <LoadingToast
          text="Adding client"
        />
      }
    </div>
  );
};

export default AddClientPage;
