import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import * as actions from '../../actions';
import { renderNumberField, renderDropzone } from './commonFormComponents';
import {
  required,
  hasMaxClientNameLength,
  hasNumberInRange,
  composeValidators
} from '../../utils/validationUtils';
import Const from '../../store/Const';
import './AddClientForm.css';
import '../Common.css';



/**
* AddClientForm handles adding new clients
*/
const AddClientForm = () => {
  const dispatch = useDispatch();
  const clientNameRef = useRef('clientNameRef');
  const submitButtonDisabled = useSelector(state => state.componentStates.submitButtonDisabled);
  const clientTypes = Object.keys(Const.internalUseClientTypes);
  const clientTypeOptions = clientTypes.map(clientType => {
    return { name: clientType, id: Const.clientTypes[clientType] };
  });

  const handleSubmit = (data) => {
    dispatch(actions.createClient.request(
      data.clientName,
      data.clientLogo,
      data.maxCourses,
      data.maxUsers,
      data.maxAdmins,
      data.clientType
    ));
    dispatch(actions.setToastOrigin.request('/addclient'));
  };

  return (
    <Form
      initialValues={{
        clientType: "placeholder"
      }}
      onSubmit={handleSubmit}
      render = {({ handleSubmit, values, errors }) => {
        return (
          <form className="addClientForm" autoComplete="off" onSubmit={handleSubmit}>
            <div className="subtitle addClientSubtitleBiggerTopMargin">Client</div>
            <Field
              name="clientName"
              validate={composeValidators(required, hasMaxClientNameLength)}
            >
              {({ input, meta }) => (
                <div className="addClientInputAndErrorContainer">
                  <div className="addClientNameInputAndCounter">
                    <input
                      {...input}
                      type="text"
                      placeholder="Moblrn"
                      className="addClientNameInputField"
                      minLength= "1"
                      maxLength= "30"
                      ref={clientNameRef}
                      required
                    />
                    <div className="addClientNameTextCounter">{calculateNameLength(clientNameRef) + '/30'}</div>
                  </div>
                  {meta.error && meta.touched && <div className="addClientErrorMessage">{meta.error}</div>}
                </div>
              )}
            </Field>
            <div className="subtitle">Client logo</div>
            <div className="helpText">Upload the clients logo here</div>
            <Field
              name="clientLogo"
              dropzonetype="rectangle"
              component={renderDropzone}
            />
            <div className="subtitle">Programs</div>
            <div className="helpText">How many programs should the client be able to create?</div>
            <div className="addClientSmallInputFieldWrapper">
              <Field
                name="maxCourses"
                className="addClientSmallInputField"
                validate={composeValidators(required, hasNumberInRange(1, 999))}
                type="number"
                defaultText="e.g. 100"
                component={renderNumberField}
              />
              <div className="addClientText">programs</div>
            </div>
            <div className="subtitle">Unique users</div>
            <div className="helpText">How many unique users should the client be able to invite?</div>
            <div className="addClientSmallInputFieldWrapper">
              <Field
                name="maxUsers"
                className="addClientSmallInputField"
                validate={composeValidators(required, hasNumberInRange(1, 99999))}
                type="number"
                defaultText="e.g. 100"
                component={renderNumberField}
              />
              <div className="addClientText">users</div>
            </div>
            <div className="subtitle">Admins</div>
            <div className="helpText">How many admins should the client be able to have?</div>
            <div className="addClientSmallInputFieldWrapper">
              <Field
                name="maxAdmins"
                className="addClientSmallInputField"
                validate={composeValidators(required, hasNumberInRange(1, 999))}
                type="number"
                defaultText="e.g. 100"
                component={renderNumberField}
              />
              <div className="addClientText">admins</div>
            </div>
            <div className="subtitle">Client type</div>
            <div className="helpText">What kind of client is this?</div>
            <div className="addClientDropdownWrapper">
              <Field
                name="clientType"
                options={clientTypeOptions}
                placeholder="Choose type"
                component={renderDropdown}
              />
            </div>
            <div className="addClientFormButtonContainer">
              <Link to="/clients">
                <button
                  className="button transparentButton"
                >
                  CANCEL
                </button>
              </Link>
              <button
                className="button purpleButton"
                onSubmit={handleSubmit}
                disabled={!allRequiredFieldsFilled(values, errors) || submitButtonDisabled}
              >
                CREATE CLIENT
              </button>
            </div>
          </form>
        );}
      }
    />
  );
};

const calculateNameLength = (clientNameRef) => {
  if (!clientNameRef.current.value) {
    return 0;
  } else {
    return clientNameRef.current.value.length;
  }
};

const renderDropdown = ({ input, ...custom }) => {
  return (
    <select
      {...input}
      className="addClientSelect"
    >
      <option value="placeholder" disabled hidden>{custom.placeholder}</option>
      {custom.options.map((client) => {
        return (
          <option key={client.id} value={client.id}>{client.name}</option>
        );
      })}
    </select>
  );
};

const allRequiredFieldsFilled = (values, errors) => {
  return (
    values &&
    values.clientName &&
    values.maxCourses &&
    values.maxUsers &&
    values.maxAdmins &&
    (values.clientType && values.clientType !== "placeholder") &&
    Object.keys(errors).length === 0
  );
};

export default AddClientForm;
