import React, { useState, useRef, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Const from '../../store/Const';
import * as actions from '../../actions';
import WarningModal from '../WarningModal';
import { initialPagesData, initialChaptersData, initialChapterPagesData, structurePagesData, structureChaptersData, structureChapterPagesData } from './LibraryDragAndDropData';
import { MenuTitleField, MenuItemTitleField } from './commonCourseCoreComponents';
import { useOutsideClick, usePrevious, onDragEnd, onDragUpdate } from '../../utils/componentUtils';
import three_dots from '../../images/svg/three_dots.svg';
import three_dots_black_bg from '../../images/svg/three_dots_black_bg.svg';
import plus_grey_thin from '../../images/svg/plus_grey_thin.svg';
import downward_arrow_smooth from '../../images/svg/downward_arrow_smooth.svg';
import pencil_icon_grey from '../../images/svg/pencil_icon_grey.svg';
import red_x_mark from '../../images/svg/red_x_mark.svg';
import white_x_mark from '../../images/svg/white_x_mark.svg';
import './Common.css';

/**
* CourseLibraryMenu is the menu showing the library pages and chapters
*/
const CourseLibraryMenu = () => {
  const dispatch = useDispatch();
  const courseId = useSelector(state => state.programs.currentProgram._id);
  const libraryPageName = useSelector(state => state.programs.currentProgram.modules.libraryModule.name) || '';
  const libraryContent = useSelector(state => state.programs.currentProgram.moduleContent.library);
  const previousLibraryContent = usePrevious(libraryContent);
  const moduleId = useSelector(state => state.programs.currentProgram.modules.libraryModule._id) || '';
  const selectedPageId = useSelector(state => state.programs.currentProgram.currentLibraryPageId) || '';
  const [openLibraryDropdownMenu, setOpenLibraryDropdownMenu] = useState(false);
  const [openLibraryMenuItemId, setOpenLibraryMenuItemId] = useState('');
  const [currentlyEditedMenuItemId, setCurrentlyEditedMenuItemId] = useState('');
  const [openDeleteItemModal, setOpenDeleteItemModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState({});
  const libraryMenuRef = useRef();
  const libraryPageMenuRef = useRef();
  const libraryTitleInputRef = useRef();
  useOutsideClick(libraryMenuRef, () => {
    setTimeout(() => setOpenLibraryDropdownMenu(false), 100);
  });
  useOutsideClick(libraryPageMenuRef, () => {
    setOpenLibraryMenuItemId('');
  });
  const handleEditTitleClick = () => {
    libraryTitleInputRef.current.focus();
    setOpenLibraryDropdownMenu(false);
  };
  const handleAddPageClick = () => {
    dispatch(actions.addComponent.request(Const.moduleItemType.ContentPage, null, moduleId, Const.moduleType.Library, { text: "" }, courseId));
    setOpenLibraryDropdownMenu(false);
  };
  const handleAddChapterClick = () => {
    dispatch(actions.addComponent.request(Const.moduleItemType.Chapter, null, moduleId, Const.moduleType.Library, { text: "" }));
    setOpenLibraryDropdownMenu(false);
  };
  const handleEditMenuItemTitleClick = (itemId) => {
    dispatch(actions.toggleEditMenuPageTitle.request(true));
    setCurrentlyEditedMenuItemId(itemId);
    setTimeout(() => setOpenLibraryMenuItemId(''), 0);                          //Why doesn't this work without the timeout?
  };
  const handleDeleteItemClick = (item) => {
    setItemToDelete(item);
    setOpenDeleteItemModal(true);
  };

  const [placeholderProps, setPlaceholderProps] = useState({});
  const [pagesData, setPagesData] = useState(initialPagesData);
  const [chaptersData, setChaptersData] = useState(initialChaptersData);
  const pagesArray = getPages(libraryContent);
  const chaptersArray = getChapters(libraryContent);

  useEffect(() => {
    structurePagesData(pagesArray, setPagesData);
    structureChaptersData(chaptersArray, setChaptersData);
    if (previousLibraryContent && libraryContent.length !== 1 && libraryContent.length === previousLibraryContent.length + 1) {
      setCurrentlyEditedMenuItemId(libraryContent[libraryContent.length - 1]._id);
    } else {
      if (previousLibraryContent && previousLibraryContent.length > 0) {
        libraryContent.forEach((item, index) => {
          if (previousLibraryContent[index] && item.type === Const.moduleItemType.Chapter && previousLibraryContent[index]._id === item._id) {
            if (item.children.length === previousLibraryContent[index].children.length + 1) {
              setCurrentlyEditedMenuItemId(item.children[item.children.length - 1]._id);
            }
          }
        });
      }
    }
  }, [libraryContent]);

  return (
    <div className="courseLibraryMenuContainer">
      <div className="courseModuleMenuSegment courseModuleMenuTitleSegment">
        <MenuTitleField
          titleText={libraryPageName ? libraryPageName : 'Library'}
          placeholder="Library"
          moduleId={moduleId}
          ref={libraryTitleInputRef}
        />
        <div className="courseModuleMenuTitleIconWrapper">
          <div className="courseModuleMenuTitleIcons" onClick={() => setOpenLibraryDropdownMenu(true)}>
            <img className="courseModuleMenuTitleIcon courseModuleMenuRotateIcon" src={three_dots}/>
            <img className="courseModuleOnHoverMenuTitleIcon" src={three_dots_black_bg}/>
          </div>
          <span className="courseModuleMenuTooltip">Library settings</span>
        </div>
        {
          openLibraryDropdownMenu &&
          renderLibraryMenu(libraryMenuRef, handleEditTitleClick, handleAddPageClick, handleAddChapterClick)
        }
      </div>
      {getChapters(libraryContent).length > 0 &&
        <div className="libraryMenuChaptersSection">
          {chaptersData.columnOrder.map(columnId => {
            const column = chaptersData.columns[columnId];
            const components = column.componentIds.map((componentId) => chaptersData.components[componentId]);
            return (
              <DragDropContext
                onDragEnd={(result) => onDragEnd(result, chaptersData, setPlaceholderProps, setChaptersData, moduleId, Const.moduleType.Library, dispatch)}
                onDragUpdate={(update) => onDragUpdate(update, setPlaceholderProps)}
                key={columnId}
              >
                <Droppable droppableId={column.id} key={column.id}>
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {components.map((component, index) => {
                        return (
                          <Draggable draggableId={component.id} index={index} key={component.id}>
                            {(provided, snapshot) => (
                              <div
                                key={component.id}
                                {...provided.draggableProps}
                                ref={provided.innerRef}
                              >
                                <div className={snapshot.isDragging ? "libraryMenuPageDragging" : ""}>
                                  <MenuChapter
                                    key={component.content._id}
                                    courseId={courseId}
                                    chapter={component.content}
                                    selectedPageId={selectedPageId}
                                    moduleId={moduleId}
                                    libraryPageMenuRef={libraryPageMenuRef}
                                    handleEditMenuItemTitleClick={handleEditMenuItemTitleClick}
                                    handleDeleteItemClick={handleDeleteItemClick}
                                    openLibraryMenuItemId={openLibraryMenuItemId}
                                    setOpenLibraryMenuItemId={setOpenLibraryMenuItemId}
                                    newChapter={component.content.newComponent}
                                    provided={provided}
                                    index={index}
                                    currentlyEditedMenuItemId={currentlyEditedMenuItemId}
                                    setCurrentlyEditedMenuItemId={setCurrentlyEditedMenuItemId}
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      <div>{provided.placeholder}</div>
                      {Object.keys(placeholderProps).length > 0 && snapshot.isDraggingOver && (
                        <div
                          className="libraryMenuChapterCustomPlaceholder"
                          style={{
                            top: placeholderProps.clientY,
                            left: placeholderProps.clientX,
                            height: placeholderProps.clientHeight - 12,
                            width: placeholderProps.clientWidth
                          }}
                        />
                      )}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            );
          })}
        </div>
      }
      <div
        className="courseModuleAddChapter"
        onClick={() => dispatch(actions.addComponent.request(Const.moduleItemType.Chapter, null, moduleId, Const.moduleType.Library))}
      >
        <div className="courseModuleAddItem courseModuleAddItemText">
          + Add chapter
        </div>
      </div>
      <div className={pagesData.columns['pages-column'].componentIds.length > 0 ? "courseModulePageSegment" : "courseModulePageSegmentEmpty"}>
        {pagesData.columnOrder.map(columnId => {
          const column = pagesData.columns[columnId];
          const components = column.componentIds.map((componentId) => pagesData.components[componentId]);
          return (
            <DragDropContext
              onDragEnd={(result) => onDragEnd(result, pagesData, setPlaceholderProps, setPagesData, moduleId, Const.moduleType.Library, dispatch)}
              onDragUpdate={(update) => onDragUpdate(update, setPlaceholderProps)}
              key={columnId}
            >
              <Droppable droppableId={column.id} key={column.id}>
                {(provided, snapshot) => (
                  <div
                    className="libraryMenuIndividualPages"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {components.map((component, index) => {
                      return (
                        <Draggable draggableId={component.id} index={index} key={component.id}>
                          {(provided, snapshot) => (
                            <div
                              key={component.id}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              <div className={snapshot.isDragging ? "libraryMenuPageDragging" : ""}>
                                {renderMenuPage(
                                  component.content,
                                  index,
                                  openLibraryMenuItemId,
                                  setOpenLibraryMenuItemId,
                                  selectedPageId,
                                  libraryPageMenuRef,
                                  handleEditMenuItemTitleClick,
                                  handleDeleteItemClick,
                                  currentlyEditedMenuItemId,
                                  setCurrentlyEditedMenuItemId,
                                  moduleId
                                )}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    <div>{provided.placeholder}</div>
                    {Object.keys(placeholderProps).length > 0 && snapshot.isDraggingOver && (
                      <div
                        className="libraryMenuPageCustomPlaceholder"
                        style={{
                          top: placeholderProps.clientY,
                          left: placeholderProps.clientX,
                          height: placeholderProps.clientHeight - 12,
                          width: placeholderProps.clientWidth
                        }}
                      />
                    )}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          );
        })}
      </div>
      <div
        className="courseModuleAddItem courseModuleAddPage"
        onClick={() => dispatch(actions.addComponent.request(Const.moduleItemType.ContentPage, null, moduleId, Const.moduleType.Library, { text: ""}, courseId))}
      >
        <div className="courseModuleAddItemText">
          + Add page
        </div>
      </div>
      <WarningModal
        open={openDeleteItemModal}
        onClose={() => setOpenDeleteItemModal(false)}
        title={itemToDelete.type === Const.moduleItemType.ContentPage ? 'Delete page' : 'Delete chapter'}
        text1={itemToDelete.type === Const.moduleItemType.ContentPage ?
          `Are you sure you want to delete the page "${itemToDelete.name || "New page"}"?`
          :
          `Are you sure you want to delete the chapter "${itemToDelete.name || "New chapter"}"?`
        }
        text2={itemToDelete.type === Const.moduleItemType.ContentPage ?
          `The page "${itemToDelete.name || "New page"}" will be deleted and CAN NOT be recovered.`
          :
          `The chapter "${itemToDelete.name || "New chapter"}" will be deleted and CAN NOT be recovered.`
        }
        leftButtonText='NO, CANCEL'
        rightButtonText='YES, DELETE'
        onLeftButtonClick={() => handleCloseDeleteItemModal(setOpenDeleteItemModal, setItemToDelete)}
        onRightButtonClick={() => handleDeleteItem(setOpenDeleteItemModal, itemToDelete, setItemToDelete, dispatch)}
      />
    </div>
  );
};

const getChapters = (libraryContent) => {
  return libraryContent.filter(item => {
    return item.type === Const.moduleItemType.Chapter;
  });
};

const getPages = (libraryContent) => {
  return libraryContent.filter(item => {
    return item.type === Const.moduleItemType.ContentPage;
  });
};

const renderMenuPage = (
  page,
  index,
  openLibraryMenuItemId,
  setOpenLibraryMenuItemId,
  selectedPageId,
  libraryPageMenuRef,
  handleEditMenuItemTitleClick,
  handleDeleteItemClick,
  currentlyEditedMenuItemId,
  setCurrentlyEditedMenuItemId,
  moduleId
) => {
  const { courseId } = useParams();
  return (
    <div
      className={page._id === selectedPageId ? "courseModuleMenuPageItem courseModuleSelectedMenuPageItem" : "courseModuleMenuPageItem"}
      key={page._id}
    >
      <Link className="courseModuleLink" to={"/course/" + courseId + "/library/" + page._id}>
        <div className="courseModuleMenuPageItemText courseModuleMenuPageClickableText">
          {
            currentlyEditedMenuItemId === page._id ?
            <MenuItemTitleField
              item={page}
              placeholder={'New page'}
              moduleId={moduleId}
              moduleType={Const.moduleType.Library}
              setCurrentlyEditedMenuItemId={setCurrentlyEditedMenuItemId}
            />
            :
            <>{page.data.text || "New page"}</>
          }
        </div>
      </Link>
      <div className="courseModuleMenuPageItemIconWrapper" onClick={() => setOpenLibraryMenuItemId(page._id)}>
        {
          page._id === openLibraryMenuItemId ?
          <>
            <img src={three_dots_black_bg}/>
            {renderLibraryItemMenu(page, libraryPageMenuRef, handleEditMenuItemTitleClick, handleDeleteItemClick, moduleId)}
          </>
          :
          <>
            <img className="courseModuleMenuPageItemIcon courseModuleMenuRotateIcon" src={three_dots}/>
            <img className="courseModuleMenuPageItemOnHoverIcon" src={three_dots_black_bg}/>
          </>
        }
      </div>
    </div>
  );
};

const MenuChapter = ({
  courseId,
  chapter,
  selectedPageId,
  moduleId,
  libraryPageMenuRef,
  handleEditMenuItemTitleClick,
  handleDeleteItemClick,
  openLibraryMenuItemId,
  setOpenLibraryMenuItemId,
  currentlyEditedMenuItemId,
  setCurrentlyEditedMenuItemId,
  newChapter,
  provided
}) => {
  const dispatch = useDispatch();
  const [chapterExpanded, setChapterExpanded] = useState(newChapter);
  const [placeholderProps, setPlaceholderProps] = useState({});
  const [chapterPagesData, setChapterPagesData] = useState(initialChapterPagesData);
  const chapterPagesArray = chapter.children;
  useEffect(() => {
    structureChapterPagesData(chapterPagesArray, setChapterPagesData);
  }, [chapter]);
  return (
    <div className="courseModuleChapterSegment">
      <div className="courseModuleMenuPageChapter" {...provided.dragHandleProps}>
        <img
          className={chapterExpanded ? "courseModuleExpandChapterIcon" : "courseModuleExpandChapterIcon courseModuleMenuInvertIcon"}
          src={downward_arrow_smooth}
          onClick={() => setChapterExpanded(!chapterExpanded)}
        />
        {
          currentlyEditedMenuItemId === chapter._id ?
          <MenuItemTitleField
            item={chapter}
            placeholder={'New chapter'}
            moduleId={moduleId}
            moduleType={Const.moduleType.Library}
            setCurrentlyEditedMenuItemId={setCurrentlyEditedMenuItemId}
          />
          :
          <div className="courseModuleMenuPageItemText">{chapter.data.text || "New chapter"}</div>
        }
        <div className="courseModuleMenuPageItemIconWrapper courseModuleMenuChapterItem" onClick={() => setOpenLibraryMenuItemId(chapter._id)}>
          {
            chapter._id === openLibraryMenuItemId ?
            <>
              <img src={three_dots_black_bg}/>
              {renderLibraryItemMenu(chapter, libraryPageMenuRef, handleEditMenuItemTitleClick, handleDeleteItemClick, moduleId)}
            </>
            :
            <>
              <img className="courseModuleMenuPageItemIcon courseModuleMenuRotateIcon" src={three_dots}/>
              <img className="courseModuleMenuPageItemOnHoverIcon" src={three_dots_black_bg}/>
            </>
          }
        </div>
      </div>
      {
        chapterExpanded &&
        <div>
          {chapterPagesData.columnOrder.map(columnId => {
            const column = chapterPagesData.columns[columnId];
            const components = column.componentIds.map((componentId) => chapterPagesData.components[componentId]);
            return (
              <DragDropContext
                onDragEnd={(result) => onDragEnd(result, chapterPagesData, setPlaceholderProps, setChapterPagesData, moduleId, Const.moduleType.Library, dispatch)}
                onDragUpdate={(update) => onDragUpdate(update, setPlaceholderProps)}
                key={columnId}
              >
                <Droppable droppableId={column.id} key={column.id}>
                  {(provided, snapshot) => (
                    <div
                      className="libraryMenuIndividualPages"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {components.map((component, index) => {
                        return (
                          <Draggable draggableId={component.id} index={index} key={component.id}>
                            {(provided, snapshot) => (
                              <div
                                key={component.id}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                              >
                                <div className={snapshot.isDragging ? "libraryMenuPageDragging" : ""}>
                                  {renderMenuPage(
                                    component.content,
                                    index,
                                    openLibraryMenuItemId,
                                    setOpenLibraryMenuItemId,
                                    selectedPageId,
                                    libraryPageMenuRef,
                                    handleEditMenuItemTitleClick,
                                    handleDeleteItemClick,
                                    currentlyEditedMenuItemId,
                                    setCurrentlyEditedMenuItemId,
                                    moduleId
                                  )}
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      <div>{provided.placeholder}</div>
                      {Object.keys(placeholderProps).length > 0 && snapshot.isDraggingOver && (
                        <div
                          className="libraryMenuPageCustomPlaceholder"
                          style={{
                            top: placeholderProps.clientY,
                            left: placeholderProps.clientX,
                            height: placeholderProps.clientHeight - 12,
                            width: placeholderProps.clientWidth
                          }}
                        />
                      )}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            );
          })}
          <div
            className="courseModuleAddItem"
            onClick={() => dispatch(actions.addComponent.request(Const.moduleItemType.ContentPage, chapter._id, moduleId, Const.moduleType.Library, { text: ""}, courseId))}
          >
            <div className="courseModuleAddItemText">
              + Add page
            </div>
          </div>
        </div>
      }
    </div>
  );
};

const renderLibraryMenu = (libraryMenuRef, handleEditTitleClick, handleAddPageClick, handleAddChapterClick) => {
  return (
    <div className="courseModuleDropdownMenu" ref={libraryMenuRef}>
      <div
        className="courseModuleDropdownMenuItem"
        onClick={() => handleEditTitleClick()}
      >
        Edit title
        <img className="courseModuleDropdownMenuItemIcon" src={pencil_icon_grey}/>
      </div>
      <div
        className="courseModuleDropdownMenuItem"
        onClick={() => handleAddChapterClick()}
      >
        Add chapter
        <img className="courseModuleDropdownMenuItemIcon" src={plus_grey_thin}/>
      </div>
      <div
        className="courseModuleDropdownMenuItem"
        onClick={() => handleAddPageClick()}
      >
        Add page
        <img className="courseModuleDropdownMenuItemIcon" src={plus_grey_thin}/>
      </div>
    </div>
  );
};

const renderLibraryItemMenu = (item, libraryPageMenuRef, handleEditMenuItemTitleClick, handleDeleteItemClick, moduleId) => {
  return (
    <div className="courseModulePageDropdownMenu" ref={libraryPageMenuRef}>
      <div
        className="courseModuleDropdownMenuItem"
        onClick={() => handleEditMenuItemTitleClick(item._id)}
      >
        Edit title
        <img className="courseModuleDropdownMenuItemIcon" src={pencil_icon_grey}/>
      </div>
      <div
        className="courseModuleDropdownMenuItem courseModuleDropdownDeleteMenuItem"
        onClick={() => handleDeleteItemClick({
          moduleItemId: item._id,
          name: item.data.text,
          parentId: item.parentId,
          moduleId,
          type: item.type
        })}
      >
        {item.type === Const.moduleItemType.ContentPage ? "Delete page" : "Delete chapter"}
        <img className="courseModuleDropdownMenuItemIcon" src={red_x_mark}/>
        <img className="courseModuleDropdownMenuItemOnHoverIcon" src={white_x_mark}/>
      </div>
    </div>
  );
};

const handleCloseDeleteItemModal = (setOpenDeleteItemModal, setItemToDelete) => {
  setOpenDeleteItemModal(false);
  setItemToDelete({});
};

const handleDeleteItem = (setOpenDeleteItemModal, itemToDelete, setItemToDelete, dispatch) => {
  setOpenDeleteItemModal(false);
  dispatch(actions.deleteComponent.request(itemToDelete.moduleId, itemToDelete.moduleItemId, Const.moduleType.Library, itemToDelete.parentId));
  setItemToDelete({});
};

export default CourseLibraryMenu;
