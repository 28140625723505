import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams  } from 'react-router-dom';
import * as actions from '../actions';
import Const from '../store/Const';
import CourseLeftMenu from '../components/CourseLeftMenu.js';
import CourseWelcomePageContent from '../components/courseCore/CourseWelcomePageContent.js';
import ImageUploadToast from '../components/courseComponents/ImageUploadToast';
import ImageUploadErrorToast from '../components/courseComponents/ImageUploadErrorToast';
import ImageUploadLoadingToast from '../components/courseComponents/ImageUploadLoadingToast';
import AutosaveIconsTrackerComponent from '../components/courseComponents/AutosaveIconsTrackerComponent';
import './CourseWelcomePage.css';

/**
* CourseWelcomePage is the component containing all the fields (within CourseWelcomePageContent)
* that make up the Course's Welcome page - "/course/:courseId/welcome"
*/

const CourseWelcomePage = () => {
  const dispatch = useDispatch();
  const { courseId } = useParams();
  useEffect(() => {
    dispatch(actions.getCourse.request(courseId));
    dispatch(actions.getModuleContent.request(courseId, Const.moduleType.Welcome));
  }, []);

  const imageUploadToastActive = useSelector(state => state.componentStates.imageUploadToastActive);
  const imageUploadErrorToastActive = useSelector(state => state.componentStates.imageUploadErrorToastActive);
  const imageUploadLoadingToastActive = useSelector(state => state.componentStates.imageUploadLoadingToastActive);

  return (
    <div className="courseWelcomeComponentsWrapper">
      <CourseLeftMenu/>
      <div className="courseWelcomeFormWrapper">
        <CourseWelcomePageContent/>
      </div>
      {imageUploadToastActive &&
        <ImageUploadToast
          text="Image uploaded"
          timeToClose={5000}
        />
      }
      {imageUploadErrorToastActive &&
        <ImageUploadErrorToast
          text="Couldn't upload image, please try again"
          timeToClose={10000}
        />
      }
      {imageUploadLoadingToastActive &&
        <ImageUploadLoadingToast
          text="Uploading image"
        />
      }
      <AutosaveIconsTrackerComponent/>
    </div>
  );
};

export default CourseWelcomePage;
