import React from 'react';
import Modal from '@material-ui/core/Modal';
import './WarningModal.css';



/**
* WarningModal is a reusable modal used for warning the user of an action
* they are about to do
*/
const WarningModal = (props) => {
  const { open, onClose, title, text1, text2, leftButtonText, rightButtonText, onLeftButtonClick, onRightButtonClick } = props;
  return (
    <Modal
      id="modal"
      className="warningModal"
      open={open}
      onClose={onClose}
    >
      <div className="warningModalBackground">
        <div className="warningModalExclamation">!</div>
        <div className="warningModalMainText">{title}</div>
        <div className="warningModalHelperText">
          {text1}
        </div>
        <div className="warningModalHelperText">
          {text2}
        </div>
        <div className="warningModalButtonsContainer">
          <button className="warningModalButton" onClick={onLeftButtonClick}>{leftButtonText}</button>
          <button className="warningModalYesButton" onClick={onRightButtonClick}>{rightButtonText}</button>
        </div>
      </div>
    </Modal>
  );
};


export default WarningModal;
