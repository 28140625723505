import React from 'react';
import './TextInputField.css';

/**
 * TextInputField: reusable text input field
 * Takes some props to enable validation (input and meta come from Final Form, if the input field
 * is used on a form build with Final Form)
 */
const TextInputField = ({
  label,
  input,
  meta,
  fieldRef,
  calculateTextLength,
  mustHaveCounter,
  type,
  placeholder,
  minLength,
  maxLength,
  required,
  prefilledValue,
  readOnly,
  disabled,
}) => {
  const finalFormError = meta ? meta.error && meta.touched : null;
  return (
    <>
      <div
        className={
          disabled
            ? 'textInputFieldLabel textInputFieldLabelDisabled'
            : 'textInputFieldLabel'
        }
      >
        {label}
      </div>
      <div
        className={
          disabled
            ? 'textInputFieldParentWrapper textInputFieldDisabled'
            : 'textInputFieldParentWrapper'
        }
      >
        <div className="textInputFieldInputAndCounter">
          {prefilledValue ? (
            <input
              {...input}
              type={type}
              placeholder={placeholder}
              value={prefilledValue}
              className={
                !finalFormError
                  ? 'textInputField'
                  : 'textInputField textInputFieldError'
              }
              minLength={minLength}
              maxLength={maxLength}
              ref={fieldRef}
              required={required}
              readOnly={readOnly}
            />
          ) : (
            <input
              {...input}
              type={type}
              placeholder={placeholder}
              className={
                !finalFormError
                  ? 'textInputField'
                  : 'textInputField textInputFieldError'
              }
              minLength={minLength}
              maxLength={maxLength}
              ref={fieldRef}
              required={required}
              readOnly={readOnly}
            />
          )}
          {mustHaveCounter && (
            <div className="textInputFieldCounter">
              {calculateTextLength(prefilledValue ? prefilledValue : fieldRef) +
                '/30'}
            </div>
          )}
        </div>
        {finalFormError && (
          <div className="textInputFieldErrorMessage">{meta.error}</div>
        )}
      </div>
    </>
  );
};

export default TextInputField;
