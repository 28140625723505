import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Const from '../../store/Const';
import { PageTitleField } from './commonCourseCoreComponents';
import { LessonsContentDropdown } from './LessonsContentDropdown';
import LessonsFeedbackComponent from '../courseComponents/LessonsFeedbackComponent';
import { renderCourseComponent, onDragEnd, onDragUpdate } from '../../utils/componentUtils';
import { getCurrentLesson } from '../../reducers/programs';
import './Common.css';

/**
* CourseLessonsContent contains all input fields that make up the Course's
* Lessons Page. Managed with a local state and auto-save functionality
*/

const CourseLessonsContent = () => {
  const dispatch = useDispatch();
  const currentLesson = useSelector(getCurrentLesson);
  const fetchedPageContent = currentLesson ? currentLesson.children : [];
  const feedbackCorrectData = fetchedPageContent.find(item => item.type === Const.moduleItemType.FeedbackCorrect);
  const feedbackWrongData = fetchedPageContent.find(item => item.type === Const.moduleItemType.FeedbackWrong);
  // We need to clean up the content so feedback and unused content components do not render an empty space
  const currentPageContent = fetchedPageContent.filter(item => (
    item.type !== Const.moduleItemType.FeedbackCorrect &&
    item.type !== Const.moduleItemType.FeedbackWrong &&
    item.type !== Const.moduleItemType.QuestionImageUpload
  ));
  const currentPageName = currentLesson && currentLesson.data ? currentLesson.data.text : 'New page';
  const moduleId = useSelector(state => state.programs.currentProgram.modules.lessonModule._id) || '';
  const [placeholderProps, setPlaceholderProps] = useState({});
  const pageTitleEditedInMenu = useSelector(state => state.componentStates.pageTitleEditedInMenu);
  
  const initialData = {
    components: {},
    columns: {
      "lessons-column": {
        id: "lessons-column",
        componentIds: []
      }
    },
    columnOrder: ["lessons-column"]
  };
  const [data, setData] = useState(initialData);

  useEffect(() => {
    const itemIds = currentPageContent.map(item => item._id);
    let lessonsContentObject = {};
    currentPageContent.forEach(item => {
      const component = {
        id: item._id,
        content: item
      };
      lessonsContentObject[item._id] = component;
    });

    const itemData = {
      components: lessonsContentObject,
      columns: {
        "lessons-column": {
          id: "lessons-column",
          componentIds: itemIds
        }
      },
      columnOrder: ["lessons-column"]
    };
    setData(itemData);
  }, [currentLesson]);

  const contentIncludesQuestion = () => {
    let questionPresent;
    currentPageContent.forEach(component => {
      if (
        component.type === Const.moduleItemType.QuestionOneChoice ||
        component.type === Const.moduleItemType.QuestionDropdown ||
        component.type === Const.moduleItemType.QuestionCheckboxes ||
        component.type === Const.moduleItemType.QuestionScale ||
        component.type === Const.moduleItemType.QuestionText ||
        component.type === Const.moduleItemType.QuestionParagraph
      ) {
        questionPresent= true; 
      }
    });
    return questionPresent;
  };

  const renderFeedbackComponent = () => {
    let feedback;
    fetchedPageContent.forEach(component => {
      if (component.type === Const.moduleItemType.FeedbackCorrect || component.type === Const.moduleItemType.FeedbackWrong) {
        feedback= true;
      }
    });
    return feedback;
  };

  return (
    <>
      {currentLesson &&
        <>
          {
            pageTitleEditedInMenu ?
            <div className="pageTitleInput">{currentPageName ? currentPageName : 'New page'}</div>
            :
            <PageTitleField
              titleText={currentPageName ? currentPageName : 'New page'}
              placeholder="New page"
              moduleId={moduleId}
              pageId={currentLesson._id}
              moduleType={Const.moduleType.Lessons}
              item={currentLesson}
            />
          }
          {currentPageContent.length !== 0 &&
            <>
              {data.columnOrder.map(columnId => {
                const column = data.columns[columnId];
                const components = column.componentIds.map((componentId) => data.components[componentId]);
                return (
                  <DragDropContext
                    onDragEnd={(result) => onDragEnd(result, data, setPlaceholderProps, setData, moduleId, Const.moduleType.Lessons, dispatch)}
                    onDragUpdate={(update) => onDragUpdate(update, setPlaceholderProps)}
                    key={columnId}
                  >
                    <Droppable droppableId={column.id} key={column.id}>
                      {(provided, snapshot) => (
                        <div
                          className="courseContentWrapper"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {components.map((component, index) => {
                            return (
                              <Draggable
                                draggableId={component.id}
                                index={index}
                                key={component.id}
                                isDragDisabled={currentPageContent.length === 1 ||
                                  component.content.type === Const.moduleItemType.FeedbackCorrect ||
                                  component.content.type === Const.moduleItemType.FeedbackWrong ||
                                  component.content.type === Const.moduleItemType.QuestionImageUpload
                                }
                              >
                                {(provided, snapshot) => (
                                  <div
                                    className="courseComponentWrapper"
                                    key={component.id}
                                    {...provided.draggableProps}
                                    ref={provided.innerRef}
                                  >
                                    <div className={snapshot.isDragging ? "courseComponentWrapperDragging" : ""}>
                                      {renderCourseComponent(component.content, currentPageContent.length, Const.moduleType.Lessons, provided, currentLesson._id)}
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                          <div>{provided.placeholder}</div>
                          {Object.keys(placeholderProps).length > 0 && snapshot.isDraggingOver && (
                            <div
                              className="dragItemCustomPlacholder"
                              style={{
                                top: placeholderProps.clientY,
                                left: placeholderProps.clientX,
                                height: placeholderProps.clientHeight - 12,
                                width: placeholderProps.clientWidth
                              }}
                            />
                          )}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                );
              })}
            </>
          }
          <LessonsContentDropdown
            moduleId={moduleId}
            parentId={currentLesson._id}
            moduleType={Const.moduleType.Lessons}
          />
          {contentIncludesQuestion() && renderFeedbackComponent() &&
            <div className="feedbackComponentWrapper">
              <LessonsFeedbackComponent
                feedbackCorrectData={feedbackCorrectData}
                feedbackWrongData={feedbackWrongData}
              />
            </div>
          }
        </>
      }
    </>
  );
};

export default CourseLessonsContent;
