import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../actions';
import { stableSort, getSorting } from '../../utils/statisticsUtils';
import { getDayMonthYearFromDate, getTimeFromDate } from '../../utils/dateUtils';
import { handleSortRequest, renderArrow } from '../../utils/componentUtils';
import WarningModal from '../../components/WarningModal';
import key_api_icon from '../../images/svg/key_api_icon.svg';
import delete_trash_can from '../../images/svg/delete_trash_can.svg';
import './ClientAPITable.css';

/**
* ClientAPITable is a table component that shows the API keys for a specific Client
*/
const ClientAPITable = () => {
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const clientAPIKeys = useSelector(state => state.clients.currentClientAPIKeys);
  const disableCreateAPIKeyButton = useSelector(state => state.componentStates.disableCreateAPIKey);
  const [openDeleteKeyModal, setOpenDeleteKeyModal] = useState(false);
  const [keyToDelete, setKeyToDelete] = useState(null);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('keyID');
  const [revealKey, setRevealKey] = useState('');

  useEffect(() => {
    dispatch(actions.getClientAPIKeys.request(clientId));
    return (
      dispatch(actions.getClientAPIKeys.success([]))
    );
  }, []);

  let rows = clientAPIKeys.map(key => {
    return createData(key.keyId, key.keyValue, key.createdAt, key.userName);
  });

  const handleCreateKeyForClient = () => {
    dispatch(actions.createAPIKeyForClient.request(clientId));
    dispatch(actions.setToastOrigin.request(`/clients/${clientId}/clientoverview`));
  };
  
  const disableCreateKeyButton = () => {
    if (clientAPIKeys.length === 5) {
      return true;
    } else if (disableCreateAPIKeyButton) {
      return true;
    }
  };

  return (
    <>
      <div className="clientOverviewAPITableWrapper">
        <div className="clientOverviewAPITableNameAndButtonWrapper">
          <div className="clientOverviewAPITableName">{`API (${clientAPIKeys.length}/5)`}</div>
          <div
            className={disableCreateKeyButton() ? "clientAPITableCreateKeyButton clientAPITableCreateKeyButtonDisabled" : "clientAPITableCreateKeyButton"}
            onClick={() => disableCreateKeyButton() ? null : handleCreateKeyForClient()}
          >
            <div>Generate key</div>
            <img className="clientAPITableCreateButtonIcon" src={key_api_icon}/>
          </div>
        </div>
        <table className="ClientAPITable">
          <tbody>
            <tr>
              <th>
                <div className="APIClientNameAndIconContainer APITableClickableTitle" onClick={() => handleSortRequest('keyID', order, setOrder, orderBy, setOrderBy)}>
                  <div className="APITableTitle">Key ID</div>
                  {renderArrow("keyID", order, orderBy)}
                </div>
              </th>
              <th>
                <div className="APITableTitleAndIconContainer">
                  <div className="APITableTitle">Key</div>
                </div>
              </th>
              <th>
                <div className="APITableTitleAndIconContainer APITableClickableTitle" onClick={() => handleSortRequest('createdDate', order, setOrder, orderBy, setOrderBy)}>
                  <div className="APITableTitle">Created</div>
                  {renderArrow("createdDate", order, orderBy)}
                </div>
              </th>
              <th>
                <div className="APITableTitleAndIconContainer APITableClickableTitle" onClick={() => handleSortRequest('creator', order, setOrder, orderBy, setOrderBy)}>
                  <div className="APITableTitle">Created by</div>
                  {renderArrow("creator", order, orderBy)}
                </div>
              </th>
              <th className="APITableTitle"></th>
            </tr>
            {stableSort(rows, getSorting(order, orderBy)).map((row, index) => (
              <tr className="APITableRow" key={index}>
                <td className="APITableText ClientAPIKeyIDValue">{row.keyID.substring(0,4)}</td>
                <td className="APITableText ClientAPIKeyValue">
                  {
                    revealKey === row.key ?
                    <div className="ClientAPIKeyValueVisible">{row.key}</div>
                    :
                    <div onClick={() => setRevealKey(row.key)}>Click to reveal</div>
                  }
                </td>
                <td className="APITableText ClientAPIDateValue">{getDayMonthYearFromDate(row.createdDate) + ' ' + getTimeFromDate(row.createdDate)}</td>
                <td className="APITableText ClientAPICreatorValue">{row.creator}</td>
                <td className="ClientAPITableMenuCell">
                  <div className="APITableMenuIconsContainer" onClick={() => handleOpenDeleteKeyModal(setOpenDeleteKeyModal, setKeyToDelete, row.keyID)}>
                    <img className="ClientAPITableMenuIcon" src={delete_trash_can} alt="hyperlink icon"/>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {clientAPIKeys.length === 0 && renderNoKeysComponent(handleCreateKeyForClient)}
      </div>
      <WarningModal
        open={openDeleteKeyModal}
        onClose={() => setOpenDeleteKeyModal(false)}
        title='Delete Key?'
        text1={`Are you sure that you want to delete ${keyToDelete && keyToDelete.substring(0,4)}?`}
        text2={`This key will be removed and CAN NOT be recovered.`}
        leftButtonText='NO, CANCEL'
        rightButtonText='YES, DELETE'
        onLeftButtonClick={() => handleCloseDeleteKeyModal(setOpenDeleteKeyModal, setKeyToDelete)}
        onRightButtonClick={() => handleDeleteKey(setOpenDeleteKeyModal, dispatch, keyToDelete, setKeyToDelete, clientId)}
      />
    </>
  );
};

const createData = (keyID, key, createdDate, creator) => {
  return { keyID, key, createdDate, creator };
};

const handleOpenDeleteKeyModal = (setOpenDeleteKeyModal, setKeyToDelete, keyID) => {
  setOpenDeleteKeyModal(true);
  setKeyToDelete(keyID);
};

const handleCloseDeleteKeyModal = (setOpenDeleteKeyModal, setKeyToDelete) => {
  setOpenDeleteKeyModal(false);
  setKeyToDelete(null);
};

const handleDeleteKey = (setOpenDeleteKeyModal, dispatch, keyToDelete, setKeyToDelete, clientId) => {
  setOpenDeleteKeyModal(false);
  dispatch(actions.deleteClientAPIKey.request(clientId, keyToDelete));
  setKeyToDelete(null);
};

const renderNoKeysComponent = (handleCreateKeyForClient) => {
  return (
    <div className="noResultsWrapper">
      <div className="noResultsText">No keys for this client...</div>
      <div className="noResultsSecondText">Create your first key below!</div>
      <div className="noResultsRedirectButton" onClick={() => handleCreateKeyForClient()}>GENERATE KEY</div>
    </div>
  );
};

export default ClientAPITable;
