import React from 'react';
import { useSelector } from 'react-redux';
import Menu from '../components/Menu.js';
import EditAdminForm from '../components/forms/EditAdminForm.js';
import Toast from '../components/Toast.js';
import ErrorToast from '../components/ErrorToast.js';
import LoadingToast from '../components/LoadingToast.js';
import './EditAdminPage.css';

/**
* EditAdminPage
*/
const EditAdminPage = () => {
  const toastActive = useSelector(state => state.componentStates.toastActive);
  const errorToastActive = useSelector(state => state.componentStates.errorToastActive);
  const loadingToastActive = useSelector(state => state.componentStates.loadingToastActive);
  return (
    <div className="editAdminPageContainer">
      <Menu/>
      <div className="editAdminFormWrapper">
        <EditAdminForm />
      </div>
      {toastActive &&
        <Toast
          text="Changes saved"
          timeToClose={5000}
        />
      }
      {errorToastActive &&
        <ErrorToast
          text="Couldn't save changes, please try again"
          timeToClose={10000}
        />
      }
      {loadingToastActive &&
        <LoadingToast
          text="Saving changes"
        />
      }
    </div>
  );
};

export default EditAdminPage;
