import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Const from '../../store/Const';
import Tooltip from '../Tooltip';
import * as actions from '../../actions';
import pushIcon from '../../images/svg/pushIcon.svg';
import './LessonsTimelineComponent.css';

/**
* LessonsTimelineComponent shows the timeline of the course next to the
* lessons menu
*/

const LessonsTimelineComponent = () => {
  const dispatch = useDispatch();
  const lessonDeprecatedModule = useSelector(state => state.programs.currentProgram.moduleContent.lessons);
  const chapters = lessonDeprecatedModule.children || [];
  const courseType = useSelector(state => state.programs.currentProgram.type) || 0;
  const courseStartDate = useSelector(state => state.programs.currentProgram.startDate) || 0;
  const openChapters = useSelector(state => state.componentStates.openChaptersInLessonsMenu) || {};
  const handleOpenPageSettingsModal = (pageId) => {
    dispatch(actions.setCurrentlyOpenPageSettingsId.request(pageId));
  };
  return (
    <div className="lessonsTimelineComponent">
      <div>
        <div className="lessonsTimelineComponentEmptyTopSquare"/>
        {chapters.map((chapter, chapterIndex) => {
          return (
            <div key={chapter._id} className="lessonsTimelineComponentChapterContainer">
              <div className="lessonsTimelineComponentChapterTime">
                {openChapters[chapter._id] || (courseType === Const.courseType.Dynamic ?
                  createChapterDurationString(chapter.children) :
                  createChapterDateRangeString(chapter.children, chapterIndex === 0, courseStartDate)
                )}
              </div>
              {openChapters[chapter._id] &&
                <>
                  {chapter.children.map((page, pageIndex) => {
                    const isFirstPage = chapterIndex === 0 && pageIndex === 0;
                    return showPageTime(page, courseType, isFirstPage, courseStartDate, handleOpenPageSettingsModal);
                  })}
                  <div className="lessonsTimelineComponentNewPageSquare"/>
                </>
              }
            </div>
          );
        })}
        <div className="lessonsTimelineComponentNewPageSquare"/>
      </div>
      <div className="lessonsTimelineComponentCourseDuration">
        {calculateCourseDuration(chapters, courseType, courseStartDate)}
      </div>
    </div>
  );
};

/**
* showPageTime
*/
const showPageTime = (page, courseType, isFirstPage, courseStartDate, handleOpenPageSettingsModal) => {
  if (courseType === Const.courseType.Dynamic) {
    if (isFirstPage) {
      if (!page.data.startOffsetMinutes) {
        return (
          <div
            key={page._id}
            className="lessonsTimelineComponentPageTime"
            onClick={() => handleOpenPageSettingsModal(page._id)}
          >
            Day 0
            {page.data.pushWhenNew && <img className="lessonsTimelineComponentPushIcon" src={pushIcon}/>}
          </div>
        );
      }
      else {
        return (
          <div
            key={page._id}
            className="lessonsTimelineComponentPageTime lessonsTimelineComponentFirstPageDelayed"
            onClick={() => handleOpenPageSettingsModal(page._id)}
          >
            {createPageDelayString(page.data.startOffsetMinutes/60)}
            {page.data.pushWhenNew && <img className="lessonsTimelineComponentPushIcon" src={pushIcon}/>}
            <Tooltip
              text="Delaying the first page will render this course empty for the user until the delay is completed, we recommend you to remove the delay for the first page."
              width="220"
            />
          </div>
        );
      }
    }
    else {
      return (
        <div
          key={page._id}
          className="lessonsTimelineComponentPageTime"
          onClick={() => handleOpenPageSettingsModal(page._id)}
        >
          {createPageDelayString(page.data.startOffsetMinutes/60)}
          {page.data.pushWhenNew && <img className="lessonsTimelineComponentPushIcon" src={pushIcon}/>}
        </div>
      );
    }
  }
  if (courseType === Const.courseType.Static) {
    if (isFirstPage) {
      return (
        <div
          key={page._id}
          className="lessonsTimelineComponentPageTime"
          onClick={() => handleOpenPageSettingsModal(page._id)}
        >
          {createDateString(courseStartDate)}
          {page.data.pushWhenNew && <img className="lessonsTimelineComponentPushIcon" src={pushIcon}/>}
        </div>
      );
    }
    else {
      return (
        <div
          key={page._id}
          className="lessonsTimelineComponentPageTime"
          onClick={() => handleOpenPageSettingsModal(page._id)}
        >
          {createDateString(page.data.startStaticDate)}
          {page.data.pushWhenNew && <img className="lessonsTimelineComponentPushIcon" src={pushIcon}/>}
        </div>
      );
    }
  }
};

/**
* createPageDelayString creates a string with the format '1w 1d 1h' denoting
* the delay of the page release after the previous page
*/
const createPageDelayString = (hoursDelayed) => {
  if (!hoursDelayed) {
    return '';
  }
  const weeks = Math.floor(hoursDelayed/168);
  const days = Math.floor((hoursDelayed - weeks*168)/24);
  const hours = hoursDelayed - weeks*168 - days*24;
  let delayString = `+${weeks ? ' ' + weeks + 'w' : ''}${days ? ' ' + days + 'd' : ''}${hours ? ' ' + hours + 'h' : ''}`;
  if (delayString.length >= 9) {
    delayString = delayString.replaceAll(' ', '');
  }
  return delayString;
};

/**
* createChapterDurationString creates a string with the format '1w 1d 1h'
* denoting the duration of a chapter
*/
const createChapterDurationString = (pages) => {
  let chapterDuration =  0;
  pages.forEach(page => {
    chapterDuration += page.data && page.data.startOffsetMinutes ? page.data.startOffsetMinutes/60 : 0;
  });
  const weeks = Math.floor(chapterDuration/168);
  const days = Math.floor((chapterDuration - weeks*168)/24);
  const hours = chapterDuration - weeks*168 - days*24;
  let chapterDurationString = `${weeks ? weeks + 'w' : ''}${days ? ' ' + days + 'd' : ''}${hours ? ' ' + hours + 'h' : ''}`;
  if (chapterDurationString.length >= 9) {
    chapterDurationString = chapterDurationString.replaceAll(' ', '');
  }
  return chapterDurationString;
};

/**
* createDateString creates a string on the form 'Jan 1'
*/
const createDateString = (date) => {
  if (!date) {
    return '';
  }
  const dateObject = new Date(date);
  return dateObject.getDate() + ' ' + Const.shortMonths[dateObject.getMonth()];
};

/**
* createChapterDateRangeString creates a string on the form 'Jan 1 - Dec 31'
* denoting the date span of the chapter for Static courses
*/
const createChapterDateRangeString = (pages, isFirstChapter, courseStartDate) => {
  if (pages.length === 0) {
    return '';
  }
  const startDate = isFirstChapter ? new Date(courseStartDate) : new Date(pages[0].data.startStaticDate);
  if (pages.length === 1) {
    return createDateString(startDate);
  }
  const endDate = new Date(pages[pages.length - 1].data.startStaticDate);
  if (
    startDate.getFullYear() === endDate.getFullYear() &&
    startDate.getMonth() === endDate.getMonth() &&
    startDate.getDate() === endDate.getDate()
  ) {
    return createDateString(startDate);
  }
  return createDateString(startDate) + ' - \n' + createDateString(endDate);
};

/**
* calculateCourseDuration calculates the duration of the course
*/
const calculateCourseDuration = (chapters, courseType, courseStartDate) => {
  let allPages = [];
  chapters.forEach(chapter => {
    allPages = allPages.concat(chapter.children);
  });
  if (allPages.length < 2) {
    return '0h';
  }
  let courseDurationInHours = 0;
  if (courseType === Const.courseType.Static) {
    let courseDuration = new Date(allPages[allPages.length - 1].data.startStaticDate).valueOf() - new Date(courseStartDate).valueOf();
    courseDurationInHours = Math.floor(courseDuration/3600000);
  }
  else if (courseType === Const.courseType.Dynamic) {
    allPages.forEach(page => {
      courseDurationInHours += page.data && page.data.startOffsetMinutes ? page.data.startOffsetMinutes/60 : 0;
    });
  }
  const weeks = Math.floor(courseDurationInHours/168);
  const days = Math.floor((courseDurationInHours - weeks*168)/24);
  const hours = courseDurationInHours - weeks*168 - days*24;
  let courseDurationString = `${weeks ? weeks + 'w' : ''}${days ? ' ' + days + 'd' : ''}${hours ? ' ' + hours + 'h' : ''}`;
  if (courseDurationString.length >= 9) {
    courseDurationString = courseDurationString.replaceAll(' ', '');
  }
  return courseDurationString;
};

export default LessonsTimelineComponent;
