import React, { useState } from 'react';
import { findEarliestViewDate, findLatestActiveDate } from '../../utils/componentUtils';
import { getDayMonthYearFromDate, getTimeFromDate } from '../../utils/dateUtils';
import UserOverviewPageComponent from './UserOverviewPageComponent';
import downward_arrow_smooth from '../../images/svg/downward_arrow_smooth.svg';
import light_green_checkmark from '../../images/svg/light_green_checkmark.svg';
import './UserOverviewChapterComponent.css';


/**
* UserOverviewChapterComponent
*/

const UserOverviewChapterComponent = ({ chapter, answers, scores }) => {
  const [openPages, setOpenPages] = useState(false);
  const startDate = findEarliestViewDate(answers);
  const lastActiveDate = findLatestActiveDate(answers);
  const isChapterFinished = scores.totalInteractions === scores.completedInteractions && scores.totalInteractions !== 0 && scores.completedInteractions !== 0;

  return (
    <>
      <div className={isChapterFinished ? "overviewChapterWrapper overviewChapterWrapperFinished" : "overviewChapterWrapper"}>
        <div className="overviewChapterNameAndMenuWrapper" onClick={() => setOpenPages(!openPages)}>
          <div className="overviewChapterName">{chapter.data.text || "New chapter"}</div>
          {chapter.children.length > 0 &&
            <img
              className={openPages ? "overviewChapterArrow overviewChapterInvertArrow" : "overviewChapterArrow"}
              src={downward_arrow_smooth}
            />
          }
        </div>
        {startDate && renderActivityDate(scores, lastActiveDate, startDate)}
        <div className="overviewChapterSliderLabel">
          <div>Pages completed</div>
          <div className="overviewChapterProgressSliderValue">{scores.totalInteractions}</div>
        </div>
        {renderProgressComponent(
          scores.totalInteractions,
          scores.completedInteractions,
          isChapterFinished
        )}
        {
          scores.totalChapterScore > 0 &&
          <>
            <div className="overviewChapterSliderLabel">
              <div>Points</div>
              <div className="overviewChapterProgressSliderValue">{scores.totalChapterScore}</div>
            </div>
            {renderProgressComponent(scores.totalChapterScore, scores.chapterStudentScore, isChapterFinished)}
          </>
        }
      </div>
      {openPages &&
        <>
          {chapter.children.map((page, index) => {
            return (
              <UserOverviewPageComponent
                key={page._id}
                page={page}
                totalScore={scores.totalScoreByInteractionWithDefaultScore[index] || 0}
                studentScore={scores.earnedScoreByInteraction[index] || 0}
                answers={answers.find(answer => {
                  return answer.moduleItemId._id === page._id;
                })}
              />
            );
          })}
        </>
      }
    </>
  );
};

const renderProgressComponent = (max, value, isChapterFinished) => {
  return (
    <div className="overviewChapterProgressComponent">
      <div className="overviewChapterProgressComponentTrack" />
      <div
        className={isChapterFinished ? "overviewChapterProgressComponentProgress overviewChapterProgressComponentFinished" : "overviewChapterProgressComponentProgress"}
        style={{width: calculateProgressWidth(max, value)}}
      >
        <div className="overviewChapterProgressComponentValue">{value ? value : 0}</div>
      </div>
    </div>
  );
};

const calculateProgressWidth = (max, value) => {
  if (!value || value === 0) {
    return 20;
  }
  const width = (362 * value) / max;
  if (width < 25) {
    return 30;
  }
  return width;
};

/**
* renderActivityDate shows the date the last answer was made if the user is
* finished or the first activity if the user is not yet done with the chapter
*/
const renderActivityDate = (currentUserScores, lastActiveDate, startDate) => {
  if (currentUserScores.totalInteractions === currentUserScores.completedInteractions) {
    return (
      <div className="overviewChapterDate overviewChapterFinishedDate">
        <img className="overviewPageCardDateIcon" src={light_green_checkmark}/>
        <div>{'Finished ' + getDayMonthYearFromDate(lastActiveDate) + ' ' + getTimeFromDate(lastActiveDate)}</div>
      </div>
    );
  }
  return (
    <div className="overviewChapterDate overviewPageCardStartedDate">
      {'Started ' + getDayMonthYearFromDate(startDate) + ' ' + getTimeFromDate(startDate)}
    </div>
  );
};

export default UserOverviewChapterComponent;
