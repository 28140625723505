import { useSelector } from 'react-redux';
import { useUpdateUserStatus } from './use-update-user-status';
import React from 'react';
import UpdateUserStatusCacheButton from './UpdateUserStatusCacheButton';
import UpdateUserStatusCacheDoneStage from './UpdateUserStatusCacheDoneStage';
import UpdateUserStatusCacheFailedStage from './UpdateUserStatusCacheFailedStage';
import UpdateUserStatusCacheInProgressStage from './UpdateUserStatusCacheInProgressStage';
import UpdateUserStatusCacheScheduledStage from './UpdateUserStatusCacheScheduledStage';

/**
 * UpdateUserStatus
 */
const UpdateUserStatus = ({ programId }) => {
  const isSuperAdmin = useSelector((state) => state.user.isSuperAdmin);
  const {
    currentPage,
    enabled,
    requestUpdate,
    status,
    timestamp,
    totalStudents,
    usersPerPage,
  } = useUpdateUserStatus(programId);

  if (status === 'scheduled') {
    const timeLeftMs = timestamp - Date.now();
    const cachedStudents = currentPage * usersPerPage;

    return timeLeftMs <= 0 ? (
      <UpdateUserStatusCacheInProgressStage
        total={totalStudents}
        current={cachedStudents}
        title="UPDATING"
        progressLabel="users done"
      />
    ) : (
      <UpdateUserStatusCacheScheduledStage
        onClick={requestUpdate}
        timestamp={timestamp}
      />
    );
  }
  if (status === 'in progress') {
    return (
      <UpdateUserStatusCacheInProgressStage
        totalStudents={totalStudents}
        currentPage={currentPage}
        usersPerPage={usersPerPage}
      />
    );
  }
  if (status === 'failed') {
    return <UpdateUserStatusCacheFailedStage />;
  }

  if (status === 'succeeded' && enabled) {
    return <UpdateUserStatusCacheDoneStage />;
  }

  if (isSuperAdmin) {
    return <UpdateUserStatusCacheButton onClick={requestUpdate} />;
  }

  return null;
};

export default UpdateUserStatus;
