import Const from '../store/Const';

/**
* getMonthAndDayFromDate creates a string on the format 'February 4th'
* from a Date object
*/
export const getMonthAndDayFromDate = (date) => {
  if (!date) {
    return "";
  }
  if (typeof date === 'string') {
    date = new Date(date);
  }
  const monthName = Const.months[date.getMonth()];
  const dayOfMonth = date.getDate();
  return monthName + ' ' + dayOfMonth + getDaySuffix(dayOfMonth);
};

const getDaySuffix = (day) => {
  const dayString = day.toString();
  const lastDigitOfDay = dayString.charAt(dayString.length - 1);
  switch (lastDigitOfDay) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

/**
* getYearMonthDayFromDate gives a string on the format yyyy-mm-dd from a
* Date object
*/
export const getYearMonthDayFromDate = (date) => {
  if (!date) {
    return "";
  }
  if (typeof date === 'string') {
    date = new Date(date);
  }
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  if (month < 10) {
    month = '0' + month;
  }
  let day = date.getDate();
  if (day < 10) {
    day = '0' + day;
  }
  return year + '-' + month + '-' + day;
};

/**
* getDayMonthYearFromDate gives a string on the format dd mm yyyy from a
* Date object. Example: 06 Apr 2021
*/
export const getDayMonthYearFromDate = (date) => {
  if (!date) {
    return "";
  }
  if (typeof date === 'string') {
    date = new Date(date);
  }
  let day = date.getDate();
  if (day < 10) {
    day = '0' + day;
  }
  let mm = date.getMonth();
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  const month = months[mm];
  const year = date.getFullYear();
  return day + ' ' + month + ' ' + year;
};

/**
* getTimeFromDate gets the time of the day from a date on the format hh:mm
*/
export const getTimeFromDate = (date) => {
  if (!date) {
    return "";
  }
  if (typeof date === 'string') {
    date = new Date(date);
  }
  let hour = date.getHours();
  if (hour < 10) {
    hour = '0' + hour;
  }
  let minute = date.getMinutes();
  if (minute < 10) {
    minute = '0' + minute;
  }
  return hour + ':' + minute;
};
