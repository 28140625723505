import { create } from 'zustand';
import { DASHBOARD_GRAPHS_QUERY_KEY } from '../../program-dashboard/use-dashboard-graphs';
import { DASHBOARD_TABLE_QUERY_KEY } from '../../program-dashboard/use-dashboard-table-data';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import axios from 'axios';

const POLLING_PERIOD_SHORT = 750;
const POLLING_PERIOD_LONG = 2000;
const URL = `https://${process.env.REACT_APP_CACHE_URL_ID}.execute-api.eu-west-1.amazonaws.com/prod/programs`;

export const useUpdateUserStatusStore = create((set, get) => ({
  enabled: false,
  lastResponse: null,
  prevTimestamp: 0,
  programId: null,
  pollingPeriod: POLLING_PERIOD_LONG,
  staleResponse: false,
  invalidateOnNextSuccess: false,
  timer: 0,
  initPolling(programId) {
    if (get().programId === programId) return;

    set({
      enabled: false,
      lastResponse: null,
      phase: 'starting',
      pollingPeriod: POLLING_PERIOD_LONG,
      prevTimestamp: 0,
      programId,
    });
    get().startPolling();
  },
  async startPolling() {
    try {
      const response = await axios.get(URL, {
        params: { ids: get().programId },
      });
      set({ lastResponse: response.data[0] });

      if (get().lastResponse?.status !== 'succeeded') {
        set({ enabled: true });
      }
      if (get().prevTimestamp === 0) {
        set({ prevTimestamp: get().lastResponse?.timestamp });
      }
      if (
        get().staleResponse &&
        get().prevTimestamp !== get().lastResponse?.timestamp
      ) {
        set({
          invalidateOnNextSuccess: true,
          staleResponse: false,
        });
      }

      if (get().lastResponse?.status === 'succeeded' && !get().staleResponse) {
        set({ pollingPeriod: POLLING_PERIOD_LONG });
      }
    } catch (error) {
      console.error(error);
    } finally {
      if (get().programId) {
        const { startPolling, pollingPeriod } = get();
        set({ timer: setTimeout(startPolling, pollingPeriod) });
      }
    }
  },
  async requestUpdate() {
    try {
      clearTimeout(get().timer);
      set({
        enabled: true,
        pollingPeriod: POLLING_PERIOD_SHORT,
        prevTimestamp: get().lastResponse?.timestamp,
        staleResponse: true,
      });

      await axios.put(URL, { programId: get().programId, command: 'start' });
    } catch (error) {
      console.error(error);
      set({ pollingPeriod: POLLING_PERIOD_LONG });
    } finally {
      get().startPolling();
    }
  },
  stopPolling() {
    clearTimeout(get().timer);
    set({ programId: null });
  },
}));

export const useUpdateUserStatus = (programId) => {
  const store = useUpdateUserStatusStore();
  const queryClient = useQueryClient();

  const {
    enabled,
    lastResponse,
    pending,
    prevTimestamp,
    requestUpdate,
    staleResponse,
    invalidateOnNextSuccess,
  } = store;
  const status = staleResponse ? 'in progress' : lastResponse?.status;
  const totalStudents = staleResponse ? 0 : lastResponse?.totalStudents;

  useEffect(() => {
    store.initPolling(programId);
    return store.stopPolling;
  }, []);
  useEffect(() => {
    if (lastResponse?.status === 'succeeded' && invalidateOnNextSuccess) {
      queryClient.invalidateQueries(DASHBOARD_TABLE_QUERY_KEY);
      queryClient.invalidateQueries(DASHBOARD_GRAPHS_QUERY_KEY);
    }
  }, [invalidateOnNextSuccess, lastResponse?.status]);

  return {
    currentPage: lastResponse?.currentPage,
    enabled,
    pending,
    prevTimestamp,
    requestUpdate,
    status,
    timestamp: lastResponse?.timestamp,
    totalStudents,
    usersPerPage: lastResponse?.usersPerPage,
  };
};
