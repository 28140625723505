import React, { useEffect, useState } from 'react';
import toast_success_icon from '../../images/svg/toast_success_icon.svg';
import './AutosaveConnectedToast.css';

/**
* AutosaveConnectedToast: toast used on the course core to show the connection status
*/
const AutosaveConnectedToast = () => {
  const currentPath = window.location.pathname.split('/');
  const doubleMenuPage = currentPath[3] === "lessons" || currentPath[3] === "library";
  const [toastClassname, setToastClassname] = useState(
    doubleMenuPage ?
    "connectedToastDoubleMenu connectedToastHidden" :
    "connectedToast connectedToastHidden"
  );

  useEffect(() => {
    setToastClassname(
      doubleMenuPage ?
      "connectedToastDoubleMenu connectedToastVisible" :
      "connectedToast connectedToastVisible"
    );
  }, []);

  return (
    <div className={toastClassname}>
      <div className="connectedToastContent">
        <img className="connectedToastIcon" src={toast_success_icon} alt="success icon"/>
        <div>Connected</div>
      </div>
    </div>
  );
};

export default AutosaveConnectedToast;
