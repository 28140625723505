import React from 'react';
import SearchComponent from './SearchComponent';
import PaginationBar from './PaginationBar';
import ClientTable from './tables/ClientTable';
import './ClientList.css';

/**
* ClientList is the wrapper for the table with clients,
* the search for client component and the pagination
*/
const ClientList = () => {
  return (
    <div className="clientListContainer">
      <div className="clientListTitle">CLIENT LIST</div>
      <div className="clientListSearchContainer">
      <SearchComponent
        name='clientName'
        defaultText='Search client'
      />
      </div>
      <ClientTable/>
      <div className="clientListPaginationContainer">
        <PaginationBar/>
      </div>
    </div>
  );
};

export default ClientList;
