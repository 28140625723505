import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Const from '../../store/Const';
import { PageTitleField } from './commonCourseCoreComponents';
import { AddContentDropdown } from './AddContentDropdown';
import { renderCourseComponent, onDragEnd, onDragUpdate } from '../../utils/componentUtils';
import { getCurrentLibraryPage } from '../../reducers/programs';
import './Common.css';

/**
* CourseLibraryContent contains all input fields that make up the Course's
* Library Page. Managed with a local state and auto-save functionality
*/

const CourseLibraryContent = () => {
  const dispatch = useDispatch();
  const currentLibraryPage = useSelector(getCurrentLibraryPage);
  const currentPageContent = currentLibraryPage ? currentLibraryPage.children : [];
  const currentPageName = currentLibraryPage && currentLibraryPage.data ? currentLibraryPage.data.text : 'New page';
  const moduleId = useSelector(state => state.programs.currentProgram.modules.libraryModule._id) || '';
  const pageTitleEditedInMenu = useSelector(state => state.componentStates.pageTitleEditedInMenu);
  const [placeholderProps, setPlaceholderProps] = useState({});
  const initialData = {
    components: {},
    columns: {
      "library-column": {
        id: "library-column",
        componentIds: []
      }
    },
    columnOrder: ["library-column"]
  };
  const [data, setData] = useState(initialData);

  useEffect(() => {
    const itemIds = currentPageContent.map(item => item._id);
    let libraryContentObject = {};
    currentPageContent.forEach(item => {
      const component = {
        id: item._id,
        content: item
      };
      libraryContentObject[item._id] = component;
    });

    const itemData = {
      components: libraryContentObject,
      columns: {
        "library-column": {
          id: "library-column",
          componentIds: itemIds
        }
      },
      columnOrder: ["library-column"]
    };
    setData(itemData);
  }, [currentLibraryPage]);

  return (
    <>
      {currentLibraryPage &&
        <>
          {
            pageTitleEditedInMenu ?
            <div className="pageTitleInput">{currentPageName ? currentPageName : 'New page'}</div>
            :
            <PageTitleField
              titleText={currentPageName ? currentPageName : 'New page'}
              placeholder='New page'
              moduleId={moduleId}
              pageId={currentLibraryPage._id}
              moduleType={Const.moduleType.Library}
            />
          }
          {currentPageContent.length !== 0 &&
            <>
              {data.columnOrder.map(columnId => {
                const column = data.columns[columnId];
                const components = column.componentIds.map((componentId) => data.components[componentId]);
                return (
                  <DragDropContext
                    onDragEnd={(result) => onDragEnd(result, data, setPlaceholderProps, setData, moduleId, Const.moduleType.Library, dispatch)}
                    onDragUpdate={(update) => onDragUpdate(update, setPlaceholderProps)}
                    key={columnId}
                  >
                    <Droppable droppableId={column.id} key={column.id}>
                      {(provided, snapshot) => (
                        <div
                          className="courseContentWrapper"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {components.map((component, index) => {
                            return (
                              <Draggable
                                draggableId={component.id}
                                index={index}
                                key={component.id}
                                isDragDisabled={currentPageContent.length === 1}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    className="courseComponentWrapper"
                                    key={component.id}
                                    {...provided.draggableProps}
                                    ref={provided.innerRef}
                                  >
                                    <div className={snapshot.isDragging ? "courseComponentWrapperDragging" : ""}>
                                      {renderCourseComponent(component.content, currentPageContent.length, Const.moduleType.Library, provided, currentLibraryPage._id)}
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                          <div>{provided.placeholder}</div>
                          {Object.keys(placeholderProps).length > 0 && snapshot.isDraggingOver && (
                            <div
                              className="dragItemCustomPlacholder"
                              style={{
                                top: placeholderProps.clientY,
                                left: placeholderProps.clientX,
                                height: placeholderProps.clientHeight - 12,
                                width: placeholderProps.clientWidth
                              }}
                            />
                          )}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                );
              })}
            </>
          }
          <AddContentDropdown
            moduleId={moduleId}
            parentId={currentLibraryPage._id}
            moduleType={Const.moduleType.Library}
          />
        </>
      }
    </>
  );
};

export default CourseLibraryContent;
