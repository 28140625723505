import React from 'react';
import { Link } from 'react-router-dom';
import chevron_icon from '../../images/styleguideComponentsImages/chevron_icon.svg';
import './PageBreadCrumbs.css';

/**
 * PageBreadCrumbs: Reusable breadcrumbs component to use on the top of a page
 * Receives an array of objects which include the text for each crumb and where
 * it should link to
 */
const PageBreadCrumbs = ({ labelsArray }) => {
  return (
    <div className="breadcrumbsWrapper">
      {labelsArray.map((label, index) => {
        if (index === labelsArray.length - 1) {
          return (
            <div className="breadcrumbsLastText" key={label.text}>
              {label.text}
            </div>
          );
        } else {
          return (
            <Link
              to={label.link}
              className="breadcrumbsLinkText"
              key={label.text}
            >
              {label.text}
              <img src={chevron_icon} className="breadcrumbsChevronIcon" />
            </Link>
          );
        }
      })}
    </div>
  );
};

export default PageBreadCrumbs;
