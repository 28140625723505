import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../actions';
import Const from '../store/Const';
import CourseLeftMenu from '../components/CourseLeftMenu.js';
import CourseEditUserForm from '../components/forms/CourseEditUserForm.js';
import Toast from '../components/Toast.js';
import ErrorToast from '../components/ErrorToast.js';
import LoadingToast from '../components/LoadingToast.js';
import './CourseEditUserPage.css';

/**
* CourseEditUserPage
*/
const CourseEditUserPage = () => {
  const dispatch = useDispatch();
  const { courseId } = useParams();
  const toastActive = useSelector(state => state.componentStates.toastActive);
  const errorToastActive = useSelector(state => state.componentStates.errorToastActive);
  const loadingToastActive = useSelector(state => state.componentStates.loadingToastActive);

  useEffect(() => {
    dispatch(actions.getCourse.request(courseId));
    dispatch(actions.getModuleContent.request(courseId, Const.moduleType.Welcome));
  }, []);

  return (
    <div className="courseEditUserPageContainer">
      <CourseLeftMenu/>
      <div className="courseEditUserFormWrapper">
        <CourseEditUserForm/>
      </div>
      {toastActive &&
        <Toast
          text="Changes saved"
          timeToClose={5000}
        />
      }
      {errorToastActive &&
        <ErrorToast
          text="Couldn't save changes, please try again"
          timeToClose={10000}
        />
      }
      {loadingToastActive &&
        <LoadingToast
          text="Saving changes"
        />
      }
    </div>
  );
};

export default CourseEditUserPage;
