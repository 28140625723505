import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams  } from 'react-router-dom';
import * as actions from '../actions';
import Const from '../store/Const';
import CourseLeftMenu from '../components/CourseLeftMenu.js';
import CourseSignupPageForm from '../components/forms/CourseSignupPageForm.js';
import './CourseSignupPage.css';

/**
* CourseSignupPage - path="/course/:id/signup"
*/

const CourseSignupPage = () => {
  const dispatch = useDispatch();
  const { courseId } = useParams();
  const currentProgram = useSelector(state => state.programs.currentProgram);
  useEffect(() => {
    dispatch(actions.getCourse.request(courseId));
    dispatch(actions.getModuleContent.request(courseId, Const.moduleType.Welcome));
  }, []);

  useEffect(() => {
    if (currentProgram.clientId) {
      dispatch(actions.getClient.request(currentProgram.clientId));
    }
  }, [currentProgram.clientId]);

  return (
    <div className="courseSignupComponentsWrapper">
      <CourseLeftMenu/>
      <div className="courseSignupContentWrapper">
        <CourseSignupPageForm/>
      </div>
    </div>
  );
};

export default CourseSignupPage;
