import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Const from '../../store/Const';
import { ModuleTitleField } from './commonCourseCoreComponents';
import { ContentDropdownWithDisclaimer } from './ContentDropdownWithDisclaimer';
import { renderCourseComponent, onDragEnd, onDragUpdate } from '../../utils/componentUtils';
import './Common.css';

/**
* CourseWelcomePageContent contains all input fields that make up the Course's
* Welcome Page
*/

const CourseWelcomePageContent = () => {
  const dispatch = useDispatch();
  const welcomePageContent = useSelector(state => state.programs.currentProgram.moduleContent.welcome);
  const welcomePageName = useSelector(state => state.programs.currentProgram.modules.welcomeModule.name) || 'Disclaimer';
  const moduleId = useSelector(state => state.programs.currentProgram.modules.welcomeModule._id) || '';
  const [placeholderProps, setPlaceholderProps] = useState({});
  const initialData = {
    components: {},
    columns: {
      "welcome-column": {
        id: "welcome-column",
        componentIds: []
      }
    },
    columnOrder: ["welcome-column"]
  };
  const [data, setData] = useState(initialData);

  useEffect(() => {
    const itemIds = welcomePageContent.map(item => item._id);
    let welcomeContentObject = {};
    welcomePageContent.forEach(item => {
      const component = {
        id: item._id,
        content: item
      };
      welcomeContentObject[item._id] = component;
    });
    const itemData = {
      components: welcomeContentObject,
      columns: {
        "welcome-column": {
          id: "welcome-column",
          componentIds: itemIds
        }
      },
      columnOrder: ["welcome-column"]
    };
    setData(itemData);
  }, [welcomePageContent]);

  return (
    <>
      <ModuleTitleField
        titleText={welcomePageName}
        placeholder="Disclaimer"
        moduleId={moduleId}
      />
      {welcomePageContent.length !== 0 &&
        <>
          {data.columnOrder.map((columnId) => {
            const column = data.columns[columnId];
            const components = column.componentIds.map((componentId) => data.components[componentId]);
            return (
              <DragDropContext
                onDragEnd={(result) => onDragEnd(result, data, setPlaceholderProps, setData, moduleId, Const.moduleType.Welcome, dispatch)}
                onDragUpdate={(update) => onDragUpdate(update, setPlaceholderProps)}
                key={columnId}
              >
                <Droppable droppableId={column.id} key={column.id}>
                  {(provided, snapshot) => (
                    <div
                      className="courseContentWrapper"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {components.map((component, index) => {
                        return (
                          <Draggable
                            draggableId={component.id}
                            index={index}
                            key={component.id}
                            isDragDisabled={welcomePageContent.length === 1}
                          >
                            {(provided, snapshot) => (
                              <div
                                className="courseComponentWrapper"
                                key={component.id}
                                {...provided.draggableProps}
                                ref={provided.innerRef}
                              >
                                <div className={snapshot.isDragging ? "courseComponentWrapperDragging" : ""}>
                                  {renderCourseComponent(component.content, welcomePageContent.length, Const.moduleType.Welcome, provided, null)}
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      <div>{provided.placeholder}</div>
                      {Object.keys(placeholderProps).length > 0 && snapshot.isDraggingOver && (
                        <div
                          className="dragItemCustomPlacholder"
                          style={{
                            top: placeholderProps.clientY,
                            left: placeholderProps.clientX,
                            height: placeholderProps.clientHeight - 12,
                            width: placeholderProps.clientWidth
                          }}
                        />
                      )}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            );
          })}
        </>
      }
      <ContentDropdownWithDisclaimer moduleId={moduleId}/>
    </>
  );
};

export default CourseWelcomePageContent;
