import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import Const from '../../store/Const';
import * as actions from '../../actions';
import { useOutsideClick } from '../../utils/componentUtils';
import plus_white from '../../images/svg/plus_white.svg';
import headline_icon from '../../images/svg/headline_icon.svg';
import text_icon from '../../images/svg/text_icon.svg';
import image_icon from '../../images/svg/image_icon.svg';
import multiple_images_icon from '../../images/svg/multiple_images_icon.svg';
import video_icon from '../../images/svg/video_icon.svg';
import link_icon from '../../images/svg/link_icon.svg';
import one_choice_icon from '../../images/svg/one_choice_icon.svg';
import multi_choice_icon from '../../images/svg/multi_choice_icon.svg';
import slider_icon from '../../images/svg/slider_icon.svg';
import './LessonsContentDropdown.css';

/**
* LessonsContentDropdown: Reusable dropdown component that includes all the options of
* fields (content) and interactions (questions) that can be added to a Lesson module
*/

export const LessonsContentDropdown = ({ moduleId, parentId, moduleType }) => {
  const dispatch = useDispatch();
  const [openContentDropdownMenu, setOpenContentDropdownMenu] = useState(false);

  const contentDropdownRef = useRef();
  useOutsideClick(contentDropdownRef, () => {
    setTimeout(() => setOpenContentDropdownMenu(false), 100);
  });

  const handleAddComponentClick = (componentType) => {
    dispatch(actions.addComponent.request(componentType, parentId, moduleId, moduleType));
    setOpenContentDropdownMenu(false);
  };

  const addContentButtonRef = useRef();
  const { top, height } = addContentButtonRef.current ? addContentButtonRef.current.getBoundingClientRect() : { top: 0, height: 0 };
  const distanceToBottom = window.innerHeight - top - height;

  return (
    <div className="lessonsDropdownButtonParent">
      <div
        className={openContentDropdownMenu ? "lessonsDropdownButton lessonsDropdownButtonActive" : "lessonsDropdownButton"}
        ref={addContentButtonRef}
        onClick={() => setOpenContentDropdownMenu(!openContentDropdownMenu)}
      >
        <img className="lessonsDropdownButtonPlusIcon" src={plus_white}/>
        <div>ADD CONTENT</div>
      </div>
      {openContentDropdownMenu && renderDropdownMenu(contentDropdownRef, handleAddComponentClick, distanceToBottom)}
    </div>
  );
};

const renderDropdownMenu = (contentDropdownRef, handleAddComponentClick, distanceToBottom) => {
  return (
    <div
      className={distanceToBottom < 280 ? "lessonsDropdownMenuWrapperDropUp" : "lessonsDropdownMenuWrapper"}
      ref={contentDropdownRef}
    >
      <div>
        <div className="lessonsDropdownMenuTitle">CONTENT</div>
        <div className="lessonsDropdownMenuItem" onClick={() => handleAddComponentClick(Const.moduleItemType.Title)}>
          <div>Headline</div>
          <img className="lessonsDropdownMenuItemIcon" src={headline_icon}/>
        </div>
        <div className="lessonsDropdownMenuItem" onClick={() => handleAddComponentClick(Const.moduleItemType.Paragraph)}>
          <div>Text</div>
          <img className="lessonsDropdownMenuItemIcon" src={text_icon}/>
        </div>
        <div className="lessonsDropdownMenuItem" onClick={() => handleAddComponentClick(Const.moduleItemType.Image)}>
          <div>Image</div>
          <img className="lessonsDropdownMenuItemIcon" src={image_icon}/>
        </div>
        <div className="lessonsDropdownMenuItem" onClick={() => handleAddComponentClick(Const.moduleItemType.ImageCarousel)}>
          <div>Multiple Images</div>
          <img className="lessonsDropdownMenuItemIcon" src={multiple_images_icon}/>
        </div>
        <div className="lessonsDropdownMenuItem" onClick={() => handleAddComponentClick(Const.moduleItemType.Video)}>
          <div>Video link (URL)</div>
          <img className="lessonsDropdownMenuItemIcon" src={video_icon}/>
        </div>
        <div className="lessonsDropdownMenuItem" onClick={() => handleAddComponentClick(Const.moduleItemType.Link)}>
          <div>Link (URL)</div>
          <img className="lessonsDropdownMenuItemIcon" src={link_icon}/>
        </div>
      </div>
      <div className="lessonsDropdownMenuDivider"></div>
      <div>
        <div className="lessonsDropdownMenuTitle">INTERACTION</div>
          <div className="lessonsDropdownMenuItem" onClick={() => handleAddComponentClick(Const.moduleItemType.QuestionOneChoice)}>
            <div>One choice</div>
            <img className="lessonsDropdownMenuItemIcon" src={one_choice_icon}/>
          </div>
          <div className="lessonsDropdownMenuItem" onClick={() => handleAddComponentClick(Const.moduleItemType.QuestionCheckboxes)}>
            <div>Multiple choices</div>
            <img className="lessonsDropdownMenuItemIcon" src={multi_choice_icon}/>
          </div>
          <div className="lessonsDropdownMenuItem" onClick={() => handleAddComponentClick(Const.moduleItemType.QuestionScale)}>
            <div>Slider</div>
            <img className="lessonsDropdownMenuItemIcon" src={slider_icon}/>
          </div>
          <div className="lessonsDropdownMenuItem" onClick={() => handleAddComponentClick(Const.moduleItemType.QuestionText)}>
            <div>Correct spelling</div>
            <img className="lessonsDropdownMenuItemIcon" src={headline_icon}/>
          </div>
          <div className="lessonsDropdownMenuItem" onClick={() => handleAddComponentClick(Const.moduleItemType.QuestionParagraph)}>
            <div>Open answer</div>
            <img className="lessonsDropdownMenuItemIcon" src={text_icon}/>
          </div>
      </div>
    </div>
  );
};
