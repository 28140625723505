const Const = {
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],
  shortMonths: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ],
  clientTypes: {
    Basic: '0',
    Partner: '1',
    Go: '2',
    Inactive: '3',
    InternalDemo: '4',
    Enterprise: '5',
  },
  internalUseClientTypes: {
    Partner: '1',
    Inactive: '3',
    InternalDemo: '4',
    Enterprise: '5',
  },
  moduleType: {
    Welcome: 2,
    Lessons: 5,
    Library: 6,
    About: 11
  },
  moduleItemType: {
    Title: 1,
    Paragraph: 2,
    Image: 3,
    Video: 4,
    Link: 5,
    Chapter: 7,
    Lesson: 8,
    FeedbackCorrect: 9,
    FeedbackWrong: 10,
    QuestionText: 11,
    QuestionParagraph: 12,
    QuestionOneChoice: 13,
    QuestionCheckboxes: 14,
    QuestionDropdown: 15,
    QuestionScale: 16,
    ContentTab: 17,
    ContentPage: 19,
    ImageCarousel: 20,
    QuestionImageUpload: 21,
    DisclaimerCheckbox: 23
  },
  certificateLanguages: [
    'Arabic',
    'Chinese',
    'Danish',
    'English',
    'Finnish',
    'French',
    'German',
    'Hindi',
    'Italian',
    'Japanese',
    'Norwegian',
    'Polish',
    'Portuguese',
    'Romanian',
    'Slovak',
    'Spanish',
    'Swedish',
    'Thai',
    'Turkish',
    'Urdu'
  ],
  courseType: {
    Dynamic: 1,
    Static: 2
  },
  pushNotificationType: {
    1: "New interaction",
    2: "Reminder"
  },
  pushStatusType: {
    1: "New",
    2: "In progress",
    3: "Sent",
    4: "Already sent",
    5: "Already answered",
    6: "Already viewed",
    7: "Failed",
    8: "Late arrival"
  },
  // 0: "Legacy Desktop" - 1: "iOS" - 2: "Android" - 3: "Web app on Mobile" - 4: "Web app on Desktop"
  deviceType: {
    0: "Browser",
    1: "iOS",
    2: "Android",
    3: "Mobile",
    4: "Desktop"
  }
};

export default Const;
