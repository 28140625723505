import { createSelector } from 'reselect';
import Const from '../store/Const';
import {
  sortStudentsByType,
  filterStudents,
} from '../utils/statisticsUtils';

const initialState = {
  currentProgram: {
    studentResults: [],
    programData: {},
    students: [],
    isCertificateActive: false,
    dynamicPushTime: {},
    startDate: new Date(),
    modules: {
      aboutModule: {},
      libraryModule: {},
      lessonModule: {},
      welcomeModule: {},
    },
    moduleContent: {
      about: [],
      library: [],
      lessons: [],
      welcome: []
    },
    currentLibraryPageId: "",
    currentLessonId: "",
    currentlyOpenPageSettingsId: "",
    currentUserPushes: []
  },
  studentFilter: {
    company: [],
    department: [],
    status: [],
    certificateStatus: []
  },
  listedCourses: []
};

/**
* programs is the reducer containing information about all programs accessible
* for the user
*/
const programs = (state = initialState, action) => {
  switch (action.type) {
    case 'REMOVEUSERFROMCOURSEINSTATISTICSTABLE_SUCCESS':
      {
        const updatedStudents = state.currentProgram.students.filter(student => {
          return student.id !== action.userId;
        });
        return {
          ...state,
          currentProgram: {
            ...state.currentProgram,
            students: updatedStudents
          }
        };
      }
    case 'RESETUSERPROGRESS_SUCCESS':
      {
        const resetUser = {
          answers: [],
          company: action.userToReset.company || '',
          department: action.userToReset.department || '',
          email: action.userToReset.email,
          id: action.userToReset.id,
          isCertificateQualified: false,
          isCourseCompleted: false,
          name: action.userToReset.name,
          studentScore: "0"
        };
        const updatedStudents = [...state.currentProgram.students, resetUser];
        return {
          ...state,
          currentProgram: {
            ...state.currentProgram,
            students: updatedStudents
          }
        };
      }
    case 'UPDATESTUDENTFILTER_REQUEST':
      return {
        ...state,
        studentFilter: action.newFilter
      };
    case 'RESETSTUDENTFILTER_REQUEST':
      return {
        ...state,
        studentFilter: {
          company: [],
          department: [],
          status: [],
          certificateStatus: []
        }
      };
    case 'GETCOURSES_SUCCESS':
      return {
        ...state,
        listedCourses: action.response.courses
      };
    case 'CREATECOURSE_SUCCESS':
    case 'EDITCOURSESETTINGS_SUCCESS': {
      let newCurrentProgram = {
        ...state.currentProgram,
        ...action.response
      };
      return {
        ...state,
        currentProgram: newCurrentProgram
      };
    }
    case 'GETCOURSE_SUCCESS': {
      let newCurrentProgram = {
        ...state.currentProgram,
        ...action.response
      };
      if (!("signupPageDescription" in action.response) && !("signupPageMaxRegistrants" in action.response)) {
        delete newCurrentProgram.signupPageDescription;
        delete newCurrentProgram.signupPageMaxRegistrants;
      }
      return {
        ...state,
        currentProgram: newCurrentProgram
      };
    }
    case 'DELETECOURSE_SUCCESS': {
      let newListedCourses = [...state.listedCourses].filter(course => {
        return course._id !== action.response.id;
      });
      return {
        ...state,
        listedCourses: newListedCourses
      };
    }
    case 'GETCOURSEMODULES_SUCCESS': {
      let newCurrentProgram = {
        ...state.currentProgram,
        modules: action.response
      };
      return {
        ...state,
        currentProgram: newCurrentProgram
      };
    }
    case 'TOGGLEENABLEMODULE_SUCCESS': {
      let moduleKeys = Object.keys(state.currentProgram.modules);
      let updatedModuleKey = moduleKeys.find(moduleKey => {
        return state.currentProgram.modules[moduleKey]._id === action.response.data._id;
      });
      let newCurrentProgram = {
        ...state.currentProgram,
        modules: { ...state.currentProgram.modules }
      };
      newCurrentProgram.modules[updatedModuleKey] = action.response.data;
      return {
        ...state,
        currentProgram: newCurrentProgram
      };
    }
    case 'GETMODULECONTENT_SUCCESS': {
      switch (action.moduleType) {
        case Const.moduleType.About: {
          let newCurrentProgram = {
            ...state.currentProgram,
            moduleContent: {
              ...state.currentProgram.moduleContent,
              about: action.response
            }
          };
          return {
            ...state,
            currentProgram: newCurrentProgram
          };
        }
        case Const.moduleType.Welcome: {
          let newCurrentProgram = {
            ...state.currentProgram,
            moduleContent: {
              ...state.currentProgram.moduleContent,
              welcome: action.response
            }
          };
          return {
            ...state,
            currentProgram: newCurrentProgram
          };
        }
        case Const.moduleType.Library: {
          let newCurrentProgram = {
            ...state.currentProgram,
            moduleContent: {
              ...state.currentProgram.moduleContent,
              library: action.response
            }
          };
          return {
            ...state,
            currentProgram: newCurrentProgram
          };
        }
        case Const.moduleType.Lessons: {
          let newCurrentProgram = {
            ...state.currentProgram,
            moduleContent: {
              ...state.currentProgram.moduleContent,
              lessons: action.response
            }
          };
          return {
            ...state,
            currentProgram: newCurrentProgram
          };
        }
        default:
          return state;
      }
    }
    case 'ADDCOMPONENT_SUCCESS': {
      action.response.autoFocus = true;
      action.response.newComponent = true;
      switch (action.moduleType) {
        case Const.moduleType.About: {
          let newCurrentProgram = {
            ...state.currentProgram,
            moduleContent: {
              ...state.currentProgram.moduleContent,
              about: [...state.currentProgram.moduleContent.about, action.response]
            }
          };
          return {
            ...state,
            currentProgram: newCurrentProgram
          };
        }
        case Const.moduleType.Welcome: {
          let newCurrentProgram = {
            ...state.currentProgram,
            moduleContent: {
              ...state.currentProgram.moduleContent,
              welcome: [...state.currentProgram.moduleContent.welcome, action.response]
            }
          };
          return {
            ...state,
            currentProgram: newCurrentProgram
          };
        }
        case Const.moduleType.Library: {
          let deepContentCopy = JSON.parse(JSON.stringify(state.currentProgram.moduleContent.library));
          if (action.parentId) {
            let parentItem = searchTree({ children: deepContentCopy }, action.parentId);
            parentItem.children.push(action.response);
          }
          else {
            deepContentCopy.push(action.response);
          }
          let newCurrentProgram = {
            ...state.currentProgram,
            moduleContent: {
              ...state.currentProgram.moduleContent,
              library: deepContentCopy
            }
          };
          return {
            ...state,
            currentProgram: newCurrentProgram
          };
        }
        case Const.moduleType.Lessons: {
          let deepContentCopy = JSON.parse(JSON.stringify(state.currentProgram.moduleContent.lessons));
          let parentItem = searchTree(deepContentCopy, action.parentId);
          parentItem.children.push(action.response);
          let newCurrentProgram = {
            ...state.currentProgram,
            moduleContent: {
              ...state.currentProgram.moduleContent,
              lessons: deepContentCopy
            }
          };
          return {
            ...state,
            currentProgram: newCurrentProgram
          };
        }
        default:
          return state;
      }
    }
    case 'DELETECOMPONENT_SUCCESS': {
      switch (action.moduleType) {
        case Const.moduleType.About: {
          let newAboutContent = [...state.currentProgram.moduleContent.about];
          const deletedItemIndex = newAboutContent.findIndex(item => item._id === action.response._id);
          newAboutContent.splice(deletedItemIndex, 1);
          let newCurrentProgram = {
            ...state.currentProgram,
            moduleContent: {
              ...state.currentProgram.moduleContent,
              about: newAboutContent
            }
          };
          return {
            ...state,
            currentProgram: newCurrentProgram
          };
        }
        case Const.moduleType.Welcome: {
          let newWelcomeContent = [...state.currentProgram.moduleContent.welcome];
          const deletedItemIndex = newWelcomeContent.findIndex(item => item._id === action.response._id);
          newWelcomeContent.splice(deletedItemIndex, 1);
          let newCurrentProgram = {
            ...state.currentProgram,
            moduleContent: {
              ...state.currentProgram.moduleContent,
              welcome: newWelcomeContent
            }
          };
          return {
            ...state,
            currentProgram: newCurrentProgram
          };
        }
        case Const.moduleType.Library: {
          let deepContentCopy = JSON.parse(JSON.stringify(state.currentProgram.moduleContent.library));
          if (action.parentId) {
            let parentItem = searchTree({ children: deepContentCopy }, action.parentId);
            const deletedItemIndex = parentItem.children.findIndex(item => item._id === action.response._id);
            parentItem.children.splice(deletedItemIndex, 1);
          }
          else {
            const deletedItemIndex = deepContentCopy.findIndex(item => item._id === action.response._id);
            deepContentCopy.splice(deletedItemIndex, 1);
          }
          let newCurrentProgram = {
            ...state.currentProgram,
            moduleContent: {
              ...state.currentProgram.moduleContent,
              library: deepContentCopy
            }
          };
          return {
            ...state,
            currentProgram: newCurrentProgram
          };
        }
        case Const.moduleType.Lessons: {
          let deepContentCopy = JSON.parse(JSON.stringify(state.currentProgram.moduleContent.lessons));
          let parentItem = searchTree(deepContentCopy, action.parentId);
          const deletedItemIndex = parentItem.children.findIndex(item => item._id === action.response._id);
          parentItem.children.splice(deletedItemIndex, 1);
          let newCurrentProgram = {
            ...state.currentProgram,
            moduleContent: {
              ...state.currentProgram.moduleContent,
              lessons: deepContentCopy
            }
          };
          return {
            ...state,
            currentProgram: newCurrentProgram
          };
        }
        default:
          return state;
      }
    }
    case 'ADDIMAGECAROUSELIMAGE_SUCCESS':
    case 'REMOVEIMAGECAROUSELIMAGE_SUCCESS':
    case 'UPDATEIMAGECOMPONENT_SUCCESS':
    case 'UPDATECOMPONENT_SUCCESS': {
      switch (action.moduleType) {
        case Const.moduleType.About: {
          let newAboutContent = [...state.currentProgram.moduleContent.about];
          const updatedItemIndex = newAboutContent.findIndex(item => item._id === action.response._id);
          newAboutContent[updatedItemIndex] = action.response;
          const newCurrentProgram = {
            ...state.currentProgram,
            moduleContent: {
              ...state.currentProgram.moduleContent,
              about: newAboutContent
            }
          };
          return {
            ...state,
            currentProgram: newCurrentProgram
          };
        }
        case Const.moduleType.Welcome: {
          let newWelcomeContent = [...state.currentProgram.moduleContent.welcome];
          const updatedItemIndex = newWelcomeContent.findIndex(item => item._id === action.response._id);
          newWelcomeContent[updatedItemIndex] = action.response;
          const newCurrentProgram = {
            ...state.currentProgram,
            moduleContent: {
              ...state.currentProgram.moduleContent,
              welcome: newWelcomeContent
            }
          };
          return {
            ...state,
            currentProgram: newCurrentProgram
          };
        }
        case Const.moduleType.Library: {
          let deepContentCopy = JSON.parse(JSON.stringify(state.currentProgram.moduleContent.library));
          let updatedItem = searchTree({ children: deepContentCopy }, action.response._id);
          delete action.response.children;
          Object.assign(updatedItem, action.response);
          let newCurrentProgram = {
            ...state.currentProgram,
            moduleContent: {
              ...state.currentProgram.moduleContent,
              library: deepContentCopy
            }
          };
          return {
            ...state,
            currentProgram: newCurrentProgram
          };
        }
        case Const.moduleType.Lessons: {
          let deepContentCopy = JSON.parse(JSON.stringify(state.currentProgram.moduleContent.lessons));
          let updatedItem = searchTree(deepContentCopy, action.response._id);
          delete action.response.children;
          Object.assign(updatedItem, action.response);
          let newCurrentProgram = {
            ...state.currentProgram,
            moduleContent: {
              ...state.currentProgram.moduleContent,
              lessons: deepContentCopy
            }
          };
          return {
            ...state,
            currentProgram: newCurrentProgram
          };
        }
        default:
          return state;
      }
    }
    case 'UPDATEMODULE_SUCCESS': {
      const modules = Object.keys(state.currentProgram.modules);
      let updatedModule = '';
      modules.forEach(module => {
        if (state.currentProgram.modules[module]._id === action.response._id) {
          updatedModule = module;
        }
      });
      const newCurrentProgram = {
        ...state.currentProgram,
        modules: {
          ...state.currentProgram.modules,
          [updatedModule]: action.response
        }
      };
      return {
        ...state,
        currentProgram: newCurrentProgram
      };
    }
    case 'SETLIBRARYCONTENT_REQUEST':
      return {
        ...state,
        currentProgram: {
          ...state.currentProgram,
          currentLibraryPageId: action.pageId
        }
      };
    case 'SETLESSONCONTENT_REQUEST':
      return {
        ...state,
        currentProgram: {
          ...state.currentProgram,
          currentLessonId: action.lessonId
        }
      };
    case 'SETCURRENTLYOPENPAGESETTINGSID_REQUEST':
      return {
        ...state,
        currentProgram: {
          ...state.currentProgram,
          currentlyOpenPageSettingsId: action.pageId
        }
      };
    case 'UPDATECOMPONENTORDER_SUCCESS': {
      switch (action.moduleType) {
        case Const.moduleType.About: {
          let newCurrentProgram = {
            ...state.currentProgram,
            moduleContent: {
              ...state.currentProgram.moduleContent,
              about: action.response
            }
          };
          return {
            ...state,
            currentProgram: newCurrentProgram
          };
        }
        case Const.moduleType.Welcome: {
          let newCurrentProgram = {
            ...state.currentProgram,
            moduleContent: {
              ...state.currentProgram.moduleContent,
              welcome: action.response
            }
          };
          return {
            ...state,
            currentProgram: newCurrentProgram
          };
        }
        case Const.moduleType.Library: {
          let deepContentCopy = JSON.parse(JSON.stringify(state.currentProgram.moduleContent.library));
          action.response.forEach(item => {
            let updatedItem = searchTree({ children: deepContentCopy }, item._id);
            delete item.children;
            Object.assign(updatedItem, item);
          });
          if (action.response[0].parentId) {
            let parentItem = searchTree({ children: deepContentCopy }, action.response[0].parentId);
            parentItem.children.sort(function compare(a, b) {
              return a.sortOrder > b.sortOrder ? 1 : -1;
            });
          } else {
            deepContentCopy.sort(function compare(a, b) {
              return a.sortOrder > b.sortOrder ? 1 : -1;
            });
          }
          let newCurrentProgram = {
            ...state.currentProgram,
            moduleContent: {
              ...state.currentProgram.moduleContent,
              library: deepContentCopy
            }
          };
          return {
            ...state,
            currentProgram: newCurrentProgram
          };
        }
        case Const.moduleType.Lessons: {
          let deepContentCopy = JSON.parse(JSON.stringify(state.currentProgram.moduleContent.lessons));
          action.response.forEach(item => {
            let updatedItem = searchTree(deepContentCopy, item._id);
            delete item.children;
            Object.assign(updatedItem, item);
          });
          let parentItem = searchTree(deepContentCopy, action.response[0].parentId);
          parentItem.children.sort(function compare(a, b) {
            return a.sortOrder > b.sortOrder ? 1 : -1;
          });
          let newCurrentProgram = {
            ...state.currentProgram,
            moduleContent: {
              ...state.currentProgram.moduleContent,
              lessons: deepContentCopy
            }
          };
          return {
            ...state,
            currentProgram: newCurrentProgram
          };
        }
        default:
          return state;
      }
    }
    case 'ADDOPTION_SUCCESS': {
      action.response.autoFocus = true;
      let deepContentCopy = JSON.parse(JSON.stringify(state.currentProgram.moduleContent.lessons));
      let updatedItem = searchTree(deepContentCopy, action.response.moduleItemId);
      if (!updatedItem.data) {
        updatedItem.data = {};
      }
      if (updatedItem.data.options) {
        updatedItem.data.options.push(action.response);
      }
      else {
        updatedItem.data.options = [action.response];
      }
      let newCurrentProgram = {
        ...state.currentProgram,
        moduleContent: {
          ...state.currentProgram.moduleContent,
          lessons: deepContentCopy
        }
      };
      return {
        ...state,
        currentProgram: newCurrentProgram
      };
    }
    case 'DELETEOPTION_SUCCESS': {
      let deepContentCopy = JSON.parse(JSON.stringify(state.currentProgram.moduleContent.lessons));
      let updatedItem = searchTree(deepContentCopy, action.moduleItemId);
      const optionIndex = updatedItem.data.options.findIndex(option => {
        return option._id === action.optionId;
      });
      updatedItem.data.options.splice(optionIndex, 1);
      let newCurrentProgram = {
        ...state.currentProgram,
        moduleContent: {
          ...state.currentProgram.moduleContent,
          lessons: deepContentCopy
        }
      };
      return {
        ...state,
        currentProgram: newCurrentProgram
      };
    }
    case 'EDITOPTION_SUCCESS': {
      let deepContentCopy = JSON.parse(JSON.stringify(state.currentProgram.moduleContent.lessons));
      let updatedItem = searchTree(deepContentCopy, action.moduleItemId);
      const optionIndex = updatedItem.data.options.findIndex(option => {
        return option._id === action.optionId;
      });
      updatedItem.data.options[optionIndex] = action.response;
      let newCurrentProgram = {
        ...state.currentProgram,
        moduleContent: {
          ...state.currentProgram.moduleContent,
          lessons: deepContentCopy
        }
      };
      return {
        ...state,
        currentProgram: newCurrentProgram
      };
    }
    case 'EDITONECHOICEOPTION_SUCCESS': {
      let deepContentCopy = JSON.parse(JSON.stringify(state.currentProgram.moduleContent.lessons));
      let updatedItem = searchTree(deepContentCopy, action.moduleItemId);
      if (action.oldOptionResponse) {
        const oldOptionIndex = updatedItem.data.options.findIndex(option => {
          return option._id === action.oldOptionResponse._id;
        });
        updatedItem.data.options[oldOptionIndex] = action.oldOptionResponse;
      }
      const newOptionIndex = updatedItem.data.options.findIndex(option => {
        return option._id === action.newOptionResponse._id;
      });
      updatedItem.data.options[newOptionIndex] = action.newOptionResponse;
      let newCurrentProgram = {
        ...state.currentProgram,
        moduleContent: {
          ...state.currentProgram.moduleContent,
          lessons: deepContentCopy
        }
      };
      return {
        ...state,
        currentProgram: newCurrentProgram
      };
    }
    case 'PUBLISHCOURSE_SUCCESS':
      return {
        ...state,
        currentProgram: {
          ...state.currentProgram,
          isPublished: action.response.isPublished
        }
      };
    case 'LOADPUSHES_SUCCESS':
      return {
        ...state,
        currentProgram: {
          ...state.currentProgram,
          currentUserPushes: action.response
        }
      };
    case 'LOGOUT_REQUEST':
      return initialState;
    default:
      return state;
  }
};



const getStudents = (state) => state.programs.currentProgram.students;
const getStudentFilter = (state) => state.programs.studentFilter;
const getSearchString = (state) => state.componentStates.searchValues;
const getAllLibraryContent = (state) => state.programs.currentProgram.moduleContent.library;
const getAllLessonContent = (state) => state.programs.currentProgram.moduleContent.lessons;
const getCurrentLibraryPageId = (state) => state.programs.currentProgram.currentLibraryPageId;
const getCurrentLessonId = (state) => state.programs.currentProgram.currentLessonId;
const getCurrentlyOpenPageSettingsId = (state) => state.programs.currentProgram.currentlyOpenPageSettingsId;
const getChapters = (state) =>
  state.programs.currentProgram.programData.interactionItems
    && state.programs.currentProgram.programData.interactionItems[0] ?
    state.programs.currentProgram.programData.interactionItems[0].children
    : [];

/**
* getPreviousPageStartDate gets the startdate of the previous page.
* This is for example needed when choosing the start date of the page so
* that the page is not available before the previous page
*/
export const getPreviousPageStartDate = createSelector(
  [getAllLessonContent, getCurrentlyOpenPageSettingsId],
  (lessonContent, currentlyOpenPageSettingsId) => {
    lessonContent = JSON.parse(JSON.stringify(lessonContent));
    let chapters = lessonContent.children || [];
    if (currentlyOpenPageSettingsId.startsWith('new_in_chapter_')) {
      const chapterId = currentlyOpenPageSettingsId.substr(15);
      const chapterIndex = chapters.findIndex(chapter => {
        return chapter._id === chapterId;
      });
      chapters[chapterIndex].children.push({ _id: currentlyOpenPageSettingsId });
    }
    let pages = [];
    chapters.forEach(chapter => {
      chapter.children.forEach(page => {
        pages.push(page);
      });
    });
    const pageIndex = pages.findIndex(page => {
      return page._id === currentlyOpenPageSettingsId;
    });
    if (pageIndex < 2) {
      return null;
    }
    return pages[pageIndex - 1].data.startStaticDate;
  }
);

/**
* getNextPageStartDate gets the startdate of the next page.
* This is for example needed when choosing the start date of the page so
* that the page is not available after the next page
*/
export const getNextPageStartDate = createSelector(
  [getAllLessonContent, getCurrentlyOpenPageSettingsId],
  (lessonContent, currentlyOpenPageSettingsId) => {
    lessonContent = JSON.parse(JSON.stringify(lessonContent));
    let chapters = lessonContent.children || [];
    if (currentlyOpenPageSettingsId.startsWith('new_in_chapter_')) {
      const chapterId = currentlyOpenPageSettingsId.substr(15);
      const chapterIndex = chapters.findIndex(chapter => {
        return chapter._id === chapterId;
      });
      chapters[chapterIndex].children.push({ _id: currentlyOpenPageSettingsId });
    }
    let pages = [];
    chapters.forEach(chapter => {
      chapter.children.forEach(page => {
        pages.push(page);
      });
    });
    const pageIndex = pages.findIndex(page => {
      return page._id === currentlyOpenPageSettingsId;
    });
    if (pageIndex === -1 || pageIndex === pages.length - 1) {
      return null;
    }
    return pages[pageIndex + 1].data.startStaticDate;
  }
);

/**
* getCurrentLibraryPage returns the selected library page that is currently displayed
*/
export const getCurrentLibraryPage = createSelector(
  [getAllLibraryContent, getCurrentLibraryPageId],
  (libraryContent, currentPageId) => {
    return searchTree({ children: libraryContent }, currentPageId);
  }
);

/**
* getCurrentLesson returns the selected lesson that is currently displayed
*/
export const getCurrentLesson = createSelector(
  [getAllLessonContent, getCurrentLessonId],
  (lessonContent, currentLessonId) => {
    return searchTree(lessonContent, currentLessonId);
  }
);

/**
* getStudentDepartments returns a list of unique departments
* for the students of the program
*/
export const getStudentDepartments = createSelector(
  [getStudents],
  (students) => {
    let departments = [];
    let studentWithoutDepartment = students.find(student => !student.department);
    if (studentWithoutDepartment) {
      departments.push("No Department");
    }
    students.forEach(student => {
      if (student.department && !departments.includes(student.department)) {
        departments.push(student.department);
      }
    });
    return departments;
  }
);

/**
* getStudentCompanies returns a list of unique companies
* for the students of the program
*/
export const getStudentCompanies = createSelector(
  [getStudents],
  (students) => {
    let companies = [];
    let studentWithoutCompany = students.find(student => !student.company);
    if (studentWithoutCompany) {
      companies.push("No Company");
    }
    students.forEach(student => {
      if (student.company && !companies.includes(student.company)) {
        companies.push(student.company);
      }
    });
    return companies;
  }
);

/**
* getStudentStatuses returns a list of statuses of students. These are
* not started, active and completed.
*/
export const getStudentStatuses = createSelector(
  [getStudents],
  (students) => {
    let sortedStudents = sortStudentsByType(students);
    let studentStatuses = [];
    if (sortedStudents.notLoggedInStudents.length > 0) {
      studentStatuses.push("Not started");
    }
    if (sortedStudents.studentsInProgress.length > 0) {
      studentStatuses.push("In progress");
    }
    if (sortedStudents.finishedStudents.length > 0) {
      studentStatuses.push("Finished");
    }
    return studentStatuses;
  }
);

/**
* getStudentCertificateStatuses returns a list of statuses of if students
* have received certificates. These can be either 'Achieved' or 'Not achieved'
*/
export const getStudentCertificateStatuses = createSelector(
  [getStudents],
  (students) => {
    let certificateStatuses = [];
    let qualifiedStudent = students.find(student => {
      return student.isCertificateQualified === true;
    });
    if (qualifiedStudent) {
      certificateStatuses.push('Achieved');
    }
    let unqualifiedStudent = students.find(student => {
      return student.isCertificateQualified === false;
    });
    if (unqualifiedStudent) {
      certificateStatuses.push('Not achieved');
    }
    return certificateStatuses;
  }
);

/**
* getFilteredStudents returns a list of students filtered by company,
* department and status
*/
export const getFilteredStudents = createSelector(
  [getStudentFilter, getStudents, getSearchString],
  (studentFilter, students, searchString) => {
    return filterStudents(studentFilter, students, searchString);
  }
);

/**
* getFilteredResults returns a list of students filtered by company,
* department and status
*/
export const getFilteredStudentResults = createSelector(
  [getFilteredStudents],
  (filteredStudents) => {
    return filteredStudents.map(student => {
      return student.answers;
    });
  }
);

/**
* getSortedStudents returns a list of statuses of students sorted by if they
* have started the course, not started the course and completed the course
*/
export const getSortedStudents = createSelector(
  [getFilteredStudents],
  (students) => {
    return sortStudentsByType(students);
  }
);

/**
* getNumberOfInteractions calculates the number of interactions in a program
*/
export const getNumberOfInteractions = createSelector(
  [getChapters],
  (chapters) => {
    let numberOfInteractions = 0;
    for (let chapter of chapters) {
      numberOfInteractions += chapter.children.length;
    }
    return numberOfInteractions;
  }
);

/**
* searchTree searches through course content in a tree structure and returns
* the specified item
*/
const searchTree = (item, itemId) => {
  if (item._id === itemId) {
    return item;
  } else if (item.children != null) {
    let i;
    let result = null;
    for (i = 0; result === null && i < item.children.length; i++) {
      result = searchTree(item.children[i], itemId);
    }
    return result;
  }
  return null;
};



export default programs;
