import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import Modal from '@material-ui/core/Modal';
import ChangePasswordForm from '../components/forms/ChangePasswordForm';
import './ChangePasswordPage.css';
import grey_cross from '../images/grey_cross.png';
import moblrn_logo_old from '../images/svg/moblrn-logo-old.svg';

const mapStateToProps = (state) => {
  return {
    showSubmitNewPasswordErrorMessage: state.componentStates.showSubmitNewPasswordErrorMessage
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeSubmitNewPasswordErrorModal: () => {
      dispatch(actions.closeSubmitNewPasswordErrorModal.request());
    },
  };
};

/**
* ChangePasswordPage is the page where users who are flagged for a password
* change will be redirected to after login
*/
const ChangePasswordPage = ({ showSubmitNewPasswordErrorMessage, closeSubmitNewPasswordErrorModal }) => {
  return (
    <div className="choosePasswordPageBackground">
      <div className="choosePasswordContent">
        <img className="moblrnLogo" src={moblrn_logo_old} alt="moblrn logo"/>
        <div className="changePasswordTitle">Create new password</div>
        <ChangePasswordForm/>
        <Modal
          id="modal"
          className="errorModal"
          open={showSubmitNewPasswordErrorMessage}
          onClose={() => closeSubmitNewPasswordErrorModal()}>
          <div className="errorModalBackground">
            <img className="errorModalX" onClick={() => closeSubmitNewPasswordErrorModal()} src={grey_cross} alt="plus"/>
            <div className="submitPasswordErrorModal">
              An error occured while submitting the new password. Please check that the verification code is correct.
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};



export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordPage);
