import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Menu from '../components/Menu.js';
import ClientList from '../components/ClientList.js';
import Toast from '../components/Toast.js';
import ErrorToast from '../components/ErrorToast.js';
import LoadingToast from '../components/LoadingToast.js';
import './ClientPage.css';

/**
* ClientPage: page showing a table including all clients names and extra details
*/
const ClientPage = () => {
  const isSuperAdmin = useSelector(state => state.user.isSuperAdmin);
  const toastActive = useSelector(state => state.componentStates.toastActive);
  const errorToastActive = useSelector(state => state.componentStates.errorToastActive);
  const loadingToastActive = useSelector(state => state.componentStates.loadingToastActive);
  return (
    <div className="clientPageContainer">
      <Menu/>
      <div className="clientListWrapper">
        {isSuperAdmin ?
          <ClientList/>
          : <Redirect to="/"/>
        }
      </div>
      {toastActive &&
        <Toast
          text="Client deleted"
          timeToClose={5000}
        />
      }
      {errorToastActive &&
        <ErrorToast
          text="Couldn't delete client, please try again"
          timeToClose={10000}
        />
      }
      {loadingToastActive &&
        <LoadingToast
          text="Deleting client"
        />
      }
    </div>
  );
};

export default ClientPage;
