import React, { useState } from 'react';
import { useEffect } from 'react';
import update_status_scheduled_icon from '../../images/svg/update_status_scheduled_icon.svg';
import update_status_icon from '../../images/svg/update_status_icon.svg';
import update_status_icon_dark from '../../images/svg/update_status_icon_dark.svg';
import './UpdateUserStatusCacheScheduledStage.css';
import { useRef } from 'react';

/**
 * UpdateUserStatusCacheScheduledStage: modal which will indicate a user status cache update
 * has been scheduled. It shows a countdown timer indicating when the upate will take place.
 */
const UpdateUserStatusCacheScheduledStage = ({ onClick, timestamp }) => {
  const [countdownMinutes, setCountdownMinutes] = useState(0);
  const [countdownSeconds, setCountdownSeconds] = useState(0);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const timer = useRef();

  const remainingTime = () => {
    const timeLeftMs = timestamp - Date.now();
    const minutes = Math.floor((timeLeftMs / 1000 / 60) % 60);
    const seconds = Math.floor((timeLeftMs / 1000) % 60);
    return {
      totalTimeLeft: timeLeftMs,
      minutes: minutes,
      seconds: seconds,
    };
  };

  const runCountdown = () => {
    const updateClock = () => {
      const timeLeft = remainingTime();
      const currentProgressPercentage = (timeLeft.totalTimeLeft * 100) / 600000;
      setCountdownMinutes(Math.max(0, timeLeft.minutes));
      setCountdownSeconds(Math.max(0, timeLeft.seconds));
      setProgressPercentage(100 - currentProgressPercentage);
      if (timeLeft.totalTimeLeft <= 0) {
        clearInterval(timer.current);
      }
    };
    updateClock();
    timer.current = setInterval(updateClock, 1000);
  };

  useEffect(() => {
    runCountdown();
    return () => clearInterval(timer.current);
  }, []);

  const leadingZero = (value) => {
    if (value < 10) {
      return '0' + value;
    } else {
      return value;
    }
  };

  return (
    <div className="updateStatusScheduledWrapper">
      <div className="updateStatusScheduledImageAndTitleWrapper">
        <img
          className="updateStatusScheduledImage"
          src={update_status_scheduled_icon}
          alt="clock icon"
        />
        <div className="updateStatusScheduledText">SCHEDULED UPDATE</div>
      </div>
      <div className="updateStatusScheduledCounterWrapper">
        <div className="updateStatusScheduledCounterText">
          Updates in
          <span>{`${leadingZero(countdownMinutes)}:${leadingZero(
            countdownSeconds
          )}`}</span>
        </div>
        <div
          className="updateStatusScheduledProgressBarBackground"
          onClick={onClick}
        >
          <div className="updateStatusScheduledProgressBarImageAndText">
            <img
              className="updateStatusScheduledProgressBarDarkImage rotatingIcon"
              src={update_status_icon_dark}
              alt="update icon"
            />
            <div className="updateStatusScheduledProgressBarDarkText">
              Update now
            </div>
          </div>
          <div
            className="updateStatusScheduledProgressBarValue"
            style={{ width: `${progressPercentage}%` }}
          >
            <div className="updateStatusScheduledProgressBarImageAndText">
              <img
                className="updateStatusScheduledProgressBarLightImage rotatingIcon"
                src={update_status_icon}
                alt="update icon"
              />
              <div className="updateStatusScheduledProgressBarLightText">
                Update now
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateUserStatusCacheScheduledStage;
