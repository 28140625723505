import React from 'react';
import Menu from '../components/Menu.js';
import UserList from '../components/UserList.js';
import './UserPage.css';


/**
* UserPage is the page where a superadmin can see an overview over all users
*/
const UserPage = () => {
  return (
    <div className="userPageContainer">
      <Menu/>
      <div className="userListWrapper">
        <UserList/>
      </div>
    </div>
  );
};

export default UserPage;
