import { downloadBatchAddReport } from './use-download-report';
import { ReactComponent as ExclamationIcon } from '../images/styleguideComponentsImages/exclamation_circle_icon.svg';
import { useBatchAddProgressStore } from './use-batch-add-progress.store';
import { useHistory } from 'react-router';
import { useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import API from '../api/api';
import ButtonSecondaryOutlined from '../components/styleguideComponents/ButtonSecondaryOutlined';
import React, { useEffect, useRef } from 'react';
import styles from './UploadStatus.module.css';
import UpdateUserStatusCacheDoneStage from '../components/updateStatusCacheModals/UpdateUserStatusCacheDoneStage';
import UpdateUserStatusCacheInProgressStage from '../components/updateStatusCacheModals/UpdateUserStatusCacheInProgressStage';
import close_icon from '../images/svg/close_icon.svg';

const POLLING_INTERVAL_MS = 500;
export const LOCAL_STORAGE_KEY = 'batchAddStatus';

const UploadStatus = () => {
  const { batchId, courseId, setBatchAddTracking } = useBatchAddProgressStore();

  const enabled = Boolean(courseId) && Boolean(batchId);
  const { data, isFetching } = useQuery(
    ['batchAddProgress', courseId, batchId],
    () => (courseId ? API.getBatchAddStatus({ courseId }) : {}),
    { enabled }
  );

  const batch = data?.batches[batchId];
  const allAdded = (batch?.added ?? 0) === (batch?.total ?? -1);
  const uploadCompleted = (batch?.pending ?? 0) === 0;

  const queryClient = useQueryClient();
  const invalidateQuery = () => {
    queryClient.invalidateQueries(['batchAddProgress', courseId, batchId]);
  };

  const timerRef = useRef();
  useEffect(() => {
    if (batchId && courseId && !timerRef.current) {
      localStorage.setItem(
        LOCAL_STORAGE_KEY,
        JSON.stringify({ batchId, courseId })
      );
      timerRef.current = setInterval(invalidateQuery, POLLING_INTERVAL_MS);
    }
  }, [batchId, courseId]);

  useEffect(() => {
    if (uploadCompleted && timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = 0;
    }
  }, [data]);

  useEffect(() => {
    const { batchId: prevBatchId, courseId: prevCourseId } = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEY) ?? '{}'
    );
    if (prevBatchId && prevCourseId) {
      setBatchAddTracking({ batchId: prevBatchId, courseId: prevCourseId });
    }
  }, []);

  const onClose = () => {
    setBatchAddTracking({ batchId: '', courseId: '' });
    invalidateQuery();
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  };

  const history = useHistory();
  const handleGoToProgram = () => {
    onClose();
    history.push(`/course/${courseId}/adduser`);
  };

  const isLoggedIn = useSelector((state) => state.componentStates.isLoggedIn);

  if (!isLoggedIn || (!batch && !isFetching)) return null;

  if (!uploadCompleted || isFetching) {
    return (
      <div
        className={styles.inProgressWrapper}
        onClick={() => history.push(`/course/${courseId}/adduser`)}
      >
        <UpdateUserStatusCacheInProgressStage
          total={batch?.total ?? 0}
          current={(batch?.total ?? 0) - (batch?.pending ?? 0)}
          title="UPLOADING USERS"
          progressLabel="users uploaded"
        />
      </div>
    );
  }

  if (allAdded) {
    return (
      <div className={styles.doneWrapper} onClick={onClose}>
        <UpdateUserStatusCacheDoneStage label="UPLOAD SUCCESSFUL" />
        <img
          className="updateStatusDoneImage"
          src={close_icon}
          alt="update done icon"
        />
      </div>
    );
  }

  const {
    added = 0,
    already = 0,
    duplicated = 0,
    error = 0,
    quota = 0,
  } = batch;

  return (
    <div className={styles.partialSuccessWrapper}>
      <ExclamationIcon className={styles.icon} />
      <h4>Upload partially successful</h4>
      {added > 0 && (
        <p>
          {added} user{added > 1 ? 's' : ''} {added > 1 ? 'were' : 'was'} added
          to your course
        </p>
      )}
      {error > 0 && (
        <p>
          {error} user{error > 1 ? 's' : ''} {error > 1 ? "weren't" : "wasn't"}{' '}
          added because of faulty email address
        </p>
      )}
      {already > 0 && (
        <p>
          {already} user{already > 1 ? 's' : ''} already existed in the course
        </p>
      )}
      {duplicated > 0 && (
        <p>
          {duplicated} user{duplicated > 1 ? 's' : ''}{' '}
          {duplicated > 1 ? 'were' : 'was'} duplicated in the given file
        </p>
      )}
      {quota > 0 && (
        <p>
          {quota} user{quota > 1 ? 's' : ''} {quota > 1 ? 'were' : 'was'} not
          added due to active users limit
        </p>
      )}
      <p>
        <span
          className={styles.downloadReportLink}
          onClick={() => downloadBatchAddReport(batch.batchId)}
        >
          Download report for more details
        </span>
      </p>
      <div className={styles.buttonsWrapper}>
        <ButtonSecondaryOutlined
          type="button"
          label="Close"
          onClick={onClose}
        />
        <ButtonSecondaryOutlined
          type="button"
          label="Go to program"
          onClick={handleGoToProgram}
        />
      </div>
    </div>
  );
};

export default UploadStatus;
