import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import * as actions from '../actions';
import Const from '../store/Const';
import { useOutsideClick, findEarliestViewDate, findLatestActiveDate } from '../utils/componentUtils';
import { getDayMonthYearFromDate, getTimeFromDate } from '../utils/dateUtils';
import CourseLeftMenu from '../components/CourseLeftMenu.js';
import WarningModal from '../components/WarningModal';
import PushesModal from '../components/courseComponents/PushesModal';
import UserOverviewChapterComponent from '../components/courseComponents/UserOverviewChapterComponent';
import three_dots from '../images/svg/three_dots.svg';
import three_dots_black_bg from '../images/svg/three_dots_black_bg.svg';
import pencil_icon_grey from '../images/svg/pencil_icon_grey.svg';
import pushIcon from '../images/svg/pushIcon.svg';
import red_x_mark from '../images/svg/red_x_mark.svg';
import white_x_mark from '../images/svg/white_x_mark.svg';
import downward_arrow_smooth from '../images/svg/downward_arrow_smooth.svg';
import light_green_checkmark from '../images/svg/light_green_checkmark.svg';
import './CourseUserOverviewPage.css';

/**
* CourseUserOverviewPage - path: /course/:id/useroverview/:userId
* Will show an overview of all the answers entered by a specific user
* in a course.
*/

const CourseUserOverviewPage = () => {
  const dispatch = useDispatch();
  const { courseId, userId } = useParams();
  const user = useSelector(state => state.user);
  const isSuperAdmin = user.isSuperAdmin;
  const userPermissions = user.permissions[0] ?? {};
  const currentUser = useSelector(state => state.users.currentCourseUser);
  const currentUserScores = useSelector(state => state.users.currentCourseUserScores) || {};
  const currentUserAnswers = useSelector(state => state.users.currentCourseUserAnswers);
  const currentProgramName = useSelector(state => state.programs.currentProgram.name);
  const lessonDeprecatedModule = useSelector(state => state.programs.currentProgram.moduleContent.lessons);
  const pushes = useSelector(state => state.programs.currentProgram.currentUserPushes);
  const chapters = lessonDeprecatedModule.children || [];
  const startDate = findEarliestViewDate(currentUserAnswers);
  const lastActiveDate = findLatestActiveDate(currentUserAnswers);
  const isCourseFinished = currentUserScores.totalInteractionsCount === currentUserScores.completedInteractionsCount;
  const [openDropdownMenu, setOpenDropdownMenu] = useState(false);
  const [openChapters, setOpenChapters] = useState(false);
  const [openRemoveUserModal, setOpenRemoveUserModal] = useState(false);
  const [openPushesModal, setOpenPushesModal] = useState(false);
  const dropdownRef = useRef();
  useOutsideClick(dropdownRef, () => {
    setTimeout(() => setOpenDropdownMenu(false), 100);
  });

  useEffect(() => {
    dispatch(actions.getCourseUser.request(userId));
    dispatch(actions.getCourse.request(courseId));
    dispatch(actions.getUserCourseScores.request(courseId, userId));
    dispatch(actions.getUserCourseAnswers.request(courseId, userId));
    dispatch(actions.getModuleContent.request(courseId, Const.moduleType.Lessons));
  }, []);

  const handleLoadPushes = () => {
    dispatch(actions.loadPushes.request(courseId, userId));
    setOpenPushesModal(true);
  };

  const handleClosePushesModal = () => {
    dispatch(actions.loadPushes.success([]));
    setOpenPushesModal(false);
  };

  return (
    <div className="courseUserOverviewPageContainer">
      <CourseLeftMenu/>
      <div className="courseUserOverviewContentWrapper">
        <div className="courseUserOverviewTitle">USER OVERVIEW</div>
        <div className="courseUserOverviewDetailsWrapper">
          <div className="userOverviewNameAndMenuWrapper">
            <div className="userOverviewName">{currentUser.username}</div>
            {
              (isSuperAdmin || userPermissions.fullRights) &&
              <div className="userOverviewMenuIconsWrapper" onClick={() => setOpenDropdownMenu(!openDropdownMenu)}>
                <img className="userOverviewMenuIcon" src={three_dots}/>
                <img className="userOverviewMenuIconOnHover" src={three_dots_black_bg}/>
                {openDropdownMenu &&
                  <div className="userOverviewMenuDropdown" ref={dropdownRef}>
                    <Link className="userOverviewDropdownItem" to={`/course/${courseId}/user/${userId}`}>
                      <div>Edit user</div>
                      <img className="userOverviewDropdownItemIcon" src={pencil_icon_grey}/>
                    </Link>
                    <div className="userOverviewDropdownItem" onClick={() => handleLoadPushes()}>
                      <div>Notification history</div>
                      <img className="userOverviewDropdownItemIcon" src={pushIcon}/>
                    </div>
                    <div className="userOverviewDropdownDelete" onClick={() => setOpenRemoveUserModal(true)}>
                      <div>Remove from program</div>
                      <img className="userOverviewDropdownMenuX" src={red_x_mark} alt="crossmark"/>
                      <img className="userOverviewDropdownMenuX userOverviewXWhite" src={white_x_mark} alt="crossmark"/>
                    </div>
                  </div>
                }
              </div>
            }
          </div>
          <div className="userOverviewProperty">Email: <span className="userOverviewValue">{currentUser.email}</span></div>
          {currentUser.company && <div className="userOverviewProperty">Company: <span className="userOverviewValue">{currentUser.company}</span></div>}
          {currentUser.department && <div className="userOverviewProperty">Department: <span className="userOverviewValue">{currentUser.department}</span></div>}
          <div className="userOverviewProperty">
            Last active:
            <span className="userOverviewValue">
              {currentUser.lastLogin ? ' ' + getDayMonthYearFromDate(currentUser.lastLogin) + ' ' + getTimeFromDate(currentUser.lastLogin) : " Not started"}
            </span>
          </div>
        </div>
        <div
          className={isCourseFinished ?
            "courseUserOverviewDetailsWrapper courseUserOverviewDetailsWrapperFinished"
            :
            "courseUserOverviewDetailsWrapper"
          }
        >
          <div className="userOverviewNameAndMenuWrapper" onClick={() => setOpenChapters(!openChapters)}>
            <div className="userOverviewName">Program progress</div>
            <img
              className={openChapters ? "userOverviewCourseProgressArrow userOverviewCourseProgressInvertArrow" : "userOverviewCourseProgressArrow"}
              src={downward_arrow_smooth}
            />
          </div>
          {startDate && renderActivityDate(currentUserScores, lastActiveDate, startDate)}
          <div className="userOverviewCourseProgressSliderLabel">
            <div>Pages completed</div>
            <div className="userOverviewProgressSliderValue">{currentUserScores.totalInteractionsCount}</div>
          </div>
          {renderProgressComponent(currentUserScores.totalInteractionsCount, currentUserScores.completedInteractionsCount, isCourseFinished)}
          {/*{
            currentUserScores.questionCount > 0 &&
            <>
              <div className="userOverviewCourseProgressSliderLabel">
                <div>Correct answers</div>
                <div className="userOverviewProgressSliderValue">{currentUserScores.questionCount}</div>
              </div>
              {/* TO DO: This progress bar is not showing the right value: we are supposed to show how many questions were answered correctly out of
              the total amount of questions. Instead this is just showing currentUserScores.answeredQuestionCount, which is the amount of
              questions answered, regardless of being answered correctly or not
              {renderProgressComponent(currentUserScores.questionCount, currentUserScores.answeredQuestionCount, isCourseFinished)}
            </>
          }*/}
          {
            currentUserScores.totalScore > 0 &&
            <>
              <div className="userOverviewCourseProgressSliderLabel">
                <div>Points</div>
                <div className="userOverviewProgressSliderValue">{currentUserScores.totalScore}</div>
              </div>
              {renderProgressComponent(currentUserScores.totalScore, currentUserScores.studentScore, isCourseFinished)}
            </>
          }
        </div>
        {openChapters &&
          <>
            {chapters.map((chapter, index) => {
              return (
                <UserOverviewChapterComponent
                  key={chapter._id}
                  chapter={chapter}
                  answers={getAnswersFromChapter(chapter._id, currentUserAnswers)}
                  scores={getChapterScoreData(currentUserScores, index)}
                />
              );
            })}
          </>
        }
      </div>
      <WarningModal
        open={openRemoveUserModal}
        onClose={() => setOpenRemoveUserModal(false)}
        title='Remove from program'
        text1={`Are you sure you want to remove "${currentUser.username}" from ${currentProgramName}?`}
        text2={`"${currentUser.username}" will be deleted and CAN NOT be recovered`}
        leftButtonText='NO, CANCEL'
        rightButtonText='YES, REMOVE'
        onLeftButtonClick={() => setOpenRemoveUserModal(false)}
        onRightButtonClick={() => {
          dispatch(actions.removeUserFromCourseUserOverview.request(courseId, currentUser._id));
          setOpenRemoveUserModal(false);
        }}
      />
      <PushesModal
        open={openPushesModal}
        onClose={() => handleClosePushesModal()}
        pushes={pushes}
      />
    </div>
  );
};

const renderProgressComponent = (max, value, isCourseFinished) => {
  return (
    <div className="userOverviewProgressComponent">
      <div className="userOverviewProgressComponentTrack" />
      <div
        className={isCourseFinished ? "userOverviewProgressComponentProgress userOverviewProgressComponentFinished" : "userOverviewProgressComponentProgress"}
        style={{width: calculateProgressWidth(max, value)}}
      >
        <div className="userOverviewProgressComponentValue">{value ? value : 0}</div>
      </div>
    </div>
  );
};

const calculateProgressWidth = (max, value) => {
  if (!value || value === 0) {
    return 20;
  }
  const width = (376 * value) / max;
  if (width < 25) {
    return 30;
  }
  return width;
};

/**
* renderActivityDate shows the date the last answer was made if the user is
* finished or the first activity if the user is not yet done with the course
*/
const renderActivityDate = (currentUserScores, lastActiveDate, startDate) => {
  if (currentUserScores.totalInteractionsCount === currentUserScores.completedInteractionsCount) {
    return (
      <div className="userOverviewCourseProgressDate overviewCourseFinishedDate">
        <img className="overviewPageCardDateIcon" src={light_green_checkmark}/>
        <div>{'Finished ' + getDayMonthYearFromDate(lastActiveDate) + ' ' + getTimeFromDate(lastActiveDate)}</div>
      </div>
    );
  }
  return (
    <div className="userOverviewCourseProgressDate">
      {'Started ' + getDayMonthYearFromDate(startDate) + ' ' + getTimeFromDate(startDate)}
    </div>
  );
};

const getAnswersFromChapter = (chapterId, answers) => {
  return answers.filter(answer => {
    return answer.moduleItemParentId === chapterId;
  });
};

const getChapterScoreData = (scoreData, index) => {
  return {
    completedInteractions: scoreData.completedInteractionsByChapter?.[index] || 0,
    totalInteractions: scoreData.totalInteractionsByChapter?.[index] || 0,
    chapterStudentScore: scoreData.studentTotalScoreByChapter?.[index] || 0,
    totalChapterScore: scoreData.totalScoreByChapter?.[index] || 0,
    earnedScoreByInteraction: scoreData.earnedScoreByInteraction?.[index] || 0,
    totalScoreByInteractionWithDefaultScore: scoreData.totalScoreByChaptersAndInteractionsWithDefaultScore?.[index] || 0
  };
};


export default CourseUserOverviewPage;
