import React from 'react';
import './ContentLoadingOverlay.css';

/**
* ContentLoadingOverlay
*/
const ContentLoadingOverlay = () => {
  return (
    <div className="contentLoadingOverlayWrapper">
      <div className="contentLoadingOverlayText">Loading...</div>
    </div>
  );
};

export default ContentLoadingOverlay;
