import { useSelector } from 'react-redux';
import ErrorToast from '../components/ErrorToast';
import LoadingOverlay from '../components/LoadingOverlay';
import LoadingToast from '../components/LoadingToast';
import ProgramLayout from '../components/layouts/ProgramLayout';
import React, { useState } from 'react';
import Toast from '../components/Toast';
import UsersData from './UsersData';
import UsersSelectActions from './UsersSelectActions';
import UsersDataProgramSelector from './UsersDataProgramSelector';
import { useUsersDataStore } from './users-data.store';
import API from '../api/api';
import { useParams } from 'react-router';

const UserListPage = () => {
  const toastActive = useSelector((state) => state.componentStates.toastActive);
  const errorToastActive = useSelector(
    (state) => state.componentStates.errorToastActive
  );
  const loadingToastActive = useSelector(
    (state) => state.componentStates.loadingToastActive
  );
  const toastMessage = useSelector(
    (state) => state.componentStates.toastMessage
  );
  const loadingResetUserProgress = useSelector(
    (state) => state.componentStates.loadingResetUserProgress
  );

  const [openResendEmailModalSelected, setOpenResendEmailModalSelected] =
    useState(false);
  const [openDeleteUserModalSelected, setOpenDeleteUserModalSelected] =
    useState(false);
  const [openRightPanel, setOpenRightPanel] = useState(false);

  const {
    deselectAllItems,
    excludedUsersIds,
    removeUser,
    selectedUsersIds,
    selectionInverted,
    totalCount,
    totalNonFinishedUsers,
    totalNonStartedUsers,
    users,
  } = useUsersDataStore();

  const rightPanelShown =
    (selectedUsersIds.length > 0 && openRightPanel) ||
    (selectionInverted && openRightPanel);

  const selectedItemsCount = selectionInverted
    ? totalCount - excludedUsersIds.length
    : selectedUsersIds.length;
  const selectedNonFinishedCount = selectionInverted
    ? totalNonFinishedUsers -
      users.filter(
        ({ _id, remoteStatus }) =>
          excludedUsersIds.includes(_id) &&
          ['Not started', 'In progress'].includes(remoteStatus)
      ).length
    : users.filter(
        ({ _id, remoteStatus }) =>
          selectedUsersIds.includes(_id) &&
          ['Not started', 'In progress'].includes(remoteStatus)
      ).length;

  const { courseId } = useParams();
  const [removingState, setRemovingState] = useState({
    error: false,
    loading: false,
    succeed: false,
  });
  const deleteSelectedUsers = async (userId) => {
    try {
      setRemovingState({
        error: false,
        loading: true,
        succeed: false,
      });
      await API.removeUserFromCourse({
        courseId,
        excludedUsersIds,
        selectionInverted: userId ? false : selectionInverted,
        selectedUsersIds: userId ? [userId] : selectedUsersIds,
      });
      if (selectionInverted) {
        users
          .filter(({ _id }) => !excludedUsersIds.includes(_id))
          .map(({ _id }) => _id)
          .forEach(removeUser);
      } else {
        const removedUsers = [...selectedUsersIds, userId];
        removedUsers.forEach(removeUser);
      }
      deselectAllItems();
      setRemovingState({
        loading: false,
        succeed: true,
      });
    } catch (error) {
      setRemovingState({
        loading: false,
        error: true,
      });
    }
  };

  return (
    <ProgramLayout
      rightContent={
        <UsersDataProgramSelector
          rightPanelShown={rightPanelShown}
          setOpenRightPanel={setOpenRightPanel}
          selectedUsersIds={selectedUsersIds}
          selectionInverted={selectionInverted}
          excludedUsersIds={excludedUsersIds}
        />
      }
      bottomContent={
        <UsersSelectActions
          selectedItemsCount={selectedItemsCount}
          deselectAllItems={deselectAllItems}
          setOpenResendEmailModalSelected={setOpenResendEmailModalSelected}
          setOpenDeleteUserModalSelected={setOpenDeleteUserModalSelected}
          selectedNonFinishedCount={selectedNonFinishedCount}
          openRightPanel={openRightPanel}
          setOpenRightPanel={setOpenRightPanel}
          totalNonStartedUsers={totalNonStartedUsers}
          excludedUsersIds={excludedUsersIds}
        />
      }
      mainContent={
        <>
          {loadingResetUserProgress && <LoadingOverlay />}
          <UsersData
            onDeleteSelectedUsers={deleteSelectedUsers}
            openResendEmailModalSelected={openResendEmailModalSelected}
            setOpenResendEmailModalSelected={setOpenResendEmailModalSelected}
            openDeleteUserModalSelected={openDeleteUserModalSelected}
            setOpenDeleteUserModalSelected={setOpenDeleteUserModalSelected}
            selectedNonFinishedCount={selectedNonFinishedCount}
            selectedItemsCount={selectedItemsCount}
          />
          {(toastActive || removingState.succeed) && (
            <Toast
              shown={removingState.succeed}
              text={removingState.succeed ? 'Users removed' : toastMessage}
              timeToClose={5000}
            />
          )}
          {(errorToastActive || removingState.error) && (
            <ErrorToast
              text={removingState.error ? 'Error removing users' : toastMessage}
              timeToClose={10000}
            />
          )}
          {(loadingToastActive || removingState.loading) && (
            <LoadingToast
              shown={removingState.loading}
              text={removingState.loading ? 'Removing users' : toastMessage}
            />
          )}
        </>
      }
    />
  );
};

export default UserListPage;
