import React from 'react';
import { useSelector } from 'react-redux';
import Menu from '../components/Menu.js';
import AddAdminForm from '../components/forms/AddAdminForm.js';
import Toast from '../components/Toast.js';
import ErrorToast from '../components/ErrorToast.js';
import LoadingToast from '../components/LoadingToast.js';
import './AddAdminPage.css';

/**
* AddAdminPage
*/
const AddAdminPage = () => {
  const toastActive = useSelector(state => state.componentStates.toastActive);
  const errorToastActive = useSelector(state => state.componentStates.errorToastActive);
  const loadingToastActive = useSelector(state => state.componentStates.loadingToastActive);
  const toastMessage = useSelector(state => state.componentStates.toastMessage);
  return (
    <div className="addAdminPageContainer">
      <Menu/>
      <div className="addAdminListWrapper">
        <AddAdminForm />
      </div>
      {toastActive &&
        <Toast
          text="Admin created"
          timeToClose={5000}
        />
      }
      {errorToastActive &&
        <ErrorToast
          text={toastMessage || "Could not add admin, please try again"}
          timeToClose={10000}
        />
      }
      {loadingToastActive &&
        <LoadingToast
          text="Creating admin"
        />
      }
    </div>
  );
};

export default AddAdminPage;
