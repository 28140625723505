import React, { useState, useRef, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Const from '../../store/Const';
import * as actions from '../../actions';
import LessonsTimelineComponent from './LessonsTimelineComponent';
import WarningModal from '../WarningModal';
import PageSettingsModal from './PageSettingsModal';
import { MenuTimelineTitleField, MenuItemTitleField } from './commonCourseCoreComponents';
import { useOutsideClick, usePrevious } from '../../utils/componentUtils';
import three_dots from '../../images/svg/three_dots.svg';
import three_dots_black_bg from '../../images/svg/three_dots_black_bg.svg';
import downward_arrow_smooth from '../../images/svg/downward_arrow_smooth.svg';
import pencil_icon_grey from '../../images/svg/pencil_icon_grey.svg';
import plus_grey_thin from '../../images/svg/plus_grey_thin.svg';
import red_x_mark from '../../images/svg/red_x_mark.svg';
import white_x_mark from '../../images/svg/white_x_mark.svg';
import settings_wheel_icon from '../../images/svg/settings_wheel_icon.svg';
import './Common.css';

/**
* CourseLessonsMenu is the menu showing the lesson pages and chapters
*/
const CourseLessonsMenu = () => {
  const dispatch = useDispatch();
  const moduleContentLoaded = useSelector(state => state.componentStates.moduleContentSuccess);
  const lessonName = useSelector(state => state.programs.currentProgram.modules.lessonModule.name) || '';
  const lessonDeprecatedModule = useSelector(state => state.programs.currentProgram.moduleContent.lessons);
  const lessonContent = lessonDeprecatedModule.children || [];
  const previousLessonsContent = usePrevious(lessonContent);
  const moduleId = useSelector(state => state.programs.currentProgram.modules.lessonModule._id) || '';
  const selectedPageId = useSelector(state => state.programs.currentProgram.currentLessonId) || '';
  const currentlyOpenPageSettingsId = useSelector(state => state.programs.currentProgram.currentlyOpenPageSettingsId);
  const courseType = useSelector(state => state.programs.currentProgram.type) || 0;
  const courseId = useSelector(state => state.programs.currentProgram._id);
  const openChapters = useSelector(state => state.componentStates.openChaptersInLessonsMenu) || {};
  const newlyCreatedCourse = useSelector(state => state.componentStates.newlyCreatedCourse);
  const [openLessonsDropdownMenu, setOpenLessonsDropdownMenu] = useState(false);
  const [openLessonsMenuItemId, setOpenLessonsMenuItemId] = useState(false);
  const [currentlyEditedMenuItemId, setCurrentlyEditedMenuItemId] = useState('');
  const [itemToDelete, setItemToDelete] = useState({});
  const [openDeleteItemModal, setOpenDeleteItemModal] = useState(false);
  const lessonsMenuRef = useRef();
  const lessonsTitleInputRef = useRef();
  const lessonsPageMenuRef = useRef();
  const handleEditTitleClick = () => {
    lessonsTitleInputRef.current.focus();
    setOpenLessonsDropdownMenu(false);
  };
  const handleAddChapterClick = () => {
    dispatch(actions.addComponent.request(Const.moduleItemType.Chapter, lessonDeprecatedModule._id, moduleId, Const.moduleType.Lessons, { text: "" }));
    setOpenLessonsDropdownMenu(false);
  };
  const handleEditMenuItemTitleClick = (itemId) => {
    dispatch(actions.toggleEditMenuPageTitle.request(true));
    setCurrentlyEditedMenuItemId(itemId);
    setTimeout(() => setOpenLessonsMenuItemId(''), 0);                          //Why doesn't this work without the timeout?
  };
  const handleDeleteItemClick = (item) => {
    setItemToDelete(item);
    setOpenDeleteItemModal(true);
  };
  const handleOpenPageSettingsModal = (pageId) => {
    if (courseType === Const.courseType.Dynamic && lessonContent[0].children.length === 0) {
      dispatch(actions.addComponent.request(
        Const.moduleItemType.Lesson,
        lessonContent[0]._id,
        moduleId,
        Const.moduleType.Lessons,
        { text: '' },
        courseId
      ));
    }
    else {
      dispatch(actions.setCurrentlyOpenPageSettingsId.request(pageId));
    }
  };
  useOutsideClick(lessonsMenuRef, () => {
    setTimeout(() => setOpenLessonsDropdownMenu(false), 100);
  });
  useOutsideClick(lessonsPageMenuRef, () => {
    setOpenLessonsMenuItemId('');
  });

  useEffect(() => {
    if (previousLessonsContent && lessonContent.length !== 1 && lessonContent.length === previousLessonsContent.length + 1) {
      setCurrentlyEditedMenuItemId(lessonContent[lessonContent.length - 1]._id);
    } else {
      if (previousLessonsContent && previousLessonsContent.length > 0) {
        lessonContent.forEach((item, index) => {
          if (previousLessonsContent[index] && item.type === Const.moduleItemType.Chapter && previousLessonsContent[index]._id === item._id) {
            if (item.children.length === previousLessonsContent[index].children.length + 1) {
              setCurrentlyEditedMenuItemId(item.children[item.children.length - 1]._id);
            }
          }
        });
      }
    }
  }, [lessonContent]);

  return (
    <div className="courseModuleMenuContainer">
      <div className="courseLessonsMenuContentAndTimelineWrapper">
        <div className="courseLessonsMenuContentAndLengthWrapper">
          <div>
            <div className="courseModuleMenuSegment courseModuleMenuTitleSegment">
              <MenuTimelineTitleField
                titleText={lessonName ? lessonName : 'Timeline'}
                placeholder='Timeline'
                moduleId={moduleId}
                moduleContentLoaded={moduleContentLoaded}
                ref={lessonsTitleInputRef}
              />
              <div className="courseModuleMenuTitleIconWrapper">
                <div className="courseModuleMenuTitleIcons" onClick={() => setOpenLessonsDropdownMenu(true)}>
                  <img className="courseModuleMenuTitleIcon courseModuleMenuRotateIcon" src={three_dots}/>
                  <img className="courseModuleOnHoverMenuTitleIcon" src={three_dots_black_bg}/>
                </div>
                <span className="courseModuleMenuTooltip">Lessons settings</span>
              </div>
              {
                openLessonsDropdownMenu &&
                renderLessonsMenu(lessonsMenuRef, handleEditTitleClick, handleAddChapterClick)
              }
            </div>
            {lessonContent.length > 0 &&
              <>
                {getChapters(lessonContent).map((chapter) => {
                  return <MenuChapter
                          key={chapter._id}
                          chapter={chapter}
                          selectedPageId={selectedPageId}
                          moduleId={moduleId}
                          newChapter={chapter.newComponent}
                          openLessonsMenuItemId={openLessonsMenuItemId}
                          setOpenLessonsMenuItemId={setOpenLessonsMenuItemId}
                          lessonsPageMenuRef={lessonsPageMenuRef}
                          handleEditMenuItemTitleClick={handleEditMenuItemTitleClick}
                          handleDeleteItemClick={handleDeleteItemClick}
                          currentlyEditedMenuItemId={currentlyEditedMenuItemId}
                          setCurrentlyEditedMenuItemId={setCurrentlyEditedMenuItemId}
                          handleOpenPageSettingsModal={handleOpenPageSettingsModal}
                          openChapters={openChapters}
                          newlyCreatedCourse={newlyCreatedCourse}
                        />;
                })}
              </>
            }
            <div
              className="courseModuleAddItem"
              onClick={() => dispatch(actions.addComponent.request(Const.moduleItemType.Chapter, lessonDeprecatedModule._id, moduleId, Const.moduleType.Lessons))}
            >
              <div className="courseModuleAddItemText">
                + Add chapter
              </div>
            </div>
          </div>
          <div className="courseLessonsMenuCourseLength">
            Program length
            <div className="courseLessonsMenuEraserLine"/>
          </div>
        </div>
        <div className="courseLessonsMenusDivideLine"/>
        <LessonsTimelineComponent/>
        <div className="courseLessonsMenusTimelineDivideLine"/>
        <div className="courseLessonsOverflowSpaceCover"/>
      </div>
      <WarningModal
        open={openDeleteItemModal}
        onClose={() => setOpenDeleteItemModal(false)}
        title={itemToDelete.type === Const.moduleItemType.Lesson ? 'Delete page' : 'Delete chapter'}
        text1={itemToDelete.type === Const.moduleItemType.Lesson ?
          `Are you sure you want to delete the page "${itemToDelete.name || "New page"}"?`
          :
          `Are you sure you want to delete the chapter "${itemToDelete.name || "New chapter"}"?`
        }
        text2={itemToDelete.type === Const.moduleItemType.Lesson ?
          `The page "${itemToDelete.name || "New page"}" will be deleted and CAN NOT be recovered.`
          :
          `The chapter "${itemToDelete.name || "New chapter"}" will be deleted and CAN NOT be recovered.`
        }
        leftButtonText='NO, CANCEL'
        rightButtonText='YES, DELETE'
        onLeftButtonClick={() => handleCloseDeleteItemModal(setOpenDeleteItemModal, setItemToDelete)}
        onRightButtonClick={() => handleDeleteItem(setOpenDeleteItemModal, itemToDelete, setItemToDelete, dispatch)}
      />
      <PageSettingsModal
        open={currentlyOpenPageSettingsId !== ''}
        onClose={() => handleOpenPageSettingsModal('')}
        page={getPageToEdit(currentlyOpenPageSettingsId, lessonContent)}
        isFirstPage={isFirstPage(currentlyOpenPageSettingsId, lessonContent)}
      />
    </div>
  );
};

const getChapters = (lessonsContent) => {
  return lessonsContent.filter(item => {
    return item.type === Const.moduleItemType.Chapter;
  });
};

const renderMenuPage = (
  page,
  index,
  selectedPageId,
  courseId,
  openLessonsMenuItemId,
  setOpenLessonsMenuItemId,
  lessonsPageMenuRef,
  handleEditMenuItemTitleClick,
  handleDeleteItemClick,
  moduleId,
  currentlyEditedMenuItemId,
  setCurrentlyEditedMenuItemId,
  handleOpenPageSettingsModal
) => {
  return (
    <div
      className={page._id === selectedPageId ? "courseModuleMenuPageItem courseModuleSelectedMenuPageItem" : "courseModuleMenuPageItem"}
      key={page._id}
    >
      <Link className="courseModuleLink" to={"/course/" + courseId + "/lessons/" + page._id}>
        <div
          className="courseModuleMenuPageItemText courseModuleMenuPageClickableText"
        >
        {
          currentlyEditedMenuItemId === page._id ?
          <MenuItemTitleField
            item={page}
            placeholder={'New page'}
            moduleId={moduleId}
            moduleType={Const.moduleType.Lessons}
            setCurrentlyEditedMenuItemId={setCurrentlyEditedMenuItemId}
          />
          :
          <>{page.data.text || "New page"}</>
        }
        </div>
      </Link>
      <div className="courseModuleMenuPageItemIconWrapper" onClick={() => setOpenLessonsMenuItemId(page._id)}>
        {
          page._id === openLessonsMenuItemId ?
          <>
            <img src={three_dots_black_bg}/>
            {renderLessonsItemMenu(
              page,
              lessonsPageMenuRef,
              handleEditMenuItemTitleClick,
              handleDeleteItemClick,
              moduleId,
              handleOpenPageSettingsModal
            )}
          </>
          :
          <div className="courseModuleMenuPageTooltipParent">
            <img className="courseModuleMenuPageItemIcon courseModuleMenuRotateIcon" src={three_dots}/>
            <img className="courseModuleMenuPageItemOnHoverIcon" src={three_dots_black_bg}/>
            <span className="courseModuleMenuPageTooltip">Page settings</span>
          </div>
        }
      </div>
    </div>
  );
};

const MenuChapter = ({
  chapter,
  selectedPageId,
  moduleId,
  newChapter,
  openLessonsMenuItemId,
  setOpenLessonsMenuItemId,
  lessonsPageMenuRef,
  handleEditMenuItemTitleClick,
  handleDeleteItemClick,
  currentlyEditedMenuItemId,
  setCurrentlyEditedMenuItemId,
  handleOpenPageSettingsModal,
  openChapters,
  newlyCreatedCourse
}) => {
  const dispatch = useDispatch();
  const { courseId } = useParams();
  useEffect(() => {
    if (newChapter || newlyCreatedCourse) {
      let newOpenChapters = { ...openChapters };
      newOpenChapters[chapter._id] = true;
      dispatch(actions.setOpenChaptersInLesssonsMenu.request(newOpenChapters));
    }
  }, []);
  return (
    <div className="courseModuleChapterSegment">
      <div className="courseModuleMenuPageChapter">
        <img
          className={openChapters[chapter._id] ? "courseModuleExpandChapterIcon" : "courseModuleExpandChapterIcon courseModuleMenuInvertIcon"}
          src={downward_arrow_smooth}
          onClick={() => {
            let newOpenChapters = { ...openChapters };
            newOpenChapters[chapter._id] = openChapters[chapter._id] ? false : true;
            dispatch(actions.setOpenChaptersInLesssonsMenu.request(newOpenChapters));
          }}
        />
        {
          currentlyEditedMenuItemId === chapter._id ?
          <MenuItemTitleField
            item={chapter}
            placeholder={'New chapter'}
            moduleId={moduleId}
            moduleType={Const.moduleType.Lessons}
            setCurrentlyEditedMenuItemId={setCurrentlyEditedMenuItemId}
          />
          :
          <div className="courseModuleMenuPageItemText">{chapter.data.text || "New chapter"}</div>
        }
        <div className="courseModuleMenuPageItemIconWrapper courseModuleMenuChapterIconWrapper" onClick={() => setOpenLessonsMenuItemId(chapter._id)}>
          {
            chapter._id === openLessonsMenuItemId ?
            <>
              <img src={three_dots_black_bg}/>
              {renderLessonsItemMenu(
                chapter,
                lessonsPageMenuRef,
                handleEditMenuItemTitleClick,
                handleDeleteItemClick,
                moduleId,
                handleOpenPageSettingsModal
              )}
            </>
            :
            <div className="courseModuleMenuChapterTooltipParent">
              <img className="courseModuleMenuPageItemIcon courseModuleMenuRotateIcon" src={three_dots}/>
              <img className="courseModuleMenuPageItemOnHoverIcon" src={three_dots_black_bg}/>
              <span className="courseModuleMenuChapterTooltip">Chapter settings</span>
            </div>
          }
        </div>
      </div>
      {
        openChapters[chapter._id] &&
        <>
          {
            chapter.children.map((page, index) => {
              return renderMenuPage(
                page,
                index,
                selectedPageId,
                courseId,
                openLessonsMenuItemId,
                setOpenLessonsMenuItemId,
                lessonsPageMenuRef,
                handleEditMenuItemTitleClick,
                handleDeleteItemClick,
                moduleId,
                currentlyEditedMenuItemId,
                setCurrentlyEditedMenuItemId,
                handleOpenPageSettingsModal
              );
            })
          }
          <div className="courseModuleAddItem" onClick={() => handleOpenPageSettingsModal('new_in_chapter_' + chapter._id)}>
            <div className="courseModuleAddItemText">
              + Add page
            </div>
          </div>
        </>
      }
    </div>
  );
};

const renderLessonsMenu = (lessonsMenuRef, handleEditTitleClick, handleAddChapterClick) => {
  return (
    <div className="courseModuleDropdownMenu" ref={lessonsMenuRef}>
      <div
        className="courseModuleDropdownMenuItem"
        onClick={() => handleEditTitleClick()}
      >
        Edit title
        <img className="courseModuleDropdownMenuItemIcon" src={pencil_icon_grey}/>
      </div>
      <div
        className="courseModuleDropdownMenuItem"
        onClick={() => handleAddChapterClick()}
      >
        Add chapter
        <img className="courseModuleDropdownMenuItemIcon" src={plus_grey_thin}/>
      </div>
    </div>
  );
};

const renderLessonsItemMenu = (
  item,
  lessonsPageMenuRef,
  handleEditMenuItemTitleClick,
  handleDeleteItemClick,
  moduleId,
  handleOpenPageSettingsModal
) => {
  return (
    <div className="courseModulePageDropdownMenu" ref={lessonsPageMenuRef}>
      <div
        className="courseModuleDropdownMenuItem"
        onClick={() => handleEditMenuItemTitleClick(item._id)}
      >
        {item.type === Const.moduleItemType.Chapter ? "Edit title" : "Edit page title"}
        <img className="courseModuleDropdownMenuItemIcon" src={pencil_icon_grey}/>
      </div>
      {
        item.type === Const.moduleItemType.Lesson &&
        <div
          className="courseModuleDropdownMenuItem"
          onClick={() => handleOpenPageSettingsModal(item._id)}
        >
          Page settings
          <img className="courseModuleDropdownMenuItemIcon" src={settings_wheel_icon}/>
        </div>
      }
      {
        item.type === Const.moduleItemType.Chapter &&
        <div
          className="courseModuleDropdownMenuItem"
          onClick={() => handleOpenPageSettingsModal('new_in_chapter_' + item._id)}
        >
          Add page
          <img className="courseModuleDropdownMenuItemIcon" src={plus_grey_thin}/>
        </div>
      }
      <div
        className="courseModuleDropdownMenuItem courseModuleDropdownDeleteMenuItem"
        onClick={() => handleDeleteItemClick({
          moduleItemId: item._id,
          name: item.data.text,
          parentId: item.parentId,
          moduleId,
          type: item.type
        })}
      >
        {item.type === Const.moduleItemType.Lesson ? "Delete page" : "Delete chapter"}
        <img className="courseModuleDropdownMenuItemIcon" src={red_x_mark}/>
        <img className="courseModuleDropdownMenuItemOnHoverIcon" src={white_x_mark}/>
      </div>
    </div>
  );
};

const handleCloseDeleteItemModal = (setOpenDeleteItemModal, setItemToDelete) => {
  setOpenDeleteItemModal(false);
  setItemToDelete({});
};

const handleDeleteItem = (setOpenDeleteItemModal, itemToDelete, setItemToDelete, dispatch) => {
  setOpenDeleteItemModal(false);
  dispatch(actions.deleteComponent.request(itemToDelete.moduleId, itemToDelete.moduleItemId, Const.moduleType.Lessons, itemToDelete.parentId));
  setItemToDelete({});
};

/**
* isFirstPage checks if the pageId matches the first page in the lessons.
* The first page can't have any time delay or push notification
*/
const isFirstPage = (pageId, lessonsContent) => {
  if (lessonsContent.length > 0 && lessonsContent[0].children.length > 0) {
    return pageId === lessonsContent[0].children[0]._id;
  }
  return true;
};

/**
* getPageToEdit finds the page to edit from the lesson chapters.
* If the page is a not yet created page, the pageId will be on the form
* 'new_in_chapter_<chapterId>'
*/
const getPageToEdit = (pageId, chapters) => {
  for (let i = 0; i < chapters.length; i++) {
    for (let j = 0; j < chapters[i].children.length; j++) {
      if (chapters[i].children[j]._id === pageId) {
        return { ...chapters[i].children[j] };
      }
    }
  }
  if (pageId.startsWith('new_in_chapter_')) {
    const chapterId = pageId.substr(15);
    const chapterIndex = chapters.findIndex(chapter => {
      return chapter._id === chapterId;
    });
    return { data: {}, parentId: chapters[chapterIndex]._id };
  }
  return { data: {} };
};

export default CourseLessonsMenu;
