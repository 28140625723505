import { createSelector } from 'reselect';
import { filterUsersDataTableStudents } from '../utils/statisticsUtils';

const initialState = {
  users: [],
  currentCourseUsers: [],
  numberOfNonStartedUsersInCurrentCourse: 0,
  currentCourseUser: {},
  currentCourseUserScores: {},
  currentCourseUserAnswers: [],
  usersTotalCount: 0,
  userTableFilter: {
    company: [],
    department: []
  },
  currentCourseUsersCompanies: [],
  currentCourseUsersDepartments: [],
  usersDataTableFilter: {
    company: [],
    department: [],
    status: []
  }
};

/**
* users is the reducer responsible for the data of multiple users
*/
const users = (state = initialState, action) => {
  switch (action.type) {
    case 'GETUSERS_SUCCESS':
      return {
        ...state,
        users: action.response.users
      };
    case 'GETUSERSINCOURSE_SUCCESS':
      return {
        ...state,
        usersTotalCount: action.response.usersTotalCount,
        userCountFiltered: action.response.userCountFiltered,
        currentCourseUsers: action.response.users,
        numberOfNonStartedUsersInCurrentCourse: action.response.totalNonStartedUsers
      };
    case 'GETUSERSFILTERSLIST_SUCCESS':
      return {
        ...state,
        currentCourseUsersCompanies: action.response.studentsCompanies,
        currentCourseUsersDepartments: action.response.studentsDepartments
      };
    case 'GETCOURSEUSER_SUCCESS':
      return {
        ...state,
        currentCourseUser: action.response
      };
    case 'GETUSERCOURSESCORES_SUCCESS':
      return {
        ...state,
        currentCourseUserScores: action.response
      };
    case 'GETUSERCOURSEANSWERS_SUCCESS':
      return {
        ...state,
        currentCourseUserAnswers: action.response
      };
    case 'REMOVEUSER_USERTABLE_REQUEST':
      {
        const updatedUsers = state.currentCourseUsers.filter(user => {
          return user._id !== action.userId;
        });
        return {
          ...state,
          currentCourseUsers: updatedUsers
        };
      }
    case 'REMOVEALLUSERSFROMCOURSE_SUCCESS':
      return {
        ...state,
        currentCourseUsers: []
      };
    case 'UPDATEUSERTABLEFILTER_REQUEST':
      return {
        ...state,
        userTableFilter: action.newFilter
      };
    case 'RESETUSERTABLEFILTER_REQUEST':
      return {
        ...state,
        userTableFilter: {
          company: [],
          department: []
        }
      };
    case 'RESETUSERDATATABLEFILTER_REQUEST':
      return {
        ...state,
        usersDataTableFilter: {
          company: [],
          department: [],
          status: []
        }
      };
    case 'UPDATEUSERSDATATABLEFILTER_REQUEST':
      return {
        ...state,
        usersDataTableFilter: action.newFilter
      };
    case 'LOGOUT_REQUEST':
      return initialState;
    default:
      return state;
  }
};

const getStudents = (state) => state.users.currentCourseUsers;
const getStudentFilter = (state) => state.users.usersDataTableFilter;
const getSearchString = (state) => state.componentStates.searchValues;
const getCourseId = (state, courseId) => courseId;

/**
* getFilteredStudents returns a list of students filtered by company,
* department
*/
export const getFilteredStudents = createSelector(
  [getStudentFilter, getStudents, getSearchString, getCourseId],
  (studentFilter, students, searchString, courseId) => {
    return filterUsersDataTableStudents(studentFilter, students, searchString, courseId);
  }
);

export default users;
