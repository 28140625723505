import CourseLeftMenu from '../CourseLeftMenu.js';
import React from 'react';
import styles from './ProgramLayout.module.css';

/**
 * Menu to the left and responsive container to the right
 */
const ProgramLayout = ({ mainContent, bottomContent, rightContent }) => {
  return (
    <div className={styles.programLayout}>
      <div className={styles.menuWrapper}>
        <CourseLeftMenu />
      </div>
      <div className={styles.contentWrapper}>{mainContent}</div>
      {rightContent && (
        <div className={styles.rightWrapper}>{rightContent}</div>
      )}
      {bottomContent && (
        <div className={styles.bottomWrapper}>{bottomContent}</div>
      )}
    </div>
  );
};

export default ProgramLayout;
