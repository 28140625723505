import React, { useEffect } from 'react';
import DropdownComponent from './DropdownComponent';
import SearchComponent from './SearchComponent';
import * as actions from '../actions';
import { useDispatch, useSelector } from 'react-redux';
import Const from '../store/Const';
import './CourseFilterAndSearchBar.css';


/**
* CourseFilterAndSearchBar is
*/
const CourseFilterAndSearchBar = () => {
  const dispatch = useDispatch();
  const isSuperAdmin = useSelector(state => state.user.isSuperAdmin);
  const clientIdsAndNames = useSelector(state => state.clients.clientNames);
  if (isSuperAdmin) {
    useEffect(() => dispatch(actions.getClientNames.request()), []);
  }
  const clientIds = clientIdsAndNames.map(client => {
    return client._id;
  });
  const clientNames = clientIdsAndNames.map(client => {
    return client.name;
  });
  const clientTypes = Object.keys(Const.clientTypes);
  const clientTypeValues = Object.values(Const.clientTypes);
  return (
    <div className={isSuperAdmin ? "courseFilterAndSearchBar" : "courseFilterAndSearchBarClientAdmin"}>
      {isSuperAdmin &&
        <DropdownComponent
          name='clientFilter'
          defaultText='Filter by client'
          optionTexts={clientNames}
          optionValues={clientIds}
        />
      }
      {isSuperAdmin &&
        <DropdownComponent
          name='clientTypeFilter'
          defaultText='Filter by client type'
          optionTexts={clientTypes}
          optionValues={clientTypeValues}
        />
      }
      <SearchComponent
        name='courseName'
        defaultText='Search for programs'
      />
    </div>
  );
};

export default CourseFilterAndSearchBar;
