import { action, createRequestTypes } from './actionHelper';



export const GETUSERROLE = createRequestTypes('GETUSERROLE');
export const GETCLIENTSUBSCRIPTIONDATA = createRequestTypes('GETCLIENTSUBSCRIPTIONDATA');
export const GETCLIENTSTRIPESUBSCRIPTIONDATA = createRequestTypes('GETCLIENTSTRIPESUBSCRIPTIONDATA');
export const TOGGLECANCELSUBSCRIPTION = createRequestTypes('TOGGLECANCELSUBSCRIPTION');
export const GETUSERANSWERSPERPROGRAM = createRequestTypes('GETUSERANSWERSPERPROGRAM');
export const PERFORMPURCHASE = createRequestTypes('PERFORMPURCHASE');
export const CHANGEBILLINGDETAILS = createRequestTypes('CHANGEBILLINGDETAILS');
export const UPDATESTUDENTFILTER = createRequestTypes('UPDATESTUDENTFILTER');
export const UPDATEUSERSDATATABLEFILTER = createRequestTypes('UPDATEUSERSDATATABLEFILTER');
export const UPDATEUSERTABLEFILTER = createRequestTypes('UPDATEUSERTABLEFILTER');
export const RESETSTUDENTFILTER = createRequestTypes('RESETSTUDENTFILTER');
export const RESETUSERTABLEFILTER = createRequestTypes('RESETUSERTABLEFILTER');
export const RESETUSERDATATABLEFILTER = createRequestTypes('RESETUSERDATATABLEFILTER');
export const GETUSERSFILTERSLIST = createRequestTypes('GETUSERSFILTERSLIST');
export const SETSHOWNINTERACTION = createRequestTypes('SETSHOWNINTERACTION');
export const DOWNLOADUSERANSWERXLS = createRequestTypes('DOWNLOADUSERANSWERXLS');
export const DOWNLOADBATCHUPLOADCSVEXAMPLE = createRequestTypes('DOWNLOADBATCHUPLOADCSVEXAMPLE');
export const DOWNLOADBATCHUPLOADXLSXEXAMPLE = createRequestTypes('DOWNLOADBATCHUPLOADXLSXEXAMPLE');
export const DOWNLOADCOURSEUSERLIST = createRequestTypes('DOWNLOADCOURSEUSERLIST');
export const FORGOTPASSWORDSUBMIT = createRequestTypes('FORGOTPASSWORDSUBMIT');
export const NEWPASSWORDSUBMIT = createRequestTypes('NEWPASSWORDSUBMIT');
export const CLOSESUBMITNEWPASSWORDERRORMODAL = createRequestTypes('CLOSESUBMITNEWPASSWORDERRORMODAL');
export const LOGIN = createRequestTypes('LOGIN');
export const LOGOUT = createRequestTypes('LOGOUT');
export const RESETPASSWORD = createRequestTypes('RESETPASSWORD');
export const LOGINFORMVALUECHANGED = createRequestTypes('LOGINFORMVALUECHANGED');
export const GETCOURSES = createRequestTypes('GETCOURSES');
export const CHANGEPAGENUMBER = createRequestTypes('CHANGEPAGENUMBER');
export const SETSELECTEDDROPDOWNVALUE = createRequestTypes('SETSELECTEDDROPDOWNVALUE');
export const GETCLIENTNAMES = createRequestTypes('GETCLIENTNAMES');
export const SETSEARCHVALUE = createRequestTypes('SETSEARCHVALUE');
export const SHOWSETTINGSDROPDOWN = createRequestTypes('SHOWSETTINGSDROPDOWN');
export const SETCLIENTLOGO = createRequestTypes('SETCLIENTLOGO');
export const GETCLIENTLOGO = createRequestTypes('GETCLIENTLOGO');
export const GETCLIENTS = createRequestTypes('GETCLIENTS');
export const GETADMINS = createRequestTypes('GETADMINS');
export const GETUSERS = createRequestTypes('GETUSERS');
export const GETADMIN = createRequestTypes('GETADMIN');
export const DELETECLIENT = createRequestTypes('DELETECLIENT');
export const GETCLIENT = createRequestTypes('GETCLIENT');
export const CREATECOURSE = createRequestTypes('CREATECOURSE');
export const COPYCOURSE = createRequestTypes('COPYCOURSE');
export const CREATECLIENT = createRequestTypes('CREATECLIENT');
export const UPDATECLIENT = createRequestTypes('UPDATECLIENT');
export const CREATEADMIN = createRequestTypes('CREATEADMIN');
export const CLOSETOAST = createRequestTypes('CLOSETOAST');
export const EDITADMIN = createRequestTypes('EDITADMIN');
export const GETCOURSE = createRequestTypes('GETCOURSE');
export const DELETECOURSE = createRequestTypes('DELETECOURSE');
export const EDITCOURSESETTINGS = createRequestTypes('EDITCOURSESETTINGS');
export const PUBLISHCOURSE = createRequestTypes('PUBLISHCOURSE');
export const CLOSEERRORTOAST = createRequestTypes('CLOSEERRORTOAST');
export const ENABLESUBMITBUTTON = createRequestTypes('ENABLESUBMITBUTTON');
export const DELETEUSER = createRequestTypes('DELETEUSER');
export const DELETESELECTEDUSERS = createRequestTypes('DELETESELECTEDUSERS');
export const SETTOASTORIGIN = createRequestTypes('SETTOASTORIGIN');
export const UPDATECOMPONENT = createRequestTypes('UPDATECOMPONENT');
export const DELETECOMPONENT = createRequestTypes('DELETECOMPONENT');
export const UPDATEIMAGECOMPONENT = createRequestTypes('UPDATEIMAGECOMPONENT');
export const ADDIMAGECAROUSELIMAGE = createRequestTypes('ADDIMAGECAROUSELIMAGE');
export const REMOVEIMAGECAROUSELIMAGE = createRequestTypes('REMOVEIMAGECAROUSELIMAGE');
export const SETVIDEOCOMPONENTVALUE = createRequestTypes('SETVIDEOCOMPONENTVALUE');
export const SETLINKCOMPONENTVALUE = createRequestTypes('SETLINKCOMPONENTVALUE');
export const GETCOURSEMODULES = createRequestTypes('GETCOURSEMODULES');
export const GETMODULECONTENT = createRequestTypes('GETMODULECONTENT');
export const ADDCOMPONENT = createRequestTypes('ADDCOMPONENT');
export const UPDATEMODULE = createRequestTypes('UPDATEMODULE');
export const TOGGLEENABLEMODULE = createRequestTypes('TOGGLEENABLEMODULE');
export const SETLIBRARYCONTENT = createRequestTypes('SETLIBRARYCONTENT');
export const SETLESSONCONTENT = createRequestTypes('SETLESSONCONTENT');
export const UPDATECOMPONENTORDER = createRequestTypes('UPDATECOMPONENTORDER');
export const CLOSEIMAGEUPLOADTOAST = createRequestTypes('CLOSEIMAGEUPLOADTOAST');
export const CLOSEIMAGEUPLOADERRORTOAST = createRequestTypes('CLOSEIMAGEUPLOADERRORTOAST');
export const SETIMAGEUPLOADTOASTORIGIN = createRequestTypes('SETIMAGEUPLOADTOASTORIGIN');
export const SETIMAGELOADINGANIMATIONORIGIN = createRequestTypes('SETIMAGELOADINGANIMATIONORIGIN');
export const CLOSEAUTOSAVESAVEDICON = createRequestTypes('CLOSEAUTOSAVESAVEDICON');
export const SETOFFLINESTATUS = createRequestTypes('SETOFFLINESTATUS');
export const SETCONNECTIONSTATUS = createRequestTypes('SETCONNECTIONSTATUS');
export const TOGGLEEDITMENUPAGETITLE = createRequestTypes('TOGGLEEDITMENUPAGETITLE');
export const SETCURRENTLYOPENPAGESETTINGSID = createRequestTypes('SETCURRENTLYOPENPAGESETTINGSID');
export const SETOPENCHAPTERSINLESSONSMENU = createRequestTypes('SETOPENCHAPTERSINLESSONSMENU');
export const ADDOPTION = createRequestTypes('ADDOPTION');
export const DELETEOPTION = createRequestTypes('DELETEOPTION');
export const EDITOPTION = createRequestTypes('EDITOPTION');
export const GETUSERSINCOURSE = createRequestTypes('GETUSERSINCOURSE');
export const ADDUSERTOCOURSE = createRequestTypes('ADDUSERTOCOURSE');
export const GETCOURSEUSER = createRequestTypes('GETCOURSEUSER');
export const EDITCOURSEUSER = createRequestTypes('EDITCOURSEUSER');
export const REMOVEUSERFROMCOURSE = createRequestTypes('REMOVEUSERFROMCOURSE');
export const REMOVEUSERFROMCOURSEUSEROVERVIEW = createRequestTypes('REMOVEUSERFROMCOURSEUSEROVERVIEW');
export const REMOVEUSERFROMCOURSEINSTATISTICSTABLE = createRequestTypes('REMOVEUSERFROMCOURSEINSTATISTICSTABLE');
export const REMOVEALLUSERSFROMCOURSE = createRequestTypes('REMOVEALLUSERSFROMCOURSE');
export const RESENDINVITE = createRequestTypes('RESENDINVITE');
export const RESENDINVITETOALLINACTIVEUSERS = createRequestTypes('RESENDINVITETOALLINACTIVEUSERS');
export const RESENDINVITESELECTEDUSERS = createRequestTypes('RESENDINVITESELECTEDUSERS');
export const GETUSERCOURSESCORES = createRequestTypes('GETUSERCOURSESCORES');
export const GETUSERCOURSEANSWERS = createRequestTypes('GETUSERCOURSEANSWERS');
export const LOADPUSHES = createRequestTypes('LOADPUSHES');
export const EDITONECHOICEOPTION = createRequestTypes('EDITONECHOICEOPTION');
export const GETALLAPIKEYS = createRequestTypes('GETALLAPIKEYS');
export const GETCLIENTAPIKEYS = createRequestTypes('GETCLIENTAPIKEYS');
export const ENABLEAPIFORCLIENT = createRequestTypes('ENABLEAPIFORCLIENT');
export const DISABLEAPIFORCLIENT = createRequestTypes('DISABLEAPIFORCLIENT');
export const CREATEAPIKEYFORCLIENT = createRequestTypes('CREATEAPIKEYFORCLIENT');
export const DELETECLIENTAPIKEY = createRequestTypes('DELETECLIENTAPIKEY');
export const RESETUSERPROGRESS = createRequestTypes('RESETUSERPROGRESS');
export const RESETUSERPROGRESS_USERTABLE = createRequestTypes('RESETUSERPROGRESS_USERTABLE');
export const REMOVEUSER_USERTABLE = createRequestTypes('REMOVEUSER_USERTABLE');
export const MULTIADD_USERS_TO_PROGRAMS = createRequestTypes('MULTIADD_USERS_TO_PROGRAMS');


export const getUserRole = {
  success: response => action(GETUSERROLE.SUCCESS, { response }),
};

export const getClientSubscriptionData = {
  request: () => action(GETCLIENTSUBSCRIPTIONDATA.REQUEST, {}),
  success: response => action(GETCLIENTSUBSCRIPTIONDATA.SUCCESS, { response }),
	failure: error => action(GETCLIENTSUBSCRIPTIONDATA.FAILURE, { error }),
};

export const getClientStripeSubscriptionData = {
  request: () => action(GETCLIENTSTRIPESUBSCRIPTIONDATA.REQUEST, {}),
  success: response => action(GETCLIENTSTRIPESUBSCRIPTIONDATA.SUCCESS, { response }),
	failure: error => action(GETCLIENTSTRIPESUBSCRIPTIONDATA.FAILURE, { error }),
};

export const toggleCancelSubscription = {
  request: (clientId) => action(TOGGLECANCELSUBSCRIPTION.REQUEST, { clientId }),
  success: response => action(TOGGLECANCELSUBSCRIPTION.SUCCESS, { response }),
	failure: error => action(TOGGLECANCELSUBSCRIPTION.FAILURE, { error }),
};

export const getUserAnswersPerProgram = {
  success: response => action(GETUSERANSWERSPERPROGRAM.SUCCESS, { response }),
	failure: error => action(GETUSERANSWERSPERPROGRAM.FAILURE, { error }),
};

export const performPurchase = {
  request: (clientId) => action(PERFORMPURCHASE.REQUEST, { clientId }),
  success: response => action(PERFORMPURCHASE.SUCCESS, { response }),
  failure: error => action(PERFORMPURCHASE.FAILURE, { error }),
};

export const changeBillingDetails = {
  request: (clientId) => action(CHANGEBILLINGDETAILS.REQUEST, { clientId }),
  success: response => action(CHANGEBILLINGDETAILS.SUCCESS, { response }),
  failure: error => action(CHANGEBILLINGDETAILS.FAILURE, { error }),
};

export const updateStudentFilter = {
  request: newFilter => action(UPDATESTUDENTFILTER.REQUEST, { newFilter }),
};

export const updateUsersDataTableFilter = {
  request: newFilter => action(UPDATEUSERSDATATABLEFILTER.REQUEST, { newFilter }),
};

export const updateUserTableFilter = {
  request: newFilter => action(UPDATEUSERTABLEFILTER.REQUEST, { newFilter }),
};

export const resetStudentFilter = {
  request: () => action(RESETSTUDENTFILTER.REQUEST, {}),
};

export const resetUserTableFilter = {
  request: () => action(RESETUSERTABLEFILTER.REQUEST, {}),
};

export const resetUserDataTableFilter = {
  request: () => action(RESETUSERDATATABLEFILTER.REQUEST, {}),
};

export const getUsersFiltersList = {
  request: courseId => action(GETUSERSFILTERSLIST.REQUEST, { courseId }),
  success: response => action(GETUSERSFILTERSLIST.SUCCESS, { response }),
  failure: error => action(GETUSERSFILTERSLIST.FAILURE, { error }),
};

export const setShownInteraction = {
  request: (interactionIndex) => action(SETSHOWNINTERACTION.REQUEST, { interactionIndex }),
};

export const downloadUserAnswerXls = {
  request: (programId) => action(DOWNLOADUSERANSWERXLS.REQUEST, {programId}),
  success: () => action(DOWNLOADUSERANSWERXLS.SUCCESS, {}),
  failure: error => action(DOWNLOADUSERANSWERXLS.FAILURE, { error })
};

export const downloadBatchUploadCsvExample = {
  request: () => action(DOWNLOADBATCHUPLOADCSVEXAMPLE.REQUEST, {}),
  failure: error => action(DOWNLOADBATCHUPLOADCSVEXAMPLE.FAILURE, { error }),
};

export const downloadBatchUploadXlsxExample = {
  request: () => action(DOWNLOADBATCHUPLOADXLSXEXAMPLE.REQUEST, {}),
  failure: error => action(DOWNLOADBATCHUPLOADXLSXEXAMPLE.FAILURE, { error }),
};

export const downloadCourseUserList = {
  request: (programId) => action(DOWNLOADCOURSEUSERLIST.REQUEST, {programId}),
  failure: error => action(DOWNLOADCOURSEUSERLIST.FAILURE, { error }),
};

export const forgotPasswordSubmit = {
  request: (verificationCode, newPassword) => action(FORGOTPASSWORDSUBMIT.REQUEST, { verificationCode, newPassword }),
  success: response => action(FORGOTPASSWORDSUBMIT.SUCCESS, { response }),
  failure: error => action(FORGOTPASSWORDSUBMIT.FAILURE, { error }),
};

export const newPasswordSubmit = {
  request: (cognitoUser, newPassword) => action(NEWPASSWORDSUBMIT.REQUEST, { cognitoUser, newPassword }),
  success: response => action(NEWPASSWORDSUBMIT.SUCCESS, { response }),
  failure: error => action(NEWPASSWORDSUBMIT.FAILURE, { error }),
};

export const closeSubmitNewPasswordErrorModal = {
  request: () => action(CLOSESUBMITNEWPASSWORDERRORMODAL.REQUEST, {}),
};

export const login = {
  request: (email, password) => action(LOGIN.REQUEST, { email, password }),
  success: response => action(LOGIN.SUCCESS, { response }),
	failure: error => action(LOGIN.FAILURE, { error }),
};

export const logout = {
  request: () => action(LOGOUT.REQUEST, {}),
  success: response => action(LOGOUT.SUCCESS, { response }),
  failure: error => action(LOGOUT.FAILURE, { error }),
};

export const resetPassword = {
  request: (username) => action(RESETPASSWORD.REQUEST, { username }),
  success: response => action(RESETPASSWORD.SUCCESS, { response }),
  failure: error => action(RESETPASSWORD.FAILURE, { error }),
};

export const loginFormValueChanged = {
  request: () => action(LOGINFORMVALUECHANGED.REQUEST, {}),
};

export const getCourses = {
  request: (clientId, pageNumber, coursesPerPage, sortAttribute, sortOrder, clientType, searchString) =>
    action(GETCOURSES.REQUEST, { clientId, pageNumber, coursesPerPage, sortAttribute, sortOrder, clientType, searchString }),
  success: response => action(GETCOURSES.SUCCESS, { response }),
  failure: error => action(GETCOURSES.FAILURE, { error }),
};

export const changePageNumber = {
  request: (number) => action(CHANGEPAGENUMBER.REQUEST, { number }),
};

export const setSelectedDropdownValue = {
  request: (name, value) => action(SETSELECTEDDROPDOWNVALUE.REQUEST, { name, value }),
};

export const getClientNames = {
  request: () => action(GETCLIENTNAMES.REQUEST, {}),
  success: response => action(GETCLIENTNAMES.SUCCESS, { response }),
  failure: error => action(GETCLIENTNAMES.FAILURE, { error }),
};

export const setSearchValue = {
  request: (name, value) => action(SETSEARCHVALUE.REQUEST, { name, value }),
};

export const showSettingsDropdown = {
  request: (value) => action(SHOWSETTINGSDROPDOWN.REQUEST, { value }),
};

export const setClientLogo = {
  request: (formData, clientId) => action(SETCLIENTLOGO.REQUEST, { formData, clientId }),
  success: response => action(SETCLIENTLOGO.SUCCESS, { response }),
  failure: error => action(SETCLIENTLOGO.FAILURE, { error }),
};

export const getClientLogo = {
  request: (clientId) => action(GETCLIENTLOGO.REQUEST, { clientId }),
  success: response => action(GETCLIENTLOGO.SUCCESS, { response }),
  failure: error => action(GETCLIENTLOGO.FAILURE, { error }),
};

export const getClients = {
  request: (
    itemsPerPage,
    currentPageNumber,
    searchString,
    sortOrder,
    sortAttribute
  ) => action(GETCLIENTS.REQUEST, {
    itemsPerPage,
    currentPageNumber,
    searchString,
    sortOrder,
    sortAttribute
  }),
  success: response => action(GETCLIENTS.SUCCESS, { response }),
  failure: error => action(GETCLIENTS.FAILURE, { error }),
};

export const getAdmins = {
  request: (
    clientId,
    itemsPerPage,
    currentPageNumber,
    searchString,
    sortOrder,
    sortAttribute
  ) => action(GETADMINS.REQUEST, {
    clientId,
    itemsPerPage,
    currentPageNumber,
    searchString,
    sortOrder,
    sortAttribute
  }),
  success: response => action(GETADMINS.SUCCESS, { response }),
  failure: error => action(GETADMINS.FAILURE, { error }),
};

export const getUsers = {
  request: (
    itemsPerPage,
    currentPageNumber,
    searchString,
    sortOrder,
    sortAttribute
  ) => action(GETUSERS.REQUEST, {
    itemsPerPage,
    currentPageNumber,
    searchString,
    sortOrder,
    sortAttribute
  }),
  success: response => action(GETUSERS.SUCCESS, { response }),
  failure: error => action(GETUSERS.FAILURE, { error }),
};

export const getAdmin = {
  request: (id) => action(GETADMIN.REQUEST, { id }),
  success: response => action(GETADMIN.SUCCESS, { response }),
  failure: error => action(GETADMIN.FAILURE, { error }),
};

export const deleteClient = {
  request: (clientId, originClientOverview) => action(DELETECLIENT.REQUEST, { clientId, originClientOverview }),
  success: response => action(DELETECLIENT.SUCCESS, { response }),
  failure: error => action(DELETECLIENT.FAILURE, { error }),
};

export const getClient = {
  request: (id) => action(GETCLIENT.REQUEST, { id }),
  success: response => action(GETCLIENT.SUCCESS, { response }),
  failure: error => action(GETCLIENT.FAILURE, { error }),
};

export const createCourse = {
  request: (
    name,
    clientId,
    timelineType,
    interactionDefaultScore,
    from,
    to,
    startDate,
    programCardImage
  ) => action(CREATECOURSE.REQUEST, {
    name,
    clientId,
    timelineType,
    interactionDefaultScore,
    from,
    to,
    startDate,
    programCardImage
  }),
  success: response => action(CREATECOURSE.SUCCESS, { response }),
  failure: error => action(CREATECOURSE.FAILURE, { error }),
};

export const copyCourse = {
  request: (courseId, clientId, selectedClientId, pageNumber, coursesPerPage, sortAttribute, sortOrder, clientType, searchString) =>
    action(COPYCOURSE.REQUEST, { courseId, clientId, selectedClientId, pageNumber, coursesPerPage, sortAttribute, sortOrder, clientType, searchString }),
  success: response => action(COPYCOURSE.SUCCESS, { response }),
  failure: error => action(COPYCOURSE.FAILURE, { error }),
};

export const createClient = {
  request: (
    name,
    logo,
    maxActivePrograms,
    maxActiveUsers,
    maxAdmins,
    clientType
  ) => action(CREATECLIENT.REQUEST, {
    name,
    logo,
    maxActivePrograms,
    maxActiveUsers,
    maxAdmins,
    clientType
  }),
  success: response => action(CREATECLIENT.SUCCESS, { response }),
  failure: error => action(CREATECLIENT.FAILURE, { error }),
};

export const updateClient = {
  request: (
    _id,
    name,
    logo,
    maxActivePrograms,
    maxActiveUsers,
    maxAdmins,
    clientType
  ) => action(UPDATECLIENT.REQUEST, {
    _id,
    name,
    logo,
    maxActivePrograms,
    maxActiveUsers,
    maxAdmins,
    clientType
  }),
  success: response => action(UPDATECLIENT.SUCCESS, { response }),
  failure: error => action(UPDATECLIENT.FAILURE, { error }),
};

export const createAdmin = {
  request: (
    username,
    email,
    isSuperAdmin,
    clientId,
    redirectPath,
    permissionsObject
  ) => action(CREATEADMIN.REQUEST, {
    username,
    email,
    isSuperAdmin,
    clientId,
    redirectPath,
    permissionsObject
  }),
  success: response => action(CREATEADMIN.SUCCESS, { response }),
  failure: error => action(CREATEADMIN.FAILURE, { error }),
};

export const closeToast = {
  request: () => action(CLOSETOAST.REQUEST, {}),
};

export const editAdmin = {
  request: (
    userId,
    username,
    email,
    permissionsObject,
    isSuperAdmin
  ) => action(EDITADMIN.REQUEST, {
    userId,
    username,
    email,
    permissionsObject,
    isSuperAdmin
  }),
  success: response => action(EDITADMIN.SUCCESS, { response }),
  failure: error => action(EDITADMIN.FAILURE, { error }),
};

export const getCourse = {
  request: (id) => action(GETCOURSE.REQUEST, { id }),
  success: response => action(GETCOURSE.SUCCESS, { response }),
  failure: error => action(GETCOURSE.FAILURE, { error }),
};

export const deleteCourse = {
  request: (id) => action(DELETECOURSE.REQUEST, { id }),
  success: response => action(DELETECOURSE.SUCCESS, { response }),
  failure: error => action(DELETECOURSE.FAILURE, { error }),
};

export const closeErrorToast = {
  request: () => action(CLOSEERRORTOAST.REQUEST, {}),
};

export const editCourseSettings = {
  request: (updatedCourse) => action(EDITCOURSESETTINGS.REQUEST, { updatedCourse }),
  success: response => action(EDITCOURSESETTINGS.SUCCESS, { response }),
  failure: error => action(EDITCOURSESETTINGS.FAILURE, { error }),
};

export const publishCourse = {
  request: (courseId, publish) => action(PUBLISHCOURSE.REQUEST, { courseId, publish }),
  success: response => action(PUBLISHCOURSE.SUCCESS, { response }),
  failure: error => action(PUBLISHCOURSE.FAILURE, { error }),
};

export const enableSubmitButton = {
  request: () => action(ENABLESUBMITBUTTON.REQUEST, {}),
};

export const deleteUser = {
  request: (userId, itemsPerPage, currentPageNumber, searchString, sortOrder, sortAttribute, isAdmin, clientId, source) =>
    action(DELETEUSER.REQUEST, { userId, itemsPerPage, currentPageNumber, searchString, sortOrder, sortAttribute, isAdmin, clientId, source }),
  success: response => action(DELETEUSER.SUCCESS, { response }),
  failure: error => action(DELETEUSER.FAILURE, { error }),
};

export const deleteSelectedUsers = {
  request: (userIds, itemsPerPage, currentPageNumber, searchString, sortOrder, sortAttribute) =>
    action(DELETESELECTEDUSERS.REQUEST, { userIds, itemsPerPage, currentPageNumber, searchString, sortOrder, sortAttribute }),
  success: response => action(DELETESELECTEDUSERS.SUCCESS, { response }),
  failure: error => action(DELETESELECTEDUSERS.FAILURE, { error }),
};

export const setToastOrigin = {
  request: (payload) => action(SETTOASTORIGIN.REQUEST, { payload }),
};

export const updateComponent = {
  request: (moduleId, moduleItemId, data, moduleType, other) => action(UPDATECOMPONENT.REQUEST, { moduleId, moduleItemId, data, moduleType, other }),
  success: (response, moduleType) => action(UPDATECOMPONENT.SUCCESS, { response, moduleType }),
  failure: error => action(UPDATECOMPONENT.FAILURE, { error }),
};

export const deleteComponent = {
  request: (moduleId, moduleItemId, moduleType, parentId) => action(DELETECOMPONENT.REQUEST, { moduleId, moduleItemId, moduleType, parentId }),
  success: (response, moduleType, parentId) => action(DELETECOMPONENT.SUCCESS, { response, moduleType, parentId }),
  failure: error => action(DELETECOMPONENT.FAILURE, { error }),
};

export const updateImageComponent = {
  request: (moduleId, moduleItemId, image, moduleType) => action(UPDATEIMAGECOMPONENT.REQUEST, { moduleId, moduleItemId, image, moduleType }),
  success: (response, moduleType) => action(UPDATEIMAGECOMPONENT.SUCCESS, { response, moduleType }),
  failure: error => action(UPDATEIMAGECOMPONENT.FAILURE, { error }),
};

export const addImageCarouselImage = {
  request: (moduleId, moduleItemId, image, moduleType) => action(ADDIMAGECAROUSELIMAGE.REQUEST, { moduleId, moduleItemId, image, moduleType }),
  success: (response, moduleType) => action(ADDIMAGECAROUSELIMAGE.SUCCESS, { response, moduleType }),
  failure: error => action(ADDIMAGECAROUSELIMAGE.FAILURE, { error }),
};

export const removeImageCarouselImage = {
  request: (moduleItemId, image, moduleType) => action(REMOVEIMAGECAROUSELIMAGE.REQUEST, { moduleItemId, image, moduleType }),
  success: (response, moduleType) => action(REMOVEIMAGECAROUSELIMAGE.SUCCESS, { response, moduleType }),
  failure: error => action(REMOVEIMAGECAROUSELIMAGE.FAILURE, { error }),
};

export const setVideoComponentValue = {
  request: (moduleId, moduleItemId, videoURL) => action(SETVIDEOCOMPONENTVALUE.REQUEST, { moduleId, moduleItemId, videoURL }),
  success: response => action(SETVIDEOCOMPONENTVALUE.SUCCESS, { response }),
  failure: error => action(SETVIDEOCOMPONENTVALUE.FAILURE, { error }),
};

export const setLinkComponentValue = {
  request: (moduleId, moduleItemId, link, linkTitle) => action(SETLINKCOMPONENTVALUE.REQUEST, { moduleId, moduleItemId, link, linkTitle }),
  success: response => action(SETLINKCOMPONENTVALUE.SUCCESS, { response }),
  failure: error => action(SETLINKCOMPONENTVALUE.FAILURE, { error }),
};

export const getCourseModules = {
  request: (courseId) => action(GETCOURSEMODULES.REQUEST, { courseId }),
  success: response => action(GETCOURSEMODULES.SUCCESS, { response }),
  failure: error => action(GETCOURSEMODULES.FAILURE, { error }),
};

export const getModuleContent = {
  request: (courseId, moduleType) => action(GETMODULECONTENT.REQUEST, { courseId, moduleType }),
  success: (response, moduleType) => action(GETMODULECONTENT.SUCCESS, { response, moduleType }),
  failure: error => action(GETMODULECONTENT.FAILURE, { error }),
};

export const addComponent = {
  request: (componentType, parentId, moduleId, moduleType, data, courseId) => action(ADDCOMPONENT.REQUEST, { componentType, parentId, moduleId, moduleType, data, courseId }),
  success: (response, moduleType, parentId) => action(ADDCOMPONENT.SUCCESS, { response, moduleType, parentId }),
  failure: error => action(ADDCOMPONENT.FAILURE, { error }),
};

export const updateModule = {
  request: (moduleId, data) => action(UPDATEMODULE.REQUEST, { moduleId, data }),
  success: (response) => action(UPDATEMODULE.SUCCESS, { response }),
  failure: error => action(UPDATEMODULE.FAILURE, { error }),
};

export const setLibraryContent = {
  request: (pageId) => action(SETLIBRARYCONTENT.REQUEST, { pageId }),
};

export const setLessonContent = {
  request: (lessonId) => action(SETLESSONCONTENT.REQUEST, { lessonId }),
};

export const updateComponentOrder = {
  request: (moduleId, moduleType, items) => action(UPDATECOMPONENTORDER.REQUEST, { moduleId, moduleType, items }),
  success: (response, moduleType) => action(UPDATECOMPONENTORDER.SUCCESS, { response, moduleType }),
  failure: error => action(UPDATECOMPONENTORDER.FAILURE, { error }),
};

export const closeImageUploadToast = {
  request: () => action(CLOSEIMAGEUPLOADTOAST.REQUEST, {}),
};

export const closeImageUploadErrorToast = {
  request: () => action(CLOSEIMAGEUPLOADERRORTOAST.REQUEST, {}),
};

export const setImageUploadToastOrigin = {
  request: (payload) => action(SETIMAGEUPLOADTOASTORIGIN.REQUEST, { payload }),
};

export const toggleEnableModule = {
  request: (moduleId, isEnabled) => action(TOGGLEENABLEMODULE.REQUEST, { moduleId, isEnabled }),
  success: (response) => action(TOGGLEENABLEMODULE.SUCCESS, { response }),
  failure: error => action(TOGGLEENABLEMODULE.FAILURE, { error }),
};

export const setImageLoadingAnimationOrigin = {
  request: (payload) => action(SETIMAGELOADINGANIMATIONORIGIN.REQUEST, { payload }),
};

export const closeAutosaveSavedIcon = {
  request: () => action(CLOSEAUTOSAVESAVEDICON.REQUEST, {}),
};

export const setOfflineStatus = {
  request: (payload) => action(SETOFFLINESTATUS.REQUEST, { payload }),
};

export const setConnectionStatus = {
  request: (payload) => action(SETCONNECTIONSTATUS.REQUEST, { payload }),
};

export const toggleEditMenuPageTitle = {
  request: (isEdited) => action(TOGGLEEDITMENUPAGETITLE.REQUEST, { isEdited }),
};

export const setCurrentlyOpenPageSettingsId = {
  request: (pageId) => action(SETCURRENTLYOPENPAGESETTINGSID.REQUEST, { pageId }),
};

export const setOpenChaptersInLesssonsMenu = {
  request: (chapterData) => action(SETOPENCHAPTERSINLESSONSMENU.REQUEST, { chapterData }),
};

export const addOption = {
  request: (moduleId, moduleItemId) => action(ADDOPTION.REQUEST, { moduleId, moduleItemId }),
  success: (response) => action(ADDOPTION.SUCCESS, { response }),
  failure: error => action(ADDOPTION.FAILURE, { error }),
};

export const deleteOption = {
  request: (moduleId, moduleItemId, optionId) => action(DELETEOPTION.REQUEST, { moduleId, moduleItemId, optionId }),
  success: (response, moduleItemId, optionId) => action(DELETEOPTION.SUCCESS, { response, moduleItemId, optionId }),
  failure: error => action(DELETEOPTION.FAILURE, { error }),
};

export const editOption = {
  request: (optionData) => action(EDITOPTION.REQUEST, { optionData }),
  success: (response, moduleItemId, optionId) => action(EDITOPTION.SUCCESS, { response, moduleItemId, optionId }),
  failure: error => action(EDITOPTION.FAILURE, { error }),
};

export const getUsersInCourse = {
  request: (courseId, currentPageNumber, itemsPerPage, searchString, sortAttribute, sortOrder, companiesFilter, departmentsFilter) =>
    action(GETUSERSINCOURSE.REQUEST, { courseId, currentPageNumber, itemsPerPage, searchString, sortAttribute, sortOrder, companiesFilter, departmentsFilter }),
  success: (response) => action(GETUSERSINCOURSE.SUCCESS, { response }),
  failure: error => action(GETUSERSINCOURSE.FAILURE, { error }),
};

export const addUserToCourse = {
  request: (courseId, username, email, company, department) => action(ADDUSERTOCOURSE.REQUEST, { courseId, username, email, company, department }),
  success: response => action(ADDUSERTOCOURSE.SUCCESS, { response }),
  failure: error => action(ADDUSERTOCOURSE.FAILURE, { error }),
};

export const getCourseUser = {
  request: (userId) => action(GETCOURSEUSER.REQUEST, { userId }),
  success: response => action(GETCOURSEUSER.SUCCESS, { response }),
  failure: error => action(GETCOURSEUSER.FAILURE, { error }),
};

export const editCourseUser = {
  request: (updatedUser) => action(EDITCOURSEUSER.REQUEST, { updatedUser }),
  success: response => action(EDITCOURSEUSER.SUCCESS, { response }),
  failure: error => action(EDITCOURSEUSER.FAILURE, { error }),
};

export const removeUserFromCourse = {
  request: (courseId, userId, currentPageNumber, itemsPerPage, searchString, sortAttribute, sortOrder, source) =>
    action(REMOVEUSERFROMCOURSE.REQUEST, { courseId, userId, currentPageNumber, itemsPerPage, searchString, sortAttribute, sortOrder, source }),
  success: userId => action(REMOVEUSERFROMCOURSE.SUCCESS, { userId }),
  failure: error => action(REMOVEUSERFROMCOURSE.FAILURE, { error }),
};

export const removeUserFromCourseUserOverview = {
  request: (courseId, userId) => action(REMOVEUSERFROMCOURSEUSEROVERVIEW.REQUEST, { courseId, userId }),
  success: userId => action(REMOVEUSERFROMCOURSEUSEROVERVIEW.SUCCESS, { userId }),
  failure: error => action(REMOVEUSERFROMCOURSEUSEROVERVIEW.FAILURE, { error }),
};

export const removeUserFromCourseInStatisticsTable = {
  request: (courseId, userId) => action(REMOVEUSERFROMCOURSEINSTATISTICSTABLE.REQUEST, { courseId, userId }),
  success: userId => action(REMOVEUSERFROMCOURSEINSTATISTICSTABLE.SUCCESS, { userId }),
  failure: error => action(REMOVEUSERFROMCOURSEINSTATISTICSTABLE.FAILURE, { error }),
};

export const removeAllUsersFromCourse = {
  request: (courseId) => action(REMOVEALLUSERSFROMCOURSE.REQUEST, { courseId }),
  success: () => action(REMOVEALLUSERSFROMCOURSE.SUCCESS, {}),
  failure: error => action(REMOVEALLUSERSFROMCOURSE.FAILURE, { error }),
};

export const resendInvite = {
  request: (params) => action(RESENDINVITE.REQUEST, params),
  success: () => action(RESENDINVITE.SUCCESS, {}),
  failure: error => action(RESENDINVITE.FAILURE, { error }),
};

export const resendInviteToAllInactiveUsers = {
  request: (courseId) => action(RESENDINVITETOALLINACTIVEUSERS.REQUEST, { courseId }),
  success: () => action(RESENDINVITETOALLINACTIVEUSERS.SUCCESS, {}),
  failure: error => action(RESENDINVITETOALLINACTIVEUSERS.FAILURE, { error }),
};

export const resendInviteSelectedUsers = {
  request: (params) => action(RESENDINVITESELECTEDUSERS.REQUEST, params),
  success: () => action(RESENDINVITESELECTEDUSERS.SUCCESS, {}),
  failure: error => action(RESENDINVITESELECTEDUSERS.FAILURE, { error }),
};

export const getUserCourseScores = {
  request: (courseId, userId) => action(GETUSERCOURSESCORES.REQUEST, { courseId, userId }),
  success: (response) => action(GETUSERCOURSESCORES.SUCCESS, { response }),
  failure: error => action(GETUSERCOURSESCORES.FAILURE, { error }),
};

export const getUserCourseAnswers = {
  request: (courseId, userId) => action(GETUSERCOURSEANSWERS.REQUEST, { courseId, userId }),
  success: (response) => action(GETUSERCOURSEANSWERS.SUCCESS, { response }),
  failure: error => action(GETUSERCOURSEANSWERS.FAILURE, { error }),
};

export const loadPushes = {
  request: (courseId, userId) => action(LOADPUSHES.REQUEST, { courseId, userId }),
  success: response => action(LOADPUSHES.SUCCESS, { response }),
  failure: error => action(LOADPUSHES.FAILURE, { error }),
};

export const editOneChoiceOption = {
  request: (oldOptionData, newOptionData) => action(EDITONECHOICEOPTION.REQUEST, { oldOptionData, newOptionData }),
  success: (oldOptionResponse, newOptionResponse, moduleItemId) => action(EDITONECHOICEOPTION.SUCCESS, { oldOptionResponse, newOptionResponse, moduleItemId }),
  failure: error => action(EDITONECHOICEOPTION.FAILURE, { error }),
};

export const getAllAPIKeys = {
  request: () => action(GETALLAPIKEYS.REQUEST, {}),
  success: response => action(GETALLAPIKEYS.SUCCESS, { response }),
  failure: error => action(GETALLAPIKEYS.FAILURE, { error }),
};

export const getClientAPIKeys = {
  request: clientId => action(GETCLIENTAPIKEYS.REQUEST, { clientId }),
  success: response => action(GETCLIENTAPIKEYS.SUCCESS, { response }),
  failure: error => action(GETCLIENTAPIKEYS.FAILURE, { error }),
};

export const enableAPIForClient = {
  request: clientId => action(ENABLEAPIFORCLIENT.REQUEST, { clientId }),
  success: () => action(ENABLEAPIFORCLIENT.SUCCESS, {}),
  failure: error => action(ENABLEAPIFORCLIENT.FAILURE, { error }),
};

export const disableAPIForClient = {
  request: clientId => action(DISABLEAPIFORCLIENT.REQUEST, { clientId }),
  success: () => action(DISABLEAPIFORCLIENT.SUCCESS, {}),
  failure: error => action(DISABLEAPIFORCLIENT.FAILURE, { error }),
};

export const createAPIKeyForClient = {
  request: clientId => action(CREATEAPIKEYFORCLIENT.REQUEST, { clientId }),
  success: () => action(CREATEAPIKEYFORCLIENT.SUCCESS, {}),
  failure: error => action(CREATEAPIKEYFORCLIENT.FAILURE, { error }),
};

export const deleteClientAPIKey = {
  request: (clientId, keyId) => action(DELETECLIENTAPIKEY.REQUEST, { clientId, keyId }),
  success: keyId => action(DELETECLIENTAPIKEY.SUCCESS, { keyId }),
  failure: error => action(DELETECLIENTAPIKEY.FAILURE, { error }),
};

export const resetUserProgress = {
  request: (userToReset, courseId) => action(RESETUSERPROGRESS.REQUEST, { userToReset, courseId }),
  success: userToReset => action(RESETUSERPROGRESS.SUCCESS, { userToReset }),
  failure: error => action(RESETUSERPROGRESS.FAILURE, { error }),
};

export const resetUserProgressUserTable = {
  request: (userToReset, courseId, currentPageNumber, itemsPerPage, searchString, sortAttribute, sortOrder, source) =>
    action(RESETUSERPROGRESS_USERTABLE.REQUEST, { userToReset, courseId, currentPageNumber, itemsPerPage, searchString, sortAttribute, sortOrder, source }),
  success: () => action(RESETUSERPROGRESS_USERTABLE.SUCCESS, {}),
  failure: error => action(RESETUSERPROGRESS_USERTABLE.FAILURE, { error })
};

export const removeUserFromUserTable = {
  request: userId => action(REMOVEUSER_USERTABLE.REQUEST, { userId })
};

export const multiaddUsersToPrograms = {
  request: params => action(MULTIADD_USERS_TO_PROGRAMS.REQUEST, { params }),
  success: response => action(MULTIADD_USERS_TO_PROGRAMS.SUCCESS, { response }),
  failure: error => action(MULTIADD_USERS_TO_PROGRAMS.FAILURE, { error }),
};
