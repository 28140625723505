import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actions from '../../actions';
import history from '../../store/history';
import { getYearMonthDayFromDate, getTimeFromDate } from '../../utils/dateUtils';
import { useOutsideClick, changeSortOrder } from '../../utils/componentUtils';
import { renderSortIcon } from './commonTableComponents';
import Tooltip from '../Tooltip';
import WarningModal from '../WarningModal';
import green_dot_live from '../../images/svg/green_dot_live.svg';
import three_dots from '../../images/svg/three_dots.svg';
import three_dots_black_bg from '../../images/svg/three_dots_black_bg.svg';
import user from '../../images/svg/user.svg';
import pencil_icon_grey from '../../images/svg/pencil_icon_grey.svg';
import duplicate_course_icon from '../../images/svg/duplicate_course_icon.svg';
import red_x_mark from '../../images/svg/red_x_mark.svg';
import white_x_mark from '../../images/svg/white_x_mark.svg';
import './CourseTable.css';


/**
* CourseTable is the table showing all courses available per user permissions
* or specified by filtering, pagination, sort order or search
*/
const CourseTable = () => {
  const dispatch = useDispatch();
  const dropdownRef = useRef();
  const user = useSelector(state => state.user);
  const isSuperAdmin = user.isSuperAdmin;
  const userPermissions = user.permissions[0] ?? {};
  const itemsPerPage = useSelector(state => state.componentStates.itemsPerPage);
  const currentPageNumber = useSelector(state => state.componentStates.currentPageNumber);
  const selectedDropdownValues = useSelector(state => state.componentStates.selectedDropdownValues);
  const searchString = useSelector(state => state.componentStates.searchValues['courseName']) || null;
  const listedCourses = useSelector(state => state.programs.listedCourses) || [];
  const clientId = useSelector(state => state.user.permissions[0] && state.user.permissions[0].clientId) || null;
  const [openDropdownMenuIndex, setOpenDropdownMenuIndex] = useState(null);
  const [sortOrder, setSortOrder] = useState(-1);
  const [sortAttribute, setSortAttribute] = useState('lastModified');
  const [openDeleteCourseModal, setOpenDeleteCourseModal] = useState(false);
  const [courseToDeleteIndex, setCourseToDeleteIndex] = useState(null);
  useEffect(() => {
    dispatch(actions.getCourses.request(
      clientId || selectedDropdownValues.clientFilter,
      currentPageNumber,
      itemsPerPage,
      sortAttribute,
      sortOrder,
      selectedDropdownValues.clientTypeFilter,
      searchString
    ));
  }, [currentPageNumber, selectedDropdownValues, searchString, sortOrder, sortAttribute]);
  useOutsideClick(dropdownRef, () => {
    setTimeout(() => setOpenDropdownMenuIndex(null), 100);
  });
  const copyCourse = (course) => {
    dispatch(actions.copyCourse.request(
      course._id,
      course.clientId,
      clientId || selectedDropdownValues.clientFilter,
      currentPageNumber,
      itemsPerPage,
      sortAttribute,
      sortOrder,
      selectedDropdownValues.clientTypeFilter,
      searchString
    ));
    dispatch(actions.setToastOrigin.request('/'));
  };

  const rowRedirectLink = courseId => {
    if (isSuperAdmin || userPermissions.fullRights || userPermissions.edit) {
      return `/course/${courseId}/lessons`;
    } else if (userPermissions.read) {
      return `/course/${courseId}/dashboard`;
    } else if (userPermissions.invite) {
      return `/course/${courseId}/users`;
    }
  };

  return (
    <table className="courseTable">
      <tbody>
        <tr>
          <th className="courseTableTitle"></th>
          <th>
            <div
              className="courseTableTitleAndIconContainer courseTableClickableTitle"
              onClick={() => changeSortOrder('name', sortAttribute, setSortAttribute, sortOrder, setSortOrder)}
            >
              <div className="courseTableTitle">Programs</div>
              {renderSortIcon('name', sortAttribute, sortOrder)}
            </div>
          </th>
          {isSuperAdmin && <th className="courseTableTitle courseTableNoIconTitle">Client</th>}
          <th>
            <div
              className="courseTableTitleAndIconContainer courseTableClickableTitle"
              onClick={() => changeSortOrder('createDate', sortAttribute, setSortAttribute, sortOrder, setSortOrder)}
            >
              <div className="courseTableTitle">Created</div>
              {renderSortIcon('createDate', sortAttribute, sortOrder)}
            </div>
          </th>
          <th>
            <div
              className="courseTableTitleAndIconContainer courseTableClickableTitle"
              onClick={() => changeSortOrder('lastModified', sortAttribute, setSortAttribute, sortOrder, setSortOrder)}
            >
              <div className="courseTableTitle">Last changed</div>
              {renderSortIcon('lastModified', sortAttribute, sortOrder)}
            </div>
          </th>
          <th>
            <div className="courseTableTitleAndIconContainer">
              <div className="courseTableTitle">Users</div>
            </div>
          </th>
          <th className="courseTableTitle"></th>
        </tr>
        {listedCourses.map((row, index) => (
          <tr className="courseTableRow" key={index}>
            <td className="courseTableText courseTableIsPublishedCell" onClick={() => history.push(rowRedirectLink(row._id))}>
              {row.isPublished ? <img className="courseTableGreenDotLive" src={green_dot_live} alt="green dot" /> : <div className="courseTableNotLiveCell" />}
            </td>
            <td className="courseTableText courseTableTitleCell" onClick={() => history.push(rowRedirectLink(row._id))}>{row.name}</td>
            {isSuperAdmin && <td className="courseTableText courseTableClientCell" onClick={() => history.push(rowRedirectLink(row._id))}>{row.clientName}</td>}
            <td className="courseTableText courseTableCreatedCell" onClick={() => history.push(rowRedirectLink(row._id))}>{getYearMonthDayFromDate(row.createDate) + ' ' + getTimeFromDate(row.createDate)}</td>
            <td className="courseTableText courseTableLastChangedCell" onClick={() => history.push(rowRedirectLink(row._id))}>{getYearMonthDayFromDate(row.lastModified) + ' ' + getTimeFromDate(row.lastModified)}</td>
            <td className="courseTableText courseTableUserCell" onClick={() => history.push(rowRedirectLink(row._id))}>{row.numberOfInvitedStudents}</td>
            {
              (isSuperAdmin || userPermissions.fullRights || userPermissions.invite || userPermissions.edit) ?
              <td
                className="courseTableText courseTableMenuCell"
                onClick={() => setOpenDropdownMenuIndex(openDropdownMenuIndex === index ? null : index)}
              >
                <div className="courseTableMenuIconsContainer">
                  <img className="courseTableMenuIcon courseTableMenuIconGrey" src={three_dots} alt="three dots"/>
                  <img className="courseTableMenuIconBlack" src={three_dots_black_bg} alt="three dots"/>
                  {openDropdownMenuIndex === null && <Tooltip text="Program menu" width="87"/>}
                  {index === openDropdownMenuIndex &&
                    renderDropdownMenu(dropdownRef, index, setOpenDeleteCourseModal, setCourseToDeleteIndex, listedCourses[index], copyCourse, isSuperAdmin, userPermissions)
                  }
                </div>
              </td>
              :
              <td></td>
            }
          </tr>
        ))}
      </tbody>
      <WarningModal
        open={openDeleteCourseModal}
        onClose={() => setOpenDeleteCourseModal(false)}
        title='Delete program'
        text1={`Are you sure you want to delete the program "${listedCourses[courseToDeleteIndex] ? listedCourses[courseToDeleteIndex].name : ''}"?`}
        text2='All content, users and other data will be DELETED from this program and CAN NOT be recovered.'
        leftButtonText='NO, CANCEL'
        rightButtonText='YES, DELETE'
        onLeftButtonClick={() => handleCloseDeleteCourseModal(setOpenDeleteCourseModal, setCourseToDeleteIndex)}
        onRightButtonClick={() => handleDeleteCourse(setOpenDeleteCourseModal, dispatch, listedCourses[courseToDeleteIndex]._id, setCourseToDeleteIndex)}
      />
    </table>
  );
};

const renderDropdownMenu = (dropdownRef, index, setOpenDeleteCourseModal, setCourseToDeleteIndex, course, copyCourse, isSuperAdmin, userPermissions) => {
  return (
    <div className="courseTableDropdownMenu" ref={dropdownRef}>
      {
        (isSuperAdmin || userPermissions.fullRights || userPermissions.invite) &&
        <Link className="courseTableDropdownMenuItem" to={'/course/' + course._id + '/users'}>
          <div className="courseDropdownItemContainer">
            <div className="courseTableDropdownMenuText">User list</div>
            <img className="courseTableDropdownMenuIcon" src={user} alt="user"/>
          </div>
        </Link>
      }
      {
        (isSuperAdmin || userPermissions.fullRights || userPermissions.edit) &&
        <>
          <Link className="courseTableDropdownMenuItem" to={'/course/' + course._id + '/lessons'}>
            <div className="courseDropdownItemContainer">
              <div className="courseTableDropdownMenuText">Edit program</div>
              <img className="courseTableDropdownMenuIcon" src={pencil_icon_grey} alt="pencil icon"/>
            </div>
          </Link>
          <div
            className="courseTableDropdownMenuItem"
            onClick={() => copyCourse(course)}
          >
            <div className="courseDropdownItemContainer">
              <div className="courseTableDropdownMenuText">Duplicate program</div>
              <img className="courseTableDropdownMenuIcon" src={duplicate_course_icon} alt="duplicate icon"/>
            </div>
          </div>
          <div
            className="courseTableDropdownMenuItem"
            onClick={() => {
              setCourseToDeleteIndex(index);
              setOpenDeleteCourseModal(true);
            }}
          >
            <div className="courseDropdownDeleteContainer">
              <div className="courseTableDropdownMenuText courseDropdownDeleteText">Delete program</div>
              <img className="courseTableDropdownMenuX" src={red_x_mark} alt="crossmark"/>
              <img className="courseTableDropdownMenuX courseTableXWhite" src={white_x_mark} alt="crossmark"/>
            </div>
          </div>
        </>
      }
    </div>
  );
};

const handleCloseDeleteCourseModal = (setOpenDeleteCourseModal, setCourseToDeleteIndex) => {
  setOpenDeleteCourseModal(false);
  setCourseToDeleteIndex(null);
};

const handleDeleteCourse = (setOpenDeleteCourseModal, dispatch, courseToDeleteId, setCourseToDeleteIndex) => {
  setOpenDeleteCourseModal(false);
  dispatch(actions.deleteCourse.request(courseToDeleteId));
  dispatch(actions.setToastOrigin.request('/'));
  setCourseToDeleteIndex(null);
};


export default CourseTable;
