import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Tooltip from '../Tooltip';
import WarningModal from '../WarningModal';
import NoResultsComponent from '../NoResultsComponent';
import * as actions from '../../actions';
import { useOutsideClick, changeSortOrder, clientAdminPermissionsRole } from '../../utils/componentUtils';
import { renderSortIcon } from './commonTableComponents';
import three_dots from '../../images/svg/three_dots.svg';
import three_dots_black_bg from '../../images/svg/three_dots_black_bg.svg';
import pencil_icon_grey from '../../images/svg/pencil_icon_grey.svg';
import red_x_mark from '../../images/svg/red_x_mark.svg';
import white_x_mark from '../../images/svg/white_x_mark.svg';
import './ClientOverviewAdminList.css';

/**
* ClientOverviewAdminList
*/
const ClientOverviewAdminList = () => {
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const admins = useSelector(state => state.admins.admins) || [];
  const [sortAttribute, setSortAttribute] = useState('username');
  const [sortOrder, setSortOrder] = useState(1);
  const [openDropdownMenuIndex, setOpenDropdownMenuIndex] = useState(null);
  const [openDeleteAdminModal, setOpenDeleteAdminModal] = useState(false);
  const [adminToDelete, setAdminToDelete] = useState(null);
  const adminToDeleteObject = admins.find(client => client._id === adminToDelete);
  const adminToDeleteName = adminToDeleteObject ? adminToDeleteObject.username : "";

  useEffect(() => {
    dispatch(actions.getAdmins.request(clientId, null, null, null, sortOrder, sortAttribute));
    return (() => {
      dispatch(actions.getAdmins.success([]));
    });
  }, [sortOrder, sortAttribute]);

  const dropdownRef = useRef();
  useOutsideClick(dropdownRef, () => {
    setTimeout(() => setOpenDropdownMenuIndex(null), 100);
  });

  const adminRole = admin => {
    if (admin.isSuperAdmin) {
      return 'Super Admin';
    } else {
      return clientAdminPermissionsRole(admin);
    }
  };

  return (
    <div className="clientOverviewAdminListWrapper">
      <div className="clientOverviewListTitle">Adminlist</div>
      <table className="clientOverviewAdminTable">
        <tbody>
          <tr>
            <th>
              <div
                className="adminNameTitleAndIconContainer adminTableClickableTitle"
                onClick={() => changeSortOrder('username', sortAttribute, setSortAttribute, sortOrder, setSortOrder)}
              >
                <div className="adminTableTitle">Name</div>
                {renderSortIcon('username', sortAttribute, sortOrder)}
              </div>
            </th>
            <th>
              <div
                className="adminTableTitleAndIconContainer adminTableClickableTitle"
                onClick={() => changeSortOrder('email', sortAttribute, setSortAttribute, sortOrder, setSortOrder)}
              >
                <div className="adminTableTitle">E-mail</div>
                {renderSortIcon('email', sortAttribute, sortOrder)}
              </div>
            </th>
            <th>
              <div
                className="adminTableTitleAndIconContainer adminTableClickableTitle"
                onClick={() => changeSortOrder('isSuperAdmin', sortAttribute, setSortAttribute, sortOrder, setSortOrder)}
              >
                <div className="adminTableTitle">Role</div>
                {renderSortIcon('isSuperAdmin', sortAttribute, sortOrder)}
              </div>
            </th>
            <th className="adminTableTitle"></th>
          </tr>
          {admins.map((admin, index) => (
            <tr className="adminTableRow" key={index}>
              <td className="adminTableText clientOverviewAdminNameValue">{admin.username}</td>
              <td className="adminTableText clientOverviewAdminEmailValue">{admin.email}</td>
              <td className="adminTableText clientOverviewAdminRoleValue">{adminRole(admin)}</td>
              <td
                className="adminTableMenuCell"
                onClick={() => setOpenDropdownMenuIndex(openDropdownMenuIndex === index ? null : index)}
              >
                <div className="adminTableMenuIconsContainer">
                  <img className="adminTableMenuIcon adminTableMenuIconGrey" src={three_dots} alt="three dots"/>
                  <img className="adminTableMenuIconBlackBG" src={three_dots_black_bg} alt="three dots"/>
                  {openDropdownMenuIndex === null && <Tooltip text="Admin menu" width="75"/>}
                  {index === openDropdownMenuIndex && renderDropdownMenu(dropdownRef, setOpenDeleteAdminModal, setAdminToDelete, admin._id)}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
        <WarningModal
          open={openDeleteAdminModal}
          onClose={() => setOpenDeleteAdminModal(false)}
          title='Delete admin'
          text1={`Are you sure you want to delete the admin "${adminToDeleteName}"?`}
          text2='The admin will be deleted and CAN NOT be recovered'
          leftButtonText='NO, CANCEL'
          rightButtonText='YES, DELETE'
          onLeftButtonClick={() => handleCloseDeleteAdminModal(setOpenDeleteAdminModal, setAdminToDelete)}
          onRightButtonClick={() => handleDeleteAdmin(setOpenDeleteAdminModal, dispatch, adminToDelete, setAdminToDelete, sortOrder, sortAttribute, clientId)}
        />
      </table>
      {admins.length > 0 &&
        <div className="clientOverviewAddAdminButtonWrapper">
          <Link to={`/admins/addadmin?clientId=${clientId}`} className="clientOverviewAddAdminButton">ADD ADMIN</Link>
        </div>
      }
      {admins.length === 0 &&
        <NoResultsComponent
          text1="No registrered admins…"
          text2="Invite your first admin below!"
          buttonText="ADD ADMIN"
          path={`/admins/addadmin?clientId=${clientId}`}
        />
      }
    </div>
  );
};

const renderDropdownMenu = (dropdownRef, setOpenDeleteAdminModal, setAdminToDelete, adminId) => {
  return (
    <div className="adminTableDropdownMenu" ref={dropdownRef}>
      <Link className="adminTableDropdownMenuItem" to={`/admin/${adminId}`}>
        <div className="adminDropdownItemContainer">
          <div className="adminTableDropdownMenuText">Edit admin</div>
          <img className="adminTableDropdownMenuIcon" src={pencil_icon_grey} alt="pencil icon"/>
        </div>
      </Link>
      <div className="adminTableDropdownMenuItem" onClick={() => handleOpenDeleteAdminModal(setOpenDeleteAdminModal, setAdminToDelete, adminId)}>
        <div className="adminDropdownDeleteContainer">
          <div className="adminTableDropdownMenuText adminDropdownDeleteText">Delete admin</div>
          <img className="adminTableDropdownMenuX" src={red_x_mark} alt="crossmark"/>
          <img className="adminTableDropdownMenuX adminTableXWhite" src={white_x_mark} alt="crossmark"/>
        </div>
      </div>
    </div>
  );
};

const handleCloseDeleteAdminModal = (setOpenDeleteAdminModal, setAdminToDelete) => {
  setOpenDeleteAdminModal(false);
  setAdminToDelete(null);
};

const handleOpenDeleteAdminModal = (setOpenDeleteAdminModal, setAdminToDelete, adminId) => {
  setOpenDeleteAdminModal(true);
  setAdminToDelete(adminId);
};

const handleDeleteAdmin = (setOpenDeleteAdminModal, dispatch, adminToDelete, setAdminToDelete, sortOrder, sortAttribute, clientId) => {
  setOpenDeleteAdminModal(false);
  dispatch(actions.deleteUser.request(adminToDelete, null, null, null, sortOrder, sortAttribute, true, clientId));
  dispatch(actions.setToastOrigin.request(`/clients/${clientId}/clientoverview`));
  setAdminToDelete(null);
};

export default ClientOverviewAdminList;
