import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as actions from '../actions';
import ButtonSecondaryOutlined from '../components/styleguideComponents/ButtonSecondaryOutlined';
import ButtonPrimaryBlue from '../components/styleguideComponents/ButtonPrimaryBlue';
import checkbox_checked from '../images/styleguideComponentsImages/checkbox_checked.svg';
import checkbox_unchecked from '../images/styleguideComponentsImages/checkbox_unchecked.svg';
import checkbox_partial_selection from '../images/styleguideComponentsImages/checkbox_partial_selection.svg';
import green_dot_live from '../images/svg/green_dot_live.svg';
import envelope_icon_white from '../images/styleguideComponentsImages/envelope_icon_white.svg';
import './UsersDataProgramSelector.css';

/**
 * UsersDataProgramSelector
 */
const UsersDataProgramSelector = ({
  rightPanelShown,
  setOpenRightPanel,
  selectedUsersIds,
  selectionInverted,
  excludedUsersIds,
}) => {
  const dispatch = useDispatch();
  const { courseId } = useParams();
  const clientId = useSelector(
    (state) => state.programs.currentProgram.clientId
  );
  const addToProgramsLoadingState = useSelector(
    (state) => state.componentStates.multiaddToProgramsLoading
  );
  const programs = useSelector((state) => state.programs.listedCourses);
  const tablePrograms = programs.filter((program) => program._id !== courseId);
  const [selectedPrograms, setSelectedPrograms] = useState([]);

  useEffect(() => {
    dispatch(
      actions.getCourses.request(clientId, 1, 9999, 'name', 1, null, null)
    );
  }, [clientId]);

  const onSelectChange = (item) => {
    if (selectedPrograms.includes(item._id)) {
      setSelectedPrograms(
        selectedPrograms.filter((index) => index !== item._id)
      );
    } else if (!selectedPrograms.includes(item._id)) {
      setSelectedPrograms([...selectedPrograms, item._id]);
    }
  };

  const areAllProgramsSeleted =
    selectedPrograms.length === tablePrograms.length;

  const handleCloseProgramSelectPanel = () => {
    setSelectedPrograms([]);
    setOpenRightPanel(false);
  };

  const handleAddUsersToPrograms = () => {
    let params = {
      excludedUsersIds,
      selectedUsersIds,
      sourceProgramId: selectionInverted ? courseId : '',
      targetProgramsIds: selectedPrograms,
    };

    dispatch(actions.multiaddUsersToPrograms.request(params));
    dispatch(actions.setToastOrigin.request(`/course/${courseId}/users`));
  };

  const headerCheckboxIcon = () => {
    if (
      selectedPrograms.length > 0 &&
      selectedPrograms.length < tablePrograms.length
    ) {
      return checkbox_partial_selection;
    } else if (areAllProgramsSeleted) {
      return checkbox_checked;
    } else {
      return checkbox_unchecked;
    }
  };

  const handleCheckboxClick = () => {
    if (areAllProgramsSeleted) {
      setSelectedPrograms([]);
    } else {
      setSelectedPrograms(tablePrograms.map((program) => program._id));
    }
  };

  return (
    <div
      className={`programsPanelWrapper ${
        rightPanelShown ? 'programsPanelWrapperShown' : ''
      }`}
    >
      <div className="programsPanelContent">
        <div className="programsPanelTitle">Invite to programs</div>

        <table className="programsPanelTable">
          <tbody>
            <tr>
              <th className="programsPanelTableHeaderRow">
                <div className="programsPanelTableNameAndSelectHeader">
                  <img
                    className="programsPanelTableSelectAllIcon"
                    src={headerCheckboxIcon()}
                    alt="checkbox icon"
                    onClick={() => handleCheckboxClick()}
                  />
                  <div className="programsPanelTableTitleAndIconContainer">
                    <div className="programsPanelTableTitle">Program name</div>
                  </div>
                </div>
              </th>
              <th className="programsPanelTableHeaderRow">
                <div className="programsPanelTableUsersTitleContainer">
                  <div className="programsPanelTableTitle">Users</div>
                </div>
              </th>
            </tr>
            {tablePrograms.map((row, index) => (
              <tr
                className={
                  selectedPrograms.includes(row._id)
                    ? 'programsPanelTableRow programsPanelTableRowSelected'
                    : 'programsPanelTableRow'
                }
                key={index}
              >
                <td className="programsPanelTableNameColumn">
                  <label className="programsPanelTableText programsPanelTableCheckboxContainer">
                    <input
                      type="checkbox"
                      checked={selectedPrograms.includes(row._id)}
                      onChange={() => onSelectChange(row)}
                    />
                    <span className="programsPanelTableCustomCheckbox"></span>
                    <div className="programsPanelTableNameAndPublishedIcon">
                      {row.isPublished ? (
                        <img
                          src={green_dot_live}
                          className="programsPanelTableGreenDotLive"
                        />
                      ) : (
                        <div className="programsPanelTableGreenDotPlaceholder" />
                      )}
                      <div className="programsPanelTableProgramName">
                        {row.name}
                      </div>
                    </div>
                  </label>
                </td>
                <td className="programsPanelTableText programsPanelTableUsersValue">
                  {row.numberOfInvitedStudents}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="programsPanelBottomMenu">
        <div className="programsPanelBottomSecondaryWrapper">
          <div className="programsPanelLeftBottom">
            <ButtonSecondaryOutlined
              type="button"
              label="Cancel"
              onClick={() => handleCloseProgramSelectPanel()}
            />
          </div>
          <ButtonPrimaryBlue
            type="button"
            label={
              addToProgramsLoadingState
                ? 'Adding to programs'
                : `Invite to ${selectedPrograms.length} ${
                    selectedPrograms.length === 1 ? 'program' : 'programs'
                  }`
            }
            disabled={
              selectedPrograms.length === 0 || addToProgramsLoadingState
            }
            iconSource={envelope_icon_white}
            loading={addToProgramsLoadingState}
            onClick={handleAddUsersToPrograms}
          />
        </div>
      </div>
    </div>
  );
};

export default UsersDataProgramSelector;
