import React from 'react';
import update_status_done_icon from '../../images/svg/update_status_done_icon.svg';
import './UpdateUserStatusCacheDoneStage.css';

/**
 * UpdateUserStatusCacheDoneStage: this component indicates a
 * user status cache update is done
 */
const UpdateUserStatusCacheDoneStage = ({ label }) => {
  return (
    <div className="updateStatusDoneWrapper">
      <img
        className="updateStatusDoneImage"
        src={update_status_done_icon}
        alt="update done icon"
      />
      <div className="updateStatusDoneText">
        {label ? label : 'UPDATE DONE'}
      </div>
    </div>
  );
};

export default UpdateUserStatusCacheDoneStage;
