import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '../../actions';
import CourseCoreMoveTooltip from './CourseCoreMoveTooltip';
import CourseCoreDeleteTooltip from './CourseCoreDeleteTooltip';
import AnswerWideTextarea from './AnswerWideTextarea';
import headline_icon from '../../images/svg/headline_icon.svg';
import draggable_handle from '../../images/svg/draggable_handle.svg';
import draggable_handle_onhover from '../../images/svg/draggable_handle_onhover.svg';
import red_x_mark from '../../images/svg/red_x_mark.svg';
import red_x_mark_onhover from '../../images/svg/red_x_mark_onhover.svg';
import './OpenAnswerComponent.css';

/**
* OpenAnswerComponent
*/

const OpenAnswerComponent = ({
  moduleId,
  moduleItemId,
  moduleType,
  provided,
  numberOfComponents,
  parentId,
  autoFocus,
  questionText
}) => {
  const dispatch = useDispatch();
  const [localQuestionText, setLocalQuestionText] = useState(questionText);
  const [hasLoaded, setHasLoaded] = useState(false);
  useEffect(() => {
    let debouncedSaveFunction = setTimeout(
      () => {
        if (hasLoaded) {
          dispatch(actions.updateComponent.request(moduleId, moduleItemId, { text: localQuestionText }, moduleType, {}));
        }
        setHasLoaded(true);
      },
      500
    );
    return () => clearTimeout(debouncedSaveFunction);
  }, [localQuestionText]);
  return (
    <div className="openAnswerContainer">
      <img
        className={numberOfComponents === 1 ? "openAnswerIcon openAnswerIconAlone" : "openAnswerIcon"}
        src={headline_icon}
      />
      {numberOfComponents > 1 &&
        <div className="openAnswerTooltipParent" {...provided.dragHandleProps}>
          <img className="openAnswerDraggableHandle" src={draggable_handle}/>
          <img className="openAnswerDraggableHandleOnHover" src={draggable_handle_onhover}/>
          <CourseCoreMoveTooltip x={"-126%"} y={"-195%"}/>
        </div>
      }
      <div className="openAnswerWrapper">
        <div className="openAnswerTitleWrapper">
          <img className="openAnswerTitleIcon" src={headline_icon}/>
          <div className="openAnswerTitleText">Open answer</div>
        </div>
        <div className="openAnswerSubtitleText">QUESTION</div>
        <AnswerWideTextarea
          text={localQuestionText}
          setNewText={setLocalQuestionText}
          autoFocus={autoFocus}
          openAnswerComponent={true}
        />
      </div>
      <div className="openAnswerXMarkContainer">
        <img className="openAnswerXMark" src={red_x_mark}/>
        <img
          className="openAnswerXMarkOnHover"
          src={red_x_mark_onhover}
          onClick={() => dispatch(actions.deleteComponent.request(moduleId, moduleItemId, moduleType, parentId))}
        />
        <CourseCoreDeleteTooltip x={"18%"} y={"-195%"}/>
      </div>
    </div>
  );
};

export default OpenAnswerComponent;
