import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { renderDropzone, renderNumberField } from './commonFormComponents';
import * as actions from '../../actions';
import {
  required,
  name,
  hasNumberInRange,
  composeValidators
} from '../../utils/validationUtils';
import Const from '../../store/Const';
import './EditClientForm.css';

/**
* EditClientForm
*/
const EditClientForm = () => {
  const dispatch = useDispatch();
  const client = useSelector(state => state.clients.currentClient);
  const toastActive = useSelector(state => state.componentStates.toastActive);
  const submitButtonDisabled = useSelector(state => state.componentStates.submitButtonDisabled);
  const clientTypes = Object.keys(Const.clientTypes);
  const clientTypeOptions = clientTypes.map(clientType => {
    return { name: clientType, id: Const.clientTypes[clientType] };
  });
  const currentClientLogo = client.logo ? process.env.REACT_APP_MEDIA_URL + client.logo : null;

  const { id } = useParams();

  useEffect(() => {
    dispatch(actions.getClient.request(id));
  }, [id, toastActive]);

  const handleSubmit = (data) => {
    dispatch(actions.updateClient.request(
      client._id,
      data.name,
      data.logo,
      data.maxActivePrograms,
      data.maxActiveUsers,
      data.maxAdmins,
      data.clientType
    ));
    dispatch(actions.setToastOrigin.request(`/client/${id}`));
  };

  return (
    <>
      <div className="editClientFormTitle">EDIT CLIENT</div>
      <Form
        onSubmit={(data) => handleSubmit(data)}
        initialValues={{
          name: client.name,
          maxActivePrograms: client.maxActivePrograms,
          maxActiveUsers: client.maxActiveUsers,
          maxAdmins: client.maxAdmins,
          clientType: client.clientType
        }}
      >
        {({ handleSubmit, values, errors }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div className="editClientSubTitle">Client</div>
            <div className="editClientQuestionWithErrorContainer">
              <Field
                name="name"
                validate={composeValidators(required, name)}
              >
                {({ input, meta }) => (
                  <div className="editClientInputAndErrorContainer">
                    <div className="editClientNameInputAndCounter">
                      <input
                        {...input}
                        type="text"
                        className="editClientInputField"
                        placeholder="Moblrn"
                        minLength= "1"
                        maxLength= "30"
                        required
                      />
                      <div className="editClientNameTextCounter">{calculateNameLength(values.name) + '/30'}</div>
                    </div>
                    {meta.error && meta.touched && <div className="editClientErrorMessage">{meta.error}</div>}
                  </div>
                )}
              </Field>
            </div>
            <div className="editClientSubTitleWithHelperText">Client Logo</div>
            <div className="editClientHelperText editClientImageHelperText">Upload the clients logo here</div>
            <div className="editClientQuestionContainer editClientDropzoneContainer">
              <Field
                name="logo"
                dropzonetype="rectangle"
                initialImage={currentClientLogo}
                component={renderDropzone}
              />
            </div>
            <div className="editClientSubTitleWithHelperText">Courses</div>
            <div className="editClientHelperText">How many courses should the client be able to create?</div>
            <div className="editClientQuestionWithErrorContainer">
              <div className="editClientInputAndErrorContainer editClientNumberInput">
                <div className="editClientPointsInputAndText">
                  <Field
                    name="maxActivePrograms"
                    validate={composeValidators(required, hasNumberInRange(1, 999))}
                    type="number"
                    defaultText="e.g. 100"
                    component={renderNumberField}
                  />
                  <div className="editClientNumberInputText">courses</div>
                </div>
              </div>
            </div>
            <div className="editClientSubTitleWithHelperText">Unique students</div>
            <div className="editClientHelperText">How many unique students should the client be able to have?</div>
            <div className="editClientQuestionWithErrorContainer">
              <div className="editClientInputAndErrorContainer editClientNumberInput">
                <div className="editClientPointsInputAndText">
                  <Field
                    name="maxActiveUsers"
                    validate={composeValidators(required, hasNumberInRange(1, 99999))}
                    type="number"
                    defaultText="e.g. 100"
                    component={renderNumberField}
                  />
                  <div className="editClientNumberInputText">students</div>
                </div>
              </div>
            </div>
            <div className="editClientSubTitleWithHelperText">Admin</div>
            <div className="editClientHelperText">How many admins should the client be able to have?</div>
            <div className="editClientQuestionWithErrorContainer">
              <div className="editClientInputAndErrorContainer editClientNumberInput">
                <div className="editClientPointsInputAndText">
                  <Field
                    name="maxAdmins"
                    validate={composeValidators(required, hasNumberInRange(1, 999))}
                    type="number"
                    defaultText="e.g. 100"
                    component={renderNumberField}
                  />
                  <div className="editClientNumberInputText">admins</div>
                </div>
              </div>
            </div>
            <div className="editClientSubTitleWithHelperText">Client type</div>
            <div className="editClientHelperText editClientImageHelperText">What kind of client is this?</div>
            <div className="editClientTypeDropdownContainer">
              <Field name="clientType" options={clientTypeOptions}>
                {({input, options}) => {
                  return (
                    <Dropdown
                      options={options}
                      name={input.name}
                      currentType={values.clientType}
                      onChange={(value) => input.onChange(value)}
                    />
                  );
                }}
              </Field>
            </div>
            <div className="editClientSubmitButtonsContainer">
              <Link to="/clients" exact="true">
                <button
                  type="button"
                  className="editClientCancelButton"
                >
                  CANCEL
                </button>
              </Link>
              <button
                type="submit"
                className="editClientSubmitButton"
                onSubmit={handleSubmit}
                disabled={disableSubmitButton(values, client, errors) || submitButtonDisabled}
              >
                SAVE CHANGES
              </button>
            </div>
          </form>
        )}
      </Form>
    </>
  );
};

const calculateNameLength = (name) => {
  if (!name) {
    return 0;
  } else {
    return name.length;
  }
};

const Dropdown = (props) => {
  return (
    <select
      name={props.name}
      onChange={props.onChange}
      className="editClientSelect"
      value={props.currentType}
    >
      {props.options.map((client) => {
        return (
          <option key={client.id} value={client.id}>{client.name}</option>
        );
      })}
    </select>
  );
};

const disableSubmitButton = (values, client, errors) => {
  return (
    Object.keys(errors).length > 0 ||
    !values.logo &&
    !(client.name !== values.name) &&
    !(client.maxActivePrograms !== parseInt(values.maxActivePrograms)) &&
    !(client.maxActiveUsers !== parseInt(values.maxActiveUsers)) &&
    !(client.maxAdmins !== parseInt(values.maxAdmins)) &&
    !(client.clientType !== parseInt(values.clientType))
  );
};

export default EditClientForm;
