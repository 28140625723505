import React from 'react';
import './IFrameComponent.css';

/**
* IFrameComponent: Renders an iframe element to display a YouTube or Vimeo video
*/
const IFrameComponent = ({ url }) => {
  if (url) {
    url = url.includes("vimeo") ? vimeoUrlFixer(url) : youtubeUrlFixer(url);
    return (
      <iframe
        className="iFrameComponent"
        src={url}
        allowFullScreen="allowFullScreen"
        mozallowfullscreen="mozallowfullscreen"
        msallowfullscreen="msallowfullscreen"
        oallowfullscreen="oallowfullscreen"
        webkitallowfullscreen="webkitallowfullscreen"
      >
      </iframe>
    );
  }
};

export default IFrameComponent;

/**
* youtubeUrlFixer() cleans up a youtube url to work in the iframe
*/
const youtubeUrlFixer = (url) => {
  let appendOptions = "?rel=0&amp;showinfo=1&amp&modestbranding=1";
  url = url.replace("youtu.be", "youtube.com/watch?v=");
  if (url.includes("?time_continue=")) {
    let videoId = url.split("v=")[1];
    let startTime = url.split("time_continue=")[1].split('&')[0];
    appendOptions = appendOptions + "&start=" + startTime;
    return "https://www.youtube.com/embed/" + videoId + appendOptions;
  }
  if (url.includes("youtube.com/embed")) {
    return url + appendOptions;
  }
  let newUrl = url.replace("youtube.com/watch?v=", "youtube.com/embed/");
  let superNewUrl = newUrl.split('&')[0];
  return superNewUrl + appendOptions;
};

/**
* vimeoUrlFixer() cleans up a youtube url to work in the iframe
*/
const vimeoUrlFixer = (url) => {
  let appendOptions = "?color=ffffff&badge=0&byline=0&portrait=0&title=0";
  if (!url.includes("player")) {
    url = url.replace("vimeo.com", "player.vimeo.com/video");
  }
  if (!url.includes("?")) {
    url = url.split('?')[0];
  }
  const [videoId, path] = url.split('video/')[1].split('/');
  const privacyParam = path ? path.split('?')[0] : '';
  if (privacyParam) {
    appendOptions += "&h=" + privacyParam;
  }
  return "https://player.vimeo.com/video/" + videoId + appendOptions;
};
