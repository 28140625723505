import React from 'react';
import MainLayout from '../components/layouts/MainLayout';
import StudentsProgressTable from './ProgressOverviewTable';

/**
 * StudentsProgressPage
 */
const StudentsProgressPage = () => {
  return (
    <MainLayout>
      <StudentsProgressTable />
    </MainLayout>
  );
};

export default StudentsProgressPage;
