import React from 'react';
import ResetPasswordForm from '../components/forms/ResetPasswordForm';
import moblrn_logo_old from '../images/svg/moblrn-logo-old.svg';
import './ResetPasswordPage.css';

/**
* ResetPasswordPage is the page where users can request an email with a
* temporary code used to change their password
*/
const ResetPasswordPage = () => {
  return (
    <div className="resetPasswordPageBackground">
      <div className="resetPasswordContent">
        <img className="moblrnLogo" src={moblrn_logo_old} alt="moblrn logo"/>
        <div className="resetPasswordTitle">Reset password</div>
        <ResetPasswordForm/>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
