import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Const from '../../store/Const';
import * as actions from '../../actions';
import { useOutsideClick } from '../../utils/componentUtils';
import plus_white from '../../images/svg/plus_white.svg';
import headline_icon from '../../images/svg/headline_icon.svg';
import text_icon from '../../images/svg/text_icon.svg';
import image_icon from '../../images/svg/image_icon.svg';
import multiple_images_icon from '../../images/svg/multiple_images_icon.svg';
import video_icon from '../../images/svg/video_icon.svg';
import link_icon from '../../images/svg/link_icon.svg';
import disclaimer_icon from '../../images/svg/disclaimer_icon.svg';
import './ContentDropdownWithDisclaimer.css';

/**
* ContentDropdownWithDisclaimer: Reusable dropdown component that includes all the options of
* fields (content) that can be added to a Module + Interaction Section which includes the Disclaimer
*/
export const ContentDropdownWithDisclaimer = ({ moduleId }) => {
  const dispatch = useDispatch();
  const welcomePageContent = useSelector(state => state.programs.currentProgram.moduleContent.welcome);
  const disclaimerComponent = welcomePageContent.find(item => item.type === Const.moduleItemType.DisclaimerCheckbox);
  const [openContentDropdownMenu, setOpenContentDropdownMenu] = useState(false);
  const contentDropdownRef = useRef();
  useOutsideClick(contentDropdownRef, () => {
    setTimeout(() => setOpenContentDropdownMenu(false), 100);
  });
  const handleAddComponentClick = (componentType) => {
    dispatch(actions.addComponent.request(componentType, null, moduleId, Const.moduleType.Welcome));
    setOpenContentDropdownMenu(false);
  };

  const addContentButtonRef = useRef();
  const { top, height } = addContentButtonRef.current ? addContentButtonRef.current.getBoundingClientRect() : { top: 0, height: 0 };
  const distanceToBottom = window.innerHeight - top - height;

  return (
    <div className="disclaimerDropdownButtonParent">
      <div
        className={openContentDropdownMenu ? "disclaimerDropdownButton disclaimerDropdownButtonActive" : "disclaimerDropdownButton"}
        ref={addContentButtonRef}
        onClick={() => setOpenContentDropdownMenu(!openContentDropdownMenu)}
      >
        <img className="disclaimerDropdownButtonPlusIcon" src={plus_white}/>
        <div>ADD CONTENT</div>
      </div>
      {openContentDropdownMenu && renderDropdownMenu(contentDropdownRef, handleAddComponentClick, disclaimerComponent, distanceToBottom)}
    </div>
  );
};

const renderDropdownMenu = (contentDropdownRef, handleAddComponentClick, disclaimerComponent, distanceToBottom) => {
  return (
    <div
      className={distanceToBottom < 280 ? "disclaimerDropdownMenuWrapperDropUp" : "disclaimerDropdownMenuWrapper"}
      ref={contentDropdownRef}
    >
      <div>
        <div className="disclaimerDropdownMenuTitle">CONTENT</div>
        <div className="disclaimerDropdownMenuItem" onClick={() => handleAddComponentClick(Const.moduleItemType.Title)}>
          <div>Headline</div>
          <img className="disclaimerDropdownMenuItemIcon" src={headline_icon}/>
        </div>
        <div className="disclaimerDropdownMenuItem" onClick={() => handleAddComponentClick(Const.moduleItemType.Paragraph)}>
          <div>Text</div>
          <img className="disclaimerDropdownMenuItemIcon" src={text_icon}/>
        </div>
        <div className="disclaimerDropdownMenuItem" onClick={() => handleAddComponentClick(Const.moduleItemType.Image)}>
          <div>Image</div>
          <img className="disclaimerDropdownMenuItemIcon" src={image_icon}/>
        </div>
        <div className="disclaimerDropdownMenuItem" onClick={() => handleAddComponentClick(Const.moduleItemType.ImageCarousel)}>
          <div>Multiple Images</div>
          <img className="disclaimerDropdownMenuItemIcon" src={multiple_images_icon}/>
        </div>
        <div className="disclaimerDropdownMenuItem" onClick={() => handleAddComponentClick(Const.moduleItemType.Video)}>
          <div>Video link (URL)</div>
          <img className="disclaimerDropdownMenuItemIcon" src={video_icon}/>
        </div>
        <div className="disclaimerDropdownMenuItem" onClick={() => handleAddComponentClick(Const.moduleItemType.Link)}>
          <div>Link (URL)</div>
          <img className="disclaimerDropdownMenuItemIcon" src={link_icon}/>
        </div>
      </div>
      <div className="disclaimerDropdownMenuDivider"></div>
      <div>
        <div className="disclaimerDropdownMenuTitle">INTERACTION</div>
        {disclaimerComponent ?
          <div className="disclaimerDropdownDisabledMenuItem">
            <div>Disclaimer</div>
            <img className="disclaimerDropdownMenuItemIcon" src={disclaimer_icon}/>
          </div>
          :
          <div className="disclaimerDropdownMenuItem" onClick={() => handleAddComponentClick(Const.moduleItemType.DisclaimerCheckbox)}>
            <div>Disclaimer</div>
            <img className="disclaimerDropdownMenuItemIcon" src={disclaimer_icon}/>
          </div>
        }
      </div>
    </div>
  );
};
