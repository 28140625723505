import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import LoadingOverlay from '../components/LoadingOverlay';
import Menu from '../components/Menu.js';
import APITable from '../components/tables/APITable.js';
import './APIOverviewPage.css';

/**
* APIOverviewPage
*/
const APIOverviewPage = () => {
  const isSuperAdmin = useSelector(state => state.user.isSuperAdmin);
  const showLoadingOverlay = useSelector(state => state.componentStates.loadingAPITableOverlayActive);
  return (
    <div className="pageContainer">
      {showLoadingOverlay && <LoadingOverlay/>}
      <Menu/>
      <div className="pageContentWrapper">
        {isSuperAdmin ?
          <div>
            <div className="pageTitle">API OVERVIEW</div>
            <APITable/>
          </div>
          : <Redirect to="/"/>
        }
      </div>
    </div>
  );
};

export default APIOverviewPage;
