import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import JoditEditor from "jodit-react";
import * as actions from '../../actions';
import './CourseWelcomeMailForm.css';

/**
* CourseWelcomeMailForm
*/

const CourseWelcomeMailForm = () => {
  const dispatch = useDispatch();
  const editor = useRef(null);
  const currentProgram = useSelector(state => state.programs.currentProgram);
  const customSubjectEmail = currentProgram.customSubjectEmail || '';
  const customEmail = currentProgram.customEmail || '';
  const [localSubject, setLocalSubject] = useState('');
  const [localEmailText, setLocalEmailText] = useState('');

  useEffect(() => {
    if (customSubjectEmail && customSubjectEmail.length > 0) {
      setLocalSubject(customSubjectEmail);
    }
    if (customEmail && customEmail.length > 0) {
      setLocalEmailText(customEmail);
    }
  }, [customSubjectEmail, customEmail]);

  const handleSubmit = () => {
    const updatedProgram = {
      _id: currentProgram._id,
      clientId: currentProgram.clientId,
      customSubjectEmail: localSubject,
      customEmail: localEmailText
    };
    dispatch(actions.editCourseSettings.request(updatedProgram));
  };

  const joditTextEditorConfig = {
    readonly: false,
    toolbarAdaptive: false,
    buttons: [
      'undo',
      'redo',
      'font',
      'fontsize',
      'bold',
      'italic',
      'underline',
      'eraser',
      'brush',
      'link',
      'ul',
      'ol',
      'indent',
      'outdent',
      'left',
      'fullsize'
    ],
    "showCharsCounter": false,
    "showWordsCounter": false,
    "showXPathInStatusbar": false,
    toolbarButtonSize: "small",
    placeholder: 'Write your Welcome email here...'
  };

  return (
    <>
      <div className="courseWelcomeMailTitle">INVITATION MESSAGE</div>
      <div className="courseWelcomeMailSubTitle">Subject</div>
      <div className="courseWelcomeMailHelperText">Enter the subject of the invitation message here</div>
      <input
        type="text"
        className="courseWelcomeMailSubjectInput"
        placeholder={'Invitation to {{ProgramName}}'}
        value={localSubject}
        onChange={(event) => setLocalSubject(event.target.value)}
      />
      <div className="courseWelcomeMailSubTitle">Message</div>
      <div className="courseWelcomeMailHelperText">Edit the content below if you want to personalise the invitation message</div>
      <div className="courseWelcomeMailHelperText courseWelcomeMailTipText">Tip!</div>
      <div className="courseWelcomeMailHelperText courseWelcomeMailBottomTipText">You can paste HTML directly into the invitation message</div>
      <div className="courseWelcomeMailTextEditorWrapper">
        <JoditEditor
          ref={editor}
          value={localEmailText}
          config={useMemo(() => joditTextEditorConfig, [])}
          tabIndex={1}
          onChange={localEmailText => setLocalEmailText(localEmailText)}
        />
      </div>
      <div className="courseWelcomeMailSubTitle">Message macros template</div>
      <div className="courseWelcomeMailHelperText courseWelcomeMailMacrosHelperText">
        Use the macros below to generate a more personal invitation message
      </div>
      <div className="courseWelcomeMailMacros">
        <div>User&apos;s name - &#123;&#123;UserName&#125;&#125;</div>
        <br/>
        <div>Program name - &#123;&#123;ProgramName&#125;&#125;</div>
        <div>Client name - &#123;&#123;ClientName&#125;&#125;</div>
        <br/>
        <div>Android app link - &#123;&#123;AndroidAppLink&#125;&#125;</div>
        <div>iOS app link - &#123;&#123;IOSAppLink&#125;&#125;</div>
        <div>Web app program link - &#123;&#123;ProgramWebLink&#125;&#125;</div>
      </div>
      <button
        className="courseWelcomeMailSubmitButton"
        type="button"
        disabled={disableSubmitButton(localSubject, localEmailText, customSubjectEmail, customEmail)}
        onClick={() => handleSubmit()}
      >
        SAVE CHANGES
      </button>
    </>
  );
};

const disableSubmitButton = (localSubject, localEmailText, customSubjectEmail, customEmail) => {
  if (!localSubject || !localEmailText) {
    return true;
  } else if (customSubjectEmail === localSubject && localEmailText.replace(/\s/g, "") === customEmail.replace(/\s/g, "")) {
    return true;
  } else {
    return false;
  }
};

export default CourseWelcomeMailForm;
